import { Educator, Institution, DonationRecipient } from 'src/services/types';

export interface FullDonationRecipient extends DonationRecipient {
    schoolName: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    dateAdded: string;
}

export function combineFavorites(
    educators: Educator[],
    institutions: Institution[]
): FullDonationRecipient[] {
    // let fullDonationRecipients: FullDonationRecipient[];
    const educatorsMapped: FullDonationRecipient[] = educators.map((_) => ({
        id: _.userId,
        schoolName: _.schoolName,
        type: 'user',
        street: _.street,
        city: _.city,
        state: _.state,
        zip: _.zip,
        firstName: _.firstName,
        lastName: _.lastName,
        institutionName: '',
        dateAdded: _.dateAdded,
    }));
    const institutionsMapped: FullDonationRecipient[] = institutions.map(
        (_) => ({
            id: _.institutionKey,
            schoolName: _.name,
            type: 'institution',
            street: _.street,
            city: _.city,
            state: _.state,
            zip: _.zip,
            firstName: '',
            lastName: '',
            institutionName: _.name,
            dateAdded: _.dateAdded,
        })
    );
    // sorting most recently added first
    return [...educatorsMapped, ...institutionsMapped].sort((a, b) => {
        const da = new Date(a.dateAdded).getTime();
        const db = new Date(b.dateAdded).getTime();
        return db - da;
    });
}

export function isFavorite(
    favorites: FullDonationRecipient[],
    id: FullDonationRecipient['id']
) {
    return favorites.some((_) => _.id === id);
}
