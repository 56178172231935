/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { IAuthenticatorContext } from 'react-oidc/lib/makeAuth';
import { User as OidcUser, User } from 'oidc-client';
import { AuthService } from 'src/lib/auth';
import useRouter from 'src/hooks/useRouter';
import ROUTES from '../routes';
import { SessionManager } from '../api-client/sessionManager';
// import Log from 'src/lib/logging';

export interface UseAuthState {
    login: () => void;
    /** side effect: logout also clears local user storage */
    logout: () => void;
    signup: () => void;
    editProfileURL: () => string;
    /**
     * user info returned from the auth service.
     * rarely used.
     */
    oidcUser?: OidcUser | null;
    isLoading?: boolean;
    isLoggedIn: boolean;
    error?: string;
}

export default function useAuthFromContext(
    context: IAuthenticatorContext
): UseAuthState {
    const { userManager } = context;
    const [oidcUser, setOidcUser] = useState<User | null>(null);
    const [error] = useState('');
    const [loadingMessage, setLoadingMessage] =
        useState<string>('Initializing');
    const { history } = useRouter();
    const isLoading = loadingMessage !== '';
    useEffect(() => {
        const auth = new AuthService(userManager);
        const userVal = auth.getUser();
        setLoadingMessage('');
        // if (userVal?.error) {
        //   Log.auth(userVal?.error);
        //   setError(userVal.error);
        //   return;
        // }
        setOidcUser(userVal);
    }, []);
    const login = () => {
        if (isLoading) {
            return;
        }
        setLoadingMessage('Logging you in');
        const auth = new AuthService(userManager);
        auth.login();
    };
    const signup = () => {
        if (isLoading) {
            return;
        }
        setLoadingMessage('Redirecting to sign in');
        const auth = new AuthService(userManager);
        auth.signup();
    };

    const logout = () => {
        setLoadingMessage('Logging you out');
        const auth = new AuthService(userManager);
        auth.logout().then(async () => {
            setOidcUser(null);
            await Promise.all([
                userManager?.clearStaleState(),
                userManager?.removeUser(),
            ]);
            const localStorageKeys = [];
            for (
                let keyIndex = 0, len = localStorage.length;
                keyIndex < len;
                keyIndex += 1
            ) {
                localStorageKeys.push(localStorage.key(keyIndex));
            }
            localStorageKeys.forEach((key) => {
                if (key && !key.includes('persistent')) {
                    localStorage.removeItem(key);
                }
            });
            sessionStorage.clear();
            setLoadingMessage('');
            window.location.replace('/');
        });
    };

    const editProfileURL = () => {
        setLoadingMessage('Redirecting to edit profile');
        const auth = new AuthService(userManager);
        return auth.getProfileURL();
    };

    const isLoggedIn = !!oidcUser;
    return {
        login,
        logout,
        signup,
        editProfileURL,
        isLoading,
        isLoggedIn,
        oidcUser,
        error,
    };
}
