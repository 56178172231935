import { useState } from 'react';
import {
  ErrorSource,
  ErrorType,
  OnErrorFn,
} from 'src/screens/claim-schools-flow/types';

// common error logic used across rewards screens
export default function useError(onError?: OnErrorFn) {
  const [hadError, setHadError] = useState(false);

  function showError(
    errorSource: ErrorSource,
    errorType: ErrorType,
    errorDetail?: string
  ) {
    setHadError(true);
    if (onError) {
      onError(errorSource, errorType, errorDetail || '');
    }
  }

  return { showError, hadError, setHadError };
}
