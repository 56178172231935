import { createContext, useContext, useState } from 'react';

export const ShippingFields = {
    fullname: '',
    companyname: '',
    country: '',
    email: '',
    city: '',
    state: '',
    street1: '',
    street2: '',
    postalcode: '',
    phonenumber: '',
    countrycode: '',
}

const ShippingInfoContext = createContext({
    recipient: {
        ...ShippingFields,
    },
    sender: {
        ...ShippingFields,
    },
    inReviewStage: false,
    fetchSenderInfo: true,
    openScanToTrack: false,
    shippingTab: 'create',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateRecipient: (recipientDetails: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateSender: (senderDetails: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateReviewStage: (stage: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateFetchSenderInfo: (flag: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateOpenScanToTrack: (flag: boolean) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateShippingTab: (value: string) => { },

});
export function useShippingInfoContextState() {
    const [sender, setSender] = useState<any>({
        sender: {
            ...ShippingFields,
        },
    })
    const [recipient, setRecipient] = useState<any>({
        recipient: {
            ...ShippingFields,
        },
    })
    const [inReviewStage, setInReviewStage] = useState(false);
    const [fetchSenderInfo, setFetchSenderInfo] = useState(false);
    const [openScanToTrack, setOpenScanToTrack] = useState(false);
    const [shippingTab, setShippingTab] = useState('created')
    const updateRecipient = (recipientDetails: any) => setRecipient(recipientDetails)
    const updateSender = (senderDetails: any) => setSender(senderDetails)
    const updateReviewStage = (stage: boolean) => setInReviewStage(stage)
    const updateFetchSenderInfo = (flag: boolean) => setFetchSenderInfo(flag);
    const updateShippingTab = (value: string) => setShippingTab(value);
    const updateOpenScanToTrack = (flag: boolean) => { setOpenScanToTrack(flag); };
    return {
        recipient,
        updateRecipient,
        sender,
        updateSender,
        inReviewStage,
        updateReviewStage, fetchSenderInfo, updateFetchSenderInfo, openScanToTrack, updateOpenScanToTrack,
        shippingTab, updateShippingTab,
    }
}

export function useShippingInfoContext() {
    return useContext(ShippingInfoContext)
}

export default ShippingInfoContext;