
import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ShippingServicesAccordion from './shipping-services-accordion';
import ShippingServicesNoData from './shipping-services-no-data';
import ShippingErrorInline from '../shipping-errors/shipping-error-inline';

const useStyles = makeStyles((theme) => ({
    ShippingServicesCreateLabelConatiner: {
        padding: '18px 0px !important',
        boxShadow: 'none',
        overflow: 'auto',
        height: 'calc(100vh - 150px)',
    },
}))
const ShippingServicesCreateLabel = ({activeTab, shipmentListData, updatedData, setError, error} : any) => {
    const [createLabelData, setCreateLabelData] = useState<any>([]);
    const classes = useStyles();
    useEffect(() => {
        const filterCreatedLabelData = shipmentListData?.filter((data: any) => 
            data?.shimpmentstatus?.shipmentstatus === "Saved" ||
            ( data?.shipmenttype === "TRACKING"
            &&  data?.shimpmentstatus?.shipmenttrackingstatus[0]?.package[0]?.activity?.status?.statusCode === '003' )
        ) 
        setCreateLabelData(filterCreatedLabelData);
    }, [shipmentListData])

    if(error) {
        return <ShippingErrorInline setError={setError}
                                    errorMessage='We couldn’t retrieve your created
                                    labels or current shipment statuses at this time.
                                    Please try again later.' />
    }
     if(createLabelData?.length === 0) {
        return <ShippingServicesNoData 
            imgData={require('src/assets/icons/createLabelsNoShippingIcon.svg')}
            error="Create a shipment or visit your nearest Staples® store to ship a package."
            errorDetails=""
        />
    }

    return <div className={classes.ShippingServicesCreateLabelConatiner}>
        {createLabelData?.map((shipCreateData: any, i: any) =>
            <ShippingServicesAccordion
                key={`${shipCreateData}_i`}
                data={shipCreateData}
                activeTab={activeTab}
                updatedData={updatedData}
            />
        )}
    </div>

}

export default ShippingServicesCreateLabel;
