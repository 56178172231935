/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
    userFirebaseTokenURL,
    userValidateFirebaseTokenURL,
} from 'src/services/urls';

type SubscriptionResponse = any;
export async function subscribeUserToken(
    fcmToken: string
): Promise<SubscriptionResponse | null> {
    try {
        const results = await axiosInstance.post(
            `${process.env.REACT_APP_USER_API_FCM_SUBSCRIBE}`,
            { Token: fcmToken }
        );
        if (results.data) {
            return results.data as SubscriptionResponse;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export async function updateFirebaseToken(
    newToken: string,
    currentToken: string | null
): Promise<any | null> {
    try {
        let data: any = { newToken };
        if (currentToken) {
            data = { ...data, currentToken };
        }
        const results = await axiosInstance.post(`${userFirebaseTokenURL}`, {
            ...data,
        });
        return null;
    } catch (e) {
        return null;
    }
}

export const getFirebaseToken = async (
    token: string | undefined
): Promise<AxiosResponse<any>> =>
    axiosInstance.get(
        `${userValidateFirebaseTokenURL}`.replace('%token%', `${token}`)
    );
