import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { IconUpArrow } from 'src/components/Icons';
import styles from './styles.module.scss';
import { Body } from '../Typography';

interface BackToTopFABProps {
    containerRef: React.RefObject<HTMLElement>;
    scrollBuffer?: number;
}
function BackToTopFAB({ containerRef, scrollBuffer = 250 }: BackToTopFABProps) {
    const [scrollPosition, setScrollPosition] = useState<number>();

    const handleScrollToTop = () => {
        containerRef.current?.scroll({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(containerRef.current?.scrollTop);
        };

        containerRef.current?.addEventListener('scroll', handleScroll);

        return () => {
            containerRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [containerRef.current]);

    const showScrollToTopFAB = useMemo(() => {
        if (scrollPosition && scrollPosition > scrollBuffer) {
            return true;
        }
        return false;
    }, [scrollPosition]);

    return (
        <AnimatePresence>
            {showScrollToTopFAB && (
                <motion.button
                    onClick={handleScrollToTop}
                    className={styles.button}
                    initial={{ top: 0, opacity: 0 }}
                    animate={{ top: '1rem', opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    exit={{ top: 0, opacity: 0 }}
                >
                    <div className={styles.buttonContent}>
                        <IconUpArrow />{' '}
                        <Body size={12} color="textWhite" weight="bold">
                            BACK TO TOP
                        </Body>
                    </div>
                </motion.button>
            )}
        </AnimatePresence>
    );
}

export default BackToTopFAB;
