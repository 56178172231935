// creating-shipping-package-information

import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import ValidationTextField from 'src/components/validation-text-field';
import InfoButton from 'src/components/info-button';
import COLORS from '../../../lib/colors';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-1px',
    },
    headerSubTitle: {
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '25.96px',
        letterSpacing: '-0.6px',
        width: '274px',
    },
    pkgTitle: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '18.88px',
        paddingBottom: '5px',
        marginTop: '30px',
    },
    nextBtn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        margin: '0',
        width: '100%',
        marginTop: '30px',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
        '&.Mui-disabled': {
            backgroundColor: COLORS.disabledBackgroundGray,
            color: COLORS.disabledTextGray,
        },
    },
    mainPackageConatiner: {
        margin: '24px',
        display: 'flex',
        flexDirection: 'column',
    },
    addPackage: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#086DD2',
    },
    addPackageContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '0',
        position: 'absolute',
        bottom: '5px',
        right: '0',
    },
    addPackagePlus: {
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#086DD2',
    },
    packagesFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        '& .MuiFormLabel-root': {
            color: '#444444 !important',
        },
    },
    packageTextField: {
        marginBottom: '24px',
    },
    chevron: {
        position: 'absolute',
        left: '50%',
        top: '145px',
    },
    tooltip: {
        '& .MuiTooltip-arrow': {
            overflow: 'unset',
            left: 'auto !important',
            right: '48% !important',
        },
    },
    deleteBtn: {
        position: 'absolute',
        right: '0',
        top: '-45px',
    },
    hide: {
        display: 'none',
    },
    errorText: {
        fontSize: '11px',
        color: '#CC0000',
        display: 'block',
        width: '100%',
    },
}));
interface packageProps {
    packageInfo: any;
    setPackageInfo: (data: any) => void;
}
const CreateShippingPackageInformation = ({
    packageInfo,
    setPackageInfo,
}: packageProps) => {
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [packages, setPackages] = useState<any>({
        package1: { package: '', weight: '' },
    });
    const totalPkg = Object.keys(packages).length
    const [totalPackages, setTotalPackages] = useState(totalPkg);
    const [emptyWeight, setEmptyWeight] = useState(false);
    const [emptyPkg, setEmptyPkg] = useState(false);
    let isFormValid = true;
    Object?.keys(packages).forEach((item) => {
        if (packages[item]?.package === '' || packages[item]?.weight === '' || packages[item]?.weight > 150 ||
            packages[item]?.weight < 1) {
            isFormValid = false;
        }
    })
    const handleOnAddPackage = () => {
        setTotalPackages(totalPackages + 1);
        setPackages((prevPackages: any) => ({
            ...prevPackages,
            [`package${totalPackages + 1}`]: { package: '', weight: '' },
        }));
        setEmptyWeight(false);
        setEmptyPkg(false);
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'add package',
            element_location: 'package information',
        });
    }
    const { inReviewStage } = useContext(ShippingInfoContext)
    const handleOnNext = (event: any) => {
        if (!isFormValid) {
            Object?.keys(packages).forEach((item) => {
                if (packages[item]?.package === '') {
                    event.preventDefault();
                    setEmptyPkg(true);
                }
                if (packages[item]?.weight === '') {
                    event.preventDefault();
                    setEmptyWeight(true);
                }
            })
        } else {
            setPackageInfo(packages);
            if (inReviewStage) {
                history.push({
                    pathname: ROUTES.createShippingInformation,
                    search: '?step=CreateShippingReviewInformation',
                })
            }
            else {
                history.push({
                    pathname: ROUTES.createShippingInformation,
                    search: '?step=CreateShippingReviewInformation',
                })
            }

            analyticsCustomEvent(eventMetricsMap.shipping_info, {
                click_text: 'next',
                element_location: 'package information',
            });
        }

    }
    const handleOnClickDelet = (key: any) => {
        setPackages((prevPackages: any) => {
            const modifyPack = { ...prevPackages };
            delete modifyPack[key];
            return modifyPack;
        });
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'delete package',
            element_location: 'package information',
        });
    };
    useEffect(() => {
        if (Object.keys(packageInfo).length) {
            setPackages(packageInfo);
        }
    }, [])
    const handleChange = (e: any, key: any) => {
        const { name, value } = e.target;
        setPackages({
            ...packages,
            [key]: {
                ...packages[key], [name]: value, maxWeight: name === 'weight' && value > 150,
                minWeight: name === 'weight' && value < 1,
            },
        });
    }

    const handleKeyDown = (event: any) => {
        if (event.key === '.') {
            event.preventDefault();
        }
    }
    return (
        <div className={classes.mainPackageConatiner}>
            <div>
                {/* <Typography className={classes.headerTitle}>Package Information</Typography> */}
                <Typography className={classes.headerSubTitle}>What are the contents of your package(s)?</Typography>
            </div>
            <div className={classes.packagesFieldContainer}>
                <div className={classes.chevron}>
                    <InfoButton
                        tooltipTitle="Package weight is for estimate calculations and will 
                        be weighed at the store when shipped."
                        tooltipCta='Got it'
                        buttonId='estimated-weight-tooltip-button'
                        tooltipPlacement='top'
                        buttonSize='13px'
                        customizedIcon={require('src/assets/icons/inforIcon.svg')}
                        tooltipClasses={{
                            tooltip: classes.tooltip,
                        }}
                    />
                </div>
                {Object.keys(packages).map((key: any, index: any) =>
                    <>
                        <div key={key}>
                            <Typography className={classes.pkgTitle}>{`Package ${index + 1}`}</Typography>
                            <div className={classes.packageTextField}>
                                <ValidationTextField
                                    fullWidth
                                    id="create-shipping-package-input"
                                    name="package"
                                    label={`Contents*`}
                                    value={packages[key].package}
                                    onChange={(e) => handleChange(e, key)}
                                    InputProps={{
                                        endAdornment: Object.keys(packages).length > 1 ? (
                                            <div className={classes.deleteBtn}>
                                                <ButtonBase
                                                    id={`create-shipping-package-${index}-remove-button`}
                                                    onClick={() =>
                                                        handleOnClickDelet(key)}
                                                >
                                                    <img
                                                        alt="delete"
                                                        src={require('../../../assets/icons/iconRedDelete.svg')}
                                                    />
                                                </ButtonBase></div>
                                        ) : null,
                                    }}
                                />
                                {emptyPkg && !packages[key]?.package &&
                                    <span className={classes.errorText}>Package content is required field.</span>}
                            </div>
                            <ValidationTextField
                                fullWidth
                                id="estimated-weight-input"
                                name="weight"
                                label='Estimated weight (lb.)*'
                                type="number"
                                onChange={(e) => handleChange(e, key)}
                                onKeyDown={handleKeyDown}
                                value={packages[key]?.weight}
                                style={{ width: '50%', minWidth: '180px' }}
                            />
                        </div>
                        {packages[key]?.maxWeight &&
                            <span className={classes.errorText}>Weight must be less than or equal to 150</span>}
                        {packages[key]?.minWeight &&
                            <span className={classes.errorText}>Weight must be greater than 0.</span>}
                        {emptyWeight && !packages[key]?.weight &&
                            <span className={classes.errorText}>Weight is required field.</span>}
                    </>
                )}
                <ButtonBase className={classes.addPackageContainer}
                    style={{ opacity: Object.keys(packages).length >= 10 ? 0.5 : 1 }}
                    disabled={!isFormValid || Object.keys(packages).length >= 10}
                    onClick={handleOnAddPackage}>
                    <div className={classes.addPackage}>Add package</div>
                    <div className={classes.addPackagePlus}>+</div>
                </ButtonBase>
            </div>
            <ButtonBase className={classes.nextBtn} onClick={(e) => handleOnNext(e)}>
                <span>Next</span>
            </ButtonBase>
        </div>
    )
}

export default CreateShippingPackageInformation;
