import React from 'react';
import clsx from 'clsx';
import Button from 'src/components/UI/Button';
import { Body, Heading } from 'src/components/UI/Typography';
import { Player } from '@lottiefiles/react-lottie-player';
import OptimalDeviceController from 'src/controllers/optimal-device-controller';
import LoadingOverlay from 'src/components/loading-overlay';
import styles from './styles.module.scss';
import ShopAsGuest from '../Login/ShopAsGuest';
import { useRouter } from '../../hooks';
import { COACH_SCREENS_ROUTES } from '../../routers/routes';

interface CoachScreenProps {
    title: string;
    subtitle: string;
    primaryButtonText: string;
    primaryButtonOnClick: () => void;
    secondaryButtonText?: string;
    secondaryButtonOnClick?: () => void;
    imageSrc: string;
    background?: string;
    isQRScreenDisplay?: boolean;
    isLoading?: boolean;
}

function CoachScreen({
    title,
    subtitle,
    primaryButtonText,
    primaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonOnClick,
    imageSrc,
    background = 'none',
    isQRScreenDisplay = false,
    isLoading = false,
}: CoachScreenProps) {
    const { pathname } = useRouter()
    return (
        <div
            className={clsx(
                styles.flex,
                styles.flexCol,
                styles.alignCenter,
                styles.justifyCenter,
                styles.p4,
                styles.screenHeight
            )}
            style={{ background }}
        >
            <div
                className={clsx(
                    styles.flex,
                    styles.flexCol,
                    styles.alignCenter,
                    styles.justifyCenter,
                    styles.rowGap4
                )}
            >
                <Heading textAlign="textCenter" type="h2" size={26} className={styles.title} weight="bold">
                    {title}
                </Heading>
                <Body textAlign="textCenter" className={styles.description} weight="light">{subtitle} </Body>
            </div>
            <Player autoplay loop src={imageSrc} />
            {/* <img style={{ maxWidth: '100%' }} src={imageSrc} alt="x" /> */}
            <div
                className={clsx(
                    styles.flex,
                    styles.flexCol,
                    styles.alignCenter,
                    styles.justifyCenter,
                    styles.rowGap4
                )}
            >
                <Button
                    variant="contained-rounded"
                    bgColor="bgBlack"
                    onClick={primaryButtonOnClick}
                    size="large"
                    className={styles.btnAlign}
                >
                    {primaryButtonText}
                </Button>
                {secondaryButtonText && secondaryButtonOnClick && (
                    <Button
                        variant="text"
                        color="textDarkgray"
                        onClick={secondaryButtonOnClick}
                        decoration="textUnderline"
                        fontSize={14}
                        className={styles.secondaryBtnAlign}
                    >
                        {secondaryButtonText}
                    </Button>
                )}
                {pathname === COACH_SCREENS_ROUTES.initial && <ShopAsGuest />}
            </div>
            {isQRScreenDisplay ? <OptimalDeviceController /> : null}
            {isLoading ? <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading} /> : null}
        </div>
    );
}

export default CoachScreen;
