import { createContext, useContext, useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/messaging';
import Log from 'src/lib/logging';
import { readNotificationPermission } from 'src/lib/firebase/notifications';
import {
    getFirebaseToken,
    subscribeUserToken,
    updateFirebaseToken,
} from 'src/lib/api-client/fcm-subscriptions';
import { SERVICE_WORKER_URL } from 'src/service-worker-registration';

type RegistrationResult = { success: boolean; reason: string };
type MessagePayload = any;
type FirebaseMessagingContext = {
    messaging: firebase.messaging.Messaging | null;
    lastMessage: MessagePayload | null;
    /**
     * Subscribes (or resubscribes) the user
     * to their relevant FCM topics (managed in the middleware).
     * Also requests notification permissions.
     */
    subscribe: () => void;
};

/**
 * Allows firebase messages to be available throughout the app
 */
const FirebaseMessagingContext = createContext<FirebaseMessagingContext>({
    messaging: null,
    lastMessage: null,
    subscribe: () => null,
});

export function useFirebaseMessagingContextState(): FirebaseMessagingContext {
    const messaging = useRef<firebase.messaging.Messaging | null>(null);
    const [lastMessage, setLastMessage] = useState(null);

    useEffect(() => {
        if (firebase.messaging.isSupported()) {
            messaging.current = firebase.messaging();

            if (messaging.current) {
                // Handle incoming messages. Called when:
                // - a message is received while the app has focus
                // - the user clicks on an app notification created by a service worker
                //  see also `messaging.onBackgroundMessage` handler in service-worker.ts
                messaging.current.onMessage((payload) => {
                    Log.firebase('Message received. ', payload);
                    setLastMessage(payload);
                });
            }
        }
    }, []);

    const getUpdateFierbaseToken = async (token: any, tokenStatus: any) => {
        const getTokenFromStore = window.localStorage.getItem('firebaseToken');
        if (token && !tokenStatus?.data?.isValid) {
            try {
                await updateFirebaseToken(token, null);
                console.log(`firebase token inserted as a new user: ${token}`);
                window.localStorage.setItem('firebaseToken', token);
            } catch (e) {
                console.log(`firebase token insert failed: ${e}`);
            }
        } else if (getTokenFromStore && token && getTokenFromStore !== token) {
            try {
                await updateFirebaseToken(token, getTokenFromStore);
                console.log(`firebase token updated: ${token}`);
                window.localStorage.setItem('firebaseToken', token);
            } catch (e) {
                console.log(`update firebase token failed: ${e}`);
            }
        } else if (
            (!getTokenFromStore ||
                getTokenFromStore === 'null' ||
                getTokenFromStore === 'undefined') &&
            tokenStatus?.data?.isValid
        ) {
            console.log(`firebase token added to local storage: ${token}`);
            window.localStorage.setItem('firebaseToken', token);
        }
        return console.log(`firebase token already inserted: ${token}`);
    };
    // NICE TO HAVE: log custom event when user grants notifications
    // const { customEvent } = useEventsReporter();

    /** Returns success:true if we have registered properly for FCM notifications */
    async function subscribeAsync(): Promise<RegistrationResult> {
        if (!process.env.REACT_APP_FCM_WEB_PUSH_KEY_PAIR) {
            return { success: false, reason: 'unknown' };
        }
        const result = await readNotificationPermission();
        if (result.userInteractionGuess) {
            Log.firebase(
                `Notification permission (user interaction):  ${result.reason}`
            );
            // User likely clicked block / allow this time
            // customEvent('notifications');
        } else {
            Log.firebase(
                `Notification permission (automatic):  ${result.reason}`
            );
        }
        if (!result.granted) {
            return { success: false, reason: result.reason };
        }
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        let token = '';
        if (messaging.current) {
            try {
                const serviceWorkerRegistration =
                    await navigator.serviceWorker.register(SERVICE_WORKER_URL);
                token = await messaging.current.getToken({
                    vapidKey: process.env.REACT_APP_FCM_WEB_PUSH_KEY_PAIR,
                    serviceWorkerRegistration,
                });
            } catch (error) {
                Log.firebase('Error getting FCM token: ', error);
                return { success: false, reason: 'FCM getToken error' };
            }
        }

        if (token) {
            Log.firebase('FCM token acquired: ', token);
            const tokenStatus = await getFirebaseToken(token);
            getUpdateFierbaseToken(token, tokenStatus);
            await subscribeUserToken(token);
            return { success: true, reason: '' };
        }
        Log.firebase(
            'No registration token available. Requests to subscribe/unsubscribe will not succeed.'
        );
        return { success: false, reason: 'unknown' };
    }

    return {
        messaging: messaging.current,
        lastMessage,
        subscribe: subscribeAsync,
    };
}

export function useFirebaseMessagingContext() {
    return useContext(FirebaseMessagingContext);
}

export default FirebaseMessagingContext;
