
import { Player } from '@lottiefiles/react-lottie-player';
import { Typography, makeStyles, Dialog, ButtonBase } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { SCREEN_NAMES } from 'src/controllers/config';
import { eventMetricsMap, useEventsReporter, useRouter, useUserInfo } from 'src/hooks';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import COLORS from 'src/lib/colors';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import ROUTES from 'src/lib/routes';
import { PointHistory, getLoyaltyAccountDetails, getPointHistory } from 'src/services/easyRewardsServices';
import { numberWithCommas } from 'src/lib/utils/app-utils';
import { Body, Button } from './UI';

const useStyles = makeStyles({
    earnedPointText: {
        lineHeight: "34px",
        color: "#343434",
        letterSpacing: "-0.75px",
    },
    pointsAlign: {
        color: "#5A0069",
        lineHeight: "32px",
    },
    dsc: {
        lineHeight: "20px",
        letterSpacing: "0px",
        margin: '25px 25px 30px !important',
        textAlign: 'center',
        color: "#000000",
    },
    lottieOver: {
        zIndex: 2000,
        pointerEvents: 'none',
    },
    btn: {
        width: '150px',
        height: '46px',
        background: '#5B0069',
        color: '#FFFFFF',
        marginBottom: '20px',
    },
    history: {
        letterSpacing: "0px",
        lineHeight: "18px",
    },
    conatiner: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        padding: '16px 5px 30px',
    },
});

const PointHitoryCelebrationScreen = () => {
    const { userInfo } = useUserInfo();
    const { analyticsCustomEvent } = useEventsReporter();
    const [dispalyPointHistory, setDispalyPointHistory] = useState(false)
    const [shippingCelebration, setShippingCelebration] = useState(false)
    const [pointHistoryData, setPointHistoryData] = useState({
        totalpoints: 0,
        trasactioncount: 0,
        shippingpoints:0,
    })
    const classes = useStyles();
    const { pathname } = useRouter();
    const { setPointHistoryDrawer } = useUserUpdateContext();
    const isIncludeMainRoute: any = [
        ROUTES.home,
        ROUTES.stores,
        ROUTES.userProfile,
        ROUTES.rewardsWallet,
        ROUTES.shop,
    ]

    const { data, refetch: getPointHistoryData, isFetching }: any =
        getPointHistory({
            rewardsNumber: userInfo?.rewardsNumber, config: {
                enabled:
                    isIncludeMainRoute?.includes?.(pathname) &&
                    !!userInfo?.rewardsNumber,
                onSuccess: (res: any) => {
                    console.log("🚀 ~ PointHitoryCelebrationScreen ~ res:", res, { check: res?.totalpoints > 0 })
                    if (res?.totalpoints > 0 || res?.shippingpoints > 0) {
                        setPointHistoryData(res);
                        setDispalyPointHistory(true);
                        if(res?.totalpoints > 0) {
                            analyticsCustomEvent(eventMetricsMap.screen_view, {
                                firebase_screen: `points_celebration`,
                                firebase_screen_class: `points_celebration`,
                            });
                        }
                        if (res?.shippingpoints > 0) {
                            setShippingCelebration(true);
                            analyticsCustomEvent(eventMetricsMap.screen_view, {
                                firebase_screen: `shipping_celebration`,
                                firebase_screen_class: `shipping_celebration`,
                            });
                        }
                    }
                },
                ...NO_REFETCH,
            },
        });
        

    const {
        refetch,
    } = getLoyaltyAccountDetails({
        rewardsNumber: userInfo?.rewardsNumber,
        config: {
            enabled: !!userInfo?.rewardsNumber,
        },
    });

    const fetchPointHistoryData = () => {
        if (isIncludeMainRoute?.includes(pathname) &&
            document.visibilityState === 'visible') {
            console.log('point history called')
            getPointHistoryData()
        }
    }
    const handleVisibility = (e: any) => {
        fetchPointHistoryData();
        e.preventDefault();
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibility);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibility);
        };
    }, [])

    // useEffect(() => {
    //     if (pathname === ROUTES.rewardsWallet) {
    //         if(pointHistoryData?.totalpoints > 0) {
    //             setDispalyPointHistory(true)
    //         }
    //     }
    // }, [pointHistoryData?.totalpoints, pathname])


    useEffect(() => {
        fetchPointHistoryData();
    }, [pathname, userInfo?.rewardsNumber])

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.POINT_CELEBRATION_SCREEN,
        dispalyPointHistory)

    const closeScreen = () => {
        setDispalyPointHistory(false);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'continue',
            element_location:  'shipping celebration',
        });
        setTimeout(() => {
            setPointHistoryData({
                totalpoints: 0,
                trasactioncount: 0,
                shippingpoints:0,
            })
        }, 2000);
        refetch();
    }
    console.log("🚀 ~ PointHitoryCelebrationScreen ~ isActiveScreen:", isActiveScreen)

    const handlePointHistoryDrawer = () => {
        setDispalyPointHistory(false);
        setTimeout(() => {
            setPointHistoryData({
                totalpoints: 0,
                trasactioncount: 0,
                shippingpoints:0,
            })
        }, 2000);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'points history',
            element_location:  'shipping celebration',
        });
        setPointHistoryDrawer(true);
    }



    return (
        <Dialog
            fullScreen
            open={isActiveScreen}
        >
            <div className={classes.conatiner}>
                <div className={classes.lottieOver}>
                    <Player
                        loop
                        autoplay
                        src={ shippingCelebration ? 
                            require('../assets/lotties/celebration-shipping.json') : 
                            require('../assets/lotties/point-history-celebration.json')}
                        className={classes.lottieOver}
                    />
                </div>
                <Body className={classes.earnedPointText} size={26} weight="light" margin="0 0 15px" >
                    You’ve earned
                </Body>
                <Body size={26} weight="bold" className={classes.pointsAlign}>
                    { shippingCelebration ? 
                        pointHistoryData?.shippingpoints 
                            && numberWithCommas?.(pointHistoryData?.shippingpoints?.toString()) :
                        pointHistoryData?.totalpoints 
                        && numberWithCommas?.(pointHistoryData?.totalpoints?.toString())
                      }
                   
                </Body>
                <Body className={classes.earnedPointText} size={26} weight="light" >
                    points
                </Body>
                <Body className={classes.dsc} size={16} weight="light">
                        {shippingCelebration ? "for shipping packages at your Staples® store." : 
                    "Great job! Your points are now ready to redeem."}
                </Body>
                <Button className={classes.btn} onClick={closeScreen}>
                    Continue
                </Button>
                <Button variant='text' fontWeight="normal" className={classes.history} fontSize={14}
                    color="textLinkBlue"
                    onClick={handlePointHistoryDrawer}>
                    Points history
                </Button>
            </div>
        </Dialog>
    );
}

// export default PointHitoryCelebrationScreen;

const WrappedPointHitoryCelebrationScreen = () => <PointHitoryCelebrationScreen />;
export default WrappedPointHitoryCelebrationScreen;