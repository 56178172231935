/* eslint-disable no-console */
/* eslint-disable quotes */
import React, { useState } from 'react';
import { Dialog, TextField } from '@material-ui/core';
import { getStores, validateZipCode } from 'src/lib/api-client/staples-stores';
import Button from 'src/components/UI/Button';
import {
    useGeolocation,
    useGuestMode,
    useRouter,
    useStoreData,
} from 'src/hooks';
import ROUTES from 'src/lib/routes';
import { Store } from 'src/services/types';
import LoadingOverlay from '../loading-overlay';
import { Heading } from '../UI/Typography';
import styles from './styles.module.scss';
import { IconRightBackArrow } from '../Icons';
import StoreSelection from '../StoreSelection';

type ZipCodeModalProps = {
    isShowing: boolean;
    setShowZipcodeDialog: (flag: boolean) => void;
    requestPermission: (flag: boolean) => void;
};

export default function ZipCodeDialog({
    isShowing,
    requestPermission,
    setShowZipcodeDialog,
}: ZipCodeModalProps) {
    const [zipCode, setZipCode] = useState<string | null>(null);
    const [zipCodeError, setZipCodeError] = useState<string | null>(null);
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
    const [mainStoreResult, setMainStoreResult] = useState({});
    const [nearbyStores, setNearbyStores] = useState<Store[]>([]);
    const [isLoading, setLoader] = useState<boolean>(false);
    const { isGuestModeOn } = useGuestMode();
    const { push } = useRouter();
    console.log(
        '🚀 ~ file: ZipcodeDialog.tsx:24 ~ ZipCodeDialog ~ storesData:',
        mainStoreResult
    );
    const { saveZipcode, detectAndSaveZipcode, isDetectingLocation } =
        useGeolocation();

    const { updateMainStore } = useStoreData();

    function handleZipOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        setZipCodeError(null);
        if (event.target.value?.length <= 5) {
            setZipCode(event.target.value);
        }
    }

    const handleChangeZipCode = (e: any) => {
        e.preventDefault();
        if (zipCode) {
            setLoader(true);
            validateZipCode(zipCode).then(async (data) => {
                if (data?.isValidZipCode) {
                    const stores = await getStores(zipCode);
                    if (stores?.length) {
                        const mainStoreId = stores[0]?.id;
                        const addeitionalStore = stores.filter(
                            (s) => s.id !== mainStoreId
                        );
                        setMainStoreResult(stores[0]);
                        setNearbyStores(addeitionalStore);
                        setShowSearchResult(true);
                    } else if (stores?.length === 0) {
                        setZipCodeError(
                            'No stores found within 250 miles of this zip code'
                        );
                    }
                    setLoader(false);

                    // saveZipcode(zipCode);
                } else {
                    setLoader(false);
                    setZipCodeError('Enter a valid zip code.');
                }
                return false;
            });
        }
    };

    const handleSetStore = (selectedStoreData: Store) => {
        updateMainStore(selectedStoreData);
        if (isGuestModeOn) {
            setShowZipcodeDialog(false);
            push(ROUTES.home);
        }
    };

    const showStoreResult = (stores: any) => {
        console.log(
            '🚀 ~ file: ZipcodeDialog.tsx:67 ~ showStoreResult ~ stores:',
            stores
        );
        // setShowSearchResult(stores)

        if (stores?.length) {
            const mainStoreId = stores[0]?.id;
            const addeitionalStore = stores.filter(
                (s: any) => s.id !== mainStoreId
            );
            setMainStoreResult(stores[0]);
            setNearbyStores(addeitionalStore);
            setShowSearchResult(true);
        } else if (stores?.length === 0) {
            setZipCodeError(
                'No stores found within 250 miles of this zip code'
            );
        }
    };

    const handleUseMyLocation = async () => {
        const result = await navigator.permissions.query({
            name: 'geolocation',
        });
        console.log('🚀 ~ requestPermission ~ result:', result);
        if (result.state === 'denied') {
            requestPermission(true);
        } else {
            requestPermission(false);
            detectAndSaveZipcode({ showStoreResult });
        }
    };

    return (
        <>
            {showSearchResult ? (
                <StoreSelection
                    open={showSearchResult}
                    onClose={() => setShowSearchResult(false)}
                    handleSetStore={handleSetStore}
                    nearbyStores={nearbyStores}
                    mainStoreResult={mainStoreResult}
                />
            ) : (
                <Dialog fullScreen open={isShowing}>
                    <section className={styles.container}>
                        <LoadingOverlay
                            variant="wordmark"
                            theme="transparent-light"
                            open={isDetectingLocation}
                        />
                        <form
                            className={styles.zipCodeForm}
                            autoComplete="off"
                            onSubmit={handleChangeZipCode}
                        >
                            <div className={styles.alignSelfStart}>
                                <Heading
                                    type="h4"
                                    size={26}
                                    weight="bold"
                                    className={styles.title}
                                    color="#000000"
                                >
                                    Enter your location
                                </Heading>
                            </div>
                            <TextField
                                id="zipcode-modal-input"
                                name="zipCode"
                                placeholder="Zip Code"
                                value={zipCode}
                                fullWidth
                                onChange={handleZipOnChange}
                                error={!!zipCodeError}
                                helperText={zipCodeError}
                                inputMode="numeric"
                                type="number"
                                style={{ marginBottom: '0.85rem' }}
                            />
                            <Button
                                variant="contained-rounded"
                                bgColor="bgBlack"
                                onClick={handleChangeZipCode}
                                disabled={!zipCode}
                                className={styles.btnAlign}
                            >
                                Search
                            </Button>
                            <Button
                                variant="text"
                                color="textDarkgray"
                                decoration="textUnderline"
                                fontSize={14}
                                onClick={handleUseMyLocation}
                                className={styles.currentLocationText}
                            >
                                Use my current location
                            </Button>
                        </form>
                    </section>
                </Dialog>
            )}
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
        </>
    );
}
