import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import {
    Box,
    Dialog,
    DialogProps,
    makeStyles,
    Typography,
    Fade,
    ButtonBase,
    CircularProgress,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { GUTTER_WIDTH_SLIM } from 'src/theme';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import differenceInDays from 'date-fns/differenceInDays';
import { getRewardsActivitiesPaginated } from 'src/lib/api-client/rewards-activities';
import { DonationActivity } from 'src/services/types';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { GroupedVirtuoso, ListRange } from 'react-virtuoso';
import { sumArray } from 'src/lib/utils/array-utils';
import ActivityCard, { ActivityPosition } from './activity-card';
import LoadingOverlay from '../loading-overlay';
import FullWidthCard from '../cards/full-width-card';

export type AllRecentActivityDialogProps = DialogProps;

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        justifyContent: 'center',
        height: '100%',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 600,
        marginTop: '0.4rem',
        letterSpacing: '-0.6pt',
    },
    paper: {
        width: '100%',
        backgroundColor: COLORS.whiteSmoke,
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    backIcon: {
        fontSize: '1.7rem',
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    titleWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        marginLeft: '-3rem',
    },
    sectionTitle: {
        fontWeight: 600,
        fontSize: '1.1rem',
        marginLeft: GUTTER_WIDTH_SLIM,
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    bottomSpace: {
        width: '100%',
        height: '5rem',
    },
    noActivityText: {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 500,
        padding: '1rem',
    },
    loadingSpinner: {
        color: COLORS.gray80,
    },
    loadingNextPageWrapper: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

/**
 * Recent Activity dialog to show all activities
 */
export default function AllRecentActivityDialog(
    props: AllRecentActivityDialogProps
) {
    const { onClose, open, ...rest } = props;
    const classes = useStyles();

    const REWARD_ACTIVITY_PAGE_SIZE = 5;
    // pageParam is the product offset (handled inside searchProducts)
    const paginatedRewardActivities = ({ pageParam = 1 }) =>
        getRewardsActivitiesPaginated({
            paginationPage: pageParam,
            paginationLimit: REWARD_ACTIVITY_PAGE_SIZE,
        });

    const {
        data: allActivityInfiniteData,
        isFetching: isAllActivityFetching,
        isFetched: isAllActivityFetched,
        isFetchingNextPage: isAllActivityFetchingNextPage,
        hasNextPage: allActivityHasNextPage,
        fetchNextPage,
    } = useInfiniteQuery(['allActivities'], paginatedRewardActivities, {
        ...NO_REFETCH,
        enabled: open,
        getNextPageParam: (lastPage) =>
            lastPage && lastPage?.paginationCount >= lastPage?.paginationLimit
                ? Number.parseInt(lastPage?.paginationPage, 10) + 1
                : undefined, // returning undefined sets hasNextPage to false
    });

    const allActivityDataFlattened =
        allActivityInfiniteData?.pages.map((p) => p.donationActivity).flat() ||
        [];

    function loadMore() {
        // wrapping this function to ensure we do not call fetchNextPage with any parameters
        // see https://react-query.tanstack.com/guides/infinite-queries
        if (
            isAllActivityFetched &&
            !isAllActivityFetching &&
            !isAllActivityFetchingNextPage
        ) {
            fetchNextPage();
        }
    }

    const {
        groupCounts,
        totalItems,
        lastThirtyDayCount,
        olderThanThirtyDaysCount,
    } = useMemo(() => {
        let lastThirtyDays = 0;
        let olderThanThirtyDays = 0;
        allActivityDataFlattened.forEach((item: DonationActivity) => {
            const daysDiff = differenceInDays(
                new Date(Date.now()),
                Date.parse(item.activityDate)
            );

            if (daysDiff <= 30) {
                lastThirtyDays += 1;
            } else {
                olderThanThirtyDays += 1;
            }
        });

        const groups: number[] = [];
        if (lastThirtyDays) {
            groups.push(lastThirtyDays);
        }

        groups.push(olderThanThirtyDays);

        return {
            groupCounts: groups,
            totalItems: sumArray(allActivityDataFlattened),
            lastThirtyDayCount: lastThirtyDays,
            olderThanThirtyDaysCount: olderThanThirtyDays,
        };
    }, [allActivityInfiniteData]);

    function onRangeChanged(range: ListRange) {
        // don't run this on first page load, as loadmore is also called (due to endReached)
        // call fetchNextPage early before the end of the list is reached
        if (
            range.endIndex > REWARD_ACTIVITY_PAGE_SIZE &&
            range.endIndex > totalItems - REWARD_ACTIVITY_PAGE_SIZE
        ) {
            fetchNextPage();
        }
    }

    function close(event: any) {
        if (onClose) {
            onClose(event, 'backdropClick');
        }
    }

    const isOpen = useIsActiveScreen(
        SCREEN_NAMES.RECENT_REWARDS_ACTIVITY,
        open
    );

    const noActivty = (lastThirtyDays: boolean) => (
        <FullWidthCard
            style={{ marginLeft: '1rem', marginRight: '1rem' }}
            hasManualShadow
        >
            <Typography className={classes.noActivityText}>
                There is no activity{' '}
                {lastThirtyDays
                    ? 'from the past 30 days'
                    : 'older than 30 days.'}
            </Typography>
        </FullWidthCard>
    );

    if (isAllActivityFetching && !allActivityDataFlattened?.length) {
        return (
            <LoadingOverlay variant="wordmark" theme="transparent-light" open />
        );
    }

    return (
        <Dialog
            fullScreen
            classes={{
                paper: classes.paper,
            }}
            onClose={onClose}
            open={isOpen}
            {...rest}
        >
            <div className={classes.screen}>
                <NavTop
                    marginBottom="-13rem"
                    bgImageSrc={require('../../assets/bgs/bgIllustrationYellowbackground.svg')}
                    bgColor={COLORS.rewardsYellow}
                    minHeight="18rem"
                >
                    <Box className={classes.commonNav}>
                        <Fade in>
                            <ButtonBase
                                onClick={close}
                                className={classes.backButton}
                            >
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                        </Fade>
                        <Box className={classes.titleWrapper}>
                            <Typography className={classes.screenTitle}>
                                My Activity
                            </Typography>
                        </Box>
                    </Box>
                </NavTop>

                <GroupedVirtuoso
                    endReached={loadMore}
                    groupCounts={groupCounts}
                    // group headers require non-zero sized elements or an error will occur
                    groupContent={(index) => {
                        const showLastThirtyDays =
                            index === 0 && lastThirtyDayCount > 0;
                        return (
                            <Typography className={classes.sectionTitle}>
                                {showLastThirtyDays
                                    ? 'Last 30 Days'
                                    : 'Older than 30 Days'}
                            </Typography>
                        );
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    itemContent={(index: number, groupIndex: number) => {
                        const item = allActivityDataFlattened[index];
                        const top = index === 0 || index === groupCounts[0];
                        const bottom =
                            index === allActivityDataFlattened?.length - 1 ||
                            index === groupCounts[0] - 1 ||
                            0;

                        let position: ActivityPosition = 'Middle';
                        if (top) {
                            position = 'Top';
                        } else if (bottom) {
                            position = 'Bottom';
                        }

                        if (item) {
                            return (
                                <div>
                                    <ActivityCard
                                        donationItem={item}
                                        position={position}
                                    />
                                </div>
                            );
                        }

                        // in case we somehow can't get data, don't show anything
                        return (
                            <CircularProgress
                                className={classes.loadingSpinner}
                            />
                        );
                    }}
                    rangeChanged={onRangeChanged}
                    components={{
                        // eslint-disable-next-line react/prop-types
                        TopItemList: ({ children }) => <div>{children}</div>,
                        Header: () => <div />,
                        Footer: () => {
                            const noActivtyContent =
                                olderThanThirtyDaysCount === 0 ? (
                                    noActivty(false)
                                ) : (
                                    <div />
                                );
                            return allActivityHasNextPage ||
                                isAllActivityFetchingNextPage ? (
                                <div className={classes.loadingNextPageWrapper}>
                                    <CircularProgress
                                        className={classes.loadingSpinner}
                                    />
                                </div>
                            ) : (
                                noActivtyContent
                            );
                        },
                    }}
                />

                <div className={classes.bottomSpace} />
            </div>
        </Dialog>
    );
}
