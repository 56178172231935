import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    useRouter,
    useNotifications,
    useEventsReporter,
    eventMetricsMap,
} from 'src/hooks';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { Dialog } from '@material-ui/core';
import { Button, Heading } from 'src/components/UI';
import { mergeClasses } from 'src/stylesheets/utils';
import LoadingOverlay from 'src/components/loading-overlay';
import CoachScreens from '.';
import styles from './styles.module.scss';

function NotificationCoachScreen() {
    const { replace } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const goToUseCaseHandlerScreen = () =>
        replace(REWARDS_ENROLLMENT_ROUTES.userUseCaseHandler);

    const { askNotificationPermission, currentNotificationPermission } =
        useNotifications();

    useLayoutEffect(() => {
        console.log(
            '273 getNotificationPermissionStatus()',
            currentNotificationPermission
        );
        if (currentNotificationPermission !== 'default') {
            goToUseCaseHandlerScreen();
        }
    }, [currentNotificationPermission]);

    useEffect(() => {
        // @Screen name userinfo_stay in the know_enable notifications
        analyticsCustomEvent(eventMetricsMap.screen_view, {
            firebase_screen: `userinfo_stay in the know_enable notifications`,
            firebase_screen_class: `userinfo_stay in the know_enable notifications`,
        });
    }, []);

    if (currentNotificationPermission !== 'default') {
        return (
            <LoadingOverlay
                id="loadingOverlay"
                variant="wordmark"
                theme="transparent-light"
                open
            />
        );
    }

    return (
        <>
            <CoachScreens
                title="Stay in the know"
                subtitle="We’ll update you on order status, limited-time deals, local store events and more."
                imageSrc={require('../../assets/lotties/graphic_notification.json')}
                primaryButtonText="Enable notifications"
                primaryButtonOnClick={() => {
                    try {
                        askNotificationPermission();
                    } catch {
                        setShowErrorDialog(true);
                    }
                }}
                secondaryButtonText="Maybe later"
                secondaryButtonOnClick={() => {
                    analyticsCustomEvent(eventMetricsMap.account_creation, {
                        click_text: 'close - push notifications',
                        element_location: 'pop-up',
                    });
                    replace(REWARDS_ENROLLMENT_ROUTES.userUseCaseHandler)
                }
                    
                }
                background="#FFFFFF"
            />
            <Dialog open={showErrorDialog}>
                <div
                    className={mergeClasses(
                        styles.p4,
                        styles.flex,
                        styles.flexCol,
                        styles.rowGap4,
                        styles.alignCenter
                    )}
                >
                    <div>
                        <Heading type="h3" weight="bold">
                            Sorry, we are not able to enable push messages at
                            this time.
                        </Heading>
                    </div>
                    <Button
                        bgColor="bgBlack"
                        onClick={() => {
                            setShowErrorDialog(false);
                            goToUseCaseHandlerScreen();
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </Dialog>
        </>
    );
}

export default NotificationCoachScreen;
