import React, { useState } from 'react';
import clsx from 'clsx';
import {
  ButtonBase,
  Collapse,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { PendingFilters } from './types';
import { rangeFromAlternatives, rangeFromPendingFilters } from './filter-utils';

const useExpandingRowStyles = makeStyles((theme) => ({
  titleAndExpander: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem',
    width: '100%',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'left',
    flexGrow: 1,
  },
  rightText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'right',
    flexShrink: 1,
    color: COLORS.brandCyan,
    paddingRight: '.5rem',
  },
  expand: {
    transform: 'rotate(90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(270deg)',
  },
}));

export default function ExpandingRow({
  expanded,
  onExpandClicked,
  title,
  children,
  filter,
  pendingFilters,
  rightTextVariant,
}: {
  expanded: boolean;
  onExpandClicked: () => void;
  title: string;
  children: any;
  filter?: any;
  pendingFilters?: PendingFilters;
  rightTextVariant?: 'range';
}) {
  let rightText = '';
  if (rightTextVariant === 'range') {
    const baseRange = rangeFromAlternatives(filter);
    const range = rangeFromPendingFilters(
      filter,
      pendingFilters || [],
      baseRange
    );
    rightText = `$${range.low} - $${range.high}`;
  }
  const classes = useExpandingRowStyles();
  return (
    <div>
      <ButtonBase
        className={classes.titleAndExpander}
        onClick={onExpandClicked}
      >
        <Typography className={classes.title}>{title}</Typography>

        <Typography className={classes.rightText}>{rightText}</Typography>
        <div
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        >
          <img src={require('../../../assets/icons/iconChevron.svg')} alt="expand or collapse" />
        </div>
      </ButtonBase>
      <Collapse in={expanded}>{children}</Collapse>
      {!expanded && <Divider />}
    </div>
  );
}

export type ExpandableSections = { [key: string]: boolean };
export function isSectionExpanded(s: ExpandableSections, k: string) {
  return s && s[k];
}

export function useExpandableSections(
  initialOpenSections?: ExpandableSections
) {
  const [openSections, setOpenSections] = useState<ExpandableSections>(
    initialOpenSections || {}
  );
  function toggleSection(key: string) {
    setOpenSections((prev: ExpandableSections) => ({
      ...prev,
      [key]: !((prev as any)[key] as boolean),
    }));
  }
  return { openSections, setOpenSections, toggleSection };
}
