import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import { getTransactionDetails } from 'src/lib/api-client/purchase-history';
import useUserInfo from 'src/hooks/useUserInfo';
import COLORS from 'src/lib/colors';
import ProductDetails from './ProductDetails';
import PurchaseSummary from './PurchaseSummary';
import TransactionBarcode from './TransactionBarcode';
import useStyles from './styles';
import ChooseListDialog from '../choose-list-dialog';

const PurchaseDetails = ({
    transactionNumber,
    storeNumber,
    open,
    date,
    transactionType,
    returnOrPurchaseItem,
    checkNumberIsNegativeOrPositive,
    totalCouponAmt,
    handleAutoScroll,
    expandedAc,
    storeAddress,
}: {
    transactionNumber: number;
    storeNumber: number;
    open: boolean;
    date: string;
    transactionType: string;
    returnOrPurchaseItem: string;
    checkNumberIsNegativeOrPositive: (data: any, flag?: boolean) => string;
    totalCouponAmt: number;
    handleAutoScroll: () => void;
    expandedAc: number;
    storeAddress?: string;
}) => {
    // Choose list dialog logic
    const [productSelectedToAddToList, setProductSelectedToAddToList] =
        useState<any>(null);

    const { userInfo } = useUserInfo();
    const rewardsNumber = userInfo?.rewardsNumber;
    const classes = useStyles();
    const storeIdNumber = userInfo?.preferredStoreId || '1';
    const { data: response, isLoading } = useQuery(
        ['transaction-details', transactionNumber],
        () => {
            if (rewardsNumber) {
                return getTransactionDetails(
                    rewardsNumber,
                    transactionNumber,
                    storeNumber
                );
            }
            return null;
        },
        {
            enabled: open && !!rewardsNumber,
        }
    );
    useEffect(() => {
        if (expandedAc === transactionNumber && !isLoading) {
            handleAutoScroll();
        }
    }, [isLoading, open, transactionNumber, expandedAc]);

    const summaryData = useMemo(() => {
        let itemsTotal = 0;
        let itemsCount = 0;
        let couponSavings = 0;
        let rewardsEarned = 0;
        let subTotal = 0;
        if (isLoading || !response) {
            return {
                itemsTotal,
                itemsCount,
                couponSavings,
                rewardsEarned,
                subTotal,
            };
        }
        response?.data?.purchaseTransDetails?.forEach((item: any) => {
            itemsTotal +=
                parseFloat(item?.priceAmt) * parseFloat(item?.qtyOrdered);
            subTotal += parseFloat(item?.totalSpendAmt);
            itemsCount += parseInt(item?.qtyOrdered, 10);
            couponSavings += parseFloat(item?.couponsUsed);
            rewardsEarned += parseFloat(item?.totalRewardsEarned);
        });
        subTotal = parseFloat(subTotal.toFixed(2));
        itemsTotal = parseFloat(itemsTotal.toFixed(2));
        couponSavings = parseFloat(couponSavings.toFixed(2));
        rewardsEarned = parseFloat(rewardsEarned.toFixed(2));
        return {
            itemsTotal,
            itemsCount,
            couponSavings,
            rewardsEarned,
            subTotal,
        };
    }, [isLoading, !!response]);
    if (isLoading || !response)
        return (
            <div className={classes.center}>
                <CircularProgress style={{ color: COLORS.textBlack }} />
            </div>
        );
    const transactionDetailsList = response?.data?.purchaseTransDetails;
    return (
        <div>
            {transactionDetailsList?.map((item: any) => (
                <ProductDetails
                    key={item?.skuNumber}
                    checkNumberIsNegativeOrPositive={
                        checkNumberIsNegativeOrPositive
                    }
                    setProductSelectedToAddToList={() => {
                        setProductSelectedToAddToList({
                            productItem: {
                                product_id: item?.skuNumber,
                                description: item?.skuDesc,
                                store_ids: [storeNumber],
                            },
                            quantity: item?.qtyOrdered,
                            storeId: storeIdNumber,
                        });
                    }}
                    {...item}
                />
            ))}
            <PurchaseSummary
                {...summaryData}
                returnOrPurchaseItem={returnOrPurchaseItem}
                checkNumberIsNegativeOrPositive={
                    checkNumberIsNegativeOrPositive
                }
                totalCouponAmt={totalCouponAmt}
                transactionType={transactionType}
                transactionDate={date}
                transactionNumber={transactionNumber}
                storeAddress={storeAddress}
                storeNumber={storeNumber}
            />
            {transactionType === 'I' ? (
                <div className={classes.productOnlineContainer} />
            ) : (
                <TransactionBarcode
                    store={storeNumber}
                    register={parseInt(transactionNumber.toString()[0], 10)}
                    transaction={parseInt(
                        transactionNumber.toString().substring(1),
                        10
                    )}
                    date={date}
                />
            )}
            <ChooseListDialog
                open={!!productSelectedToAddToList}
                handleClose={() => setProductSelectedToAddToList(null)}
                {...productSelectedToAddToList}
            />
        </div>
    );
};

export default PurchaseDetails;
