import { useQuery, UseQueryResult } from "react-query";
import axiosInstance from "src/lib/api-client/custom-axios";
import { NO_REFETCH } from "src/lib/utils/react-query-utils";
import { configurationBaseURL } from "./urls";

interface ConfigDetail {
    shortdesc: string;
    longdesc: string;
    sortby: number;
}

interface Config {
    shortdesc: string;
    longdesc: string;
    configdetails: ConfigDetail[];
}

export interface ConfigResponse {
    configs: Config[];
    status: string;
    message: string;
    issuccess: boolean;
}

export function getConfigDetails(configName: string): UseQueryResult<ConfigResponse> {
    const queryFunc = async (): Promise<ConfigResponse> =>
       
        (await axiosInstance.get<ConfigResponse>(
            configurationBaseURL.replace('%configName%', `${configName}`)
        )).data;
    return useQuery(['getConfigDetails'], queryFunc, {
        ...NO_REFETCH,
    });
}

export default getConfigDetails;