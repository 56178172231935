import { AlternativesEntity } from 'src/lib/api-client/adept-mind-types';
import {
  PendingFilters,
  Filter,
} from 'src/screens/product-browse/filter/types';

export const getFilter = (filters: Filter[], label: string) =>
  filters.find((f) => f.label === label);

export const hasFilterOption = (filterOptions: any[], label: string) => {
  const filterObj = getFilter(filterOptions, label);
  return (
    filterObj && filterObj.alternatives && filterObj.alternatives.length > 0
  );
};

// for most filters, we're just using the "$in" array for multiselect
// the only current exception to this is price (uses $gt and $lt instead)
export function hasMultiSelectValueInPendingFilters(
  pf: PendingFilters,
  f: Filter,
  v: any
): boolean {
  const filter = pf.find((p) => p.label === f.label);
  return !!(filter?.value?.$in || []).find((inValue) => inValue === v);
}

export function addMultiSelectFilterValueToPendingFilters(
  pf: PendingFilters,
  f: Filter,
  v: any
): PendingFilters {
  const filter = pf.find((p) => p.label === f.label);
  const newIn = (filter?.value?.$in || []).filter((inValue) => inValue !== v);
  const newPf = pf.filter((p) => p.label !== f.label);
  return [...newPf, { label: f.label, value: { $in: [...newIn, v] } }];
}

export function removeMultiSelectFilterValueFromPendingFilters(
  pf: PendingFilters,
  f: Filter,
  v: any
): PendingFilters {
  const filter = pf.find((p) => p.label === f.label);
  const newIn = (filter?.value?.$in || []).filter((inValue) => inValue !== v);
  const newPf = pf.filter((p) => p.label !== f.label);
  if (newIn.length === 0) {
    return newPf;
  }
  return [...newPf, { label: f.label, value: { $in: newIn } }];
}

export function updateNumericRangeFilterValue(
  pf: PendingFilters,
  f: Filter,
  range: { high?: number; low?: number }
): PendingFilters {
  const newPf = pf.filter((p) => p.label !== f.label);
  if (!range?.low && !range?.high) {
    return newPf;
  }
  return [
    ...newPf,
    { label: f.label, value: { $gt: range?.low, $lt: range?.high } },
  ];
}

export type Range = { low?: number; high?: number };
export const rangeFromPendingFilters = (
  filter: Filter,
  pendingFilters: PendingFilters,
  baseRange: Range
) => {
  const range = { ...baseRange };
  const pendingFilter = pendingFilters.find((pf) => pf.label === filter.label);
  range.high = pendingFilter?.value?.$lt || range.high;
  range.low = pendingFilter?.value?.$gt || range.low;
  return range;
};

export const rangeFromAlternatives = (filter: Filter) => {
  const alternatives = filter.alternatives || [];
  return alternatives.reduce(
    (agg, alt: AlternativesEntity) => {
      const newAgg = agg;
      if (alt.value.$gt && newAgg.low > alt.value.$gt) {
        newAgg.low = alt.value.$gt;
      }
      if (alt.value.$lt && newAgg.high < alt.value.$lt) {
        newAgg.high = alt.value.$lt;
      }
      return newAgg;
    },
    { low: Infinity, high: -Infinity }
  );
};
