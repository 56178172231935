import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ShippingServicesAccordion from './shipping-services-accordion';
import ShippingServicesNoData from './shipping-services-no-data';
import ShippingErrorInline from '../shipping-errors/shipping-error-inline';

const useStyles = makeStyles((theme) => ({
     ShippingServicesDeliveredLabelConatiner: {
        padding: '18px 0px !important',
        boxShadow: 'none',
        overflow: 'auto',
        height: 'calc(100vh - 168px)',
    },
}))
const ShippingServicesDeliveredLabel = ({activeTab, shipmentListData, setError, error, updatedData} : any) => {
    const [DeliveredLabelData, setDeliveredLabelData] = useState<any>([]);
    const classes = useStyles();

    useEffect(() => {
        const filterDelivereddLabelData = shipmentListData?.filter((data: any) => {
            // Check if shipment status is "Manifested" or "Created"
            const isManifestedOrCreated = ["Manifested", "Created"].includes(data?.shimpmentstatus?.shipmentstatus);
        
            // Check if all packages have been delivered
            const allPackagesDelivered = data?.shimpmentstatus?.shipmenttrackingstatus?.filter((status: any) =>
                status?.package[0]?.activity?.status?.statusCode === '011')?.length > 0
            return allPackagesDelivered;
        });
        setDeliveredLabelData(filterDelivereddLabelData);
    }, [])
    console.log({DeliveredLabelData, shipmentListData});
    if(error) {
        return <ShippingErrorInline setError={setError}
                                    errorMessage='We couldn’t retrieve your created labels
                                     or current shipment statuses at this time.
                                     Please try again later.' />
    }
    if(DeliveredLabelData?.length === 0) {
        return <ShippingServicesNoData 
            imgData={require('src/assets/icons/shippedLabelsNoShippingIcon.svg')}
            error="You don’t have any delivered packages."
            errorDetails="Create a shipment or visit your nearest Staples® store to ship a package."
        />
    }
    return <div className={classes.ShippingServicesDeliveredLabelConatiner}>
        {DeliveredLabelData?.map((shipCreateData: any, i: any) =>
            <ShippingServicesAccordion
                key={`${shipCreateData}_i`}
                data={shipCreateData}
                updatedData={updatedData}
                activeTab={activeTab}
            />
        )}
    </div>

}

export default ShippingServicesDeliveredLabel;
