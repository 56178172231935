/* eslint-disable max-len */
import * as React from 'react';

const defaultColor = '#221E1F';

function SvgComponent(
    props: React.SVGProps<SVGSVGElement> & { fillColor: string }
) {
    const { fillColor = defaultColor } = props;
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.9539 23.9056H8.72079C8.31203 23.9056 7.97759 23.5703 7.97759 23.1605C7.97759 22.7507 8.31203 22.4154 8.72079 22.4154H20.2107V14.0262C20.2107 13.6164 20.5452 13.2811 20.9539 13.2811H22.4552L20.404 11.2246C20.2628 11.083 20.1884 10.8967 20.1884 10.6955L20.2033 4.66753H18.1V7.11152C18.1 7.40957 17.9217 7.68526 17.6392 7.79703C17.3643 7.91625 17.0447 7.84919 16.8292 7.63311L13.0016 3.78829L3.53323 13.2886H5.0345C5.44326 13.2886 5.7777 13.6239 5.7777 14.0337V23.1679C5.7777 23.5777 5.44326 23.913 5.0345 23.913C4.62574 23.913 4.2913 23.5777 4.2913 23.1679V14.7788H1.74211C1.44482 14.7788 1.16984 14.6 1.05836 14.3168C0.939446 14.0411 1.00633 13.7207 1.22186 13.5047L12.474 2.21608C12.6152 2.07451 12.801 2 13.0016 2C13.2023 2 13.3881 2.08196 13.5293 2.21608L16.6136 5.31578V3.92986C16.6136 3.52004 16.9481 3.18474 17.3568 3.18474H20.9539C21.1546 3.18474 21.3404 3.2667 21.4816 3.40083C21.6228 3.5424 21.6971 3.72868 21.6971 3.92986L21.6823 10.3975L24.7814 13.5047C24.997 13.7207 25.0564 14.0411 24.9449 14.3168C24.8335 14.5925 24.5585 14.7788 24.2612 14.7788H21.7046V23.1679C21.7046 23.5777 21.3701 23.913 20.9614 23.913L20.9539 23.9056Z"
                fill={fillColor}
            />
        </svg>

    );
}

export default SvgComponent;
