import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
    makeStyles,
    Box,
    Typography,
    Collapse,
    Fade,
    ButtonBase,
    Container,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import PaginationDots from 'src/components/pagination-dots';
import COLORS from 'src/lib/colors';
import useRouter from 'src/hooks/useRouter';
import NavTop from 'src/components/nav-top';
import DonationSearchBegin from 'src/screens/donation-flow/donation-search-begin';
import DonationSearchResults from 'src/screens/donation-flow/donation-search-results';
import DonationSuccess from 'src/screens/donation-flow/donation-success';
import DonationSelectRecipient from 'src/screens/donation-flow/donation-select-recipient';
import DonationSetAmount from 'src/screens/donation-flow/donation-set-amount';
import { CommonStepProps, DonateStep } from 'src/screens/donation-flow/types';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        background: COLORS.rewardsYellow,
        minHeight: '100vh',
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    backIcon: {
        fontSize: '1.7rem',
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    closeIcon: {
        width: '2rem',
        height: '2rem',
        margin: '2rem 1.25rem 1.5rem 0rem',
        fontSize: '1.7rem',
    },
    backIconBalance: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
        color: 'transparent',
    },
    paginationWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        paddingTop: '.5rem',
    },
    stepDescription: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 2,
        margin: '0rem 2rem',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    screenDescription: {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 600,
        padding: '0rem 4rem 1rem',
        letterSpacing: '-0.30pt',
        lineHeight: '1.3rem',
    },
});

/**
 * Additional cleanup might be possible, as of now we are including every possible step inside of every step.
 * We could alternatively build the next copmponent as we are generating the next step instead.
 */
const Step = (props: CommonStepProps) => {
    const { isFinished, donateStep, updateControllerValues } = props;
    if (isFinished) {
        return <DonationSuccess {...props} />;
    }
    switch (donateStep) {
        case 'selectRecipient':
            return (
                <DonationSelectRecipient
                    updateControllerValues={updateControllerValues}
                />
            );
        case 'setAmount':
            return <DonationSetAmount {...props} />;
        case 'success':
            return <DonationSuccess {...props} />;
        case 'searchResults':
            return <DonationSearchResults {...props} />;
        case 'search':
        default:
            return <DonationSearchBegin {...props} />;
    }
};

type StepDescriptionProps = {
    currentStep?: DonateStep;
    isFinished?: boolean;
    recipientName?: string;
    isEnterAmount?: boolean;
};

const StepDescription = ({
    currentStep,
    isFinished,
    recipientName,
    isEnterAmount,
}: StepDescriptionProps) => {
    const classes = useStyles();
    let topText: { title: string; description?: string } = { title: '' };
    if (isFinished) {
        topText = { title: 'Success!' };
    } else {
        switch (currentStep) {
            case 'selectRecipient':
                topText = {
                    title: 'Choose the school or a teacher.',
                };
                break;
            case 'setAmount':
                topText = {
                    title: isEnterAmount
                        ? `Please enter amount to give to ${recipientName}.`
                        : `How much would you like to give to ${recipientName}?`,
                };
                break;
            case 'success':
                topText = {
                    title: "You've made a difference!",
                };
                break;
            case 'searchResults':
                topText = { title: 'Select your school' };
                break;
            case 'search':
            default:
                topText = { title: "Let's find your school" };
                break;
        }
    }

    return (
        <>
            <Typography className={classes.screenTitle}>
                {topText.title}
            </Typography>
            <Collapse in={!!topText.description}>
                <Typography
                    className={classes.screenDescription}
                    style={{ minHeight: '1rem' }}
                >
                    {topText.description}
                </Typography>
            </Collapse>
        </>
    );
};

/** Renders and animates the list of steps as they are added by the flow controller */
const Steps = (props: CommonStepProps) => {
    const {
        donateStep: currentStep,
        isFinished,
        finishFlow,
        donateAmount,
        recipientName,
        recipientType,
        recipientSchool,
        ...rest
    } = props;

    const classes = useStyles();
    const { history } = useRouter();
    const { customEvent } = useEventsReporter();
    const [isEnterAmount, setEnterAmount] = useState(false);

    const [isFetching, setIsFetching] = useState<boolean | undefined>(false);
    function back() {
        if (isFetching || currentStep === 'search') {
            return;
        }
        history.goBack();
    }

    const [steps, setSteps] = useState<DonateStep[]>(['search']); // always start on search
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        if (currentStep) {
            if (steps.indexOf(currentStep) < 0) {
                // TODO: smarter cleanup of old steps
                // like when we go back to the create new account step,
                // then go back and link an account
                setSteps((oldSteps) => [...oldSteps, currentStep]);
            } else if (activeIndex !== steps.indexOf(currentStep)) {
                setActiveIndex(steps.indexOf(currentStep));
            }
        }
    }, [activeIndex, currentStep, steps]);

    useEffect(() => {
        const element = document.getElementById('donation-flow-screens');
        element?.scrollIntoView();
    }, [activeIndex]);
    function onTransitionEnd() {
        if (activeIndex === 0) {
            setSteps(['search']);
        }

        // for some reason the SwipeableViews ref property does not point to the div that actually scrolls
        // falling back to querying for id instead
        const scrollingElement = document.querySelector(
            '#reward-donation-swipeable'
        );
        if (scrollingElement) {
            scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const handleChangeIndex = (index: number) => {
        setActiveIndex(index);
    };

    function handleClose() {
        customEvent(eventMetricsMap.give_close);
        finishFlow();
    }

    const hideBackButton =
        (activeIndex > 0 && currentStep === 'success') ||
        currentStep === 'search';
    const hidePaginationDots = activeIndex > 0 && currentStep !== 'success';

    return (
        <Box className={classes.screen} id="donation-flow-screens">
            <NavTop
                minHeight="16rem"
                marginBottom="-12rem"
                bgColor={COLORS.rewardsYellow}
                bgImageSrc={require('../../assets/bgs/bgIllustrationYellowbackground.svg')}
            >
                <Box className={classes.commonNav}>
                    <Fade in={!hideBackButton}>
                        <ButtonBase
                            onClick={back}
                            className={classes.backButton}
                        >
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                    </Fade>
                    <Box className={classes.paginationWrapper}>
                        <Fade in={hidePaginationDots}>
                            <div>
                                <PaginationDots
                                    dots={4}
                                    disabled
                                    index={activeIndex}
                                    onChangeIndex={handleChangeIndex}
                                    activeColor={COLORS.homeBlack}
                                    inActiveColor={COLORS.primaryWhite}
                                />
                            </div>
                        </Fade>
                    </Box>
                    <ButtonBase
                        onClick={handleClose}
                        className={classes.closeIcon}
                        id="donation-flow-close-button"
                    >
                        <img
                            src={require('../../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </ButtonBase>
                </Box>
            </NavTop>
            <Box className={classes.stepDescription}>
                <StepDescription
                    currentStep={currentStep}
                    isFinished={isFinished}
                    recipientName={
                        recipientType === 'school'
                            ? recipientSchool
                            : recipientName
                    }
                    isEnterAmount={isEnterAmount}
                />
            </Box>
            <SwipeableViews
                disabled
                id="reward-donation-swipeable"
                enableMouseEvents={false}
                onTransitionEnd={onTransitionEnd}
                index={activeIndex}
                style={{ flexGrow: 1, minHeight: 'calc(100vh - 12rem)' }}
                containerStyle={{
                    flexGrow: 1,
                    minHeight: 'calc(100vh - 12rem)',
                }}
            >
                {steps.map((s) => (
                    <Container key={s} maxWidth="md">
                        <Step
                            donateStep={s}
                            isFinished={isFinished}
                            updateIsFetching={setIsFetching}
                            donateAmount={donateAmount}
                            finishFlow={finishFlow}
                            handleGoBack={back}
                            setEnterAmount={setEnterAmount}
                            {...rest}
                        />
                    </Container>
                ))}
            </SwipeableViews>
        </Box>
    );
};

export default Steps;
