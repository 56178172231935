import React, { useEffect } from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import useRouter from 'src/hooks/useRouter';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import ROUTES from 'src/lib/routes';

const useStyles = makeStyles({
  root: {
    padding: '0px',
  },
  enrollFormCard: {
    background: COLORS.primaryWhite,
    boxShadow: 'none',
    margin: '0',
    justifyContent: 'center',
    padding: '0',
  },
  screenDescription: {
    textAlign: 'center',
    fontSize: '0.9rem',
    letterSpacing: '-0.30pt',
    lineHeight: '1.3rem',
    padding: '0px 10px',
  },
  vectorContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vectorContainerImage: (heightOfHeader) => ({
    height: `calc(100vh - ${heightOfHeader}px)`,
  }),
  primaryButton: {
    width: '274px',
    margin: '11px 0 9px 0',
    height: '2.5rem',
    borderRadius: '1.885rem',
    background: COLORS.homeBlack,
    color: COLORS.whiteSmoke,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: COLORS.homeBlack,
    },
  },
  secondaryButton: {
    width: '274px',
    margin: '13px 0 20px 0',
    height: '2.5rem',
    border: 'none',
    background: 'none',
    color: COLORS.primaryWhite,
    textTransform: 'none',
    fontSize: '1rem',
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: COLORS.brandRed,
    },
  },
  screenTitle: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 800,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: '0px',
    marginBottom: 70,
  },
  screenTitleContainer: {
    marging: '0px 30px',
  },
});

export function SRWConfirmation() {
  const router = useRouter();
  const { history } = useRouter();
  const { classroomWalletRewardsTab, notifyUserUpdate,
    classroomWalletRewardsTabWithoutRewardsScreen } = useUserUpdateContext();
  const heightOfHeader = document?.getElementById?.('srw-confirmation-header')?.clientHeight;
  const classes = useStyles(heightOfHeader);
  useEffect(() => {
    notifyUserUpdate();
  }, []);
  const goToCR = () => {
    // classroomWalletRewardsTabWithoutRewardsScreen();
    history.push(ROUTES.home);
  };
  const goToLtr = () => {
    if (router.pathname === ROUTES.stores) {
      history.push({
        pathname: ROUTES.stores,
        search: '',
      });
    } else {
      history.push({
        pathname: ROUTES.rewardsWallet,
        search: '',
      });
    }
  };
  return (
    <FullWidthCard
      hasManualShadow
      className={classes.enrollFormCard}
      id="classroom-srw-number-card"
    >
      <div id="srw-confirmation-header" className={classes.screenTitleContainer}>
        <Typography className={classes.screenTitle} id="user-type-title">
          You’re reaching new heights!
        </Typography>
        <Typography
          className={classes.screenDescription}
          style={{ minHeight: '1rem' }}
        >
          Now you can view your Staples Rewards in the Staples Connect Wallet and
          track your recycling activity in the Stores section of this app.
        </Typography>
      </div>
      <div className={classes.vectorContainer}>
        <img src={require('../../assets/icons/srwVector.svg')} alt="classroom srw vector icon"
          className={classes.vectorContainerImage} />
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={goToCR}
            classes={{
              root: classes.primaryButton,
            }}
          >
            Continue
          </Button>
          {/* <Button
            variant="contained"
            onClick={goToLtr}
            classes={{
              root: classes.secondaryButton,
            }}
          >
            I'll do it later
          </Button> */}
        </Box>
      </div>
    </FullWidthCard>
  );
}

export default SRWConfirmation;