import React, { useState, useEffect, ReactNode } from 'react';
import {
    Box,
    ButtonBase,
    Snackbar,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface IAlertMessage {
    open: boolean;
    handleClose: () => void;
    btnName: string;
    message: string;
    handleBtnClick: () => void;
    status: string;
    icon: ReactNode;
}

const useStyles = makeStyles({
    alertContainer: {
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        height: '40px',
        borderRadius: '30px',
        '& .MuiAlert-icon': {
            display: 'none',
        },
        '& .MuiAlert-action': {
            display: 'none',
        },
        '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
    },
    messageAlign: {
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
    },
    btn: {
        color: '#086DD2',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        marginLeft: 'auto',
    },
    icon: {
        height: '35px',
        width: '35px',
        marginLeft: '-13px',
        marginRight: '10px',
    },
});
const AlertMessage = ({
    open,
    handleClose,
    btnName,
    message,
    handleBtnClick,
    status,
    icon,
}: IAlertMessage) => {
    const classes = useStyles();
    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            style={{ bottom: '115px' }}
        >
            <Alert
                onClose={handleClose}
                className={classes.alertContainer}
                style={{ background: '#000000' }}
            >
                <div className={classes.icon}>{icon}</div>
                <div className={classes.messageAlign}>{message}</div>
                <ButtonBase onClick={handleBtnClick} className={classes.btn}>
                    {btnName}
                </ButtonBase>
            </Alert>
        </Snackbar>
    );
};

export default AlertMessage;
