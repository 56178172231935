import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import EnrollmentCompleteScreen from 'src/screens/RewardsEnrollment/EnrollmentCompleteScreen';
import FavoriteSelectionScreen from 'src/screens/RewardsEnrollment/FavoriteSelectionScreen';
import FavoriteSelectionIntroScreen from 'src/screens/RewardsEnrollment/FavoriteSelectionScreen/FavoriteSelectionIntroScreen';
import MissingInfoFormScreen from 'src/screens/RewardsEnrollment/MissingInfoFormScreen';
import MissingInfoConfirmationScreen from 'src/screens/RewardsEnrollment/MissingInfoConfirmationScreen';
import PersonalizePointsScreen from 'src/screens/RewardsEnrollment/PersonalizePointsScreen';
import RewardsEnrollmentConfirmationScreen from 'src/screens/RewardsEnrollment/RewardsEnrollmentConfirmationScreen';
import WelcomeScreen from 'src/screens/RewardsEnrollment/WelcomeScreen';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserState,
} from 'src/hooks';
import WelcomeAnimation from 'src/screens/RewardsEnrollment/WelcomeScreen/WelcomeAnimation';
import NewAccountIntroScreen from 'src/screens/RewardsEnrollment/NewAccountIntroScreen';
import UserUseCaseHandler from 'src/screens/UserUseCaseHandler';
import { REWARDS_ENROLLMENT_ROUTES } from './routes';

function RewardsEnrollmentRouter() {
    const { pathname } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [{ userType }] = useUserState();
    const isUserNonmigrated = userType === 'non-migrated';

    useEffect(() => {
        if (pathname === REWARDS_ENROLLMENT_ROUTES.welcomeAnimation) {
            // @screen name enrollflow_start easy rewards animation
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_start easy rewards animation `,
                firebase_screen_class: `enrollflow_start easy rewards animation `,
            });
        } else if (pathname === REWARDS_ENROLLMENT_ROUTES.welcome) {
            // @screen name enrollflow_new account easy rewards intro
            if (isUserNonmigrated) {
                analyticsCustomEvent(eventMetricsMap.screen_view, {
                    firebase_screen: `enrollflow_nonmigrated easy rewards enroll`,
                    firebase_screen_class: `enrollflow_nonmigrated easy rewards enroll`,
                });
            } else {
                analyticsCustomEvent(eventMetricsMap.screen_view, {
                    firebase_screen: `enrollflow_new account easy rewards intro`,
                    firebase_screen_class: `enrollflow_new account easy rewards intro`,
                });
            }
        } else if (pathname === REWARDS_ENROLLMENT_ROUTES.peronalize) {
            // @screen name enrollflow_easy rewards personalization
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_easy rewards personalization`,
                firebase_screen_class: `enrollflow_easy rewards personalization`,
            });
        } else if (pathname === REWARDS_ENROLLMENT_ROUTES.favoritesIntro) {
            // @screen name enrollflow_confirmed personalization animation
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_confirmed personalization animation`,
                firebase_screen_class: `enrollflow_confirmed personalization animation`,
            });
        } else if (pathname === REWARDS_ENROLLMENT_ROUTES.favorites) {
            // @screen name enrollflow_easy rewards bonus point categories selection
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_easy rewards bonus point categories selection`,
                firebase_screen_class: `enrollflow_easy rewards bonus point categories selection`,
            });
        } else if (pathname === REWARDS_ENROLLMENT_ROUTES.finish) {
            // @screen name enrollflow_you’re ready to earn
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_you’re ready to earn`,
                firebase_screen_class: `enrollflow_you’re ready to earn`,
            });
        } else if (
            pathname === REWARDS_ENROLLMENT_ROUTES.enrollmentConfirmation
        ) {
            // @screen name enrollflow_migrated account accept terms conditions
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_migrated account accept terms conditions`,
                firebase_screen_class: `enrollflow_migrated account accept terms conditions`,
            });

            analyticsCustomEvent(eventMetricsMap.login_flow, {
                click_text: `sign in`,
                element_location: `Migrated flow`,
            });
        } else if (pathname === REWARDS_ENROLLMENT_ROUTES.missingInfo) {
            // @screen name enrollflow_please complete your profile
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_please complete your profile`,
                firebase_screen_class: `enrollflow_please complete your profile`,
            });
        } else if (
            pathname === REWARDS_ENROLLMENT_ROUTES.missingInfoConfimation
        ) {
            // @screen name enrollflow_we need more profile information
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_we need more profile information`,
                firebase_screen_class: `enrollflow_we need more profile information`,
            });
        } else if (pathname === REWARDS_ENROLLMENT_ROUTES.newAccountIntro) {
            // @screen name enrollflow_account created animation
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `enrollflow_account created animation`,
                firebase_screen_class: `enrollflow_account created animation`,
            });
        }
    }, [pathname]);

    return (
        <Switch>
            <Route path={REWARDS_ENROLLMENT_ROUTES.userUseCaseHandler}>
                <UserUseCaseHandler />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.enrollmentConfirmation}>
                <RewardsEnrollmentConfirmationScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.missingInfo}>
                <MissingInfoFormScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.missingInfoConfimation}>
                <MissingInfoConfirmationScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.newAccountIntro}>
                <NewAccountIntroScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.welcomeAnimation}>
                <WelcomeAnimation />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.welcome}>
                <WelcomeScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.peronalize}>
                <PersonalizePointsScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.favoritesIntro}>
                <FavoriteSelectionIntroScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.favorites}>
                <FavoriteSelectionScreen />
            </Route>
            <Route path={REWARDS_ENROLLMENT_ROUTES.finish}>
                <EnrollmentCompleteScreen />
            </Route>
        </Switch>
    );
}

export default RewardsEnrollmentRouter;
