/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        background: ' #F8F7F7',
        borderRadius: '15px',
        minHeight: '50px',
    },
    details: {
        height: '100%',
        padding: '0px 15px',
        justifyContent: 'flex-start',
    },
    icon: {
        background: '#EFEEEE',
        height: '100%',
        justifyContent: 'center',
    },
    grid: {
        display: 'flex',
        alignItems: 'center',
    },
}));

type InfoIconCardProps = {
    children: ReactNode;
    icon: ReactNode;
};

export default function InfoIconCard({ children, icon }: InfoIconCardProps) {
    const classes = useStyles();
    return (
        <Card className={classes.root} elevation={0}>
            <Grid container>
                <Grid item xs={2} className={clsx(classes.grid, classes.icon)}>
                    {icon}
                </Grid>
                <Grid
                    item
                    xs={10}
                    className={clsx(classes.grid, classes.details)}
                >
                    {children}
                </Grid>
            </Grid>
        </Card>
    );
}
