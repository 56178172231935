import { ButtonBase, Typography } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import InfoButton from 'src/components/info-button';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import useStyles from './styles';

interface PurchaseSummaryProps {
  itemsTotal: number;
  itemsCount: number;
  couponSavings: number;
  rewardsEarned: number;
  subTotal: number;
  returnOrPurchaseItem: string;
  checkNumberIsNegativeOrPositive: (data: any, flag?: boolean) => string;
  totalCouponAmt: number;
  transactionType: string;
  transactionNumber: number;
  transactionDate: string;
  storeAddress?: string;
  storeNumber?: number;
}
const helpCenterURL = 'https://www.staplesconnect.com/help';

const PurchaseSummary = ({
  itemsTotal,
  itemsCount,
  couponSavings,
  rewardsEarned,
  subTotal,
  returnOrPurchaseItem,
  checkNumberIsNegativeOrPositive,
  totalCouponAmt,
  transactionType,
  transactionNumber,
  transactionDate,
  storeAddress,
  storeNumber,
}: PurchaseSummaryProps) => {
  const classes = useStyles();
  const handleStoreNavigation = () => {
    if(storeNumber) {
      return openInNewTab(`https://stores.staples.com/${storeNumber}`);
    } 
    return openInNewTab(`https://stores.staples.com`);
  }
  return (
    <div style={{ marginTop: '1.938rem' }}>
      <Typography variant="h6" className={classes.purchaseSummaryHeading}>
        Purchase Summary
      </Typography>
      {transactionType === "I" ?
        <div>
          <div className={classes.purchaseSummaryRow} style={{ marginBottom: '15px' }}>
            <Typography variant="body1">Online Order</Typography>
            <Typography variant="body1" style={{ fontSize: '15px' }} >{moment(transactionDate).isValid() ?
              moment(transactionDate).format('MMM DD, YYYY') :
              transactionDate}</Typography>
          </div>
          <div className={classes.purchaseSummaryRow} style={{ marginBottom: '24px' }}>
            <Typography variant="body1">Order Number</Typography>
            <Typography variant="body1">#{transactionNumber}</Typography>
          </div>
        </div> :
        <div>
          <div className={classes.purchaseSummaryRow} style={{ marginBottom: '15px' }}>
            <Typography variant="body1">In-Store Order</Typography>
            <Typography variant="body1" style={{ fontSize: '15px' }}>{moment(transactionDate).isValid() ?
              moment(transactionDate).format('MMM DD, YYYY') :
              transactionDate}</Typography>
          </div>
          <div className={classes.purchaseSummaryRowForStoreAddress} style={{ marginBottom: '24px' }}>
            <Typography variant="body1">Store Address</Typography>
            <ButtonBase
            className={classes.purchaseSummaryStoreAddressAlign}
            onClick={handleStoreNavigation}
            >{storeAddress}</ButtonBase>
          </div>
        </div>
      }
      <div className={classes.purchaseSummaryRow} style={{ marginBottom: '9px' }}>
        <Typography variant="body1">{`Items(${itemsCount})`}</Typography>
        <Typography variant="body1">{checkNumberIsNegativeOrPositive(itemsTotal)}</Typography>
      </div>
      <div className={classes.purchaseSummaryRow} style={{ marginBottom: '17px' }}>
        <Typography variant="body1">Coupon savings</Typography>
        <Typography variant="body1" className={returnOrPurchaseItem === 'return' ?
          classes.grayText : classes.greenText}>
          {checkNumberIsNegativeOrPositive(totalCouponAmt, true)}
        </Typography>
      </div>
      <hr
        style={{
          background: '#D9D9D6',
          margin: '0 0 12px',
          borderColor: '#D9D9D6',
        }}
      />
      <div className={classes.purchaseSummaryRow} style={{ marginBottom: '8px' }}>
        <Typography variant="h6" className={classes.purchaseSummarySubtotal}>
          Subtotal
          <InfoButton
            tooltipTitle='This is the pre-tax total. 
              Please visit the store if you need your final total including tax.'
            tooltipCta='Visit Help Center'
            buttonId='tooltip-button'
            buttonSize='13px'
            id="purchaseSummarySubtotalInfo"
            customizedIcon={require('../../assets/icons/infoBlackIcon.svg')}
            tooltipPlacement='top-end'
            urlLink={helpCenterURL} 
            tooltipClasses={{
              root: classes.tooltipRoot,
              popper: classes.root,
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            tooltipTitleStyle={classes.tooltipTitleStyle}
            tooltipButtonStyle={classes.tooltipButtonStyle}
            flipToolTip
            preventOverflowToottip
            />
        </Typography>
        <Typography variant="body1" style={{ fontSize: '17px' }} className={classes.purchaseSummarySubtotalValue}>
          {checkNumberIsNegativeOrPositive(subTotal)}</Typography>
      </div>
      <div className={classes.purchaseSummaryRow}>
        <Typography variant="body1">Staples Rewards Earned</Typography>
        <Typography variant="body1"
          className={
            classes.staplesRewardsEarnedAligned}>
          <img
            className={classes.coinIconRewards}
            src={require('../../assets/icons/coinIcon.svg')} 
            alt="coin icon"
          /> {checkNumberIsNegativeOrPositive(rewardsEarned)}
        </Typography>
      </div>
    </div>
  );
};

export default PurchaseSummary;
