/* eslint-disable max-len */
import React from 'react';
import IconInterface from './IconInterface';

function IconScanShipping({ height = 20, width = 20 }: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_450_1464)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.3225 0.7395C12.9705 0.2765 12.4205 0.001 11.834 0H4.4165L4.2995 0.0035C3.7175 0.04 3.1825 0.346 2.8565 0.835L0.5465 4.302C0.209 4.641 0 5.1085 0 5.625V14.3745L0.0025 14.474C0.0545 15.463 0.8725 16.2495 1.875 16.2495H6.875L6.939 16.246C7.254 16.214 7.5 15.948 7.5 15.6245C7.5 15.2795 7.22 14.9995 6.875 14.9995H1.875L1.811 14.996C1.496 14.964 1.25 14.698 1.25 14.3745V5.625L1.253 5.561C1.268 5.411 1.3365 5.2765 1.439 5.177C1.4475 5.1695 1.4555 5.162 1.4635 5.1545C1.5735 5.0585 1.717 5 1.8745 5H14.3745L14.4385 5.003C14.5705 5.0165 14.6905 5.071 14.785 5.1535C14.7935 5.1615 14.802 5.1695 14.8105 5.177C14.927 5.2905 14.9995 5.449 14.9995 5.6245V6.8745L15.003 6.9385C15.035 7.2535 15.301 7.4995 15.6245 7.4995C15.9695 7.4995 16.2495 7.2195 16.2495 6.8745V5.6245L16.247 5.525C16.222 5.048 16.019 4.6185 15.703 4.3015L13.3905 0.835L13.323 0.74L13.3225 0.7395ZM4.4165 1.25H7.5V3.75H2.416L3.8965 1.528L3.938 1.4725C4.056 1.332 4.2305 1.25 4.4165 1.25ZM8.75 1.25V3.75H13.8325L12.3505 1.5285C12.2475 1.3745 12.0825 1.2745 11.9015 1.254L11.833 1.25H8.75ZM16.5855 14.9995H14.3755C14.0305 14.9995 13.7505 14.7195 13.7505 14.3745V12.1655C13.7505 11.8205 14.0305 11.5405 14.3755 11.5405C14.7205 11.5405 15.0005 11.8205 15.0005 12.1655V13.7495H16.5855C16.9305 13.7495 17.2105 14.0295 17.2105 14.3745C17.2105 14.7195 16.9305 14.9995 16.5855 14.9995ZM14.3755 9.9995C11.963 9.9995 10.0005 11.962 10.0005 14.3745C10.0005 16.787 11.963 18.7495 14.3755 18.7495C16.788 18.7495 18.7505 16.787 18.7505 14.3745C18.7505 11.962 16.787 9.9995 14.3755 9.9995ZM14.3755 19.9995C11.274 19.9995 8.7505 17.476 8.7505 14.3745C8.7505 11.273 11.274 8.7495 14.3755 8.7495C17.477 8.7495 20.0005 11.273 20.0005 14.3745C20.0005 17.476 17.4765 19.9995 14.3755 19.9995Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_450_1464">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconScanShipping;
