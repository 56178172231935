import { useEffect, useRef } from 'react';

import * as PDF417 from 'pdf417-generator';
import useInterval from 'src/hooks/useInterval';

function useCardCanvas(cardNumber?: string | null) {
    const canvasRef = useRef<any>();
    const cardRedraw = () => {
        if (canvasRef?.current && cardNumber) {
            PDF417.draw(cardNumber, canvasRef.current, 2);
        }
    };
    useEffect(() => {
        cardRedraw();
    }, [cardNumber, canvasRef?.current]);
    useInterval(cardRedraw, 100);

    return { canvasRef, cardRedraw };
}

export default useCardCanvas;
