import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, ButtonBase, Dialog, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { eventMetricsMap, useEventsReporter, useRouter, useUserInfo } from 'src/hooks';
import ROUTES from 'src/lib/routes';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import ShippingPrivacyTermsHome from
    'src/components/shipping-services/shipping-privacy-terms/shipping-privacy-terms-home';
import clsx from 'clsx';
import useConfigInfo from 'src/hooks/useConfigInfo';
import COLORS from '../../../lib/colors';
import Brcd from '../../BarcodeScanner/Brcd';
import { addShipmentTracking } from '../../../lib/api-client/shipment-services';
import LoadingOverlay from '../../loading-overlay';
import ShippingServicesIntro from './shipping-services-intro';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        padding: '0 !important',
    },
    shippingServicesContainer: {
        display: 'flex',
        cursor: 'pointer',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        padding: '20px 20px 15px 18px',
        margin: '1.5rem',
    },
    shippingServicesSubContainer: {
        marginTop: "100px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    },
    shippingServicesCTATopText: {
        fontSize: '40px',
        fontFamily: 'StaplesNormsBold',
        lineHeight: '34px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
    },
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    shippingServicesCTARightImage: {
        margin: '24px 0 20px',
        width: '150px',
    },
    iconBack: {
        position: 'absolute',
        left: '14px',
        top: '18px',
        zIndex: 1,
    },
    shortText1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '26px',
        letterSpacing: '0px',
        color: '#1E1E1E',
        marginBottom: '40px',
    },
    saveTimeAlign: {
        fontSize: '18px',
        textAlign: "center",
        fontFamily: 'StaplesNormsRegular',
        lineHeight: '22px',
        letterSpacing: '-0.46pxpx',
        color: '#1E1E1E',
        margin: '24px 50px',
    },
    imageContainer: {
        position: 'relative',
        // marginRight: '1.25rem',
        display: 'flex',
        alignItems: 'center',
    },
    shippingServiceContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: "#FFFFFF",
        height: '100vh',
        overflowY: 'auto',
    },
    shippingLogo: {
        position: 'fixed',
        width: '100px',
        height: 'auto',
        top: '0',
        right: '0',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    savedLabels: {
        fontFamily: "StaplesNormsMedium",
        height: '40px',
        width: '200px',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            color: '#086DD2',
            textAlign: 'center',
        },
    },
    tileButtonContainer: {
        display: 'flex',
    },
    tileButton: {
        fontFamily: 'StaplesNormsMedium',
        fontSize: '16px',
        background: COLORS.brandRed,
        height: '50px',
        width: '300px',
        borderRadius: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        color: COLORS.primaryWhite,
        fontWeight: 700,
        '& img': {
            width: '54px',
        },
    },
    startShipmentTile: {
        borderRadius: '30px',
        background: `${COLORS.brandRed}`,
        border: `2px solid ${COLORS.brandRed}`,
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '10px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    privacyLink: {
        bottom: '0px',
        position: 'absolute',
        height: '40px',
        alignSelf: 'center',
        textDecoration: 'underline',
    },

}));

const ShippingServicesHome = ({ goBack }: { goBack: () => void }) => {
    const classes = useStyles();
    const { history, query } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [showTermsPrivacy, setShowTermsPrivacy] = useState<boolean>(false);
    const privacyPolicyURL = 'https://www.staplesconnect.com/privacypolicy';
    const { updateFetchSenderInfo, updateReviewStage, openScanToTrack, updateOpenScanToTrack, updateShippingTab } =
        useContext(ShippingInfoContext);
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);
    const [scannerData, setScannerData] = useState('');
    const [isScanCompleted, setIsScanCompleted] = useState(false);
    const [trackingError, setTrackingError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isIntroShow, setIsIntroShow] = useState(false);

    const { isRewardShipperEnable } = useUserInfo();
    const {shortdesc: shippingConfigPoints} = useConfigInfo('ShippingOfferPoints');

    const [scanMessage, setScanMessage] = useState({
        title: 'Scan successful!',
        description: 'Visit Labels and tracking to view its current ' +
            'status or scan another package.',
    })


    useEffect(() => {
      if(history?.location?.state) {
        updateFetchSenderInfo(true);
        updateReviewStage(false)
        if(sessionStorage.getItem('startShipment')) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=createShipmentSenderInfo',
            });
        }else {
            // sessionStorage.setItem('startShipment', 'true');
            setIsIntroShow(true);
        }
      }
    }, [])
    

    const handleStartShipment = () => {
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'main shipping page',
        });
        updateFetchSenderInfo(true);
        updateReviewStage(false)
        if(sessionStorage.getItem('startShipment')) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=createShipmentSenderInfo',
            });
        }else {
            // sessionStorage.setItem('startShipment', 'true');
            setIsIntroShow(true);
        }
    }

    useEffect(() => {
        if (openScanToTrack) {
            setIsBarcodeScannerOpen(true);
            updateOpenScanToTrack(false);
        }
    }, [openScanToTrack])
    const handleScan = () => {
        setIsBarcodeScannerOpen(true);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'scan to track',
            element_location: 'main shipping page',
        });
    }

    useEffect(() => {
        if (query?.barcode) {
            setScannerData(query.barcode)
        }

    }, [query?.barcode])

    const getShipmentTrackingDetails = async (trackingnumber: any) => {
        setIsLoading(true);
        const response = await addShipmentTracking(trackingnumber);
        setScannerData('')
        setIsLoading(false);
        if (response?.issuccess) {
            setTrackingError(true);
            if (response?.status === "SUCCESS") {
                setTrackingError(false);
                setIsScanCompleted(true);
                setScanMessage(
                    {
                        title: 'Scan successful!',
                        description: 'Visit Labels and tracking to view its current ' +
                            'status or scan another package.',
                    }
                )
            }
            else if (response?.status === "Fail") {
                setScanMessage({
                    title: 'Your package couldn’t be found',
                    description: 'We couldn’t find your package at this time. Please try again later.',
                });
            }
            else if (response?.status === "DUPLICATE_TRACKING") {
                setScanMessage({
                    title: 'Duplicate label',
                    description: 'This label has been previously scanned ' +
                        'and is already in the system.',
                });
            }
            else if (response?.status === "INVALID_TRACKING") {
                setScanMessage({
                    title: `Your package couldn't be found`,
                    description: 'Make sure you have a UPS label and ' +
                        'shipment number to start tracking.',
                });
            }
            else if (response?.status === "NONUS_TRACKING") {
                setScanMessage({
                    title: 'Want to ship internationally?',
                    description: 'Bring your package to your local Staples store ' +
                        'and we’ll ship it anywhere in the world.',
                });
            }
            else {
                setScanMessage({
                    title: 'Label couldn’t be scanned',
                    description: 'We couldn’t scan your label at this time. Please try again later.',
                });
            }
            setIsScanCompleted(true);
            setScannerData('')
        }
        else {
            setTrackingError(true);
            setIsScanCompleted(true);
            setScanMessage({
                title: 'Your package couldn’t be found',
                description: 'We couldn’t find your package at this time. Please try again later.',
            });
        }

    }
    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData)
        }
    }, [scannerData]);

    const handleClose = () => {
        setIsScanCompleted(false);
        setTrackingError(false);
        setScannerData('');
    }
    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state)
        }
        history.push(ROUTES.shippingServicesLabelShipment)
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'labels and tracking',
            element_location: 'main shipping page',
        });

    }
    const handlePrivacyPolicy = () => {
        setShowTermsPrivacy(true);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'privacy & terms',
            element_location: 'main shipping page',
        });
    }
    const handleShippingGetQuote = () => {
        history.push({
            pathname: ROUTES.getQuoteShippingInformation,
            search: '?step=getEstimator',
        })
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'get a quote',
            element_location: 'main shipping page',
        });
    }
    if (isLoading) {
        return <LoadingOverlay
            variant="wordmark"
            theme="transparent-light"
            open={isLoading} />
    }
    const handleGoBack = () => {
        if (isIntroShow) {
            setIsIntroShow(false)
        } else if (query?.backpath) {
            history.push(query.backpath)
        } else {
            goBack();
        }
    }
    return (
        <div className={classes.shippingServiceContainer}>
            <ButtonBase onClick={handleGoBack} className={classes.iconBack}>
                <img
                    src={require('src/assets/icons/iconBackArrow.svg')}
                    alt="iconBack"
                />
            </ButtonBase>
            {isIntroShow ? (
                <ShippingServicesIntro />
            ) : (
                <>
                <div className={classes.shippingServicesSubContainer}>
                <div className={classes.imageContainer}>
                    <img
                        className={classes.shippingServicesCTARightImage}
                        src={require('src/assets/icons/img_shipping_icon.svg')}
                        alt="shipping services UPS"
                    />
                </div>

                <Typography className={classes.shippingServicesCTATopText}>
                    Shipping services
                </Typography>
                <Typography className={classes.saveTimeAlign}>
                    Save time by prepping your label for faster shipping in store.
                </Typography>

                <div className={classes.tileButtonContainer}>
                    <ButtonBase className={clsx(classes.tileButton, classes.startShipmentTile)}
                        onClick={handleStartShipment}>
                        <div>Start a shipment</div>
                    </ButtonBase>

                </div>

                <ButtonBase className={classes.savedLabels}
                    onClick={handleScan}>
                    <img
                        src={require('src/assets/icons/icon_scan_to_track.svg')}
                        alt="scan to track"
                    />
                    <span style={{ margin: '5px' }}>Scan to track</span>
                </ButtonBase>

                <ButtonBase className={classes.savedLabels}
                    onClick={() => handleSavedLabelShipment('create')}>
                    <img
                        src={require('src/assets/icons/icon_labels_and_tracking.svg')}
                        alt="iconBack" />
                    <span style={{ margin: '5px' }}>Labels and tracking</span>
                </ButtonBase>

                <ButtonBase className={classes.savedLabels}
                    onClick={handleShippingGetQuote}>
                    <img
                        src={require('src/assets/icons/icon_get_a_quote.svg')}
                        alt="iconBack" />
                    <span style={{ margin: '5px' }}>Get a quote</span>
                </ButtonBase>


                {isBarcodeScannerOpen && (
                    <Brcd isScannerOpen={isBarcodeScannerOpen}
                        shippingScan
                        setScannerData={setScannerData}
                        onClose={() => setIsBarcodeScannerOpen(false)}
                    />
                )}

                {isScanCompleted && (
                    <Dialog open={isScanCompleted}
                        classes={{
                            paper: classes.paper,
                        }}
                    >
                        <div className={classes.closeButton}>
                            <Button
                                onClick={handleClose}
                                className={classes.closeIcon}
                                id="close-button"
                            >
                                <img src={require('../../../assets/icons/iconCloseBlack.svg')} alt="close icon" />
                            </Button>
                        </div>
                        <div className={classes.dialogWrapper}>
                            <Typography variant='h3' className={classes.dialogTitle} >
                                {scanMessage.title}
                            </Typography>
                            {
                                !trackingError && <img
                                    className={classes.successIcon}
                                    src={require('../../../assets/icons/icon_success.svg')}
                                    alt="scan successful"
                                />
                            }


                            <Typography className={classes.dialogMessage}>
                                {scanMessage.description}
                            </Typography>
                            <ButtonBase className={classes.startShipment}
                                onClick={() => handleSavedLabelShipment('shipped')}>
                                <span>Go to labels and tracking</span>
                            </ButtonBase>

                            <ButtonBase className={clsx(classes.linkCta, classes.anotherPackage)} onClick={handleScan}>
                                <span>Scan another package </span>
                            </ButtonBase>
                        </div>
                    </Dialog>
                )}

            </div>
            <ButtonBase className={classes.privacyLink}
                onClick={handlePrivacyPolicy}>
                <span style={{ margin: '5px' }}>Privacy & Terms</span>
            </ButtonBase>
            {
                showTermsPrivacy ?
                    <ShippingPrivacyTermsHome showDrawer={showTermsPrivacy}
                        setShowTermsPrivacy={setShowTermsPrivacy} /> :
                    null
            }
                </>
            )}
            
            
        </div>
    );
}

export default ShippingServicesHome;
