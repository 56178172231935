import React from 'react';
import COLORS from 'src/lib/colors';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
    makeStyles,
    Typography,
    ButtonBase,
} from '@material-ui/core';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        padding: '15px',
        alignItems: 'center',
        '& #common-dialog-title': {
            marginTop: '10px',
            paddingBottom: '8px',
        },
    },
    titleWrapper: {
        padding: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '1rem',
        fontWeight: 700,
        letterSpacing: '-0.5pt',
        alignSelf: 'center',
        textAlign: 'center',
    },
    titlePadding: { marginTop: '2rem' },
    dialogContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeIcon: {
        justifyContent: 'flex-end',
        position: 'absolute',
        width: '24px',
        height: '24px',
        top: '10px',
        right: '0px',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        margin: '0',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
        fontSize: '0.9rem',
    },
    actionsWrapper: {
        flexDirection: 'column',
        width: '100%',
    },
    primaryButton: {
        width: '90%',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: `${COLORS.brandRed} !important`,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
    },
    vericalGap: {
        width: '100%',
        height: '.2rem',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
    },
    backLink: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
    },
});

export type deleteStatusLabel = {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
    message: string;
    title: string;
    buttonLabel: string;
};

const DeleteStatusDialog = (props: deleteStatusLabel) => {
    const {
        open,
        onClose,
        message,
        title,
        buttonLabel,
        onDelete,
    } = props;
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="common-dialog-title"
            classes={{
                paper: classes.paper,
            }}
        >
            <Button
                fullWidth
                onClick={onClose}
                className={classes.closeIcon}
                id="close-button"
            >
                <img src={require('../../../assets/icons/iconCloseBlack.svg')} alt="close icon" />
            </Button>
            <DialogTitle
                disableTypography
                id="common-dialog-title"
            >

                <Typography
                    className={classes.title}
                    id="common-dialog-title-text"
                >
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContentWrapper}>
                <DialogContentText className={classes.dialogContentText}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.actionsWrapper}>
                <Button
                    variant="contained"
                    onClick={onDelete}
                    classes={{
                        root: classes.primaryButton,
                    }}
                >
                    {buttonLabel}
                </Button>
                <div className={classes.actions}>
                    <ButtonBase className={classes.backLink} onClick={onClose}>
                        <span>Go back</span>
                    </ButtonBase>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteStatusDialog; 