import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from 'src/components/loading-button';
import ValidationTextField from 'src/components/validation-text-field';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';

const useStyles = makeStyles({
  paper: { borderRadius: '1rem', margin: '1rem' },
  titleWrapper: {
    padding: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: '-0.5pt',
    alignSelf: 'center',
    marginTop: '-1.6rem',
    marginBottom: '-.5rem',
  },
  titlePadding: { marginTop: '2rem' },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    justifyContent: 'flex-end',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialogContentText: {
    color: COLORS.secondaryNeutralCharcoal,
    textAlign: 'center',
    marginTop: '1rem',
    letterSpacing: '-0.5pt',
    lineHeight: '1.2rem',
  },
  actionsWrapper: {
    flexDirection: 'column',
  },
  primaryButton: {
    width: '90%',
    margin: '2.5rem 5% 1rem 5%',
    height: '2.5rem',
    borderRadius: '1.885rem',
    background: COLORS.homeBlack,
    color: COLORS.whiteSmoke,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: COLORS.homeBlack,
    },
    '&:active': {
      backgroundColor: COLORS.homeBlack,
    },
  },
});

export type ChangeLocationDialogProps = {
  open: boolean;
  onComplete: (zipCode: string) => void;
  handleClose: () => void;
};

// USA zip codes only
const zipCodeRegExp = /^\d{5}(?:[-\s]\d{4})?$/;

const validationSchema = yup.object({
  zipCode: yup
    .string()
    .min(5, 'Zip code is too short.')
    .max(5, 'Zip code is too long.')
    .required('Zip code is a required field.')
    .matches(zipCodeRegExp, 'Enter a valid zip code.'),
});

export default function ChangeLocationDialog(props: ChangeLocationDialogProps) {
  const { open, handleClose, onComplete } = props;
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      zipCode: '',
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      onComplete(values.zipCode);
      setSubmitting(false);
      handleClose();
    },
  });

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldError,
  } = formik;

  function handleZipChange(e: React.ChangeEvent<HTMLInputElement>) {
    // We are wrapping this to omit type="number"
    // which causes formik to remove leading zeros
    handleChange({ target: { value: e.target.value, name: e.target.name } });
  }
  const clearError = (e: any) => {
    const fieldName = e.type === 'focus' ? e.target.name : '';
    setFieldError(fieldName, '');
  };

  function onDoneButtonPressed(e: any) {
    if (isValid) {
      handleSubmit(e);
    }
  }

  useIsActiveScreen(SCREEN_NAMES.CHANGE_ZIP_CODE_DIALOG, open);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="list-create-edit-dialog-title"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle
          disableTypography
          id="list-create-edit-dialog-title"
          className={classes.titleWrapper}
        >
          <Button
            fullWidth
            onClick={handleClose}
            className={classes.closeIcon}
            id="close-button"
          >
            <img src={require('../assets/icons/iconCloseBlack.svg')} alt="close icon" />
          </Button>
          <Typography className={classes.title}>Change Zip Code</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContentWrapper}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <ValidationTextField
              fullWidth
              id="zipCode"
              name="zipCode"
              label="Change Zip Code"
              onChange={handleZipChange}
              onFocus={(e) => clearError(e)}
              value={values.zipCode}
              error={touched.zipCode && Boolean(errors.zipCode)}
              helperText={touched.zipCode && errors.zipCode}
              inputMode="numeric"
              type="number"
            />
            <LoadingButton
              fullWidth
              id="import-list-change-location-done-btn"
              variant="contained"
              classes={{
                root: classes.primaryButton,
              }}
              type="submit"
              onClick={(e) => onDoneButtonPressed(e)}
              loading={isSubmitting}
            >
              Done
            </LoadingButton>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
