import axiosInstance from 'src/lib/api-client/custom-axios';

export const getLoyaltyDetails = async (url: string, loyaltyPayload: any): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance
        .post(url, loyaltyPayload)
        return dataRes?.data;
        
    } catch (e: any) {
        return { error: e };
    }
};

export default getLoyaltyDetails;