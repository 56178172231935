import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginScreen from 'src/screens/Login';
import CreateAccountScreen from 'src/screens/Login/CreateAccountScreen';
import AccountLookupScreen from 'src/screens/Login/AccountLookupScreen';
import ForgotPassword from 'src/components/login-flow/forgot-password';
import OnboardingScreens from 'src/screens/Login/OnboardingScreens';
import PasswordReset from 'src/components/login-flow/pwd-reset';
import PasswordResetEmail from 'src/components/login-flow/pwd-reset-email';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import FirstTimeUserScreen from 'src/screens/Login/FirstTimeUserScreen';
// import CoachScreens from 'src/screens/CoachScreens';
// import { guestTokenLogin } from 'src/lib/api-client/guest-api';
// import ROUTES from 'src/lib/routes';
import { COACH_SCREENS_ROUTES, LOGIN_ROUTES } from './routes';

function LoginRouter() {
    const { push, pathname } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();

    useEffect(() => {
        if (pathname === COACH_SCREENS_ROUTES.initial) {
            console.log(
                '🚀 ~ useEffect ~ COACH_SCREENS_ROUTES.initial:',
                COACH_SCREENS_ROUTES.initial
            );
            // @Screen namewelcome_all in one place
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `welcome_all in one place`,
                firebase_screen_class: `welcome_all in one place`,
            });
        } else if (pathname === LOGIN_ROUTES.lookup) {
            // @Screen name login_lets see if you have an account
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `login_lets see if you have an account`,
                firebase_screen_class: `login_lets see if you have an account`,
            });
        } else if (pathname === '/create') {
            // @Screen name  login_create an account
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `login_create an account`,
                firebase_screen_class: `login_create an account`,
            });
        } else if (pathname === LOGIN_ROUTES.lookup) {
            // @Screen name login_lets see if you have an account
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `login_lets see if you have an account`,
                firebase_screen_class: `login_lets see if you have an account`,
            });
        } else if (pathname === '/login') {
            // @Screen name login_sign in
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `login_sign in`,
                firebase_screen_class: `login_sign in`,
            });
        }
    }, [pathname]);

    return (
        <Switch>
            <Route exact path={COACH_SCREENS_ROUTES.initial}>
                {/* <CoachScreens
                    title="All in one place"
                    subtitle="Now you can place orders, access your rewards, unlock exclusive deals and more."
                    imageSrc={require('../assets/lotties/graphic_benefits.json')}
                    primaryButtonText="Sign in or sign up"
                    primaryButtonOnClick={() => {
                        push(LOGIN_ROUTES.login)
                        analyticsCustomEvent(eventMetricsMap.sign_in_page, {
                            click_text: 'Sign in or sign up',
                            element_location: `app first page`,
                        });
                    }}
                    secondaryButtonText="Continue as guest"
                    background="#FFFFFF"
                    isQRScreenDisplay
                /> */}
                <FirstTimeUserScreen />
            </Route>
            <Route path={LOGIN_ROUTES.onboarding}>
                <OnboardingScreens />
            </Route>
            <Route path={LOGIN_ROUTES.forgotPassword}>
                <ForgotPassword />
            </Route>
            {/* <Route path={LOGIN_ROUTES.lookup}>
                <AccountLookupScreen />
            </Route> */}
            <Route path={LOGIN_ROUTES.login}>
                <LoginScreen />
            </Route>
            <Route path={LOGIN_ROUTES.create}>
                <CreateAccountScreen />
            </Route>
            <Route path={LOGIN_ROUTES.passwordReset}>
                <PasswordReset />
            </Route>
            <Route path={LOGIN_ROUTES.passwordReseteEmail}>
                <PasswordResetEmail />
            </Route>
        </Switch>
    );
}

export default LoginRouter;
