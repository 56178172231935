import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { searchSchools } from 'src/lib/api-client/locations';
import { SchoolResult } from 'src/services/types';
import Log from 'src/lib/logging';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';

type SchoolSearchOpts = {
    /** The string we're searching for */
    partialAddress?: string;
};

/**
 * Returns stores based on the passed search param.
 * @param opts.partialAddress the search string
 */
export default function useSchoolSearch(opts: SchoolSearchOpts = {}) {
    const { partialAddress } = opts;
    const { userInfo } = useLocalUserInfo();

    const {
        data: schoolsData,
        error: schoolsError,
        isFetching,
    } = useQuery(
        ['schoolSearch', partialAddress, userInfo?.zipCode],
        async () => searchSchools(partialAddress),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );

    const [schools, setSchools] = useState<SchoolResult[]>(
        (schoolsData as SchoolResult[]) || []
    );

    useEffect(() => {
        if (schoolsError) {
            Log.log('error fetching stores');
            Log.log(schoolsError);
        } else if (schoolsData) {
            // Limit the results displayed to the user by 20
            const filteredSchools = schoolsData.slice(0, 20);
            setSchools(filteredSchools as SchoolResult[]);
        }
    }, [schoolsData, schoolsError]);

    function clearSchools() {
        setSchools([]);
    }

    return {
        isFetching,
        error: schoolsError,
        schools,
        clearSchools,
    };
}
