/* eslint-disable no-console */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { Box, Dialog, Typography, Button } from '@material-ui/core';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';
import Log from 'src/lib/logging';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { getStores } from 'src/lib/api-client/staples-stores';
import { COACH_SCREENS_ROUTES } from 'src/routers/routes';
import { useRouter, useUserInfo, useStoreData, useEventsReporter, eventMetricsMap } from 'src/hooks';
import ZipCodeDialog from './ZipcodeDialog';

/**
 * We require a zip code from the user at all times.
 * The user must enter a zip code manually before continuing.
 */

const clearCacheData = async () => {
    await caches?.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });

        return true;
    });
    await new Promise((r) => setTimeout(r, 500));
    return true;
};

interface ZipCodeDialogContainerProps {
    showZipcodeDialog: boolean;
    setShowZipcodeDialog: (newValue: boolean) => void;
    requestPermission: (flag: boolean) => void;
}

function ZipCodeDialogContainer({
    showZipcodeDialog,
    setShowZipcodeDialog,
    requestPermission,
}: ZipCodeDialogContainerProps) {
    const { notifyUserUpdate } = useUserUpdateContext();
    const { userInfo: localUserInfo, patchLocalUserInfo } = useLocalUserInfo();
    useStoreData(); // updates the user storeId as a side effect
    const [zipCodeError, setZipcodeError] = useState<any>(null);
    const { analyticsCustomEvent } = useEventsReporter();

    function handleErrorClose() {
        setZipcodeError(null);
    }
    const { userInfo } = useUserInfo({
        onSetUserInfoComplete: (data, err) => {
            if (err) {
                setZipcodeError(err);
                Log.axios('error on patching user', err);
            }
            setShowZipcodeDialog(false);
            // we may want another error message here... for now assume the patch call succeeds
            notifyUserUpdate();
        },
    });

    const inferredZipCode =
        userInfo?.currentLocationZipCode || localUserInfo?.zipCode;

    useEffect(() => {
        if (
            userInfo?.currentLocationZipCode &&
            localUserInfo?.zipCode !== userInfo?.currentLocationZipCode
        ) {
            // add zipcode to local userinfo so that we have it before any initial user fetch
            patchLocalUserInfo({
                zipCode: userInfo?.currentLocationZipCode,
            });
        }
    }, [userInfo?.currentLocationZipCode]);

    useEffect(() => {
        (async () => {
            await clearCacheData();
        })();
    }, []);

    useEffect(() => {
        if (showZipcodeDialog) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `userinfo_set your store manually search`,
                firebase_screen_class: `userinfo_set your store manually search`,
            });
        }
    }, [showZipcodeDialog])

    return (
        <>
            {showZipcodeDialog && (
                <ZipCodeDialog isShowing={showZipcodeDialog} setShowZipcodeDialog={setShowZipcodeDialog}
                    requestPermission={requestPermission} />
            )}
            <Dialog
                open={!!inferredZipCode && !!zipCodeError}
                onClose={handleErrorClose}
            >
                <Box
                    p={1}
                    style={{
                        display: 'flex',
                        alignContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography>
                        There was an error saving your information.
                    </Typography>
                    <Button onClick={handleErrorClose}>Try again</Button>
                </Box>
            </Dialog>
        </>
    );
}

export default ZipCodeDialogContainer;