import React from 'react';
import { DonationActivity } from 'src/services/types';
import { getDonationText, iconforActivity } from 'src/lib/utils/type-utils';
import {
    fmtDollarsAndCents,
    formatCommasWithDecimal,
} from 'src/lib/utils/string-utils';
import { format, formatDistanceToNow, isAfter, isBefore } from 'date-fns';
import { makeStyles, Typography } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import clsx from 'clsx';

export type ActivityPosition = 'Top' | 'Middle' | 'Bottom';
type ActivityCardProps = {
    donationItem: DonationActivity;
    position?: ActivityPosition;
};

const activityDescription = (d: DonationActivity) => {
    const { dollars, cents } = fmtDollarsAndCents(d.pointsAmount);
    let toReturn = `You gave $${dollars}.${cents} of your Classroom Rewards to ${d.sourceOrDestination}.`;
    if (d.donationType === 'Credit') {
        toReturn = `A supporter gave you $${dollars}.${cents} of Classroom Rewards!`;
    } else if (d.donationType === 'BackendAdjustment') {
        toReturn = `Staples has gifted you $${dollars}.${cents} of Classroom Rewards!`;
    } else if (d.donationType === 'Earned') {
        toReturn = `You earned $${dollars}.${cents} in Classroom Rewards!`;
    }
    return toReturn;
};

const dateDescription = (d: DonationActivity) => {
    const activityDate = Date.parse(d.activityDate);
    const twentyThreeHoursAgo = new Date(Date.now());
    twentyThreeHoursAgo.setHours(twentyThreeHoursAgo.getHours() - 23);

    const lessThanMinute = new Date(Date.now());
    lessThanMinute.setSeconds(lessThanMinute.getSeconds() - 30);

    if (isBefore(activityDate, twentyThreeHoursAgo)) {
        // if older than 23 hours ago, format as '5.1.21 • 1:30PM'
        return format(activityDate, 'M.d.yy • h:mmaa');
    }

    if (isAfter(activityDate, lessThanMinute)) {
        // if less than 1 minute ago, don't replace anything
        return formatDistanceToNow(activityDate, { addSuffix: true });
    }

    // otherwise format as '2h ago'
    return formatDistanceToNow(activityDate, { addSuffix: true })
        .replace('about ', '')
        .replace(' hours', 'h')
        .replace(' hour', 'h')
        .replace(' minutes', 'm')
        .replace(' minute', 'm');
};

const useStyles = makeStyles({
    activityWrapper: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: `${COLORS.secondaryNeutralLightGray} solid 1px`,
        backgroundColor: COLORS.primaryWhite,
        paddingTop: '0.5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',

        marginLeft: '1rem',
        marginRight: '1rem',
    },
    activityWrapperTop: {
        marginTop: '1rem',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
    },
    activityWrapperBottom: {
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
    },
    icon: {
        width: '3.7rem',
    },
    rightContent: {
        paddingLeft: '1rem',
        paddingBottom: '1rem',
    },
    amountText: {
        paddingTop: '1rem',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        letterSpacing: '-0.4pt',
    },
    descriptionText: {
        fontSize: '0.9rem',
        letterSpacing: '-0.4pt',
    },
    dateText: {
        paddingTop: '.35rem',
        fontSize: '0.85rem',
        letterSpacing: '0',
    },
});

/** Renders and animates the list of steps as they are added by the flow controller */
const ActivityCard = (props: ActivityCardProps) => {
    const { donationItem, position = 'Middle' } = props;
    const classes = useStyles();
    return (
        <div
            key={donationItem.id}
            className={clsx(
                position === 'Top' && classes.activityWrapperTop,
                position === 'Bottom' && classes.activityWrapperBottom,
                classes.activityWrapper
            )}
        >
            <img
                src={iconforActivity(donationItem.donationType)}
                alt="activity icon"
                className={classes.icon}
            />
            <div className={classes.rightContent}>
                <Typography className={classes.amountText}>
                    ${formatCommasWithDecimal(donationItem.pointsAmount / 100)}{' '}
                    {getDonationText(donationItem.donationType)}
                </Typography>
                <Typography className={classes.descriptionText}>
                    {activityDescription(donationItem)}
                </Typography>
                <Typography className={classes.dateText}>
                    {dateDescription(donationItem)}
                </Typography>
            </div>
        </div>
    );
};

export default ActivityCard;
