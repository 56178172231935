/* eslint-disable max-len */
import React from 'react';

function IconGreenDollar() {
    return (
        <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.52 26.32C2.88 25.68 0.64 22.96 0.64 22.96L3.84 19.76C3.84 19.76 5.76 22.16 9.12 22.16C11.68 22.16 12.8 21.04 12.8 19.6C12.8 15.76 1.6 17.52 1.6 9.68C1.6 6.48 3.84 4.24 7.52 3.28V0.4H11.68V3.28C15.04 3.76 17.28 6.16 17.28 6.16L14.08 9.36C14.08 9.36 12.48 7.44 9.92 7.44C7.52 7.44 6.56 8.4 6.56 9.68C6.56 13.52 17.76 11.76 17.76 19.6C17.76 22.96 15.2 25.52 11.68 26.32V29.2H7.52V26.32Z" fill="#4CCC65" />
        </svg>
    );
}

export default IconGreenDollar;
