/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { ReactNode } from 'react';
import { IconSolidCheckmark } from 'src/components/Icons';
import styles from './styles.module.scss';

interface SelectableCardProps {
    disabled?: boolean;
    selected?: boolean;
    onSelect?: React.MouseEventHandler<HTMLButtonElement>;
    containerClassname?: string;
    children: ReactNode;
    withInput?: ReactNode;
}

function SelectableCard({
    onSelect,
    selected,
    disabled,
    containerClassname,
    children,
    withInput,
}: SelectableCardProps) {
    const buttonContainerClassname = selected
        ? styles.containerSelected
        : styles.container;
    return (
        <div className={buttonContainerClassname}>
            <button
            type="button"
            onClick={onSelect}
            disabled={disabled}
            role='button'
            
            >
            <span className={styles.checkmarkContainer}>
            <IconSolidCheckmark />
            </span>
            <div className={containerClassname} style={withInput ? { paddingBottom: '0px' } : {}}>{children}</div>
            </button>
            {withInput ? <div className={styles.cardInput}> {withInput}</div>: null}
        </div>

    );
}

export default SelectableCard;
