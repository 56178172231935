import React, { useEffect } from 'react';
import { DialogContentText, makeStyles } from '@material-ui/core';
import ErrorDialog, { ErrorDialogProps } from 'src/components/error-dialog';
import COLORS from 'src/lib/colors';
import ValidationTextField from 'src/components/validation-text-field';
import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles({
  dialogContentText: {
    color: COLORS.secondaryNeutralCharcoal,
    textAlign: 'center',
    marginTop: '1rem',
    letterSpacing: '-0.5pt',
    lineHeight: '1.2rem',
    height: '2rem',
  },
});

type FormState = {
  quantity?: number;
};

type GenericUserInputDialogProps = ErrorDialogProps & {
  title: string;
  defaultInputValue?: number | undefined;
  handleInputChange: (value: number) => void;
  handleSubmit?: (value: number) => void;
};

export default function GenericUserInputDialog(
  props: GenericUserInputDialogProps
) {
  const classes = useStyles();
  const {
    title,
    defaultInputValue = 1,
    open,
    handleInputChange,
    handleSubmit,
    ...rest
  } = props;

  const onSubmit = (values: FormState) => {
    if (values.quantity && handleSubmit) {
      handleSubmit(values.quantity);
    }
  };

  const formik = useFormik({
    initialValues: {
      quantity: defaultInputValue,
    },
    validationSchema: yup.object({
      quantity: yup
        .number()
        .required('Quantity required')
        .positive()
        .integer('Whole numbers only')
        .min(1, 'Quantity too small')
        .max(99, 'Quantity too large'),
    }),
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    formik.setFieldValue('quantity', defaultInputValue);
  }, [open]);

  const clearError = (e: any) => {
    // Reset the form value if theres an existing error
    if (formik.errors.quantity) {
      formik.setFieldValue('quantity', defaultInputValue);
    }
    const fieldName = e.type === 'focus' ? e.target.name : '';
    formik.setFieldError(fieldName, '');
  };

  return (
    <ErrorDialog
      open={open}
      errorTitle={title}
      primaryBtnLabel="Apply"
      primaryBtnId="user-input-btn"
      disablePrimaryBtn={
        !formik.values.quantity ||
        formik.values.quantity === 0 ||
        Boolean(formik.errors.quantity)
      }
      secondaryBtnLabel="Cancel"
      handlePrimaryBtnClick={() =>
        handleInputChange(formik.values.quantity || 0)
      }
      {...rest}
    >
      <DialogContentText
        id="generic-user-input"
        component="div" // <form> cannot appear as a descendant of <p>
        className={classes.dialogContentText}
      >
        <form onSubmit={formik.handleSubmit}>
          <ValidationTextField
            fullWidth
            inputProps={{
              style: { textAlign: 'center', width: '100px' },
            }}
            name="quantity"
            id="item-quantity"
            inputMode="numeric"
            type="number"
            placeholder="Enter amount"
            onChange={formik.handleChange}
            onFocus={(e) => clearError(e)}
            value={formik.values.quantity}
            error={Boolean(formik.errors.quantity)}
            helperText={formik.errors.quantity}
          />
        </form>
      </DialogContentText>
    </ErrorDialog>
  );
}
