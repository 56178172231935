/* eslint-disable import/prefer-default-export */

// Constructs URLSearchParams from params object for only keys with defined values
export const makeSearchString = (
  params?: Record<string, string | number | boolean | undefined> | null
): string | undefined => {
  if (!params) {
    return undefined;
  }
  const safeParams: Record<string, string> = {};
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      safeParams[key] = params[key] as string;
    }
  });
  return new URLSearchParams(safeParams).toString();
};
