/* eslint-disable import/prefer-default-export */
import ROUTES, { Routes } from 'src/lib/routes';
import axiosInstance from '../api-client/custom-axios-without-auth';
import { makeSearchString } from './url-utils';

const supportedPaths = [ROUTES.home, ROUTES.rewards];
export type dynamicLinkSupportedPathsType = typeof supportedPaths[number];

type dynamicLinkParams = {
  // eslint-disable-next-line camelcase
  access_token?: string;
  refreshToken?: string;
  expirationTime?: number;
};

export async function getShortDynamicLink(
  longDynamicLink: string
): Promise<string> {
  let shortDynamicLink = '';
  // using fetch instead of axios since we need no-cors mode
  try {
    const res = await axiosInstance.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      { longDynamicLink },
      {
        // do not use out default headers (sending Ocp-Apim-Subscription-Key causes cors error)
        headers: null,
      }
    );
    shortDynamicLink = res.data.shortLink;
  } catch (e) {
    shortDynamicLink = '';
  }

  return shortDynamicLink;
}

// builds the link the iOS app will open, this cannot be another dynamic link
// optionally passing the appSafeLink
function linkForIOSApp(routePath: Routes, params?: dynamicLinkParams) {
  const appSafeLink = `${process.env.REACT_APP_PUBLIC_URL}${routePath}`;
  if (params) {
    const urlParams = makeSearchString(params);
    return urlParams ? `${appSafeLink}?${urlParams.toString()}` : appSafeLink;
  }
  return appSafeLink;
}

// used for fallback urls if the iOS app is not installed
// we do not need to pass an access token here because we're already in session storage
const linkForPWA = (routePath: Routes) =>
  `${process.env.REACT_APP_PUBLIC_URL}${routePath}`;

// see https://firebase.google.com/docs/dynamic-links/create-manually#parameters
export async function getDynamicLinkForPath(
  routePath: dynamicLinkSupportedPathsType,
  params?: dynamicLinkParams
) {
  if (
    !process.env.REACT_APP_DYNAMIC_LINK_DOMAIN ||
    !process.env.REACT_APP_DYNAMIC_LINK_IOS_BUNDLE_ID
  ) {
    // eslint-disable-next-line no-console
    console.error(`did not create dynamic link: required env vars were not defined
        REACT_APP_DYNAMIC_LINK_DOMAIN ${process.env.REACT_APP_DYNAMIC_LINK_DOMAIN}
        REACT_APP_DYNAMIC_LINK_IOS_BUNDLE_ID ${process.env.REACT_APP_DYNAMIC_LINK_IOS_BUNDLE_ID}
        `);

    return routePath;
  }

  // Enfore home or rewards path
  const path = supportedPaths.includes(routePath) ? routePath : ROUTES.home;
  // build our dynamic link manually
  if (process.env.REACT_APP_DYNAMIC_LINK_DOMAIN) {
    const bundleId = process.env.REACT_APP_DYNAMIC_LINK_IOS_BUNDLE_ID;
    const fallbackLink = linkForPWA(path);
    // https://firebase.google.com/docs/dynamic-links/create-manually#parameters
    const urlParams = makeSearchString({
      link: linkForIOSApp(path, params),
      ibi: bundleId, // ios - bundle id
      ipbi: bundleId, // ios - ipad bundle id
      isi: process.env.REACT_APP_IOS_APP_STORE_ID, // ios - app store ID used to open app store if app not installed
      // omitting ifl and ipfl so app store opens if app not installed on ios
      ofl: fallbackLink, // other platforms - fallback link for platforms other than ios and android
      efr: '1', // skip the app preview page when the dynamic link is opened
      ius:
        process.env.REACT_APP_DYNAMIC_LINK_IOS_CUSTOM_URL_SCHEME || // custom URL scheme, if something the bundle ID
        'com.staplesconnect',
    });

    const longDynamicLink = urlParams
      ? `${process.env.REACT_APP_DYNAMIC_LINK_DOMAIN}?${urlParams.toString()}`
      : process.env.REACT_APP_DYNAMIC_LINK_DOMAIN;

    let shortDynamicLink = '';

    try {
      shortDynamicLink = await getShortDynamicLink(longDynamicLink);
    } catch (e) {
      shortDynamicLink = '';
    }

    return shortDynamicLink || longDynamicLink;
  }
  // did not create dynamic link: unsupported route path;
  return routePath;
}
