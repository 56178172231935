import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { Body, Heading, Button } from 'src/components/UI';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import styles from './styles.module.scss';
import '../../../assets/animate/animate.css';

const LetsStartScreen = ({ handleNext }: any) => {
    const animationClass =
        'animate__animated animate__fadeIn animate__delay-0.7s';

    const { analyticsCustomEvent } = useEventsReporter();
    const handleLestStart = () => {
        handleNext(1)
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `lets start!`,
        });
    }

    return (
        <div className={`${styles.boxWrapper} ${animationClass}`}>
            <img
                src={
                    'https://de-app.staplesconnect.com/images/CoachMarks/cm_img_intro_sm.png'
                }
                alt="img"
            />
            <Heading type="h2" size={26} weight="bold" lineHeight={32}>
                So good to see you!
            </Heading>
            <Body
                size={16}
                weight="light"
                lineHeight={20}
                className={`${styles.bodyTextAlign}`}
            >
                Let’s show you around and see what’s new.
            </Body>

            <Button bgColor="bgred" onClick={handleLestStart}>
                Lets start!
            </Button>
        </div>
    );
};

export default LetsStartScreen;
