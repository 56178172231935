/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { getUserAssociatedSchools } from 'src/lib/api-client/locations';
import {
    AssociatedSchoolResult,
    RewardsAccountConfirmState,
    RewardsAccountUserType,
} from 'src/services/types';
import {
    locationClaimSchoolURL,
    rewardsLinkAccountURL,
    rewardsMaskedAccountURL,
    rewardsSignUpURL,
    walletLinkAccountURL,
    walletSignUpURL,
} from 'src/services/urls';

type RewardsCheck = {
    maskedCredential: string | null;
    accountIdentifier: string | null;
    accountExists: boolean;
    accountClaimed: boolean;
    accountMisconfigured: boolean;
    isPhoneExists: boolean;
    isEmailExists: boolean;
};

export async function getMaskedRewardsAccountInfo(
    accountIdentifier: string,
    identifierType?: string
): Promise<RewardsCheck | null> {
    const rewardsCheck = await axiosInstance.post(
        `${process.env.REACT_APP_REWARDS_API_MASKED_ACCOUNT_URL}`,
        { accountIdentifier, identifierType }
    );
    if (
        rewardsCheck.data &&
        typeof rewardsCheck.data.accountExists === 'boolean'
    ) {
        return rewardsCheck.data as RewardsCheck;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when getting masked account info ${rewardsMaskedAccountURL}`
    );
    Log.axios(rewardsCheck);
    return null;
}

type LinkRewardsAccount = {
    isSuccess: boolean;
};

export async function linkRewardsAccount(
    accountIdentifier?: string,
    accountCredential?: string,
    userType?: RewardsAccountUserType
): Promise<LinkRewardsAccount | null> {
    if (!accountIdentifier || !accountCredential) {
        return null;
    }
    const linkAccount = await axiosInstance.post(`${rewardsLinkAccountURL}`, {
        accountIdentifier,
        accountCredential,
        userType,
    });
    if (linkAccount.data && typeof linkAccount.data.isSuccess === 'boolean') {
        return linkAccount.data as LinkRewardsAccount;
    }

    Log.axios(
        `received an unexpected response when linking account ${rewardsLinkAccountURL}`
    );
    Log.axios(linkAccount);
    return null;
}

export async function linkSRWAccount(
    rewardsNumber?: string | null,
    email?: string,
    phoneNumber?: string | null
): Promise<LinkRewardsAccount | null> {
    if (!(email || phoneNumber)) {
        return null;
    }
    const linkAccount = await axiosInstance.post(`${walletLinkAccountURL}`, {
        rewardsNumber,
        email,
        phoneNumber,
    });
    if (linkAccount.data && typeof linkAccount.data.isSuccess === 'boolean') {
        return linkAccount.data as LinkRewardsAccount;
    }

    Log.axios(
        `received an unexpected response when linking account ${walletLinkAccountURL}`
    );
    Log.axios(linkAccount);
    return null;
}

export type AccountCreateRequest = {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    companyName?: string;
    industry?: string;
    shopFor?: string; // 'Myself' or 'Business'
    userType?: RewardsAccountUserType;
};

type CreateRewardsAccountCode =
    | 'Success'
    | 'InvalidEmail'
    | 'InvalidShopForValue'
    | 'CompanyNameIsEmpty'
    | 'AccountAlreadyExists'
    | 'InvalidPhoneNumber';
// InvalidPhoneNumber handled with the default error state
export type AccountCreateResponse = {
    rewardsNumber: number;
    email: string;
    code: CreateRewardsAccountCode;
    isSuccess: boolean;
};

export async function createRewardsAccount(
    newAccountInfo: AccountCreateRequest
): Promise<AccountCreateResponse | null> {
    const createAccount = await axiosInstance.post(
        `${rewardsSignUpURL}`,
        newAccountInfo
    );

    if (createAccount && createAccount.data) {
        return createAccount.data as AccountCreateResponse;
    }
    Log.axios(
        `received an unexpected response when linking account ${rewardsSignUpURL}`
    );
    Log.axios(createAccount);
    return null;
}

/* eslint no-param-reassign: "error" */
export async function createSRWAccount(
    newAccountInfo: AccountCreateRequest
): Promise<AccountCreateResponse | null> {
    if (newAccountInfo?.userType) {
        delete newAccountInfo?.userType;
    }
    const createAccount = await axiosInstance.post(
        `${walletSignUpURL}`,
        newAccountInfo
    );

    if (createAccount && createAccount.data) {
        return createAccount.data as AccountCreateResponse;
    }
    Log.axios(
        `received an unexpected response when linking account ${walletSignUpURL}`
    );
    Log.axios(createAccount);
    return null;
}

type ClaimSchoolResponseCode = string;

export type ClaimSchoolResponse = {
    isSuccess: boolean;
    code: null | ClaimSchoolResponseCode;
    userConfirmState?: RewardsAccountConfirmState;
    previousSchools: AssociatedSchoolResult[];
    newSchoolIds: string[];
};

export async function claimSchools(
    schoolIds: string[]
): Promise<ClaimSchoolResponse | null> {
    const previousSchools = await getUserAssociatedSchools();
    const claimSchoolResp = await axiosInstance.post(
        `${locationClaimSchoolURL}`,
        { institutionIdentifiers: schoolIds }
    );
    if (claimSchoolResp.data) {
        const modifiedData = {
            ...claimSchoolResp.data,
            previousSchools,
            newSchoolIds: schoolIds,
        };
        return modifiedData as ClaimSchoolResponse;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when claiming schools via ${locationClaimSchoolURL}`
    );
    Log.axios(claimSchoolResp);
    return null;
}

export async function deleteClaimedSchool(
    schoolId: string
): Promise<ClaimSchoolResponse | null> {
    const deleteSchoolResp = await axiosInstance.delete(
        `${locationClaimSchoolURL}/${schoolId}`
    );
    if (deleteSchoolResp.data) {
        return deleteSchoolResp.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when deleting claimed school via ${locationClaimSchoolURL}/${schoolId}`
    );
    Log.axios(deleteSchoolResp);
    return null;
}
