import { Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'scroll',
  },
  noStoresContainer: {
    paddingTop: '0.5rem',
  },
  noStoresIconContainer: {
    textAlign: 'center',
    display: 'block',
  },
  noStoresText: {
    textAlign: 'center',
    fontSize: '1.0625rem',
    fontWeight: 500,
    paddingTop: '0.5rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '2rem',
  },
});

type NoStoreResultsProps = {
  subText: string;
};

export default function NoStoreResults({ subText }: NoStoreResultsProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Divider color={COLORS.fordGray} />
      <div className={classes.noStoresContainer}>
        <span className={classes.noStoresIconContainer}>
          <img src={require('../../assets/icons/iconNoStore.svg')} alt="no stores icon" />
        </span>

        <Typography className={classes.noStoresText}>{subText}</Typography>
      </div>
    </div>
  );
}
