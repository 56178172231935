import useRouter from 'src/hooks/useRouter';
import { isOnLandingScreen } from 'src/lib/utils/screen-utils';

const useIsOnLandingScreen = () => {
    const router = useRouter();
    return {
        isOnLandingScreen: isOnLandingScreen(router.query, router.pathname),
        router,
    };
};

export default useIsOnLandingScreen;
