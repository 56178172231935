import React, { ReactNode } from 'react';
import { mergeClasses } from 'src/stylesheets/utils';
import styles from './styles.module.scss';

interface PillProps {
    color?: string;
    bgColor?: string;
    borderColor?: string;
    fontWeight?: string;
    children: ReactNode;
    className?: string;
    ariaLabel?: string;
}

function Pill({
    color = 'default',
    bgColor = 'default',
    borderColor = 'default',
    fontWeight = 'bold',
    children,
    className,
    ariaLabel,
}: PillProps) {
    return (
        <div
            className={mergeClasses(
                styles.pillContainer,
                styles[bgColor],
                styles[color],
                styles.borderBase,
                styles[borderColor],
                styles[fontWeight],
                styles.fullRounded,
                styles.borderExtraLightgray,
                className
            )}
            aria-label={ariaLabel}
        >
            {children}
        </div>
    );
}

export default Pill;
