/* eslint-disable react/no-unused-prop-types */
import { makeStyles } from '@material-ui/core';
import { NAV_BAR_HEIGHT } from 'src/components/nav-bottom-old';
import COLORS from 'src/lib/colors';
import { GUTTER_WIDTH } from 'src/theme';
import MaskBorderTop from '../../assets/bgs/mask-border-top.png';

const useStyles = makeStyles((theme) => ({
    screen: ({ isMobileWidth }: { isMobileWidth: boolean }) => ({
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        textAlign: 'center',
        position: 'absolute',
        inset: 0,
        bottom: isMobileWidth ? NAV_BAR_HEIGHT : 0,
    }),
    backIcon: {
        fontSize: '1.3rem',
        color: COLORS.primaryWhite,
    },
    backButton: {
        marginLeft: '1rem',
        // margin right needed to balance out editButton width and center the text content
        marginRight: '30px',
    },
    topContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '3rem',
        textAlign: 'center',
    },
    paddedContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: `0 calc(1.5rem + ${GUTTER_WIDTH})`,
    },
    listsAmountNumber: {
        marginTop: '.5rem',
        fontSize: '4rem',
        color: COLORS.primaryWhite,
        fontWeight: 'bold',
        [theme.breakpoints.down(520)]: {
            fontSize: '3.5rem',
        },
    },
    listsAmountLabel: {
        marginTop: '-1.25rem',
        marginBottom: '1.5rem',
        fontSize: '1.5rem',
        color: COLORS.primaryWhite,
        fontWeight: 500,
        [theme.breakpoints.down(520)]: {
            fontSize: '1.25rem',
            marginBottom: '1rem',
        },
    },
    createButton: {
        borderRadius: '1.5rem',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '25rem',
        textTransform: 'none',
        fontSize: '1.2rem',
        background: COLORS.brandRed,
        color: COLORS.primaryWhite,
        '&:hover': {
            backgroundColor: COLORS.darkRed,
        },
        '&:active': {
            backgroundColor: COLORS.darkRed,
        },
        [theme.breakpoints.down(520)]: {
            fontSize: '1rem',
        },
    },
    importButton: {
        textTransform: 'none',
        fontSize: '1.15rem',
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        padding: '1rem 0.2rem',
        [theme.breakpoints.down(520)]: {
            fontSize: '0.8rem',
        },
    },
    findSupplyListWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    disclaimerContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    disclaimerIcon: {
        width: '100%',
        maxWidth: '10rem',
        marginBottom: '1rem',
        marginTop: '1.5rem',
    },
    disclaimerText: {
        fontSize: '1.1rem',
        fontWeight: 500,
    },

    bottomSpace: {
        width: '100%',
        height: '9.5rem',
    },
    topMaskedList: {
        WebkitMaskBoxImageSource: `url(${MaskBorderTop})`,
        WebkitMaskBoxImageSlice: '20 fill',
        WebkitMaskBoxImageWidth: '20px',
        WebkitMaskBoxImageOutset: '0px',
    },
    editButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: '3rem',
        marginBottom: '-3rem',
    },
    editButton: {
        color: COLORS.primaryWhite,
        fontSize: '1.25rem',
        fontWeight: 'bold',
        textTransform: 'none',
        '&:disabled': {
            color: COLORS.fordGray,
        },
        transition: 'color .5s',
        marginLeft: 'auto',
    },
    recivedListWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 1rem 0',
    },
    saveButton: {
        color: COLORS.primaryWhite,
        fontSize: '1rem',
        fontWeight: 600,
        padding: '6px 12px',
        textTransform: 'none',
        '&:disabled': {
            color: COLORS.fordGray,
        },
        transition: 'color .5s',
    },
    receivedListNamedBy: {
        fontSize: '1.5rem',
        color: COLORS.primaryWhite,
        fontWeight: 500,
        lineHeight: '1.75rem',
    },
    receivedListSubtitle: {
        lineHeight: '1.2rem',
        fontSize: '1rem',
        color: COLORS.primaryWhite,
        fontWeight: 400,
    },
    sharedByContainer: {
        height: '1.9rem',
        width: '10.19rem',
        display: 'flex',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '-2rem',
        bottom: 0,
        right: 0,
        left: 0,
        alignItems: 'center',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        justifyContent: 'center',
    },
    nameByShared: {
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 450,
        lineHeight: '13px',
        textAlign: 'center',
    },
    topContentSharedList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        position: 'relative',
    },
}));

export default useStyles;
