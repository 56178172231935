/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import {
    Dialog,
    Button,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    makeStyles,
    Box,
} from '@material-ui/core';
import NavTop from 'src/components/nav-top';
import { useCreateImportListContext } from 'src/lib/contexts/create-import-list-context';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import ScreenWidth from 'src/components/screen-width';
import LoadingButton from 'src/components/loading-button';
import COLORS from 'src/lib/colors';
import useStyles from './use-styles';

const useStylesDialog = makeStyles({
    discardContainer: {
        width: '328px',
        borderRadius: '1rem',
        margin: '1rem',
        paddingBottom: '1rem',
    },
    discardContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
    },
    discardLink: {
        fontSize: '1rem',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        color: COLORS.brandCyan,
        fontWeight: 'bold',
    },
    container: {
        borderRadius: '16px',
        paddingBottom: '10px',
    },
    title: {
        fontSize: '1rem',
        fontWeight: 500,
        alignSelf: 'center',
        padding: '5px',
    },
    closeIcon: {
        justifyContent: 'flex-end',
    },
    saveButton: {
        width: '90%',
        margin: '2.5rem 5% 1rem 5%',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    discardButton: {
        width: '90%',
        margin: '1.5rem 5% 1rem 5%',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
    },
});
interface IReceivedSharedList {
    onSave: (boolean: boolean) => void;
    onDiscard: () => void;
    listDetails: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ReceiveSharedList({
    onDiscard,
    onSave,
    listDetails,
}: IReceivedSharedList) {
    const isMobileWidth = useIsMobileWidth();
    const { currentPage, goBack, activePaginationIndex } =
        useCreateImportListContext();
    const classes = useStyles({ isMobileWidth });
    const classesDialog = useStylesDialog();
    const [viewSharedList, setViewSharedList] = useState(false);
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const handleOnClick = (type: string) => {
        if (type === 'save') {
            onSave(true);
        } else {
            setMode(type);
            setOpen(true);
        }
    };
    function handleClose() {
        setOpen(false);
    }
    const handleDiscardAction = () => {
        onDiscard();
        setOpen(false);
    };
    return (
        <Box>
            <div>
                <div className={classes.topContentSharedList}>
                    <div className={classes.recivedListWrapper}>
                        <Button
                            onClick={() => {
                                handleOnClick('save');
                            }}
                            className={classes.saveButton}
                            id="edit-mode-toggle-button"
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                handleOnClick('discard');
                            }}
                            className={classes.saveButton}
                            id="edit-mode-toggle-button"
                        >
                            Discard
                        </Button>
                    </div>
                    <div className={classes.paddedContent}>
                        <Typography
                            className={classes.receivedListNamedBy}
                            id="list-available-amount-text"
                        >
                            {listDetails?.productListName}
                        </Typography>
                        <Typography
                            className={classes.receivedListSubtitle}
                            id="list-available-text"
                        >
                            {listDetails?.description}
                        </Typography>
                    </div>
                    <div
                        className={classes.sharedByContainer}
                        style={{
                            backgroundColor:
                                listDetails?.sharedByRole === 'Supporter'
                                    ? COLORS.brandCyan
                                    : COLORS.rewardsYellow,
                        }}
                    >
                        <span
                            className={classes.nameByShared}
                            style={{
                                color:
                                    listDetails?.sharedByRole === 'Supporter'
                                        ? COLORS.primaryWhite
                                        : COLORS.textBlack,
                            }}
                        >
                            Shared by{' '}
                            {listDetails?.sharedByRole === 'Educator'
                                ? 'teacher, '
                                : ''}
                            <b>{listDetails?.sharedBy}</b>
                        </span>
                    </div>
                </div>
            </div>

            {mode === 'discard' && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classesDialog.discardContainer }}
                >
                    <Button
                        fullWidth
                        onClick={handleClose}
                        className={classesDialog.closeIcon}
                        id="close-button"
                    >
                        <img
                            src={require('../../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </Button>
                    <DialogTitle
                        id="alert-dialog-title"
                        className={classesDialog.title}
                    >
                        Discard
                    </DialogTitle>
                    <DialogContent>
                        <div
                            id="alert-dialog-description"
                            className={classesDialog.discardContent}
                        >
                            Are you sure you want to delete this list
                            permanently?
                        </div>
                        <LoadingButton
                            fullWidth
                            id="list-create-edit-dialog-done-button"
                            variant="contained"
                            classes={{
                                root: classesDialog?.discardButton,
                            }}
                            type="submit"
                            onClick={() => setOpen(false)}
                            loading={false}
                        >
                            No, don't discard this list
                        </LoadingButton>
                        <span
                            tabIndex={0}
                            role="button"
                            className={classesDialog.discardLink}
                            onClick={handleDiscardAction}
                            onKeyPress={handleDiscardAction}
                        >
                            Yes, discard this list
                        </span>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
}

export default ReceiveSharedList;
