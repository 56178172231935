import React, { useState } from 'react';
import COLORS from 'src/lib/colors';
import { ButtonBase, Divider, makeStyles, Typography } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import NavTop from 'src/components/nav-top';
import { CommonPageProps } from 'src/screens/product-browse/common-page';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ScreenWidth from 'src/components/screen-width';
import { SortRequest, SortRequestByValue } from 'src/lib/api-client/adept-mind-types';
import clsx from 'clsx';

type SortOption = {
  id: string;
  label: string;
  value: number | undefined;
};
// always show the following sorts and always in this order
const SUPPORTED_SORTS: SortOption[] = [
  {
    id: 'bestMatch',
    label: 'Best Match',
    value: 0,
  },
  {
    id: 'price-asc',
    label: 'Price: Low to High',
    value: 1,
  },
  {
    id: 'price-desc',
    label: 'Price: High to Low',
    value: 2,
  },
  {
    id: 'rating',
    label: 'Top Rating',
    value: 5,
  },
  {
    id: 'newArrivals',
    label: 'New Arrivals',
    value: 6,
  },
];

const useStyles = makeStyles({
  screen: {
    background: COLORS.primaryWhite,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  titleAndBack: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '.75rem',
  },
  backButton: { paddingLeft: '.5rem', width: '6rem' },
  clearAll: {
    color: COLORS.primaryWhite,
    fontWeight: 'bold',
    fontSize: '1rem',
    width: '5.5rem',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1.5rem',
    lineClamp: 1,
    color: COLORS.primaryWhite,
    fontWeight: 500,
  },
  buttonBalance: {
    display: 'flex',
    justifyContent: 'center',
    width: '6rem',
    paddingRight: '.5rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    flexGrow: 1,
  },
});

type SortRowProps = {
  sort: SortOption;
  index: number;
  sortSelected: boolean;
  onRowClicked: (s: SortOption) => void;
};
const useSortRowStyles = makeStyles({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  indented: {
    paddingLeft: '1.5rem',
  },
  title: {
    minHeight: '1.2rem',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
    flexGrow: 1,
  },
  rowContent: {
    width: '100%',
    display: 'flex',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  icon: {
    marginRight: '1.5rem',
    marginLeft: '1rem',
    marginBottom: '-.5rem',
    marginTop: '-.5rem',
  },
});

function SortRow({ sort, index, sortSelected, onRowClicked }: SortRowProps) {
  const classes = useSortRowStyles();
  const { analyticsCustomEvent } = useEventsReporter();
  function onClick() {
    analyticsCustomEvent(eventMetricsMap.product_search, {
      click_text: sort.label,
      element_location: 'search results',
    });
    optimoveEvents(eventMetricsMap.scm_product_search, {
      click_text: sort.label,
      element_location: 'search results',
    });
    onRowClicked(sort);
  }
  return (
    <div className={classes.row} key={`sort-option-${sort.id}`}>
      <ButtonBase onClick={onClick} id={`sort-option-${index}-button`}>
        <div className={classes.rowContent}>
          <Typography
            className={clsx(classes.title, classes.indented)}
            id={`sort-option-${index}-label-text`}
          >
            {sort.label}
          </Typography>
          {sortSelected && (
            <img
              className={classes.icon}
              src={require('../../assets/icons/iconBlackCheckmark.svg')}
              alt="selected"
            />
          )}
        </div>
      </ButtonBase>
      <div className={classes.indented}>
        <Divider />
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ProductSortScreen(props: CommonPageProps) {
  const classes = useStyles();
  const { analyticsCustomEvent } = useEventsReporter();
  const { goBack, setSorting, sorts } = useProductBrowseContext();

  // keeping sorting as an array since that's how it needs to be passed to adeptmind
  const [pendingSorts, setPendingSorts] =
    useState<SortRequestByValue | undefined>(sorts);

  function onClearAllClicked() {
    analyticsCustomEvent(eventMetricsMap.product_search, {
      click_text: 'clear all',
      element_location: 'search results',
    });
    optimoveEvents(eventMetricsMap.scm_product_search, {
      click_text: 'clear all',
      element_location: 'search results',
    });
    // clear all sorts
    setSorting([]);
    setPendingSorts(undefined);
    goBack();
  }
  function backButtonClicked() {
    // commit to the current sort
    setSorting(pendingSorts);
    goBack();
    setPendingSorts(undefined);
  }

  function onRowClicked(s: any) {
    // only handling one sort at a time
    if (s.value === pendingSorts) {
      setPendingSorts(undefined);
    } else {
      setPendingSorts(s.value);
    }
  }

  return (
    <div className={classes.screen}>
      <ScreenWidth>
        <NavTop noBorderRadius minHeight="5rem">
          <div className={classes.titleAndBack}>
            <ButtonBase
              className={classes.backButton}
              onClick={backButtonClicked}
            >
              <img src={require('../../assets/icons/iconBackWhite.svg')} alt="back" />
            </ButtonBase>
            <Typography className={classes.title}>Sort</Typography>
            <div className={classes.buttonBalance}>
              <ButtonBase
                className={classes.clearAll}
                onClick={onClearAllClicked}
              >
                Clear All
              </ButtonBase>
            </div>
          </div>
        </NavTop>
        <div className={classes.content}>
          {SUPPORTED_SORTS.map((s, i) => (
            <SortRow
              key={s.id}
              index={i}
              sort={s}
              sortSelected={!!(s.value === pendingSorts)}
              onRowClicked={onRowClicked}
            />
          ))}
        </div>
      </ScreenWidth>
    </div>
  );
}