import React, { useState, useMemo, ChangeEvent } from 'react';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { UserData } from 'react-oidc';
import { IAuthenticatorContext } from 'react-oidc/lib/makeAuth';
import ROUTES from 'src/lib/routes';
import {
    Box,
    ButtonBase,
    Divider,
    makeStyles,
    Typography,
    IconButton,
    Fade,
    Avatar,
} from '@material-ui/core';
import {
    LOCAL_STORAGE_KEYS,
    saveToLocalStorage,
} from 'src/hooks/useLocalStorage';
import useAuthFromContext from 'src/lib/auth/use-auth-from-context';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import Create from '@material-ui/icons/Create';
import { InUserDataConsumer } from 'src/lib/types';
import useUserInfo from 'src/hooks/useUserInfo';
import AppLink from 'src/components/link';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingOverlay from 'src/components/loading-overlay';
import { RewardsAccountUserType, User } from 'src/services/types';
import { getUserAssociatedSchools } from 'src/lib/api-client/locations';
import InfoButton from 'src/components/info-button';
import { Clear } from '@material-ui/icons';
// TODO: STP-1310 reenable phone number
// import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import { useCachedSchools } from 'src/lib/utils/school-utils';
import { fullName } from 'src/lib/utils/user-utils';
import useRouter from 'src/hooks/useRouter';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import ScrollContainer from 'src/components/screen-scroll';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { Form, Formik, FormikHelpers, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import ValidationTextField from '../validation-text-field';

const helpCenterURL = 'https://www.staplesconnect.com/help';
const termsAndConditionsURL = 'https://www.staplesconnect.com/terms-conditions';
const privacyPolicyURL = 'https://www.staplesconnect.com/privacypolicy';
// eslint-disable-next-line max-len
const dontSellPersonalInfoURL =
    'https://submit-irm.trustarc.com/services/validation/f079d43b-9446-4b1a-8ee9-c2bc24658561';
const californiaNoticeURL = 'https://www.staplesconnect.com/californianotice';

const nameInfo =
    'To prevent fraud, please contact member services to change your name in your profile.';
const infoCtaText = 'Visit Help Center';
const schoolAssociationInfo =
    'Please visit the Help Center to contact member services to make changes to your claimed school.';
const infoButtonSize = '1.8rem';

const useStyles = makeStyles({
    mainContainer: {
        background: COLORS.whiteSmoke,
    },
    nameAndRole: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: COLORS.primaryWhite,
    },
    profileImage: {
        height: '6.5rem',
        width: '6.5rem',
    },
    editIcon: {
        position: 'absolute',
        right: 0,
        top: '0.5rem',
    },
    editPasswordIcon: {
        position: 'absolute',
        right: 0,
        top: '-5px',
    },
    inputWrapper: {
        marginBottom: '1.6rem',
        position: 'relative',
    },
    changePassword: {
        fontWeight: 700,
        fontSize: '1.1rem',
        lineHeight: '1rem',
    },
    name: {
        fontSize: '1.3rem',
        marginTop: '0.6rem',
    },
    role: {
        lineHeight: '0.8rem',
    },
    profileContainer: {
        marginTop: '2rem',
        padding: '0.5rem',
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    topRowTitle: {
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        fontSize: '1.1rem',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '2rem 0',
        alignItems: 'center',
    },
    bottomButton: {
        color: COLORS.brandCyan,
        fontSize: '1.1rem',
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        marginTop: '1rem',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    appVersionText: {
        color: COLORS.fordGray,
        fontSize: '1rem',
        letterSpacing: '-0.6pt',
        marginTop: '1.2rem',
        display: 'flex',
        flexDirection: 'row',
    },
    appVersionTitle: {
        fontWeight: 600,
        paddingRight: '0.2rem',
    },
    associatedSchoolContainer: {
        marginTop: '1.5rem',
    },
    schoolDivider: {
        backgroundColor: COLORS.gray80,
        width: '100%',
    },
    backIcon: {
        fontSize: '1.3rem',
        color: COLORS.primaryWhite,
    },
    backButton: {
        left: '1rem',
        position: 'relative',
    },
    closeButton: {
        right: '1rem',
        position: 'relative',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    continueButton: {
        alignSelf: 'center',
        backgroundColor: 'black',
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '100%',
        fontWeight: 500,
        margin: '0.75rem 0',
        fontSize: '0.95rem',
        letterSpacing: '0',
        fontFamily: 'TTNorms',
    },
});

const useCardRowStyles = makeStyles({
    row: {
        marginTop: '1.3rem',
    },
    titleAndInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        fontWeight: 500,
        fontSize: '1.1rem',
        letterSpacing: '-0.4pt',
    },
    content: {
        fontSize: '1.1rem',
        letterSpacing: '-0.4pt',
        lineHeight: '0.8rem',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
});

export function AccountSecurity() {
    sessionStorage.setItem(
        LOCAL_STORAGE_KEYS.AUTH_REDIRECT,
        window.location.pathname
    );
    /* const validationSchema = yup.object({
        phoneNumber: yup
          .string()
          .min(10, 'Phone Number is too short.')
          .max(10, 'Phone Number is too long.')
          .required('Phone Number is a required field.'),
      }); */
    const classes = useStyles();
    const [editMail, setEditMail] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);

    const { userInfo, isFetchingUserInfo } = useUserInfo();
    const formik: any = useFormik({
        initialValues: {
            email: userInfo?.email,
            password: '',
        },
        // validationSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values, { setSubmitting }) => {
            //   onComplete(values.zipCode);
            //   setSubmitting(false);
            //   handleClose();
        },
    });
    const {
        values,
        handleSubmit,
        handleChange,
        errors,
        touched,
        isValid,
        isSubmitting,
        setFieldError,
        setFieldValue,
    } = formik;

    const router = useRouter();

    if (isFetchingUserInfo && !userInfo) {
        return <LoadingOverlay variant="wordmark" theme="light" open />;
    }

    const handleOnBackClick = () => {
        router.push(ROUTES.userManageProfile);
    };
    const editEmail = () => {
        setEditMail(!editMail);
    };
    const handleContinue = () => {
        setEditMail(false);
        router.push(ROUTES.confirm);
    };
    const handleResetPassword = () => {
        setResetPassword(!resetPassword);
    };
    const handleResetSave = () => {
        setResetPassword(false);
    };
    const handleFieldChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFieldValue(e.target.name, e?.target?.value);
    };
    const clearError = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        setFieldError(fieldName, '');
    };

    return (
        <>
            <Box className={classes.mainContainer}>
                <NavTop
                    bgImageSrc={require('../../assets/bgs/bgRedUserProfile.svg')}
                    marginBottom="-15.5rem"
                    minHeight="18rem"
                />
                <div className={classes.headerContainer}>
                    <Fade in>
                        <IconButton
                            onClick={handleOnBackClick}
                            size="small"
                            className={classes.backButton}
                        >
                            <ArrowBackIosRounded className={classes.backIcon} />
                        </IconButton>
                    </Fade>
                    <Fade in>
                        <IconButton
                            onClick={handleOnBackClick}
                            size="small"
                            className={classes.closeButton}
                        >
                            <img
                                src={require('../../assets/icons/whiteCloseBtn.svg')}
                                alt="whiteCloseBtn"
                            />
                        </IconButton>
                    </Fade>
                </div>
                <div className={classes.nameAndRole}>
                    <Avatar
                        src={require('../../assets/icons/iconAccountSecurity.png')}
                        className={classes.profileImage}
                        alt="profile"
                    />
                    <Typography
                        className={classes.name}
                        id="user-profile-name-text"
                    >
                        Account Security
                    </Typography>
                    {/*  <Typography className={classes.name} id="user-profile-name-text">
                        {fullName(userInfo)}
                    </Typography>
                    <Typography className={classes.role} id="user-profile-user-type-text">
                    {userInfo?.email}
                    </Typography> */}
                </div>
                <FullWidthCard
                    cardContainerClassName={classes.profileContainer}
                    hasManualShadow
                >
                    <form onSubmit={formik.handleSubmit}>
                        <div className={classes.inputWrapper}>
                            <ValidationTextField
                                fullWidth
                                id="list-name-input"
                                name="email"
                                label="Email Address"
                                type="text"
                                value={values.email}
                                onChange={(e) => handleFieldChange(e)}
                                disabled={!editMail}
                                onFocus={(e) => clearError(e)}
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                inputProps={{ maxLength: 10 }}
                            />
                            <div className={classes.editIcon}>
                                {editMail ? (
                                    <Fade in>
                                        <IconButton
                                            onClick={editEmail}
                                            size="small"
                                        >
                                            <Clear />
                                        </IconButton>
                                    </Fade>
                                ) : (
                                    <Fade in>
                                        <IconButton
                                            onClick={editEmail}
                                            size="small"
                                        >
                                            <Create />
                                        </IconButton>
                                    </Fade>
                                )}
                            </div>
                        </div>
                        {editMail ? (
                            <button
                                className={classes.continueButton}
                                type="button"
                                id="recycle-visit"
                                onClick={handleContinue}
                            >
                                Continue
                            </button>
                        ) : (
                            ''
                        )}
                        {!resetPassword ? (
                            <div className={classes.inputWrapper}>
                                <ValidationTextField
                                    fullWidth
                                    id="list-name-input"
                                    name="password"
                                    label="Password"
                                    type="text"
                                    value={values.password}
                                    onChange={handleChange}
                                    disabled={!resetPassword}
                                    onFocus={(e) => clearError(e)}
                                    error={
                                        touched.password && !!errors.password
                                    }
                                    helperText={
                                        touched.password && errors.password
                                    }
                                    inputProps={{ maxLength: 10 }}
                                    className="fs-exclude"
                                />
                                <div className={classes.editIcon}>
                                    <Fade in>
                                        <IconButton
                                            onClick={handleResetPassword}
                                            size="small"
                                        >
                                            <Create />
                                        </IconButton>
                                    </Fade>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.inputWrapper}>
                                <Typography className={classes.changePassword}>
                                    Change Password
                                </Typography>
                                <div className={classes.editPasswordIcon}>
                                    <Fade in>
                                        <IconButton
                                            onClick={handleResetPassword}
                                            size="small"
                                        >
                                            <Clear />
                                        </IconButton>
                                    </Fade>
                                </div>
                            </div>
                        )}
                    </form>
                    {resetPassword ? (
                        <form>
                            <div className={classes.inputWrapper}>
                                <ValidationTextField
                                    fullWidth
                                    id="list-name-input"
                                    name="phone"
                                    label="Current Password"
                                    type="text"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    /* disabled={isLoading}
                                    onFocus={(e) => clearError(e)}
                                    value={formik.values.name}
                                    error={formik.touched.name && !!formik.errors.name}
                                    helperText={formik.touched.name && formik.errors.name} */
                                    inputProps={{ maxLength: 10 }}
                                />
                            </div>
                            <div className={classes.inputWrapper}>
                                <ValidationTextField
                                    fullWidth
                                    id="list-name-input"
                                    name="phone"
                                    label="New Password"
                                    type="text"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    /* disabled={isLoading}
                                    onFocus={(e) => clearError(e)}
                                    value={formik.values.name}
                                    error={formik.touched.name && !!formik.errors.name}
                                    helperText={formik.touched.name && formik.errors.name} */
                                    inputProps={{ maxLength: 10 }}
                                />
                            </div>
                            <div className={classes.inputWrapper}>
                                <ValidationTextField
                                    fullWidth
                                    id="list-name-input"
                                    name="phone"
                                    label="Confirm Password"
                                    type="text"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    /* disabled={isLoading}
                                    onFocus={(e) => clearError(e)}
                                    value={formik.values.name}
                                    error={formik.touched.name && !!formik.errors.name}
                                    helperText={formik.touched.name && formik.errors.name} */
                                    inputProps={{ maxLength: 10 }}
                                />
                            </div>
                            <button
                                className={classes.continueButton}
                                type="button"
                                id="recycle-visit"
                                onClick={handleResetSave}
                            >
                                Save Changes
                            </button>
                        </form>
                    ) : (
                        ''
                    )}
                </FullWidthCard>
            </Box>
        </>
    );
}

export default () => (
    <UserData.Consumer>
        {() => (
            <ScrollContainer>
                <AccountSecurity />
            </ScrollContainer>
        )}
    </UserData.Consumer>
);
