import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useRouter from 'src/hooks/useRouter';
import ROUTES from '../../../lib/routes';
import useEventsReporter from '../../../hooks/useEventsReporter';

const useStyles = makeStyles({
    errorContainer: {
        marginTop: '35px',
    },
    errorHolder: {
        width: '80%',
        display: 'flex',
    },
    errorWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginTop: '20px',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
    },
    backLink: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
    },
    warningIcon: {
        height: '45px',
        width: 'auto',
        marginRight: '15px',
    },
    errorAlign: {
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        textAlign: 'left',
    },
})
export type InLineError = {
    setError?: any;
    errorMessage?: string;
}
export default function ShippingErrorInline(props: InLineError) {
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const { setError, errorMessage = "Delivery rates and estimates couldn’t be retrieved at this time. " +
        "Please try again later." } = props;

    const handleStartShipment = () => {
        setError(false)
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        })
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'main shipping page',
        });
    }
    const handleBack = () => {
        setError(false)
        history.push({
            pathname: ROUTES.shippingServices,
        });
    }
    return (
        <div className={classes.errorContainer}>
            <div className={classes.errorWrapper}>
                <div className={classes.errorHolder}>
                    <img
                        className={classes.warningIcon}
                        src={require('../../../assets/icons/iconWarning.svg')}
                        alt="warning"
                    />
                    <div>
                        <Typography className={classes.errorAlign}>
                            {errorMessage}
                        </Typography>
                        <ButtonBase className={classes.startShipment} onClick={handleStartShipment}>
                            <span>Start a shipment</span>
                        </ButtonBase>
                        <div className={classes.actions}>
                            <ButtonBase className={classes.backLink} onClick={handleBack}>
                                <span>Go back</span>
                            </ButtonBase>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
