import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ShippingServicesAccordion from './shipping-services-accordion';
import ShippingServicesNoData from './shipping-services-no-data';
import ShippingErrorInline from '../shipping-errors/shipping-error-inline';

const useStyles = makeStyles((theme) => ({
    ShippingServicesShippedLabelConatiner: {
        padding: '18px 0px !important',
        boxShadow: 'none',
        overflow: 'auto',
        height: 'calc(100vh - 168px)',
    },
}))
const ShippingServicesShippedLabel = ({ activeTab, shipmentListData, setError, error, updatedData }: any) => {
    const [shippedLabelData, setshippedLabelData] = useState<any>([]);
    const classes = useStyles();
    useEffect(() => {
        const filtershippeddLabelData = shipmentListData?.filter((data: any) => {
            // Check if shipment status is "Manifested" or "Created"
            const isManifestedOrCreated = ["Manifested", "Created"].includes(data?.shimpmentstatus?.shipmentstatus);
            let allPackagesDelivered = false;
            /**
             * For Scan to track packages, we are always having a shipmenttype as TRACKING
             * for this package shimenttype===TRACKING and statusCode === '003'
             * is just a label created and scanned but handed over to any of the shipping point,
             *
             * for all other shipmenttypes - statusCode === 003 is shipped
             */
            if (!(data?.shipmenttype === "TRACKING" && data?.shimpmentstatus?.shipmenttrackingstatus[0]?.package[0]?.activity?.status?.statusCode === '003')) {
                // Check if all packages have been delivered
                allPackagesDelivered = data?.shimpmentstatus?.shipmenttrackingstatus?.filter((pkg: any) =>
                    pkg?.package[0]?.activity?.status?.statusCode !== '011')?.length > 0
            }
            return allPackagesDelivered;
        });
        setshippedLabelData(filtershippeddLabelData);
    }, [])
    console.log({ shippedLabelData, shipmentListData });
    if (error) {
        return <ShippingErrorInline setError={setError}
            errorMessage='We couldn’t retrieve your created labels
                                     or current shipment statuses at this time.
                                     Please try again later.' />
    }
    if (shippedLabelData?.length === 0) {
        return <ShippingServicesNoData
            imgData={require('src/assets/icons/shippedLabelsNoShippingIcon.svg')}
            error="You don’t have any packages shipped."
            errorDetails="Create a shipment or visit your nearest Staples® store to ship a package."
        />
    }
    return <div className={classes.ShippingServicesShippedLabelConatiner}>
        {shippedLabelData?.map((shipCreateData: any, i: any) =>
            <ShippingServicesAccordion
                key={`${shipCreateData}_i`}
                data={shipCreateData}
                activeTab={activeTab}
                updatedData={updatedData}
            />
        )}
    </div>

}

export default ShippingServicesShippedLabel;
