import React, { createContext, useContext, useState } from 'react';
import ROUTES from 'src/lib/routes';
import useLocalStorage from 'src/hooks/useLocalStorage';

interface StickyNotification {
    id: string;
    group: string;
}

export interface StickyNotificationDetails {
    content: React.ReactNode[];
    cta: () => void;
    ctaLabel: string;
    isDismissable?: boolean;
    screen: 'all' | (typeof ROUTES)[keyof typeof ROUTES];
}
interface StickyNotificationGroup {
    [key: string]: {
        notifications: StickyNotification[];
    } & StickyNotificationDetails;
}

const StickyNotificationContext = createContext({
    stickyNotifications: {} as StickyNotificationGroup,
    addGroup: (groupName: string, groupDetails: StickyNotificationDetails) =>
        undefined as void,
    addNotification: (notification: StickyNotification) => undefined as void,
    removeNotification: (notificationId: string) => undefined as void,
});

export const StickyNotificationContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [stickyNotifications, setStickyNotifications] =
        useState<StickyNotificationGroup>({});
    const [dismissedNotifications, setDismissedNotifications] = useLocalStorage(
        'dismissed_notifications',
        [] as string[]
    );
    function addGroup(
        groupName: string,
        groupDetails: StickyNotificationDetails
    ) {
        setStickyNotifications((oldValue) => {
            if (!oldValue[groupName]) {
                return {
                    ...oldValue,
                    [groupName]: { ...groupDetails, notifications: [] },
                };
            }
            return oldValue;
        });
    }
    function addNotification(notification: StickyNotification) {
        setStickyNotifications((oldValue) => {
            const newValue = { ...oldValue };
            const uuid = `${notification.id}_${notification.group}`;
            if (
                !dismissedNotifications?.includes(uuid) &&
                oldValue[notification.group] &&
                !oldValue[notification.group]?.notifications.some(
                    (item) => item.id === notification.id
                )
            ) {
                newValue[notification.group].notifications = [
                    ...oldValue[notification.group].notifications,
                    notification,
                ];
            }
            return newValue;
        });
    }
    function removeNotification(notificationGroup: string) {
        setStickyNotifications((oldValue) => {
            const newValue = { ...oldValue };
            if (oldValue[notificationGroup]) {
                const safeDismissedNotification = dismissedNotifications || [];
                setDismissedNotifications([
                    ...safeDismissedNotification,
                    ...oldValue[notificationGroup].notifications.map(
                        (item) => `${item.id}_${notificationGroup}`
                    ),
                ]);
                newValue[notificationGroup].notifications = [];
            }
            return newValue;
        });
    }
    return (
        <StickyNotificationContext.Provider
            value={{
                addGroup,
                stickyNotifications,
                addNotification,
                removeNotification,
            }}
        >
            {children}
        </StickyNotificationContext.Provider>
    );
};

const useStickyNotification = () => useContext(StickyNotificationContext);

export default useStickyNotification;
