import React from 'react';
import { ButtonBase, Typography, makeStyles } from '@material-ui/core';
import FullWidthCard from 'src/components/cards/full-width-card';
import { fmtDollarsAndCents } from 'src/lib/utils/string-utils';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
  fullWidthCard: {
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
  backToCommunityWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '.5rem',
    marginBottom: '-1.5rem',
  },
  backToCommunityLeft: {
    flex: 3,
  },
  backToCommunityRight: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
  },
  backToCommunityText: {
    fontWeight: 500,
    fontSize: '1rem',
    letterSpacing: '-0.4pt',
    lineHeight: '1.4rem',
    maxWidth: '15rem',
  },
  backToCommunityAmountWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    letterSpacing: '-0.6pt',
  },
  amountSign: {
    fontSize: '1.8rem',
  },
  backToCommunityAmount: {
    fontSize: '2.8rem',
  },
  backToCommunityDecimalAmount: {
    fontSize: '1.2rem',
  },
  backToCommunityButtonGiveNow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: COLORS.brandCyan,
    textAlign: 'center',
    '& img': {
      paddingBottom: '.85rem',
    },
  },
});

type AvailableToDonateCardProps = {
  cardId: string;
  amountId: string;
  buttonId: string;
  availableDonationAmount?: number;
  onGiveNowPressed: () => void;
};

export default function AvailableToDonateCard({
  cardId,
  amountId,
  buttonId,
  onGiveNowPressed,
  availableDonationAmount = 0,
}: AvailableToDonateCardProps) {
  const classes = useStyles();
  const { dollars: availableToDonateDollars, cents: availableToDonateCents } =
    fmtDollarsAndCents(availableDonationAmount);
  return (
    <FullWidthCard hasManualShadow id={cardId} className={classes.fullWidthCard}>
      <div className={classes.backToCommunityWrapper}>
        <div className={classes.backToCommunityLeft}>
          <Typography className={classes.backToCommunityText}>
            Classroom Rewards
            <br />
            Available to Donate
          </Typography>
          <Typography
            className={classes.backToCommunityAmountWrapper}
            id={amountId}
          >
            <span className={classes.amountSign}>$</span>
            <span className={classes.backToCommunityAmount}>
              <span id="amount-text">{availableToDonateDollars}</span>
              <sup className={classes.backToCommunityDecimalAmount}>
                {availableToDonateCents}
              </sup>
            </span>
          </Typography>
        </div>
        <div className={classes.backToCommunityRight}>
          <ButtonBase
            className={classes.backToCommunityButtonGiveNow}
            onClick={onGiveNowPressed}
            id={buttonId}
          >
            <img
              src={require('../assets/icons/iconClassroomrewardsDonate.svg')}
              alt="classroom reward icon"
            />
            Give now
          </ButtonBase>
        </div>
      </div>
    </FullWidthCard>
  );
}
