import React from 'react';
import { useRouteMatch } from 'react-router';
import { ButtonBase } from '@material-ui/core';
import useStickyNotification, {
    StickyNotificationDetails,
} from 'src/hooks/useStickyNotification';
import useStyles from './styles';

interface StickyNotificationProps extends StickyNotificationDetails {
    count: number;
    group: string;
}

const StickyNotification = ({
    count = 1,
    group,
    content,
    ctaLabel,
    cta,
    screen,
    isDismissable = true,
}: StickyNotificationProps) => {
    const { removeNotification } = useStickyNotification();
    const match = screen === 'all' || !!useRouteMatch(screen);
    const classes = useStyles();
    return match ? (
        <div className={classes.container}>
            <span className={classes.content}>
                {count === 1 ? content[0] : content[1]}
                {count > 1 && <span>{`(${count})`}</span>}
            </span>
            <ButtonBase
                className={classes.ctaLabel}
                onClick={() => {
                    cta();
                    removeNotification(group);
                }}
            >
                {ctaLabel}
            </ButtonBase>
            {isDismissable && (
                <ButtonBase onClick={() => removeNotification(group)}>
                    <img
                        src={require('../../assets/icons/iconCloseBlack.svg')}
                        alt="close"
                    />
                </ButtonBase>
            )}
        </div>
    ) : null;
};

export default StickyNotification;
