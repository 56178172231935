import { atom, useAtom } from 'jotai';
import { getPatchDataFromPartialDiff } from 'src/lib/utils/patch-utils';
import { getUserInfo, patchUserInfo } from 'src/services/userServices';
import { User } from 'src/services/types';
import { useEffect, useCallback } from 'react';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { LOGIN_ROUTES } from 'src/routers/routes';
import { isListEnabled } from 'src/lib/api-client/lists';
import { useQuery } from 'react-query';
import { removeStplSessionIdUniqueId } from 'src/lib/utils/app-utils';
import useGuestMode from './useGuestMode';

interface UserState {
    userType: 'new' | 'migrated' | 'non-migrated' | null;
    email: string | undefined | null;
}

const userStateAtom = atom<UserState>({
    userType: null,
    email: '',
});

export function useUserState(): [
    UserState,
    (newUserState: Partial<UserState>) => void
] {
    const [userState, setUserStateAction] = useAtom(userStateAtom);

    const setUserState = useCallback(
        (newUserState: Partial<UserState>) => {
            setUserStateAction((currentUserState) => ({
                ...currentUserState,
                ...newUserState,
            }));
        },
        [setUserStateAction]
    );
    return [userState, setUserState];
}

/** common hook for fetching editable user info */
function useUserInfo(
    opts: {
        onSetUserInfoComplete?: (data: unknown, error: unknown) => void;
    } = {}
) {
    const {
        data: userInfo,
        error: userInfoError,
        isFetching: isFetchingUserInfo,
        refetch: refetchUserInfo,
    } = getUserInfo();

    const {
        mutate: mutateUserInfo,
        error: patchUserInfoError,
        isLoading: isPatchingUserInfo,
        status: patchUserStatus,
    } = patchUserInfo({
        onSuccess: () => {
            refetchUserInfo();
        },
        onSettled: opts?.onSetUserInfoComplete,
    });
    const { isGuestModeOn } = useGuestMode();
    function setUserInfo(newUserInfo: Partial<User>) {
        // get diff between new and old info and begin sending patch data call
        mutateUserInfo(
            getPatchDataFromPartialDiff(userInfo || {}, newUserInfo)
        );
    }

    const { data: rewardsShippingListEnabled } = useQuery(
        ['rewardsShippingListEnabled'],
        () => isListEnabled('RewardShippers'),
        {
            refetchOnMount: true,
        }
    );

    useEffect(() => {
        if (
            userInfoError &&
            !isFetchingUserInfo &&
            SessionManager.isLoggedIn &&
            !isGuestModeOn
        ) {
            removeStplSessionIdUniqueId();
            window.location.replace(`somniapp${LOGIN_ROUTES.login}`);
            localStorage?.removeItem('login-details');
            SessionManager?.session?.killSession();
        }
    }, [userInfoError, isFetchingUserInfo]);

    return {
        userInfo,
        userInfoError,
        refetchUserInfo,
        isFetchingUserInfo,
        setUserInfo,
        patchUserStatus,
        patchUserInfoError,
        isPatchingUserInfo,
        isProcessing: isFetchingUserInfo || isPatchingUserInfo,
        error: userInfoError || patchUserInfoError,
        isRewardShipperEnable: rewardsShippingListEnabled?.isenabled,
    };
}

export default useUserInfo;
