import React, { useState } from 'react';
import {
    Backdrop,
    Button,
    Dialog,
    DialogProps,
    makeStyles,
    withStyles,
    Typography,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import useCardCanvas from 'src/hooks/useCardCanvas';
import { format } from 'date-fns';
import { fmtDollarsAndCents } from 'src/lib/utils/string-utils';
import { Player } from '@lottiefiles/react-lottie-player';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';

const YellowBackdrop = withStyles({
    root: {
        background: COLORS.rewardsYellow,
    },
})(Backdrop);
const BlueBackdrop = withStyles({
    root: {
        background: COLORS.brandCyan,
    },
})(Backdrop);

export type RewardsPromoCardDialogProps = DialogProps & {
    variant: 'school' | 'teacher';
    cardOwner?: string;
    amountAvailable?: number;
    // number to render
    cardNumber?: string | null;
    pin?: string | null;
    // iso date string | null
    expiration?: string | null;
};

// icon radius in rem
const ICON_RADIUS = 3;
// overhang for lottie icon
const CIRCULAR_OVERHANG = '3.5';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: `${ICON_RADIUS + 3}rem 1rem 6rem 1rem`,
        overflow: 'visible',
    },
    container: {
        backgroundColor: '#ffc916',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: `-${ICON_RADIUS}rem`,
        padding: '0 1.5rem',
    },
    cardOwner: {
        fontWeight: 'bold',
        fontSize: '1.8rem',
        padding: '.75rem',
        textAlign: 'center',
    },
    amountAvailableWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontWeight: 'bold',
        letterSpacing: '-0.6pt',
    },
    amountSign: {
        fontSize: '2rem',
    },
    amount: {
        fontSize: '5.2rem',
        marginTop: '-1.5rem',
    },
    decimalAmount: {
        fontSize: '2.1rem',
        marginTop: '-0.2rem',
    },
    classroomRewardsTitle: {
        fontSize: '1rem',
        fontWeight: 500,
        paddingBottom: '.5rem',
        textAlign: 'center',
    },
    renderedCardNumber: { width: '100%', maxWidth: '20rem', height: '7rem' },
    cardNumber: {
        marginTop: '.5rem',
        padding: '.1rem .5rem',
        zIndex: 1,
        fontWeight: 500,
        backgroundColor: COLORS.primaryWhite,
    },
    pin: {
        fontSize: '2rem',
        fontWeight: 'bold',
        letterSpacing: '-0.51pt',
        textTransform: 'uppercase',
        marginTop: '-3.5rem',
    },
    photoIdDisclaimer: {
        color: COLORS.secondaryNeutralBlack,
        fontSize: '1rem',
        width: '80%',
        textAlign: 'center',
        marginBottom: '1rem',
    },
    cashierDisclaimer: {
        color: COLORS.secondaryNeutralBlack,
        fontSize: '0.75rem',
        fontWeight: 'bold',
        marginTop: '2rem',
        width: '80%',
        textAlign: 'center',
    },
    expirationDate: { paddingTop: '1rem', fontWeight: 500 },
    closeButton: {
        marginTop: '6rem',
        marginBottom: '-7rem',
        width: '100%',
        maxWidth: '17rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: COLORS.disabledBackgroundGray,
            color: COLORS.disabledTextGray,
        },
    },
    lottiePin: {
        marginTop: '2rem',
        textAlign: 'center',
    },
    footer: {
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        overflow: 'visible',
        backgroundColor: COLORS.secondaryNeutralLightGray,
    },
    footerTitle: {
        paddingTop: '0.25rem',
        fontWeight: 500,
        textAlign: 'center',
    },
    footerSubTitle: {
        fontSize: '0.75rem',
        textAlign: 'center',
        paddingBottom: '0.25rem',
    },
});

/**
 * Promo card dialog to show rewards number info.
 */
export default function RewardsPromoCardDialog(
    props: RewardsPromoCardDialogProps
) {
    const {
        variant,
        cardOwner,
        amountAvailable,
        cardNumber,
        pin,
        expiration,
        onClose,
        open,
        ...rest
    } = props;
    function close(event: any) {
        if (onClose) {
            onClose(event, 'backdropClick');
        }
    }
    const classes = useStyles();
    const [showDialogContent, setShowDialogContent] = useState(false);

    const { canvasRef, cardRedraw } = useCardCanvas(cardNumber);

    const expirationDate = expiration ? new Date(expiration) : null;

    const { dollars: availableDollars, cents: availableCents } =
        fmtDollarsAndCents(amountAvailable || 0);

    const animationPlaying = (e: string) => {
        // waiting for animation to start/play before displaying Dialog content
        if (e === 'play') {
            setShowDialogContent(true);
        }
    };
    const isPromoCardOpen = useIsActiveScreen(SCREEN_NAMES.PROMO_CARD, open);

    return (
        <Dialog
            classes={{
                paper: classes.paper,
                container: classes.container,
            }}
            onAnimationEnd={cardRedraw}
            onTransitionEnd={cardRedraw}
            onClose={onClose}
            BackdropComponent={
                variant === 'school' ? YellowBackdrop : BlueBackdrop
            }
            scroll="body"
            open={isPromoCardOpen}
            {...rest}
        >
            <div className={classes.content}>
                <Player
                    onEvent={animationPlaying}
                    autoplay
                    loop
                    src={
                        variant === 'school'
                            ? require('../assets/lotties/Promo-Card-School.json')
                            : require('../assets/lotties/Promo-Card-Teacher.json')
                    }
                    style={{ marginTop: `-${CIRCULAR_OVERHANG + 1}rem` }}
                />
                {showDialogContent && (
                    <>
                        <Typography className={classes.classroomRewardsTitle}>
                            Staples Connect Classroom Rewards
                        </Typography>
                        <Typography
                            id="promo-card-owner-text"
                            className={classes.cardOwner}
                        >
                            {cardOwner}
                        </Typography>
                        <Typography
                            className={classes.amountAvailableWrapper}
                            id="promo-card-dollar-amount-text"
                        >
                            <span className={classes.amountSign}>$</span>
                            <span className={classes.amount}>
                                {availableDollars}
                            </span>
                            <span className={classes.decimalAmount}>
                                {availableCents}
                            </span>
                        </Typography>
                        <Typography className={classes.photoIdDisclaimer}>
                            Present a valid photo ID at checkout.
                        </Typography>
                        <canvas
                            ref={canvasRef}
                            className={classes.renderedCardNumber}
                        />
                        <Typography className={classes.cardNumber}>
                            {cardNumber}
                        </Typography>
                        <div className={classes.lottiePin}>
                            <Player
                                autoplay
                                loop
                                src={
                                    variant === 'school'
                                        ? require('../assets/lotties/PIN-Code-Highlight-School.json')
                                        : require('../assets/lotties/PIN-Code-Highlight-Teacher.json')
                                }
                            />
                            <Typography className={classes.pin}>
                                PIN: {pin}
                            </Typography>
                        </div>
                        {expirationDate && (
                            <Typography className={classes.expirationDate}>
                                Expires on {format(expirationDate, 'MM/dd/yy')}
                            </Typography>
                        )}
                        <Button className={classes.closeButton} onClick={close}>
                            Close
                        </Button>
                    </>
                )}
            </div>
            <div className={classes.footer}>
                <Typography className={classes.footerTitle}>
                    Cashier instructions
                </Typography>
                <Typography className={classes.footerSubTitle}>
                    Tender as e-giftcard/cashcard at the register.
                </Typography>
            </div>
        </Dialog>
    );
}
