import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import COLORS from 'src/lib/colors';

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: COLORS.secondaryNeutralBlack,
      '&$checked': {
        transform: 'translateX(12px)',
        color: COLORS.primaryWhite,
        '& + $track': {
          opacity: 1,
          backgroundColor: COLORS.brandRed,
          borderColor: COLORS.brandRed,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: 'none',
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

export default CustomSwitch;
