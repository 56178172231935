import React, { ChangeEvent, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    makeStyles,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import clsx from 'clsx';
import COLORS from 'src/lib/colors';
import BlackCheckbox from 'src/components/black-checkbox';
import ErrorDialog from 'src/components/error-dialog';
import FullWidthCard from 'src/components/cards/full-width-card';
import { getUserLists } from 'src/lib/api-client/lists';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useQuery } from 'react-query';
import { useToastMessageContext } from 'src/lib/contexts/toast-message-context';
import ListCreateEditDialog from 'src/components/list-create-edit-dialog';
import { ListItemResult } from 'src/services/types';
import useAddItem, { UseAddItemProduct } from 'src/hooks/useAddItem';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { ListModalMode, ListMode } from 'src/screens/lists/list.types';
import LoadingOverlay from './loading-overlay';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem 1rem 0 0 ',
        minHeight: '50%',
        maxHeight: '75%',
        width: '100%',
        margin: 0,
        position: 'fixed',
        bottom: 0,
    },
    titleWrapper: {
        padding: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
    },
    title: {
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: '-0.5pt',
        alignSelf: 'center',
        marginTop: '-1.6rem',
        marginBottom: '-.5rem',
    },
    titlePadding: { marginTop: '2rem' },
    dialogContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '310px',

        '& .MuiFormControlLabel-label': {
            width: '80%',
        },
    },
    closeIcon: {
        justifyContent: 'flex-end',
        '& img': {
            height: '28px', // specify image sizing to reduce layout shift
            width: '28px',
        },
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        marginTop: '1rem',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
    },
    actionsWrapper: {
        flexDirection: 'column',
    },
    primaryButton: {
        width: '90%',
        margin: '2.5rem 5% 1rem 5%',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    buttonDialogAction: {
        display: 'block',
        marginBottom: '1rem',
    },
    addToListButtonContainer: {
        padding: '2rem 3rem 0 3rem',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '10px',
    },
    addToListButton: {
        borderRadius: '1.5rem',
        padding: '.5rem 5rem',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '25rem',
        textTransform: 'none',
        fontSize: '1.2rem',
        background: COLORS.homeBlack,
        color: COLORS.primaryWhite,
        boxShadow: 'none !important',
        '&:hover, &:active': {
            backgroundColor: COLORS.homeBlack,
            color: COLORS.primaryWhite,
        },
    },
    checkboxLabel: {
        margin: '1rem 0',
        width: '100%',
        justifyContent: 'space-between',
        borderBottom: '1px solid gray',
        paddingBottom: '1.5rem',
    },
    listName: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingRight: '1rem',
        width: '100%',
    },
    listDescription: {
        textAlign: 'left',
        fontSize: '.85rem',
        display: 'inline-block',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingRight: '1rem',
        width: '100%',
    },
    oneLineClamp: {
        WebkitLineClamp: 1,
    },
    threeLineClamp: {
        WebkitLineClamp: 3,
    },
    body1: {
        width: '100%',
    },
    disclaimerContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    disclaimerIcon: {
        width: '100%',
        maxWidth: '10rem',
        marginBottom: '1rem',
        marginTop: '1.5rem',
        height: '160px', // specify image sizing to reduce layout shift
    },
    disclaimerText: {
        fontSize: '1.1rem',
        fontWeight: 500,
    },
    loader: {
        marginTop: '2rem',
    },
});

type ListCreateEditDialogProps = {
    open: boolean;
    handleClose?: () => void;
    productItem?: UseAddItemProduct;
    quantity: number;
    storeId: string | undefined;
};

export default function ChooseListDialog(props: ListCreateEditDialogProps) {
    const { open, handleClose, productItem, quantity, storeId } = props;
    const { userListUpdateCount, notifyUserListUpdate } =
        useUserUpdateContext();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const [listArray, setListArray] = useState<number[]>([]);
    const [newListCreated, setNewListCreated] = useState(false);
    const [error, setError] = useState<string | null>();
    const { setToastMessage } = useToastMessageContext();
    const [listMode, setListMode] = useState<ListModalMode>({
        mode: null,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { data, isSuccess } = useQuery(
        ['getUserLists', userListUpdateCount],
        () => getUserLists(),
        {
            refetchOnReconnect: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const classes = useStyles();

    useEffect(() => {
        if (isSuccess && data && data.length > 0 && newListCreated) {
            setListArray([data[0].id]);
            setNewListCreated(false);
        }
    }, [data]);

    function createNewList() {
        analyticsCustomEvent(eventMetricsMap.ereceipts, {
            click_text: 'create a new list',
            element_location: 'choose list option',
        });
        optimoveEvents(eventMetricsMap.scm_ereceipts, {
            click_text: 'create a new list',
            element_location: 'choose list option',
        });
        setListMode({
            mode: 'create',
            editDetails: undefined,
        });
    }
    const handleAddedModalClose = () => {
        setListMode({
            mode: null,
        });
    };

    function handleListModalClose() {
        setListMode((state) => ({ ...state, open: false }));
    }

    function handleErrorClose() {
        setError(null);
    }

    function onListDialogComplete(success: boolean, mode: ListMode) {
        if (success) {
            notifyUserListUpdate();
            customEvent(eventMetricsMap.list_created);
            setListMode({
                mode: null,
            });
            setError(null);
            if (mode === 'create') {
                setToastMessage({ title: 'List created!' });
                setNewListCreated(true);
            } else if (mode === 'edit') {
                setToastMessage({
                    title: 'List rename successful!',
                    details: 'Your list has been renamed.',
                });
            }
        } else {
            setError(mode);
        }
    }

    function onAddItemCompleted() {
        setIsLoading(false);

        if (handleClose) {
            handleClose();
        }
    }

    const { commitToAddition } = useAddItem({
        listArray,
        onComplete: onAddItemCompleted,
    });

    const addListClick = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked === true) {
            setListArray(Array.from(new Set(listArray.concat(+e.target.name))));
        } else {
            setListArray(listArray.filter((x) => x !== +e.target.name));
        }
    };

    const commitAddToLists = () => {
        if (listArray.length > 0 && productItem) {
            analyticsCustomEvent(eventMetricsMap.ereceipts, {
                click_text: 'add to list',
                element_location: 'choose list option',
            });
            optimoveEvents(eventMetricsMap.scm_ereceipts, {
                click_text: 'add to list',
                element_location: 'choose list option',
            });
            setIsLoading(true);
            commitToAddition(productItem, { quantity, storeId });
        }
    };

    const commitAddToListsAll = (type: string) => {
        if (type === 'addToList' && productItem) {
            setIsLoading(true);
            commitToAddition(productItem, { quantity, storeId });
        }
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="list-create-edit-dialog-title"
                classes={{
                    paper: classes.paper,
                }}
            >
                <DialogTitle
                    disableTypography
                    id="list-create-edit-dialog-title"
                    className={classes.titleWrapper}
                >
                    <Button
                        fullWidth
                        onClick={handleClose}
                        className={classes.closeIcon}
                        id="close-button"
                    >
                        <img
                            src={require('../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </Button>
                    <Typography className={classes.title}>
                        Choose List
                    </Typography>
                </DialogTitle>
                {data ? (
                    <>
                        <DialogContent className={classes.dialogContentWrapper}>
                            {data?.length > 0 ? (
                                data.map(
                                    (item: ListItemResult, index: number) => (
                                        <FormControlLabel
                                            key={item.id}
                                            className={classes.checkboxLabel}
                                            control={
                                                <BlackCheckbox
                                                    onChange={addListClick}
                                                    name={item.id.toString()}
                                                    checked={
                                                        listArray.indexOf(
                                                            item.id
                                                        ) !== -1
                                                    }
                                                />
                                            }
                                            label={
                                                <>
                                                    <Typography
                                                        className={clsx(
                                                            classes.listName,
                                                            classes.oneLineClamp,
                                                            classes.body1
                                                        )}
                                                        id={`list-item-${index}-name-text`}
                                                        variant="body1"
                                                    >
                                                        {item.productListName}
                                                    </Typography>
                                                    <Typography
                                                        className={clsx(
                                                            classes.listDescription,
                                                            classes.threeLineClamp,
                                                            classes.body1
                                                        )}
                                                        id={`list-item-${index}-description-text`}
                                                        variant="body1"
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                </>
                                            }
                                            labelPlacement="start"
                                        />
                                    )
                                )
                            ) : (
                                <FullWidthCard id="empty-list-disclaimer-card">
                                    <Box className={classes.disclaimerContent}>
                                        <img
                                            className={classes.disclaimerIcon}
                                            src={require('../assets/icons/iconNolist.svg')}
                                            alt="no lists"
                                        />
                                        <Typography
                                            className={classes.disclaimerText}
                                            id="empty-list-disclaimer-text"
                                        >
                                            There are no lists yet. Create a new
                                            list to get started.
                                        </Typography>
                                    </Box>
                                </FullWidthCard>
                            )}
                        </DialogContent>
                        <DialogActions className={classes.buttonDialogAction}>
                            <div className={classes.addToListButtonContainer}>
                                {data && data?.length > 0 && (
                                    <Button
                                        onClick={commitAddToLists}
                                        className={classes.addToListButton}
                                        disabled={listArray.length < 1}
                                        variant="contained"
                                    >
                                        Add to list
                                    </Button>
                                )}
                                <Button
                                    onClick={createNewList}
                                    className={classes.addToListButton}
                                    hidden={listArray.length > 0}
                                >
                                    Create a new list
                                </Button>
                            </div>
                        </DialogActions>
                    </>
                ) : (
                    <DialogContent className={classes.dialogContentWrapper}>
                        <CircularProgress className={classes.loader} />
                    </DialogContent>
                )}
            </Dialog>
            <ListCreateEditDialog
                open={listMode.mode === 'create'}
                mode={listMode.mode}
                handleClose={handleAddedModalClose}
                onComplete={onListDialogComplete}
                lowestValue={0}
                addList={commitAddToListsAll}
                type="addListDirect"
            />
            <ErrorDialog open={!!error} handleClose={handleErrorClose} />
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
        </>
    );
}
