/* eslint-disable import/prefer-default-export */
import axiosInstance from 'src/lib/api-client/custom-axios';
import { AxiosResponse } from 'axios';
import {
    rewardsSaveCouponURL,
    rewardsSaveScannedCouponURL,
    walletAccountTypeURL,
    walletGetStaplesRewardsByIdURL,
    walletUserChamberMemberURL,
} from 'src/services/urls';

export async function getWalletAccountType(
    number: string | null | undefined
): Promise<AxiosResponse<any>> {
    const activities = await axiosInstance.get(
        `${walletAccountTypeURL}/${number}`
    );
    return activities || {};
}

export const getStaplesRewardsRedeemData = async (
    rewardNumber: string | number | undefined
): Promise<AxiosResponse<any>> =>
    axiosInstance.get(
        `${process.env.REACT_APP_REWARDS_API_STAPLES_REWARDS_REDEEM_GET_URL}`.replace(
            '%rewardId%',
            `${rewardNumber}`
        )
    );

export const getStaplesRewardsData = async (
    rewardNumber: string | null | undefined
): Promise<AxiosResponse<any>> =>
    axiosInstance.get(
        `${process.env.REACT_APP_REWARDS_API_STAPLES_REWARDS_GET_URL}`.replace(
            '%rewardId%',
            `${rewardNumber}`
        )
    );

export async function getAddCouponData(data: {
    rewardsNumber: string | number;
    couponCode: string | number | undefined;
}) {
    const response = await axiosInstance.post(
        `${process.env.REACT_APP_ADD_SCAN_COUPONS_URL}`,
        {
            rewardsNumber: data.rewardsNumber,
            couponnumber: data.couponCode,
        }
    );
    return response;
}

export async function getScanCouponData(data: {
    rewardsNumber: string | number;
    couponCode: string | number | undefined;
}) {
    const response = await axiosInstance.post(
        `${rewardsSaveScannedCouponURL}`,
        {
            rewardsNumber: data.rewardsNumber,
            couponCode: data.couponCode,
        }
    );
    return response;
}
export const getDigitalCouponsData = async (
    rewardNumber: string | number | undefined
) => {
    const response = await axiosInstance.get(
        `${walletGetStaplesRewardsByIdURL}`.replace(
            '%rewardId%',
            `${rewardNumber}`
        )
    );
    let list: any = [];
    if (response?.data?.couponDataList?.length) {
        const filterData = response?.data?.couponDataList.find(
            (data: any) =>
                data?.category === 'Coupon' &&
                data?.couponInfo?.isNewCoupon &&
                data?.couponInfo?.offerType === 'Hero'
        );
        const isAllCoupnsRedeemed = response?.data?.couponDataList.filter(
            (data: any) => data?.couponInfo?.isRedeemed
        );
        if (
            response?.data?.couponDataList.length > 0 &&
            isAllCoupnsRedeemed.length > 0 &&
            response?.data?.couponDataList.length === isAllCoupnsRedeemed.length
        ) {
            list = {
                title: 'GREAT JOB!',
                shortText2: 'You have redeemed all digital coupons.',
                shortText3: 'New coupons every Sunday',
                link: '',
                type: 'Coupons',
                imageURL: require('../../assets/icons/noCouponImageHome.svg'),
            };
        } else if (filterData && filterData?.couponInfo?.isRedeemed === false) {
            list = {
                title: 'LATEST DIGITAL COUPONS',
                shortText1: filterData?.couponInfo?.shortText1,
                shortText2: filterData?.couponInfo?.shortText2,
                link: 'View all digital coupons',
                type: 'Coupons',
                imageURL: filterData?.couponInfo?.imageURL,
            };
        } else {
            list = {
                title: 'LATEST DIGITAL COUPONS',
                shortText2: 'More coupons available',
                link: 'View all digital coupons',
                type: 'Coupons',
                imageURL: require('../../assets/icons/noCouponImageHome.svg'),
            };
        }

        return list;
    }
    return null;
};

export async function saveDigitalCoupon(data: {
    rewardsNumber: string | number;
    couponCode: number;
    isSaved: boolean;
}) {
    const response = await axiosInstance.post(`${rewardsSaveCouponURL}`, {
        rewardsNumber: data.rewardsNumber,
        couponCode: data.couponCode,
        isSaved: data.isSaved,
    });
    return response;
}

// REACT_APP_USER_API_CHAMBER_MEMBER

export const getCustomerPriceTypes = async (
    rewardsNumber: string | number | undefined | null
): Promise<AxiosResponse<any>> =>
    axiosInstance.get(
        `${process.env.REACT_APP_USER_API_CHAMBER_MEMBER}`.replace(
            '%rewardsNumber%',
            `${rewardsNumber}`
        )
    );


export const getClassRoomRewardsDonation = async () => {
    const response = await axiosInstance.get(
        `${process.env.REACT_APP_REWARDS_API_CLASSROOM}`.replace('%functionalityname%', `ClassRoomRewardsDonation`)
    );
    if (response.data) {
        localStorage.setItem('classRoomRewards', response.data.isenabled)
    }
};


export const getClassRoomRewards = async () => {
    const response = await axiosInstance.get(
        `${process.env.REACT_APP_REWARDS_API_CLASSROOM}`.replace('%functionalityname%', `ClassRoomRewardsEnrollment`)
    );
    if (response.data) {
        localStorage.setItem('classRoomRewardsEnrolled', response.data.isenabled)
    }
};
