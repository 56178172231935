import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Log from 'src/lib/logging';

export const axiosInstance: AxiosInstance = axios.create({
    headers: {
        // always add the api key (needed for API Management)
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_PUBLIC_API_KEY || '',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

/**
 * Attempt to add the token stored in localstorage to all axios requests.
 * Adjust this method we ever chsange our storage policy for our AuthService
 */
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => ({
    ...config,
    headers: {
        ...config.headers,
    },
}
));

/** log requests/responses */
axios.interceptors.request.use((request: any) => {
    Log.axios('Request', JSON.stringify(request, null, 2));
    return request;
});
axios.interceptors.response.use((request: any) => {
    Log.axios('Response', JSON.stringify(request, null, 2));
    return request;
});

/** for all requests, for now, we create and use a common axios instance */
export default axiosInstance;