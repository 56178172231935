/* eslint import/namespace: [2, { allowComputed: false }] */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SessionManager, COMMON_HEADERS } from './sessionManager';
import { getSubscriptionKey } from './sign-on';

export const axiosInstanceCms: AxiosInstance = axios.create({
  headers: {
    // always add the api key (needed for API Management)
    'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

// export const getCmsShopDepartmentData = async (
//   data: any
// ): Promise<any | null> => {
//   const subsccriptionKey = await getSubscriptionKey('StaplesCMS');
//   axiosInstanceCms.interceptors.request.use((config: any) => ({
//     ...config,
//     headers: {
//       ...config.headers,
//       ...COMMON_HEADERS,
//       client_id: subsccriptionKey,
//       Authorization: `Bearer ${SessionManager.access_token}`,
//     },
//   }));
//   const url = `${process.env.REACT_APP_EASY_API_CMS}`;
//   try {
//     const reportData = await axiosInstanceCms.post(url, data);
//     console.log({ reportData });
//     if (reportData.data) {
//       const getTopNavigationOptionsLeft =
//         reportData?.data?.['sparq-index']?.topNavigationOptionsLeft;
//       const filterDepatmentData = getTopNavigationOptionsLeft.find(
//         (menuData: any) => menuData.componentName === 'NavigationMegaMenu'
//       );
//       const filterDepatmentDataWithServices = getTopNavigationOptionsLeft.find(
//         (menuData: any) => menuData.ariaLabel === 'Services Menu'
//       );
//       const filterOutMobileOnlyData =
//         filterDepatmentData?.navigationFlyouts?.reduce(
//           (acc: any, mData: any) => {
//             const mobileOnlyData = mData?.navigationFlyoutsItems?.filter(
//               (items: any) =>
//                 items?.permissionlimit?.includes('MobileOnly') ||
//                 !items?.permissionlimit
//             );
//             acc = [...acc, ...mobileOnlyData];
//             return acc;
//           },
//           []
//         );
//       const filterOutMobileOnlyDataServices =
//         filterDepatmentDataWithServices?.navigationFlyouts?.reduce(
//           (acc: any, mData: any) => {
//             const mobileOnlyData = mData?.navigationFlyoutsItems?.filter(
//               (items: any) =>
//                 items?.permissionlimit?.includes('MobileOnly') ||
//                 !items?.permissionlimit
//             );
//             acc = [...acc, ...mobileOnlyData];
//             return acc;
//           },
//           []
//         );
//       console.log({
//         filterDepatmentData,
//         filterOutMobileOnlyData,
//         filterDepatmentDataWithServices,
//         filterOutMobileOnlyDataServices,
//       });
//       return { filterOutMobileOnlyData, filterOutMobileOnlyDataServices };
//     }
//     return null;
//   } catch (e) {
//     return e;
//   }
// };


export const getCmsShopDepartmentList = async (
  id: string
): Promise<any | null> => {
  const subsccriptionKey = await getSubscriptionKey('StaplesCMS');
  axiosInstanceCms.interceptors.request.use((config: any) => ({
    ...config,
    headers: {
      ...config.headers,
      ...COMMON_HEADERS,
      client_id: subsccriptionKey,
      Authorization: `Bearer ${SessionManager.access_token}`,
    },
  }));
  const url = `${process.env.REACT_APP_EASY_API_CMS_List}`;
  const data = { "tenantId": "STAPLES_DOT_COM", "channel": "sdc", "channelId": "sdc", "contentIds": [id] }

  try {
    const details = await axiosInstanceCms.post(url, data);
    console.log({ details });
    if (details?.data) {
      return details?.data;
    }
    return null;
  } catch (e) {
    return e;
  }
};

export const getCmsShopDepartmentData = async (
  data: any
): Promise<any | null> => {
  const subsccriptionKey = await getSubscriptionKey('StaplesCMS');
  axiosInstanceCms.interceptors.request.use((config: any) => ({
    ...config,
    headers: {
      ...config.headers,
      ...COMMON_HEADERS,
      client_id: subsccriptionKey,
      Authorization: `Bearer ${SessionManager.access_token}`,
    },
  }));
  const url = `${process.env.REACT_APP_EASY_API_CMS}`;
  try {
    const reportData = await axiosInstanceCms.post(url, data);
    console.log({ reportData });
    if (reportData.data) {
      const getTopNavigationOptionsLeft =
        reportData.data?.topNavigationOptionsLeft;
      const findMegaMenuId = getTopNavigationOptionsLeft?.find(
        (menuData: any) => menuData.componentName === 'NavigationMegaMenu'
      )?.metadata?.id;
      console.log("🚀 ~ findMegaMenuId:", findMegaMenuId)

      const findMoreDataDependOnId = await getCmsShopDepartmentList(findMegaMenuId)
      console.log("🚀 ~ findMoreDataDependOnId:", findMoreDataDependOnId)

      const filterOutMobileOnlyData =
        findMoreDataDependOnId?.[0]?.navigationFlyouts?.reduce(
          (acc: any, mData: any) => {
            console.log("🚀 ~ mData:", mData)
            const mobileOnlyData = mData?.navigationFlyoutsItems?.filter(
              (items: any) =>
                items?.permissionlimit?.includes('MobileOnly') ||
                !items?.permissionlimit
            );
            acc = [...acc, ...mobileOnlyData];
            return acc;
          },
          []
        );

      console.log({
        filterOutMobileOnlyData,
      });
      return {
        filterOutMobileOnlyData,
      };
    }
    return null;
  } catch (e) {
    return e;
  }
};
