/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconClosePlain({
    height = 10,
    width = 10,
    fillColor = 'fillBlack',
}: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 5.72158L9.12904 9.85062C9.32822 10.0498 9.65144 10.0498 9.85062 9.85062C10.0498 9.65144 10.0498 9.32822 9.85062 9.12904L5.72158 5L9.85062 0.870964C10.0498 0.671784 10.0498 0.348565 9.85062 0.149385C9.65144 -0.049795 9.32822 -0.0497949 9.12904 0.149385L7.70026 1.57816L5 4.27842L0.870964 0.149385C0.671784 -0.049795 0.348565 -0.049795 0.149385 0.149385C-0.049795 0.348565 -0.049795 0.671784 0.149385 0.870964L4.27842 5L0.149385 9.12904C-0.0497949 9.32822 -0.0497949 9.65144 0.149385 9.85062C0.348565 10.0498 0.671784 10.0498 0.870964 9.85062L5 5.72158Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconClosePlain;
