import { makeSearchString } from 'src/lib/utils/url-utils';
import useRouter from 'src/hooks/useRouter';

export default function useAllRecentActivity() {
    const { history, pathname, query } = useRouter();
    function openAllRecentActivity() {
        history.push({
            pathname,
            search: makeSearchString({ recentActivity: 'all' }),
        });
    }

    function closeAllRecentActivity() {
        history.replace({
            pathname,
            search: undefined,
        });
    }
    const allRecentActivityOpen = !!(query as any)?.recentActivity;
    return {
        openAllRecentActivity,
        closeAllRecentActivity,
        allRecentActivityOpen,
    };
}
