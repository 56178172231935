import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import { useRouter } from 'src/hooks';
import styles from './styles.module.scss';

function WelcomeAnimation() {
    const { replace, location } = useRouter();
    const { onCompleteNavigateTo } = location.state as any;
    return (
        <div className={styles.container}>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={styles.animationContainer}
                >
                    <Player
                        onEvent={(event) => {
                            if (event === 'complete') {
                                replace(onCompleteNavigateTo);
                            }

                        }}
                        autoplay
                        // loop
                        src={require('../../../assets/lotties/rewards-enrollment/easy-rewards-logo-transition.json')}
                        style={{ height: '100vh', width: '100vw' }}
                    />
                </motion.div>
            </AnimatePresence>
        </div>
    );
}

export default WelcomeAnimation;
