import { RewardsAccountUserType } from 'src/services/types';

// Shared types across enroll steps and controllers
export type EnrollSteps =
    | 'step1'
    | 'step2'
    | 'step3'
    | 'selectSchool'
    | 'confirmSchool'
    | 'signUp'
    | 'success'
    | 'srwConfirmation';

export type RewardsControllerState = {
    enrollSubStep?: string;
    enrollStep?: EnrollSteps;
    userType?: RewardsAccountUserType;
    accountIdentifier?: string;
    maskedCredential?: string;
    selectedSchoolIds?: string;
    flowName?: string;
    type?: string;
};

export type UpdateControllerValuesFn = (state: RewardsControllerState) => void;

export type ErrorType =
    | 'match-not-found'
    | 'no-email'
    | 'school-claimed'
    | 'max-schools'
    | 'min-schools'
    | 'account-exists'
    | 'max-first-and-last-name'
    | 'max-first-name'
    | 'max-last-name'
    | 'no-phone'
    | 'no-phone-email'
    | 'unknown';

export type ErrorSource = 'step2' | 'step3' | 'signUp' | 'confirmSchool';
export type OnErrorFn = (
    errorSource: ErrorSource,
    newErrorType: ErrorType,
    /**
     * Any additional detail we need for the error.
     * If school-claimed, this is the name of the school.
     */
    errorDetail: string
) => void;

export type RewardsEnrollUser = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    shopFor?: string;
    companyName?: string;
    industry?: string | null;
};

export type CommonStepProps = {
    updateControllerValues: UpdateControllerValuesFn;
    updateIsFetching?: (isFetching: boolean) => void;
    onError?: OnErrorFn;
    userType?: RewardsAccountUserType;
    accountIdentifier?: string;
    maskedCredential?: string;
    enrollStep?: string;
    isFinished: boolean;
    finishFlow: () => void;
    RewardsEnrollUser?: RewardsEnrollUser;
    enrollSubStep?: string;
    flowName?: string;
    type?: string;
    selectedRole?: string;
};

export const getRewardsErrorType = (createRewardsAccountCode: string) => {
    switch (createRewardsAccountCode) {
        case 'FirstAndLastNameTooLong':
            return 'max-first-and-last-name';
        case 'FirstNameTooLong':
            return 'max-first-name';
        case 'LastNameTooLong':
            return 'max-last-name';
        case 'AccountAlreadyExists':
            return 'account-exists';
        default:
            return 'unknown';
    }
};
