import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import RewardsEnrollStep1 from 'src/screens/rewards-enroll-flow/rewards-enroll-step1';
import RewardsEnrollStep2 from 'src/screens/rewards-enroll-flow/rewards-enroll-step2';
import RewardsEnrollStep3 from 'src/screens/rewards-enroll-flow/rewards-enroll-step3';
import RewardsEnrollSuccess from 'src/screens/rewards-enroll-flow/rewards-enroll-success';
import RewardsEnrollSignUp from 'src/screens/rewards-enroll-flow/rewards-enroll-signup';
import RewardsEnrollConfirmSchool from 'src/screens/rewards-enroll-flow/rewards-enroll-confirmSchool';
import RewardsEnrollSelectSchool from 'src/screens/rewards-enroll-flow/rewards-enroll-selectSchool';
import {
    CommonStepProps,
    EnrollSteps,
    RewardsControllerState,
} from 'src/screens/rewards-enroll-flow/types';
import {
    makeStyles,
    Box,
    Typography,
    Collapse,
    Fade,
    ButtonBase,
    Dialog,
    Slide,
    Container,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import PaginationDots from 'src/components/pagination-dots';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import useUserInfo from 'src/hooks/useUserInfo';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { TransitionProps } from '@material-ui/core/transitions';
import {
    inferSchools,
    SimplifiedSchool,
    splitIds,
    useCachedSchools,
} from 'src/lib/utils/school-utils';
import useRouter from 'src/hooks/useRouter';
import SRWConfirmation from 'src/screens/rewards-wallet/srw-confirmation';
import useError from 'src/screens/rewards-enroll-flow/use-error';
import ROUTES from 'src/lib/routes';
import { RewardsAccountUserType } from 'src/services/types';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        background: COLORS.whiteSmoke,
        minHeight: '100vh',
    },
    screenSrwConfirmation: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        background: COLORS.primaryWhite,
        minHeight: '100vh',
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    backIcon: {
        fontSize: '1.7rem',
    },
    backIconHidden: {
        fontSize: '1.7rem',
        color: 'transparent',
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    backIconBalance: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
        color: 'transparent',
    },
    paginationWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        paddingTop: '.5rem',
    },
    stepDescription: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 2,
        margin: '0rem 2rem',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    screenDescription: {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 600,
        padding: '0rem 4rem 1rem',
        letterSpacing: '-0.30pt',
        lineHeight: '1.3rem',
    },
});

/**
 * Additional cleanup might be possible, as of now we are including every possible step inside of every step.
 * We could alternatively build the next copmponent as we are generating the next step instead.
 */
type CurrentStepProps = CommonStepProps & {
    selectedSchools: SimplifiedSchool[];
    selectAnotherSchool: () => void;
    onSchoolRemoved: (school: SimplifiedSchool) => void;
};

const Step = (props: CurrentStepProps) => {
    const { isFinished, enrollStep } = props;
    if (isFinished) {
        return <RewardsEnrollSuccess {...props} />;
    }
    switch (enrollStep as any) {
        case 'step1':
            return <RewardsEnrollStep1 {...props} />;
        case 'step2':
            return <RewardsEnrollStep2 {...props} />;
        case 'step3':
            return <RewardsEnrollStep3 {...props} />;
        case 'signUp':
            return <RewardsEnrollSignUp {...props} />;
        case 'srwConfirmation':
            return <SRWConfirmation />;
        case 'selectSchool':
            return <RewardsEnrollSelectSchool {...props} />;
        case 'confirmSchool':
            return <RewardsEnrollConfirmSchool {...props} />;
        case 'success':
            return <Box />;
        default:
            return <RewardsEnrollStep1 {...props} />;
    }
};

type StepDescriptionProps = {
    currentStep?: string;
    isFinished?: boolean;
    enrollSubStep?: string;
    isRewardWallet?: boolean;
};

const StepDescription = ({
    currentStep,
    isFinished,
    enrollSubStep,
    isRewardWallet,
}: StepDescriptionProps) => {
    const classes = useStyles();
    let topText: { title: string; description?: string } = { title: '' };
    if (isFinished) {
        topText = { title: 'Success!' };
    } else if (enrollSubStep === 'schoolSearched') {
        topText = { title: 'Select your school' };
    } else {
        switch (currentStep) {
            case 'step2':
                topText = { title: 'Staples Rewards lookup' };
                break;
            case 'step3':
                topText = { title: "Let's confirm it's really you" };
                break;
            case 'signUp':
                topText = { title: 'Create Account' };
                break;
            case 'selectSchool':
                topText = {
                    title: "Let's find your school",
                };
                break;
            case 'confirmSchool':
                topText = {
                    title: 'Confirm selected school',
                };
                break;
            case 'success':
                topText = { title: '' };
                break;
            case 'srwConfirmation':
                topText = { title: 'You’re reaching new heights!' };
                break;
            case 'step1':
            default:
                topText = {
                    title: 'Introduce yourself',
                    description: 'Select the category that best describes you.',
                };
                break;
        }
    }

    return (
        <>
            <Typography
                className={classes.screenTitle}
                style={{
                    color: isRewardWallet
                        ? COLORS.primaryWhite
                        : COLORS.homeBlack,
                }}
            >
                {topText.title}
            </Typography>
            <Collapse in={!!topText.description}>
                <Typography
                    className={classes.screenDescription}
                    style={{ minHeight: '1rem' }}
                >
                    {topText.description}
                </Typography>
            </Collapse>
        </>
    );
};

// eslint-disable-next-line prefer-arrow-callback
const SuccessTransition = React.forwardRef(function SuccessTransition(
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/** Renders and animates the list of steps as they are added by the flow controller */
const Steps = (props: CommonStepProps) => {
    const {
        enrollStep,
        isFinished,
        userType,
        updateControllerValues,
        onError,
        enrollSubStep,
        ...rest
    } = props;
    const classes = useStyles();
    const router = useRouter();
    const { userInfo } = useUserInfo();
    const { notifyUserUpdate } = useUserUpdateContext();
    const [hasBuiltPriorSteps, setHasBuiltPriorSteps] = useState(false);
    const [isFetchingFromSteps, setIsFetchingFromSteps] = useState<
        boolean | undefined
    >(false);
    const [isSRWFlow, setIsSrwFlow] = useState(rest.flowName === 'SRW');
    const [currentStep, setCurrentStep] = useState<EnrollSteps | any>(
        enrollStep
    );

    // Remove dependency on route.

    // If we have rewards number then classroom rewards should just let user select the role they want. (works)

    useEffect(() => {
        setTimeout(() => {
            setIsSrwFlow(rest.flowName === 'SRW');
        }, 400);
    }, [rest.flowName]);
    useEffect(() => {
        setTimeout(() => {
            setCurrentStep(enrollStep);
        }, 400);
    }, [enrollStep]);

    const indexForStep: any = isSRWFlow
        ? {
              step1: 0,
              step2: 1,
              step3: 2,
              signUp: 2,
              srwConfirmation: 3,
          }
        : {
              step1: 0,
              step2: 1,
              step3: 2,
              signUp: 2,
              selectSchool: 3,
              confirmSchool: 4,
          };

    const stepsFromStep: any = isSRWFlow
        ? {
              step1: ['step1'],
              step2: ['step1', 'step2'],
              step3: ['step1', 'step2', 'step3'],
              signUp: ['step1', 'step2', 'signUp'],
              // steps prior to selectSchool do not matter since the user cannot navigate back to it;
              srwConfirmation: ['step1', 'step2', 'step3', 'srwConfirmation'],
              // success step has no prior steps since the user cannot navigate back to it
          }
        : {
              step1: ['step1'],
              step2: ['step1', 'step2'],
              step3: ['step1', 'step2', 'step3'],
              signUp: ['step1', 'step2', 'signUp'],
              // steps prior to selectSchool do not matter since the user cannot navigate back to it;
              selectSchool: ['step1', 'step2', 'step3', 'selectSchool'],
              confirmSchool: [
                  'step1',
                  'step2',
                  'step3',
                  'selectSchool',
                  'confirmSchool',
              ],
              // success step has no prior steps since the user cannot navigate back to it
          };

    const { selectedSchoolIds } = router.query as RewardsControllerState;
    const [cachedSchools, setCachedSchools] = useCachedSchools();

    const [selectedSchools, setSelectedSchools] = useState(
        inferSchools(cachedSchools, splitIds(selectedSchoolIds))
    );

    const { showError } = useError(onError);

    useEffect(() => {
        setSelectedSchools(
            inferSchools(cachedSchools, splitIds(selectedSchoolIds))
        );
    }, [cachedSchools, selectedSchoolIds, enrollStep]);

    const getDisplayIndex = (currStep?: string) => {
        if (!currStep || !(indexForStep as any)[currStep as string]) {
            return 1;
        }
        if (currStep === 'success') {
            return userType === 'Supporter' ? 3 : 5;
        }
        return (indexForStep as any)[currStep];
    };

    const selectAnotherSchool = () => {
        if (selectedSchools.length <= 4) {
            updateControllerValues({
                selectedSchoolIds: selectedSchools.map((s) => s.id).join(','),
                enrollStep: 'selectSchool',
            });
        } else {
            showError('confirmSchool', 'max-schools');
        }
    };

    function back() {
        if (isFetchingFromSteps || !hasBuiltPriorSteps) {
            return;
        }

        if (currentStep === 'selectSchool') {
            notifyUserUpdate(); // update enroll button on the rewards page
            router.replace('rewards');
            return;
        }

        if (currentStep === 'confirmSchool') {
            selectAnotherSchool();
            return;
        }
        if (
            (currentStep === 'step2' || currentStep === 'signUp') &&
            router.pathname === '/home/purchasehistory'
        ) {
            router.push({
                pathname: '/home/purchasehistory',
                search: '',
            });
            return;
        }

        if (currentStep === 'step2' && router.pathname === ROUTES.stores) {
            router.push({
                pathname: ROUTES.stores,
                search: '',
            });
            return;
        }

        if (
            (currentStep === 'step2' || currentStep === 'signUp') &&
            isSRWFlow &&
            rest.type === 'create-new'
        ) {
            router.history.goBack();
            return;
        }

        if (currentStep === 'step2' && isSRWFlow) {
            router.push(ROUTES.rewardsWallet);
            return;
        }

        let newStep: EnrollSteps = 'step1';
        const priorSteps = currentStep ? stepsFromStep[currentStep] : [];
        if (priorSteps && priorSteps.length > 0) {
            newStep = priorSteps[priorSteps.length - 2];
        }
        updateControllerValues({ enrollStep: newStep });
    }

    function onSchoolRemoved(school: SimplifiedSchool) {
        setSelectedSchools(
            (oldSchools) => oldSchools.filter((s) => s.id !== school.id)
            // eslint-disable-next-line function-paren-newline
        );

        const filteredSchools = (cachedSchools as SimplifiedSchool[]).filter(
            (s) => s.id !== school.id
        );

        setCachedSchools([...filteredSchools]);

        // returning to 'selectSchool' if all selected schools are deleted
        if (selectedSchools.length <= 1) {
            updateControllerValues({
                enrollStep: 'selectSchool',
            });
        }
    }

    const [steps, setSteps] = useState<string[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    // our pagination dots display index is different from our real index
    const [displayIndex, setDisplayIndex] = useState(
        getDisplayIndex(currentStep)
    );
    useEffect(() => {
        if (currentStep && hasBuiltPriorSteps) {
            if (steps.indexOf(currentStep) < 0) {
                setSteps((oldSteps) => [...oldSteps, currentStep]);
            } else {
                setActiveIndex(steps.indexOf(currentStep));
            }
        }
    }, [activeIndex, currentStep, steps, hasBuiltPriorSteps]);

    useEffect(() => {
        setDisplayIndex(getDisplayIndex(currentStep));
    }, [userType, currentStep]);

    useEffect(() => {
        if (userInfo && !hasBuiltPriorSteps) {
            if (currentStep) {
                setSteps(stepsFromStep[currentStep] || []);
            } else {
                setSteps(['step1']);
            }
            setHasBuiltPriorSteps(true);
        }
    }, [userInfo, hasBuiltPriorSteps, currentStep]);

    function onTransitionEnd() {
        if (activeIndex === 0) {
            setSteps(['step1']);
        }
        // for some reason the SwipeableViews ref property does not point to the div that actually scrolls
        // falling back to querying for id instead
        const scrollingElement = document.querySelector(
            '#reward-registration-swipeable'
        );
        if (scrollingElement) {
            scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
    const handleChangeIndex = (index: number) => {
        setActiveIndex(index);
    };

    const hideBackButton = currentStep === 'success';
    return (
        <Box
            className={
                enrollStep === 'srwConfirmation'
                    ? classes.screenSrwConfirmation
                    : classes.screen
            }
            id="rewards-enroll-screens"
        >
            {enrollStep === 'srwConfirmation' ? null : (
                <NavTop
                    minHeight="16rem"
                    marginBottom="-12rem"
                    bgImageSrc={require('../../assets/bgs/bgIllustrationYellowbackground.svg')}
                    bgColor={isSRWFlow ? COLORS.brandRed : COLORS.rewardsYellow}
                >
                    <Box className={classes.commonNav}>
                        <ButtonBase
                            onClick={back}
                            disabled={hideBackButton}
                            className={classes.backButton}
                        >
                            <ArrowBackIosRoundedIcon
                                className={
                                    hideBackButton
                                        ? classes.backIconHidden
                                        : classes.backIcon
                                }
                            />
                        </ButtonBase>
                        <Box className={classes.paginationWrapper}>
                            <Fade
                                in={
                                    displayIndex > 0 &&
                                    currentStep !== 'success'
                                }
                            >
                                <div>
                                    <PaginationDots
                                        dots={userType === 'Supporter' ? 3 : 5}
                                        disabled
                                        index={displayIndex - 1}
                                        onChangeIndex={handleChangeIndex}
                                        activeColor={COLORS.homeBlack}
                                        inActiveColor={COLORS.primaryWhite}
                                    />
                                </div>
                            </Fade>
                        </Box>
                        <ArrowBackIosRoundedIcon
                            className={classes.backIconBalance}
                        />
                    </Box>
                </NavTop>
            )}
            {enrollStep === 'srwConfirmation' ? null : (
                <Box className={classes.stepDescription}>
                    <StepDescription
                        enrollSubStep={enrollSubStep}
                        currentStep={currentStep}
                        isFinished={isFinished}
                        isRewardWallet={isSRWFlow}
                    />
                </Box>
            )}
            <Fade in={hasBuiltPriorSteps}>
                <SwipeableViews
                    disabled
                    id="reward-registration-swipeable"
                    enableMouseEvents={false}
                    onTransitionEnd={onTransitionEnd}
                    index={activeIndex}
                    style={{ flexGrow: 1, minHeight: 'calc(100vh - 12rem)' }}
                    containerStyle={{
                        flexGrow: 1,
                        minHeight: 'calc(100vh - 12rem)',
                    }}
                >
                    {steps.map((s) => (
                        <Container
                            key={s}
                            maxWidth="md"
                            style={{
                                padding:
                                    currentStep === 'srwConfirmation' ||
                                    currentStep === 'signUp'
                                        ? 0
                                        : '0 16px',
                            }}
                        >
                            <Step
                                enrollStep={s}
                                isFinished={isFinished}
                                userType={userType}
                                updateIsFetching={setIsFetchingFromSteps}
                                updateControllerValues={updateControllerValues}
                                selectAnotherSchool={selectAnotherSchool}
                                onSchoolRemoved={onSchoolRemoved}
                                selectedSchools={selectedSchools}
                                onError={onError}
                                {...rest}
                            />
                        </Container>
                    ))}
                </SwipeableViews>
            </Fade>
            <Dialog
                fullScreen
                keepMounted
                open={currentStep === 'success'}
                TransitionComponent={SuccessTransition}
            >
                <RewardsEnrollSuccess
                    isFinished={isFinished}
                    userType={userType}
                    updateIsFetching={setIsFetchingFromSteps}
                    updateControllerValues={updateControllerValues}
                    {...rest}
                />
            </Dialog>
        </Box>
    );
};

export default Steps;
