/* eslint-disable import/prefer-default-export */
import { userGetCurrentInfoURL, walletReportFraudURL } from 'src/services/urls';
import axiosInstance from './custom-axios';
import { User } from '../../services/types';
import { SessionManager } from './sessionManager';
/** Returns the information of the current logged in user */

export async function getUserInfo(): Promise<User> {
    const userInfo = await axiosInstance.get(userGetCurrentInfoURL);
    return userInfo.data;
}

// the user info endpoint does not follow RFC 6902
// so we do not use getPatchDataFromPartialDiff
export async function patchUserInfo(data: any[]) {
    const userInfo = await axiosInstance.patch(userGetCurrentInfoURL, data);
    return userInfo.data;
}

export async function getUserPasscodeInfo(): Promise<User> {
    const userPasscode = await axiosInstance.get(
        `${process.env.REACT_APP_USER_API_URL}/pnidampasscode/${SessionManager.access_token}`
    );
    return userPasscode.data;
}

export async function reportFraudCTA(
    reporterEmailID: string,
    reporteePrincipleRole: string,
    schoolID: string,
    educatorID: string | null
): Promise<any | null> {
    const reportData = await axiosInstance.post(`${walletReportFraudURL}`, {
        reporterEmailID,
        reporteePrincipleRole,
        schoolID,
        educatorID,
    });
    if (reportData.data) {
        return reportData.data;
    }
    return null;
}
