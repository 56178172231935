/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
    SchoolResult,
    EducatorResult,
    AssociatedSchoolResult,
} from 'src/services/types';
import {
    locationClaimSchoolURL,
    locationSchoolDetailsURL,
    locationSchoolEducatorsURL,
    locationSchoolSearchURL,
} from 'src/services/urls';

export async function searchSchools(
    query?: string
): Promise<SchoolResult[] | null> {
    if (!query) {
        return null;
    }
    const schoolSearchResult = await axiosInstance.post(
        `${locationSchoolSearchURL}`,
        { query }
    );
    if (schoolSearchResult.data && typeof schoolSearchResult.data.schools) {
        return schoolSearchResult.data.schools as SchoolResult[];
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when getting school results ${locationSchoolSearchURL}`
    );
    Log.axios(schoolSearchResult);
    return null;
}

/**
 * @param schoolId
 * @param page
 */
export async function getSchoolById(
    schoolId?: string
): Promise<SchoolResult | null> {
    if (!schoolId) {
        return null;
    }

    const requestURL = locationSchoolDetailsURL?.replace(
        '%schoolId%',
        schoolId
    );
    let school;
    try {
        school = await axiosInstance.get(`${requestURL}`);

        if (school?.data) {
            return school?.data;
        }
    } catch (e) {
        Log.axios(
            // eslint-disable-next-line max-len
            `received an unexpected response when fetching a school via ${requestURL}`
        );
    }
    Log.axios(school);
    return null;
}

/**
 * @param schoolId
 * @param page
 */
export async function getEducatorsBySchoolId(
    schoolId?: string,
    page = 0
): Promise<EducatorResult[] | null> {
    if (!schoolId) {
        return null;
    }
    const educators = await axiosInstance.get(
        `${locationSchoolEducatorsURL
            ?.replace(
                '%schoolId%',
                schoolId
                // TODO: STP-698 paginate
            )
            ?.replace('%page%', `${page}`)}`
    );
    if (educators?.data?.results) {
        return educators?.data?.results;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when donating via ${locationSchoolEducatorsURL}`
    );
    Log.axios(educators);
    return null;
}

export async function getUserAssociatedSchools(): Promise<
    AssociatedSchoolResult[] | null
> {
    const schools = await axiosInstance.get(`${locationClaimSchoolURL}`);

    if (schools?.data?.schoolAssociations) {
        return schools?.data?.schoolAssociations;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching associated schools via ${locationClaimSchoolURL}`
    );
    Log.axios(schools);
    return null;
}
