
import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ShippingServicesTrackingCards from './shipping-services-tracking-cards';
import ShippingServicesSenderReceiverAddress from './shipping-services-sender-receiver-address';

const useStyles = makeStyles((theme) => ({
    shippingServiceAccordianDetailsContainers: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '100%',
    },
}));

const ShippingServicesAccordianDetails = ({ shippingData, activeTab, updatedData }: any) => {
    const classes = useStyles();
    return (
        <div className={classes.shippingServiceAccordianDetailsContainers}>

            <ShippingServicesTrackingCards
                data={shippingData}
                activeTab={activeTab}
            />
            <ShippingServicesSenderReceiverAddress
                data={shippingData}
                activeTab={activeTab}
                updatedData={updatedData}
            />

        </div>
    )
}

export default ShippingServicesAccordianDetails;
