import React, { useMemo } from 'react';
import { Typography, makeStyles, Box, Dialog, Button, ButtonBase } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
    paper: {
        background: 'transparent',
    },
    container: {
        background: '#000000D9',
    },
    nextButton: {
        textTransform: 'none',
        minHeight: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
    },
    imageContainer: {
        position: "relative",
        margin: "0 auto",
        backgroundImage: "url('../../assets/icons/OvalForFirstVisitRecycle.png')",
        width: '107px',
        height: '72px',
        background: '#ffffff',
        borderRadius: '45%',
    },
    recycleOvalIm: {
        padding: '10px 13px',
    },
    recyclelImg: {
        padding: '10px 13px',
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '23px 35px 20px',
        background: '#ffffff',
        borderRadius: '15px',
        alignItems: 'center',
    },
    headerAlign: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        textAlign: 'center',
        width: '16.93rem',
    },
    footerAlign: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        textAlign: 'center',
        marginTop: '30px',
        width: '16.93rem',
    },
    title: {
        fontWeight: 700,
        fontSize: '21px',
    },
    subTitle: {
        fontWeight: 400,
        fontSize: '16px',
    },
    description: {
        fontWeight: 400,
        fontSize: '21px',
        lineHeight: '25px',
        letterSpacing: '-0.3375000059604645px',
    },
    rewardsSummaryIconWrapper: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '6rem',
        height: '6rem',
        margin: '0 auto',
        marginBottom: '-4.5rem',
        zIndex: 99999,
    },
    imageAlign: {
        width: '95px',
        height: '95px',
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
    },
    backButton: {
        color: '#086DD2',
        fontSize: '14px',
        fontWeight: 700,
    },
});

export function RewardsEnrollConfirmSignUp({ open, selectedRole, onConfirm, onBack }:
    { open: boolean, selectedRole: string, onConfirm: any, onBack: () => void }) {
    const classes = useStyles();

    const conformationData = useMemo(() => {
        let data = { title: '', description: '' };
        if (selectedRole === "Supporter") {
            data = {
                title: 'Parent or Supporter.',
                description: `Supporters are
                customers that can donate to teachers and schools.`};
        } else if (selectedRole === "Administrator") {
            data = {
                title: 'Admin.',
                description: `Admins can redeem the rewards donated to the school.
                 Choose this option only if you work at the school as a principle,
                a dean or have been approved by your school to operate as an Admin.
                Verification will be required.`};
        } else if (selectedRole === "Educator") {
            data = {
                title: 'Teacher.',
                description: `Teachers can earn rewards as well as receive reward donations.`,
            };
        }
        return data;
    }, [selectedRole]);

    return (
        <Dialog
            open={open}
            onClose={onBack}
            aria-labelledby="reward-confirmation-screen-sign-up"
            classes={{
                paper: classes.paper,
            }}
            className={classes.container}
        >
            <Box display="flex" className={classes.boxContainer}>
                <div className={classes.headerAlign}>
                    <Typography className={classes.title}>
                        Are you sure?
                    </Typography>
                    <img src={require('../../assets/icons/rewardsSignUpConfirmIcon.svg')}
                        alt='rewardsSignUpConfirmIcon'
                        className={classes.imageAlign} />
                    <Typography className={classes.subTitle}>
                        You selected <b>{conformationData.title}</b>
                    </Typography>
                    <Typography>
                        {conformationData.description}
                    </Typography>
                    <Typography>
                        <b>This selection cannot be reversed.</b>
                    </Typography>
                </div>
                <div className={classes.footerAlign}>
                    <Button
                        onClick={() => onConfirm(selectedRole)}
                        fullWidth
                        id="classroom-enrollment-success-finish-button"
                        variant="contained"
                        classes={{
                            root: classes.nextButton,
                        }}
                    >
                        Confirm
                    </Button>
                    <ButtonBase
                        onClick={onBack}
                        classes={{
                            root: classes.backButton,
                        }}
                    >
                        Go back
                    </ButtonBase>
                </div>
            </Box>
        </Dialog>
    );
}

export default RewardsEnrollConfirmSignUp;
