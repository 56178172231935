import React, { useState } from 'react';
import COLORS from 'src/lib/colors';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import NavTop from 'src/components/nav-top';
import { CommonPageProps } from 'src/screens/product-browse/common-page';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ScreenWidth from 'src/components/screen-width';
import { SearchProductsResult } from 'src/lib/api-client/adept-mind';
// import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
// import { trackEvent } from 'src/lib/api-client/beacons-adeptmind';
import ExpandingRow, {
  isSectionExpanded,
  useExpandableSections,
} from './expanding-row';
import MultiSelectFilterRow from './multi-select-filter-row';
import { Filter, PendingFilters } from './types';
import { getFilter, hasFilterOption } from './filter-utils';
import DoubleSliderFilterRow from './double-slider-filter-row';

// for the 1.1 release, only show brand, category, and price
const SUPPORTED_FILTERS_LABELS = ['BRAND', 'CATEGORY', 'PRICE'];

const useStyles = makeStyles({
  screen: {
    background: COLORS.primaryWhite,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  titleAndBack: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '.75rem',
  },
  backButton: { paddingLeft: '.5rem', width: '6rem' },
  clearAll: {
    color: COLORS.primaryWhite,
    fontWeight: 'bold',
    fontSize: '1rem',
    width: '5.5rem',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1.5rem',
    lineClamp: 1,
    color: COLORS.primaryWhite,
    fontWeight: 500,
  },
  buttonBalance: {
    display: 'flex',
    justifyContent: 'center',
    width: '6rem',
    paddingRight: '.5rem',
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    flexGrow: 1,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ProductFilterScreen(props: CommonPageProps) {
  const classes = useStyles();
  const { goBack, setFilters } = useProductBrowseContext();
  // eslint-disable-next-line react/destructuring-assignment
  const lastResult = props?.page?.pageDetails
    ?.lastResult as SearchProductsResult;
  const filters = lastResult?.availableFilters || [];
  const lastAppliedFilters = (lastResult?.appliedFilters ||
    []) as PendingFilters;
  const [filterOptions] = useState(
    filters.filter((f: any) => SUPPORTED_FILTERS_LABELS.indexOf(f.label) >= 0)
  );
  const [pendingFilters, setPendingFilters] =
    useState<PendingFilters>(lastAppliedFilters);
  // const { userGetIdentity } = useUserUpdateContext();
  // const trackEventServiceCall = (productFilerData: any) => {
  //   const dataBody = {
  //     action: "apply",
  //     category: "filter",
  //     distinct_id: userGetIdentity?.distinct_id || "",
  //     query_id: userGetIdentity?.query_id || "",
  //     search_id: userGetIdentity?.search_id || "",
  //     session_id: userGetIdentity?.session_id || "",
  //     data: {
  //       filters: productFilerData,
  //     },
  //   }
  //   trackEvent(dataBody);
  // }
  function onClearAllClicked() {
    // clear all filters
    setFilters([]);
    setPendingFilters([]);
    goBack();
  }
  function backButtonClicked() {
    // commit to the current filters
    // trackEventServiceCall(pendingFilters);
    setFilters(pendingFilters);
    setPendingFilters([]);
    goBack();
  }

  // tracks which section is expanded
  const { openSections, toggleSection } = useExpandableSections(
    SUPPORTED_FILTERS_LABELS.reduce(
      (agg, l) => ({
        ...agg,
        [l]: lastAppliedFilters.find((f) => l === f.label),
      }),
      {}
    )
  );

  return (
    <div className={classes.screen}>
      <ScreenWidth>
        <NavTop noBorderRadius minHeight="5rem">
          <div className={classes.titleAndBack}>
            <ButtonBase
              className={classes.backButton}
              onClick={backButtonClicked}
            >
              <img src={require('../../../assets/icons/iconBackWhite.svg')} alt="back" />
            </ButtonBase>
            <Typography className={classes.title}>Filter</Typography>
            <div className={classes.buttonBalance}>
              <ButtonBase
                className={classes.clearAll}
                onClick={onClearAllClicked}
              >
                Clear All
              </ButtonBase>
            </div>
          </div>
        </NavTop>
        <div className={classes.filters}>
          {hasFilterOption(filterOptions, 'BRAND') && (
            <ExpandingRow
              title="Brand"
              expanded={isSectionExpanded(openSections, 'BRAND')}
              onExpandClicked={() => toggleSection('BRAND')}
            >
              <MultiSelectFilterRow
                filter={getFilter(filters, 'BRAND') as Filter}
                pendingFilters={pendingFilters}
                updatePendingFilters={setPendingFilters}
              />
            </ExpandingRow>
          )}
          {hasFilterOption(filterOptions, 'CATEGORY') && (
            <ExpandingRow
              title="Category"
              expanded={isSectionExpanded(openSections, 'CATEGORY')}
              onExpandClicked={() => toggleSection('CATEGORY')}
            >
              <MultiSelectFilterRow
                filter={getFilter(filters, 'CATEGORY') as Filter}
                pendingFilters={pendingFilters}
                updatePendingFilters={setPendingFilters}
              />
            </ExpandingRow>
          )}
          {hasFilterOption(filterOptions, 'PRICE') && (
            <ExpandingRow
              title="Price"
              expanded={isSectionExpanded(openSections, 'PRICE')}
              onExpandClicked={() => toggleSection('PRICE')}
              rightTextVariant="range"
              filter={getFilter(filters, 'PRICE') as Filter}
              pendingFilters={pendingFilters}
            >
              <DoubleSliderFilterRow
                filter={getFilter(filters, 'PRICE') as Filter}
                pendingFilters={pendingFilters}
                updatePendingFilters={setPendingFilters}
              />
            </ExpandingRow>
          )}
        </div>
      </ScreenWidth>
    </div>
  );
}
