import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
  screen: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    background: COLORS.homeBlack,
  },
  commonNav: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '.8rem',
    marginBottom: '2rem',
  },
  backIcon: {
    fontSize: '1.3rem',
    color: COLORS.primaryWhite,
  },
  backButton: {
    marginLeft: '1rem',
    // margin right needed to balance out editButton width and center the text content
    marginRight: '30px',
  },
  editButton: {
    color: COLORS.primaryWhite,
    '&:disabled': {
      color: COLORS.fordGray,
    },
    transition: 'color .5s',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    // width: '65px',
  },
  // NOT BEING USED. I don't know what it's for -YS
  // backIconBalance: {
  //   fontSize: '1.3rem',
  //   margin: '2rem 0 1.5rem 1rem',
  //   color: COLORS.primaryWhite,
  // },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: COLORS.primaryWhite,
    width: '90%',
    padding: '0 1rem',
    margin: '0 auto',
  },
  listName: {
    fontSize: '1.5rem',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
  },
  listDescription: {
    fontSize: '1rem',
    width: '100%',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  addItemButtonContainer: {
    textAlign: 'center',
    marginLeft: '3.5rem',
    marginRight: '3.5rem',
    marginTop: '2rem',
  },
  addItemButton: {
    borderRadius: '1.5rem',
    alignSelf: 'center',
    width: '100%',
    maxWidth: '25rem',
    textTransform: 'none',
    fontSize: '1.2rem',
    background: COLORS.brandRed,
    color: COLORS.primaryWhite,
    '&:hover': {
      backgroundColor: COLORS.darkRed,
    },
    '&:active': {
      backgroundColor: COLORS.darkRed,
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '1.5rem',
  },
  itemsContainer: {
    marginTop: '2rem',
    alignItems: 'center',
  },
  itemsContainerForSharedList: {
    flexGrow: 1,
    marginTop: 0,
    padding: '10px 0',
    background: '#F2F2F2',
  },
  itemContainer: {
    marginBottom: '1.5rem',
    margin: '0 1.5rem',
  },
  noItemsContainer: {
    margin: '0 1.5rem',
  },
  disclaimerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  disclaimerIcon: {
    width: '100%',
    maxWidth: '10rem',
    marginBottom: '1rem',
    marginTop: '1.5rem',
  },
  disclaimerText: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  deleteItem: {
    fontSize: '28px',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
  },
  itemContent: {
    paddingTop: '1rem',
    height: '100%',
  },
  itemContentWeeklyAd: {
    display: 'flex',
    alignItems: 'center',
  },
  itemContainerWeeklyAd: {
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    margin: '1.5rem auto',
    width: '90%',
    marginBottom: '1.5rem',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  titleWeeklyAd: {
    color: COLORS.brandCyan,
    fontWeight: 'bold',
  },
  itemImageWeeklyAd: {
    height: '50px',
    width: '80px',
    marginRight: '0.5rem',
  },
  itemImageContainer: {
    height: '100%',
  },
  itemImage: {
    height: '80px',
    width: '80px',
  },
  itemName: {
    fontSize: '1.0625rem',
    fontWeight: 500,
  },
  itemStore: {
    fontWeight: 500,
  },
  itemPrice: {
    fontSize: '1.3125rem',
    fontWeight: 'bold',
  },
  itemQuantity: {},
  itemQuantityInput: {
    marginLeft: '0.5rem',
  },
  checkedOffItemsTitle: {
    color: COLORS.primaryWhite,
    fontSize: '17pt',
    marginLeft: '2rem',
    marginBottom: '1rem',
  },
  singleLineClamp: {
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  shareListBtn: {
    color: '#086DD2',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    marginTop: '15px',
    '&:disabled': {
      color: 'grey',
    },
  },
  shareListIcon: {
    marginLeft: '10px',
    width: '30px',
  },
  shareListDialog: {
    '& .MuiTypography-displayBlock': {
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: '15px',
    },
    '& .MuiAvatar-colorDefault': {
      backgroundColor: 'transparent',
    },
    '& .MuiPaper-rounded': {
      borderRadius: '15px',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '0',
      paddingRight: '16px',
      borderBottom: '1px solid #979797',
      width: '300px',
    },
    '& .MuiListItem-gutters:last-child': {
      borderBottom: 'none',
    },
  },
  closeIcon: {
    minWidth: 'unset',
    alignSelf: 'flex-end',
  },
  shareListTitle: {
    padding: '0 24px',
    margin: 0,
    marginBottom: '0.5rem',
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 24px 30px 24px',
    alignItems: 'center',
  },
  sharedByContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '-2rem',
  },
  nameByShared: {
    fontSize: '1rem',
    fontStyle: 'normal',
    height: '2rem',
    fontWeight: 450,
    lineHeight: '13px',
    textAlign: 'center',
    color: COLORS.primaryWhite,
    minWidth: '120px',
    padding: '0.6rem 0.7rem',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    display: 'inline-block',
  },
  lottie: {
    width: '3rem',
    height: '3rem',
  },
});

export default useStyles;
