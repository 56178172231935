export const baseRoute = '/somniapp';

export const LOGIN_ROUTES = {
    onboarding: '/onboarding',
    lookup: '/lookup',
    login: '/login',
    create: '/create',
    passwordReset: '/pwd-reset',
    forgotPassword: '/forgot-pwd',
    passwordReseteEmail: '/pwd-reset-email',
};

// we must need to define pre login route
export const APP_PRE_LOGIN_ROUTES = [
    '/onboarding',
    '/lookup',
    '/login',
    '/create',
    '/pwd-reset',
    '/forgot-pwd',
    '/pwd-reset-email',
];

export const REWARDS_ENROLLMENT_ROUTES = {
    base: '/rewards-enrollment',
    get userUseCaseHandler() {
        return `${this.base}/usecasehandler`;
    },
    get newAccountIntro() {
        return `${this.base}/newaccintro`;
    },
    get welcomeAnimation() {
        return `${this.base}/welcomeanimation`;
    },
    get welcome() {
        return `${this.base}/welcome`;
    },
    get peronalize() {
        return `${this.base}/personalize`;
    },
    get favoritesIntro() {
        return `${this.base}/favoritesintro`;
    },
    get favorites() {
        return `${this.base}/favorites`;
    },
    get enrollmentConfirmation() {
        return `${this.base}/confirm`;
    },
    get missingInfo() {
        return `${this.base}/missinginfo`;
    },
    get missingInfoConfimation() {
        return `${this.base}/missinginfoconfirm`;
    },
    get finish() {
        return `${this.base}/finish`;
    },
};

export const COACH_SCREENS_ROUTES = {
    base: '/coachscreen',
    get initial() {
        return '/';
    },
    get location() {
        return `${this.base}/location`;
    },
    get notification() {
        return `${this.base}/notification`;
    },
};

export const BASE_ROUTES = {};
