import React from 'react';
import { makeStyles, Slider, withStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { CommonRowProps } from 'src/screens/product-browse/filter/types';
import {
  rangeFromAlternatives,
  rangeFromPendingFilters,
  updateNumericRangeFilterValue,
} from 'src/screens/product-browse/filter/filter-utils';

const useDoubleSliderFilterRowStyles = makeStyles({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  rowContent: {
    width: '100%',
    display: 'flex',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
});

function ThumbComponent(props: any) {
  return (
    <div {...props}>
      <img src={require('../../../assets/products/sliderDot.svg')} alt="slider" />
    </div>
  );
}

const StyledSlider = withStyles({
  root: {
    color: COLORS.brandCyan,
    height: 6,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: COLORS.transparentPrimaryWhite,
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: COLORS.transparentPrimaryWhite,
    '&:focus, &:hover, &$active': {
      boxShadow: COLORS.transparentPrimaryWhite,
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    marginTop: -6,
    height: 16,
  },
  rail: {
    color: COLORS.gray80,
    opacity: 1,
    marginTop: -1,
    height: 7,
    borderRadius: 5,
  },
})(Slider);

function ariaValueText(value: number) {
  return `$${value}`;
}

export default function DoubleSliderFilterRow({
  filter,
  pendingFilters,
  updatePendingFilters,
}: CommonRowProps) {
  const classes = useDoubleSliderFilterRowStyles();
  const baseRange = rangeFromAlternatives(filter);
  const currentRange = rangeFromPendingFilters(
    filter,
    pendingFilters,
    baseRange
  );
  const handleChange = (event: any, newValue: number[] | number) => {
    if (typeof newValue === 'number') {
      // invalid values! skip setting range
      return;
    }
    const newRange = { low: newValue[0], high: newValue[1] };
    updatePendingFilters(
      updateNumericRangeFilterValue(pendingFilters, filter, newRange)
    );
  };

  // range sliders must be arrays
  // TODO: special case for price
  return (
    <div className={classes.row}>
      <StyledSlider
        step={1}
        min={baseRange.low}
        max={baseRange.high}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        className={classes.rowContent}
        ThumbComponent={ThumbComponent}
        getAriaValueText={ariaValueText}
        value={[currentRange.low || 0, currentRange.high || 0]}
      />
    </div>
  );
}
