import { createContext, useState, useContext, useEffect } from 'react';
import {
  ScreenName,
  SCREEN_NAMES,
  addScreenInOrder,
} from 'src/controllers/config';
import Log from 'src/lib/logging';

const ScreenOrderContext = createContext({
  activeScreen: SCREEN_NAMES.BASE,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* eslint-disable @typescript-eslint/no-empty-function */
  requestScreen: (screenName: ScreenName) => {},
  revokeScreenRequest: (screenName: ScreenName) => {},
  prepareForLogout: () => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
  /* eslint-enable @typescript-eslint/no-empty-function */
});

export function useScreenOrderContextState() {
  const [screenRequests, setScreenRequests] = useState<ScreenName[]>([
    SCREEN_NAMES.BASE,
  ]);
  function requestScreen(screenName: ScreenName) {
    setScreenRequests((prevVal) => addScreenInOrder(screenName, prevVal));
  }
  function revokeScreenRequest(screenName: ScreenName) {
    if (screenName === SCREEN_NAMES.BASE) {
      Log.screens('WARN: you cannot revoke the base screen');
      return;
    }
    setScreenRequests((prevVal) => prevVal.filter((s) => s !== screenName));
  }
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  function prepareForLogout() {
    setIsLoggingOut(true);
  }
  const activeScreen =
    screenRequests.length > 0 && !isLoggingOut
      ? screenRequests[0]
      : SCREEN_NAMES.BASE;
  return {
    activeScreen,
    screenRequests,
    requestScreen,
    revokeScreenRequest,
    prepareForLogout,
  };
}

export function useScreenOrderContext() {
  return useContext(ScreenOrderContext);
}

/** hides logic for screen order so that dialogs can isolate their display logic */
export function useIsActiveScreen(
  screenName: ScreenName,
  isRequestingScreen?: boolean
) {
  const { activeScreen, requestScreen, revokeScreenRequest } =
    useScreenOrderContext();
  useEffect(() => {
    if (isRequestingScreen === true) {
      requestScreen(screenName);
    } else if (isRequestingScreen === false) {
      revokeScreenRequest(screenName);
    }
  }, [isRequestingScreen]);
  useEffect(() => {
    Log.screens(`screenName: ${screenName} activeScreen: ${activeScreen}`);
  }, [activeScreen]);
  return activeScreen === screenName;
}

export default ScreenOrderContext;
