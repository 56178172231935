/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconEasyRewardsHorizontal({
    height = 36,
    width = 254,
    fillColor = 'fillRed',
}: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 254 36"
            id="EasyRewardsImage"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.62529 16.1595H17.4651V10.4714H6.62529V5.85313H18.9033V0H3.37748C1.51191 0 0 1.51191 0 3.37749V23.386C0 25.2516 1.51191 26.7635 3.37748 26.7635H19.369V20.7748H6.62529V16.1565V16.1595Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M54.4671 15.0219C53.2292 14.5208 52.5602 14.2526 51.4904 13.8165C49.952 13.1799 49.4833 12.779 49.4833 12.11C49.4833 11.4086 50.3528 10.94 51.626 10.94C53.132 10.94 54.7706 11.5088 56.3768 12.614L59.1531 8.70012C57.0459 6.9937 54.0014 5.95628 50.9894 5.95628C46.0381 5.95628 42.7254 8.46435 42.7254 12.1778C42.7254 14.7537 44.1312 16.6959 46.7749 17.7981C47.8447 18.2343 48.8497 18.6351 49.9195 19.0683C51.6584 19.7698 52.1948 20.206 52.1948 20.875C52.1948 21.6766 51.3253 22.213 50.0522 22.213C48.3457 22.213 46.2709 21.4114 44.4996 20.0704L41.7234 24.4205C43.9986 26.2949 46.9753 27.2969 50.3204 27.2969C55.6725 27.2969 59.2533 24.7211 59.2533 20.7718C59.2533 18.0604 57.8829 16.3894 54.47 15.016L54.4671 15.0219Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M76.2793 6.48973L72.1267 19.3955L67.2815 6.48973H60.5236L67.0811 21.7149C62.0591 21.8063 58.6846 24.7918 58.6846 29.1743C58.6846 33.0557 61.8292 36 65.9789 36C69.825 36 72.4362 34.1934 74.175 30.3119C74.9118 28.7057 75.3451 27.7361 75.7135 26.7664H82.0706V21.7149H77.4729L82.772 6.49268H76.2823L76.2793 6.48973ZM68.8848 28.6379C68.2836 29.9081 67.479 30.5447 66.477 30.5447C65.4749 30.5447 64.7381 29.8433 64.7381 28.7705C64.7381 27.5651 65.8433 26.7635 67.4466 26.7635H69.7542C69.486 27.4001 69.2532 27.9011 68.8848 28.6379Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M31.65 5.95628C28.2696 5.95628 25.0925 6.92591 22.384 8.80033L24.9923 12.95C26.8667 11.8124 29.0064 11.1433 30.7452 11.1433C32.9202 11.1433 34.3909 12.0806 34.3909 13.4864V13.6544L27.9336 14.7242C23.5187 15.461 21.1757 17.7009 21.1757 21.2139C21.1757 24.727 24.2201 27.3028 28.2342 27.3028C30.7423 27.3028 33.1177 26.1328 34.1227 24.4263V26.7694H40.2794V13.0855C40.2794 8.40245 37.3027 5.95923 31.647 5.95923L31.65 5.95628ZM34.3938 17.5977C34.3938 20.374 32.5548 22.4812 30.144 22.4812C28.8059 22.4812 27.7685 21.6442 27.7685 20.5744C27.7685 19.4367 28.6733 18.6351 30.3444 18.2667L34.3938 17.3973V17.5977Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M113.013 9.43397C113.013 3.78125 108.931 0 102.842 0H94.6786C92.8131 0 91.3011 1.51191 91.3011 3.37749V26.7635H97.9264V19.0006H101.607L105.89 26.7635H113.216L108.165 17.7627C111.209 16.1565 113.016 13.0443 113.016 9.43103L113.013 9.43397ZM101.672 13.3154H97.9264V5.68809H101.672C104.617 5.68809 106.155 6.9937 106.155 9.50176C106.155 12.0098 104.617 13.3154 101.672 13.3154Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M239.056 15.0219C237.818 14.5208 237.149 14.2526 236.079 13.8165C234.541 13.1799 234.072 12.779 234.072 12.11C234.072 11.4086 234.941 10.94 236.215 10.94C237.721 10.94 239.359 11.5088 240.965 12.614L243.742 8.70012C241.634 6.9937 238.59 5.95628 235.578 5.95628C230.627 5.95628 227.314 8.46435 227.314 12.1778C227.314 14.7537 228.72 16.6929 231.364 17.7981C232.433 18.2343 233.438 18.6351 234.508 19.0683C236.247 19.7698 236.783 20.206 236.783 20.875C236.783 21.6766 235.914 22.213 234.641 22.213C232.934 22.213 230.86 21.4114 229.088 20.0704L226.312 24.4205C228.587 26.2949 231.564 27.2969 234.909 27.2969C240.261 27.2969 243.842 24.7211 243.842 20.7718C243.842 18.0634 242.471 16.3894 239.059 15.016L239.056 15.0219Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M218.145 8.83274C216.707 6.99369 214.6 5.95628 211.824 5.95628C206.536 5.95628 202.558 10.5745 202.558 16.6634C202.558 22.7523 206.539 27.3028 211.824 27.3028C214.5 27.3028 216.507 26.233 218.113 24.4263V26.7694H224.703V0H218.145V8.83274ZM213.73 21.2787C211.054 21.2787 209.28 19.3719 209.28 16.5279C209.28 13.6838 211.054 11.8094 213.73 11.8094C216.406 11.8094 218.181 13.7162 218.181 16.5279C218.181 19.3395 216.406 21.2787 213.73 21.2787Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M124.522 5.95627C118.433 5.95627 113.714 10.5392 113.714 16.6281C113.714 22.717 118.498 27.2999 124.755 27.2999C128.268 27.2999 131.18 26.1622 133.688 23.9194L130.307 19.9053C128.633 21.3112 127.062 21.9448 124.955 21.9448C122.412 21.9448 120.472 20.371 120.372 18.364H134.693L134.793 17.1586C135.562 10.7337 131.18 5.95038 124.522 5.95038V5.95627ZM120.272 14.6888C120.64 12.5138 122.347 11.0431 124.522 11.0431C126.561 11.0431 128.168 12.5138 128.268 14.6888H120.272Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M249.636 27.2684C251.667 26.8772 252.997 24.9132 252.606 22.8817C252.215 20.8503 250.251 19.5206 248.219 19.9118C246.188 20.303 244.858 22.2669 245.249 24.2984C245.64 26.3299 247.604 27.6596 249.636 27.2684Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M160.254 6.48973L156.673 18.5025L153.092 6.48973H148.544L144.863 18.5025L141.218 6.48973H134.292L141.383 26.7635H147.071L150.716 15.7911L154.33 26.7635H160.018L166.944 6.48973H160.254Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M195.296 9.20113V6.49266H188.739V26.7664H195.296V17.8335C195.296 14.4884 197.103 12.4814 200.18 12.4814H201.886V5.89143H200.916C198.741 5.89143 196.97 6.92885 195.296 9.20408V9.20113Z"
                className={color_classes[fillColor]}
            />
            <path
                d="M176.961 5.95628C173.581 5.95628 170.404 6.92591 167.695 8.80033L170.304 12.95C172.178 11.8124 174.318 11.1433 176.057 11.1433C178.232 11.1433 179.702 12.0806 179.702 13.4864V13.6544L173.245 14.7242C168.83 15.461 166.487 17.7009 166.487 21.2139C166.487 24.727 169.531 27.3028 173.546 27.3028C176.054 27.3028 178.429 26.1328 179.434 24.4263V26.7694H185.591V13.0855C185.591 8.40245 182.614 5.95923 176.958 5.95923L176.961 5.95628ZM179.705 17.5977C179.705 20.374 177.866 22.4812 175.455 22.4812C174.117 22.4812 173.08 21.6442 173.08 20.5744C173.08 19.4367 173.982 18.6351 175.656 18.2667L179.705 17.3973V17.5977Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconEasyRewardsHorizontal;
