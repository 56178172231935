/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React from 'react';

function IconUniDirectionalArrow({ height = '20', width = '20' }: { height?: string, width?: string }) {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" fill="#CC0000" />
            <path d="M24.3094 35.3744L24.3094 14.6908C24.3094 14.3095 24.6189 14 25.0002 14C25.3816 14 25.691 14.3095 25.691 14.6908L25.691 35.3744C25.691 35.7552 25.3816 36.0652 25.0002 36.0652C24.6189 36.0652 24.3094 35.7557 24.3094 35.3744Z" fill="white" />
            <path d="M20.0011 31.0649C20.0011 30.8881 20.068 30.7107 20.2039 30.5759C20.4725 30.3062 20.9107 30.3073 21.1804 30.577L24.9997 34.3974L28.8201 30.5759C29.0898 30.3073 29.528 30.3073 29.7977 30.5759C30.0674 30.8455 30.0674 31.2838 29.7977 31.5535L25.4883 35.863C25.3589 35.9923 25.1826 36.0647 24.9992 36.0647C24.8157 36.0647 24.64 35.9923 24.5101 35.8619L20.2017 31.5524C20.0669 31.4186 20 31.2418 20 31.0644L20.0011 31.0649Z" fill="white" />
        </svg>
    );
}

export default IconUniDirectionalArrow;