import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography, ButtonBase } from '@material-ui/core';

import COLORS from 'src/lib/colors';
import { ListItemType } from 'src/services/types';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingButton from 'src/components/loading-button';
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';
import InfoButton from 'src/components/info-button';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';
import DiagonalStrikethroughText from 'src/components/diagonal-strikethrough-text';
import GenericDeleteItemDialog from 'src/components/generic-delete-item-dialog';
import { deleteItem } from 'src/lib/api-client/lists';
import { useQuery } from 'react-query';
import GenericUserInputDialog from 'src/components/generic-user-input-dialog';
import { useToastMessageContext } from 'src/lib/contexts/toast-message-context';
import clsx from 'clsx';
import useUpdateItem from 'src/hooks/useUpdateItem';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ProductStarRating from 'src/components/product-star-rating';

const useStyles = makeStyles((theme) => ({
    swipeableListContainer: {
        '& .swipeable-list': {
            flex: '1 1',
            width: '100%',
            height: '100%',
            overflowY: 'auto',
        },
        '& .swipeable-list-item': {
            position: 'relative',
            transition: 'max-height 0.5s ease-in-out',
            maxHeight: '1000px',
            transformOrigin: 'top',
            overflow: 'hidden',
            width: '100%',
        },
        '& .swipeable-list-item__content': {
            width: '100%',
            alignItems: 'center',
            boxSizing: 'border-box',
            height: '100%',
            display: 'flex',
        },
        '& .swipeable-list-item__leading-actions': {
            position: 'absolute',
            display: 'flex',
            width: '0px',
            overflow: 'hidden',
            height: '100%',
        },
        '& .swipe-action': {
            display: 'flex',
            width: '100%',
            alignItems: 'stretch',
        },
        '& .swipe-action__leading': {
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'flex-end',
            transform: 'scale(1)',
            transformOrigin: 'center left',
            transition: 'transform 0.2s ease-out',
        },
        '& .swipeable-list-item__trailing-actions': {
            display: 'flex',
            width: '0px',
            position: 'absolute',
            right: '0',
            top: '0',
            justifyContent: 'flex-end',
            overflow: 'hidden',
            height: '100%',
        },
        '& .swipe-action ': {
            display: 'flex',
            width: '100%',
            alignItems: 'stretch',
        },
        '& .swipe-action__trailing': {
            overflow: 'hidden',
            display: 'flex',
            transform: 'scale(1)',
            transformOrigin: 'center left',
            transition: 'transform 0.2s ease-out',
        },
    },
    backIcon: {
        fontSize: '1.7rem',
        color: COLORS.primaryWhite,
    },
    itemsContainer: {
        marginTop: '2rem',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    deleteItem: {
        fontSize: '28px',
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent',
    },
    itemContainer: {
        marginBottom: '1.5rem',
        width: '100%',
        cursor: 'pointer',
    },
    itemContent: {
        paddingTop: '1rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    itemDetailsContainer: {
        width: '100%',
    },
    itemImage: {
        height: '80px',
        width: '80px',
        marginRight: '0.5rem',
        objectFit: 'contain',
    },
    itemName: {
        fontSize: '1.0625rem',
        fontWeight: 500,
    },
    changeStoreButton: {
        fontSize: '1rem',
        color: COLORS.brandCyan,
        fontWeight: 'bold',
    },
    itemStoreContainer: {
        fontWeight: 500,
    },
    itemStoreName: {
        fontWeight: 'bold',
        color: COLORS.brandCyan,
    },
    prices: {
        display: 'flex',
        alignItems: 'baseline',
    },
    itemPrice: {
        fontSize: '1.3125rem',
        fontWeight: 'bold',
    },
    quantityRowContainer: {
        // height: '40px',
        whiteSpace: 'nowrap',
    },
    quantityTextContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    quantityText: {
        display: '-webkit-box',
    },
    quantityValue: {
        display: 'inline-block',
    },
    actionButton: {
        [theme.breakpoints.down('sm')]: {
            minWidth: '30px',
        },
    },
    actionButtonQuntity: {
        [theme.breakpoints.down('sm')]: {
            minWidth: '28px',
        },
        padding: 0,
    },
    iconCheckmarkContainer: {
        marginRight: '1rem',
    },
    iconUndoContainer: {
        marginLeft: '1rem',
    },
    actionIcon: {
        width: '44pt',
        display: 'inline-block',
        height: '100%',
        verticalAlign: 'middle',
    },
    checkmarkIcon: {
        width: '28pt',
    },
    tooltipContainer: {
        width: '65%',
        height: '1rem',
        display: 'flex',
        justifyContent: 'center',
    },
    tooltipTitleStyle: {
        fontWeight: 500,
    },
}));

const itemHasPrice = (item: ListItemType) =>
    item.itemNumber && item.price && item.price > 0;
const getItemOriginalPrice = (item: ListItemType) => item.price;
const isItemOnSale = (item: ListItemType) => item.price !== item.salePrice;
const getItemPrice = (item: ListItemType) =>
    isItemOnSale(item) ? item.salePrice : item.price;

type IndividualListItemProps = {
    item: ListItemType;
    inEditMode: boolean;
    handleToggleCheckOffItem?: (item: ListItemType) => void;
    handleChangeStoreClick?: (item: ListItemType) => void;
    handleRefresh?: () => void;
    hideToolTip?: boolean;
};

/** Renders and animates the list of steps as they are added by the flow controller */
const IndividualListItem = (props: IndividualListItemProps) => {
    const {
        item,
        inEditMode,
        handleToggleCheckOffItem,
        handleChangeStoreClick,
        handleRefresh,
        hideToolTip,
    } = props;
    const classes = useStyles();
    const { setToastMessage } = useToastMessageContext();
    const { userInfo: localUserInfo, patchLocalUserInfo } = useLocalUserInfo();

    const { currentPage } = useProductBrowseContext();

    const [isTooltipOpen, setIsTooltipOpen] = useState(
        item.sequence === 0 && !localUserInfo?.userViewedItemListToolTip
    );

    useEffect(() => {
        setIsTooltipOpen(
            item.sequence === 0 && !localUserInfo?.userViewedItemListToolTip
        );
    }, [item.sequence]);

    const [isDeleteItemDialogOpen, setIsDeleteItemDialogOpen] = useState(false);
    const [isUpdateQuantityDialogOpen, setIsUpdateQuantityDialogOpen] =
        useState(false);
    const [deleteListItem, setDeleteListItem] = useState(false);
    const [swipeInProgress, setSwipeInProgress] = useState(false);

    function refreshList() {
        if (handleRefresh) {
            handleRefresh();
        }
    }

    const { error: deleteItemError, isFetching: deleteItemIsFetching } = useQuery(
        ['deleteItem', item, deleteListItem],
        async () => deleteItem({ itemId: item?.id?.toString() || '' }),
        {
            enabled: !!item && deleteListItem,
        }
    );

    useEffect(() => {
        if (!deleteItemIsFetching && item && deleteListItem) {
            if (deleteItemError) {
                setDeleteListItem(false);
            }
            setToastMessage({
                title: 'Item removed from your list!',
            });
            refreshList();
        }
    }, [deleteItemError, deleteItemIsFetching]);

    const { commitToUpdate, isFetching: updateItemIsFetching } = useUpdateItem({
        onComplete: refreshList,
    });

    function handleDeleteItem() {
        setDeleteListItem(true);
        setIsDeleteItemDialogOpen(false);
    }

    function handleOpenDeleteItemDialog() {
        setIsDeleteItemDialogOpen(true);
    }

    function handleUpdateItemQuantity(newQuantity: number) {
        commitToUpdate(item.id, newQuantity, 'quantity');
        setIsUpdateQuantityDialogOpen(false);
    }

    function handleSwipeInProgress() {
        if (!swipeInProgress) {
            setSwipeInProgress(true);
        }
    }

    const handleSwipeEnd = () => {
        if (handleToggleCheckOffItem) {
            handleToggleCheckOffItem(item);
        }
    };


    const leadingActions = () => (
        <LeadingActions>
            <SwipeAction onClick={handleSwipeEnd}>
                <div className={classes.iconCheckmarkContainer}>
                    <img
                        className={classes.actionIcon}
                        src={require('../../../assets/icons/iconListCheckmark.svg')}
                        alt="checkmark"
                    />
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const trailingActions = () => (
        <TrailingActions>
            <SwipeAction onClick={handleSwipeEnd}>
                <div className={classes.iconUndoContainer}>
                    <img
                        className={classes.actionIcon}
                        src={require('../../../assets/icons/iconListUndo.svg')}
                        alt="checkmark"
                    />
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    const handleViewedToolTip = () => {
        setIsTooltipOpen(false);
        patchLocalUserInfo({ userViewedItemListToolTip: true });
    };


    const itemCheckedOff = item.isChecked && !inEditMode;
    const card = (
        <FullWidthCard
            hasManualShadow
            className={classes.itemContainer}
        >
            <Box className={classes.itemContent} display="flex" flexDirection="row">
                <img
                    className={classes.itemImage}
                    src={item.imageUrl || require('../../../assets/icons/iconNoItemImage.svg')}
                    alt="item icon"
                />

                <Box
                    className={classes.itemDetailsContainer}
                    display="flex"
                    flexDirection="column"
                >
                    <Typography className={classes.itemName}>{item.name}</Typography>
                    {!hideToolTip && isTooltipOpen && !currentPage && (
                        <span className={classes.tooltipContainer}>
                            <InfoButton
                                buttonId="rewards-enroll-signup-why-phone-tooltip"
                                buttonType="text"
                                // eslint-disable-next-line max-len
                                tooltipTitle="Swipe right to check items off your list or swipe left to add them back to your list."
                                tooltipTitleStyle={classes.tooltipTitleStyle}
                                tooltipPlacement="top"
                                tooltipCta="Got it!"
                                tooltipOpen={isTooltipOpen}
                                secondary
                                showButton={false}
                                onTooltipClose={handleViewedToolTip}
                            />
                        </span>
                    )}

                    {inEditMode && itemHasPrice(item) && (
                        <div className={classes.prices}>
                            <Typography className={classes.itemPrice}>
                                ${getItemPrice(item)}
                            </Typography>
                            {isItemOnSale(item) && (
                                <DiagonalStrikethroughText>
                                    ${getItemOriginalPrice(item)}
                                </DiagonalStrikethroughText>
                            )}
                        </div>
                    )}
                    <ProductStarRating
                        totalReviews={item.reviewsCount}
                        ratingValue={item.productRating}
                    />
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.quantityRowContainer}
                    >
                        <div className={classes.quantityTextContainer}>
                            <Typography className={classes.quantityText}>
                                Quantity:{' '}
                                <span className={classes.quantityValue}>{item.quantity}</span>
                            </Typography>
                            {inEditMode && !itemCheckedOff && (<LoadingButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsUpdateQuantityDialogOpen(true);
                                }}
                                className={classes.actionButtonQuntity}
                                loading={updateItemIsFetching}
                            >
                                <img src={require('../../../assets/icons/iconCaretBlue.svg')} alt="caret" />
                            </LoadingButton>)}
                        </div>
                        {inEditMode && !itemCheckedOff && (
                            <>
                                <LoadingButton
                                    className={clsx(classes.actionButton, classes.deleteItem)}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation();
                                        handleOpenDeleteItemDialog();
                                    }}
                                    loading={false}
                                >
                                    <img alt="delete item" src={require('../../../assets/icons/iconDeleteRed.svg')} />
                                </LoadingButton>
                            </>
                        )}
                        {itemCheckedOff && (
                            <img
                                className={classes.checkmarkIcon}
                                src={require('../../../assets/icons/iconListCheckmark.svg')}
                                alt="checkmark"
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </FullWidthCard>
    );
    return (
        <div className={classes.swipeableListContainer}>
            {hideToolTip ? (
                [card]
            ) : (
                <SwipeableList fullSwipe={false} type={ListType.IOS}>
                    <SwipeableListItem
                        leadingActions={!item.isChecked && leadingActions()}
                        trailingActions={item.isChecked && trailingActions()}
                        blockSwipe={
                            deleteItemIsFetching || updateItemIsFetching || inEditMode
                        }
                        onSwipeEnd={handleSwipeEnd}
                        onSwipeProgress={handleSwipeInProgress}
                    >
                        {card}
                    </SwipeableListItem>
                </SwipeableList>
            )}
            <GenericDeleteItemDialog
                open={isDeleteItemDialogOpen}
                title={item?.name || ''}
                iconSrc={item?.imageUrl || require('../../../assets/icons/iconNoItemImage.svg')}
                handleClose={() => setIsDeleteItemDialogOpen(false)}
                handlePrimaryBtnClick={() => {
                    handleDeleteItem();
                }}
                handleSecondaryBtnClick={() => setIsDeleteItemDialogOpen(false)}
                primaryBtnLoading={deleteItemIsFetching && !!item}
            />

            <GenericUserInputDialog
                open={isUpdateQuantityDialogOpen}
                title="Quantity"
                defaultInputValue={item?.quantity}
                handleClose={() => setIsUpdateQuantityDialogOpen(false)}
                handleInputChange={handleUpdateItemQuantity}
                handleSubmit={(newValue: number) => {
                    handleUpdateItemQuantity(newValue);
                }}
                handleSecondaryBtnClick={() => setIsUpdateQuantityDialogOpen(false)}
                primaryBtnLoading={updateItemIsFetching && !!item}
            />
        </div>
    );
};

export default IndividualListItem;