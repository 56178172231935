/* eslint-disable import/prefer-default-export */
import { parsePhoneNumberFromString, formatNumber } from 'libphonenumber-js';

/** True if value is null, undefined or a whitespace only string */
export const isBlank = (value?: any) => {
  if (typeof value === 'string') {
    return (value || '').trim() === '';
  }
  return value === null || value === undefined;
};

// Fix for inconsistent rounding using normal toFixed
// https://stackoverflow.com/a/23560569
function toFixedPrecision(num: number, precision: number) {
  return (+`${Math.round(+`${num}e${precision}`)}e${-precision}`).toFixed(
    precision
  );
}

/**
 * @param n: the value in dollars to format
 * @param removeZeroZeroDecimal: If true, returned value will not include .00 when n is a whole number
 */
export const formatCommasWithDecimal = (
  n = 0,
  removeZeroZeroDecimal = false // If true, returned value will not include .00 when n is a whole number
) => {
  const isWholeNumber = !(n % 1);
  const roundedFloat = parseFloat(toFixedPrecision(n, 2));

  return roundedFloat
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: removeZeroZeroDecimal && isWholeNumber ? 0 : 2,
    })
    .replace('$', '');
};

/** returns dollars and cents as separate strings. cents are zero-padded. */
export function fmtDollarsAndCents(amount: number): {
  dollars: string;
  cents: string;
} {
  const stringFmt = formatCommasWithDecimal(amount / 100);
  const [dollars, cents] = stringFmt.split('.');
  return { dollars, cents };
}

/** Assumes US phone number and then falls back to an international variant. */
export const formatPhoneNumber = (pn?: string) => {
  if (!pn) {
    return '';
  }
  const n = parsePhoneNumberFromString(pn, 'US');
  if (n?.country === 'US') {
    return formatNumber(pn, 'US', 'NATIONAL');
  }
  return formatNumber(pn, 'INTERNATIONAL');
};

const regexLeadingZeros = new RegExp('^[0]+');
export const trimLeadingZeros = (s?: string) => {
  if (!s) {
    return '';
  }
  return s.replace(regexLeadingZeros, '');
};

export const phoneNumberFormat = (phoneNumber: string) => {
  if(phoneNumber) {
  const phoneNum = phoneNumber.replace(/\D/g,"")
  if (phoneNum.length === 10) {
    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
  }
  return phoneNum;
  }
  return null;
}

export const stringCapitalize = (str: string) => {
  if(str) {
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
  }
  return null;
}
