import { TextField, withStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused, & label.Mui-error': {
      color: COLORS.secondaryNeutralCharcoal,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.secondaryNeutralCharcoal,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderColor: COLORS.brandRed,
    },
  },
})(TextField);

export default ValidationTextField;
