import { useEffect, useState } from 'react';
import Log from 'src/lib/logging';

const STORAGE_EVENT_KEY = 'app.staplesconnect.storage';

export function getItemFromLocalStorage<T>(key: string, initialValue: T) {
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
    } catch (error) {
        // If error also return initialValue
        Log.localStorage(error);
        return initialValue;
    }
}

export function saveToLocalStorage<T>(key: string, valueToStore: T) {
    try {
        const stringifiedValue = JSON.stringify(valueToStore);
        window.localStorage.setItem(key, stringifiedValue);
        window.dispatchEvent(new Event(STORAGE_EVENT_KEY));
    } catch (error) {
        Log.localStorage(error);
    }
}

export function removeFromLocalStorage(key: string) {
    try {
        window.localStorage.removeItem(key);
        window.dispatchEvent(new Event(STORAGE_EVENT_KEY));
    } catch (error) {
        Log.localStorage(error);
    }
}
/**
 * Reads/writes a value in local storage.
 * Writing to local storage is a synchronous call that blocks the main thread, so use sparingly.
 * For larger bits of data, use something like react-indexed-db instead.
 */
export default function useLocalStorage<T>(key: string, initialValue: T) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() =>
        getItemFromLocalStorage(key, initialValue)
    );
    // Return a wrapped version of useState's setter function that
    // persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have the same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            saveToLocalStorage(key, valueToStore);
        } catch (error) {
            // A more advanced implementation would handle the error case
            Log.localStorage(error);
        }
    };

    // updating stored value from some other part of the app
    function localStorageUpdated() {
        if (window.localStorage) {
            const currentValueInStorage = window.localStorage.getItem(key);
            try {
                const parsedValue = currentValueInStorage
                    ? JSON.parse(currentValueInStorage)
                    : null;
                setStoredValue(parsedValue);
            } catch (e) {
                Log.localStorage('Could not parse stored value as JSON');
            }
        }
    }

    useEffect(() => {
        window.addEventListener(STORAGE_EVENT_KEY, localStorageUpdated, false);
        // remove listener when component unmounts
        return () => {
            window.removeEventListener(STORAGE_EVENT_KEY, localStorageUpdated);
        };
    }, []);

    return [storedValue, setValue] as const;
}

/** used to avoid collisions in local storage */
const prefixKeyValue = (baseID: string) => `app.staplesconnect.${baseID}`;

// common local storage keys:
export const LOCAL_STORAGE_KEYS = {
    /** see LocalUserInfo */
    USER_INFO: prefixKeyValue('USER_INFO'),
    /** see LocalDeviceInfo */
    DEVICE: prefixKeyValue('DEVICE'),
    /** used to defer when to show the PWA install prompt */
    INSTALL_PROMPT: prefixKeyValue('INSTALL_PROMPT'),
    /** used to temporarily store cached school data during school search */
    CACHED_SCHOOLS: prefixKeyValue('CACHED_SCHOOLS'),
    /** used for redirects back from certain hosted login pages (user edit page) */
    AUTH_REDIRECT: prefixKeyValue('AUTH_REDIRECT'),
    ENROLLMENT_TRACKER: prefixKeyValue('ENROLLMENT_TRACKER'),
} as const;
