/* eslint-disable camelcase */
import { useState } from 'react';
import { useQuery } from 'react-query';
import { ProductsEntity } from 'src/lib/api-client/adept-mind-types';
import { addItem } from 'src/lib/api-client/lists';
import { useToastMessageContext } from 'src/lib/contexts/toast-message-context';
import useStoreData from 'src/hooks/useStoreData';
import eventMetricsMap from './useEventsReporter/event-types';
import useEventsReporter from './useEventsReporter';

/**
 * Products/Items are added to lists in numerous ways but have common behvior across them
 * This hook centralizes that behavior.
 */

export interface UseAddItemProduct {
    description: string;
    product_id: string;
    store_ids: number[];
}

const useAddItem = ({
    listArray,
    onError,
    onSuccess,
    onComplete,
}: {
    listArray: number[];
    onError?: () => void;
    onSuccess?: () => void;
    onComplete?: () => void;
}) => {
    const { mainStoreId } = useStoreData();
    const { customEvent } = useEventsReporter();
    const { setToastMessage } = useToastMessageContext();
    const [product, setProduct] = useState<any>();
    const [committedToAddition, setCommittedToAddition] = useState(false);
    const [alternativeQuantity, setAlternativeQuantity] = useState<number>();
    function onAdditionComplete() {
        setCommittedToAddition(false);
        setProduct(undefined);
        setAlternativeQuantity(undefined);
        if (onComplete) {
            onComplete();
        }
    }

    function onAdditionSuccess() {
        setToastMessage({
            title: 'Item added to your list!',
        });
        if (onSuccess) {
            onSuccess();
        }
        customEvent(eventMetricsMap.added_product);
        onAdditionComplete();
    }

    function onAdditonError() {
        setToastMessage({
            title: 'Error adding item to list.',
        });
        if (onError) {
            onError();
        }
        onAdditionComplete();
    }
    const mainStoreIdAsNumber = mainStoreId
        ? Number.parseInt(mainStoreId, 10)
        : 0;
    const resolvedQuantity = alternativeQuantity || 1;
    const resolvedStoreId = mainStoreIdAsNumber;
    console.log({ product })
    const queryProps = useQuery(
        ['addItem', committedToAddition, listArray.join(',')],
        async () =>
            addItem({
                itemNumber: product?.itemId || '',
                listIds: listArray,
                quantity: resolvedQuantity,
                storeId: resolvedStoreId,
                itemName: product?.title || '',
                itemImageUrl: product?.image || '',
                productRating: product?.rating || 0,
                reviewsCount: product?.ratingCount || 0,
            }),
        {
            keepPreviousData: true,
            enabled: committedToAddition && listArray.length > 0,
            onSuccess: onAdditionSuccess,
            onError: onAdditonError,
        }
    );

    function commitToAddition(
        newProductItem: any,
        details: { quantity: number; storeId?: any }
    ) {
        setProduct(newProductItem);
        setAlternativeQuantity(details.quantity);

        setCommittedToAddition(true);
    }
    return {
        ...queryProps,
        commitToAddition,
        setCommittedToAddition,
    };
};

export default useAddItem;
