import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ConfirmSchool from 'src/screens/claim-schools-flow/confirmSchool';
import SelectSchool from 'src/screens/claim-schools-flow/selectSchool';
import {
    CommonStepProps,
    ClaimSchoolSteps,
    ClaimSchoolsControllerState,
} from 'src/screens/claim-schools-flow/types';
import useRouter from 'src/hooks/useRouter';
import {
    makeStyles,
    Box,
    Typography,
    Collapse,
    Fade,
    ButtonBase,
    CircularProgress,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import useUserInfo from 'src/hooks/useUserInfo';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        background: COLORS.whiteSmoke,
        minHeight: '100vh',
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    backIcon: {
        fontSize: '1.7rem',
    },
    backIconHidden: {
        fontSize: '1.7rem',
        color: 'transparent',
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    backIconBalance: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
        color: 'transparent',
    },
    paginationWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        paddingTop: '.5rem',
    },
    closeIcon: {
        width: '2rem',
        height: '2rem',
        margin: '2rem 1.25rem 1.5rem 0rem',
        fontSize: '1.7rem',
    },
    hiddenCloseIcon: {
        display: 'none',
    },
    stepDescription: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 2,
        margin: '0rem 2rem',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    screenDescription: {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 600,
        padding: '0rem 4rem 1rem',
        letterSpacing: '-0.30pt',
        lineHeight: '1.3rem',
    },
    loadingContainer: {
        marginTop: '11rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

/**
 * Additional cleanup might be possible, as of now we are including every possible step inside of every step.
 * We could alternatively build the next copmponent as we are generating the next step instead.
 */
const Step = (props: CommonStepProps) => {
    const { claimSchoolStep } = props;
    switch (claimSchoolStep as any) {
        case 'selectSchool':
            return <SelectSchool {...props} />;
        case 'confirmSchool':
        default:
            return <ConfirmSchool {...props} />;
    }
};

type StepDescriptionProps = {
    currentStep?: string;
    enrollSubStep?: string;
};

const StepDescription = ({
    currentStep,
    enrollSubStep,
}: StepDescriptionProps) => {
    const classes = useStyles();
    let topText: { title: string; description?: string } = { title: '' };
    if (enrollSubStep === 'schoolSearched') {
        topText = { title: 'Select your school' };
    } else {
        switch (currentStep) {
            case 'selectSchool':
                topText = {
                    title: "Let's find your school",
                };
                break;
            case 'confirmSchool':
            default:
                topText = {
                    title: 'Confirm selected school',
                };
                break;
        }
    }

    return (
        <>
            <Typography className={classes.screenTitle}>
                {topText.title}
            </Typography>
            <Collapse in={!!topText.description}>
                <Typography
                    className={classes.screenDescription}
                    style={{ minHeight: '1rem' }}
                >
                    {topText.description}
                </Typography>
            </Collapse>
        </>
    );
};

const indexForStep: any = {
    selectSchool: 0,
    confirmSchool: 1,
};

const stepsFromStep: any = {
    selectSchool: ['selectSchool'],
    confirmSchool: ['selectSchool', 'confirmSchool'],
};

function getDisplayIndex(currentStep: string) {
    return (indexForStep as any)[currentStep];
}

/** Renders and animates the list of steps as they are added by the flow controller */
const Steps = (props: CommonStepProps) => {
    const {
        claimSchoolStep,
        userType,
        updateControllerValues,
        enrollSubStep,
        ...rest
    } = props;
    const currentStep = claimSchoolStep as ClaimSchoolSteps;
    const { query, history } = useRouter();
    const { notifyUserUpdate } = useUserUpdateContext();
    const { selectedSchoolIds, isSchoolSaved } =
        query as ClaimSchoolsControllerState;
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const [hasBuiltPriorSteps, setHasBuiltPriorSteps] = useState(false);
    const [, setIsFetchingFromSteps] = useState<boolean | undefined>(false);

    function finishFlow() {
        notifyUserUpdate();
        history.push({
            pathname: '/user-profile',
            search: '',
        });
    }

    const [steps, setSteps] = useState<string[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    // our pagination dots display index is different from our real index
    const [, setDisplayIndex] = useState(getDisplayIndex(currentStep));
    useEffect(() => {
        if (currentStep && hasBuiltPriorSteps) {
            if (steps.indexOf(currentStep) < 0) {
                setSteps((oldSteps) => [...oldSteps, currentStep]);
            } else {
                setActiveIndex(steps.indexOf(currentStep));
            }
        }
    }, [activeIndex, currentStep, steps, hasBuiltPriorSteps]);

    useEffect(() => {
        setDisplayIndex(getDisplayIndex(currentStep));
    }, [currentStep]);

    useEffect(() => {
        if (userInfo && !hasBuiltPriorSteps) {
            if (currentStep) {
                setSteps(stepsFromStep[currentStep] || []);
            } else {
                setSteps(['selectSchool']);
            }
            setHasBuiltPriorSteps(true);
        }
    }, [userInfo, hasBuiltPriorSteps, currentStep]);

    function onTransitionEnd() {
        if (activeIndex === 0) {
            setSteps(['selectSchool']);
        }
        // for some reason the SwipeableViews ref property does not point to the div that actually scrolls
        // falling back to querying for id instead
        const scrollingElement = document.querySelector(
            '#reward-registration-swipeable'
        );
        if (scrollingElement) {
            scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const hideCloseButton =
        selectedSchoolIds === '' || isSchoolSaved === 'false';

    return (
        <Box className={classes.screen} id="rewards-enroll-screens">
            <NavTop
                minHeight="16rem"
                marginBottom="-12rem"
                bgImageSrc={require('../../assets/bgs/bgIllustrationYellowbackground.svg')}
                bgColor={COLORS.rewardsYellow}
            >
                <Box className={classes.commonNav}>
                    <ButtonBase
                        onClick={finishFlow}
                        className={
                            hideCloseButton
                                ? classes.hiddenCloseIcon
                                : classes.closeIcon
                        }
                        id="donation-flow-close-button"
                    >
                        <img
                            src={require('../../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </ButtonBase>
                </Box>
            </NavTop>
            <Box className={classes.stepDescription}>
                <StepDescription
                    currentStep={currentStep}
                    enrollSubStep={enrollSubStep}
                />
            </Box>
            <Fade in={hasBuiltPriorSteps}>
                {steps.length === 0 ? (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                ) : (
                    <SwipeableViews
                        disabled
                        id="reward-registration-swipeable"
                        enableMouseEvents={false}
                        onTransitionEnd={onTransitionEnd}
                        index={activeIndex}
                        style={{
                            flexGrow: 1,
                            minHeight: 'calc(100vh - 12rem)',
                        }}
                        containerStyle={{
                            flexGrow: 1,
                            minHeight: 'calc(100vh - 12rem)',
                        }}
                    >
                        {steps.map((s) => (
                            <Step
                                key={s}
                                claimSchoolStep={s}
                                userType={userType}
                                updateIsFetching={setIsFetchingFromSteps}
                                updateControllerValues={updateControllerValues}
                                {...rest}
                            />
                        ))}
                    </SwipeableViews>
                )}
            </Fade>
        </Box>
    );
};

export default Steps;
