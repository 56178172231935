/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useMemo, useState } from 'react';
import {
    Typography,
    makeStyles,
    Box,
    Tabs,
    Tab,
    Button,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import useUserInfo from 'src/hooks/useUserInfo';
import COLORS from 'src/lib/colors';
import DigitalCouponsWallet from './digital-coupons-wallet';

const useStyles = makeStyles({
    wrapper: {
        width: '100%',
        background: '#F3F3F3',
        flexGrow: 1,
    },
    tabName: {
        textTransform: 'capitalize',
        width: '33%',
    },
    tabWrapper: {
        background: '#F3F3F3',
        padding: '1.5rem',
        margin: '0 auto',
    },
    tabNameWrapper: {
        background: '#FFFFFF',
        borderBottom: '1px solid grey',
    },
    availabelCouponButton: {
        width: '90%',
        padding: '.5rem',
        minHeight: '2.5rem',
        textTransform: 'none',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        fontWeight: 400,
        fontSize: '1.1rem',
        marginLeft: '5%',
        marginTop: '2rem',
        marginBottom: '2rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    saveCouponText: {
        fontWeight: 400,
        fontSize: '1.19rem',
        marginBottom: '1.5rem',
        textAlign: 'center',
        marginTop: '1rem',
    },
    initRedeemTextWrapper: {
        backgroundColor: '#81C671',
        padding: '0.5rem 1rem',
        height: '3.2rem',
        borderRadius: '0.5rem',
        marginBottom: '1.5rem',
        marginTop: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
    },
    initTextWrapper: {
        backgroundColor: '#fff',
        padding: '0.5rem 1rem',
        height: 'auto',
        borderRadius: '4px',
        marginBottom: '1.5rem',
        display: 'flex',
        alignItems: 'center',
    },
    initText: {
        display: 'inline-block',
        marginLeft: '1rem',
        textAlign: 'left',
        width: '85%',
        fontWeight: 500,
        lineHeight: '20px',
    },
    exlIcon: {
        lineHeight: '73px',
        display: 'flex',
    },
    skeletonWrapper: {
        backgroundColor: '#fff',
        padding: '1rem',
        height: '136px',
        display: 'flex',
    },
    skeletonLeft: {
        backgroundColor: '#f3f3f3',
        width: '40%',
        display: 'inline-block',
        height: '106px',
    },
    skeletonRight: {
        backgroundColor: '#fff',
        display: 'inline-block',
        width: '55%',
        height: '106px',
        marginLeft: '1rem',
    },
    initNoDataText: {
        fontSize: '1.18rem',
        fontWeight: 400,
        textAlign: 'center',
        marginTop: '1rem',
    },
    allRedeemedText: {
        padding: '15px',
        width: '297px',
        height: '300px',
        margin: '0 auto',
        textAlign: 'center',
    },
});
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            className={classes.tabWrapper}
            hidden={value !== index}
            id={`available-coupons-${index}`}
            aria-labelledby={`available-coupons-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function DigitalCoupons({
    staplesRewardsDetail,
    latestCouponsList,
    isAllCoupunsRedeemed,
    couponSavings,
    filterAndSetCouponList,
}: any) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [coupValidDate, setCoupValidDate] = useState<any>();
    const { userInfo } = useUserInfo();
    const rewardsNumber = userInfo?.rewardsNumber || '0';

    const handleChange = (_event: any, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (staplesRewardsDetail?.data?.couponValid) {
            setCoupValidDate(
                format(
                    parseISO(staplesRewardsDetail?.data?.couponValid),
                    'MM/dd/yy'
                )
            );
        }
    }, [staplesRewardsDetail?.data?.couponValid]);
    const countOfRedeemedCoupons = useMemo(() => {
        const redeemedCoupons = latestCouponsList.filter(
            (coupon: any) =>
                coupon.category === 'Coupon' && coupon.couponInfo.isRedeemed
        );
        return redeemedCoupons.length;
    }, [latestCouponsList.length]);
    return (
        <div>
            <div className={classes.wrapper}>
                <div style={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="digital-coupons"
                        className={classes.tabNameWrapper}
                    >
                        <Tab
                            label={`Available(${
                                !rewardsNumber
                                    ? latestCouponsList?.filter(
                                          (x: any) =>
                                              x?.couponInfo?.isNewCoupon &&
                                              !x?.couponInfo?.isRedeemed
                                      )?.length
                                    : latestCouponsList?.filter(
                                          (x: any) => !x.couponInfo?.isRedeemed
                                      )?.length || 0
                            })`}
                            id="availableCoupons"
                            className={classes.tabName}
                        />
                        <Tab
                            label={`Saved(${
                                !rewardsNumber
                                    ? latestCouponsList?.filter(
                                          (x: any) =>
                                              x?.couponInfo?.isNewCoupon &&
                                              x.couponInfo.isSaved &&
                                              !x?.couponInfo?.isRedeemed
                                      )?.length
                                    : latestCouponsList?.filter(
                                          (x: any) =>
                                              x.couponInfo.isSaved &&
                                              !x.couponInfo?.isRedeemed
                                      )?.length || 0
                            })`}
                            id="savedCoupons"
                            className={classes.tabName}
                        />
                        <Tab
                            label={`Redeemed(${
                                !rewardsNumber
                                    ? latestCouponsList?.filter(
                                          (x: any) => x?.couponInfo?.isNewCoupon
                                      )?.length
                                    : latestCouponsList?.filter(
                                          (x: any) => x.couponInfo.isRedeemed
                                      )?.length || 0
                            })`}
                            id="redeemedCoupons"
                            className={classes.tabName}
                        />
                    </Tabs>
                </div>
                {value === 0 && isAllCoupunsRedeemed ? (
                    <Typography
                        className={classes.allRedeemedText}
                        style={{ marginTop: '0.9rem' }}
                    >
                        <b>Great Job!</b> You have redeemed all digital coupons.
                        Check back on Sunday for more coupons.
                    </Typography>
                ) : latestCouponsList.length === 0 ||
                  (!rewardsNumber &&
                      latestCouponsList?.filter(
                          (x: any) => x?.couponInfo?.isNewCoupon
                      )?.length === 0) ? (
                    <TabPanel value={value} index={0}>
                        <div className={classes.tabWrapper}>
                            <div className={classes.initTextWrapper}>
                                <span className={classes.exlIcon}>
                                    <img
                                        src={require('../../assets/icons/iconExlametryPoint.svg')}
                                        alt="exlametry icon"
                                    />
                                </span>
                                <Typography className={classes.initText}>
                                    {rewardsNumber !== null &&
                                    rewardsNumber !== '0'
                                        ? `These coupons will be replaced on ${coupValidDate}. Saving the coupons will extend their expiration date!`
                                        : `Join Now or Sign In to see more coupons!`}
                                </Typography>
                            </div>
                            <div className={classes.skeletonWrapper}>
                                <div className={classes.skeletonLeft}> </div>
                                <div className={classes.skeletonRight}>
                                    <div
                                        style={{
                                            backgroundColor: '#f3f3f3',
                                            height: '24px',
                                            width: '60%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {' '}
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: '#f3f3f3',
                                            height: '14px',
                                            width: '80%',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        {' '}
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: '#f3f3f3',
                                            height: '10px',
                                            width: '60%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {' '}
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: '#f3f3f3',
                                            height: '24px',
                                            width: '40%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {' '}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                ) : (
                    <>
                        <div className={classes.tabWrapper}>
                            {value === 0 &&
                                !!rewardsNumber &&
                                rewardsNumber === '0' && (
                                    <div className={classes.initTextWrapper}>
                                        <span className={classes.exlIcon}>
                                            <img
                                                src={require('../../assets/icons/iconExlametryPoint.svg')}
                                                alt="exlametry icon"
                                            />
                                        </span>
                                        <Typography
                                            className={classes.initText}
                                        >
                                            Join Now or Sign In to see more
                                            coupons!
                                        </Typography>
                                    </div>
                                )}

                            {value !== 2 && (
                                <DigitalCouponsWallet
                                    staplesRewardsDetail={latestCouponsList}
                                    tab={value}
                                    setLatestCouponsList={
                                        filterAndSetCouponList
                                    }
                                />
                            )}
                        </div>
                    </>
                )}
                <>
                    {value === 1 && isAllCoupunsRedeemed ? (
                        <Typography className={classes.allRedeemedText}>
                            <b>Great Job!</b> You have redeemed all digital
                            coupons. Check back on Sunday for more coupons.
                        </Typography>
                    ) : (
                        value === 1 &&
                        latestCouponsList?.filter(
                            (x: any) =>
                                // x?.couponInfo?.isNewCoupon &&
                                x.couponInfo.isSaved &&
                                !x?.couponInfo?.isRedeemed
                            // x?.couponInfo?.offerType === 'Hero'
                        )?.length === 0 && (
                            <>
                                <Typography className={classes.initNoDataText}>
                                    You have not saved any coupons.
                                </Typography>
                                <Button
                                    className={classes.availabelCouponButton}
                                    onClick={() => setValue(0)}
                                >
                                    Check available coupons
                                </Button>
                            </>
                        )
                    )}
                </>
                <>
                    {value === 2 && countOfRedeemedCoupons > 0 ? (
                        <div className={classes.tabWrapper}>
                            {couponSavings > 1 ? (
                                <div className={classes.initRedeemTextWrapper}>
                                    <span className={classes.exlIcon}>
                                        <img
                                            src={require('../../assets/icons/iconDollar.svg')}
                                            alt="dollar icon"
                                        />
                                    </span>
                                    <Typography className={classes.initText}>
                                        You saved ${Math.floor(couponSavings)}{' '}
                                        with in-app coupons this year!
                                    </Typography>
                                </div>
                            ) : null}
                            {/* {latestRedeemCouponsList?.} */}
                            <DigitalCouponsWallet
                                staplesRewardsDetail={latestCouponsList}
                                tab={value}
                            />
                        </div>
                    ) : value === 2 ? (
                        <>
                            <Typography className={classes.initNoDataText}>
                                You have not redeemed any coupons.
                            </Typography>
                            <Button
                                className={classes.availabelCouponButton}
                                onClick={() => setValue(0)}
                            >
                                Check available coupons
                            </Button>
                        </>
                    ) : null}
                </>
            </div>
        </div>
    );
}
export default DigitalCoupons;
