import React, { useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import useEventsReporter from 'src/hooks/useEventsReporter';
import RewardsEnrolledNonEducator from 'src/screens/rewards-old/rewards-enrolled-non-educator';
import RewardsEnrolledEducator from 'src/screens/rewards-old/rewards-enrolled-educator';
import RewardsUnenrolled from 'src/screens/rewards-old/rewards-unenrolled';
import useUserInfo from 'src/hooks/useUserInfo';
import { isAccountSetupFinished } from 'src/lib/utils/account-utils';
import { fullName } from 'src/lib/utils/user-utils';
import LoadingOverlay from 'src/components/loading-overlay';
import ScrollContainer from 'src/components/screen-scroll';
import COLORS from 'src/lib/colors';

/** responsible for fetching the baseline rewards info from the user */
const useStyles = makeStyles({
    topNavContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        background: COLORS.rewardsYellow,
        backgroundImage: 'url(bgs/classroomRewardsBg.svg)',
        height: 'calc(100vh - 72px)',
    },
});
function Rewards({ switchTab }: any) {
    const classes = useStyles();
    const { userInfo, isFetchingUserInfo, isProcessing } = useUserInfo();
    const { customEvent, analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    if (isProcessing && userInfo) {
        return (
            <div className={classes.topNavContainer}>
                {switchTab()}
                <LoadingOverlay variant="heart" theme="transparent-dark" open />
            </div>
        );
    }
    if (isAccountSetupFinished(userInfo)) {
        if (userInfo?.userType === 'Supporter') {
            return (
                <RewardsEnrolledNonEducator
                    switchTab={switchTab}
                    rewardsDetails={userInfo?.rewardsDetails}
                />
            );
        }
        return (
            <RewardsEnrolledEducator
                switchTab={switchTab}
                rewardsDetails={userInfo?.rewardsDetails}
                userType={userInfo?.userType || ('Educator' as any)}
                userName={fullName(userInfo)}
            />
        );
    }
    if (userInfo) {
        return (
            <RewardsUnenrolled
                switchTab={switchTab}
                userType={userInfo?.userType}
                userConfirmState={userInfo?.userConfirmState}
            />
        );
    }
    if (isFetchingUserInfo) {
        return (
            <div className={classes.topNavContainer}>
                {switchTab()}
                <LoadingOverlay variant="heart" theme="transparent-dark" open />
                ;
            </div>
        );
    }
    return <Typography>Error, could not load user data :(</Typography>;
}
// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedRewards = ({ switchTab }: any) => (
    <ScrollContainer>
        <Rewards switchTab={switchTab} />
    </ScrollContainer>
);
export default WrappedRewards;
