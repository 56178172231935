import React from 'react';
import { DialogContentText, makeStyles, Typography } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import ErrorDialog, { ErrorDialogProps } from 'src/components/error-dialog';
import { DonationResponseCode } from 'src/lib/api-client/rewards-donation';

type DonationErrorDialogProps = ErrorDialogProps & {
  recipientName: string;
  errorType: DonationResponseCode | null | undefined | 'Unknown';
  handleGiveToAnother: () => void;
};

const useDefaultContentStyles = makeStyles({
  errorIcon: {
    width: '6.5rem',
  },
  dialogContentText: {
    color: COLORS.secondaryNeutralCharcoal,
    textAlign: 'center',
    marginTop: '1rem',
    letterSpacing: '-0.5pt',
    lineHeight: '1.2rem',
  },
});

const useDonationErrorContentStyles = makeStyles({
  errorIcon: {
    width: '6.5rem',
  },
  description: {
    textAlign: 'center',
    marginTop: '1.2rem',
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  note: {
    textAlign: 'center',
    fontSize: '.8rem',
    marginTop: '2rem',
    marginBottom: '0rem',
    color: COLORS.secondaryNeutralBlack,
  },
});

const defaultTitle = 'Unable to donate';
const maxContributionTitle = 'This Piggie is Full!';

function DefaultErrorContent() {
  const classes = useDefaultContentStyles();
  return (
    <>
      <img
        className={classes.errorIcon}
        src={require('../../assets/icons/iconMissingUserError.svg')}
        alt="not found error icon"
      />
      <DialogContentText
        id="error-dialog-description"
        className={classes.dialogContentText}
      >
        Whoops! We were unable to process your donation. Please contact member
        services
      </DialogContentText>
    </>
  );
}

function MaxContributionErrorContent({
  recipientName,
}: {
  recipientName: string;
}) {
  const classes = useDonationErrorContentStyles();
  return (
    <>
      <img
        className={classes.errorIcon}
        src={require('../../assets/icons/iconErrorGivingMax.svg')}
        alt="not found error icon"
      />
      <Typography id="error-dialog-description" className={classes.description}>
        It looks like {recipientName} has reached their contribution limit.
        Please try donating to another school or teacher.
      </Typography>
      <Typography id="error-dialog-description" className={classes.note}>
        Note: Teachers and schools can receive up to $1000 in rewards over a 6
        month period.
      </Typography>
    </>
  );
}

export default function DonationErrorDialog(props: DonationErrorDialogProps) {
  const { open, handleClose, recipientName, handleGiveToAnother, errorType } =
    props;

  const maxContributionReached = errorType === 'GiftLimitReached';

  const title = maxContributionReached ? maxContributionTitle : defaultTitle;
  const primaryBtnLabel = maxContributionReached ? 'Give to another' : 'Close';
  const primaryBtnClick = maxContributionReached
    ? handleGiveToAnother
    : handleClose;
  return (
    <ErrorDialog
      open={open}
      handleClose={handleClose}
      errorTitle={title}
      primaryBtnLabel={primaryBtnLabel}
      handlePrimaryBtnClick={primaryBtnClick}
      hideSecondaryBtn
      hideCloseBtn
    >
      {maxContributionReached ? (
        <MaxContributionErrorContent recipientName={recipientName} />
      ) : (
        <DefaultErrorContent />
      )}
    </ErrorDialog>
  );
}
