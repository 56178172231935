import { ProductsEntity } from 'src/lib/api-client/adept-mind-types';

export const getProductQuantity = (product?: ProductsEntity): number =>
  product?.models && product?.models[0] && product?.models[0]?.variants
    ? product.models[0].variants[0].inventory_quantity?.quantity
    : 0;

export const getProductModel = (product?: ProductsEntity): string =>
  product?.models && product?.models[0] ? product?.models[0].model_id : '';

export const getProductSalePriceNum = (product?: ProductsEntity) =>
  product?.models && product?.models[0] && product?.models[0]?.variants
    ? product.models[0].variants[0].sale_price
    : 0;

export const getProductSalePrice = (product: ProductsEntity) =>
  getProductSalePriceNum(product)?.toFixed(2);

const getProductOriginalPriceNum = (product: ProductsEntity) =>
  product.models && product.models[0].variants
    ? product.models[0].variants[0].price
    : 0;

export const getProductOriginalPrice = (product: ProductsEntity) =>
  getProductOriginalPriceNum(product)?.toFixed(2);

export const isProductOnSale = (product: ProductsEntity) =>
  getProductSalePrice(product) !== getProductOriginalPrice(product);

export const getProductPriceNum = (product: ProductsEntity) =>
  isProductOnSale(product)
    ? getProductSalePriceNum(product)
    : getProductOriginalPriceNum(product);

export const getProductPrice = (product: ProductsEntity) =>
  isProductOnSale(product)
    ? getProductSalePrice(product)
    : getProductOriginalPrice(product);

export function productImg(product: ProductsEntity) {
  return product?.image || require('../../assets/products/product-fallback.png');
}

export default getProductPrice;
