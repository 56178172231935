/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useEffect } from 'react';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    topDealTitle: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        textTransform: 'uppercase',
    },
    scrollLocation: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        marginLeft: '4px',
        color: '#585858',
    },
    cardItem: {
        width: '90px',
        borderRadius: '15px',
        boxShadow: 'none',
        color: '#343434',
    },
    productDetail: {
        width: '125px',
        fontSize: '13px',
        margin: '10px 0 0 0',
        color: '#343434',
        lineHeight: '16px',
        overflowWrap: 'anywhere',
        display: '-webkit-box',
        maxWidth: '200px',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        fontWeight: 400,
    },
    buttonAdd: {
        background: '#CC0000',
        borderRadius: '25px',
        width: '75px',
        height: '30px',
        color: 'white',
        fontWeight: 700,
        marginTop: '10px',
    },
    titleContainer: {
        paddingBottom: '14px',
    },
    productImages: {
        width: '115px',
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageMainCardContainer: {
        padding: '19px !important',
    },
    shopDepartmentContainer: {
        marginTop: '26px',
        '& #shop-department-product': {
            padding: '0 !important',
        },
    },
    mainDepartmentName: {
        height: '58px',
        borderBottom: '1px solid #DDDDDD',
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
    mainDepartmentNameConatiner: {
        display: 'flex',
        flexDirection: 'column',
    },
    iconNameAlign: {
        display: 'flex',
        gap: '14px',
        alignItems: 'center',
    },
    btnAlign: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    imageAlign: {
        width: '30px',
    },
});

interface IDepartments {
    data: any;
    onClick: (data: any, label?: string) => void;
    // eslint-disable-next-line react/no-unused-prop-types
    openService?: boolean | undefined;
    // eslint-disable-next-line react/no-unused-prop-types
    tabindex?: number;
}

interface IListOfItems {
    image?: string;
    label: string;
    hasMoreList: boolean;
    isImageRequired?: boolean;
}

const imageIcon = (title: string) => (
    <div
        style={{
            width: '30px',
            height: '30px',
            background: '#10A0D1',
            color: '#ffffff',
            fontSize: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 400,
        }}
    >
        {title}
    </div>
);

const ListOfItems = ({ image, label, hasMoreList, isImageRequired = false }: IListOfItems) => {
    const classes = useStyles();
    return (
        <data className={classes.mainDepartmentName}>
            <div className={classes.iconNameAlign}>
                {isImageRequired && image ? <img className={classes.imageAlign}
                    src={image} alt="Icons" /> :
                    isImageRequired && imageIcon(label)}
                <Typography>{label}</Typography>
            </div>
            {hasMoreList ? (
                <img
                    src={require('../../assets/icons/iconRight.svg')}
                    alt="right arrow"
                />
            ) : null}
        </data>
    );
};
// eslint-disable-next-line react/no-unused-prop-types
const Departments = ({ data, onClick, openService, tabindex }: IDepartments) => {
    const classes = useStyles();
    return (
        <ButtonBase
            onClick={() => onClick(data, data?.ariaLabel)}
            className={classes.btnAlign}
            role='button'
            aria-label={data?.ariaLabel}
            tabIndex={tabindex && tabindex + 7}
        >
            <ListOfItems
                label={data?.ariaLabel}
                hasMoreList={
                    data?.navigationText?.[0]?.navigationTextItems?.length
                }
                image={!openService ? data?.imageUrl : ''}
                isImageRequired={!openService}
            />
        </ButtonBase>
    );
};
// eslint-disable-next-line react/no-unused-prop-types
const DepartmentsNavigationText = ({ data, onClick }: IDepartments) => {
    const classes = useStyles();
    return (
        <ButtonBase
            onClick={() => onClick(data, data?.ariaLabel)}
            className={classes.btnAlign}
        >
            <ListOfItems
                label={data?.ariaLabel}
                hasMoreList={
                    data?.navigationTextSubOption?.[0]
                        ?.navigationTextSubOptionItems?.length
                }
            />
        </ButtonBase>
    );
};
// eslint-disable-next-line react/no-unused-prop-types
const DepartmentsNavigationTextSubOption = ({
    data,
    onClick,
}: IDepartments) => {
    const classes = useStyles();
    return (
        <ButtonBase onClick={() => onClick(data)} className={classes.btnAlign}>
            <ListOfItems label={data?.ariaLabel} hasMoreList={false} />
        </ButtonBase>
    );
};

const retrieveDepartmentOption = (
    getShopDepartment: any,
    handleOnClickDepartment: any,
    navigationTextSubOption: any,
    navigationText: any,
    openService = false
) => {
    if (
        !navigationTextSubOption?.length &&
        !navigationText.length &&
        getShopDepartment?.length
    ) {
        return getShopDepartment?.map((mainDep: any, index: number) => (
            <Departments
                data={mainDep}
                onClick={handleOnClickDepartment}
                openService={openService}
                tabindex={index}
            />
        ));
    }
    if (!navigationTextSubOption?.length && navigationText.length) {
        return navigationText?.map((mainDep: any) => (
            <DepartmentsNavigationText
                data={mainDep}
                onClick={handleOnClickDepartment}
            />
        ));
    }
    if (navigationTextSubOption?.length) {
        return navigationTextSubOption?.map((mainDep: any) => (
            <DepartmentsNavigationTextSubOption
                data={mainDep}
                onClick={handleOnClickDepartment}
            />
        ));
    }
    return null;
};
const ShopDepartment = ({
    getShopDepartment,
    handleOnClickDepartment,
    navigationTextSubOption,
    navigationText,
    title,
    handleServices,
    openService,
}: any) => {
    const classes = useStyles();
    const disTitle = title();
    console.log({
        getShopDepartment,
        services: getShopDepartment?.filterOutMobileOnlyData,
    });

    if (openService) {
        return (
            <div
                className={classes.shopDepartmentContainer}
                style={{
                    marginTop: disTitle === 'Departments' ? '26px' : '0px',
                }}
            >
                <div className={classes.titleContainer}>
                    <Typography className={classes.topDealTitle}>
                        {disTitle}
                    </Typography>
                </div>

                <div className={classes.mainDepartmentNameConatiner}>
                    {retrieveDepartmentOption(
                        getShopDepartment?.filterOutMobileOnlyData,
                        handleOnClickDepartment,
                        navigationTextSubOption,
                        navigationText,
                        openService
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            className={classes.shopDepartmentContainer}
            style={{ marginTop: disTitle === 'Departments' ? '26px' : '0px' }}
        >
            <div className={classes.titleContainer}>
                <Typography tabIndex={6} className={classes.topDealTitle} aria-label="Departments">
                    {disTitle}
                </Typography>
            </div>

            <div className={classes.mainDepartmentNameConatiner}>
                {retrieveDepartmentOption(
                    getShopDepartment?.filterOutMobileOnlyData,
                    handleOnClickDepartment,
                    navigationTextSubOption,
                    navigationText
                )}
                {disTitle === 'Departments' &&
                    getShopDepartment?.filterOutMobileOnlyData?.length ? (
                    <ButtonBase
                        onClick={() => handleServices()}
                        className={classes.btnAlign}
                        aria-label="Services"
                        role="button"
                    >
                        <ListOfItems
                            label="Services"
                            hasMoreList
                            isImageRequired
                        />
                    </ButtonBase>
                ) : null}
            </div>
        </div>
    );
};

export default ShopDepartment;
