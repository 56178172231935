import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles({
  totalReviews: {
    marginLeft: '0.5rem',
    textDecoration: 'underline',  // remove textDecoration for underline
  },
});

export type ProductStarRatingProps = {
  ratingValue: number;
  totalReviews?: number | undefined;
  maxStars?: number;
  onClick?: any;
  textCssClassName?: string;
};

function getStarIcon(ratingValue: number, currentStar: number): string {
  const starLevel = ratingValue - currentStar;
  if (starLevel >= 1) {
    return require('../assets/icons/iconStarFilled.svg');
  }
  if (!starLevel || starLevel <= 0) {
    return require('../assets/icons/iconStarEmpty.svg');
  }

  return require('../assets/icons/iconStarHalfFilled.svg');
}

export const ReviewStars = ({
  ratingValue,
  maxStars = 5,
}: {
  ratingValue: number;
  maxStars?: number;
}) => (
  <>
    {[...Array(maxStars)].map((_x, i) => {
      const starIcon = getStarIcon(ratingValue, i);
      return <img src={starIcon} alt="star" id={`star_${i}`} aria-label={`${ratingValue} star rating`} />;
    })}
  </>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ProductStarRating(props: ProductStarRatingProps) {
  const { ratingValue, totalReviews, maxStars = 5, onClick, textCssClassName = "" } = props;
  const classes = useStyles();
  return (
    <Box
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      display="flex"
      flexDirection="row"
      id="star_container"
    >
      <ReviewStars ratingValue={ratingValue} maxStars={maxStars} />
      {typeof totalReviews === 'number' && (
        <Typography className={clsx(classes.totalReviews, textCssClassName)} aria-label={`${totalReviews?.toLocaleString().replace(/,/g, ',')} reviews`}>
          {totalReviews?.toLocaleString().replace(/,/g, ',')}
        </Typography>
      )}
    </Box>
  );
}
