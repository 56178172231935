import React from 'react';
import { Button, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { ProductsEntity } from 'src/lib/api-client/adept-mind-types';
import ProductStarRating from 'src/components/product-star-rating';
import {
  getProductOriginalPrice,
  getProductPrice,
  isProductOnSale,
  productImg,
} from 'src/lib/utils/products-utils';
import COLORS from 'src/lib/colors';
import DiagonalStrikethroughText from 'src/components/diagonal-strikethrough-text';

const useStyles = makeStyles({
  similarProductContainer: {
    paddingLeft: '1em',
    paddingRight: '1rem',
  },
  productImageButton: {
    width: '100%',
    cursor: 'pointer',
    minHeight: '13rem',
  },
  productImage: {
    width: 'auto',
    height: '80px',
    display: 'block',
    margin: 'auto',
  },
  productTitle: {
    marginTop: '0.5rem',
    width: '100%',
    fontSize: '1.1667rem',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  prices: {
    marginTop: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  productPrice: {
    fontSize: '1.333rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  addToListButton: {
    fontSize: '1.1667rem',
    color: COLORS.brandCyan,
    textAlign: 'center',
    width: '100%',
    '& span': {
      minWidth: '6rem',
    },
  },
  productStarRating: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
    width: '100%',
  },
});

type SimilarProductProps = {
  productItem: ProductsEntity;
  handleSimilarProductClick?: (product: ProductsEntity) => void;
  handleSimilarProductReviewsClick?: (product: ProductsEntity) => void;
  handleAddToListClick?: (product: ProductsEntity) => void;
  hideFeatures?: boolean;
};

/** Renders and animates the list of steps as they are added by the flow controller */
const SimilarProductItem = (props: SimilarProductProps) => {
  const {
    productItem,
    handleSimilarProductClick,
    handleSimilarProductReviewsClick,
    handleAddToListClick,
    hideFeatures,
  } = props;
  const classes = useStyles();

  function onAddToListClick() {
    if (handleAddToListClick) {
      handleAddToListClick(productItem);
    }
  }

  function onSimilarProductClick() {
    if (handleSimilarProductClick) {
      handleSimilarProductClick(productItem);
    }
  }

  function onSimilarProductReviewsClick() {
    if (handleSimilarProductReviewsClick) {
      handleSimilarProductReviewsClick(productItem);
    }
  }
  return (
    <div
      key={`similar-product-${productItem.prod_id}`}
      className={classes.similarProductContainer}
      id={`similar-product-${productItem.prod_id}`}
    >
      <ButtonBase
        className={classes.productImageButton}
        onClick={onSimilarProductClick}
      >
        <div>
          <img
            className={classes.productImage}
            id={`similar-product-image-${productItem.prod_id}`}
            src={productImg(productItem)}
            alt="product"
          />

          <Typography className={classes.productTitle}>
            {productItem.title}
          </Typography>
          <div className={classes.productStarRating}>
            <ProductStarRating
              onClick={onSimilarProductReviewsClick}
              ratingValue={productItem?.product_rating || 0}
              totalReviews={productItem?.num_reviews}
            />
          </div>
          <div className={classes.prices}>
            <Typography className={classes.productPrice}>
              ${getProductPrice(productItem)}
            </Typography>
            {isProductOnSale(productItem) && (
              <DiagonalStrikethroughText>
                ${getProductOriginalPrice(productItem)}
              </DiagonalStrikethroughText>
            )}
          </div>
        </div>
      </ButtonBase>

      {!hideFeatures && (
        <Button
          id={`similar-product-add-to-list-${productItem.prod_id}`}
          onClick={onAddToListClick}
          className={classes.addToListButton}
          disabled={hideFeatures}
        >
          Add to List
        </Button>
      )}
    </div>
  );
};

export default SimilarProductItem;
