/* eslint-disable import/prefer-default-export */
import { User, RewardsDetails } from 'src/services/types';

export function isAccountSetupFinished(u?: User) {
    if (!u || !u.userType) {
        return false;
    }
    return u.userConfirmState === 'Confirmed';
}

export function getAvailablePayoutAmount(
    rewardsDetails?: RewardsDetails
): number {
    if (rewardsDetails?.promoCard && rewardsDetails?.payoutAvailableAmount) {
        return rewardsDetails?.payoutAvailableAmount || 0;
    }
    return 0;
}
