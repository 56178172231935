/* eslint-disable import/prefer-default-export */

type NotificationPermissionResult = {
    /**
     * Returns true if the site has permission to show notifications in the browser.
     * This does not guarantee a notification will appear, since
     * browsers themselves need notification permissions on the OS level.
     * Detecting those OS level settings is not currently possible.
     */
    granted: boolean;
    /** Used for debugging why we don't have permissions granted. */
    reason: string;
    /** Used for debugging why we don't have permissions granted. */
    reasonCode: string;
    /**
     * We have no direct way of telling if the permission prompt was visible to the user or not.
     * Once denied, permissions are always denied until the user has to go into their browser settings to turn it off.
     */
    userInteractionGuess: boolean;
};

export async function readNotificationPermission(): Promise<NotificationPermissionResult> {
    // First check if the browser supports notifications
    if (!('Notification' in window)) {
        return {
            granted: false,
            userInteractionGuess: false,
            reason: 'Browser does not support notifications',
            reasonCode: 'browserSupport',
        };
    }
    const timeOfRequest = new Date().getTime();
    const { permission } = Notification;
    const timeToReply = new Date().getTime() - timeOfRequest;
    const userInteractionGuess = timeToReply > 500;
    if (permission === 'granted') {
        return {
            granted: true,
            userInteractionGuess,
            reason: 'User explicitly allowed notifications',
            reasonCode: 'userGranted',
        };
    }
    if (permission === 'denied') {
        return {
            granted: false,
            userInteractionGuess,
            reason: 'User explicitly blocked notifications',
            reasonCode: 'userDenied',
        };
    }
    if (permission === 'default') {
        return {
            granted: false,
            userInteractionGuess,
            reason: 'User dismissed the request to show notifications without comitting a choice.',
            reasonCode: 'userDefault',
        };
    }
    return {
        granted: false,
        userInteractionGuess,
        reason: `Unknown: ${permission}`,
        reasonCode: 'unknown',
    };
}
