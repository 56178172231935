import React from 'react';
import { makeStyles } from '@material-ui/core';
import PaginationDot from 'src/components/pagination-dots/pagination-dot';
import COLORS from 'src/lib/colors';

type PaginationProps = {
  dots?: number;
  index?: number;
  onChangeIndex?: any;
  activeColor?: string;
  inActiveColor?: string;
  disabled?: boolean;
  dotSizeStyle?:any;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

function Pagination({
  dots = 3,
  index: selectedIndex,
  onChangeIndex,
  disabled,
  activeColor = COLORS.brandRed,
  inActiveColor = COLORS.gray80,
  dotSizeStyle,
}: PaginationProps) {
  const classes = useStyles();
  const handleClick = (event: any, index: number) => {
    if (!disabled) {
      onChangeIndex(index);
    }
  };

  const children = [] as any;

  for (let i = 0; i < dots; i += 1) {
    children.push(
      <PaginationDot
        key={i}
        index={i}
        active={i === selectedIndex}
        onClick={handleClick}
        activeColor={activeColor}
        inActiveColor={inActiveColor}
        dotSizeStyle={dotSizeStyle}
      />
    );
  }

  return (
    <div className={classes.root} id="pagination-dots-container">
      {children}
    </div>
  );
}

export default React.memo(Pagination);
