import { createContext, useState, useContext, useEffect } from 'react';
import useRouter from 'src/hooks/useRouter';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { ListItemType, ListResult } from 'src/services/types';

// using ExpectedQuery only for the initial page display for simplicity
type ExpectedQuery = { importListStep: string };
export type CreateImportListPageType =
    | 'select-school'
    | 'view-lists'
    | 'view-items';
type PageDetails = any;
export type Page = {
    type: CreateImportListPageType;
    pageDetails?: PageDetails;
};
type CreateImportListContextState = {
    // handle our own page history stack
    // currently this is independent of the browser history,
    // but we could rewrite it to handle browser state changes
    goToPage: (page: Page) => void;
    goBack: (page?: Page) => void;
    /** clears page stack */
    end: () => void;
    pages: Page[];
    currentPage?: Page;
    lastPoppedPage?: Page;
    setItemsFromImportList: (importedItems: ListItemType[]) => void;
    itemsFromImportList: ListItemType[];
    setImportedListDetails: (importedList: any) => void;
    importedListDetails: any;
    setSelectedSchoolDetails: (selectedSchool: any) => void;
    selectedSchoolDetails: any;
    activePaginationIndex: number;
};

/** Handles both browsing and searching */
const CreateImportListContext = createContext<CreateImportListContextState>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    goToPage: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    goBack: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    end: () => {},
    pages: [],
    currentPage: undefined,
    lastPoppedPage: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setItemsFromImportList: () => {},
    itemsFromImportList: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setImportedListDetails: () => {},
    importedListDetails: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedSchoolDetails: () => {},
    selectedSchoolDetails: {},
    activePaginationIndex: 0,
});

const initialPage: Page = {
    type: 'select-school',
};

const indexForStep: any = {
    'select-school': 1,
    'view-lists': 2,
};

export function useCreateImportListContextState(): CreateImportListContextState {
    const { history, pathname, query } = useRouter<ExpectedQuery>();
    const [activePaginationIndex, setActivePaginationIndex] = useState(1);
    const [pages, setPages] = useState<Page[]>(
        query.importListStep ? [initialPage] : []
    );
    const [lastPoppedPage, setLastPoppedPage] = useState<Page>(initialPage);
    const currentPage = pages.length > 0 ? pages[pages.length - 1] : undefined;
    function goToPage(newPage: Page) {
        setPages((currentPages) => [...currentPages, newPage]);
    }

    function goBack() {
        if (pages.length > 0) {
            setLastPoppedPage(pages[pages.length - 1]);
        }
        setPages((currentPages) => {
            const newPages = [...currentPages];
            newPages.pop();
            return newPages;
        });
    }

    // ensure createImportList is in our query params when our state allows it
    useEffect(() => {
        if (pages.length > 0) {
            setActivePaginationIndex(
                indexForStep[pages[pages.length - 1].type]
            );
        }
        if (pages.length === 0 && query.importListStep) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { importListStep, ...rest } = query;
            history.push({
                pathname,
                search: makeSearchString({ ...rest }),
            });
        }
        if (pages.length > 0 && !query?.importListStep) {
            history.push({
                pathname,
                search: makeSearchString({ ...query, importListStep: 'true' }),
            });
        }

        // whenever pages value get changed, trigger scroll top for next screen
        const scrollingElement = document.querySelector(
            '#import-list-swipeable'
        );
        if (scrollingElement) {
            scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [pages, query]);

    const [selectedSchoolDetails, setSelectedSchoolDetails] = useState(null);
    const [itemsFromImportList, setItemsFromImportList] = useState<
        ListItemType[]
    >([]);
    const [importedListDetails, setImportedListDetails] =
        useState<ListResult | null>(null);

    function end() {
        if (pages.length > 0) {
            setLastPoppedPage(pages[pages.length - 1]);
        }
        setPages([]);
    }

    return {
        pages,
        currentPage,
        lastPoppedPage,
        goToPage,
        goBack,
        end,
        itemsFromImportList,
        setItemsFromImportList,
        importedListDetails,
        setImportedListDetails,
        selectedSchoolDetails,
        setSelectedSchoolDetails,
        activePaginationIndex,
    };
}

export function useCreateImportListContext() {
    return useContext(CreateImportListContext);
}

export default CreateImportListContext;
