import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Heading } from 'src/components/UI';
import { useRouter, useUserInfo } from 'src/hooks';
import { COACH_SCREENS_ROUTES, REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { mergeClasses } from 'src/stylesheets/utils';
import LoadingOverlay from 'src/components/loading-overlay';
import styles from './styles.module.scss';

function NewAccountIntroScreen() {
    const { userInfo } = useUserInfo();
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);

    const { replace } = useRouter();

    if (!userInfo) {
        return <LoadingOverlay theme="light" open />;
    }

    return (
        <div
            className={mergeClasses(
                styles.flex,
                styles.flexCol,
                styles.alignCenter,
                styles.justifyCenter,
                styles.newAccountIntroScreenContainer
            )}
        >
            <AnimatePresence
                onExitComplete={() => {
                    replace(COACH_SCREENS_ROUTES.location, {
                        onCompleteNavigateTo: REWARDS_ENROLLMENT_ROUTES.welcomeAnimation,
                    });
                }}
            >
                {!isAnimationComplete && (
                    <div className={styles.containerAccIntro}>
                        <motion.div
                            key={1}
                            className={styles.textPurple}
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: { duration: 1.5 },
                            }}
                            exit={{
                                opacity: 0,
                                transition: { duration: 1 },
                            }}
                        >
                            <Heading
                                className={styles.topHeader}
                                type="h3"
                                size={26}
                                weight="bold"
                            >
                                Hi, {userInfo?.firstName}!
                            </Heading>
                        </motion.div>
                        <motion.div
                            key={2}
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: { delay: 1.5, duration: 1.5 },
                            }}
                            exit={{
                                opacity: 0,
                                transition: { duration: 1 },
                            }}
                            onAnimationComplete={() =>
                                setIsAnimationComplete(true)}
                        >
                            <Heading
                                className={styles.bottomHeader}
                                type="h3"
                                size={20}
                                textAlign="textCenter"
                                weight="bold"
                            >
                                Your Staples&reg; account has been created.
                            </Heading>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default NewAccountIntroScreen;
