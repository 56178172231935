import React, { useState, useEffect } from 'react';
import { Typography, makeStyles, ButtonBase } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import COLORS from 'src/lib/colors';
import useUserInfo from 'src/hooks/useUserInfo';
import { getWalletAccountType } from '../../lib/api-client/wallet-activities';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        overflow: 'visible',
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        background: '#ffffff',
        flexDirection: 'column',
        width: '340px',
        height: '270px',
        borderRadius: '1.2rem',
        padding: '1.2rem 2rem',
        marginBottom: '1rem',
        position: 'relative',
        marginTop: '30px',
    },
    title: {
        margin: '1.9rem 0 0.5rem 0',
        fontWeight: 700,
        fontSize: '17px',
    },
    memberType: {
        fontWeight: 400,
        color: COLORS.homeBlack,
        fontSize: '0.9rem',
        lineHeight: '1rem',
    },
    secondaryAccountIcon: {
        height: '5.8rem',
        width: '5.8rem',
        background: COLORS.primaryWhite,
        position: 'absolute',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '-2.7rem',
    },
    cCSWrapper: {
        width: '270px',
        height: '2.7rem',
        background: COLORS.brandRed,
        borderRadius: '1.8rem',
        marginTop: '1rem',
    },
    cCSLinkWrapper: {
        color: COLORS.primaryWhite,
        textDecoration: 'none',
    },
});

function SecondaryAccount() {
    const classes = useStyles();
    const contactUsURL = 'https://www.staplesconnect.com/contact-us';
    const { analyticsCustomEvent } = useEventsReporter();
    const [accountData, setAccountData] = useState({
        accountType: '',
        associatedAccountsData: { firstName: '', lastName: '' } || null,
    });
    const { userInfo } = useUserInfo();
    useEffect(() => {
        const getAccountData = async () => {
            const accountDetails: any = await getWalletAccountType(
                userInfo?.rewardsNumber
            );
            if (accountDetails?.data) {
                const data = {
                    accountType: accountDetails?.data?.accountType || '',
                    associatedAccountsData:
                        accountDetails?.data?.associatedAccountsData?.[0] ||
                        null,
                };
                setAccountData(data);
            }
        };
        if (userInfo?.rewardsNumber) {
            getAccountData();
        }
    }, [userInfo?.rewardsNumber]);
    return (
        <>
            {accountData?.accountType === 'SUB' ? (
                <div
                    className={classes.contentWrapper}
                    style={{ margin: '62px auto 10px' }}
                >
                    <div className={classes.secondaryAccountIcon}>
                        <img
                            src={require('../../assets/icons/secondaryAccount.svg')}
                            alt="secondary account"
                        />
                    </div>
                    <Typography className={classes.title}>
                        Secondary Account
                    </Typography>
                    <Typography className={classes.memberType}>
                        You are logged into a "sub" account of{' '}
                        <b>
                            {accountData?.associatedAccountsData?.lastName}{' '}
                            {accountData?.associatedAccountsData?.firstName}
                        </b>{' '}
                        Staples Rewards Account. This account won't earn any
                        Rewards back for purchases or Ink and Toner Recycling.
                        If you believe this is an error please contact customer
                        service
                    </Typography>
                    <ButtonBase
                        className={classes.cCSWrapper}
                        id="donation-school-search-result-favorite"
                    >
                        <Typography>
                            <a
                                href={contactUsURL}
                                className={classes.cCSLinkWrapper}
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.outbound,
                                        {
                                            click_text: 'contact us',
                                            link_domain: 'staplesconnect.com',
                                            link_url: contactUsURL,
                                        }
                                    );
                                    optimoveEvents(
                                        eventMetricsMap.scm_outbound,
                                        {
                                            click_text: 'contact us',
                                            link_domain: 'staplesconnect.com',
                                            link_url: contactUsURL,
                                        }
                                    );
                                }}
                            >
                                Contact Customer Service
                            </a>
                        </Typography>
                    </ButtonBase>
                </div>
            ) : null}
        </>
    );
}

export default SecondaryAccount;
