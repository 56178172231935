/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Typography,
    makeStyles,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { optimoveEvents } from 'src/screens/optimove';
import * as yup from 'yup';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { useQuery } from 'react-query';
import { getMaskedRewardsAccountInfo } from 'src/lib/api-client/rewards-enrollment';
import InputMask from 'react-input-mask';
import ValidationTextField from 'src/components/validation-text-field';
import {
    CommonStepProps,
    ErrorType,
} from 'src/screens/rewards-enroll-flow/types';
import LoadingButton from 'src/components/loading-button';
import clsx from 'clsx';
import { phoneRegExp } from 'src/lib/validations';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '2.8rem 1.2rem 1.5rem',
    },
    enrollFormCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
    },
    formControlsRoot: {
        marginLeft: '0.8rem',
    },
    searchByLabel: {
        marginBottom: '1.2rem',
        color: `${COLORS.textBlack} !important`,
        fontSize: '1.5rem',
        fontWeight: 400,
    },
    stapleRadioButon: {
        height: '2.5rem',
        '& span': {
            fontSize: '1.1rem',
            color: COLORS.homeBlack,
        },
    },
    formDescription: {
        fontSize: '1rem',
        textAlign: 'center',
        marginTop: '0.2rem',
        padding: '0 0.6rem',
        lineHeight: '1.2rem',
        fontWeight: 500,
    },
    haveAccountText: {
        fontSize: '0.7rem',
        textAlign: 'center',
        padding: '0 0.6rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
    },
    signUpText: {
        fontSize: '0.7rem',
        fontWeight: 600,
        color: COLORS.brandCyan,
        paddingLeft: '0',
        minWidth: 'auto',
    },
    nextButton: {
        marginTop: '3rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    textFieldLabel: {
        '& label': {
            textAlign: 'center',
            top: 'unset',
            bottom: '2rem',
        },
        '& label.Mui-focused': {
            width: '150%',
            textAlign: 'left',
        },
    },
    textFieldLabelError: {
        '& label': {
            bottom: '3.4rem',
        },
    },
});

const validationSchemaEmail = yup.object({
    email: yup
        .string()
        .required('This field is required')
        .email('Enter a valid email'),
});

const validationSchemaEmailRequired = yup.object({
    email: yup.string().required('This field is required'),
});

const schemaTemplate = (type: string) =>
    yup
        .string()
        .required('This field is required')
        .matches(phoneRegExp, 'Phone Number is invalid.');
const schemaTemplateRewardsNumber = (type: string) =>
    yup
        .string()
        .required('This field is required.')
        .min(10, 'Rewards Number is invalid.');

const validationPhoneSchema = yup.object({
    phone: schemaTemplate('Phone Number'),
});

const validationRewardSchema = yup.object({
    rewardsNumber: schemaTemplateRewardsNumber('Reward Number'),
});

type RewardsEnrollProps = CommonStepProps;

type PayloadProps = {
    value: string;
    type: string;
};
const payload = { value: '', type: '' };

export function RewardsEnrollStep2({
    updateControllerValues,
    updateIsFetching,
    onError,
}: RewardsEnrollProps) {
    const [attemptedAccountCredential, setAttemptedAccountCredential] =
        useState<PayloadProps>({ value: '', type: '' });
    const [attemptCount, setAttemptCount] = useState(0);
    const [searchValue, setSearchValue] = React.useState('email');

    const { data, isFetching } = useQuery(
        ['getMaskedCredentials', attemptedAccountCredential, attemptCount],
        async () =>
            getMaskedRewardsAccountInfo(
                attemptedAccountCredential.value,
                attemptedAccountCredential.type
            ),
        {
            // only call the api if we have an account credential to attempt
            enabled: attemptedAccountCredential.value !== '',
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );
    useEffect(() => {
        if (updateIsFetching) {
            updateIsFetching(isFetching);
        }
    }, [isFetching]);

    const classes = useStyles();
    const { analyticsCustomEvent } = useEventsReporter();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function navigateToEnrollNextStep({
        accountIdentifier,
        maskedCredential,
        type,
    }: {
        accountIdentifier: string;
        maskedCredential: string;
        type: string;
    }) {
        updateControllerValues({
            enrollStep: 'step3',
            accountIdentifier,
            maskedCredential,
            type,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function navigateToRewardsSignUp() {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards_enroll, {
            click_text: 'sign up',
            element_location: 'staples rewards search',
        });
        analyticsCustomEvent(eventMetricsMap.account_creation, {
            click_text: 'sign up',
            element_location: 'staples rewards lookup',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards_enroll, {
            click_text: 'sign up',
            element_location: 'staples rewards search',
        });
        if (isFetching) {
            return;
        }
        updateControllerValues({ enrollStep: 'signUp' });
    }

    const [validationSchema, setValidationSchema] = useState<any>(
        validationSchemaEmail
    );

    const handleLookupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === 'phone') {
            setValidationSchema(validationPhoneSchema);
        } else if ((event.target as HTMLInputElement).value === 'reward') {
            setValidationSchema(validationRewardSchema);
        } else {
            setValidationSchema(validationSchemaEmailRequired);
        }
    };

    function showError(errorType: ErrorType) {
        // eslint-disable-next-line no-console
        if (onError) {
            onError('step2', errorType, '');
        }
    }
    useEffect(() => {
        if (attemptedAccountCredential.value !== '' && !isFetching) {
            if (data) {
                setAttemptedAccountCredential(payload);
                const {
                    accountExists,
                    accountIdentifier,
                    maskedCredential,
                    isPhoneExists,
                    isEmailExists,
                } = data;
                if (
                    accountExists &&
                    accountIdentifier !== null &&
                    maskedCredential !== null
                ) {
                    navigateToEnrollNextStep({
                        accountIdentifier,
                        maskedCredential,
                        type: payload.type,
                    });
                } else if (data.accountMisconfigured) {
                    if (!isPhoneExists && isEmailExists) {
                        showError('no-phone');
                    } else if (isPhoneExists && !isEmailExists) {
                        showError('no-email');
                    } else {
                        showError('no-phone-email');
                    }
                } else if (data.accountExists && data.accountClaimed) {
                    showError('account-exists');
                } else {
                    showError('match-not-found');
                }
            } else {
                showError('unknown');
            }
        }
    }, [isFetching, attemptedAccountCredential, data]);

    const formikEmail = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: (values) => {
            if (isFetching) {
                return;
            }
            setAttemptCount((oldVal: number) => oldVal + 1);
            // begin verification with endpoint if we are not already fetching
            if (searchValue === 'email') {
                payload.value = values.email;
                payload.type = 'email';
            }
            analyticsCustomEvent(eventMetricsMap.classroom_rewards_enroll, {
                click_text: 'email address',
                element_location: 'staples rewards search',
            });
            analyticsCustomEvent(eventMetricsMap.account_creation, {
                click_text: 'email address',
                element_location: 'staples rewards lookup',
            });
            optimoveEvents(eventMetricsMap.scm_classroom_rewards_enroll, {
                click_text: 'email address',
                element_location: 'staples rewards search',
            });
            setAttemptedAccountCredential(payload);
        },
    });
    const formikReward = useFormik({
        initialValues: {
            rewardsNumber: '',
        },
        validationSchema,
        onSubmit: (values) => {
            if (isFetching) {
                return;
            }
            setAttemptCount((oldVal: number) => oldVal + 1);
            // begin verification with endpoint if we are not already fetching
            if (searchValue === 'reward') {
                payload.value = values.rewardsNumber;
                payload.type = 'reward';
            }
            analyticsCustomEvent(eventMetricsMap.classroom_rewards_enroll, {
                click_text: 'staples rewards#',
                element_location: 'staples rewards search',
            });
            analyticsCustomEvent(eventMetricsMap.account_creation, {
                click_text: 'staples rewards#',
                element_location: 'staples rewards lookup',
            });
            optimoveEvents(eventMetricsMap.scm_classroom_rewards_enroll, {
                click_text: 'staples rewards#',
                element_location: 'staples rewards search',
            });
            setAttemptedAccountCredential(payload);
        },
    });
    const formikPhone = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema,
        onSubmit: (values) => {
            if (isFetching) {
                return;
            }
            const phone = (values?.phone || '').replace(/[^0-9]/gi, '');
            setAttemptCount((oldVal: number) => oldVal + 1);
            // begin verification with endpoint if we are not already fetching
            if (searchValue === 'phone') {
                payload.value = phone;
                payload.type = 'phone';
            }
            analyticsCustomEvent(eventMetricsMap.classroom_rewards_enroll, {
                click_text: 'phone number',
                element_location: 'staples rewards search',
            });
            analyticsCustomEvent(eventMetricsMap.account_creation, {
                click_text: 'phone number',
                element_location: 'staples rewards lookup',
            });
            optimoveEvents(eventMetricsMap.scm_classroom_rewards_enroll, {
                click_text: 'phone number',
                element_location: 'staples rewards search',
            });
            setAttemptedAccountCredential(payload);
        },
    });
    useEffect(() => {
        if (formikEmail.values.email.indexOf('@') === -1) {
            setValidationSchema(validationSchemaEmail);
        } else {
            setValidationSchema(validationSchemaEmailRequired);
        }
    }, [formikEmail.values.email]);

    const handleRewardChange = (e: any) => {
        const val = e.currentTarget.value;
        if (val?.length > 10) {
            return false;
        }
        return formikReward.setFieldValue('rewardsNumber', val);
    };

    const clearError = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        formikEmail.setFieldError(fieldName, '');
    };
    const emailInputHasError =
        formikEmail.touched.email && Boolean(formikEmail.errors.email);

    const rewardInputHasError =
        formikReward.touched.rewardsNumber &&
        Boolean(formikReward.errors.rewardsNumber);
    const phoneInputHasError =
        formikPhone.touched.phone && Boolean(formikPhone.errors.phone);

    return (
        <FullWidthCard
            hasManualShadow
            className={classes.enrollFormCard}
            id="classroom-rewards-number-card"
        >
            {/* <Typography className={classes.formDescription} id="user-type-title">
        Please enter your Staples Rewards number or associated email address
        below.
      </Typography> */}
            <FormControl className={classes.formControlsRoot}>
                <FormLabel
                    id="staples-lookup"
                    className={classes.searchByLabel}
                >
                    Search by
                </FormLabel>
                <RadioGroup
                    aria-labelledby="staples-lookup"
                    defaultValue={searchValue}
                    name="staples"
                    onChange={handleLookupChange}
                >
                    <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email Address"
                        className={classes.stapleRadioButon}
                    />
                    <FormControlLabel
                        value="phone"
                        control={<Radio />}
                        label="Phone Number"
                        className={classes.stapleRadioButon}
                    />
                    <FormControlLabel
                        value="reward"
                        control={<Radio />}
                        label="Staples Rewards #"
                        className={classes.stapleRadioButon}
                    />
                </RadioGroup>
            </FormControl>
            {searchValue === 'email' && (
                <form
                    onSubmit={formikEmail.handleSubmit}
                    className={classes.form}
                >
                    <ValidationTextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Enter Email Address"
                        classes={{
                            root: clsx(
                                classes.textFieldLabel,
                                emailInputHasError &&
                                    classes.textFieldLabelError
                            ),
                        }}
                        disabled={isFetching}
                        value={formikEmail.values.email}
                        onChange={formikEmail.handleChange}
                        onFocus={(e) => clearError(e)}
                        error={emailInputHasError}
                        helperText={
                            formikEmail.touched.email &&
                            formikEmail.errors.email
                        }
                    />
                    <LoadingButton
                        fullWidth
                        id="step2-next-button"
                        variant="contained"
                        classes={{
                            root: classes.nextButton,
                        }}
                        type="submit"
                        loading={isFetching}
                    >
                        Continue
                    </LoadingButton>
                </form>
            )}
            {searchValue === 'reward' && (
                <form
                    onSubmit={formikReward.handleSubmit}
                    className={classes.form}
                >
                    <ValidationTextField
                        fullWidth
                        id="rewardsNumber"
                        name="rewardsNumber"
                        label="Enter Staples Rewards #"
                        classes={{
                            root: clsx(
                                classes.textFieldLabel,
                                rewardInputHasError &&
                                    classes.textFieldLabelError
                            ),
                        }}
                        disabled={isFetching}
                        value={formikReward.values.rewardsNumber}
                        onChange={(e) => handleRewardChange(e)}
                        onFocus={(e) => clearError(e)}
                        error={rewardInputHasError}
                        helperText={
                            formikReward.touched.rewardsNumber &&
                            formikReward.errors.rewardsNumber
                        }
                    />

                    <LoadingButton
                        fullWidth
                        id="step2-next-button"
                        variant="contained"
                        classes={{
                            root: classes.nextButton,
                        }}
                        type="submit"
                        loading={isFetching}
                    >
                        Continue
                    </LoadingButton>
                </form>
            )}
            {searchValue === 'phone' && (
                <form
                    onSubmit={formikPhone.handleSubmit}
                    className={classes.form}
                >
                    <InputMask
                        mask="999-999-9999"
                        disabled={isFetching}
                        value={formikPhone.values.phone}
                        onChange={formikPhone.handleChange}
                        onFocus={(e) => clearError(e)}
                    >
                        {() => (
                            <ValidationTextField
                                fullWidth
                                id="phone"
                                name="phone"
                                label="Enter Phone Number"
                                classes={{
                                    root: clsx(
                                        classes.textFieldLabel,
                                        phoneInputHasError &&
                                            classes.textFieldLabelError
                                    ),
                                }}
                                disabled={isFetching}
                                helperText={
                                    formikPhone.touched.phone &&
                                    formikPhone.errors.phone
                                }
                                value={formikPhone.values.phone}
                                error={phoneInputHasError}
                            />
                        )}
                    </InputMask>

                    <LoadingButton
                        fullWidth
                        id="step2-next-button"
                        variant="contained"
                        classes={{
                            root: classes.nextButton,
                        }}
                        type="submit"
                        loading={isFetching}
                    >
                        Continue
                    </LoadingButton>
                </form>
            )}
            <Typography
                className={classes.haveAccountText}
                id="user-type-description"
            >
                Don't have a Staples Rewards account?{' '}
                <span
                    id="step2-sign-up-button"
                    tabIndex={0}
                    role="button"
                    className={classes.signUpText}
                    onClick={navigateToRewardsSignUp}
                    onKeyPress={navigateToRewardsSignUp}
                >
                    Sign Up
                </span>
            </Typography>
        </FullWidthCard>
    );
}

export default RewardsEnrollStep2;
