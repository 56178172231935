import React from 'react';
import { makeStyles, Button, Typography } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { CommonStepProps } from './types';
import bgIllustrationSuccessScreens from '../../assets/bgs/classroomRewardsBg.svg';


const useStyles = makeStyles({
  screen: {
    flexGrow: 1,
    justifyContent: 'center',
    minHeight: '100vh',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.primaryWhite,
    backgroundImage: `url(${bgIllustrationSuccessScreens})`,
  },
  mainContent: {
    display: 'flex',
    flexGrow: 0.5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '0 5rem',
    // eslint-disable-next-line max-len
    background: `radial-gradient(${COLORS.primaryWhite} 35%, ${COLORS.transparentPrimaryWhite} 80%)`,
    maxWidth: '27rem',
  },
  successImage: {
    width: '10rem',
    height: '10rem',
    objectFit: 'contain',
  },
  mainText: {
    fontSize: '2.1rem',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  secondaryText: {
    fontSize: '1.3rem',
    paddingTop: '.5rem',
    textAlign: 'center',
    fontWeight: 500,
  },
  bottomContent: {
    justifySelf: 'center',
    alignSelf: 'center',
    display: 'flex',
    flexShrink: 1,
    padding: '3rem 2rem',
    flexDirection: 'column',
    // eslint-disable-next-line max-len
    background: `radial-gradient(${COLORS.primaryWhite} 30%, ${COLORS.transparentPrimaryWhite} 80%)`,
    maxWidth: '20rem',
  },
  additionalInfoText: {
    fontSize: '0.9rem',
    textAlign: 'center',
    padding: '0rem 0.1rem 3rem',
    '& span': {
      fontWeight: 500,
    },
  },
  nextButton: {
    textTransform: 'none',
    minHeight: '2.5rem',
    borderRadius: '1.885rem',
    background: COLORS.homeBlack,
    color: COLORS.whiteSmoke,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: COLORS.homeBlack,
    },
    '&:active': {
      backgroundColor: COLORS.homeBlack,
    },
  },
});
export function RewardsEnrollSuccess({
  userType,
  finishFlow,
}: CommonStepProps) {
  const classes = useStyles();
  let mainText = 'Success!';
  switch (userType) {
    case 'Administrator':
      mainText = 'Nice work!';
      break;
    case 'Educator':
      mainText = 'Great job!';
      break;
    case 'Supporter':
    default:
      mainText = 'Success!';
      break;
  }
  return (
    <div className={classes.screen} id="rewards-enroll-success-screen">
      <div className={classes.mainContent}>
        <img
          id="rewards-enroll-success-image"
          className={classes.successImage}
          src={
            userType === 'Administrator'
              ? require('../../assets/icons/successEmoji2.png')
              : require('../../assets/icons/successEmoji.png')
          }
          alt="success emoji"
        />
        <Typography
          id="rewards-enroll-success-main-text"
          className={classes.mainText}
        >
          {mainText}
        </Typography>
        <Typography
          id="rewards-enroll-success-secondary-text"
          className={classes.secondaryText}
        >
          {userType === 'Supporter'
            ? 'Now you can start earning and giving Staples Classroom Rewards.'
            : 'Now you can start earning and receiving Staples Classroom Rewards.'}
        </Typography>
      </div>
      <div className={classes.bottomContent}>
        <Button
          onClick={finishFlow}
          fullWidth
          id="classroom-enrollment-success-finish-button"
          variant="contained"
          classes={{
            root: classes.nextButton,
          }}
        >
          Go To Classroom Rewards
        </Button>
      </div>
    </div>
  );
}

export default RewardsEnrollSuccess;
