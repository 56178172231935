/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React from 'react';

function Coin({height='20', width='20'}:{height?:string, width?:string}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="10" cy="10" r="9" fill="#FFDDA7" stroke="#FFAD5E" strokeWidth="2" />
        <path 
            d="M13.4328 8H7.06604C6.77851 8.00107 6.50356 8.1043 6.30309 8.28645C6.10263 8.46859 5.9935 8.71434 6.0003 8.96834V11.9777C5.99997 11.9833 6.0022 11.9888 6.00649 11.993C6.01079 11.9972 6.01679 11.9997 6.02318 12H6.84326C6.85589 12 6.86614 11.9909 6.86614 11.9798V8.92259H13.6279V11.9777C13.6285 11.989 13.6391 11.9979 13.652 11.9979H14.4756C14.4885 11.9979 14.4991 11.989 14.4997 11.9777V8.96834C14.5062 8.71424 14.3968 8.4685 14.1961 8.28639C13.9955 8.10428 13.7204 8.00108 13.4328 8"
            fill="#FFAD5E"
            stroke="#FFAD5E"
        />
        </svg>
    );
}

export default Coin;
