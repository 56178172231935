/* eslint-disable import/prefer-default-export */
/* eslint import/namespace: [2, { allowComputed: false }] */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { LOCAL_STORAGE_KEYS, getItemFromLocalStorage } from 'src/hooks';
import { SessionManager } from './sessionManager';
import { getSubscriptionKey } from './sign-on';
import axiosInstance from './custom-axios';
import { akamaiTelemetryHeader } from '../utils/app-utils';
import { atRecommendationRecentlyViewItems } from './adobe';
import { User } from './types';

const cartInstance: AxiosInstance = axios.create({
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const getHeaderData = async () => {
  const lastUser = getItemFromLocalStorage(
    LOCAL_STORAGE_KEYS.USER_INFO,
    {} as User
  );
  const headerData = await atRecommendationRecentlyViewItems('RECENTLY_VIEWED_DOTCOM', lastUser.preferredStoreId, lastUser.zipCode, true)
  return headerData;
}

export const getCartDetails = async (): Promise<any | null> => {
  const subscriptionKey = await getSubscriptionKey('StaplesCart');
  const headerData: any = await getHeaderData();
  cartInstance.interceptors.request.use((config: any) => ({
    ...config,
    headers: {
      ...config.headers,
      client_id: subscriptionKey || '',
      Authorization: `Bearer ${SessionManager.access_token}`,
      "x-ctx-token": headerData?.["x-ctx-token"],
      "x-device-id": headerData?.["x-device-id"],
      ...akamaiTelemetryHeader,
    },
  }));
  try {
    const results: any = await cartInstance.get(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout`
    );
    Cookies.set('ctx-id', results?.data?.cartInfo?.ctxId);
    return results;
  } catch (e) {
    return null;
  }
};

export const mergeCartDetails = async (): Promise<any | null> => {
  const subscriptionKey = await getSubscriptionKey('StaplesCart');
  cartInstance.interceptors.request.use((config: any) => ({
    ...config,
    headers: {
      ...config.headers,
      client_id: subscriptionKey || '',
      Authorization: `Bearer ${SessionManager.access_token}`,
      ...akamaiTelemetryHeader,
    },
  }));
  try {
    const results: any = await cartInstance.put(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout/default`,
      {
        "mergeCart": true,
        "guestCtxId": Cookies.get('ctx-id'),
      }
    );

    return results;
  } catch (e) {
    return null;
  }
};

export async function addToCartProduct(data: any): Promise<any | null> {
  const subscriptionKey = await getSubscriptionKey('StaplesCart');
  const headerData: any = await getHeaderData();
  cartInstance.interceptors.request.use((config: any) => ({
    ...config,
    headers: {
      ...config.headers,
      client_id: subscriptionKey || '',
      "x-ctx-token": headerData?.["x-ctx-token"],
      "x-device-id": headerData?.["x-device-id"],
      Authorization: `Bearer ${SessionManager.access_token}`,
    },
  }));
  try {
    const results = await cartInstance.post(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout/default/item`,
      { ...data }
    );
    Cookies.set('ctx-id', results?.data?.cartInfo?.ctxId);
    const cartCountDetails = await getCartDetails();
    return { status: true, data: results, cartCountDetails };
  } catch (e) {
    return { status: false, data: [] };
  }
}


export async function navigateToCheckoutCheck(): Promise<any | null> {
  const subscriptionKey = await getSubscriptionKey('StaplesCart');
  const headerData: any = await getHeaderData();
  cartInstance.interceptors.request.use((config: any) => ({
    ...config,
    headers: {
      ...config.headers,
      client_id: subscriptionKey || '',
      "x-ctx-token": headerData?.["x-ctx-token"],
      "x-device-id": headerData?.["x-device-id"],
      Authorization: `Bearer ${SessionManager.access_token}`,
    },
  }));
  try {
    const results = await cartInstance.put(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout/default?buyNow=true&mmx=true&reset=true&ux2=true&memItem=true`,
      { proceedCheckout: "true" }
    );
    Cookies.set('ctx-id', results?.data?.cartInfo?.ctxId);
    return { status: true, data: results };
  } catch (e) {
    return { status: false, data: [] };
  }
}


export async function getDCNNumber(accessToken: string): Promise<any | null> {
  try {
    const results: any = await axiosInstance.get(
      `${process.env.REACT_APP_DCN_API_URL}/${accessToken}`
    );
    return results?.data?.directCustomerNumber ?? null;
  } catch (e) {
    return null;
  }
}