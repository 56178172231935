import {
  RewardsAccountUserType,
  RewardsAccountConfirmState,
  User,
} from 'src/services/types';

/* eslint-disable import/prefer-default-export */
export const makeEnrollQuery = (
  userType?: RewardsAccountUserType,
  userConfirmState?: RewardsAccountConfirmState
) => {
  if (!userType || !userConfirmState) {
    return { enrollStep: 'step1' };
  }
  return { enrollStep: 'selectSchool', userType };
};

export const fullName = (userInfo?: User) => {
  // Check if both firstName and lastName are empty or not provided
  if (!userInfo?.firstName && !userInfo?.lastName) {
    return "Valued Member"; // Return "Valued Member" if both are absent
  }

  // Otherwise, construct the full name from the provided firstName and lastName
  return `${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`
    .toLowerCase() // Convert to lowercase
    .replace(/(?<!\p{L})(\p{L})/gu, m => m.toUpperCase());
    // Capitalize the first letter of each name part, regardless of preceding characters
};



export const isEducatorOrAdmin = (userType?: RewardsAccountUserType) =>
  userType === 'Administrator' || userType === 'Educator';

export const validateZipCode = (e: any) => {
  // eslint-disable-next-line
  let value = e.target.value;
  const zipRegex = /^(\d{0,5})(-)?(\d{0,4})?$/;

  // Check if the value is valid according to the regex
  if (!zipRegex.test(value)) {
    return false;
  }
  return true;
}
