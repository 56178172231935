import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import FullWidthCard, {
    FullWidthCardProps,
} from 'src/components/cards/full-width-card';
import { Store } from 'src/services/types';
import {
    googleMapURL,
    describeStoreHours,
    storeDetailsUrl,
} from 'src/lib/utils/store-utils';
import MapPreview, { MapOverlay } from 'src/components/map-preview';
import useStoreData from 'src/hooks/useStoreData';
import AppLink from 'src/components/link';
import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import StoreDetailsDrawer from '../store-details-drawer';
import { Body } from '../UI';

type MainStoreCardProps = FullWidthCardProps & {
    storeData?: Store;
    isLoadingStore?: boolean;
    onClick?: () => void;
    isExpanded?: boolean;
    storeServices: any;
};

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '9.75rem',
        margin: '0',
        boxShadow: 'none',
    },
    noLocationContainer: {
        marginTop: '3rem',
        marginBottom: '2rem',
        textAlign: 'center',
        display: 'block',
    },
    noLocationIconContainer: {
        width: '6.938rem',
    },
    noLocationTitle: {
        fontWeight: 500,
        textAlign: 'center',
        paddingLeft: '2rem',
        paddingRight: '2rem',
    },
    ctas: {
        display: 'flex',
        padding: '.889rem 1.9rem',
        justifyContent: 'space-between',
        [theme.breakpoints.down(500)]: {
            padding: '.889rem 0.6rem',
        },
    },
    ctaText: {
        color: '#086dd2',
        // fontWeight: 'bold',
        fontFamily: 'StaplesNormsBold',
        fontSize: '1rem',
        [theme.breakpoints.down(520)]: {
            fontSize: '0.8rem',
        },
    },
    nearByStore: {
        marginTop: '13px',
        [theme.breakpoints.down(500)]: {
            position: 'relative',
            top: '10px',
        },
    },
    lowerInfo: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(500)]: {
            justifyContent: 'space-around',
        },
    },
    leftContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    rightContent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.5rem',
        padding: '0rem 1.9rem 0rem 1.9rem',
        [theme.breakpoints.down(500)]: {
            marginBottom: '1.5rem',
            padding: '0rem 0.6rem',
        },
    },
    storeInfo: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        fontSize: '1rem',
        padding: '0rem 1rem 0rem 1.9rem',
        marginTop: '1.5rem',
        [theme.breakpoints.down(520)]: {
            padding: '0rem 0.6rem',
        },
    },
    mediumWeight: {
        // fontWeight: 600,
        fontSize: '1rem',
        [theme.breakpoints.down(520)]: {
            fontSize: '0.8rem',
        },
    },
    infoText: {
        fontSize: '1rem',
        [theme.breakpoints.down(520)]: {
            fontSize: '0.8rem',
        },
    },
    ctaIcons: {
        marginTop: '1.25rem',
        '& a:nth-child(1)': {
            marginRight: '1.3376rem',
        },
    },
    icon: {
        width: '1.375rem',
        height: '1.375rem',
    },
    iconContainer: {
        display: 'flex',
        gap: '10px',
        padding: '0rem 1rem 1rem 1.9rem',
    },
}));

const hasStoreData = (storeData: Store | undefined) =>
    !!storeData && !!storeData.address;

/** Displays community information on the home screen */
export default function MainStoreCard({
    storeData,
    isLoadingStore,
    onClick,
    isExpanded,
    storeServices,
}: MainStoreCardProps) {
    const classes = useStyles();
    const [storeOpenMessage, setStoreOpenMessage] = useState('');
    const [storeOpenUntilMessage, setStoreOpenUntilMessage] = useState('');
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const [storeDetailsModalOpen, setStoreDetailsModalOpen] = useState(false);
    const { mainStore, additionalStores, updateMainStore } = useStoreData();
    const onStoreSelected = (store: Store) => {
        updateMainStore(store);
        // setSearchModalOpen(false);
    }
    useEffect(() => {
        if (hasStoreData(storeData)) {
            if (storeData?.storeHours) {
                const { open, closingTime, nextDayOpenTime } = describeStoreHours(
                    storeData?.storeHours, storeData?.timezone
                );
                if (open) {
                    setStoreOpenMessage('Open Now');
                    setStoreOpenUntilMessage(`Until ${closingTime}`);
                } else {
                    setStoreOpenMessage('Closed Now');
                    if (nextDayOpenTime !== 'Closed') {
                        setStoreOpenUntilMessage(`Open At ${nextDayOpenTime}`);
                    }
                }
            } else {
                setStoreOpenMessage('Temporarily Closed');
            }
        }
    }, [storeData]);
    if (!storeData && !isLoadingStore) {
        return (
            <FullWidthCard
                cardContainerClassName={classes.container}
                noPadding
                hasManualShadow
                id="community-card"
            >
                <div className={classes.noLocationContainer}>
                    <span className={classes.noLocationIconContainer}>
                        <img
                            src={require('../../assets/icons/iconNolocationLarge.svg')}
                            alt="no location icon"
                        />
                    </span>

                    <Typography
                        className={classes.noLocationTitle}
                        id="community-card-no-location-title"
                    >
                        We're sorry but we could not find your location. Please
                        update your location to be connected to your local
                        community.
                    </Typography>
                </div>
            </FullWidthCard>
        );
    }

    return (
        <FullWidthCard
            cardContainerClassName={classes.container}
            loading={!hasStoreData(storeData) || isLoadingStore}
            noPadding
            hasManualShadow
            id="community-card"
        >
            <MapOverlay>
                <MapPreview
                    withBorderRadius
                    mapHeight={160}
                    center={{
                        longitude: storeData?.longitude || 0,
                        latitude: storeData?.latitude || 0,
                    }}
                />
            </MapOverlay>
            <Box className={classes.lowerInfo}>
                <Box className={classes.leftContent}>
                    <Box className={classes.storeInfo}>
                        <Body className={classes.mediumWeight} weight='bold' id='MainStoreCardAddressLine1'>
                            {storeData?.address?.address_1}
                        </Body>
                        <Body className={classes.mediumWeight} weight='bold' id='MainStoreCardAddressLine2'>
                            {storeData?.address?.city},{' '}
                            {storeData?.address?.region}{' '}
                            {storeData?.address?.postal_code}
                        </Body>
                        <Typography
                            className={classes.infoText}
                            id="main-store-card-phone-number-text"
                        >
                            Tel: {formatPhoneNumber(storeData?.phoneNumber)}
                        </Typography>
                        <Typography
                            className={classes.infoText}
                            id="main-store-card-fax-number-text"
                        >
                            Fax: {formatPhoneNumber(storeData?.faxNumber)}
                        </Typography>
                    </Box>
                    <Box className={classes.ctas}>
                        <AppLink
                            id="main-store-card-store-details-link"
                            className={classes.ctaText}
                            href='#'
                            // href={storeDetailsUrl(storeData, customEvent)}
                            target="_blank"
                            onClick={(e) => {
                                e.preventDefault();
                                setStoreDetailsModalOpen(true)
                                analyticsCustomEvent(eventMetricsMap.stores, {
                                    click_text: 'store details',
                                    element_location: 'your store information',
                                    store_details: `${storeData?.address?.city}-${storeData?.storeNumber}`,
                                });
                                optimoveEvents(eventMetricsMap.scm_stores, {
                                    click_text: 'store details',
                                    element_location: 'your store information',
                                    store_details: `${storeData?.address?.city}-${storeData?.storeNumber}`,
                                });
                            }}
                        >
                            Store details
                        </AppLink>
                    </Box>
                    {isExpanded && (
                        <div className={classes.iconContainer}>
                            {storeServices(storeData)}
                        </div>
                    )}
                </Box>
                <Box className={classes.rightContent}>
                    <Body className={classes.mediumWeight} weight='bold' id="MainStoreCardOpenCloseStatus">
                        {storeOpenMessage}
                    </Body>

                    <Typography className={classes.infoText} id="MainStoreCardOpenCloseTime">
                        {storeOpenUntilMessage}
                    </Typography>
                    <Box className={classes.ctaIcons}>
                        <AppLink
                            aria-label='Call'
                            role='button'
                            href={`tel:${storeData?.phoneNumber}`}
                            onClick={() => {
                                analyticsCustomEvent(eventMetricsMap.stores, {
                                    click_text: 'call',
                                    element_location: 'your store information',
                                    store_details: `${storeData?.address?.city}-${storeData?.storeNumber}`,
                                });
                                optimoveEvents(eventMetricsMap.scm_stores, {
                                    click_text: 'call',
                                    element_location: 'your store information',
                                    store_details: `${storeData?.address?.city}-${storeData?.storeNumber}`,
                                });
                            }}
                        >
                            <img
                                src={require('../../assets/icons/iconPhone.svg')}
                                alt="phone icon"
                                className={classes.icon}
                            />
                        </AppLink>
                        <AppLink
                            href={googleMapURL(storeData)}
                            role='button'
                            aria-label='Map Bring up directions to your Staples store'
                            target="_blank"
                            onClick={() => {
                                analyticsCustomEvent(eventMetricsMap.stores, {
                                    click_text: 'get directions',
                                    element_location: 'your store information',
                                    store_details: `${storeData?.address?.city}-${storeData?.storeNumber}`,
                                });
                                optimoveEvents(eventMetricsMap.scm_stores, {
                                    click_text: 'get directions',
                                    element_location: 'your store information',
                                    store_details: `${storeData?.address?.city}-${storeData?.storeNumber}`,
                                });
                            }}
                        >
                            <img
                                aria-hidden="true"
                                src={require('../../assets/icons/iconDirection.svg')}
                                alt="maps icon"
                                className={classes.icon}
                            />
                        </AppLink>
                        {!isExpanded ? (
                            <Typography
                                className={`${classes.ctaText} ${classes.nearByStore}`} role='link' tabIndex={0}
                                onClick={onClick}
                            >
                                Nearby stores
                            </Typography>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            {storeDetailsModalOpen ? <StoreDetailsDrawer
                mainStore={mainStore}
                nearbyStores={additionalStores}
                onStoreSelected={onStoreSelected}
                open={storeDetailsModalOpen}
                ignoreIsActiveScreen
                handleClose={() => setStoreDetailsModalOpen(false)}
            /> : null}
        </FullWidthCard>
    );
}
