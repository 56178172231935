import React, { useState } from 'react';
import {
    makeStyles,
    ButtonBase,
    Typography,
    Box,
    CircularProgress,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import { ListSchoolResult } from 'src/services/types';
import ChangeLocationDialog from 'src/components/change-location-dialog';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';
import useUserInfo from 'src/hooks/useUserInfo';
import { getSchoolsByZipCode } from 'src/lib/api-client/lists';
import { useCreateImportListContext } from 'src/lib/contexts/create-import-list-context';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
    },
    schoolsListCard: {
        marginTop: '1rem',
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    resultsCountWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '1rem',
        borderBottom: `0.1rem ${COLORS.gray80} solid`,
        marginBottom: '1rem',
    },
    resultsCountText: {
        fontWeight: 500,
    },
    searchedZipCodeText: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: '0.3rem 0',
    },
    changeLocationText: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
    },
});

const useSchoolRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        padding: '0 0 1rem 1rem',
        minHeight: '3.5rem',
        marginRight: '-1rem',
        '&:last-child > *': {
            borderBottom: 'none',
        },
        flexDirection: 'column',
    },
    schoolItem: {
        width: '100%',
        display: 'flex',
        padding: '0 1rem 1rem 0',
        minHeight: '3.5rem',
        flexShrink: 0,
        margin: '0 .5rem 0 0',
        borderBottom: `0.1rem ${COLORS.gray80} solid`,
        flexDirection: 'column',
    },
    textWrapper: {
        flexGrow: 1,
    },
    name: {
        fontSize: '1.1rem',
        display: 'flex',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    address: {
        fontSize: '0.9rem',
        display: 'flex',
    },
    ctaWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '0.6rem',
    },
    viewListsText: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        fontSize: '0.9rem',
    },
    listAvailableText: {
        color: COLORS.successGreen,
        fontSize: '0.9rem',
    },
    noListText: {
        fontSize: '0.9rem',
        color: COLORS.vividRed,
    },
});

type SchoolRowProps = {
    index: number;
    school: ListSchoolResult;
    viewLists: (selectedSchool: ListSchoolResult) => void;
};

/** School selection row when enrolling for a school */
function SchoolRow({ school, viewLists, index }: SchoolRowProps) {
    const classes = useSchoolRowStyles();
    function handlePress() {
        viewLists(school);
    }
    return (
        <div
            id={`school-selection-search-result-${index}`}
            className={classes.row}
        >
            <div className={classes.schoolItem}>
                <div className={classes.textWrapper}>
                    <Typography
                        id={`school-selection-search-result-${index}-name-text`}
                        className={classes.name}
                        component="div"
                    >
                        {school.name}
                    </Typography>
                    <Typography
                        id={`school-selection-search-result-${index}-address-street-text`}
                        className={classes.address}
                        component="div"
                    >
                        {school?.address}
                    </Typography>
                    <Typography
                        id={`school-selection-search-result-${index}-address-city-region-text`}
                        className={classes.address}
                        component="div"
                    >
                        {school.city}, {school.state}, {school.zip}
                    </Typography>
                </div>
                <div className={classes.ctaWrapper}>
                    {school.listCount > 0 ? (
                        <>
                            <Typography className={classes.listAvailableText}>
                                Lists Available
                            </Typography>
                            <ButtonBase
                                id={`school-selection-search-result-${index}-main-button`}
                                onClick={handlePress}
                            >
                                <Typography className={classes.viewListsText}>
                                    View Lists
                                </Typography>
                            </ButtonBase>
                        </>
                    ) : (
                        <Typography className={classes.noListText}>
                            No Lists Available
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
}

export default function ImportListSelectSchool() {
    const classes = useStyles();
    const { userInfo: localUserInfo } = useLocalUserInfo();
    const { userInfo } = useUserInfo();
    const inferredZipCode =
        userInfo?.currentLocationZipCode || localUserInfo?.zipCode || null; // '48093';
    const [changedLocationZipCode, setChangedLocationZipCode] = useState('');
    const onChangeLocationComplete = (newZipCode: string) => {
        setChangedLocationZipCode(newZipCode);
    };
    const finalZipCode =
        changedLocationZipCode === ''
            ? inferredZipCode
            : changedLocationZipCode;
    const [changeLocationDialogOpen, setChangeLocationDialogOpen] =
        useState(false);

    const { data: schoolsByZipCodeData, isFetching: isFetchingSchools } =
        useQuery(
            ['schoolsAsPerZipCode', finalZipCode],
            () =>
                getSchoolsByZipCode({
                    zipCode: finalZipCode,
                }),
            {
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
            }
        );

    const { setSelectedSchoolDetails, goToPage } = useCreateImportListContext();

    const handleViewLists = (school: ListSchoolResult) => {
        goToPage({ type: 'view-lists' });
        setSelectedSchoolDetails(school);
    };

    const handleChangeLocation = () => {
        setChangeLocationDialogOpen(true);
    };
    const handleChangeLocationClose = () => {
        setChangeLocationDialogOpen(false);
    };

    return (
        <>
            <Box className={classes.screen}>
                <FullWidthCard
                    hasManualShadow
                    noPadding
                    className={classes.schoolsListCard}
                    id="classroom-rewards-number-card"
                >
                    <div className={classes.list}>
                        {isFetchingSchools && (
                            <div className={classes.loading}>
                                <CircularProgress
                                    size="1rem"
                                    style={{ color: COLORS.textBlack }}
                                />
                            </div>
                        )}
                        {schoolsByZipCodeData && (
                            <>
                                <div
                                    id="school-selection-search-result-count"
                                    className={classes.resultsCountWrapper}
                                >
                                    <Typography
                                        id="school-selection-search-result-name-text"
                                        className={classes.resultsCountText}
                                        component="div"
                                    >
                                        Showing {schoolsByZipCodeData.length}{' '}
                                        results for zip code:
                                    </Typography>
                                    <Typography
                                        id="school-selection-search-result-address-street-text"
                                        className={classes.searchedZipCodeText}
                                        component="div"
                                    >
                                        {finalZipCode}
                                    </Typography>
                                    <ButtonBase
                                        id="school-selection-search-result-select-button"
                                        onClick={() => handleChangeLocation()}
                                    >
                                        <Typography
                                            id="school-selection-search-result-address-city-region-text"
                                            className={
                                                classes.changeLocationText
                                            }
                                            component="div"
                                        >
                                            Change Location
                                        </Typography>
                                    </ButtonBase>
                                </div>
                                {schoolsByZipCodeData.map(
                                    (s: any, index: any) => (
                                        <SchoolRow
                                            index={index}
                                            key={s.id}
                                            school={s}
                                            viewLists={handleViewLists}
                                        />
                                    )
                                )}
                            </>
                        )}
                    </div>
                </FullWidthCard>
            </Box>
            <ChangeLocationDialog
                open={changeLocationDialogOpen}
                handleClose={handleChangeLocationClose}
                onComplete={onChangeLocationComplete}
            />
        </>
    );
}
