/* eslint-disable no-console */
import { useState } from 'react';
import axios from 'axios';
import { patchUserInfo } from 'src/lib/api-client/user-info';
import { buildPatchOperations } from 'src/lib/utils/patch-utils';
import { getEstimatedZipCode } from 'src/lib/api-client/google/geolocation';
import { Store } from 'src/services/types';
import { getStores } from 'src/lib/api-client/staples-stores';
import useUserInfo from './useUserInfo';
import useLocalUserInfo from './useLocalUserInfo';

async function storeCoordinates(latitude: any, longitude: any, limit = 1) {
    if (process.env.REACT_APP_STORE_LOCATION) {
        let url = process.env.REACT_APP_STORE_LOCATION as string;
        url += `?latitude=${latitude}&longitude=${longitude}&radius=250&limit=${limit}`;
        try {
            const stores = await axios.get(url);
            return { status: true, data: stores.data };
        } catch (error) {
            console.error('Failed to get Co-Ordinates', error);
            return { status: false, data: null };
        }
    }
    return { status: false, data: null };
}

function useGeolocation() {
    const [isDetectingLocation, setIsDetectingLocation] = useState(false);
    const { setUserInfo } = useUserInfo();
    const { patchLocalUserInfo } = useLocalUserInfo();

    const saveZipcode = async (newZipCode: string) => {
        const stores = await getStores(newZipCode);
        if (stores.length) {
            setUserInfo({ currentLocationZipCode: newZipCode });
            patchLocalUserInfo({
                zipCode: newZipCode,
            });
        }
    };

    interface DetectAndSaveLocationParams {
        handleSuccess?: () => void;
        handleError?: () => void;
        showStoreResult?: (stores: Store[]) => void;
    }
    const detectAndSaveZipcode = async ({
        handleSuccess,
        handleError,
        showStoreResult,
    }: DetectAndSaveLocationParams) => {
        setIsDetectingLocation(true);
        const exactLocation = await getEstimatedZipCode();
        if (exactLocation) {
            const { latitude, longitude, postalCode } = exactLocation;
            const limit = showStoreResult ? 20 : 1;
            const stores: any = await storeCoordinates(latitude, longitude, limit);
            if (stores.status) {
                const storeNumber = stores.data?.[0]?.storeNumber;
                if (postalCode) {
                    try {
                        if (showStoreResult) {
                            showStoreResult(stores.data)
                        } else {
                            saveZipcode(postalCode);
                            if (storeNumber) {
                                await patchUserInfo(
                                    buildPatchOperations([
                                        {
                                            field: 'preferredStoreId',
                                            value: storeNumber,
                                        },
                                    ])
                                );
                            }
                            if (handleSuccess) handleSuccess();
                        }
                    } catch (error) {
                        setIsDetectingLocation(false);
                        console.error(error);
                        if (handleError) handleError();
                    }
                }
            }
        }
        setIsDetectingLocation(false);
    };

    return {
        saveZipcode,
        isDetectingLocation,
        detectAndSaveZipcode,
    };
}

export default useGeolocation;
