/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import { IconRightBackArrow } from 'src/components/Icons';
import ScrollContainer from 'src/components/screen-scroll';
import { eventMetricsMap, useEventsReporter, useRouter, useUserInfo } from 'src/hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { fmtDollarsAndCents } from 'src/lib/utils/string-utils';
import ROUTES from 'src/lib/routes';
import RewardsPromoCardDialog from 'src/components/rewards-promo-card-dialog';
import { getAvailablePayoutAmount } from 'src/lib/utils/account-utils';
import { fullName } from 'src/lib/utils/user-utils';
import { format } from 'date-fns';
import NavTop from 'src/components/nav-top';
import { Body } from 'src/components/UI';
import { useQuery } from 'react-query';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import { CACHE_ONLY } from 'src/lib/utils/react-query-utils';
import WalletTabs from '../rewards-wallet/wallet-tabs';
import styles from './styles.module.scss';


function OtherBalance() {
    const { userInfo } = useUserInfo();
    const { push, location }: any = useRouter();

    const [otherLocationName, setOtherLocationName] = useState('');
    const [activeTabName, setActiveTabName] = useState(location?.state?.otherBalance === 'notify' ? 2 : 1);
    const [promoCardOpen, setPromoCardOpen] = useState(false)
    const availablePayoutAmount = getAvailablePayoutAmount(userInfo?.rewardsDetails);
    const { promoCard } = userInfo?.rewardsDetails || {};
    const promoCardExpiration = promoCard?.expiration;
    const { analyticsCustomEvent } = useEventsReporter();

    const expirationDate = promoCardExpiration
        ? new Date(promoCardExpiration)
        : new Date();

    const { data: staplesRewardsDetail } = useQuery(
        ['details', userInfo?.rewardsNumber],
        () => getStaplesRewardsData(userInfo?.rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );

    const getCouponsRewardsCategory = useMemo(() => {
        const couponsRewardsData =
            staplesRewardsDetail?.data?.couponDataList?.findIndex(
                (couponsData: any) => couponsData.category === 'Reward'
            ) !== -1;
        return couponsRewardsData;
    }, [staplesRewardsDetail]);

    const getOtherBalanceName = () => {
        let res = '';
        const isClassroomRewardsApplicable = userInfo?.walletId && userInfo?.rewardsDetails?.payoutAvailableAmount > 0;
        const isStaplesRewardsApplicable = getCouponsRewardsCategory && userInfo?.rewardsNumber;
    
        if (isClassroomRewardsApplicable) {
            res = 'Classroom Rewards';
        }
        if (isStaplesRewardsApplicable) {
            if (isClassroomRewardsApplicable) {
                // Add ' & ' only if Classroom Rewards is also present
                res += ' & ';
            }
            res += 'Staples Rewards';
        }

        setOtherLocationName(res);
        setActiveTabName(res === 'Staples Rewards' || location?.state?.otherBalance === 'notify' ? 2 : 1)
    };

    React.useEffect(() => {
        getOtherBalanceName();
        
    }, [getCouponsRewardsCategory, staplesRewardsDetail]);



    const handleToggle = (index: number) => {
        // const tabName = event.target.id;
        setActiveTabName(index);
        analyticsCustomEvent(eventMetricsMap.screen_view, {
            firebase_screen: `app_account`,
            firebase_screen_class: `app_account`,
        });
    };

    const { dollars: availableDollars, cents: availableCents } =
        fmtDollarsAndCents(userInfo?.rewardsDetails?.payoutAvailableAmount || 0);


    const closePromoCard = () => {
        setPromoCardOpen(!promoCardOpen);
    }


    return (
        <>
            <div className={styles.headerWrapper}>
                <NavTop
                    bgImageSrc={require('../../assets/bgs/bgRedUserProfile.svg')}
                    marginBottom="-16.5rem"
                    minHeight={otherLocationName.includes('&') ? "180px" : "125px"}
                />
                <div className={styles.iconText}>
                    <span onClick={() => push('/user-profile')}>
                        <IconRightBackArrow fillColor='white' /></span>
                    {otherLocationName.includes('&') ? (
                        <Typography className={styles.otherText}>Other balances</Typography>
                    ) : ''}
                </div>
                {/* switch button start */}
                {otherLocationName.includes('&') ? (
                    <>
                        <div className={styles.wrapper}>
                            <div
                                className={`${styles.leftBtnWrapper} ${activeTabName === 1 && styles.leftActive
                                    }`}
                            >
                                <Typography
                                    id="points"
                                    className={`${styles.bodyText}`}
                                    onClick={() => handleToggle(1)}
                                >
                                    Classroom Rewards
                                </Typography>
                            </div>
                            <div
                                className={`${styles.rightBtnWrapper} ${activeTabName === 2 && styles.rightActive
                                    }`}
                            >
                                <Typography
                                    id="coupons"
                                    className={`${styles.bodyText}`}
                                    onClick={() => handleToggle(2)}
                                >
                                    Staples Rewards
                                </Typography>
                            </div>
                        </div>
                    </>
                ) : (
                    <Body size={16} className={styles.otherBalanceName}>{otherLocationName}</Body>
                )}


                {/* switch button end */}

            </div>
            {activeTabName === 1 ? (
                <Box className={styles.classRoomReward}>
                    <Typography
                        className={styles.rewardsSummaryAmount}
                        id="rewards-summary-type-lifetime-amount-text"
                    >
                        <span className={styles.doller}>$</span>
                        <span className={styles.amount}>
                            {availableDollars}
                        </span>
                        <span className={styles.cent}> {availableCents}</span>
                    </Typography>
                    <Typography className={styles.classRoomText}>Classroom Rewards Available</Typography>
                    <Typography className={styles.expireText}> Expires on{' '}
                        {format(expirationDate, 'MM/dd/yy')}</Typography>
                    <Button
                        onClick={() => setPromoCardOpen(true)}
                        className={styles.giveNowButton}
                    >
                        Pay with Classroom Rewards
                    </Button>
                </Box>
            ) : ''}
            {activeTabName === 2 ? (
                <WalletTabs />
            ) : ''}
            <RewardsPromoCardDialog
                open={promoCardOpen}
                onClose={closePromoCard}
                variant={
                    userInfo?.userType === 'Administrator' ? 'school' : 'teacher'
                }
                cardOwner={fullName(userInfo)}
                amountAvailable={availablePayoutAmount}
                expiration={userInfo?.rewardsDetails?.promoCard?.expiration}
                cardNumber={userInfo?.rewardsDetails?.promoCard?.number}
                pin={userInfo?.rewardsDetails?.promoCard?.pin}
            />
        </>
    );
}

const WrappedOtherBalance = () => (
    <ScrollContainer>
        <OtherBalance />
    </ScrollContainer>
);
export default WrappedOtherBalance;
