import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { GUTTER_WIDTH } from 'src/theme';
import AppCard, { AppCardProps } from './app-card';

export type FullWidthCardProps = AppCardProps & {
  cardContainerClassName?: string;
};

const useStyles = makeStyles({
  card: {
    marginLeft: GUTTER_WIDTH,
    marginRight: GUTTER_WIDTH,
  },
});

/** baseline card used across the app */
export default function FullWidthCard(props: FullWidthCardProps) {
  const classes = useStyles();
  const { cardContainerClassName, ...rest } = props;
  return (
    <AppCard className={clsx(classes.card, cardContainerClassName)} {...rest} />
  );
}
