/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconPlusThin({
    width = 24,
    height = 24,
    fillColor = 'fillPurple',
}: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Vector"
                d="M23.0891 11.1287H12.9109V0.910891C12.9109 0.435644 12.5149 0 12 0C11.5248 0 11.0891 0.39604 11.0891 0.910891V11.1287H0.910891C0.435644 11.1287 0 11.5248 0 12.0396C0 12.5149 0.39604 12.9505 0.910891 12.9505H11.1287V23.0891C11.1287 23.5644 11.5248 24 12.0396 24C12.5149 24 12.9505 23.604 12.9505 23.0891V12.9109H23.0891C23.5644 12.9109 24 12.5149 24 12C24 11.5248 23.5644 11.1287 23.0891 11.1287Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconPlusThin;
