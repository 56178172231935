import React, { useState } from 'react';
import {
    Typography,
    makeStyles,
    ButtonBase,
    Button,
    CircularProgress,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import {
    CommonStepProps,
    DonateControllerState,
} from 'src/screens/donation-flow/types';
import useRouter from 'src/hooks/useRouter';
import { useQuery } from 'react-query';
import {
    getEducatorsBySchoolId,
    getSchoolById,
} from 'src/lib/api-client/locations';
import { EducatorResult, SchoolResult } from 'src/services/types';
import ShareDialog from 'src/components/share-dialog';
import clsx from 'clsx';
import FavoriteButton from 'src/components/favorites-button';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { reportFraudCTA } from 'src/lib/api-client/user-info';
import useLocalUserInfo from 'src/hooks/useLocalUserInfo';
import DonationReportDialog from './donation-report-dialog';

const useStyles = makeStyles({
    mainCard: {
        marginTop: '1rem',

        marginBottom: '2rem',
        justifyContent: 'center',
    },
    schoolCardContent: {
        display: 'flex',
        marginBottom: '-.25rem',
    },
    leftWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        display: 'flex',
        flexGrow: 1,
    },
    adminInfo: {
        display: 'flex',
    },
    adminTitle: {
        fontSize: '1rem',
        fontWeight: 500,
        display: 'flex',
        flexGrow: 1,
    },
    scondarySchoolInfo: {
        fontSize: '1rem',
        display: 'flex',
        flexGrow: 1,
    },
    rightWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 1,
        marginTop: '1.5rem',
        justifyContent: 'space-between',
        alignItem: 'center',
    },
    favoriteButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        height: '1rem',
        padding: '0rem',
        visibility: 'hidden',
    },
    giveNowButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        height: '1.1rem',
        fontWeight: 'bold',
        padding: '0rem',
        paddingRight: '1rem',
    },
    sectionTitle: {
        color: COLORS.secondaryNeutralBlack,
        fontWeight: 'bold',
        fontSize: '1.10em',
        marginLeft: GUTTER_WIDTH,
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '-1rem',
        overflowY: 'scroll',
    },
    inviteCTARow: {
        paddingTop: '1rem',
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
    },
    educatorTitle: {
        fontSize: '1.3125rem',
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '2rem',
        marginBottom: '2rem',
        padding: '0 1rem',
    },
    scondaryEducatorInfo: {
        fontSize: '1rem',
        lineHeight: '1.125rem',
        textAlign: 'center',
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    sendInviteContainer: {
        width: '100%',
    },
    sendInviteButtonContainer: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    sendInviteButton: {
        width: '72%',
        marginBottom: '3rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: COLORS.disabledBackgroundGray,
            color: COLORS.disabledTextGray,
        },
    },
    progressLoader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '7rem',
    },
    reportButton: {
        color: COLORS.brandRed,
        fontSize: '0.8rem',
        display: 'flex',
        gap: '7px',
    },
});

const useTeacherRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        padding: '1rem, 1rem, 0rem, 1rem',
        minHeight: '3.5rem',
        marginRight: '-1rem',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    leftWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '16px',
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        display: 'flex',
        flexGrow: 1,
    },
    address: {
        fontSize: '1rem',
        display: 'flex',
        flexGrow: 1,
    },
    rightWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 1,
        padding: '0px 0px 16px 0px !important',
        alignItems: 'center',
        marginLeft: '16px',
        justifyContent: 'space-between',
        paddingBottom: '1rem',
        borderBottom: `1px solid ${COLORS.secondaryNeutralLightGray}`,
    },
    favoriteButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        height: '1rem',
        padding: '0rem',
        visibility: 'hidden',
    },
    giveNowButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        height: '1rem',
        fontWeight: 'bold',
        padding: '0rem',
        paddingRight: '1rem',
    },
    reportButton: {
        color: COLORS.brandRed,
        fontSize: '0.8rem',
        display: 'flex',
        gap: '7px',
    },
});

type EducatorRowProps = {
    index: number;
    educator: EducatorResult;
    school?: SchoolResult | null;
    onRowPress: (id: string, name: string) => void;
    openReportDialog: (
        e: any,
        school: SchoolResult | null | undefined | EducatorResult,
        type: string
    ) => void;
    isReportDisable: (type: string, id: string) => boolean;
};
function EducatorRow({
    index,
    educator,
    school,
    onRowPress,
    openReportDialog,
    isReportDisable,
}: EducatorRowProps) {
    const classes = useTeacherRowStyles();
    const { analyticsCustomEvent } = useEventsReporter();
    const { firstName, lastName, id } = educator;
    const educatorFullName = `${firstName} ${lastName}`;
    function giveNowPressed() {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: 'Give Now',
            element_location: 'search results',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: 'Give Now',
            element_location: 'search results',
        });
        onRowPress(id, educatorFullName);
    }
    return (
        <>
            <div className={classes.row} id={`donation-select-educator-${index}-row`}>
                <div className={classes.leftWrapper}>
                    <Typography
                        className={classes.name}
                        id={`donation-select-educator-${index}-name-text`}
                    >
                        {firstName} {lastName}
                    </Typography>
                    <Typography
                        className={classes.address}
                        id={`donation-select-educator-${index}-address-street-text`}
                    >
                        {school?.name}
                    </Typography>
                    <Typography
                        className={classes.address}
                        id={`donation-select-educator-${index}-address-city-state-zip-text`}
                    >
                        {school?.city}, {school?.state}, {school?.zip}
                    </Typography>
                </div>
            </div>
            <div className={classes.rightWrapper}>
                <FavoriteButton
                    favoriteId={id}
                    index={index}
                    style={{ marginBottom: '0px' }}
                />
                <ButtonBase
                    id="donation-select-school-give-now-button"
                    className={classes.reportButton}
                    onClick={(e) => openReportDialog(e, educator, 'educator')}
                    disabled={isReportDisable('educator', id)}
                    style={{
                        color: isReportDisable('educator', id) ? COLORS.gray80 : '',
                    }}
                >
                    {isReportDisable('educator', id) ? (
                        <img src={require('../../assets/icons/iconDisableReport.svg')} alt="close icon" />
                    ) : (
                        <img src={require('../../assets/icons/iconReport.svg')} alt="close icon" />
                    )}
                    Report
                </ButtonBase>
                <ButtonBase
                    id={`donation-select-educator-${index}-give-now-button`}
                    className={classes.giveNowButton}
                    onClick={giveNowPressed}
                >
                    Give now
                </ButtonBase>
            </div>
        </>
    );
}

export function DonationSelectRecipient({ updateControllerValues }: any) {
    const classes = useStyles();
    const { query } = useRouter();
    const { schoolId, schoolName } = query as DonateControllerState;
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showReportDialog, setShowReportDialog] = useState(false);
    const { userInfo } = useLocalUserInfo();
    const { email } = userInfo;
    const [listReportedSchool, setReported] = useState<string[]>([]);
    const [listReportedEducator, setReportedEducator] = useState<string[]>([]);
    const [selectedReportCard, setSelectedReportCard] = useState({
        id: '',
        type: '',
        schoolID: '',
        reporterEmailID: '',
    });
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const closeReportDialog = () => {
        setShowReportDialog(false);
    };
    const onHandleReport = () => {
        const selectedReportId = selectedReportCard.id;
        const selectedReportType = selectedReportCard.type;
        if (selectedReportType === 'school') {
            const reportedData = [...listReportedSchool, selectedReportId];
            setReported(reportedData);
        } else if (selectedReportType === 'educator') {
            const reportedData = [...listReportedEducator, selectedReportId];
            setReportedEducator(reportedData);
        }
        setSelectedReportCard({
            id: '',
            type: '',
            schoolID: '',
            reporterEmailID: '',
        });
        closeReportDialog();
    };
    const classRoomRewardsEnrolled = localStorage?.getItem('classRoomRewardsEnrolled') === 'true';

    const reportFraud = async (e: any) => {
        const type =
            selectedReportCard?.type === 'school' ? 'Administrator' : 'Educator';
        const id =
            selectedReportCard?.type !== 'school' ? selectedReportCard.id : 'NULL';
        await reportFraudCTA(
            selectedReportCard.reporterEmailID,
            type,
            selectedReportCard.schoolID,
            id
        );
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: 'report',
            element_location: 'search results',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: 'report',
            element_location: 'search results',
        });
        onHandleReport();
        closeReportDialog();
    };

    const openReportDialog = (
        e: any,
        schoolData: SchoolResult | null | undefined | EducatorResult,
        type: string
    ) => {
        const selectedData = {
            id: schoolData?.id || '',
            type,
            schoolID: schoolId || '',
            reporterEmailID: email || '',
        };
        setSelectedReportCard(selectedData);
        setShowReportDialog(true);
    };
    const { data: schoolData, isFetching: isFetchingSchool } = useQuery(
        ['getSchoolById', schoolId],
        () => getSchoolById(schoolId)
        // eslint-disable-next-line function-paren-newline
    );

    const { data: educatorData, isFetching: isFetchingEducators } = useQuery(
        ['getSchoolEducators', schoolId],
        () => getEducatorsBySchoolId(schoolId)
        // eslint-disable-next-line function-paren-newline
    );
    const renderTeachers = !classRoomRewardsEnrolled && educatorData?.length !== 0;
    const displayEnrolledTeachersCTA = classRoomRewardsEnrolled;

    function schoolGiveNowPressed() {
        const schoolAdminName =
            !!schoolData?.admin?.firstName && !!schoolData?.admin?.lastName
                ? `${schoolData?.admin?.firstName} ${schoolData?.admin?.lastName}`
                : '';
        updateControllerValues({
            donateStep: 'setAmount',
            recipientType: 'school',
            recipientId: schoolId,
            recipientName: schoolAdminName,
            recipientSchool: schoolName,
            recipientAddress: `${schoolData?.city}, ${schoolData?.state}, ${schoolData?.zip}`,
        });
    }

    function onEducatorRowPress(id: string, educatorName: string) {
        updateControllerValues({
            donateStep: 'setAmount',
            recipientType: 'user',
            recipientId: id,
            recipientName: educatorName,
            recipientSchool: schoolName,
            recipientAddress: `${schoolData?.city}, ${schoolData?.state}, ${schoolData?.zip}`,
        });
    }

    function openShareDialog() {
        customEvent(eventMetricsMap.invite_educator_clicked);
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: 'Invite a teacher now',
            element_location: 'search results',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: 'Invite a teacher now',
            element_location: 'search results',
        });
        setShowShareDialog(true);
    }

    function closeShareDialog() {
        setShowShareDialog(false);
    }

    const isReportDisable = (type: string, id: any) => {
        let reportDisable = false;
        if (type === 'educator') {
            reportDisable = !!(
                listReportedEducator &&
                listReportedEducator.length > 0 &&
                id &&
                listReportedEducator.includes(id)
            );
        } else if (type === 'school') {
            reportDisable = !!(
                listReportedSchool &&
                listReportedSchool.length > 0 &&
                id &&
                listReportedSchool.includes(id)
            );
        }
        return reportDisable;
    };
    if (isFetchingEducators || isFetchingSchool) {
        return (
            <>
                <Typography className={classes.sectionTitle}>
                    Choose this School:
                </Typography>
                <FullWidthCard
                    hasManualShadow
                    className={classes.mainCard}
                    id="donation-flow-select-school-card"
                >
                    <div className={classes.progressLoader}>
                        <CircularProgress />
                    </div>
                </FullWidthCard>
                <Typography className={classes.sectionTitle}>
                    Or choose from these Classroom Rewards enrolled teachers:
                </Typography>

                <FullWidthCard
                    hasManualShadow
                    noPadding
                    className={classes.mainCard}
                    id="donation-flow-select-school-card"
                >
                    <div className={classes.progressLoader}>
                        <CircularProgress />
                    </div>
                </FullWidthCard>
            </>
        );
    }

    return (
        <>
            <Typography className={classes.sectionTitle}>
                Choose this School:
            </Typography>
            <FullWidthCard
                hasManualShadow
                className={classes.mainCard}
                id="donation-flow-select-school-card"
            >
                <div className={classes.schoolCardContent}>
                    <div className={classes.leftWrapper}>
                        <Typography
                            className={classes.name}
                            id="donation-select-school-name-text"
                        >
                            {schoolData?.name || schoolName}
                        </Typography>
                        {schoolData?.admin?.firstName && (
                            <div className={classes.adminInfo}>
                                <Typography
                                    className={classes.adminTitle}
                                    id="donation-select-school-admin-name-text"
                                >
                                    {schoolData?.admin?.firstName} {schoolData?.admin?.lastName}
                                </Typography>
                            </div>
                        )}
                        <Typography
                            className={classes.scondarySchoolInfo}
                            id="donation-select-school-address-street-text"
                        >
                            {schoolData?.street}
                        </Typography>
                        <Typography
                            className={classes.scondarySchoolInfo}
                            id="donation-select-school-address-city-state-zip-text"
                        >
                            {schoolData?.city}, {schoolData?.state}, {schoolData?.zip}
                        </Typography>
                    </div>
                </div>
                <div className={classes.rightWrapper}>
                    <FavoriteButton
                        favoriteId={schoolData?.id}
                        style={{ marginBottom: '0px' }}
                    />
                    {schoolData?.admin && (
                        <ButtonBase
                            id="donation-select-school-give-now-button"
                            className={classes.reportButton}
                            onClick={(e) => openReportDialog(e, schoolData, 'school')}
                            disabled={isReportDisable('school', schoolData?.id)}
                            style={{
                                color: isReportDisable('school', schoolData?.id)
                                    ? COLORS.gray80
                                    : '',
                            }}
                        >
                            {isReportDisable('school', schoolData?.id) ? (
                                <img src={require('../../assets/icons/iconDisableReport.svg')} alt="close icon" />
                            ) : (
                                <img src={require('../../assets/icons/iconReport.svg')} alt="close icon" />
                            )}
                            Report
                        </ButtonBase>
                    )}
                    <ButtonBase
                        id="donation-select-school-give-now-button"
                        className={classes.giveNowButton}
                        onClick={schoolGiveNowPressed}
                    >
                        Give now
                    </ButtonBase>
                </div>
            </FullWidthCard>
            {(renderTeachers || displayEnrolledTeachersCTA) && <Typography className={classes.sectionTitle}>
                Or choose from these Classroom Rewards enrolled teachers:
            </Typography>}
            <FullWidthCard
                hasManualShadow
                noPadding
                className={classes.mainCard}
                id="donation-flow-select-school-card"
            >
                <div className={clsx(!!educatorData?.length && classes.list)}>
                    {!!educatorData?.length &&
                        (educatorData || []).map((e, index) => (
                            <EducatorRow
                                index={index}
                                educator={e}
                                school={schoolData}
                                onRowPress={onEducatorRowPress}
                                openReportDialog={openReportDialog}
                                isReportDisable={isReportDisable}
                            />
                        ))}

                    {(displayEnrolledTeachersCTA) &&
                        <>
                            <div>
                                <div className={classes.sendInviteContainer}>
                                    <Typography
                                        className={classes.educatorTitle}
                                        id="donation-educator-title"
                                    >
                                        {educatorData?.length
                                            ? 'Not seeing your Educator?'
                                            : 'There are no enrolled teachers for this school'}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.sendInviteButtonContainer}>
                                <Button
                                    id="donation-invite-educator-button"
                                    variant="contained"
                                    className={classes.sendInviteButton}
                                    onClick={openShareDialog}
                                >
                                    Invite a teacher now
                                </Button>
                            </div>
                        </>}
                </div>
            </FullWidthCard>
            <ShareDialog
                open={showShareDialog}
                onClose={closeShareDialog}
                inviteType="inviting"
            />
            <DonationReportDialog
                open={showReportDialog}
                onClose={closeReportDialog}
                reportFraud={reportFraud}
            />
        </>
    );
}

export default DonationSelectRecipient;