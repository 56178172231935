import React from 'react';
import {
    Button,
    Typography,
    makeStyles,
    List,
    ListSubheader,
    ListItem,
    Divider,
} from '@material-ui/core';
import { Store } from 'src/services/types';
import { Template } from 'src/lib/utils/placeholder-utils';
import COLORS from 'src/lib/colors';
import AppLink from 'src/components/link';
import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import { googleMapURL, storeDistance } from 'src/lib/utils/store-utils';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
    },
    searchResultsTitle: {
        background: COLORS.primaryWhite,
        width: '100%',
        fontSize: '1.15rem',
        margin: 0,
        color: COLORS.secondaryNeutralBlack,
        paddingTop: '0',
        marginBottom: '-.5rem',
        marginTop: '-.5rem',
    },
    icon: {},
    smallInfoText: {
        fontSize: '1rem',
    },
    milesAwayText: {
        marginTop: '.1rem',
        fontSize: '1rem',
    },
    quantityText: {
        marginTop: '.1rem',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    noStoresContainer: {
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },
    noStoresIconContainer: {
        textAlign: 'center',
        display: 'block',
    },
    noStoresText: {
        textAlign: 'center',
        fontSize: '1.0625rem',
        paddingTop: '0.5rem',
        paddingLeft: '4rem',
        paddingRight: '4rem',
        fontWeight: 500,
    },
    searchResultResult: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        justifyItems: 'flex-start',
        paddingRight: 0,
        alignItems: 'baseline',
    },
    searchResultResultCTAs: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '.75rem',
        marginBottom: '.25rem',
    },
    setAsMyStoreButton: {
        textTransform: 'none',
        fontSize: '1rem',
        color: COLORS.brandCyan,
        marginLeft: '-.5rem',
    },
    searchResultCTAs: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '.25rem',
        '& a': {
            marginRight: '.75rem',
        },
    },
    searchResultDivider: {
        width: '100%',
    },
});

type SearchResultsProps = {
    results: Store[];
    isFetching?: boolean;
    placeholderStoreResults: Template[];
    selectStoreCtaText: string;
    withInventory: boolean;
    onStoreSelected: (newStore: Store) => void;
};
/** The results of store search */
export default function SearchResults({
    results,
    isFetching,
    placeholderStoreResults,
    selectStoreCtaText,
    withInventory,
    onStoreSelected,
}: SearchResultsProps) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <List component="div">
                <ListSubheader
                    component="p"
                    className={classes.searchResultsTitle}
                >
                    Search Results:
                </ListSubheader>
                {!isFetching && !results?.length && (
                    <div className={classes.noStoresContainer}>
                        <span className={classes.noStoresIconContainer}>
                            <img
                                src={require('../../assets/icons/iconNoStore.svg')}
                                alt="no stores icon"
                            />
                        </span>

                        <Typography className={classes.noStoresText}>
                            No stores were found by your search. Please enter a
                            different search criteria to try again.
                        </Typography>
                    </div>
                )}
                {isFetching &&
                    placeholderStoreResults.map((s: Template, indx: number) => (
                        <ListItem
                            key={s.id}
                            component="div"
                            className={classes.searchResultResult}
                            id={`search-result-row-${indx}`}
                        >
                            <Typography
                                className={classes.smallInfoText}
                                id={`search-result-row-${indx}-address-text`}
                            >
                                <Skeleton style={{ minWidth: '10rem' }} />
                            </Typography>
                            <Typography
                                className={classes.smallInfoText}
                                id={`search-result-row-${indx}-city-text`}
                            >
                                <Skeleton style={{ minWidth: '10rem' }} />
                            </Typography>
                            <Typography
                                className={classes.smallInfoText}
                                id={`search-result-row-${indx}-phonenumber-text`}
                            >
                                <Skeleton style={{ minWidth: '5rem' }} />
                            </Typography>
                            <Typography
                                className={classes.milesAwayText}
                                id={`search-result-row-${indx}-milesAway-text`}
                            >
                                <Skeleton style={{ minWidth: '5rem' }} />
                            </Typography>
                            <div className={classes.searchResultResultCTAs}>
                                <Button
                                    disabled
                                    id={`search-result-row-${indx}-set-store-button`}
                                    className={classes.setAsMyStoreButton}
                                    onClick={() => null}
                                >
                                    {selectStoreCtaText}
                                </Button>
                                <div className={classes.searchResultCTAs}>
                                    <AppLink
                                        href="#"
                                        style={{
                                            pointerEvents: 'none',
                                            opacity: 0.5,
                                        }}
                                        id={`search-result-row-${indx}-telephone-link`}
                                    >
                                        <img
                                            src={require('../../assets/icons/iconPhone.svg')}
                                            alt="phone icon"
                                            className={classes.icon}
                                        />
                                    </AppLink>
                                    <AppLink
                                        href="#"
                                        style={{
                                            pointerEvents: 'none',
                                            opacity: 0.5,
                                        }}
                                        id={`nearby-item-row-${indx}-map-link`}
                                    >
                                        <img
                                            src={require('../../assets/icons/iconDirection.svg')}
                                            alt="maps icon"
                                            className={classes.icon}
                                        />
                                    </AppLink>
                                </div>
                            </div>
                            <Divider className={classes.searchResultDivider} />
                        </ListItem>
                    ))}
                {!isFetching &&
                    results.map((s: Store, indx: number) => (
                        <ListItem
                            key={s.id}
                            component="div"
                            className={classes.searchResultResult}
                            id={`search-result-row-${indx}`}
                        >
                            <Typography
                                className={classes.smallInfoText}
                                id={`search-result-row-${indx}-address-text`}
                            >
                                {s?.address?.address_1}
                            </Typography>
                            <Typography
                                className={classes.smallInfoText}
                                id={`search-result-row-${indx}-city-text`}
                            >
                                {s?.address?.city}, {s?.address?.region}{' '}
                                {s?.address?.postal_code}
                            </Typography>
                            <Typography
                                className={classes.smallInfoText}
                                id={`search-result-row-${indx}-phonenumber-text`}
                            >
                                Tel: {formatPhoneNumber(s?.phoneNumber)}
                            </Typography>
                            <Typography
                                className={classes.milesAwayText}
                                id={`search-result-row-${indx}-milesAway-text`}
                            >
                                {storeDistance(s)} miles away
                            </Typography>
                            {withInventory && (
                                <Typography
                                    className={classes.quantityText}
                                    id={`search-result-row-${indx}-quantity-text`}
                                >
                                    {s?.onHandQuantity
                                        ? `${s?.onHandQuantity} in stock`
                                        : 'Out of stock'}
                                </Typography>
                            )}

                            <div className={classes.searchResultResultCTAs}>
                                <Button
                                    id={`search-result-row-${indx}-set-store-button`}
                                    className={classes.setAsMyStoreButton}
                                    onClick={() => onStoreSelected(s)}
                                >
                                    {selectStoreCtaText}
                                </Button>
                                <div className={classes.searchResultCTAs}>
                                    <AppLink
                                        href={`tel:${s?.phoneNumber}`}
                                        id={`search-result-row-${indx}-telephone-link`}
                                    >
                                        <img
                                            src={require('../../assets/icons/iconPhone.svg')}
                                            alt="phone icon"
                                            className={classes.icon}
                                        />
                                    </AppLink>
                                    <AppLink
                                        href={googleMapURL(s)}
                                        target="_blank"
                                        id={`nearby-item-row-${indx}-map-link`}
                                    >
                                        <img
                                            src={require('../../assets/icons/iconDirection.svg')}
                                            alt="maps icon"
                                            className={classes.icon}
                                        />
                                    </AppLink>
                                </div>
                            </div>
                            <Divider className={classes.searchResultDivider} />
                        </ListItem>
                    ))}
            </List>
        </div>
    );
}
