import { Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const Header = ({ goBack }: { goBack: () => void }) => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <button className={classes.backButton} onClick={goBack} type="button">
        <img
         src={require('../../assets/icons/iconBackWhite.svg')} 
         alt="back icon"
        />
      </button>
      <Typography className={classes.headerContent} variant="h3">
        eReceipts
      </Typography>
    </div>
  );
};

export default Header;
