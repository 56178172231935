import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconWarning({
    height = 20,
    width = 20,
    fillColor = 'fillWarnYellow',
}: IconInterface) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C10.8122 0 11.5537 0.461912 11.9118 1.19093L19.8059 17.2726C20.0943 17.8594 20.0595 18.5535 19.7137 19.1084C19.3678 19.6633 18.7601 20.0004 18.1067 20H1.89378C1.23994 20.0004 0.632183 19.6633 0.286375 19.1084C-0.0594334 18.5535 -0.0943062 17.8594 0.193987 17.2729L8.08821 1.19093C8.4463 0.461912 9.18781 0 10 0ZM18.6839 17.8237L10.7898 1.74189L10.7349 1.64584C10.5735 1.4008 10.2983 1.25001 10 1.25001C9.66445 1.25001 9.3581 1.44085 9.21023 1.74189L1.31593 17.824C1.218 18.0232 1.22984 18.2589 1.34724 18.4473C1.46464 18.6357 1.67097 18.75 1.89337 18.75H18.1071C18.3291 18.75 18.5354 18.6357 18.6528 18.4473C18.7702 18.2589 18.782 18.0232 18.6839 17.8237ZM10.625 13.1248V6.87476C10.625 6.52958 10.3452 6.24976 9.99998 6.24976C9.6548 6.24976 9.37498 6.52958 9.37498 6.87476V13.1248C9.37498 13.47 9.6548 13.7498 9.99998 13.7498C10.3452 13.7498 10.625 13.47 10.625 13.1248ZM9.06248 15.9374C9.06248 16.4551 9.48221 16.8749 9.99998 16.8749C10.5177 16.8749 10.9375 16.4551 10.9375 15.9374C10.9375 15.4541 10.5718 15.0562 10.1021 15.0053L9.99961 14.9998C9.48201 15 9.06248 15.4197 9.06248 15.9374Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconWarning;
