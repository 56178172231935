/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ButtonBase, SwipeableDrawer } from '@material-ui/core';
import Button from 'src/components/UI/Button';
import { Body, Heading } from 'src/components/UI/Typography';
import { mergeClasses } from 'src/stylesheets/utils';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useEventsReporter, eventMetricsMap, useUserInfo } from 'src/hooks';
import IconBack from 'src/components/Icons/IconBack';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import BrandIframe from 'src/components/BrandIframe';
import { useHistory } from 'react-router-dom';
import { getLoyaltyRewardPoints } from 'src/services/easyRewardsServices';
import styles from './styles.module.scss';

interface InfoSectionProps {
    title: string;
    body: string;
    link?: string;
    linkCta?: () => void;
    ariaLabel?: string;
}
function InfoSection({ title, body, link, linkCta, ariaLabel  }: InfoSectionProps) {
    return (
        <>
            <div className={styles.infoSection}>
                <Heading type="h6" color="textPurple" size={20} weight="bold">
                    {title}
                </Heading>
                <Body>{body}</Body>
                {link && linkCta && (
                    <Button
                        onClick={linkCta}
                        variant="text"
                        decoration="textUnderline"
                        color="textBlack"
                        fontWeight="normal"
                        fontSize={14}
                        ariaLabel={link}
                    >
                        {link}
                    </Button>
                )}
            </div>
            <hr className={styles.hr} />
        </>
    );
}

interface PointHistoryInfoDrawerProps {
    open: boolean;
    setOpen: (v: boolean) => void;
    variant: 'points-history' | 'points-info';
    hanRewardsWorks: (v: boolean) => void;
}
function PointHistoryDrawer({
    open,
    setOpen,
    variant,
    hanRewardsWorks,
}: PointHistoryInfoDrawerProps) {
    const { analyticsCustomEvent } = useEventsReporter();
    const { openChooseCategory } = useUserUpdateContext();
    const [isLoading, setIsLoading] = useState(true);
    const [currentIframeUrl, setCurrentIframeUrl] = useState('');
    const { pointHistoryPage, isIframeLoading } = useTabsUIStateContext();
    const { userInfo } = useUserInfo();
    const { data: loyaltyRewardPoints } = getLoyaltyRewardPoints();
    const history = useHistory();
    const documentData = document?.getElementById?.(
        'point-history'
    ) as HTMLIFrameElement;

    const pointHistoryUrl = `${process.env.REACT_APP_DOTCOM_URL}/grs/rewards/sr/pointhistory`;

    console.log(
        '🚀 ~ file: PointHistoryDrawer.tsx:58 ~ pointHistoryUrl:',
        pointHistoryUrl
    );

    const goBack = () => {
        if (!pointHistoryPage && documentData?.src) {
            documentData.src = pointHistoryUrl;
        }
    };
    const pointInfoList: InfoSectionProps[] = [
        {
            title: '1 point per $1 spent',
            body: 'Earn on every purchase and service order in store and online.*',
            link: 'Shop and earn',
            linkCta: () => {
                analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                    click_text: 'shop and earn',
                    element_location: 'how it works',
                    event_rewards_number:
                        userInfo?.rewardsNumber?.padStart(10, '0') || '',
                });
                setOpen(false);
            },
            ariaLabel: 'Shop and earn link',
        },
        {
            title: 'Bonus points categories',
            body: 'Earn double or more on the 3 categories you buy most.',
            link: 'Choose categories',
            linkCta: () => {
                analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                    click_text: 'choose categories',
                    element_location: 'how it works',
                    event_rewards_number:
                        userInfo?.rewardsNumber?.padStart(10, '0') || '',
                });
                openChooseCategory();
            },
            ariaLabel: 'Choose categories link',
        },
        {
            title: 'Ink and toner recycling',
            body: `Earn ${loyaltyRewardPoints?.[0]?.results?.[0].points || 'additional'
                } points on each ink or toner cartridge you recycle, up to ${loyaltyRewardPoints?.[0]?.results?.[0]
                    .maxnumberofcartridgesrecycled || 'a limit'
                } per month. No purchase required. Exclusions and limitations apply.**`,
            link: 'Recycle cartridges',
            linkCta: () => {
                analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                    click_text: 'recycle cartridges',
                    element_location: 'how it works',
                    event_rewards_number:
                        userInfo?.rewardsNumber?.padStart(10, '0') || '',
                });
                document.location.href = '/somniapp/stores';
            },
            ariaLabel: 'Recycle cartridges link',
        },
        {
            title: 'Redeem points at checkout',
            body: 'Save instantly on your purchases, plus points never expire for active members.†',
        },
    ];

    const handleClose = () => {
        hanRewardsWorks(false);
        setOpen(false);
    };

    React.useEffect(() => {
        if (open) {
            const unblock = history.block(() => {
                handleClose();
                return false;
            });
            return () => {
                unblock();
            };
        }
        return undefined;
    }, [open]);

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => setOpen(true)}
            className={styles.swipDrawer}
        >
            <div
                role="button"
                aria-label="close drawer"
                tabIndex={0} // Ensures it is focusable
                className={styles.indicator}
            />
            {variant === 'points-history' && (
                <div style={{ height: '91vh' }}>
                    {!isIframeLoading && (
                        <>
                            {!pointHistoryPage ? (
                                <ButtonBase
                                    onClick={goBack}
                                    className={styles.iconBack}
                                >
                                    <IconBack />
                                </ButtonBase>
                            ) : (
                                <ButtonBase
                                    className={styles.title}
                                    onClick={() => hanRewardsWorks(true)}
                                >
                                    How Easy Rewards works
                                </ButtonBase>
                            )}
                        </>
                    )}
                    <BrandIframe
                        id="point-history"
                        title="Point history IFrame"
                        height="81vh"
                        src={pointHistoryUrl}
                    />
                </div>
            )}
            {variant === 'points-info' && (
                <div
                    className={mergeClasses(
                        styles.flex,
                        styles.flexCol,
                        styles.rowGap4,
                        styles.drawer
                    )}
                    style={{ height: '90vh' }}
                >
                    <Heading type="h4" size={26} weight="bold">
                        It's easy to earn, redeem and save
                    </Heading>
                    {pointInfoList.map((infoSectionProps) => (
                        <InfoSection {...infoSectionProps} />
                    ))}
                    <Body size={12} color="textMediumGray" weight="light">
                        *Excludes postage, phone/gift cards, direct mail
                        services, promotional products, large quantity orders
                        and third-party/marketplace purchases except as listed
                        here.
                    </Body>
                    <Body size={12} color="textMediumGray" weight="light">
                        **Maximum number of cartridges recycled per month varies
                        by account.
                    </Body>
                    <Body size={12} color="textMediumGray" weight="light">
                        †An Easy Rewards member must earn or redeem at least one
                        point in an 18-month period to remain active.
                    </Body>
                </div>
            )}
        </SwipeableDrawer>

    );
}

// export default PointHistoryDrawer;

const WrappedPointHistoryDrawer = (props: PointHistoryInfoDrawerProps) => (
    <PointHistoryDrawer {...props} />
);
export default WrappedPointHistoryDrawer;
