import React from 'react';
import { User } from 'src/services/types';

function useFullStoryEvents() {
    const updateFsUserDetailsEvent = (userInfo: User) => {
        if ((window as any) && (window as any).FS) {
            (window as any).FS.identify(userInfo.email, {
                displayName: userInfo?.email,
                email: userInfo?.email,
            });
        }
    };

    const updateFSUserAgent = () => {
        if ((window as any) && (window as any).FS) {
            (window as any).FS.event('Staples Omni App', {
                userAgent_str: 'staples_somni_android_app',
            });
        }
    };

    return {
        updateFsUserDetailsEvent,
        updateFSUserAgent,
    };
}

export default useFullStoryEvents;
