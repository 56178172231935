import React, { useRef, useEffect, useState } from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import { Player } from '@lottiefiles/react-lottie-player';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import {
    CommonStepProps,
    DonateControllerState,
} from 'src/screens/donation-flow/types';
import useRouter from 'src/hooks/useRouter';
import ShareDialog from 'src/components/share-dialog';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';

const useStyles = makeStyles({
    mainCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '0.8rem',
    },
    title: {
        color: COLORS.secondaryNeutralBlack,
        fontWeight: 'bold',
        fontSize: '1.30rem',
        textAlign: 'center',
        letterSpacing: '-0.6pt',
    },
    animationWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0.5rem',
    },
    recipientAddressWrapper: {
        color: COLORS.secondaryNeutralBlack,
        lineHeight: '1.5rem',
        letterSpacing: '-0.5pt',
        fontSize: '1.1rem',
        textAlign: 'center',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    shareButton: {
        width: '72%',
        marginBottom: '3rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
});

type DonationSuccessProps = CommonStepProps;

export function DonationSuccess({
    finishFlow,
    donateAmount,
}: DonationSuccessProps) {
    const classes = useStyles();
    const playerRef = useRef<Player>(null);
    const { query } = useRouter();
    const { recipientName, recipientSchool, recipientType, recipientAddress } =
        query as DonateControllerState;

    const [showShareDialog, setShowShareDialog] = useState(false);
    const { customEvent } = useEventsReporter();

    useEffect(() => {
        setTimeout(() => {
            if (playerRef.current) {
                playerRef.current.play();
            }
        }, 800);
    }, []);

    const donation = Number(donateAmount);
    const fmtAmount = donation?.toFixed(2);

    function shareNow() {
        customEvent(eventMetricsMap.share_tapped);
        setShowShareDialog(true);
    }

    function closeShareDialog() {
        setShowShareDialog(false);
    }

    return (
        <Box>
            <FullWidthCard
                hasManualShadow
                className={classes.mainCard}
                id="donation-flow-success-card"
            >
                <Typography
                    className={classes.title}
                    id="giving-success-amount-text"
                >
                    You just gave ${fmtAmount} to...
                </Typography>
                <Box className={classes.animationWrapper}>
                    <Player
                        ref={playerRef}
                        autoplay={false}
                        loop
                        controls
                        src={require('../../assets/lotties/giving-success.json')}
                        style={{ height: '16rem', width: '16rem' }}
                    />
                </Box>
                <Typography
                    onClick={finishFlow}
                    className={classes.title}
                    id="giving-success-recipient-name-text"
                >
                    {recipientType === 'school'
                        ? recipientSchool
                        : recipientName}
                </Typography>
                <Typography
                    onClick={finishFlow}
                    className={classes.recipientAddressWrapper}
                    id="giving-success-recipient-address-text"
                >
                    {recipientType !== 'school' && (
                        <>
                            {recipientSchool} <br />
                        </>
                    )}

                    {recipientAddress}
                </Typography>
            </FullWidthCard>
            <Box className={classes.buttonWrapper}>
                <Button
                    id="giving-amount-give-now-button"
                    variant="contained"
                    classes={{
                        root: classes.shareButton,
                    }}
                    onClick={shareNow}
                >
                    Share
                </Button>
            </Box>
            <ShareDialog
                open={showShareDialog}
                onClose={closeShareDialog}
                inviteType="giving"
                amount={donation}
            />
        </Box>
    );
}

export default DonationSuccess;
