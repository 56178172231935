import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

type PaginationDotProps = {
    active: boolean;
    index: number;
    onClick: any;
    activeColor: string;
    inActiveColor: string;
    dotSizeStyle?: any;
};

const useStyles = makeStyles({
    root: {
        height: '7px',
        width: '7px',
        cursor: 'pointer',
        border: 0,
        background: 'none',
        padding: 0,
        margin: '0 0.2rem',
        display: 'flex',
        transition: 'width .4s ease-in-out, background-color .4s ease-in-out',
    },
    rootActive: {
        width: '30px',
    },
    dot: {
        backgroundColor: ({ inActiveColor }: any) =>
            inActiveColor || COLORS.gray80,
        height: '100%',
        width: '100%',
        borderRadius: '.415rem',
    },
    dotActive: {
        backgroundColor: ({ activeColor }: any) =>
            activeColor || COLORS.brandRed,
    },
});

export default function PaginationDot({
    active,
    index,
    onClick,
    activeColor,
    inActiveColor,
    dotSizeStyle,
}: PaginationDotProps) {
    const classes = useStyles({ activeColor, inActiveColor });
    const handleClick = (event: any) => {
        onClick(event, index);
    };

    return (
        <button
            type="button"
            aria-label='next-item'
            style={dotSizeStyle}
            className={clsx(classes.root, active && classes.rootActive)}
            onClick={handleClick}
            id={`pagination-dot-button-${index}`}
        >
            <div className={clsx(classes.dot, active && classes.dotActive)} />
        </button>
    );
}
