/* eslint-disable arrow-body-style */
import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { getPurchaseHistory } from 'src/lib/api-client/purchase-history';
import useUserInfo from 'src/hooks/useUserInfo';
import COLORS from 'src/lib/colors';
import { makeSearchString } from 'src/lib/utils/url-utils';
import useRouter from 'src/hooks/useRouter';
import Header from './Header';
import PurchaseAccordion from './PurchaseAccordion';
import ReceiptFilter from './ReceiptFilter';
import { getFilterOptions, PurchaseReceiptType } from './utils';
import useStyles from './styles';
import FullWidthCard from '../cards/full-width-card';

// Limit is 5 nodes
// STORE IS R
const PurchaseReceipts = ({ goBack }: { goBack: () => void }) => {
    const classes = useStyles();
    //   Filter logic
    const [selectedOption, selectOption] = useState<string>('1');
    const [expandedAc, setExpanded] = useState<number | null>(null);
    const { query, history, pathname } = useRouter();
    const [isComponentMount, setComponentMount] = useState(false);
    const [orderType, setOrderType] = useState({ online: true, inStore: true });
    const [productType, setProductType] = useState(!!query?.date);
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const handleChangeOnAccordian = (panel: number) => {
        if (expandedAc === panel) {
            setExpanded(null);
        } else {
            setExpanded(panel);
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        selectOption(event.target.value);
    };
    const { startDate, endDate } =
        getFilterOptions()[parseInt(selectedOption, 10)].value;

    const { userInfo } = useUserInfo();
    const rewardsNumber = userInfo?.rewardsNumber;
    //   Load more transactions logic
    const [numberOfTransactionsToShow, setNumberOfTransactionsToShow] =
        useState(5);

    useEffect(() => {
        if (query && query.transaction) {
            handleChangeOnAccordian(parseInt(query.transaction, 10));
        }
    }, []);
    const getTheOrderTypeData = () => {
        if (orderType.online && orderType.inStore) {
            return 'BOTH';
        }
        if (orderType.online) {
            return 'ONLINE';
        }
        if (orderType.inStore) {
            return 'INSTORE';
        }
        return '';
    };
    const orderTypeData = getTheOrderTypeData();

    const {
        data: response,
        isLoading: isLoadingPurchaseHistory,
        isError,
    } = useQuery(
        [
            'purchase-history',
            startDate,
            endDate,
            rewardsNumber,
            orderTypeData,
            productType,
        ],
        () => {
            const productTypeTypeData = productType ? 'ESP' : '';
            setNumberOfTransactionsToShow(5);
            if (rewardsNumber) {
                return getPurchaseHistory(
                    startDate,
                    endDate,
                    rewardsNumber,
                    orderTypeData,
                    productTypeTypeData
                );
            }
            return null;
        },
        {
            enabled: !!rewardsNumber,
        }
    );
    useEffect(() => {
        if (query.store && query.transaction && query.date) {
            const purchasedYear = new Date(query.date).getFullYear();
            switch (purchasedYear) {
                case 2023:
                    selectOption('2');
                    break;
                case 2022:
                    selectOption('3');
                    break;
                case 2021:
                    selectOption('4');
                    break;
                default:
                    selectOption('1');
            }
            setExpanded(query.transaction);
        }

        setTimeout(() => {
            setComponentMount(true);
        }, 1000);
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    const data = useMemo(
        () =>
            response?.data?.perchaseDetails.slice(
                0,
                numberOfTransactionsToShow
            ) || [],
        [numberOfTransactionsToShow, response]
    );
    const handleSignIn = () => {
        analyticsCustomEvent(eventMetricsMap.ereceipts, {
            click_text: 'sign in',
        });
        optimoveEvents(eventMetricsMap.scm_ereceipts, {
            click_text: 'sign in',
        });
        history.push({
            pathname,
            search: makeSearchString({ enrollStep: 'step2', flowName: 'SRW' }),
        });
    };
    const handleSignUpFlow = () => {
        analyticsCustomEvent(eventMetricsMap.ereceipts, {
            click_text: 'sign up',
        });
        optimoveEvents(eventMetricsMap.scm_ereceipts, {
            click_text: 'sign up',
        });
        history.push({
            pathname,
            search: makeSearchString({ enrollStep: 'signUp', flowName: 'SRW' }),
        });
    };
    const handleCheckBoxAndRadio = (e: any) => {
        e.preventDefault();
        const { name } = e.target;
        if (name === 'productType') {
            setProductType(!productType);
        } else {
            setOrderType({ ...orderType, [name]: e.target.checked });
        }
        console.log({ handleCheckBoxAndRadio: e });
    };
    const userWithoutRewardsNumber = () => {
        return (
            <div className={classes.eReceiptsContainer}>
                <img
                    src={require('../../assets/icons/eReceiptsIcon.svg')}
                    alt="staples connect icon"
                />
                <Typography className={classes.eReceiptsMainText}>
                    Please link your Staples Rewards account to see eReceipts.
                    <ButtonBase
                        className={classes.joinNowButton}
                        onClick={handleSignIn}
                    >
                        Sign in
                    </ButtonBase>
                </Typography>
                <FullWidthCard
                    id="explore-your-store-card"
                    noPadding
                    className={classes.signInCardContainer}
                >
                    <div className={classes.signInCardTextContainer}>
                        <img
                            src={require('../../assets/icons/staplesConnect.svg')}
                            alt="staples connect icon"
                            className={classes.staplesConectImageAlign}
                        />
                        <Typography className={classes.signInTitle}>
                            Don’t have Staples Rewards?
                        </Typography>
                        <Typography className={classes.signInSubTitle}>
                            Sign up for free today and unlock access to even
                            more coupons, plus up to 5% back in rewards and
                            more.
                        </Typography>
                        <Button
                            className={classes.eReceiptsSignUpBtn}
                            onClick={handleSignUpFlow}
                        >
                            Sign up
                        </Button>
                    </div>
                </FullWidthCard>
            </div>
        );
    };
    return (
        <div className={classes.eReceiptsContainerMain}>
            <Header goBack={goBack} />
            <div className={classes.eReceiptsSubMainContainer}>
                {(() => {
                    if (isLoadingPurchaseHistory || !isComponentMount) {
                        return (
                            <div>
                                <ReceiptFilter
                                    selectedOption={selectedOption}
                                    handleChange={handleChange}
                                    orderType={orderType}
                                    productType={productType}
                                    handleCheckBoxAndRadio={
                                        handleCheckBoxAndRadio
                                    }
                                />
                                <div className={classes.center}>
                                    <CircularProgress
                                        style={{ color: COLORS.textBlack }}
                                    />
                                </div>
                            </div>
                        );
                    }
                    if (isError) {
                        return (
                            <Typography className={classes.center}>
                                An unexpected error occurred while fetching your
                                transactions. Please try again later.
                            </Typography>
                        );
                    }
                    if (data?.length === 0 && userInfo?.rewardsNumber) {
                        return (
                            <div>
                                <ReceiptFilter
                                    selectedOption={selectedOption}
                                    handleChange={handleChange}
                                    orderType={orderType}
                                    productType={productType}
                                    handleCheckBoxAndRadio={
                                        handleCheckBoxAndRadio
                                    }
                                />
                                {/*  <img
                                    src={require('../../assets/icons/eReceiptsIcon.svg')}
                                    alt="staples connect icon"
                                    className={classes.staplesConectImageAlign}
                                /> */}

                                <Typography className={classes.center}>
                                    You have no eReceipts yet. Check back after
                                    you have placed a transaction.
                                </Typography>
                            </div>
                        );
                    }
                    if (userInfo?.rewardsNumber === null) {
                        return userWithoutRewardsNumber();
                    }
                    return (
                        <div>
                            <ReceiptFilter
                                selectedOption={selectedOption}
                                handleChange={handleChange}
                                orderType={orderType}
                                productType={productType}
                                handleCheckBoxAndRadio={handleCheckBoxAndRadio}
                            />
                            {data?.map((item: PurchaseReceiptType) => (
                                <PurchaseAccordion
                                    key={item.transationNumber}
                                    {...item}
                                    expandedAc={expandedAc}
                                    handleChange={handleChangeOnAccordian}
                                />
                            ))}
                            {numberOfTransactionsToShow <
                                response?.data?.perchaseDetails?.length && (
                                <ButtonBase
                                    className={classes.loadMoreButton}
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.ereceipts,
                                            {
                                                click_text:
                                                    'load more transactions',
                                            }
                                        );
                                        optimoveEvents(
                                            eventMetricsMap.ereceipts,
                                            {
                                                click_text:
                                                    'load more transactions',
                                            }
                                        );
                                        setNumberOfTransactionsToShow(
                                            (oldValue) => oldValue + 5
                                        );
                                    }}
                                >
                                    Load more transactions
                                </ButtonBase>
                            )}
                        </div>
                    );
                })()}
            </div>
        </div>
    );
};

export default PurchaseReceipts;
