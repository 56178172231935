import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    ButtonBase,
    makeStyles,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { phoneNumberValidationShipping } from 'src/lib/validations';
import ROUTES from 'src/lib/routes';
import USStates from 'src/constants/us-states';
import {
    useUserInfo,
    useRouter,
    eventMetricsMap,
    useEventsReporter,
} from 'src/hooks';
import ValidationTextField from 'src/components/validation-text-field';
import { validateZipCode } from 'src/lib/utils/user-utils';
import { Country, State, City } from 'country-state-city';
import ReactInputMask from 'react-input-mask';
import { usePlacesWidget } from 'react-google-autocomplete';
import { getEstimatedLatLong } from 'src/lib/api-client/google/geolocation';
import {
    getLastShipmentListDetails,
    getShipmentValidation,
} from 'src/lib/api-client/shipment-services';
import LoadingOverlay from 'src/components/loading-overlay';
import ConfirmationDialog from 'src/components/shipping-services/creating-shipment/confirmation-dialog';
import ShippingErrorDialog from 'src/components/shipping-services/shipping-errors/shipping-error-dialog';
import ShippingInfoContext, { ShippingFields } from 'src/lib/contexts/shipping-info-context';
import clsx from 'clsx';

const useStyles = makeStyles((t) => ({
    headerTitle: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-1px',
    },
    headerSubTitle: {
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '25.96px',
        letterSpacing: '-0.6px',
    },
    createShippingSenderContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '24px',
    },
    createShippingSenderFieldContainer: {
        display: 'flex',
        gap: 15,
        marginBottom: '16px',
    },
    createShippingSenderHeaderTitle: {
        marginBottom: '20px',
    },
    uploadBtn: {
        height: '30px',
        width: '108px',
        borderRadius: '14.5px',
        background: '#FFFFFF',
        '& span': {
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '12px',
            letterSpacing: '0px',
            textAlign: 'center',
        },
    },
    saveBtn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        marginTop: '14px',
        fontVariantLigatures: 'none',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    iconInfo: {},
    logoIconConatiner: {
        display: 'inline-flex',
        gap: 12,
        alignItems: 'center',
        margin: '6px 0px 30px',
    },
    formConatiner: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormLabel-root': {
            color: '#444444 !important',
        },
    },
    menuPaper: {
        maxHeight: 300,
    },
    formControl: {
        minWidth: '50%',
    },
    errorAlign: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        textAlign: 'center',
        color: '#c0392b',
    },
    hidden: {
        display: 'none',
    },
}));
const validationSchema = yup.object({
    firstName: yup
        .string()
        .required('First name is a required field.')
        .matches(/^ *[a-zA-Z]+( [a-zA-Z]+)* *$/, 'First name is invalid'),
    lastName: yup
        .string()
        .required('Last name is a required field.')
        .matches(/^ *[a-zA-Z]+( [a-zA-Z]+)* *$/, 'Last name is invalid'),
    phoneNumber: phoneNumberValidationShipping.required(
        'Phone number is a required field.'
    ),
    address: yup.string().required('Street address is a required field.'),
    city: yup.string().required('City is a required field.'),
    zipCode: yup
        .string()
        .required('Zip code is a required field.')
        .matches(/^(?:\d{5}|\d{5}-\d{4})$/, 'Zip code is invalid'),
    state: yup.string().required('State is a required field.'),
    email: yup
        .string()
        .required('Email is required field')
        .matches(
            /^ *[\w-_.]+@([\w-]+\.)+[\w-]{2,4} *$/g,
            'Email is not valid.'
        ),
});

interface senderProps {
    setSenderInfo: (data: any) => void;
    senderInfo: any;
}

const CreateShippingSenderInformation = ({
    setSenderInfo,
    senderInfo,
}: senderProps) => {
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [activeField, setActiveField] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);
    const [errorTitle, setErrorTitle] = useState<undefined | string>(undefined);
    const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);
    const [responseData, setResponseData] = useState({});
    const {
        sender,
        inReviewStage,
        updateSender,
        fetchSenderInfo,
        updateFetchSenderInfo,
    } = useContext(ShippingInfoContext);
    const { userInfo } = useUserInfo();
    const handleDialogClose = () => {
        setIsConfirmationRequired(false);
        setError(false);
        setErrorMsg(undefined);
        setErrorTitle(undefined);
    };
    const fields = [
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'companyName',
        'address',
        'city',
        'state',
        'zipCode',
        'aptNumb',
    ];
    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: -114,
                behavior: 'smooth',
            });
        }, 1000);
    };
    const getLastShipmentData = async () => {
        setIsLoading(true);
        const response = await getLastShipmentListDetails();
        if (!Object.keys(response).includes('error') && Object.keys(response)?.length) {
            if(response?.shipmenttype !== "TRACKING") {
                updateSender(response.shipperdetails);
                history.push({
                    pathname: ROUTES.createShippingInformation,
                    search: '?step=CreateShippingRecipientInformation',
                });  
            } 
            setIsLoading(false);  
        } else if (Object.keys(response).includes('error')) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (fetchSenderInfo) {
            getLastShipmentData();
            updateFetchSenderInfo(false);
        }
    }, []);
    useEffect(() => {
        if (sender.fullname) {
            const data = {
                firstName: sender.fullname.split(' ')[0],
                lastName: sender.fullname.split(' ')[1],
                companyName: sender.companyname,
                address: sender.street1,
                aptNumb: sender.street2,
                phoneNumber: sender.phonenumber,
                city: sender.city,
                state: sender.state,
                zipCode: sender.postalcode,
                email: sender.email,
            };
            setSenderInfo(data);
            updateSender({ ...ShippingFields });
        }
    }, [sender]);
    const sendData = (data: any, isUseModifyAddress = false) => {
        setSenderInfo(data);
        if (inReviewStage) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=CreateShippingReviewInformation',
            });
        } else {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=CreateShippingRecipientInformation',
            });
        }
    };
    const handleNavigation = async (currentValues: any) => {
        setError(false);
        // setIsLoading(true)
        const bodyData = {
            fullName: `${currentValues?.firstName} ${currentValues?.lastName}`,
            companyName: currentValues?.companyName,
            street1: currentValues?.address ? currentValues?.address : '',
            street2: currentValues?.aptNumb,
            city: currentValues?.city,
            state: currentValues?.state,
            postalcode: currentValues?.zipCode,
            phoneNumber: currentValues?.phoneNumber,
            countrycode: 1,
            country: 'US',
            email: currentValues?.email,
        };
        setIsLoading(true);
        const responseDataValues = await getShipmentValidation(bodyData);
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'confirm',
            element_location: 'sender information',
        });
        setIsLoading(false);

        if (responseDataValues?.result === 'Success') {
            setIsConfirmationRequired(false);
            sendData(currentValues);
        } else if (
            responseDataValues?.result === 'Verify' &&
            responseDataValues?.modifiedAddress
        ) {
            setIsConfirmationRequired(true);
            setResponseData({ ...responseDataValues, oldAddress: currentValues });
        }
        else if (responseDataValues?.result === 'Warning') {
            setErrorMsg(responseDataValues?.messages[0])
            setError(true);
        }
        else if (responseDataValues?.result === 'Failure') {
            setErrorTitle('Invalid Address');
            setErrorMsg("The address provided is invalid and could not be used. Please check again.")
            setError(true);
        }
        else {
            setError(true);
            setErrorMsg(
                'We couldn’t validate the provided address at this time. Please try again later.'
            );
        }
    };

    /*
     * Listening to browser back button
     * */
    window.onpopstate = () => {
        if (isConfirmationRequired) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=createShipmentSenderInfo',
            });
            setSenderInfo((responseData as any)?.oldAddress);
        }
    };
    const {
        values,
        handleSubmit,
        handleChange,
        errors,
        touched,
        setFieldError,
        setFieldValue,
        setFieldTouched,
    } = useFormik({
        initialValues: {
            firstName: senderInfo?.firstName
                ? senderInfo?.firstName
                : userInfo?.firstName,
            lastName: senderInfo?.lastName
                ? senderInfo?.lastName
                : userInfo?.lastName,
            email: senderInfo?.email ? senderInfo?.email : userInfo?.email,
            phoneNumber: senderInfo?.phoneNumber,
            companyName: senderInfo?.companyName,
            address: senderInfo?.address,
            city: senderInfo?.city,
            state: senderInfo?.state,
            zipCode: senderInfo?.zipCode,
            aptNumb: senderInfo?.aptNumb,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (currentValues) => {
            const phoneNumber = (currentValues?.phoneNumber || '').replace(
                /[^0-9]/gi,
                ''
            );
            handleNavigation(currentValues);
        },
    });

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY,
        onPlaceSelected: (place) => {
            const ADDRESS = {
                street_address: ['street_number', 'route'],
                postal_code: 'postal_code',
                city: 'locality',
                sublocality: 'sublocality',
                state: 'administrative_area_level_1',
            };
            const addressData = place?.address_components || [];
            const zipCode = (
                addressData.find((data: any) =>
                    data.types.includes(ADDRESS.postal_code)
                ) || {}
            ).short_name;
            const city = (
                addressData.find((data: any) =>
                    data.types.includes(ADDRESS.city)
                ) || {}
            ).long_name;
            const sublocality = (
                addressData.find((data) =>
                    data.types.includes(ADDRESS.sublocality)
                ) || {}
            ).long_name;

            const state = (
                addressData.find((data: any) =>
                    data.types.includes(ADDRESS.state)
                ) || {}
            ).short_name;
            const streetAddress = (
                addressData.find((data: any) =>
                    data.types.includes(ADDRESS.street_address[1])
                ) || {}
            ).long_name;
            const route = (
                addressData.find((data: any) =>
                    data.types.includes(ADDRESS.street_address[0])
                ) || {}
            ).long_name;

            const fullStreetAddress = route ? `${route} ${streetAddress}` : streetAddress;


            handleChange({ target: { value: state ?? '', name: "state" } });
            setFieldValue("city", city ?? sublocality ?? '');
            setFieldValue("address", fullStreetAddress);
            setFieldValue("zipCode", zipCode ?? '');
        },
        options: {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components', 'geometry'],
            types: ['address'],
        },
    });
    const handleStateChange = (e: any) => {
        const state = e.target?.value;
        handleChange({
            target: { value: e.target.value, name: e.target.name },
        });
        // setFieldValue("state", state);
    };
    const changeLabel = (e: any) => {
        const fieldName = e.type === 'focus' ? e?.target?.name : '';
        setFieldError(fieldName, '');
        setActiveField(fieldName);
    };
    const handleZipChange = (e: any) => {
        const zipStatus = validateZipCode(e);
        if (zipStatus) {
            if (e.target.value.length === 7 && !e.target.value.includes('-')) {
                // eslint-disable-next-line
                e.target.value = `${e.target.value.slice(
                    0,
                    5
                )}-${e.target.value.slice(5)}`;
                handleChange({
                    target: { value: e.target.value, name: e.target.name },
                });
            } else {
                handleChange({
                    target: { value: e.target.value, name: e.target.name },
                });
            }
        } else {
            e.preventDefault();
        }
    };

    useEffect(() => {
        getEstimatedLatLong().then((res) => {
            console.log({ res });
        });
    }, []);

    useEffect(() => {
        const clearForm = localStorage.getItem('clearForm');
        if (clearForm === 'true') {
            fields.map((field) => setFieldValue(field, ''));
        }
    }, []);
    return (<>{
        <div className={clsx(classes.createShippingSenderContainer, isLoading && classes.hidden)}>
            <Typography className={classes.errorAlign} >{error}</Typography>
            <div className={classes.createShippingSenderHeaderTitle}>
                {/* <Typography className={classes.headerTitle}>Hello!</Typography> */}
                <Typography className={classes.headerSubTitle}>
                    Let’s start with your name and address.
                </Typography>
            </div>
            <form onSubmit={handleSubmit} className={classes.formConatiner}>
                <div className={classes.createShippingSenderFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-first-name-input"
                        name="firstName"
                        label="First name*"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{ shrink: !!values.firstName }}
                    />
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-last-name-input"
                        name="lastName"
                        label="Last name*"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        inputProps={{ maxLength: 20 }}
                        InputLabelProps={{ shrink: !!values.lastName }}
                    />
                </div>
                <div className={classes.createShippingSenderFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-company-name-input"
                        name="companyName"
                        label="Company name"
                        inputProps={{ maxLength: 35 }}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                    />
                </div>
                <div className={classes.createShippingSenderFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-street-address-input"
                        name="address"
                        label="Street address*"
                        inputProps={{ maxLength: 35 }}
                        value={values.address}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        inputRef={ref}
                        InputLabelProps={{ shrink: !!values.address }}
                    //
                    />
                </div>
                <div className={classes.createShippingSenderFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-aptNumb-input"
                        name="aptNumb"
                        label="Apt., floor, suite, etc."
                        inputProps={{ maxLength: 35 }}
                        value={values.aptNumb}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                    />
                </div>
                <div className={classes.createShippingSenderFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-city-input"
                        name="city"
                        label="City*"
                        inputProps={{ maxLength: 30 }}
                        value={values.city}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                        InputLabelProps={{ shrink: !!values.city }}
                    />
                </div>
                <div className={classes.createShippingSenderFieldContainer}>
                    <FormControl className={classes.formControl}>
                        {/* <InputLabel id="demo-simple-select-label">State*</InputLabel> */}
                        <ValidationTextField
                            fullWidth
                            id="create-shipping-sender-state-input"
                            name="state"
                            label='State*'
                            inputProps={{ maxLength: 30 }}
                            select
                            value={values.state}
                            onChange={handleStateChange}
                            onBlur={(e: any) => changeLabel(e)}
                            onFocus={(e: any) => changeLabel(e)}
                            error={touched.state && Boolean(errors.state)}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: !!values.state }}
                        // InputProps={{ classes: { paper: classes.menuPaper } }}
                        >
                            {USStates.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </ValidationTextField>
                        {touched.state && Boolean(errors.state) ? (
                            <FormHelperText style={{ color: '#f44336' }}>
                                State is a required field.
                            </FormHelperText>
                        ) : null}
                    </FormControl>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-zipCode-input"
                        name="zipCode"
                        label='Zip code*'
                        type="text"
                        inputProps={{ maxLength: 10 }}
                        value={values.zipCode}
                        onChange={handleZipChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.zipCode && Boolean(errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                        InputLabelProps={{ shrink: !!values.zipCode }}
                    />
                </div>
                <div className={classes.createShippingSenderFieldContainer}>
                    <ReactInputMask
                        mask="(999) 999-9999"
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        name="phoneNumber"
                        inputMode="tel"
                        type="phone"
                        value={values.phoneNumber}
                    >
                        {() => (
                            <ValidationTextField
                                fullWidth
                                id="create-shipping-sender-phonenumber-input"
                                name="phoneNumber"
                                label="Phone number*"
                                inputProps={{ maxLength: 15 }}
                                value={values.phoneNumber}
                                inputMode="tel"
                                type="phone"
                                error={
                                    touched.phoneNumber &&
                                    Boolean(errors.phoneNumber)
                                }
                                helperText={
                                    touched.phoneNumber && errors.phoneNumber
                                }
                            />
                        )}
                    </ReactInputMask>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-sender-email-input"
                        name="email"
                        label="Email *"
                        inputProps={{ maxLength: 50 }}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputLabelProps={{ shrink: !!values.email }}
                    />
                </div>
                {/* <div className={classes.logoIconConatiner}>
                    <ButtonBase className={classes.uploadBtn}><span>Upload a Logo</span></ButtonBase>
                    <img src={require('../../assets/icons/inforIcon.svg')}
                     alt="info icon" className={classes.iconInfo} />
                </div> */}
                <ButtonBase className={classes.saveBtn} type="submit">
                    <span>Confirm</span>
                </ButtonBase>
            </form>

            {isConfirmationRequired &&
                <ConfirmationDialog open={isConfirmationRequired} handleClose={handleDialogClose}
                    data={responseData} sendData={sendData} />
            }
            {
                error &&
                <ShippingErrorDialog
                    title={errorTitle}
                    errorDescription={errorMsg}
                    open={error}
                    handleClose={handleDialogClose} />
            }

        </div>

    }  <LoadingOverlay
            variant="wordmark"
            theme="transparent-light"
            open={isLoading}
        /></>
    )
}

export default CreateShippingSenderInformation;
