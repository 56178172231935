/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import { optimoveEvents } from 'src/screens/optimove';
import { GUTTER_WIDTH } from 'src/theme';
import FullWidthCard from 'src/components/cards/full-width-card';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import { storeFlyerUrl } from 'src/lib/utils/store-utils';
import useStoreData from 'src/hooks/useStoreData';
import COLORS from 'src/lib/colors';
import useRouter from 'src/hooks/useRouter';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import Carousel from 'src/components/Carousel';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import ROUTES from 'src/lib/routes';
import { makeSearchString } from 'src/lib/utils/url-utils';
import {
  CACHE_ONLY,
  NO_REFETCH_FOCUS_CONNECT,
} from 'src/lib/utils/react-query-utils';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { getFeaturedLists } from 'src/lib/api-client/lists';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { ListItemResult } from 'src/services/types';
import { getHomeCarouselsDetails } from 'src/lib/api-client/staples-home';
import { useTabsUIStateContext } from "../lib/contexts/tabs-ui-state-context";

const defaultUrl =
  'https://www.staples-3p.com/s7/is/image/Staples/BCD8A562-CD9A-4AC9-A5ECCE1D0FEEF6FF';
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: '0 !important',
  },
  localStaplesCTA: {
    marginTop: '1rem',
    height: '7.4rem',
    marginLeft: GUTTER_WIDTH,
    marginRight: GUTTER_WIDTH,
    cursor: 'pointer',
  },
  rewardCardSub: {
    padding: '20px 7px',
  },
  rewardCard: {
    display: 'flex',
    cursor: 'pointer',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
  addCouponsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  },
  localStaplesCTALeftContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '15px 0px 13px 15px',
  },
  localStaplesCTABottomText: {
    fontWeight: 500,
    marginTop: ({ isMobileWidth }: any) =>
      isMobileWidth ? '0.55rem' : '1.15rem',
    paddingLeft: '1rem',
    paddingBottom: '0.6rem',
    fontSize: '0.875rem',
    color: COLORS.brandCyan,
    [theme.breakpoints.down(362)]: {
      marginTop: '0 !important',
      fontSize: '0.9rem',
    },
  },
  localStaplesCTARightImage: {
    width: '140px',
    minHeight: '7rem',
    height: 'auto',
    objectFit: 'cover',
  },
  localStaplesCTASquareImage: {
    width: '110px',
    height: '110px',
    objectFit: 'cover',
  },
  localStaplesCTASquareImageConatiner: {
    display: 'flex',
    alignItems: 'center',
    width: '140px',
    justifyContent: 'center',
    minHeight: '7rem',
    height: 'auto',
  },
  localStaplesCTAFullImage: {
    objectFit: 'cover',
  },
  shortText1: {
    fontSize: '26px',
    color: COLORS.brandRed,
    fontWeight: 700,
    paddingLeft: '1rem',
    lineHeight: '1.3rem',
    letterSpacing: '-0.6pt',
    paddingTop: '10px',
  },
  carouselMainContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: '10px',
    height: '150px',
  },
  carouselMainContainerFullImages: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: '10px',
    height: '150px',
  },
  carouselContainersmallTextLeftImage: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '15px 15px 13px 0px',
  },
  carouselTitle: {
    color: '#343434',
    fontSize: '12px',
    fontWeight: 700,
    [theme.breakpoints.down(400)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down(385)]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down(375)]: {
      fontSize: '11px',
    },
  },
  carouselDescription: {
    color: '#343434',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    [theme.breakpoints.down(400)]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down(385)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down(375)]: {
      fontSize: '13px',
    },
  },
  carouselLink: {
    color: '#086DD2',
    fontSize: '15px',
    fontWeight: 500,
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down(385)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down(375)]: {
      fontSize: '12px',
    },
  },
  carouselSpecialAmountText: {
    fontSize: '30px',
    fontWeight: 700,
    letterSpacing: '-1px',
    color: '#CC0000',
    [theme.breakpoints.down(400)]: {
      fontSize: '28px',
    },
    [theme.breakpoints.down(385)]: {
      fontSize: '27px',
    },
    [theme.breakpoints.down(375)]: {
      fontSize: '26px',
    },
  },
}));

/** used for loading and displaying classroom reward summary */
export function ViewAllDigitalCoupons({ userInfo, setStoreLinkInNative }: any) {
  const isMobileWidth = useIsMobileWidth();
  const classes = useStyles({ isMobileWidth });
  const { customEvent, analyticsCustomEvent } = useEventsReporter();
  const { history } = useRouter();
  const { data: carousel } = useQuery(
    ['carouselDetails'],
    () => getHomeCarouselsDetails(),
    {
      ...NO_REFETCH_FOCUS_CONNECT,
    }
  );
  const {
    featuredListUpdateCount,
    staplesRewardsWalletTab,
    classroomWalletRewardsTab,
  } = useUserUpdateContext();
  const { data: featuredIteamsList }: any = useQuery(
    ['getFeaturedListsCoupons', featuredListUpdateCount],
    () => getFeaturedLists(),
    {
      ...NO_REFETCH_FOCUS_CONNECT,
    }
  );
  const handleDigitalCoupons = (
    event: any,
    actionmetadata: any,
    actiontarget: any,
    actiontitle: any,
    position: number
  ) => {
    event.preventDefault();
    customEvent(eventMetricsMap.recycle_rewards_getstarted);
    analyticsCustomEvent(eventMetricsMap.stores, {
      click_text: actiontitle,
      element_location: 'promotion carousel',
      tile_category: position,
    });

    optimoveEvents(eventMetricsMap.scm_home, {
      click_text: actiontitle,
      element_location: 'promotion carousel',
      tile_category: position,
    });
    if (actionmetadata === 'https://staplestechtradein.reuse.com' || actiontitle === 'Holiday Cards') {
      openInNewTab(actionmetadata);
    } else if (actiontarget === 'goToURL') {
      const modifyUrl = actionmetadata?.replace('https://www.staples.com', process.env.REACT_APP_DOTCOM_URL)
      setStoreLinkInNative(modifyUrl);
    } else if (actiontarget === 'goToStoresTab') {
      history.push(ROUTES.stores);
    } else if (actiontarget === 'goToClassroomRewardsTab') {
      history.push(ROUTES.rewards);
    } else if (actiontarget === 'goToListsTab') {
      history.push(ROUTES.lists);
    } else if (actiontarget === 'goToWalletDigitalCoupons') {
      history.push(ROUTES.rewardsWallet);
    } else if (actiontarget === 'goToWalletClassroomRewards') {
      classroomWalletRewardsTab();
      history.push(ROUTES.rewardsWallet);
    } else if (actiontarget === 'goToWalletStaplesRewards') {
      staplesRewardsWalletTab();
      history.push(ROUTES.rewardsWallet);
    } else if (actiontarget === 'goToScannableCoupon') {
      if (actionmetadata) {
        history.push({
          pathname: ROUTES.coupons,
          search: makeSearchString({
            actionmetadata,
            deepLink: false,
          }),
        });
      } else {
        history.push(ROUTES.coupons);
      }
    } else if (actiontarget === 'goToList') {
      const featuredItem: any =
        featuredIteamsList?.find(
          (listItem: any) =>
            listItem.featuredListId === Number(actionmetadata)
        ) || null;
      if (featuredItem?.id) {
        history.push({
          pathname: `${ROUTES.lists}/${featuredItem?.id}`,
          search: '?type=featured',
        });
      }
    }
  };

  return (
    <>
      {carousel && carousel.length ? (
        <Box className={classes.addCouponsContainer}>
          <Carousel
            isAutoPlay
            activeColor={COLORS.brandRed}
            content={carousel
              ?.sort((a: any, b: any) => a.priority - b.priority)
              ?.map(
                ({
                  actionmetadata,
                  actiontarget,
                  actiontitle,
                  carouselid,
                  carouseltext1,
                  carouseltext2,
                  carouseltext3,
                  carouseltitle,
                  carouseltype,
                  template,
                  imgurl,
                }: any, i: number) => (
                  <FullWidthCard noPadding id="view-digital-coupons-container"
                    onClick={(e) =>
                      handleDigitalCoupons(e, actionmetadata, actiontarget, actiontitle, i)
                    }
                    style={{ boxShadow: 'none' }}
                  >
                    <div className={classes.rewardCard}>
                      {template === 'smallTextRightImage' ? (
                        <div className={classes.carouselMainContainer}>
                          <Box className={classes.localStaplesCTALeftContent}>
                            <Typography
                              className={classes.carouselTitle}
                              style={{ marginBottom: '7px' }}
                            >
                              {carouseltitle?.toUpperCase()}
                            </Typography>
                            <Typography
                              className={classes.carouselDescription}
                              style={{ marginBottom: '9px' }}
                            >
                              <div> {carouseltext1}</div>
                              <div> {carouseltext2}</div>
                              <div> {carouseltext3}</div>
                            </Typography>
                            <Typography className={classes.carouselLink}>
                              {actiontitle}
                            </Typography>
                          </Box>
                          <img
                            className={classes.localStaplesCTARightImage}
                            src={imgurl || defaultUrl}
                            alt="explore staples"
                          />
                        </div>
                      ) : null}
                      {template === 'smallTextLeftImage' ? (
                        <div className={classes.carouselMainContainer}>
                          <img
                            className={classes.localStaplesCTARightImage}
                            src={imgurl || defaultUrl}
                            alt="explore staples"
                          />
                          <Box
                            className={
                              classes.carouselContainersmallTextLeftImage
                            }
                          >
                            <Typography
                              className={classes.carouselTitle}
                              style={{ marginBottom: '7px' }}
                            >
                              {carouseltitle?.toUpperCase()}
                            </Typography>
                            <Typography
                              className={classes.carouselDescription}
                              style={{ marginBottom: '9px' }}
                            >
                              <div> {carouseltext1}</div>
                              <div> {carouseltext2}</div>
                              <div> {carouseltext3}</div>
                            </Typography>
                            <Typography className={classes.carouselLink}>
                              {actiontitle}
                            </Typography>
                          </Box>
                        </div>
                      ) : null}
                      {template === 'bigTextRightImage' ? (
                        <div className={classes.carouselMainContainer}>
                          <Box className={classes.localStaplesCTALeftContent}>
                            <Typography
                              className={classes.carouselTitle}
                              style={{ marginBottom: '6px' }}
                            >
                              {carouseltitle?.toUpperCase()}
                            </Typography>
                            <Typography
                              className={classes.carouselDescription}
                              style={{ marginBottom: '8px' }}
                            >
                              <div
                                className={classes.carouselSpecialAmountText}
                              >
                                {carouseltext1}
                              </div>
                              <div>{carouseltext2}</div>
                              <div>{carouseltext3}</div>
                            </Typography>
                            <Typography className={classes.carouselLink}>
                              {actiontitle}
                            </Typography>
                          </Box>
                          <img
                            className={classes.localStaplesCTARightImage}
                            src={imgurl || defaultUrl}
                            alt="explore staples"
                          />
                        </div>
                      ) : null}
                      {template === 'bigTextLeftImage' ? (
                        <div className={classes.carouselMainContainer}>
                          <img
                            className={classes.localStaplesCTARightImage}
                            src={imgurl || defaultUrl}
                            alt="explore staples"
                          />
                          <Box
                            className={
                              classes.carouselContainersmallTextLeftImage
                            }
                          >
                            <Typography
                              className={classes.carouselTitle}
                              style={{ marginBottom: '7px' }}
                            >
                              {carouseltitle?.toUpperCase()}
                            </Typography>
                            <Typography
                              className={classes.carouselDescription}
                              style={{ marginBottom: '9px' }}
                            >
                              <div
                                className={classes.carouselSpecialAmountText}
                              >
                                {carouseltext1}
                              </div>
                              <div>{carouseltext2}</div>
                              <div>{carouseltext3}</div>
                            </Typography>
                            <Typography className={classes.carouselLink}>
                              {actiontitle}
                            </Typography>
                          </Box>
                        </div>
                      ) : null}
                      {template === 'fullImage' ? (
                        <div className={classes.carouselMainContainerFullImages}>
                          <img
                            className={classes.localStaplesCTAFullImage}
                            src={imgurl || defaultUrl}
                            alt="explore staples"
                          />
                        </div>
                      ) : null}
                      {template === 'bigTextSquareImage' ? (
                        <div className={classes.carouselMainContainer}>
                          <Box className={classes.localStaplesCTALeftContent}>
                            <Typography
                              className={classes.carouselTitle}
                              style={{ marginBottom: '7px' }}
                            >
                              {carouseltitle?.toUpperCase()}
                            </Typography>
                            <Typography
                              className={classes.carouselDescription}
                              style={{ marginBottom: '9px' }}
                            >
                              <div
                                className={classes.carouselSpecialAmountText}
                              >
                                {carouseltext1}
                              </div>
                              <div>{carouseltext2}</div>
                              <div>{carouseltext3}</div>
                            </Typography>
                            <Typography className={classes.carouselLink}>
                              {actiontitle}
                            </Typography>
                          </Box>
                          <div className={classes.localStaplesCTASquareImageConatiner}>
                            <img
                              className={classes.localStaplesCTASquareImage}
                              src={imgurl || defaultUrl}
                              alt="explore staples"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </FullWidthCard>
                )
              )}
            dots={carousel.length}
            styles={{
              containerStyle: {
                width: '100%',
                height: 'auto',
              },
            }}
          />
        </Box>
      ) : null}
    </>
  );
}

export default ViewAllDigitalCoupons;
