/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconStaplesRound({
    height = 35,
    width = 35,
    fillColor = 'fillRed',
}: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2510_876)">
                <circle
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    className={color_classes[fillColor]}
                />
            </g>
            <path
                d="M24.4897 12.8333H10.5078C9.87636 12.8358 9.27253 13.0766 8.8323 13.5016C8.39206 13.9266 8.15242 14.5 8.16734 15.0927V22.1144C8.16663 22.1276 8.17152 22.1405 8.18095 22.1503C8.19037 22.1601 8.20356 22.1659 8.21759 22.1666H10.0185C10.0463 22.1666 10.0688 22.1455 10.0688 22.1194V14.986H24.9181V22.1144C24.9195 22.1409 24.9428 22.1617 24.971 22.1616H26.7799C26.8081 22.1617 26.8313 22.1409 26.8328 22.1144V15.0927C26.847 14.4998 26.6067 13.9264 26.1661 13.5015C25.7254 13.0766 25.1213 12.8358 24.4897 12.8333"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_2510_876"
                    x="0"
                    y="0"
                    width="35"
                    height="35"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2510_876"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2510_876"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default IconStaplesRound;
