import { createContext, useState, useContext } from 'react';
import Log from 'src/lib/logging';
import useInterval from 'src/hooks/useInterval';

type ToastMessage = { title?: string; details?: string };
const ToastMessageContext = createContext({
    activeMessage: {} as ToastMessage,
    toastIsOpen: false,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-empty-function */
    setToastMessage: (newMessage: ToastMessage) => {},
    dismissToast: () => {},
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-enable @typescript-eslint/no-empty-function */
});

// idea: have this set inside activeMessage, but use two seconds as a default
const messageTimeToLive = 2000;
export function useToastMessageContextState() {
    const [activeMessage, setActiveMessage] = useState<ToastMessage>({});
    const [messageLastSet, setMessageLastSet] = useState<number>(Date.now());
    const [toastIsOpen, setToastIsOpen] = useState(false);
    // idea: possibly keep a queue of messages
    function setToastMessage(newMessage: ToastMessage) {
        setActiveMessage(newMessage);
        setMessageLastSet(Date.now());
        Log.toast({ newMessage });
    }

    useInterval(() => {
        const messageLastChecked = Date.now();
        setToastIsOpen(
            activeMessage?.title !== undefined &&
                messageTimeToLive > messageLastChecked - messageLastSet
        );
    }, 500);

    function dismissToast() {
        setMessageLastSet(0);
    }

    return {
        activeMessage,
        toastIsOpen,
        setToastMessage,
        dismissToast,
    };
}

export function useToastMessageContext() {
    return useContext(ToastMessageContext);
}

export default ToastMessageContext;
