import { useMemo } from 'react';
import {
    useParams,
    useLocation,
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';
import { baseRoute } from 'src/routers/routes';

export function hardReplace(url: string) {
    window.location.replace(`${baseRoute}${url}`);
}

function useRouter<ExpectedQueryType = any>() {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const prevPath = `${location.pathname}${location.search}`;

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(
        () => ({
            // For convenience add push(), replace(), pathname at top level
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            prevPath,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            } as ExpectedQueryType,
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            match,
            location,
            history,
        }),
        [params, match, location, history]
    );
}

export default useRouter;
