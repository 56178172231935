import React from 'react';
import { makeStyles } from '@material-ui/core';
import { GUTTER_WIDTH } from 'src/theme';
import AppCard, { AppCardProps } from './app-card';

type StyleProps = { minCardWidth?: string };
type ScrollableCardProps = AppCardProps & StyleProps;

const useStyles = makeStyles({
  card: {
    marginRight: GUTTER_WIDTH,
    minWidth: ({ minCardWidth }: StyleProps) => minCardWidth || '20rem',
  },
});

/** baseline card used across the app */
export default function ScrollableCard(props: ScrollableCardProps) {
  const { minCardWidth, ...rest } = props;
  const classes = useStyles({ minCardWidth });
  return <AppCard className={classes.card} {...rest} />;
}
