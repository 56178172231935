import React from 'react';
import clsx from 'clsx';
import { Box, BoxProps, makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { Skeleton } from '@material-ui/lab';

export type FullWidthRowProps = BoxProps & {
  loading?: boolean;
};

const useStyles = makeStyles({
  fullRow: {
    width: '100%',
    display: 'flex',
    marginTop: '0.888rem',
    minHeight: '5rem',
    backgroundColor: COLORS.primaryWhite,
  },
});

/** baseline row used across the app */
export default function FullWidthRow(props: FullWidthRowProps) {
  const { className, loading, ...rest } = props;
  const classes = useStyles();
  if (loading) {
    return (
      <Box
        className={clsx(
          classes.fullRow,
          className
        )}
        {...rest}
      >
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height="100%"
          style={{ minHeight: 'inherit' }}
        />
      </Box>
    );
  }
  return (
    <Box
      className={clsx(
        classes.fullRow,
        className
      )}
      {...rest}
    />
  );
}
