import { Button, ButtonBase, Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';

import React from 'react';


export type ShippingErrorDialogProps = {
    open: boolean;
    title?: string;
    errorDescription?: string;
    handleClose?: () => void;
};
const useStyles = makeStyles({
    paper: { borderRadius: '15px', margin: '1rem', padding: '10px 0px 20px 0px', color: '#101820' },
    closeIcon: {
       position:'absolute',
        top:'-20px',
        right: '-20px',

    },
    errorTitle: {
        position: 'relative',
        textAlign:'center',
    },
    primary:{
        height: '40px',
        width: '200px',
        borderRadius: '20px',
        background: '#CC0000',
        marginTop: '25px',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    actions: {
        display: 'flex',
        justifyContent:'center',
    },
})
export default function ShippingErrorDialog(props: ShippingErrorDialogProps) {
    const classes = useStyles();
    const { open, handleClose, title = 'Hmm...', errorDescription = 'Please try again later.' } = props;
    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="sender-information-dialog-title"
                classes={{
                    paper: classes.paper,
                }}
            ><DialogTitle>
                <div className={classes.errorTitle}>
                    {title}
                    <Button
                        onClick={handleClose}
                        className={classes.closeIcon}
                        id="close-button"
                    >
                        <img src={require('../../../assets/icons/iconCloseBlack.svg')} alt="close icon" />
                    </Button>
                </div>

            </DialogTitle>
                <DialogContent >

                    <Typography >{errorDescription}</Typography>
                    <div className={classes.actions}>
                    <ButtonBase className={classes.primary}  onClick={handleClose}>
                        <span>Got it</span>
                    </ButtonBase>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
