/* eslint-disable import/prefer-default-export */

export function openInNewTab(url: string) {
  if (window?.open) {
    window.open(url, '_blank');
  }
}

export function openInSameTab(url: string) {
  if (window?.open) {
    window.open(url, '_self');
  }
}
