/* eslint-disable max-len */
import React from 'react';
import { Typography, Chip, ButtonBase } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useStyles from './styles';

interface TransactionDetails {
    skuDesc: string;
    qtyOrdered: string;
    priceAmt: string;
    imgURL: string;
    ESPLink?: string;
    setProductSelectedToAddToList: () => void;
    inStock: boolean;
    checkNumberIsNegativeOrPositive: (data: any, flag?: boolean) => string;
    isDetailsAvailable: boolean;
    skuNumber?: string;
    canPurchaseAgain?: boolean;
}

const ProductDetails = ({
    skuDesc,
    qtyOrdered,
    priceAmt,
    imgURL,
    inStock,
    ESPLink,
    setProductSelectedToAddToList,
    checkNumberIsNegativeOrPositive,
    isDetailsAvailable,
    skuNumber,
    canPurchaseAgain,
}: TransactionDetails) => {
    const classes = useStyles();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const techServicesURL =
        'https://www.staplesconnect.com/site/binaries/content/assets/staples-connect/tech-services/staples.com_terms_protection_tech_3.pdf';

    return (
        <div className={classes.productContainer}>
            <div className={classes.detailsContainer}>
                <div className={classes.chipAndImageContainer}>
                    {inStock && (
                        <Chip
                            color="primary"
                            size="small"
                            label="IN-STOCK"
                            className={classes.chip}
                        />
                    )}
                    <div className={classes.productImage}>
                        <img
                            src={
                                imgURL ||
                                require('../../assets/icons/iconProductFallback.svg')
                            }
                            alt="product-img"
                        />
                    </div>
                </div>
                <div className={classes.productDetails}>
                    <Typography className={classes.productName} variant="h6">
                        {skuDesc}
                    </Typography>
                    <div className={classes.moreProductDetails}>
                        <div className={classes.ctaContainer}>
                            <p>
                                Quantity: <b>{qtyOrdered}</b>
                            </p>
                            {canPurchaseAgain ? (
                                <ButtonBase
                                    className={classes.linkButton}
                                    onClick={() => {
                                        openInNewTab(
                                            `https://www.staples.com/product_${skuNumber}`
                                        );
                                        customEvent(
                                            eventMetricsMap.eReceipt_buyagain
                                        );
                                        analyticsCustomEvent(
                                            eventMetricsMap.ereceipts,
                                            {
                                                click_text: 'buy again',
                                                element_location:
                                                    'purchase info',
                                            }
                                        );
                                        optimoveEvents(
                                            eventMetricsMap.scm_ereceipts,
                                            {
                                                click_text: 'buy again',
                                                element_location:
                                                    'purchase info',
                                            }
                                        );
                                    }}
                                >
                                    Buy again
                                </ButtonBase>
                            ) : null}
                        </div>
                        <div className={classes.ctaContainer}>
                            <p>
                                Price:{' '}
                                <b>
                                    {checkNumberIsNegativeOrPositive(priceAmt)}{' '}
                                </b>
                                <span style={{ fontSize: 10 }}>Each</span>
                            </p>
                            {isDetailsAvailable ? (
                                <button
                                    className={classes.linkButton}
                                    onClick={() => {
                                        setProductSelectedToAddToList();
                                        customEvent(
                                            eventMetricsMap.eReceipt_addtolist
                                        );
                                    }}
                                    type="button"
                                >
                                    Add to list
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            {ESPLink && (
                <div className={classes.supportContainer}>
                    <div>
                        <p className={classes.protectionTitle}>
                            Protection and Tech Help
                        </p>
                        <p className={classes.protectionSubtitle}>
                            Powered by Asurion
                        </p>
                    </div>
                    <div className={classes.supportCta}>
                        <div>
                            <a
                                className={classes.protectionPhoneLink}
                                href="tel:844-728-7854"
                            >
                                844-728-7854
                            </a>
                            <a
                                className={classes.tAndC}
                                target="_blank"
                                href={techServicesURL}
                                rel="noreferrer"
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.outbound,
                                        {
                                            click_text: 'Terms and Conditions',
                                            link_domain: 'staplesconnect.com',
                                            link_url: techServicesURL,
                                        }
                                    );
                                    optimoveEvents(
                                        eventMetricsMap.scm_outbound,
                                        {
                                            click_text: 'Terms and Conditions',
                                            link_domain: 'staplesconnect.com',
                                            link_url: techServicesURL,
                                        }
                                    );
                                }}
                            >
                                Terms and Conditions
                            </a>
                        </div>
                        <a
                            className={classes.linkButton}
                            href="https://www.asurion.com/staples"
                        >
                            View Coverage
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetails;
