/* eslint-disable import/namespace */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import {
    Box,
    ButtonBase,
    Drawer,
    Typography,
    makeStyles,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import ScrollContainer from 'src/components/screen-scroll';
import Header from 'src/components/header';
import {
    NO_REFETCH,
    NO_REFETCH_FOCUS_CONNECT,
} from 'src/lib/utils/react-query-utils';
import { useQuery } from 'react-query';
import { getCmsShopDepartmentData } from 'src/lib/api-client/cms-api';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import BrandIframe from 'src/components/BrandIframe';
import { addToCartProduct } from 'src/lib/api-client/cart-api';
import LoadingOverlay from 'src/components/loading-overlay';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import CommonDialog from 'src/components/common-dialog';
import useAdobeAnalytics from 'src/hooks/use-adobe-analytics';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { IconCart, IconClose } from 'src/components/Icons';
import NavTop from 'src/components/nav-top';
import { useGuestMode } from 'src/hooks';
import OpenPrintExternalDialog from 'src/components/open-print-external-dialog';
import ShopDepartment from './shop-department';
import ShopTopDeals from './shop-top-deals';
import AlertMessage from '../alert-message';

const useStyles = makeStyles({
    mainCotainer: {
        background: '#ffffff',
    },
    screen: {
        flexGrow: 1,
        background: COLORS.primaryWhite,
        overflow: 'hidden',
    },
    subConatiner: {
        padding: '1rem 1rem',
        overflow: 'auto',
    },
    iframe: {
        width: '100vw',
        height: '100vh',
    },
    paperDrawer: {
        borderRadius: ' 15px 15px 0px 0px',
        margin: '0',
    },
    btnCtaClose: {
        position: 'absolute',
        right: 15,
        top: 15,
    },
    exitContainer: {
        height: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '24px',
    },
    btnCta: {
        fontSize: '1.1667rem',
        background: COLORS.brandRed,
        textAlign: 'center',
        width: '175px',
        height: '36px',
        borderRadius: '28px',
        '& span': {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    ctwText: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#000000',
        margin: '20px auto 16px',
    },
    tacoext: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#000000',
        marginBottom: '27px',
        width: '213px',
        textAlign: 'center',
    },
    headerConatiner: {
        position: 'sticky',
        top: 0,
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    headerLogo: {
        margin: '0 auto',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
        color: '#ffffff',
        margin: 'auto',
    },
});

const ShopBaseRouter = () => {
    const classes = useStyles();
    const {
        navigationText,
        setNavigationText,
        navigationTextSubOption,
        setNavigationTextSubOption,
        navigationUrl,
        setNavigationUrl,
        openService,
        setOpenServices,
        cartPage,
        shopLinkInNative,
        setShopLinkInNative,
        searchSeeAllLink,
        setSearchSeeAllLink,
    } = useTabsUIStateContext();

    const [openDrawer, setDrawer] = useState(false);
    const router = useRouter();
    const [showLoading, setLoading] = useState(false);
    const { updateCartCount, cartCount } = useCartCountContext();
    const {
        handleSetCartPage,
        openPrintDrawer,
        setOpenPrintDrawer,
        printPage,
    } = useTabsUIStateContext();
    const { isGuestModeOn } = useGuestMode();
    const isStoreModeStickyNotification = !!document.getElementById(
        'store-mode-container'
    );

    const optimovePushNotificationData: any = JSON.parse(
        localStorage.getItem('optimovePushNotificationData') || '{}'
    );
    console.log(
        '🚀 ~ ShopBaseRouter ~ optimovePushNotificationData:',
        optimovePushNotificationData
    );

    const { navBottomActiveTab, setNavBottomActiveTab } =
        useUserUpdateContext();
    const { fromPath, begin } = useProductBrowseContext();
    const [cartItemAddedStatus, setCartItemAddedStatus] = useState({
        status: '',
        requestBody: {},
        message: '',
        title: '',
    });

    const {
        data: getShopDepartment,
        refetch,
        isFetched,
    } = useQuery(
        ['getDepatmentsCms'],
        () =>
            getCmsShopDepartmentData({
                template: 'HeaderTemplate',
                tenantId: 'STAPLES_DOT_COM',
                channel: 'sdc',
                channelId: 'sdc',
                experience: 'sparqheader',
            }),
        {
            ...NO_REFETCH_FOCUS_CONNECT,
        }
    );
    useEffect(() => {
        if (isGuestModeOn || SessionManager.isLoggedIn) {
            if (
                SessionManager.access_token &&
                !getShopDepartment?.getShopDepartment
                    ?.filterOutMobileOnlyData &&
                isFetched
            ) {
                refetch();
            }
        }
    }, [isGuestModeOn, SessionManager.isLoggedIn, SessionManager.access_token]);

    const { addCartDataToAdobe } = useAdobeAnalytics();

    console.log({ navigationText, navigationUrl, navigationTextSubOption });
    const handleAutoScroll = () => {
        const scrollingElement = document.querySelector('#shop-main-conatiner');
        if (scrollingElement) {
            scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    const handleOnClickDepartment = (data: any, labelItems: string) => {
        handleAutoScroll();
        if (data?.navigationText?.[0]?.navigationTextItems?.length) {
            const itemsData: any = {
                navigationText: [
                    ...(data?.destinationURL
                        ? [
                              {
                                  ariaLabel: data.destinationURL
                                      ? `All ${data.ariaLabel}`
                                      : '',
                                  destinationURL: data.destinationURL,
                              },
                          ]
                        : []),
                    ...data?.navigationText?.[0]?.navigationTextItems,
                ],
                navigationTextTitle: labelItems,
            };
            return setNavigationText(itemsData);
        }
        if (
            data?.navigationTextSubOption?.[0]?.navigationTextSubOptionItems
                ?.length
        ) {
            const itemsData: any = {
                navigationTextSubOption: [
                    ...(data?.destinationReplaceURL
                        ? [
                              {
                                  ariaLabel: data.destinationReplaceURL
                                      ? `All ${data.ariaLabel}`
                                      : '',
                                  destinationReplaceURL:
                                      data.destinationReplaceURL,
                              },
                          ]
                        : []),
                    ...data?.navigationTextSubOption?.[0]
                        ?.navigationTextSubOptionItems,
                ],
                navigationTextSubOptionTitle: labelItems,
            };
            return setNavigationTextSubOption(itemsData);
        }
        if (SessionManager.isLoggedIn && data?.GroupOverrideURL?.[0]?.url) {
            if (openService) {
                setDrawer(true);
            }
            return setNavigationUrl(data?.GroupOverrideURL[0]?.url);
        }
        if (data?.destinationURL) {
            if (openService) {
                setDrawer(true);
            }
            // if (data?.destinationURL?.includes(`services/printing`)) {
            //     setOpenPrintDrawer({
            //         src: process.env.REACT_APP_DOT_COM_URL + data?.destinationURL,
            //         show: true,
            //     })
            //     return setNavigationUrl("");
            // }
            return setNavigationUrl(data?.destinationURL);
        }
        if (data?.destinationReplaceURL) {
            if (openService) {
                setDrawer(true);
            }
            // if (data?.destinationReplaceURL?.includes(`services/printing`)) {
            //     setOpenPrintDrawer({
            //         src: process.env.REACT_APP_DOT_COM_URL + data?.destinationReplaceURL,
            //         show: true,
            //     })
            //     return setNavigationUrl("");
            // }
            return setNavigationUrl(data?.destinationReplaceURL);
        }
        return null;
    };

    const handleBack = () => {
        if (navigationUrl) {
            setNavigationUrl('');
        } else if (navigationTextSubOption?.navigationTextSubOption?.length) {
            setNavigationTextSubOption({
                navigationTextSubOption: [],
                navigationTextSubOptionTitle: '',
            });
        } else if (navigationText?.navigationText?.length) {
            setNavigationText({
                navigationText: [],
                navigationTextTitle: '',
            });
        } else if (openService) {
            setOpenServices(false);
        }
        handleAutoScroll();
        handleSetCartPage(false);
    };
    const getTitle = () => {
        let disTitle = 'Departments';
        if (navigationTextSubOption?.navigationTextSubOptionTitle) {
            disTitle = navigationTextSubOption?.navigationTextSubOptionTitle;
        } else if (navigationText?.navigationTextTitle) {
            disTitle = navigationText?.navigationTextTitle;
        } else if (openService) {
            disTitle = 'Services';
        }
        console.log('🚀 ~ getTitle ~ disTitle:', disTitle);
        return disTitle;
    };
    const handleServices = () => {
        handleAutoScroll();
        setOpenServices(true);
    };
    console.log({ navigationUrl });

    const handleCtw = () => {
        setDrawer(false);
        setNavigationUrl('');
        return openInNewTab(
            `${process.env.REACT_APP_DOT_COM_URL}${navigationUrl}`
        );
    };

    const handleCtwClose = () => {
        setDrawer(false);
        setNavigationUrl('');
    };

    const handleNavigationShopDeatls = (url: string) => {
        setNavigationUrl(url);
    };

    const addItemToCart = async (cartItemData: any) => {
        setCartItemAddedStatus({
            ...cartItemAddedStatus,
            status: '',
            requestBody: {},
        });
        setLoading(true);
        const cartDetails: any = await addToCartProduct(cartItemData);
        if (
            cartDetails?.data?.data?.cartInfo?.items?.[0]
                ?.selectedDeliveryOption?.messages?.[0]?.code === 'OUT_OF_STOCK'
        ) {
            setCartItemAddedStatus({
                status: 'OUT_OF_STOCK',
                requestBody: {},
                message: 'This item is out of stock at this time',
                title: 'Unable to add item',
            });
        } else if (
            cartDetails?.data?.data?.cartInfo?.items?.length > 0 &&
            cartDetails?.cartCountDetails?.data?.cartInfo?.cartAttributes
                ?.numberOfItems > cartCount
        ) {
            if ('vibrate' in navigator) {
                navigator.vibrate(100);
            }
            const cartCountData =
                cartDetails?.cartCountDetails?.data?.cartInfo?.cartAttributes
                    ?.numberOfItems;
            if (cartCountData) {
                updateCartCount(cartCountData);
            }
            setCartItemAddedStatus({
                ...cartItemAddedStatus,
                status: 'success',
                requestBody: {},
            });
            addCartDataToAdobe({
                location: 'Todays Top Deals',
                cartId: cartDetails?.cartCountDetails?.data?.cartInfo?.cartId,
                ctxId: cartDetails?.cartCountDetails?.data?.cartInfo?.ctxId,
                cartItems: cartDetails?.cartCountDetails?.data?.cartInfo?.items,
                cartTotal:
                    cartDetails?.cartCountDetails?.data?.cartInfo?.cartSummary
                        ?.cartSubTotal,
            });
        } else {
            setCartItemAddedStatus({
                ...cartItemAddedStatus,
                status: 'failed',
                requestBody: cartItemData,
            });
        }
        setLoading(false);
    };

    const handleCloseToast = () => {
        setCartItemAddedStatus({
            status: '',
            requestBody: {},
            message: '',
            title: '',
        });
    };

    const handleBtnClicked = () => {
        if (cartItemAddedStatus?.status === 'success') {
            return router.push(ROUTES.cart);
        }
        return addItemToCart(cartItemAddedStatus?.requestBody);
    };
    const handleSeeAllBackButton = () => {
        begin();
        setSearchSeeAllLink({
            src: '',
            show: false,
        });
    };

    const handleBackButton = () => {
        if (shopLinkInNative?.route) {
            router.push(shopLinkInNative?.route);
        } else if (shopLinkInNative?.route !== ROUTES.shop) {
            router.push(ROUTES.home);
        }
        setShopLinkInNative({
            src: '',
            show: false,
            route: '',
        });
    };
    useEffect(() => {
        if (optimovePushNotificationData?.url) {
            const modifyUrl = optimovePushNotificationData.url?.replace(
                process.env.REACT_APP_DOT_COM_URL,
                ''
            );
            setShopLinkInNative({
                src: modifyUrl,
                show: true,
                route: ROUTES.shop,
            });
            localStorage.setItem('optimovePushNotificationData', '');
        }
    }, [optimovePushNotificationData]);

    useEffect(() => {
        if (navBottomActiveTab === ROUTES.shop && fromPath === ROUTES.shop) {
            setNavigationText({
                navigationText: [],
                navigationTextTitle: '',
            });
            setNavigationTextSubOption({
                navigationTextSubOption: [],
                navigationTextSubOptionTitle: '',
            });
            setShopLinkInNative({
                src: '',
                show: false,
                route: '',
            });
            setSearchSeeAllLink({
                src: '',
                show: false,
            });
            setNavigationUrl('');
            setOpenServices(false);
            setNavBottomActiveTab('');
        }
    }, [navBottomActiveTab]);

    useEffect(() => {
        handleSetCartPage(false);
    }, []);

    if (searchSeeAllLink.show) {
        return (
            <>
                <Header backButton onClickHandler={handleSeeAllBackButton} />
                <BrandIframe
                    src={`${process.env.REACT_APP_DOT_COM_URL}${searchSeeAllLink.src}`}
                    title="Shop-Screen"
                    id="shop-iframe"
                />
            </>
        );
    }

    if (shopLinkInNative.show) {
        return (
            <>
                <Header backButton onClickHandler={handleBackButton} />
                <BrandIframe
                    src={`${process.env.REACT_APP_DOT_COM_URL}${shopLinkInNative.src}`}
                    title="Shop-Screen"
                    id="shop-iframe"
                />
            </>
        );
    }
    return (
        <>
            <Box className={classes.screen}>
                <div className={classes.headerConatiner}>
                    {navigationUrl?.includes('/services/printing') ? (
                        <NavTop
                            withTopScroll
                            bgColor={COLORS.brandRed}
                            bgImageSrc={require('../../assets/bgs/header-bg.svg')}
                            noBorderRadius
                            minHeight="0"
                        >
                            <Box className={classes.cartHeader}>
                                <Typography className={classes.title}>
                                    Print Shop
                                </Typography>
                                <ButtonBase onClick={handleBack}>
                                    <IconClose />
                                </ButtonBase>
                            </Box>
                        </NavTop>
                    ) : cartPage ? (
                        <NavTop
                            withTopScroll
                            bgColor={COLORS.brandRed}
                            bgImageSrc={require('../../assets/bgs/header-bg.svg')}
                            noBorderRadius
                            minHeight="0"
                        >
                            <Box className={classes.cartHeader}>
                                <ButtonBase onClick={handleBack}>
                                    <ArrowBackIosRoundedIcon
                                        className={classes.backIcon}
                                    />
                                </ButtonBase>
                                <img
                                    className={classes.headerLogo}
                                    src={require('../../assets/icons/iconStaplesLogo.svg')}
                                    alt="staples connect logo"
                                    id="staples-connect-logo"
                                />
                            </Box>
                        </NavTop>
                    ) : (
                        <Header
                            backButton={
                                !!openService ||
                                !!navigationUrl ||
                                !!navigationText?.navigationText?.length ||
                                !!navigationTextSubOption
                                    ?.navigationTextSubOption?.length
                            }
                            onClickHandler={handleBack}
                        />
                    )}
                </div>
                <div className={classes.mainCotainer}>
                    {!navigationUrl || openService ? (
                        <div
                            className={classes.subConatiner}
                            id="shop-main-conatiner"
                            style={{
                                height: isStoreModeStickyNotification
                                    ? 'calc(100vh - 225px)'
                                    : 'calc(100vh - 143px)',
                            }}
                        >
                            {cartItemAddedStatus?.status !== 'OUT_OF_STOCK' ? (
                                <AlertMessage
                                    open={!!cartItemAddedStatus?.status}
                                    status={cartItemAddedStatus?.status}
                                    handleClose={handleCloseToast}
                                    icon={<IconCart />}
                                    btnName={
                                        cartItemAddedStatus?.status ===
                                        'success'
                                            ? 'View cart'
                                            : 'Try again'
                                    }
                                    message={
                                        cartItemAddedStatus?.status ===
                                        'success'
                                            ? 'Item added to your cart!'
                                            : 'Item wasn’t added to your cart.'
                                    }
                                    handleBtnClick={handleBtnClicked}
                                />
                            ) : null}
                            {!openService &&
                                !navigationText?.navigationText?.length &&
                                !navigationTextSubOption
                                    ?.navigationTextSubOption?.length && (
                                    <ShopTopDeals
                                        handleNavigationShopDeatls={
                                            handleNavigationShopDeatls
                                        }
                                        addItemToCart={addItemToCart}
                                        cartStatus={
                                            cartItemAddedStatus?.status ===
                                            'success'
                                        }
                                    />
                                )}
                            <ShopDepartment
                                getShopDepartment={getShopDepartment}
                                handleOnClickDepartment={
                                    handleOnClickDepartment
                                }
                                navigationText={navigationText?.navigationText}
                                navigationTextSubOption={
                                    navigationTextSubOption?.navigationTextSubOption
                                }
                                title={getTitle}
                                handleServices={handleServices}
                                openService={openService}
                                setDrawer={setDrawer}
                            />
                            {cartItemAddedStatus?.status === 'OUT_OF_STOCK' ? (
                                <CommonDialog
                                    open={
                                        cartItemAddedStatus?.status ===
                                        'OUT_OF_STOCK'
                                    }
                                    onClose={handleCloseToast}
                                    message={cartItemAddedStatus?.message}
                                    title={cartItemAddedStatus?.title}
                                />
                            ) : null}
                            {openPrintDrawer.show && (
                                <OpenPrintExternalDialog />
                            )}
                        </div>
                    ) : (
                        <BrandIframe
                            src={`${process.env.REACT_APP_DOT_COM_URL}${navigationUrl}`}
                            title="Shop-Screen"
                            id="shop-iframe"
                            height={
                                cartPage ||
                                navigationUrl?.includes('/services/printing')
                                    ? 'calc(100vh - 78px)'
                                    : 'null'
                            }
                        />
                    )}
                </div>
            </Box>
            <Drawer
                anchor="bottom"
                open={openDrawer}
                classes={{
                    paper: classes.paperDrawer,
                }}
                onClose={() => {
                    setDrawer(false);
                    setNavigationUrl('');
                }}
            >
                <ButtonBase
                    className={classes.btnCtaClose}
                    onClick={handleCtwClose}
                >
                    <img
                        src={require('../../assets/icons/close-icon-drawer.svg')}
                        alt="close btn"
                    />
                </ButtonBase>
                <div className={classes.exitContainer}>
                    <Typography className={classes.ctwText}>
                        Continue to website
                    </Typography>
                    <Typography className={classes.tacoext}>
                        This action is continued out of the Staples app on our
                        website.
                    </Typography>
                    <ButtonBase className={classes.btnCta} onClick={handleCtw}>
                        <span>Continue to website</span>
                    </ButtonBase>
                </div>
            </Drawer>
            {showLoading ? (
                <LoadingOverlay
                    id="loadingOverlay"
                    variant="wordmark"
                    theme="transparent-light"
                    open={showLoading}
                />
            ) : null}
        </>
    );
};

const WrappedShopBase = () => <ShopBaseRouter />;

export default WrappedShopBase;
