import React, { useState, ChangeEvent, useEffect } from 'react';
import {
    makeStyles,
    InputBase,
    ButtonBase,
    Typography,
    Collapse,
    CircularProgress,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import useRouter from 'src/hooks/useRouter';
import {
    CommonStepProps,
    ClaimSchoolsControllerState,
} from 'src/screens/claim-schools-flow/types';
import { getUserAssociatedSchools } from 'src/lib/api-client/locations';
import { useQuery } from 'react-query';
import useSchoolSearch from 'src/hooks/useSchoolSearch';
import { GUTTER_WIDTH } from 'src/theme';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingButton from 'src/components/loading-button';
import { SchoolResult } from 'src/services/types';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {
    useCachedSchools,
    SimplifiedSchool,
    splitIds,
} from 'src/lib/utils/school-utils';
import useUserInfo from 'src/hooks/useUserInfo';

type SelectSchoolProps = CommonStepProps;

const useStyles = makeStyles({
    // TODO: enrollFormCard can be deduplicated
    enrollFormCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
        minHeight: '50vh',
    },
    schoolNotFoundDisclaimer: {
        textAlign: 'center',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        marginBottom: '2em',
        fontSize: '.75rem',
    },
    schoolSearchDescription: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '1rem',
        padding: '1rem 2.75rem 3rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    searchInputForm: {
        width: '100%',
        display: 'contents',
    },
    searchInput: {
        display: 'flex',
        flexGrow: 1,
        margin: '1rem 1.25rem',
        padding: '0.25rem 0',
        borderRadius: '1.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    searchIconBtn: {
        width: '1.75rem',
        height: '1.75rem',
        marginLeft: '.5rem',
        marginRight: '.5rem',
    },
    searchIcon: {
        width: '1.75rem',
        height: '1.75rem',
    },
    schoolSearchDisclaimer: {
        textAlign: 'center',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        fontSize: '.75rem',
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '-1rem',
        height: '50vh',
        overflowY: 'scroll',
    },
    nextButtonWrapper: {
        margin: '.5rem .75rem',
    },
    nextButton: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const useSchoolRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        padding: '1rem',
        minHeight: '3.5rem',
        borderBottom: '1px black solid',
        marginRight: '-1rem',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    interactiveText: {
        flexGrow: 1,
    },
    textWrapper: {
        flexGrow: 1,
    },
    name: {
        fontSize: '1.1rem',
        display: 'flex',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    address: {
        fontSize: '0.9rem',
        display: 'flex',
    },
    icon: {
        width: '1.7rem',
        height: '1.7rem',
    },
});

type SchoolRowProps = {
    index: number;
    school: SchoolResult;
    onPress: (selectedSchool: SchoolResult) => void;
    isSelected: boolean;
};

/** School selection row when enrolling for a school */
function SchoolRow({ school, isSelected, onPress, index }: SchoolRowProps) {
    const classes = useSchoolRowStyles();
    function handlePress() {
        onPress(school);
    }
    return (
        <div
            id={`school-selection-search-result-${index}`}
            className={classes.row}
        >
            <ButtonBase
                id={`school-selection-search-result-${index}-main-button`}
                onClick={handlePress}
                className={classes.interactiveText}
            >
                <div className={classes.textWrapper}>
                    <Typography
                        id={`school-selection-search-result-${index}-name-text`}
                        className={classes.name}
                        component="div"
                    >
                        {school.name}
                    </Typography>
                    <Typography
                        id={`school-selection-search-result-${index}-address-street-text`}
                        className={classes.address}
                        component="div"
                    >
                        {school.street}
                    </Typography>
                    <Typography
                        id={`school-selection-search-result-${index}-address-city-region-text`}
                        className={classes.address}
                        component="div"
                    >
                        {school.city}, {school.state}, {school.zip}
                    </Typography>
                </div>
            </ButtonBase>
            <ButtonBase
                id={`school-selection-search-result-${index}-select-button`}
                onClick={handlePress}
            >
                {isSelected ? (
                    <RadioButtonCheckedIcon />
                ) : (
                    <RadioButtonUncheckedIcon />
                )}
            </ButtonBase>
        </div>
    );
}

const educatorDescription =
    // eslint-disable-next-line max-len
    "Enter the school name or address below and we'll connect it with your Classroom Rewards profile.";

export default function SelectSchool({
    updateControllerValues,
    userType,
}: SelectSchoolProps) {
    const classes = useStyles();
    const isAdmin = userType === 'Administrator';
    const { query } = useRouter();
    const { userInfo } = useUserInfo();
    const { selectedSchoolIds: idsFromQuery } =
        query as ClaimSchoolsControllerState;
    const [cachedSchools, setCachedSchools] = useCachedSchools();
    const [selectedSchools, setSelectedSchools] = useState<SimplifiedSchool[]>(
        []
    );
    const onRowPress = (school: SchoolResult) => {
        setSelectedSchools([school]);
    };
    const [searchString, setSearchString] = useState('');
    const [submittedSearchString, setSubmittedSearchString] = useState('');
    const [allAssociatedSchools, setAssociatedSchools] = useState<string[]>([]);
    const [filterList, setFilterList] = useState<SchoolResult[]>([]);
    const {
        isFetching: isSearching,
        schools,
        clearSchools,
    } = useSchoolSearch({
        partialAddress: submittedSearchString,
    });
    const { data: associatedSchools } = useQuery(
        ['getAssociatedSchools', userInfo],
        () => getUserAssociatedSchools(),
        {
            enabled:
                userInfo?.userType === 'Administrator' ||
                userInfo?.userType === 'Educator',
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    );
    function onUpdateSearch(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
        setSearchString(event.target.value);
    }
    function handleSubmit(e: any) {
        e.preventDefault();
        setSubmittedSearchString(searchString);
        clearSchools();
        setSelectedSchools([]);
    }

    const selectedSchoolIds = selectedSchools.map((s) => s.id); // memoize?

    const nextScreen = () => {
        setCachedSchools([...cachedSchools, ...selectedSchools]);
        setSearchString('');
        setSubmittedSearchString('');
        clearSchools();
        const selectedSchoolsToSubmit = [...selectedSchools];
        setSelectedSchools([]);
        updateControllerValues({
            claimSchoolStep: 'confirmSchool',
            selectedSchoolIds: [
                ...splitIds(idsFromQuery),
                ...selectedSchoolsToSubmit.map((s) => s.id),
            ].join(','),
            enrollSubStep: '',
        });
    };

    useEffect(() => {
        if (schools.length > 0) {
            const selectedSchoolsToSubmit = [...selectedSchools];
            updateControllerValues({
                enrollSubStep: 'schoolSearched',
                claimSchoolStep: 'selectSchool',
                selectedSchoolIds: [
                    ...splitIds(idsFromQuery),
                    ...selectedSchoolsToSubmit.map((s) => s.id),
                ].join(','),
            });
            const schoolData = schools.filter(
                (x: SchoolResult) => !allAssociatedSchools.includes(x.id)
            );
            setFilterList(schoolData);
        }
    }, [schools]);

    const nextButtonEnabled =
        selectedSchools.length > 0 ||
        (searchString.length > 0 && !isSearching && filterList.length === 0) ||
        (searchString.length > 0 && searchString !== submittedSearchString);
    function onNextButtonPressed() {
        if (selectedSchools.length > 0) {
            nextScreen();
        } else if (searchString.length > 0) {
            clearSchools();
            setSubmittedSearchString(searchString);
        }
    }
    useEffect(() => {
        if (cachedSchools?.length) {
            const getCachedSchools: any = cachedSchools?.map((x: any) => x.id);
            setAssociatedSchools(getCachedSchools);
        }
    }, [cachedSchools]);
    useEffect(() => {
        if (associatedSchools?.length) {
            const associatedSchoolsIds: any = associatedSchools?.map(
                (x: any) => x.id
            );
            setAssociatedSchools(associatedSchoolsIds);
        }
    }, [associatedSchools]);

    return (
        <FullWidthCard
            hasManualShadow
            noPadding
            className={classes.enrollFormCard}
            id="classroom-rewards-number-card"
        >
            <Collapse in={submittedSearchString === ''}>
                <Typography className={classes.schoolSearchDescription}>
                    {educatorDescription}
                </Typography>
            </Collapse>
            <form className={classes.searchInputForm} onSubmit={handleSubmit}>
                <InputBase
                    id="school-selection-search-input"
                    className={classes.searchInput}
                    placeholder="Search school"
                    onChange={onUpdateSearch}
                    value={searchString}
                    startAdornment={
                        <ButtonBase
                            onClick={handleSubmit}
                            id="school-selection-search-button"
                            className={classes.searchIconBtn}
                        >
                            <img
                                id="school-search-icon-img"
                                src={require('../../assets/icons/iconSearchThin.svg')}
                                className={classes.searchIcon}
                                alt="search icon"
                            />
                        </ButtonBase>
                    }
                />
            </form>
            <Collapse in={isAdmin && submittedSearchString !== ''}>
                <Typography className={classes.schoolSearchDisclaimer}>
                    Don't see your school? Make sure it's classified as an
                    Educational Category in Google.
                </Typography>
            </Collapse>
            <Collapse in={submittedSearchString !== ''}>
                <Typography className={classes.schoolNotFoundDisclaimer}>
                    Not finding the school you're looking for? Try searching by
                    the city, state the school is located in or the school name.
                </Typography>
            </Collapse>
            <Collapse in={submittedSearchString !== ''}>
                {isSearching && filterList.length === 0 && (
                    <div className={classes.loading}>
                        <CircularProgress
                            size="1rem"
                            style={{ color: COLORS.textBlack }}
                        />
                    </div>
                )}
                <div className={classes.list}>
                    {filterList.map((s, index) => (
                        <SchoolRow
                            index={index}
                            key={s.id}
                            school={s}
                            onPress={onRowPress}
                            isSelected={
                                !!selectedSchoolIds.find(
                                    (selId) => selId === s.id
                                )
                            }
                        />
                    ))}
                </div>
            </Collapse>
            <div className={classes.nextButtonWrapper}>
                <LoadingButton
                    fullWidth
                    id="school-selection-search-results-next-button"
                    variant="contained"
                    classes={{
                        root: classes.nextButton,
                    }}
                    type="submit"
                    disabled={!nextButtonEnabled}
                    loading={false}
                    onClick={onNextButtonPressed}
                >
                    Continue
                </LoadingButton>
            </div>
        </FullWidthCard>
    );
}
