import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { makeStyles, ButtonBase, Typography, Button } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { CommonStepProps } from 'src/screens/rewards-enroll-flow/types';
import { GUTTER_WIDTH } from 'src/theme';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingButton from 'src/components/loading-button';
import { claimSchools } from 'src/lib/api-client/rewards-enrollment';
import useError from 'src/screens/rewards-enroll-flow/use-error';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';

const useStyles = makeStyles({
    // TODO: enrollFormCard can be deduplicated
    enrollFormCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
        minHeight: '3 0rem',
        display: 'flex',
        flexDirection: 'column',
    },
    schoolSearchDescription: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '1rem',
        padding: '1rem 2.75rem 0rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    selectedSchoolsList: {
        margin: '0 2rem',
        flexGrow: 1,
        minHeight: '10rem',
    },
    buttonWrapper: {
        margin: '4rem 2rem .5rem',
    },
    nextButton: {
        textTransform: 'none',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    addAnotherSchool: {
        marginTop: '1rem',
        textTransform: 'none',
        color: COLORS.brandCyan,
        width: '100%',
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
});

const useSchoolRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'flex-start',
        padding: '1rem 0',
        minHeight: '2.5rem',
        borderBottom: `1px solid ${COLORS.secondaryNeutralLightGray}`,
    },
    name: {
        fontSize: '1.1rem',
        display: 'flex',
        textTransform: 'none',
        flexGrow: 1,
    },
    iconWrapper: {},
    icon: {
        width: '1.7rem',
        height: '1.7rem',
    },
});

type SimplifiedSchool = {
    name: string;
    id: string;
};

type SelectedSchoolRowProps = {
    index: number;
    school: SimplifiedSchool;
    onDelete: (selectedSchool: SimplifiedSchool) => void;
};
function SelectedSchoolRow({
    school,
    onDelete,
    index,
}: SelectedSchoolRowProps) {
    const classes = useSchoolRowStyles();
    function handlePress() {
        onDelete(school);
    }
    return (
        <div
            id={`school-selection-confirm-result-${index}`}
            className={classes.row}
        >
            <Typography
                id={`school-selection-confirm-result-${index}-name-text`}
                className={classes.name}
                component="div"
            >
                {school.name}
            </Typography>
            <ButtonBase
                id={`school-selection-confirm-result-${index}-remove-button`}
                className={classes.iconWrapper}
                onClick={handlePress}
            >
                <img
                    className={classes.icon}
                    alt="delete"
                    src={require('../../assets/icons/iconDelete.svg')}
                />
            </ButtonBase>
        </div>
    );
}

type RewardsEnrollConfirmSchoolProps = CommonStepProps & {
    selectedSchools: SimplifiedSchool[];
    selectAnotherSchool: () => void;
    onSchoolRemoved: (school: SimplifiedSchool) => void;
};

function RewardsEnrollConfirmSchool({
    userType,
    updateControllerValues,
    onError,
    selectedSchools,
    selectAnotherSchool,
    onSchoolRemoved,
}: RewardsEnrollConfirmSchoolProps) {
    const classes = useStyles();
    const { hadError, setHadError, showError } = useError(onError);
    const [pendingSchoolIds, setPendingSchoolIds] = useState<string[]>([]);
    const { customEvent } = useEventsReporter();
    const isAdmin = userType === 'Administrator';

    const {
        data: claimSchoolsResp,
        error,
        isFetching,
    } = useQuery(
        ['claimSchools', pendingSchoolIds.join(',')],
        () => claimSchools(pendingSchoolIds),
        {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            enabled: pendingSchoolIds.length > 0 && !hadError,
        }
    );

    useEffect(() => {
        // selected schools coming in selectSchool
        // deduping schools based on id
        updateControllerValues({
            selectedSchoolIds: Array.from(
                new Set(selectedSchools.map((s) => s.id))
            ).join(','),
        });
        setHadError(false);
    }, [selectedSchools]);

    const nextScreen = () => {
        setPendingSchoolIds(selectedSchools.map((s) => s.id));
    };

    useEffect(() => {
        let inferredError = false;
        if (!isFetching && claimSchoolsResp) {
            setPendingSchoolIds([]);
            if (claimSchoolsResp.isSuccess) {
                const prevSchoolIds = claimSchoolsResp.previousSchools.map(
                    (s) => s.id
                );
                const schoolIdDelta = claimSchoolsResp.newSchoolIds.filter(
                    (id) => prevSchoolIds.findIndex((pId) => id === pId) >= 0
                );
                if (schoolIdDelta.length > 0) {
                    customEvent(
                        isAdmin
                            ? eventMetricsMap.admin_claimed_school
                            : eventMetricsMap.educator_claimed_school
                    );
                }
                updateControllerValues({
                    enrollStep: 'success',
                });
            } else {
                inferredError = true;
            }
        }
        if (!isFetching && error) {
            inferredError = true;
        }
        if (inferredError) {
            let schoolName = 'One of your schools';
            if (selectedSchools.length > 0) {
                schoolName = selectedSchools[0].name;
            }
            showError('confirmSchool', 'school-claimed', schoolName);
        }
    }, [claimSchoolsResp, error, isFetching]);

    return (
        <FullWidthCard
            hasManualShadow
            noPadding
            className={classes.enrollFormCard}
            id="classroom-rewards-number-card"
        >
            <Typography className={classes.schoolSearchDescription}>
                Selected school{selectedSchools.length > 1 ? 's' : ''}:
            </Typography>
            <div className={classes.selectedSchoolsList}>
                {selectedSchools.map((s, index) => (
                    <SelectedSchoolRow
                        school={s}
                        key={s.id}
                        index={index}
                        onDelete={onSchoolRemoved}
                    />
                ))}
            </div>
            <div className={classes.buttonWrapper}>
                <LoadingButton
                    fullWidth
                    id="school-selection-confirm-result-next-button"
                    variant="contained"
                    classes={{
                        root: classes.nextButton,
                    }}
                    type="submit"
                    disabled={selectedSchools.length === 0}
                    loading={isFetching}
                    onClick={nextScreen}
                >
                    Continue
                </LoadingButton>
                {!isAdmin && (
                    <Button
                        id="school-selection-confirm-result-add-more-button"
                        className={classes.addAnotherSchool}
                        onClick={selectAnotherSchool}
                        disabled={isFetching}
                    >
                        Add another school
                    </Button>
                )}
            </div>
        </FullWidthCard>
    );
}

export default RewardsEnrollConfirmSchool;
