import React, { ReactNode } from 'react';
import { useScreenSize } from 'src/hooks';
import { mergeClasses } from 'src/stylesheets/utils';
import styles from './styles.module.scss';

interface BodyProps {
    display?: 'block' | 'inline';
    children: ReactNode;
    color?: string;
    size?: number;
    lineHeight?: number;
    weight?: 'light' | 'normal' | 'bold';
    margin?: string;
    padding?: string;
    textAlign?: 'textLeft' | 'textCenter' | 'textRight';
    className?: string;
    breakpoint?: number;
    tabIndex?: number;
    ariaLabel?: string;
    ariaDescription?: string;
    role?: string;
    id?: string;
}

function Body({
    display = 'block',
    children,
    color = 'default',
    size = 16,
    lineHeight,
    weight = 'normal',
    margin = '0',
    padding = '0',
    textAlign = 'textLeft',
    className,
    breakpoint = 390,
    tabIndex = undefined,
    ariaLabel = undefined,
    ariaDescription = undefined,
    role = undefined,
    id = '',
}: BodyProps) {
    // Writing case if we have more type in future
    const mergedClasses = mergeClasses(
        styles.reset,
        styles[display],
        styles[color],
        styles[weight],
        styles[textAlign],
        className
    );

    const lineHeightInPx = `${lineHeight}px`;
    let fontSize = size;
    const { width } = useScreenSize();
    if (width < breakpoint) {
        fontSize -= 2;
    }
    return (
        <p
            className={mergedClasses}
            id={id}
            style={{ fontSize, margin, padding, lineHeight: lineHeightInPx }}
            tabIndex={tabIndex} aria-label={ariaLabel} aria-roledescription={ariaDescription} role={role}
        >
            {children}
        </p>
    );
}

export default Body;
