/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */

import { User } from 'src/services/types';

export function validateUserInfo(data?: User): boolean {
    if (!data) return false;
    if (
        data.firstName &&
        data.lastName &&
        data.phone &&
        data.email &&
        data.email.includes('@') &&
        data.phone.length === 10
    ) {
        return true;
    }
    return false;
}

export const validateEmail = (email: string) => {
    console.log('🚀 ~ validateEmail ~ email:', email);
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function obfuscateUserEmail(email: string) {
    if (!email) return '';
    if (!validateEmail(email)) {
        return `${email?.slice(0, 2)}${'x'.repeat(email.length - 2)}`;
    }
    const [address, domain] = email?.split('@');
    const [domainName, domainExtension] = domain?.split('.');
    return `${address[0]}${'x'.repeat(address.length - 1)}@${
        domainName[0]
    }${'x'.repeat(domainName.length - 1)}.${domainExtension}`;
}

export const formatPhoneNumber = (value: string) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you format the area code too early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less than 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater than seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )} ${phoneNumber.slice(6, 10)}`;
};
