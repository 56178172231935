import { getConfigDetails, ConfigResponse } from "src/services/configServices";
import { UseQueryResult } from 'react-query';

function useConfigInfo(configName: string) {
  const { data, error }: UseQueryResult<ConfigResponse> = getConfigDetails(configName);

  const shortdesc = data?.configs[0]?.configdetails[0]?.shortdesc ?? '0';

  return {
    configDetails: data,
    shortdesc,
    isError: error,
  };
}

export default useConfigInfo;