/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';

function getOidcUserFromSessionStorage() {
  let storedOidcUser = null;
  if (typeof localStorage !== 'undefined') {
    storedOidcUser = localStorage.getItem(
      // eslint-disable-next-line max-len
      `oidc.user:${process.env.REACT_APP_AKAMAI_AUTHORITY_BASE_URL}/login:${process.env.REACT_APP_AKAMAI_CLIENT_ID}`
    );
  }
  return storedOidcUser;
}

export function getOidcUserFromSessionStorageParsed(): Oidc.User | null {
  const storedOidcUser = getOidcUserFromSessionStorage();
  if (storedOidcUser) {
    try {
      const oidcUser = JSON.parse(storedOidcUser);
      return oidcUser;
    } catch (e) {
      Log.axios('could not parse stored user', e);
    }
  }
  return null;
}

export function getOidcToken() {
  const parsedStoredOidcUser = getOidcUserFromSessionStorageParsed();
  return parsedStoredOidcUser?.access_token || null;
}

export function getOidcUserEmail() {
  const parsedStoredOidcUser = getOidcUserFromSessionStorageParsed();
  return parsedStoredOidcUser?.profile.email || null;
}
