import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import ROUTES from "../lib/routes";
import { setPreviousPath } from "../lib/utils/setPreviousPath";
import useRouter from "../hooks/useRouter";

type Query = {
    term: string;
};

const useStyles = makeStyles({
    container: {
        padding: '15px 20px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px',
    },
    logo: {
        width: '15px',
    },
});

export default function RecentlySearched() {
    const classes = useStyles();
    const { push, location, pathname } = useRouter();
    const { setSearchString, fromPath, setFromPath, end } = useProductBrowseContext();
    const { analyticsCustomEvent } = useEventsReporter();

    const recentlySearchedQueryArray = JSON.parse(
        localStorage.getItem('searchHistory') || '[]'
    );
    const [queries] = useState(recentlySearchedQueryArray);

    const handleRowClicked = (query: any) => {
        setSearchString(query)
        analyticsCustomEvent(eventMetricsMap.product_search, {
            click_text: 'search performed',
            element_location: 'search bar',
            search_term: query,
        });
        const path =
            ROUTES.productDetailsPage === location.pathname
                ? fromPath
                : location.pathname;
        setFromPath(path);
        if (pathname && !pathname.includes(ROUTES.lists)) {
            end();
            push(
                `${ROUTES.productDetailsPage}?searchString=${query}`,
                setPreviousPath(location, path)
            );
        }
    }
    return (

        <Box className={classes.container}>
            {queries.length !== 0 && (
                <>
                    <Typography className={classes.title}>Recently Searched</Typography>
                    {queries?.map((query: Query) => (
                        <Box className={classes.row} key={Math.random()}
                            onClick={() => handleRowClicked(query?.term)}>
                            <Typography>{query.term}</Typography>
                            <img
                                src={require('../assets/icons/iconArrow.svg')}
                                className={classes.logo}
                                alt="chevon icon"
                            />
                        </Box>
                    ))}
                </>
            )}
        </Box>

    );
}
