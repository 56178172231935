import React, { useRef, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import moment from 'moment';
import PurchaseDetails from './PurchaseDetails';
import { PurchaseReceiptType } from './utils';
import useStyles from './styles';

const PurchaseAccordion = ({
    transationNumber,
    totalSpendAmt,
    totalEarnedAmt,
    transactionDate,
    transactionType,
    storeNumber,
    expandedAc,
    storeAddress,
    totalCouponAmt,
    handleChange,
}: PurchaseReceiptType) => {
    const classes = useStyles();
    const [isAccordionOpen, setIsAccordionOpen] = useState(
        expandedAc === transationNumber
    );
    const { customEvent } = useEventsReporter();

    const scrollRef = useRef<HTMLDivElement | null>(null);

    const checkNumberIsNegative = (num: string) => {
        if (num) {
            const convertStringIntoNumber = Number(num);
            const isReturnItem = Math.sign(convertStringIntoNumber);
            return isReturnItem;
        }
        return num;
    };
    const returnOrPurchaseItem =
        checkNumberIsNegative(totalSpendAmt) === -1 ? 'return' : 'purchase';
    const checkNumberIsNegativeOrPositive = (num: any, isBracket = false) => {
        let modifyNumber = num;
        const convertStringIntoNumber = Number(num);
        const isNumberNagetive = Math.sign(convertStringIntoNumber) === -1;
        if (returnOrPurchaseItem === 'return') {
            const removeMinusSign = Number(num.toString().replace('-', ''));
            if (isNumberNagetive && !isBracket) {
                modifyNumber = `-$${removeMinusSign.toFixed(2)}`;
            } else {
                modifyNumber = `($${removeMinusSign.toFixed(2)})`;
            }
        } else if (isNumberNagetive) {
            const removeMinusSign = Number(num.toString().replace('-', ''));
            modifyNumber = `-$${removeMinusSign.toFixed(2)}`;
        } else {
            modifyNumber = `$${convertStringIntoNumber.toFixed(2)}`;
        }
        return modifyNumber;
    };

    const purchaseType =
        transactionType === 'I'
            ? `Online ${returnOrPurchaseItem}`
            : `Store ${returnOrPurchaseItem}`;

    const handleAutoScroll = () => {
        setTimeout(() => {
            if (scrollRef?.current) scrollRef.current.scrollIntoView();
        }, 300);
    };
    return (
        <Accordion
            key={transationNumber}
            ref={scrollRef}
            onChange={(_event, expanded) => {
                setIsAccordionOpen(expanded);
                handleChange?.(transationNumber);
                customEvent(eventMetricsMap.eReceipt_tapped);
            }}
            expanded={expandedAc === transationNumber}
            className={classes.accordionContainer}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.purchaseReceiptContent}>
                    <img
                        className={classes.purchaseIcon}
                        alt="purchase receipt icon"
                        id={
                            transactionType === 'I'
                                ? `online_${transationNumber}`
                                : `inStore_${transationNumber}`
                        }
                        src={
                            transactionType === 'I'
                                ? require('../../assets/icons/purchaseReceiptIcon.svg')
                                : require('../../assets/icons/iconStoreBuy.svg')
                        }
                    />
                    <div>
                        <Typography
                            className={classes.productPrice}
                            id={`${totalSpendAmt}_${transationNumber}`}
                        >
                            {checkNumberIsNegativeOrPositive(totalSpendAmt)}
                        </Typography>
                        <div>
                            <Typography
                                className={classes.productPurchaseDetails}
                                id={`${transactionDate}_${transationNumber}`}
                            >
                                {transactionType === 'R' ? (
                                    <div>
                                        {moment(transactionDate).format(
                                            'MMM DD, YYYY'
                                        )}{' '}
                                        •&nbsp;
                                        <span
                                            className={
                                                classes.productPurchaseDetailsAddress
                                            }
                                        >
                                            {storeAddress}
                                        </span>
                                    </div>
                                ) : (
                                    `${moment(transactionDate).format(
                                        'MMM DD, YYYY'
                                    )} • ${purchaseType}`
                                )}
                            </Typography>
                        </div>
                        <div className={classes.productRewardsSavingContainer}>
                            <img
                                className={classes.coinIcon}
                                src={require('../../assets/icons/coinIcon.svg')}
                                alt="coin icon"
                            />
                            <Typography
                                className={classes.productRewardsSaving}
                            >
                                {`Staples Rewards earned ${checkNumberIsNegativeOrPositive(
                                    totalEarnedAmt
                                )}`}
                            </Typography>
                        </div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <PurchaseDetails
                    transactionNumber={transationNumber}
                    checkNumberIsNegativeOrPositive={
                        checkNumberIsNegativeOrPositive
                    }
                    storeNumber={storeNumber}
                    open={isAccordionOpen}
                    date={transactionDate}
                    transactionType={transactionType}
                    returnOrPurchaseItem={returnOrPurchaseItem}
                    totalCouponAmt={totalCouponAmt}
                    handleAutoScroll={handleAutoScroll}
                    expandedAc={expandedAc}
                    storeAddress={storeAddress}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default PurchaseAccordion;
