/* eslint-disable import/prefer-default-export */
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
    userPartialShipmentURL,
    userShipmentAddressValidationURL,
} from 'src/services/urls';

// TODO Removed hardcoded rewardsNumber
export const getShipmentRates = async (
    shipperpostalcode: string,
    recipientpostalcode: string,
    recipientcountrycode: number,
    recipientcountry: string,
    packageweight: string
): Promise<any> => {
    try {
        const data = await axiosInstance.post(
            `${process.env.REACT_APP_SHIPMENT_RATES}`,
            {
                shipperpostalcode,
                recipientpostalcode,
                recipientcountrycode,
                recipientcountry,
                packageweight,
            }
        );
        return data;
    } catch (e) {
        console.log({ e });
    }
    return null;
};

export const getPartialShipment = async (data: any): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance.post(userPartialShipmentURL, {
            ...data,
        });
        return dataRes?.data;
    } catch (e: any) {
        return 'Sorry Unable to Process your Request at this Moment, Please Try Again Later!!';
    }
};

export const getShipmentValidation = async (data: any): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance.post(
            userShipmentAddressValidationURL,
            { ...data }
        );
        console.log({ dataRes });
        return dataRes?.data;
    } catch (e: any) {
        return 'Sorry Unable to Process your Request at this Moment, Please Try Again Later!!';
    }
};

export const getShipmentListDetails = async (): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance.get(
            `${process.env.REACT_APP_SHIPMENT_LIST_DETAILS}`
        );
        return dataRes?.data?.shipments;
    } catch (e: any) {
        return { error: e };
    }
};

export const getLastShipmentListDetails = async (): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance.get(
            `${process.env.REACT_APP_SHIPMENT_LIST_DETAILS}`
        );
        let sortedShipmentList;
        if (dataRes?.data?.shipments?.length) {
            sortedShipmentList = dataRes?.data?.shipments?.sort(
                (a: any, b: any) => b.shipmentdetailid - a.shipmentdetailid
            );
        }
        return sortedShipmentList[0];
    } catch (e: any) {
        return { error: e };
    }
};

export const deleteShipment = async (shipmentnumber: any): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance.post(
            `${process.env.REACT_APP_DELETE_SHIPMENT}`,
            { shipmentnumber }
        );
        return dataRes?.data;
    } catch (e: any) {
        return 'Sorry Unable to Process your Request at this Moment, Please Try Again Later!!';
    }
};
export const addShipmentTracking = async (
    trackingnumber: string
): Promise<any> => {
    try {
        const trackingResponse: any = await axiosInstance.post(
            `${process.env.REACT_APP_SHIPMENT_ADD_SHIPMENT_TRACKING}`,
            {
                trackingnumber,
            }
        );
        return trackingResponse?.data;
    } catch (e: any) {
        return e?.response.data;
    }
};
export const deleteTracking = async (trackingnumber: any): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance.post(
            `${process.env.REACT_APP_DELETE_TRACKING}`,
            { trackingnumber }
        );
        return dataRes?.data;
    } catch (e: any) {
        return 'Sorry Unable to Process your Request at this Moment, Please Try Again Later!!';
    }
};
