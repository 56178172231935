import React from 'react';

function IconTopDesign() {
    return (
        <svg
            width="166"
            height="132"
            viewBox="0 0 166 132"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_3828_7744"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="183"
                height="132"
            >
                <path d="M183 0H0.503357V132H183V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_3828_7744)">
                <path
                    d="M50.9646 48.6177C58.789 48.6177 65.132 42.2747 65.132 34.4502C65.132 26.6258 58.789 20.2828 50.9646 20.2828C43.1401 20.2828 36.7971 26.6258 36.7971 34.4502C36.7971 42.2747 43.1401 48.6177 50.9646 48.6177Z"
                    fill="#CC0000"
                />
                <path
                    d="M94.7695 97.3779L68.2581 70.8664C62.9771 65.5855 62.9771 57.0246 68.2581 51.7436C73.5391 46.4627 82.0999 46.4627 87.3809 51.7436L113.892 78.2551C119.173 83.536 119.173 92.0969 113.892 97.3779C108.611 102.659 100.051 102.659 94.7695 97.3779Z"
                    fill="#5B0069"
                />
                <path
                    d="M10.567 13.1753L-23.5344 -20.9261C-28.8154 -26.2071 -28.8154 -34.7679 -23.5344 -40.0489C-18.2534 -45.3299 -9.69256 -45.3299 -4.41159 -40.0489L29.6927 -5.9446C34.9737 -0.663624 34.9737 7.89723 29.6927 13.1782C24.4118 18.4562 15.8479 18.4562 10.567 13.1753Z"
                    fill="#5B0069"
                />
                <path
                    d="M131.351 72.4116C139.176 72.4116 145.519 66.0686 145.519 58.2441C145.519 50.4197 139.176 44.0767 131.351 44.0767C123.527 44.0767 117.184 50.4197 117.184 58.2441C117.184 66.0686 123.527 72.4116 131.351 72.4116Z"
                    fill="#0058D8"
                />
                <path
                    d="M159.683 100.744C167.508 100.744 173.851 94.4006 173.851 86.5761C173.851 78.7516 167.508 72.4086 159.683 72.4086C151.859 72.4086 145.516 78.7516 145.516 86.5761C145.516 94.4006 151.859 100.744 159.683 100.744Z"
                    fill="#0FBAFF"
                />
                <path
                    d="M86.9931 -5.24008L113.505 21.2714C118.785 26.5523 118.785 35.1132 113.505 40.3942C108.224 45.6751 99.6627 45.6751 94.3817 40.3942L67.8703 13.8827C62.5893 8.60175 62.5893 0.0408983 67.8703 -5.24008C73.1512 -10.521 81.7121 -10.521 86.9931 -5.24008Z"
                    fill="#3E009F"
                />
                <path
                    d="M137.165 -11.6607L186.023 37.1972C191.304 42.4782 191.304 51.039 186.023 56.32C180.742 61.601 172.181 61.601 166.9 56.32L118.042 7.46213C112.761 2.18116 112.761 -6.37969 118.042 -11.6607C123.32 -16.9416 131.884 -16.9416 137.165 -11.6607Z"
                    fill="#CC0000"
                />
            </g>
        </svg>
    );
}

export default IconTopDesign;
