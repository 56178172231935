import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
    makeStyles,
    ButtonBase,
    Typography,
    Box,
    CircularProgress,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import COLORS from 'src/lib/colors';
import { useCreateImportListContext } from 'src/lib/contexts/create-import-list-context';
import { GUTTER_WIDTH } from 'src/theme';
import FullWidthCard from 'src/components/cards/full-width-card';
import { ListBySchoolResult } from 'src/services/types';
import { getListsBySchool, getItemsByListId } from 'src/lib/api-client/lists';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
    },
    enrollFormCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
        position: 'relative',
    },
    sectionTitle: {
        color: COLORS.primaryWhite,
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        fontWeight: 500,
    },
    schoolSearchDescription: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '1rem',
        padding: '1rem 2.75rem 3rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    searchInputForm: {
        width: '100%',
        display: 'contents',
    },
    searchInput: {
        display: 'flex',
        flexGrow: 1,
        margin: '1rem 1.25rem',
        padding: '0.25rem 0',
        borderRadius: '1.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    searchIconBtn: {
        width: '1.75rem',
        height: '1.75rem',
        marginLeft: '.5rem',
        marginRight: '.5rem',
    },
    searchIcon: {
        width: '1.75rem',
        height: '1.75rem',
    },
    schoolSearchDisclaimer: {
        textAlign: 'center',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        fontSize: '.75rem',
    },
    schoolNotFoundDisclaimer: {
        textAlign: 'center',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        marginBottom: '2em',
        fontSize: '.75rem',
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '-1rem',
        minHeight: '50vh',
    },
    nextButtonWrapper: {
        margin: '.5rem .75rem',
    },
    nextButton: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    importListLoadingText: {
        color: COLORS.fordGray,
        marginLeft: '0.2rem',
        marginTop: '-17rem',
        fontWeight: 500,
    },
    importListLoader: {
        flexDirection: 'row',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: '-2rem',
        height: '100vh',
        backgroundColor: COLORS.whiteBackgroundWithOpacity,
    },
    resultsCountWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '1rem',
        borderBottom: `0.1rem ${COLORS.gray80} solid`,
        marginBottom: '1rem',
    },
    resultsCountText: {
        fontWeight: 500,
    },
    searchedZipCodeText: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: '0.3rem 0',
    },
    changeLocationText: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
    },
    schoolDetailWrapper: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        padding: '0 0 0 1rem',
        flexDirection: 'column',
    },
    schoolItem: {
        width: '100%',
        display: 'flex',
        minHeight: '3.5rem',
        flexShrink: 0,
        flexDirection: 'column',
    },
    schoolItems: {
        width: '100%',
        display: 'flex',
        padding: '0 1rem 1rem 0',
        minHeight: '3.5rem',
        flexShrink: 0,
        margin: '0 .5rem 0 0',
        borderBottom: `0.1rem ${COLORS.gray80} solid`,
        flexDirection: 'column',
    },
    interactiveText: {
        flexGrow: 1,
    },
    textWrapper: {
        flexGrow: 1,
    },
    name: {
        fontSize: '1.1rem',
        display: 'flex',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    address: {
        fontSize: '0.9rem',
        display: 'flex',
    },
    icon: {
        width: '1.7rem',
        height: '1.7rem',
    },
});

const useListRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        padding: '0 0 1rem 1rem',
        minHeight: '3.5rem',
        marginRight: '-1rem',
        '&:last-child > *': {
            borderBottom: 'none',
        },
        flexDirection: 'column',
    },
    schoolItem: {
        width: '100%',
        display: 'flex',
        padding: '0 1rem 1rem 0',
        minHeight: '3.5rem',
        flexShrink: 0,
        margin: '0 .5rem 0 0',
        borderBottom: `0.1rem ${COLORS.gray80} solid`,
    },
    interactiveText: {
        flexGrow: 1,
    },
    textWrapper: {
        flexGrow: 1,
    },
    name: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'left',
        paddingRight: '0.5rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    description: {
        fontSize: '0.9rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    oneLineClamp: {
        WebkitLineClamp: 1,
    },
    threeLineClamp: {
        WebkitLineClamp: 3,
    },
    importListButton: {
        position: 'revert',
    },
    icon: {
        width: '1.7rem',
        height: '1.7rem',
    },
    ctaWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '0.6rem',
    },
    viewListsText: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        fontSize: '0.9rem',
    },
    listAvailableText: {
        color: COLORS.successGreen,
        fontSize: '0.9rem',
    },
    noListText: {
        fontSize: '0.9rem',
        color: COLORS.darkRed,
    },
});

type ListRowProps = {
    index: number;
    listsBySchool: ListBySchoolResult;
    onPress: (selectedSchool: ListBySchoolResult) => void;
};

/** School selection row when enrolling for a school */
function ListsRow({ listsBySchool, onPress, index }: ListRowProps) {
    const classes = useListRowStyles();
    const { customEvent } = useEventsReporter();

    function handlePress() {
        onPress(listsBySchool);
        customEvent(eventMetricsMap.list_imported);
    }
    return (
        <div
            id={`school-selection-search-result-${index}`}
            className={classes.row}
        >
            <div className={classes.schoolItem}>
                <div className={classes.textWrapper}>
                    <Typography
                        id={`school-selection-search-result-${index}-name-text`}
                        className={clsx(classes.name, classes.oneLineClamp)}
                        component="div"
                    >
                        {listsBySchool.listName}
                    </Typography>
                    <Typography
                        id={`school-selection-search-result-${index}-address-street-text`}
                        className={clsx(
                            classes.description,
                            classes.threeLineClamp
                        )}
                        component="div"
                    >
                        {listsBySchool?.description}
                    </Typography>
                </div>
                <ButtonBase
                    id={`school-selection-search-result-${index}-main-button`}
                    onClick={handlePress}
                    className={classes.importListButton}
                >
                    <Typography className={classes.viewListsText}>
                        Import List
                    </Typography>
                </ButtonBase>
            </div>
        </div>
    );
}

export default function ImportListSelectSchool() {
    const classes = useStyles();
    const [selectedListId, setSelectedListId] = useState<
        null | undefined | string
    >(null);

    const { notifyUserListUpdate } = useUserUpdateContext();

    const { setImportedListDetails, goToPage, selectedSchoolDetails } =
        useCreateImportListContext();

    const { data: listsBySchoolData, isFetching: isFetchingSchools } = useQuery(
        ['listsAsPerSchool', selectedSchoolDetails.id],
        () => getListsBySchool(selectedSchoolDetails.id),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    );

    const { data: itemsByListId, isFetching: isFetchingItems } = useQuery(
        ['itemsAsPerListId', selectedListId],
        () => getItemsByListId(selectedListId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            enabled: selectedListId != null,
        }
    );

    useEffect(() => {
        if (itemsByListId && !isFetchingItems) {
            setImportedListDetails(itemsByListId);
            notifyUserListUpdate();
            goToPage({ type: 'view-items' });
        }
    }, [itemsByListId, isFetchingItems]);

    const onRowPress = (lists: ListBySchoolResult) => {
        setSelectedListId(`${lists.id}`);
    };

    return (
        <Box className={classes.screen}>
            <Typography className={classes.sectionTitle}>
                Your School:
            </Typography>
            <FullWidthCard
                hasManualShadow
                noPadding
                className={classes.enrollFormCard}
                id="classroom-rewards-number-card"
            >
                <div
                    id="school-selection-search-result"
                    className={classes.schoolDetailWrapper}
                >
                    <Typography
                        id="school-selection-search-result-name-text"
                        className={classes.name}
                        component="div"
                    >
                        {selectedSchoolDetails.name}
                    </Typography>
                    <Typography
                        id="school-selection-search-result-address-street-text"
                        className={classes.address}
                        component="div"
                    >
                        {selectedSchoolDetails.address}
                    </Typography>
                    <Typography
                        id="school-selection-search-result-address-city-region-text"
                        className={classes.address}
                        component="div"
                    >
                        {selectedSchoolDetails.city}{' '}
                        {selectedSchoolDetails.state},{' '}
                        {selectedSchoolDetails.zip}
                    </Typography>
                </div>
            </FullWidthCard>
            <Typography className={classes.sectionTitle}>
                Available Lists:
            </Typography>
            <FullWidthCard
                hasManualShadow
                noPadding
                className={classes.enrollFormCard}
                id="classroom-rewards-number-card"
            >
                {isFetchingItems && (
                    <div className={classes.importListLoader}>
                        <CircularProgress
                            size="1.3rem"
                            style={{
                                color: COLORS.fordGray,
                                marginTop: '-17rem',
                            }}
                        />
                        <Typography className={classes.importListLoadingText}>
                            Importing List...
                        </Typography>
                    </div>
                )}
                <div className={classes.list}>
                    {isFetchingSchools && (
                        <div className={classes.loading}>
                            <CircularProgress
                                size="1rem"
                                style={{ color: COLORS.textBlack }}
                            />
                        </div>
                    )}
                    {listsBySchoolData &&
                        listsBySchoolData.map((s: any, index: any) => (
                            <ListsRow
                                index={index}
                                key={s.id}
                                listsBySchool={s}
                                onPress={onRowPress}
                            />
                        ))}
                </div>
            </FullWidthCard>
        </Box>
    );
}
