/* eslint-disable max-len */
import React from 'react';

function IconPasswordClose() {
    return (
        <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.5744 12.9733C5.52587 12.9733 5.47733 12.9633 5.42967 12.9425C3.1382 11.9325 1.27573 10.2792 0.0442 8.1625C-0.0147333 8.06167 -0.0147333 7.93833 0.0442 7.83667C2.14153 4.23667 6.10913 2 10.4 2C12.1368 2 13.8103 2.35583 15.3721 3.05833C15.5454 3.13667 15.6208 3.335 15.5393 3.50167C15.4579 3.66833 15.2525 3.74083 15.0783 3.6625C13.6084 3.00167 12.0345 2.6675 10.3991 2.6675C6.4246 2.6675 2.743 4.70667 0.745333 8.00083C1.90493 9.915 3.62007 11.4125 5.71827 12.3375C5.89247 12.4142 5.96873 12.6125 5.889 12.7792C5.83093 12.9017 5.70527 12.9733 5.57353 12.9733H5.5744Z"
                fill="#999999"
            />
            <path
                d="M10.4 13.9998C9.22562 13.9998 8.07382 13.8357 6.97749 13.5115C6.79462 13.4573 6.69149 13.2707 6.74782 13.0948C6.80415 12.919 6.99742 12.8198 7.18115 12.874C8.21249 13.1782 9.29582 13.3323 10.4008 13.3323C14.3754 13.3323 18.0561 11.294 20.0546 7.99901C19.1516 6.51401 17.8871 5.25484 16.3869 4.34734C16.2248 4.24901 16.1763 4.04318 16.2777 3.88734C16.3791 3.73151 16.5932 3.68484 16.7552 3.78234C18.4071 4.78151 19.7903 6.18234 20.7558 7.83484C20.8147 7.93568 20.8147 8.05984 20.7558 8.16068C18.6584 11.7615 14.6908 13.9982 10.4 13.9982V13.9998Z"
                fill="#999999"
            />
            <path
                d="M2.42674 16C2.33834 16 2.24908 15.9675 2.18148 15.9025C2.04628 15.7725 2.04628 15.5617 2.18148 15.4308L18.1281 0.0975C18.2633 -0.0325 18.4835 -0.0325 18.6187 0.0975C18.7539 0.2275 18.7539 0.438333 18.6187 0.569167L2.67201 15.9025C2.60441 15.9675 2.51601 16 2.42674 16Z"
                fill="#999999"
            />
            <path
                d="M8.19497 10.4532C8.10657 10.4532 8.01904 10.4215 7.95144 10.3573C7.29451 9.734 6.93311 8.8965 6.93311 7.99984C6.93311 6.1615 8.48791 4.6665 10.3998 4.6665C11.3323 4.6665 12.2024 5.014 12.8507 5.64567C12.985 5.7765 12.9842 5.98734 12.8472 6.11734C12.7112 6.2465 12.4919 6.24567 12.3567 6.114C11.8393 5.61067 11.1442 5.33317 10.3989 5.33317C8.87011 5.33317 7.62557 6.529 7.62557 7.99984C7.62557 8.7165 7.91417 9.38567 8.43764 9.88317C8.57371 10.0123 8.57544 10.224 8.44111 10.3548C8.37351 10.4207 8.28424 10.454 8.19411 10.454L8.19497 10.4532Z"
                fill="#999999"
            />
            <path
                d="M10.4001 11.3335C10.1618 11.3335 9.933 11.3118 9.70073 11.2668C9.51267 11.231 9.39133 11.0552 9.4286 10.8743C9.46587 10.6935 9.64787 10.5768 9.8368 10.6127C10.0231 10.6485 10.2077 10.666 10.4001 10.666C11.9289 10.666 13.1735 9.47017 13.1735 7.99934C13.1735 7.816 13.1553 7.63934 13.118 7.45767C13.0807 7.27684 13.2029 7.10184 13.391 7.066C13.5791 7.03017 13.7611 7.14767 13.7983 7.32767C13.8451 7.55267 13.8668 7.77184 13.8668 7.99934C13.8668 9.83767 12.312 11.3327 10.4001 11.3327V11.3335Z"
                fill="#999999"
            />
        </svg>
    );
}

export default IconPasswordClose;
