import React, { useState, useEffect } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { optimoveEvents } from 'src/screens/optimove';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { HandleCloseFn } from 'src/components/error-dialog';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import {
    ErrorSource,
    ErrorType,
    OnErrorFn,
    RewardsControllerState,
    UpdateControllerValuesFn,
} from 'src/screens/rewards-enroll-flow/types';
import RewardsEnrollErrorDialog from 'src/screens/rewards-enroll-flow/rewards-enroll-error-dialog';
import Steps from 'src/screens/rewards-enroll-flow/steps';
import Log from 'src/lib/logging';
import ROUTES from 'src/lib/routes';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';

type ExpectedQuery = RewardsControllerState;

const SlideTransition = (props: any) => <Slide direction="left" {...props} />;
const SLIDE_TRANSITION_DURATION = 300;

function RewardsFlowController() {
    const { query } = useRouter();
    const {
        enrollStep,
        accountIdentifier,
        maskedCredential,
        userType,
        enrollSubStep,
        flowName,
        ...rest
    } = query as ExpectedQuery;

    const { analyticsCustomEvent } = useEventsReporter();
    const [previousStep, setPreviousStep] = useState(enrollStep);
    const [isFinished, setIsFinished] = useState(false);
    const [inProgress, setInProgress] = useState(
        enrollStep !== undefined && !isFinished
    );
    const [transitionDuration, setTransitionDuration] = useState(0);
    useEffect(() => {
        if (enrollStep !== previousStep) {
            setTransitionDuration(SLIDE_TRANSITION_DURATION);
            setInProgress(enrollStep !== undefined && !isFinished);
            setPreviousStep(enrollStep);
        }
    }, [enrollStep, previousStep, isFinished]);

    const { history, pathname } = useRouter();

    const [showError, setShowError] = useState(false);

    const [errorType, setErrorType] = useState<ErrorType>('match-not-found');

    const [adminSchoolName, setAdminSchoolName] = useState('');
    const onError: OnErrorFn = (
        errorSource: ErrorSource,
        newErrorType: ErrorType,
        errorDetail = ''
    ) => {
        Log.log(`error from ${errorSource}: ${newErrorType}  ${errorDetail}`);
        setErrorType(newErrorType);
        setAdminSchoolName(errorDetail);
        setShowError(true);
    };

    const handleCloseErrorDialog: HandleCloseFn = () => {
        setShowError(false);
    };

    const { notifyUserUpdate, classroomWalletRewardsTabWithoutRewardsScreen } =
        useUserUpdateContext();
    const updateControllerValues: UpdateControllerValuesFn = (newValues) => {
        if (newValues.enrollStep === 'success') {
            // refresh user state as soon as we go to the success screen
            // to give more time to load the new user data behind the scenes
            notifyUserUpdate();
        }
        history.push({
            pathname,
            search: makeSearchString({
                enrollStep,
                enrollSubStep,
                flowName,
                ...newValues,
                userType: userType || undefined,
            }),
        });
    };

    const finishFlow = () => {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards_enroll, {
            click_text: 'go to classroom rewards',
            element_location: 'account creation',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards_enroll, {
            click_text: 'go to classroom rewards',
            element_location: 'account creation',
        });
        setIsFinished(true);
        if (pathname === ROUTES.rewardsWallet) {
            classroomWalletRewardsTabWithoutRewardsScreen();
            history.push({
                pathname: ROUTES.rewardsWallet,
                search: '',
            });
        } else {
            history.push({
                pathname: ROUTES.rewards,
                search: '',
            });
        }
    };

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.REWARDS_ENROLLMENT_FLOW,
        inProgress
    );

    return (
        <Dialog
            fullScreen
            open={isActiveScreen}
            transitionDuration={transitionDuration}
            TransitionComponent={SlideTransition}
            TransitionProps={{ in: isActiveScreen }}
            onExited={() => setIsFinished(false)}
        >
            <Steps
                enrollSubStep={enrollSubStep}
                enrollStep={enrollStep}
                isFinished={isFinished}
                userType={userType}
                maskedCredential={maskedCredential}
                accountIdentifier={accountIdentifier}
                finishFlow={finishFlow}
                updateControllerValues={updateControllerValues}
                onError={onError}
                flowName={flowName}
                type={rest.type}
            />
            <RewardsEnrollErrorDialog
                open={showError}
                errorType={errorType}
                schoolName={adminSchoolName}
                handleClose={handleCloseErrorDialog}
            />
        </Dialog>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedRewardsController = () => <RewardsFlowController />;
export default WrappedRewardsController;
