import React from 'react';
import PaginationDots from '../pagination-dots';
import PaginationNumbers from './PaginationNumbers';

type PaginationProps = {
    dots: number;
    index: number;
    activeColor: string;
    dotSizeStyle?: any;
    type?: 'dot' | 'number';
    onChangeIndex: any;
};

const CarouselPagination = ({
    dots,
    dotSizeStyle,
    index,
    activeColor,
    onChangeIndex,
    type,
}: PaginationProps) =>
    type === 'dot' ? (
        <PaginationDots
            dots={dots}
            index={index}
            activeColor={activeColor}
            dotSizeStyle={dotSizeStyle}
            onChangeIndex={onChangeIndex}
        />
    ) : (
        <PaginationNumbers
            totalCount={dots}
            index={Math.floor(index)}
            onChangeIndex={onChangeIndex}
        />
    );

export default CarouselPagination;
