/* eslint-disable import/prefer-default-export */
import ROUTES from '../routes';

export const setPreviousPath = (location?: any, fromPath?: any) => {
  // /* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
  if (location.pathname === ROUTES.productDetailsPage || location.pathname === ROUTES.previousOrders ) {
    return {
      from: fromPath,
    };
  }
  return {
    from: location.pathname,
  };
};

