/* eslint-disable max-len */
// /* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Button, Dialog, makeStyles } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import AppLink from 'src/components/link';
import COLORS from 'src/lib/colors';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import { isAccountSetupFinished } from 'src/lib/utils/account-utils';
import useUserInfo from 'src/hooks/useUserInfo';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { makeEnrollQuery } from 'src/lib/utils/user-utils';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';

// Change the promotion dates here
export const isPromotionRunning =
    new Date() >= new Date(2022, 5, 28) && new Date() < new Date(2022, 6, 28);
interface PromotionDetails {
    promotionImage: string;
    enrolled: {
        promotionTitle: string;
        promotionText: string;
        buttonText: string;
        cta: () => void;
        onClose: () => void;
    };
    unenrolled: {
        promotionTitle: string;
        promotionText: string;
        buttonText: string;
        cta: () => void;
        onClose: () => void;
    };
}

const useStyles = makeStyles({
    promotionDialog: {
        '& .MuiPaper-rounded': {
            borderRadius: '15px',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 24px',
        alignItems: 'center',
    },
    promotionTitle: {
        padding: '0 24px',
        margin: 0,
        marginBottom: '0.5rem',
        fontSize: 17,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    promotionText: {
        margin: 0,
        marginBottom: '1rem',
        fontSize: 14,
        textAlign: 'center',
    },
    promotionImage: {
        maxWidth: '274px',
        borderRadius: 15,
    },
    enrollButton: {
        borderRadius: '1.5rem',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '25rem',
        textTransform: 'none',
        fontSize: '1.2rem',
        background: COLORS.brandRed,
        color: COLORS.primaryWhite,
        marginBottom: '0.5rem',
        '&:hover': {
            backgroundColor: COLORS.darkRed,
        },
        '&:active': {
            backgroundColor: COLORS.darkRed,
        },
    },
    tcButton: {
        fontWeight: 'bold',
        // marginRight: GUTTER_WIDTH,
        color: COLORS.brandCyan,
        cursor: 'pointer',
        fontSize: '1rem',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
    },
    closeIcon: {
        minWidth: 'unset',
        alignSelf: 'flex-end',
    },
});
const termsAndConditionsURL = 'https://www.staplesconnect.com/terms-conditions';
const PromotionDialog = () => {
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const [promotionDialogOpen, setPromotionDialogOpen] = useState(false);
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const [userType, setUserType] = useState<'enrolled' | 'unenrolled'>(
        'unenrolled'
    );
    const router = useRouter();

    const [lastPromoTimestamp, setLastPromoTimestamp] = useLocalStorage(
        'last_promo_display',
        new Date()
    );

    useEffect(() => {
        if (
            isPromotionRunning &&
            router.pathname === '/home' &&
            router.history.location?.search === '' &&
            new Date() >= new Date(lastPromoTimestamp)
        ) {
            if (isAccountSetupFinished(userInfo)) {
                setUserType('enrolled');
            } else {
                setUserType('unenrolled');
            }
            setPromotionDialogOpen(true);
        }
    }, []);

    const promotionDetails: PromotionDetails = {
        promotionImage:
            'https://de-app.staplesconnect.com/images/Home/TeacherPromo.png',
        enrolled: {
            promotionTitle: '10% back in Classroom Rewards!',
            promotionText:
                'Support your local schools every time you shop. Now thru 9/24, earn 10% back in Classroom Rewards to give to a teacher or school. Teachers, earn 10% back too! ',
            buttonText: 'Donate now',
            cta: () => {
                const newPromoTimestamp = new Date();
                newPromoTimestamp.setFullYear(
                    newPromoTimestamp.getFullYear() + 10
                );
                setLastPromoTimestamp(newPromoTimestamp);
                setPromotionDialogOpen(false);
                router.history.push(ROUTES.rewards);
            },
            onClose: () => {
                const newPromoTimestamp = new Date();
                newPromoTimestamp.setHours(newPromoTimestamp.getHours() + 1);
                setLastPromoTimestamp(newPromoTimestamp);
                setPromotionDialogOpen(false);
            },
        },
        unenrolled: {
            promotionTitle: 'Join now to get 20% back to donate',
            promotionText:
                'Support your local schools every time you shop. Now thru 9/24, earn 10% back in Classroom Rewards to give to a teacher or school. Teachers, earn 10% back too!',
            buttonText: 'Enroll now',
            cta: () => {
                const newPromoTimestamp = new Date();
                newPromoTimestamp.setFullYear(
                    newPromoTimestamp.getFullYear() + 10
                );
                setLastPromoTimestamp(newPromoTimestamp);
                setPromotionDialogOpen(false);
                router.history.push({
                    pathname: router.pathname,
                    search: makeSearchString(
                        makeEnrollQuery(
                            userInfo?.userType,
                            userInfo?.userConfirmState
                        )
                    ),
                });
            },
            onClose: () => {
                const newPromoTimestamp = new Date();
                newPromoTimestamp.setHours(newPromoTimestamp.getHours() + 1);
                setLastPromoTimestamp(newPromoTimestamp);
                setPromotionDialogOpen(false);
            },
        },
    };

    const selectedPromotionDetails = promotionDetails[userType];

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.CUSTOM_PROMOTION,
        promotionDialogOpen
    );

    return (
        <Dialog
            className={classes.promotionDialog}
            onClose={selectedPromotionDetails.onClose}
            open={isActiveScreen}
        >
            <Button
                onClick={selectedPromotionDetails.onClose}
                className={classes.closeIcon}
                id="close-button"
            >
                <img
                    src={require('../assets/icons/iconCloseBlack.svg')}
                    alt="close icon"
                />
            </Button>
            <h4 className={classes.promotionTitle}>
                {selectedPromotionDetails.promotionTitle}
            </h4>
            <div className={classes.content}>
                <p className={classes.promotionText}>
                    {selectedPromotionDetails.promotionText}
                </p>
                <div>
                    <img
                        className={classes.promotionImage}
                        src={promotionDetails?.promotionImage}
                        alt="classroom-rewards-promotion"
                    />
                </div>
            </div>
            <div className={classes.dialogActions}>
                <Button
                    className={classes.enrollButton}
                    onClick={selectedPromotionDetails.cta}
                >
                    {selectedPromotionDetails.buttonText}
                </Button>
                <AppLink
                    id="user-profile-terms-and-conditions-button"
                    className={classes.tcButton}
                    href={termsAndConditionsURL}
                    target="_blank"
                    onClick={() => {
                        analyticsCustomEvent(eventMetricsMap.account_info, {
                            click_text: 'terms & conditions',
                            element_location: 'user profile',
                        });
                        analyticsCustomEvent(eventMetricsMap.outbound, {
                            click_text: 'Terms & conditions',
                            link_domain: 'staplesconnect.com',
                            link_url: termsAndConditionsURL,
                        });
                        optimoveEvents(eventMetricsMap.scm_account_info, {
                            click_text: 'terms & conditions',
                            element_location: 'user profile',
                        });
                        optimoveEvents(eventMetricsMap.scm_outbound, {
                            click_text: 'Terms & conditions',
                            link_domain: 'staplesconnect.com',
                            link_url: termsAndConditionsURL,
                        });
                    }}
                >
                    Terms & Conditions apply
                </AppLink>
            </div>
        </Dialog>
    );
};

export default PromotionDialog;
