import React from 'react';
import {
    Typography,
    makeStyles,
    Collapse,
    ButtonBase,
    Box,
    CircularProgress,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import FavoriteButton from 'src/components/favorites-button';
import {
    CommonStepProps,
    DonateControllerState,
} from 'src/screens/donation-flow/types';
import useSchoolSearch from 'src/hooks/useSchoolSearch';
import { SchoolResult } from 'src/services/types';
import useRouter from 'src/hooks/useRouter';

const useStyles = makeStyles({
    mainCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
    },
    schoolSearchTopSection: {
        padding: '1rem 1.25rem',
        borderBottom: `1px solid ${COLORS.secondaryNeutralLightGray}`,
    },
    schoolSearchTitle: {
        fontWeight: 500,
        fontSize: '1rem',
        textAlign: 'center',
        padding: '.75rem 2.75rem 0rem',
    },
    schoolSearchDescription: {
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 'bold',
        padding: '.5rem 2.75rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    schoolSearchDescriptionDetails: {
        textAlign: 'center',
        fontSize: '0.75rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '-1rem',
        height: 'auto',
        overflowY: 'scroll',
    },
    schoolSearchLoading: {
        padding: '1.75rem 2.75rem 2rem',
        display: 'flex',
        justifyContent: 'center',
    },
    schoolSearchNoResults: {
        fontWeight: 500,
        fontSize: '1rem',
        textAlign: 'center',
        padding: '1.75rem 2.75rem 2rem',
    },
});

const useSchoolRowStyles = makeStyles({
    schoolRow: {
        borderBottom: `1px solid ${COLORS.secondaryNeutralLightGray}`,
        marginLeft: '1rem',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    buttonRow: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        padding: '1rem 1rem 1rem 0',
        minHeight: '3.5rem',
    },
    leftWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        textAlign: 'left',
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        display: 'flex',
        flexGrow: 1,
    },
    address: {
        fontSize: '1.1rem',
        display: 'flex',
        flexGrow: 1,
    },
    iconWrapper: {},
    icon: {
        width: '1.7rem',
        height: '1.7rem',
    },
});

type SchoolRowProps = {
    index: number;
    school: SchoolResult;
    onPress: (selectedSchool: SchoolResult) => void;
};

/** School selection row when donating to a school or one of their teachers */
function SchoolRow({ school, onPress, index }: SchoolRowProps) {
    const classes = useSchoolRowStyles();
    const { analyticsCustomEvent } = useEventsReporter();
    function handlePress() {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: 'select school',
            element_location: 'search',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: 'search performed',
            element_location: 'search',
        });
        onPress(school);
    }

    return (
        <div className={classes.schoolRow}>
            <ButtonBase
                id={`donation-school-search-result-${index}`}
                className={classes.buttonRow}
                onClick={handlePress}
            >
                <div className={classes.leftWrapper}>
                    <Typography
                        id={`donation-school-search-result-${index}-name-text`}
                        className={classes.name}
                    >
                        {school.name}
                    </Typography>
                    <Typography
                        id={`donation-school-search-result-${index}-street-address-text`}
                        className={classes.address}
                    >
                        {school.street}
                    </Typography>
                    <Typography
                        id={`donation-school-search-result-${index}-city-state-zip-text`}
                        className={classes.address}
                    >
                        {school.city}, {school.state}, {school.zip}
                    </Typography>
                </div>
                <div className={classes.iconWrapper}>
                    <img
                        className={classes.icon}
                        src={require('../../assets/icons/iconChevron.svg')}
                        alt="right chevron"
                    />
                </div>
            </ButtonBase>
            <FavoriteButton favoriteId={school.id} index={index} />
        </div>
    );
}

export function DonationSearchResults({
    updateControllerValues,
}: CommonStepProps) {
    const classes = useStyles();
    const { query } = useRouter();
    const { schoolSearch } = query as DonateControllerState;

    const { isFetching, schools } = useSchoolSearch({
        partialAddress: schoolSearch,
    });

    const onRowPress = (school: SchoolResult) => {
        updateControllerValues({
            donateStep: 'selectRecipient',
            schoolId: school?.id,
            schoolName: school?.name,
        });
    };

    return (
        <>
            <FullWidthCard
                hasManualShadow
                noPadding
                className={classes.mainCard}
                id="donation-flow-search-results-card"
            >
                <Box className={classes.schoolSearchTopSection}>
                    <Typography
                        className={classes.schoolSearchTitle}
                        id="donation-flow-search-results-title-text"
                    >
                        {isFetching
                            ? 'Searching for:'
                            : `Showing ${schools.length} results for:`}
                    </Typography>
                    <Typography
                        className={classes.schoolSearchDescription}
                        id="donation-flow-search-results-search-query-text"
                    >
                        {schoolSearch}
                    </Typography>
                    <Typography
                        className={classes.schoolSearchDescriptionDetails}
                        id="donation-flow-search-results-search-query-text-details"
                    >
                        Not finding the school you’re looking for? Try searching
                        by the city, state the schools is located in or the
                        school name.
                    </Typography>
                </Box>
                <Collapse in={isFetching}>
                    <Box className={classes.schoolSearchLoading}>
                        <CircularProgress />
                    </Box>
                </Collapse>
                <Collapse in={!isFetching}>
                    <Collapse in={schools.length > 0}>
                        <div className={classes.list}>
                            {schools.map((s, index) => (
                                <SchoolRow
                                    index={index}
                                    key={s.id}
                                    school={s}
                                    onPress={onRowPress}
                                />
                            ))}
                        </div>
                    </Collapse>
                    <Collapse in={schools.length === 0}>
                        <Typography className={classes.schoolSearchNoResults}>
                            No results. Please go back and try another search.
                        </Typography>
                    </Collapse>
                </Collapse>
            </FullWidthCard>
        </>
    );
}

export default DonationSearchResults;
