/** Contains theme and other shared constants */
import { createTheme } from '@material-ui/core/styles';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';
import COLORS from 'src/lib/colors';

/** fixes a weird rendering bug where the letter 't' merges into certain letters */
const textRendering: TypographyStyleOptions = {
    textRendering: 'optimizeSpeed',
};
// Create a theme instance.
const theme = createTheme({
    palette: {
        type: 'light',
        background: {
            default: COLORS.whiteSmoke,
        },
    },
    overrides: {
        MuiButtonBase: {
            root: {
                textTransform: 'none',
                transition: 'color .01s',
                fontFamily: ['StaplesNormsRegular'].join(','),
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                transition: 'color .01s',
                fontFamily: ['StaplesNormsRegular'].join(','),
            },
            // TODO: STP-430 move more button style declarations into theme
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: COLORS.secondaryNeutralLightGray,
            },
        },
    },
    typography: {
        fontFamily: ['StaplesNormsRegular'].join(','),
        // fixes a rendering issue in safari iOS
        // we need to override every typography type...
        h1: textRendering,
        h2: textRendering,
        h3: textRendering,
        h4: textRendering,
        h5: textRendering,
        h6: textRendering,
        body1: textRendering,
        body2: textRendering,
        subtitle1: textRendering,
        subtitle2: textRendering,
        button: textRendering,
        caption: textRendering,
        overline: textRendering,
    },
});

/** The space between the edges of the screen and the sides of cards */
export const GUTTER_WIDTH = '2rem';
export const GUTTER_WIDTH_SLIM = '1.5rem';

export default theme;
