/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    ButtonBase,
    makeStyles,
    Typography,
} from '@material-ui/core';
import axios, { AxiosInstance } from 'axios';
import COLORS from 'src/lib/colors';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import LoadingButton from 'src/components/loading-button';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { eventMetricsMap, useEventsReporter, useUserState } from 'src/hooks';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as yup from 'yup';
import { getStplSessionIdUniqueId } from 'src/lib/utils/app-utils';
import { LOGIN_ROUTES } from 'src/routers/routes';
import { sendEmailToUser } from './pwd-reset';
import FullWidthCard from '../cards/full-width-card';
import LoadingOverlay from '../loading-overlay';
import NavTop from '../nav-top';
import Captcha from './captcha';
import styles from './styles.module.scss';
import { Body, Heading } from '../UI';

const useStyles = makeStyles({
    screenload: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        '& #pwd-screen': {
            display: 'flex',
            alignItems: 'baseline',
            borderRadius: 0,
            height: '17px',
        },
    },
    createAccountButton: {
        borderRadius: '100px',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        height: '50px',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
        fontWeight: 500,
    },
    headerSubTitleFound: {
        width: '242px',
        margin: '15px 28px 15px 28px',
        color: COLORS.textBlack,
        fontSize: '17px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
    headerTitle: {
        color: COLORS.textBlack,
        lineHeight: '32px',
        marginBottom: '40px',
    },
    textFieldLabel: {
        '& label': {
            textAlign: 'center',
            top: 'unset',
            bottom: '2rem',
        },
        '& label.Mui-focused': {
            width: '150%',
            textAlign: 'left',
        },
        marginBottom: '20px',
    },
    textFieldLabelError: {
        '& label': {
            bottom: '3.4rem',
        },
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },

    backToLogin: {
        fontSize: '14px',
        margin: '1.7rem 0 1rem 0',
        color: COLORS.brandCyan,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    headerErrorText: {
        width: '70%',
        margin: '15px',
        color: COLORS.whiteSmoke,
        fontSize: '17px',
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'center',
    },
});

const validationSchemaFields = yup.object({
    email: yup
        .string()
        .required('Email is required')
        // eslint-disable-next-line
        .email('Enter a valid email'),
});
const ForgotPassword = ({ showInModal, setNextScreen }: any) => {
    const classes = useStyles();
    const { history, push } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [failedStatus, setFailedStatus] = useState(false);
    const [fieldFocus, setfieldFocus] = useState('');

    const [captchaHtml, setCaptchaHtml] = useState('');
    const [nuSessionId] = useState(getStplSessionIdUniqueId());
    const [userState, setUserState] = useUserState();
    const emailId = localStorage.getItem('pwdEmail');
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();

    useEffect(() => {
        analyticsCustomEvent(eventMetricsMap.screen_view, {
            firebase_screen: `login_reset password`,
            firebase_screen_class: `login_reset password`,
        });
    }, []);

    const sendMail = async (values: any) => {
        setFailedStatus(false);
        const stplSessionId = getStplSessionIdUniqueId();
        setIsLoading(true);
        SessionManager.userName = values.email;
        SessionManager.isForgotPassword = true;

        const nPayload = (
            document?.getElementsByName('nds-pmd')[0] as HTMLInputElement
        )?.value;

        const nuCaptchaAnswer = (document.getElementById(
            'nucaptcha-answer'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-answer') as HTMLInputElement)
                .value
            : '';
        const nuCaptchaToken = (document.getElementById(
            'nucaptcha-token'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-token') as HTMLInputElement)
                .value
            : '';

        analyticsCustomEvent(eventMetricsMap.password_reset, {
            click_text: 'send',
            element_location: 'account password reset',
        });

        const { status, message } = await sendEmailToUser(
            values.email,
            stplSessionId,
            nPayload,
            nuCaptchaAnswer,
            nuCaptchaToken
        );
        const captchaInRes =
            message?.body?.captchaResponse?.captchaResponseHtml;
        if (captchaInRes) {
            setCaptchaHtml(captchaInRes);
        }
        setIsLoading(false);
        if (!status) {
            setIsLoading(false);
            setFailedStatus(true);
            return;
            // eslint-disable-next-line
        }
        setIsLoading(false);
        setFailedStatus(false);
        if (!captchaInRes) {
            if (!showInModal) {
                history.push(ROUTES.passwordResetEmail);
            } else {
                setNextScreen(ROUTES.passwordResetEmail);
            }
        }
    };
    const formikFieldValue = useFormik({
        initialValues: {
            email: emailId || '',
        },
        validationSchema: validationSchemaFields,
        validateOnMount: true,
        onSubmit: (values) => {
            sendMail(values);
            localStorage.setItem('pwdEmail', values.email);
        },
    });
    const handleOnBlur = (e: string) => {
        if (e) {
            formikFieldValue.setTouched({
                ...formikFieldValue.touched,
                [e]: true,
            });
        }
        setFailedStatus(false);
        setfieldFocus('');
    };
    const handleOnFocus = (e: string) => {
        if (e) {
            setfieldFocus(e);
            setFailedStatus(false);
        }
    };
    const { handleSubmit } = formikFieldValue;
    const goBack = () => {
        if (formikFieldValue.values.email) {
            setUserState({
                email: formikFieldValue.values.email,
            });
            if (!showInModal) {
                push(LOGIN_ROUTES.login);
            } else {
                setNextScreen(LOGIN_ROUTES.login);
            }
        } else if (emailId) {
            setUserState({ email: emailId });
            if (!showInModal) {
                push(LOGIN_ROUTES.login);
            } else {
                setNextScreen(LOGIN_ROUTES.login);
            }
        } else if (!showInModal) {
            push(LOGIN_ROUTES.login);
        } else {
            setNextScreen(LOGIN_ROUTES.login);
        }
    };
    const clearError = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        formikFieldValue.setFieldError(fieldName, '');
    };
    const emailInputHasError =
        formikFieldValue.touched.email &&
        Boolean(formikFieldValue.errors.email);
    return (
        <div
            style={{ height: showInModal ? '85vh' : '100vh' }}
            className={styles.container}
        >
            {isLoading ? (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            ) : null}
            <ButtonBase
                tabIndex={5}
                role="button"
                aria-label="Go back"
                onClick={() => goBack()}
            >
                <img
                    className={styles.backIcon}
                    src={require('src/assets/icons/iconBackArrow.svg')}
                    alt="iconBack"
                />
            </ButtonBase>

            <Heading
                type="h2"
                weight="bold"
                size={26}
                lineHeight={32}
                className={classes.headerTitle}
            >
                Reset your password
            </Heading>

            <div className={classes.contentContainer} id="content-container">
                <form onSubmit={handleSubmit} className={classes.form}>
                    <div className={styles.fieldContainer}>
                        <label htmlFor="email" className={styles.labelAlign}>
                            Enter email for password reset instructions
                        </label>
                        <input
                            type="email"
                            aria-required
                            aria-label="Email"
                            id="email"
                            value={formikFieldValue.values.email}
                            onChange={(e) => {
                                formikFieldValue.handleChange(e);
                            }}
                            onBlur={() => handleOnBlur('email')}
                            onFocus={() => handleOnFocus('email')}
                            style={{
                                border: emailInputHasError
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: emailInputHasError
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            className={
                                fieldFocus === 'email'
                                    ? styles.inputFieldActive
                                    : styles.inputFieldAlign
                            }
                            required
                        />

                        {emailInputHasError ? (
                            <div
                                style={{
                                    color: COLORS.brandRed,
                                    marginTop: '10px',
                                }}
                            >
                                {formikFieldValue.errors.email}
                            </div>
                        ) : null}

                        {failedStatus && (
                            <Body
                                size={12}
                                lineHeight={16}
                                color="$red"
                                weight="light"
                                className={styles.errorAlign}
                            >
                                We were unable to send the password reset email.
                                Please make sure you have entered a valid email
                                address.
                            </Body>
                        )}
                    </div>
                    <Captcha
                        capthaHtml={captchaHtml}
                        sessionId={getStplSessionIdUniqueId()}
                        onCaptchaSumbit={handleSubmit}
                        placement="PasswordResetWebSC"
                    />
                    <LoadingButton
                        tabIndex={3}
                        aria-selected
                        aria-label="Send"
                        fullWidth
                        id="step2-next-button"
                        variant="contained"
                        classes={{
                            root: classes.createAccountButton,
                        }}
                        type="submit"
                        loading={false}
                        disabled={
                            formikFieldValue.errors &&
                            Object.keys(formikFieldValue.errors).length !== 0
                        }
                    >
                        Send
                    </LoadingButton>
                </form>
            </div>
        </div>
    );
};
export default ForgotPassword;
