import React, { useState, useEffect } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { HandleCloseFn } from 'src/components/error-dialog';
import {
    ErrorSource,
    ErrorType,
    OnErrorFn,
    ClaimSchoolsControllerState,
    UpdateControllerValuesFn,
} from 'src/screens/claim-schools-flow/types';
import RewardsEnrollErrorDialog from 'src/screens/rewards-enroll-flow/rewards-enroll-error-dialog';
import Steps from 'src/screens/claim-schools-flow/steps';
import Log from 'src/lib/logging';
import ROUTES from 'src/lib/routes';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';

type ExpectedQuery = ClaimSchoolsControllerState;

const SlideTransition = (props: any) => <Slide direction="left" {...props} />;
const SLIDE_TRANSITION_DURATION = 300;

function ConfirmSchoolController() {
    const { query } = useRouter();
    const { claimSchoolStep, isSchoolSaved, enrollSubStep } =
        query as ExpectedQuery;
    const [previousStep, setPreviousStep] = useState(claimSchoolStep);
    const [isFinished, setIsFinished] = useState(false);
    const [inProgress, setInProgress] = useState(
        claimSchoolStep !== undefined && !isFinished
    );
    const [transitionDuration, setTransitionDuration] = useState(0);
    useEffect(() => {
        if (claimSchoolStep !== previousStep) {
            setTransitionDuration(SLIDE_TRANSITION_DURATION);
            setInProgress(claimSchoolStep !== undefined && !isFinished);
            setPreviousStep(claimSchoolStep);
        }
    }, [claimSchoolStep, previousStep, isFinished]);

    const { history, pathname } = useRouter();

    const [showError, setShowError] = useState(false);

    const [errorType, setErrorType] = useState<ErrorType>('match-not-found');

    const [adminSchoolName, setAdminSchoolName] = useState('');
    const onError: OnErrorFn = (
        errorSource: ErrorSource,
        newErrorType: ErrorType,
        errorDetail = ''
    ) => {
        Log.log(`error from ${errorSource}: ${newErrorType}  ${errorDetail}`);
        setErrorType(newErrorType);
        setAdminSchoolName(errorDetail);
        setShowError(true);
    };

    const handleCloseErrorDialog: HandleCloseFn = () => {
        setShowError(false);

        if (errorType === 'min-schools') {
            history.push({
                pathname,
                search: makeSearchString({
                    claimSchoolStep: 'selectSchool',
                    isSchoolSaved: 'false',
                }),
            });
        }
    };

    const updateControllerValues: UpdateControllerValuesFn = (newValues) => {
        history.push({
            pathname,
            search: makeSearchString({
                claimSchoolStep,
                isSchoolSaved,
                ...newValues,
            }),
        });
    };

    const finishFlow = () => {
        setIsFinished(true);
        history.push({
            pathname: ROUTES.rewards,
            search: '',
        });
    };

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.CLAIM_SCHOOL,
        inProgress
    );

    return (
        <Dialog
            fullScreen
            open={isActiveScreen}
            transitionDuration={transitionDuration}
            TransitionComponent={SlideTransition}
            TransitionProps={{ in: isActiveScreen }}
            onExited={() => setIsFinished(false)}
        >
            <Steps
                enrollSubStep={enrollSubStep}
                claimSchoolStep={claimSchoolStep}
                isFinished={isFinished}
                finishFlow={finishFlow}
                updateControllerValues={updateControllerValues}
                onError={onError}
            />
            <RewardsEnrollErrorDialog
                open={showError}
                errorType={errorType}
                schoolName={adminSchoolName}
                handleClose={handleCloseErrorDialog}
            />
        </Dialog>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedConfirmSchoolController = () => <ConfirmSchoolController />;
export default WrappedConfirmSchoolController;
