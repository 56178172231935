import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  dynamicLinkSupportedPathsType,
  getDynamicLinkForPath,
} from 'src/lib/utils/dynamic-link-utils';
import { getOidcUserFromSessionStorageParsed } from 'src/lib/utils/oidc-utils';

function useDynamicLink(route: dynamicLinkSupportedPathsType) {
  const [dynamicLink, setDynamicLink] = useState('');
  const oidcUser = getOidcUserFromSessionStorageParsed();
  const oidcUserAccessToken = oidcUser?.access_token;
  const oidcUserRefreshToken = oidcUser?.refresh_token;
  const oidcUserExpiresAt = oidcUser?.expires_at;

  useEffect(() => {
    async function setupLink() {
      // if (oidcUserAccessToken) {
      const url = await getDynamicLinkForPath(route, {
        access_token: oidcUserAccessToken,
        refreshToken: oidcUserRefreshToken,
        expirationTime: oidcUserExpiresAt,
      });
      setDynamicLink(url);
      // }
    }
    setupLink();
  }, [oidcUserAccessToken, oidcUserRefreshToken, oidcUserExpiresAt, route]);

  const doDynamicLink = useCallback(async () => {
    // not using react router for external URL
    window.location.href = dynamicLink;
  }, [dynamicLink]);

  return useMemo(
    () => ({ doDynamicLink, dynamicLink }),
    [doDynamicLink, dynamicLink]
  );
}

export default useDynamicLink;
