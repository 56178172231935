import { useMemo } from 'react';
import { NO_REFETCH_FOCUS_CONNECT } from 'src/lib/utils/react-query-utils';
import { getListDetails } from 'src/lib/api-client/lists';
import { useQuery } from 'react-query';

export default function useItemFromList({
  listId,
  productId,
}: {
  listId: string;
  productId: any;
}) {
  const {
    data: listDetailsData,
    refetch: refetchListDetailsData,
    isFetching,
  } = useQuery(
    ['userListDetails', listId, 'user-list'],
    async () =>
      getListDetails({
        listId,
      }),
    {
      keepPreviousData: true,
      enabled: !!listId,
      ...NO_REFETCH_FOCUS_CONNECT,
    }
  );
  const itemFromList = useMemo(() => {
    if (listId && listDetailsData?.items?.length && productId) {
      return listDetailsData.items.find(
        (item) => item.itemNumber === productId
      );
    }
    return null;
  }, [listId, productId, listDetailsData]);

  return {
    refetchListDetailsData,
    isFetching,
    listDetailsData,
    itemFromList,
  };
}
