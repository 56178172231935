/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Typography, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import { useQuery } from 'react-query';
import {
    linkRewardsAccount,
    linkSRWAccount,
} from 'src/lib/api-client/rewards-enrollment';
import LoadingButton from 'src/components/loading-button';
import ValidationTextField from 'src/components/validation-text-field';
import { CommonStepProps } from 'src/screens/rewards-enroll-flow/types';
import { phoneNumberValidation } from 'src/lib/validations';
import useUserInfo from 'src/hooks/useUserInfo';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { useParams } from 'react-router';
import useError from './use-error';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '2.8rem 1.2rem 1.5rem',
    },
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        justifyContent: 'center',
        paddingBottom: '2rem',
        minHeight: '100vh',
    },
    backIcon: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: '1rem',
    },
    enrollFormCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
    },
    formDescription: {
        fontSize: '1rem',
        textAlign: 'center',
        marginTop: '0.2rem',
        padding: '0 0.6rem',
        lineHeight: '1.2rem',
        fontWeight: 500,
    },
    haveAccountText: {
        fontSize: '0.7rem',
        textAlign: 'center',
        padding: '0 0.6rem',
    },
    signUpText: {
        fontSize: '0.7rem',
        fontWeight: 600,
        color: COLORS.brandCyan,
    },
    nextButton: {
        textTransform: 'none',
        marginTop: '3rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
});

const validationSchemaEmail = yup.object({
    emailOrPhone: yup
        .string()
        .required('This field is required')
        .email('Enter a valid email'),
});

const validationSchemaPhoneNumber = yup.object({
    emailOrPhone: phoneNumberValidation.required('This field is required'),
});

type RewardsEnrollProps = CommonStepProps;

export function RewardsEnrollStep3({
    accountIdentifier,
    maskedCredential,
    type,
    userType,
    updateIsFetching,
    updateControllerValues,
    onError,
}: RewardsEnrollProps) {
    // eslint-disable-next-line no-console
    const [unmaskedCredential, setUnmaskedCredential] = useState('');
    const [phoneNbr, setPhoneNbr] = useState('');
    const [emailId, setEmailId] = useState('');
    const { userInfo } = useUserInfo();
    const { hadError, setHadError, showError } = useError(onError);
    const router = useRouter();
    const identifierType = router.query.type;
    const isRewardWallet = router.pathname === ROUTES.rewardsWallet;
    const isRewardEnrollFlowFromLogin = router.pathname === ROUTES.rewardEnroll;
    const [maskedType, setMaskedType] = useState<'phone' | 'email'>('phone');
    const rewardsNumber: any =
        identifierType === 'reward' ? accountIdentifier : null;
    const emailIdAdr = maskedType !== 'phone' ? emailId : accountIdentifier;
    const phoneNbrData: any =
        identifierType === 'phone'
            ? accountIdentifier
            : maskedType === 'phone'
            ? phoneNbr.replace(/[^\d]/g, '')
            : null;
    const { data, isFetching } =
        isRewardWallet || isRewardEnrollFlowFromLogin
            ? useQuery(
                  ['linkSRWAccount', rewardsNumber, emailIdAdr, phoneNbrData],
                  async () =>
                      linkSRWAccount(rewardsNumber, emailIdAdr, phoneNbrData),
                  {
                      // only call the api if we have an account credential to attempt
                      enabled: unmaskedCredential !== '' && !hadError,
                  }
              )
            : useQuery(
                  [
                      'linkRewardsAccount',
                      accountIdentifier,
                      unmaskedCredential,
                      userType,
                  ],
                  async () =>
                      linkRewardsAccount(
                          accountIdentifier,
                          unmaskedCredential,
                          userType
                      ),
                  {
                      // only call the api if we have an account credential to attempt
                      enabled: unmaskedCredential !== '' && !hadError,
                  }
              );
    useEffect(() => {
        if (updateIsFetching) {
            updateIsFetching(isFetching);
        }
    }, [isFetching]);

    const classes = useStyles();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function navigateToEnrollNextStep() {
        if (userType === 'Supporter') {
            updateControllerValues({ enrollStep: 'success' });
        } else if (isRewardWallet || isRewardEnrollFlowFromLogin) {
            updateControllerValues({ enrollStep: 'srwConfirmation' });
        } else {
            updateControllerValues({ enrollStep: 'selectSchool' });
        }
    }

    const [validationSchema, setValidationSchema] = useState(
        validationSchemaPhoneNumber
    );

    useEffect(() => {
        if (unmaskedCredential !== '' && !isFetching) {
            if (data) {
                const { isSuccess } = data;
                if (isSuccess) {
                    // once we have successfully submitted the form and moved to next screen
                    // set unmaskedCredential='' so the API doesn't get called again
                    setUnmaskedCredential('');
                    navigateToEnrollNextStep();
                } else {
                    setUnmaskedCredential('');
                    showError('step3', 'match-not-found', ''); // Could not find an account
                }
            } else {
                showError('step3', 'match-not-found', ''); // Loading finished with no data.
            }
        }
    }, [isFetching, unmaskedCredential, data]);

    const formik = useFormik({
        initialValues: {
            emailOrPhone: '',
        },
        validationSchema,
        onSubmit: (values) => {
            setHadError(false);
            // begin verification with endpoint if we are not already fetching
            let credential = values.emailOrPhone;
            if (credential.indexOf('@') < 0) {
                credential = credential.replaceAll('-', '');
            }
            setUnmaskedCredential(credential);
        },
    });
    useEffect(() => {
        if (maskedCredential && maskedCredential.indexOf('@') >= 0) {
            setValidationSchema(validationSchemaEmail);
        } else {
            setValidationSchema(validationSchemaPhoneNumber);
        }
    }, [maskedCredential]);

    const label = maskedType === 'phone' ? 'Enter phone number' : 'Enter email';
    const maskedTypeDescriptor =
        maskedType === 'phone' ? 'the phone number' : 'the email address';
    useEffect(() => {
        if (maskedCredential) {
            // when navigating between 2nd & 3rd screen and updating input values
            // maskedCredential is also changed, so reset the field
            formik.setFieldValue('emailOrPhone', '');

            if (maskedCredential.indexOf('@') > 0) {
                setMaskedType('email');
            } else {
                setMaskedType('phone');
            }
        } else {
            // once navigated to previous screen, reset the error and touch events
            // so when coming back to this screen no error displays by default
            formik.setErrors({ emailOrPhone: '' });
            formik.setFieldTouched('emailOrPhone', false);
        }
    }, [maskedCredential]);

    const clearError = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        formik.setFieldError(fieldName, '');
    };

    return (
        <FullWidthCard
            hasManualShadow
            className={classes.enrollFormCard}
            id="classroom-rewards-number-card"
        >
            <Typography
                className={classes.formDescription}
                id="user-type-title"
            >
                We found {maskedTypeDescriptor} {maskedCredential} associated
                with your account. Please enter it below to verify your
                identity.
            </Typography>
            <form onSubmit={formik.handleSubmit} className={classes.root}>
                {maskedType === 'phone' ? (
                    <InputMask
                        mask="999-999-9999"
                        onChange={(e) => {
                            formik.handleChange(e);
                            setPhoneNbr(e.target.value);
                        }}
                        name="emailOrPhone"
                        type="text"
                        onFocus={(e) => clearError(e)}
                        value={formik.values.emailOrPhone}
                    >
                        {
                            // This is input is for phone only. Ignore the misleading formik name "emailOrPhone"
                            () => (
                                <ValidationTextField
                                    fullWidth
                                    id="phoneNumber"
                                    name="emailOrPhone"
                                    inputMode="tel"
                                    type="phone"
                                    value={formik.values.emailOrPhone}
                                    error={
                                        formik.touched.emailOrPhone &&
                                        Boolean(formik.errors.emailOrPhone)
                                    }
                                    helperText={
                                        formik.touched.emailOrPhone &&
                                        formik.errors.emailOrPhone
                                    }
                                    label={label}
                                />
                            )
                        }
                    </InputMask>
                ) : (
                    <ValidationTextField
                        fullWidth
                        id="email-or-phone-input"
                        name="emailOrPhone"
                        label={label}
                        disabled={isFetching}
                        value={formik.values.emailOrPhone}
                        onFocus={(e) => clearError(e)}
                        onChange={(e) => {
                            formik.handleChange(e);
                            setEmailId(e.target.value);
                        }}
                        error={
                            formik.touched.emailOrPhone &&
                            Boolean(formik.errors.emailOrPhone)
                        }
                        helperText={
                            formik.touched.emailOrPhone &&
                            formik.errors.emailOrPhone
                        }
                        inputMode="email"
                        type="email"
                    />
                )}
                <LoadingButton
                    loading={isFetching}
                    fullWidth
                    id="next-button"
                    variant="contained"
                    classes={{
                        root: classes.nextButton,
                    }}
                    type="submit"
                >
                    Continue
                </LoadingButton>
            </form>
        </FullWidthCard>
    );
}

export default RewardsEnrollStep3;
