import { useState } from 'react';
import { useQuery } from 'react-query';
import { updateItem } from 'src/lib/api-client/lists';
import { useToastMessageContext } from 'src/lib/contexts/toast-message-context';
import { ListItemType, UpdateItemType } from 'src/services/types';

/**
 * Products/Items are modified in lists in numerous ways but have common behvior across them
 * This hook centralizes that behavior.
 */
const useUpdateItem = ({
    toastConfig,
    onError,
    onSuccess,
    onComplete,
}: {
    toastConfig?: { skip?: boolean; success?: string; error?: string };
    onError?: () => void;
    onSuccess?: () => void;
    onComplete?: () => void;
}) => {
    const { setToastMessage } = useToastMessageContext();
    const [committedToUpdate, setCommittedToUpdate] = useState(false);
    const [listItemId, setListItemId] = useState<ListItemType['id']>();
    const [fieldsToUpdate, setFieldsToUpdate] = useState<
        { key: UpdateItemType; value: string | number }[]
    >([]);
    function onUpdateComplete() {
        setCommittedToUpdate(false);
        setListItemId(undefined);
        setFieldsToUpdate([]);
        if (onComplete) {
            onComplete();
        }
    }

    function onUpdateSuccess() {
        if (!toastConfig?.skip) {
            setToastMessage({
                title: toastConfig?.success || 'List updated!',
            });
        }

        if (onSuccess) {
            onSuccess();
        }
        onUpdateComplete();
    }

    function onUpdateError() {
        if (!toastConfig?.skip) {
            setToastMessage({
                title: toastConfig?.error || 'Error updating list.',
            });
        }
        if (onError) {
            onError();
        }
        onUpdateComplete();
    }
    const queryProps = useQuery(
        ['updateItem', committedToUpdate],
        async () =>
            updateItem({
                itemId: listItemId ? listItemId.toString() : null,
                fields: fieldsToUpdate,
            }),
        {
            keepPreviousData: true,
            enabled: committedToUpdate && !!listItemId,
            onSuccess: onUpdateSuccess,
            onError: onUpdateError,
        }
    );

    function commitToUpdates(
        itemId: ListItemType['id'],
        fields: { key: UpdateItemType; value: string | number }[]
    ) {
        setListItemId(itemId);
        setFieldsToUpdate(fields);
        setCommittedToUpdate(true);
    }

    function commitToUpdate(
        itemId: ListItemType['id'],
        newValue: string | number,
        updateType: UpdateItemType
    ) {
        commitToUpdates(itemId, [{ key: updateType, value: newValue }]);
    }

    return {
        ...queryProps,
        commitToUpdate,
        commitToUpdates,
    };
};

export default useUpdateItem;
