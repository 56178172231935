/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { Favorites } from 'src/services/types';
import {
    rewardsGetFavoritesURL,
    rewardsPostFavoritesURL,
} from 'src/services/urls';

export type FavoritesResponseCode = 'Success' | 'InvalidIdentifier' | '';

export type FavoritesResponse = {
    isSuccess: boolean;
    code: FavoritesResponseCode;
};

export async function getFavorites(): Promise<Favorites> {
    const results = await axiosInstance.get(`${rewardsGetFavoritesURL}`);
    if (
        results.data &&
        typeof results.data.favoriteInstitutions &&
        typeof results.data.favoriteEducators
    ) {
        return results.data as Favorites;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when calling ${rewardsGetFavoritesURL}`
    );
    Log.axios(results);
    return { favoriteInstitutions: [], favoriteEducators: [] };
}

export async function addFavorite(
    // institutionId or userID ie  `classroomrewards:v1:disney-ii-magnet-elementary-school:chicago:il`
    favoriteId?: string | null
): Promise<null | FavoritesResponse> {
    const addFavoriteResp = await axiosInstance.post(
        `${rewardsPostFavoritesURL}`,
        { favoriteIdentifier: favoriteId }
    );
    if (addFavoriteResp.data) {
        return addFavoriteResp.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when donating via ${rewardsPostFavoritesURL}`
    );
    Log.axios(addFavoriteResp);
    return null;
}

export async function deleteFavorite(
    // institutionId or userID ie  `classroomrewards:v1:disney-ii-magnet-elementary-school:chicago:il`
    favoriteId?: string | null
): Promise<FavoritesResponse | null> {
    const deleteFavoriteResp = await axiosInstance.delete(
        `${rewardsPostFavoritesURL}/${favoriteId}`
    );
    if (deleteFavoriteResp.data) {
        return deleteFavoriteResp.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when deleting claimed school via ${rewardsPostFavoritesURL}/${favoriteId}`
    );
    Log.axios(deleteFavoriteResp);
    return null;
}
