/* eslint-disable import/prefer-default-export */
import {
  Log,
  Logger,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';
import log from 'src/lib/logging';
import { getOidcUserFromSessionStorageParsed } from '../utils/oidc-utils';

const loggerForUserManager: Logger = {
  debug: (message: any, ...params: any[]) => log.authInternal(message, params),
  error: (message: any, ...params: any[]) => log.auth(message, params),
  info: (message: any, ...params: any[]) => log.authInternal(message, params),
  warn: (message: any, ...params: any[]) => log.auth(message, params),
};

/** akamai requires custom claims for each user info field */
const claims =
  // eslint-disable-next-line max-len
  '{"userinfo":{"given_name":null,"address":null,"family_name":null,"middle_name":null,"preferred_username":null,"phone_number":null,"phone_number_verified":null,"email":null,"email_verified":null}}';

/**
 * Wrapper for oidc client.
 * You should not call this service directly
 * since we have a hook for better user state management
 * use use-auth-from-context whenever possible.
 */
export class AuthService {
  public userManager: UserManager;

  constructor(userManager?: UserManager | null) {
    const settings: UserManagerSettings = {
      authority: `${process.env.REACT_APP_AKAMAI_AUTHORITY_BASE_URL}/login`,
      client_id: process.env.REACT_APP_AKAMAI_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_AKAMAI_REDIRECT_URI,
      post_logout_redirect_uri:
        process.env.REACT_APP_AKAMAI_POST_LOGOUT_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid profile email address phone',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    this.userManager = userManager || new UserManager(settings);
    Log.logger = loggerForUserManager;
    Log.level = Log.DEBUG;
  }

  /** returns a user if we have an access token */
  // eslint-disable-next-line class-methods-use-this
  public getUser(): User | null {
    const user = getOidcUserFromSessionStorageParsed();
    if (user?.access_token) {
      return user;
    }
    return null;
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect({
      scope: 'openid profile email address phone',
      prompt: 'login',
      extraQueryParams: {
        claims,
      },
    });
  }

  public signup(): Promise<void> {
    return this.userManager.signinRedirect({
      scope: 'openid profile email address phone',
      prompt: 'create',
      extraQueryParams: {
        claims,
      },
    });
  }

  public handleRedirect(): Promise<any> {
    try {
      return this.userManager.signinRedirectCallback();
    } catch (e) {
      return Promise.resolve({ error: 'sign_in_failed', message: e });
    }
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.revokeAccessToken();
  }

  // TODO: determine if a more native profile UX can be used with akamai
  // for now just use the profile redirect hosted by akamai
  // eslint-disable-next-line class-methods-use-this
  public getProfileURL(): string {
    // eslint-disable-next-line max-len
    return `${process.env.REACT_APP_AKAMAI_AUTHORITY_BASE_URL}/auth-ui/profile?client_id=${process.env.REACT_APP_AKAMAI_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AKAMAI_REDIRECT_URI}`;
  }
}
