import React from 'react';
import { DialogContentText, makeStyles } from '@material-ui/core';
import ErrorDialog, { ErrorDialogProps } from 'src/components/error-dialog';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
  dialogContentText: {
    color: COLORS.secondaryNeutralCharcoal,
    textAlign: 'center',
    marginTop: '1rem',
    letterSpacing: '-0.5pt',
    lineHeight: '1.2rem',
  },
  iconStyle: {
    maxWidth: '200px',
    maxHeight: '200px',
  },
});

type GenericDeleteItemDialogProps = ErrorDialogProps & {
  title: string;
  topTitle?: string;
  iconSrc?: string | null | undefined;
};

export default function GenericDeleteItemDialog(
  props: GenericDeleteItemDialogProps
) {
  const classes = useStyles();
  const { title, topTitle = 'Remove item?', iconSrc, open, ...rest } = props;

  return (
    <ErrorDialog
      open={open}
      errorTitle={topTitle}
      primaryBtnLabel="Remove"
      primaryBtnId="remove-list-btn"
      secondaryBtnLabel="Keep"
      {...rest}
    >
      {iconSrc && (
        <img className={classes.iconStyle} src={iconSrc} alt="icon lists" />
      )}
      <DialogContentText
        id="remove-list-id"
        className={classes.dialogContentText}
      >
        {title}
      </DialogContentText>
    </ErrorDialog>
  );
}
