/* eslint-disable max-len */
import React from 'react';
import { DialogContentText, makeStyles, Typography } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import ErrorDialog, { ErrorDialogProps } from 'src/components/error-dialog';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import useRouter from 'src/hooks/useRouter';

type RewardsEnrollErrorDialogProps = ErrorDialogProps & {
    errorType:
    | 'match-not-found'
    | 'no-email'
    | 'school-claimed'
    | 'max-schools'
    | 'min-schools'
    | 'account-exists'
    | 'max-first-and-last-name'
    | 'max-first-name'
    | 'max-last-name'
    | 'no-phone'
    | 'no-phone-email'
    | 'unknown';
    schoolName: string;
};

const tryAgainTitle = "Let's try that again!";
const emailNotFoundTitle = 'Your account does not have an email on file.';
const noPhoneTitle = 'Your account does not have a phone number on file.';
const schoolClaimedTitle = 'Looks like your school is a popular choice';
const noPhoneEmailTitle =
    'Your account does not have a phone number and email address on file.';
const unknownTitle = 'Oops!';
const accountExistsTitle = 'Uh Oh!';
const maxSchoolsReached = 'Max Limit Reached!';
const minSchoolsReached = 'Minimum Required';
const visitStoreBtn = 'Visit store';
const tryAgainBtn = 'Try Again';

const useDefaultContentStyles = makeStyles({
    errorIcon: {
        width: '6.5rem',
    },
    imgWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '1rem',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        marginTop: '1rem',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
    },
});

function ErrorContent({
    errorDescription,
    errorType,
}: {
    errorDescription: string;
    errorType?: string;
}) {
    const classes = useDefaultContentStyles();
    let iconName = '';
    switch (errorType) {
        case 'no-phone-email':
            iconName = require('../../assets/icons/iconMissingEmail.svg');
            break;
        case 'no-email':
            iconName = require('../../assets/icons/iconMissingEmail.svg');
            break;
        case 'match-not-found':
            iconName = require('../../assets/icons/iconMissingUserError.svg');
            break;
        case 'no-phone':
            iconName = require('../../assets/icons/iconMissingPhone.svg');
            break;
        default:
            iconName = require('../../assets/icons/iconMissingUserError.svg');
            break;
    }
    return (
        <>
            {errorType === 'no-phone-email' ? (
                <div className={classes.imgWrapper}>
                    <img
                        className={classes.errorIcon}
                        src={require('../../assets/icons/iconMissingPhone.svg')}
                        alt="not found error icon"
                    />
                    <img
                        className={classes.errorIcon}
                        src={iconName}
                        alt="not found error icon"
                    />
                </div>
            ) : (
                <img
                    className={classes.errorIcon}
                    src={iconName}
                    alt="not found error icon"
                />
            )}

            <DialogContentText
                id="error-dialog-description"
                className={classes.dialogContentText}
            >
                {errorDescription}
            </DialogContentText>
        </>
    );
}

const useSchoolClaimedErrorContentStyles = makeStyles({
    wrapper: {},
    schoolName: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        marginTop: '1rem',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
        fontSize: '1.4rem',
        fontWeight: 'bold',
    },
    alreadyClaimed: {
        textAlign: 'center',
        marginTop: '.5rem',
        fontWeight: 500,
    },
    description: { textAlign: 'center', margin: '1.2rem .5rem' },
});

function SchoolClaimedErrorContent({ schoolName }: { schoolName: string }) {
    const classes = useSchoolClaimedErrorContentStyles();
    return (
        <div className={classes.wrapper}>
            <Typography
                id="error-dialog-school-name"
                className={classes.schoolName}
            >
                {schoolName}
            </Typography>
            <Typography
                id="error-dialog-already-claimed"
                className={classes.alreadyClaimed}
            >
                is already associated with a school adminstrator profile
            </Typography>
            <Typography
                id="error-dialog-description"
                className={classes.description}
            >
                Please select a different school or contact member services if
                this looks like an error.
            </Typography>
        </div>
    );
}
/**
 * Error dialog used for rewards enrollment.
 */
export default function RewardsEnrollErrorDialog(
    props: RewardsEnrollErrorDialogProps
) {
    const { open, handleClose, errorType, schoolName } = props;
    const memberServicesURL = 'https://www.staplesconnect.com/contact-us';
    const router = useRouter();

    let title;
    let currentErrorDialog;
    let hidePrimary;
    let hideSecondary;
    let secondaryBtnLabel;
    let primaryBtnLabel;
    let showRedPrimaryBtn;
    const handlePrimaryBtnClick = () => {
        handleClose()
        if (errorType === 'no-email' || errorType === 'no-phone' || errorType === 'no-phone-email') {
            openInNewTab(memberServicesURL);
        }
    }
    const handleSecondaryBtnClick = () => {
        handleClose()
        if (errorType === 'no-email' || errorType === 'no-phone' || errorType === 'no-phone-email') {
            router.push(`/stores`);
        }
        if (errorType === 'match-not-found' || errorType === 'account-exists') {
            openInNewTab(memberServicesURL);
        }
    }
    switch (errorType) {
        case 'school-claimed':
            currentErrorDialog = (
                <SchoolClaimedErrorContent schoolName={schoolName} />
            );
            title = schoolClaimedTitle;
            hidePrimary = true;
            break;
        case 'max-schools':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="Sorry! It looks like you've reached the 5 schools association limit.
      Please review your selected schools before moving on to finish your
      sign-up."
                />
            );
            title = maxSchoolsReached;
            hidePrimary = true;
            hideSecondary = true;
            break;
        case 'min-schools':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="Please associate yourself with at least one school to continue as an
      educator."
                />
            );
            title = minSchoolsReached;
            hidePrimary = true;
            hideSecondary = true;
            break;
        case 'account-exists':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="Looks like an account already exists with that information. Please
      double check and try again, contact member services, or create a new
      account with different info."
                />
            );
            title = accountExistsTitle;
            break;
        case 'max-first-and-last-name':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="You’ve exceeded the maximum character length for both first and last
      names. Please update and try again."
                />
            );
            title = tryAgainTitle;
            break;
        case 'max-first-name':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="You’ve exceeded the maximum number of 15 characters for first name.
      Please update and try again."
                />
            );
            title = tryAgainTitle;
            break;
        case 'max-last-name':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="You’ve exceeded the maximum number of 20 characters for last name.
      Please update and try again."
                />
            );
            title = tryAgainTitle;
            break;
        case 'no-email':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="To resolve this, please ask an in-store associate to assist you or contact member services."
                    errorType={errorType}
                />
            );
            title = emailNotFoundTitle;
            secondaryBtnLabel = visitStoreBtn;
            primaryBtnLabel = 'Contact member services';
            showRedPrimaryBtn = true;
            break;
        case 'match-not-found':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="We couldn't find an exact match. Please try again. Still having trouble?
          Please contact member services or create a new account."
                    errorType={errorType}
                />
            );
            title = tryAgainTitle;
            secondaryBtnLabel = 'Contact member services';
            primaryBtnLabel = tryAgainBtn;
            showRedPrimaryBtn = true;
            break;
        case 'no-phone-email':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="To resolve this, please ask an in-store associate to assist you or contact member services."
                    errorType={errorType}
                />
            );
            title = noPhoneEmailTitle;
            secondaryBtnLabel = visitStoreBtn;
            primaryBtnLabel = 'Contact member services';
            showRedPrimaryBtn = true;
            break;
        case 'no-phone':
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="To resolve this, please ask an in-store associate to assist you or contact member services."
                    errorType={errorType}
                />
            );
            title = noPhoneTitle;
            showRedPrimaryBtn = true;
            secondaryBtnLabel = visitStoreBtn;
            primaryBtnLabel = 'Contact member services';
            break;
        default:
            currentErrorDialog = (
                <ErrorContent
                    errorDescription="Something unexpected happened. Please double check your information
      before resubmitting, or contact member services."
                />
            );
            title = unknownTitle;
            break;
    }

    return (
        <ErrorDialog
            open={open}
            handleClose={handleClose}
            errorTitle={title}
            hidePrimaryBtn={hidePrimary}
            hideSecondaryBtn={hideSecondary}
            secondaryBtnLabel={secondaryBtnLabel}
            primaryBtnLabel={primaryBtnLabel}
            handlePrimaryBtnClick={handlePrimaryBtnClick}
            handleSecondaryBtnClick={handleSecondaryBtnClick}
            showRedPrimaryBtn
        >
            {currentErrorDialog}
        </ErrorDialog>
    );
}
