import React from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { SearchProductsResult } from 'src/lib/api-client/adept-mind';
import FilterSortButtons from './filter-sort-buttons';

const useNoSearchResultsStyles = makeStyles({
  noResults: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: '1.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  noResultsText: {
    fontSize: '1rem',
  },
  mainDisclaimerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '3rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainDisclaimerIcon: {
    width: '10rem',
    height: '10rem',
    marginBottom: '1rem',
  },
  mainDisclaimerText: {
    fontWeight: 500,
    fontSize: '1.2rem',
    width: '100%',
    textAlign: 'center',
  },
  filterSortButtons: {
    marginTop: '-1.5rem',
    marginBottom: '1rem',
  },
});
export default function NoSearchResults({
  searchTerm,
  lastResult,
}: {
  searchTerm: string;
  lastResult?: SearchProductsResult | null;
}) {
  const classes = useNoSearchResultsStyles();
  const showFilterButtons =
    !!lastResult?.availableFilters && lastResult?.availableFilters.length > 0;

  return (
    <div className={classes.noResults} id="product-search-no-results">
      {showFilterButtons && (
        <div className={classes.filterSortButtons}>
          <FilterSortButtons lastResult={lastResult} />
          <Divider />
        </div>
      )}
      <Typography className={classes.noResultsText}>
        No search results found for "{searchTerm}".
      </Typography>

      <div className={classes.mainDisclaimerWrapper}>
        <img
          src={require('../../../assets/icons/iconNosearchresults.svg')}
          alt="no search results"
          className={classes.mainDisclaimerIcon}
        />
        <Typography className={classes.mainDisclaimerText}>
          No search results found. Please enter a different search term.
        </Typography>
      </div>
    </div>
  );
}
