/* eslint-disable max-len */
import * as React from 'react';

function SvgComponent(
    props: React.SVGProps<SVGSVGElement> & { fillColor: string }
) {
    const { fillColor = '#101820' } = props;
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="13"
                cy="13" r="11.3"
                stroke={fillColor}
                strokeWidth="1.4"/>
            <path
                d="M18.9592 18.2057C18.3061 16.4681 16.8912 15.1648 15.1497 14.5132C17.0544 13.3185 17.6531 10.8206 16.4558 8.92004C15.2585 7.01946 12.8095 6.42213 10.8503 7.61679C8.89115 8.81144 8.34694 11.3093 9.54421 13.2642C9.87075 13.8073 10.3061 14.2417 10.8503 14.5675C9.10884 15.1648 7.7483 16.5224 7.04082 18.2601C6.93197 18.5316 7.04082 18.8574 7.36735 18.966C7.47619 18.966 7.53061 18.966 7.58503 18.966C7.80272 18.966 8.02041 18.8031 8.07483 18.6402C9.16326 15.925 12.2109 14.5675 14.932 15.6535C16.2925 16.1966 17.3809 17.2826 17.9252 18.6402C18.034 18.9117 18.3605 19.0746 18.6327 18.966C18.9592 18.8031 19.068 18.5316 18.9592 18.2057ZM10.034 11.0921C10.034 9.46307 11.3946 8.10551 13.0272 8.10551C14.6599 8.10551 16.0204 9.46307 16.0204 11.0921C16.0204 12.7212 14.6599 14.0788 13.0272 14.0788C11.3946 14.0788 10.034 12.7755 10.034 11.0921Z"
                fill={fillColor}
                stroke={fillColor}
                strokeWidth="0.25"/>
        </svg>

    );
}

export default SvgComponent;
