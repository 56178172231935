import React, { useState, ChangeEvent, useEffect } from 'react';
import {
    makeStyles,
    InputBase,
    ButtonBase,
    Typography,
    Collapse,
    CircularProgress,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import useRouter from 'src/hooks/useRouter';
import {
    CommonStepProps,
    RewardsControllerState,
} from 'src/screens/rewards-enroll-flow/types';
import useSchoolSearch from 'src/hooks/useSchoolSearch';
import { GUTTER_WIDTH } from 'src/theme';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingButton from 'src/components/loading-button';
import { SchoolResult } from 'src/services/types';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {
    useCachedSchools,
    SimplifiedSchool,
    splitIds,
} from 'src/lib/utils/school-utils';
import { isEducatorOrAdmin } from 'src/lib/utils/user-utils';

type RewardsEnrollSelectSchoolProps = CommonStepProps;

const useStyles = makeStyles({
    // TODO: enrollFormCard can be deduplicated
    enrollFormCard: {
        marginTop: '1rem',
        // marginLeft: GUTTER_WIDTH,
        // marginRight: GUTTER_WIDTH,
        marginLeft: '4px',
        marginRight: '4px',
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
        // minHeight: '50vh',
    },
    schoolSearchDescription: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '1rem',
        padding: '1rem 2.75rem 3rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    searchInputForm: {
        width: '100%',
        display: 'contents',
    },
    searchInput: {
        display: 'flex',
        flexGrow: 1,
        margin: '1rem 1.25rem',
        padding: '0.25rem 0',
        borderRadius: '1.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    searchIconBtn: {
        width: '1.75rem',
        height: '1.75rem',
        marginLeft: '.5rem',
        marginRight: '.5rem',
    },
    searchIcon: {
        width: '1.75rem',
        height: '1.75rem',
    },
    schoolSearchDisclaimer: {
        textAlign: 'center',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        fontSize: '.75rem',
    },
    schoolNotFoundDisclaimer: {
        textAlign: 'center',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        marginBottom: '2em',
        fontSize: '.75rem',
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '-1rem',
        height: '40vh',
        overflowY: 'scroll',
    },
    nextButtonWrapper: {
        margin: '.5rem .75rem',
    },
    nextButton: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const useSchoolRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        padding: '0 0 1rem 1rem',
        minHeight: '3.5rem',
        marginRight: '-1rem',
        '&:last-child > *': {
            borderBottom: 'none',
        },
    },
    schoolItem: {
        width: '100%',
        display: 'flex',
        padding: '0 1rem 1rem 0',
        minHeight: '3.5rem',
        alignItems: 'center',
        flexShrink: 0,
        margin: '0 .5rem 0 0',
        borderBottom: '1px black solid',
    },
    interactiveText: {
        flexGrow: 1,
    },
    textWrapper: {
        flexGrow: 1,
    },
    name: {
        fontSize: '1.1rem',
        display: 'flex',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    address: {
        fontSize: '0.9rem',
        display: 'flex',
    },
    icon: {
        width: '1.7rem',
        height: '1.7rem',
    },
});

type SchoolRowProps = {
    index: number;
    school: SchoolResult;
    onPress: (selectedSchool: SchoolResult) => void;
    isSelected: boolean;
};

/** School selection row when enrolling for a school */
function SchoolRow({ school, isSelected, onPress, index }: SchoolRowProps) {
    const classes = useSchoolRowStyles();
    function handlePress() {
        onPress(school);
    }
    return (
        <div
            id={`school-selection-search-result-${index}`}
            className={classes.row}
        >
            <div className={classes.schoolItem}>
                <ButtonBase
                    id={`school-selection-search-result-${index}-main-button`}
                    onClick={handlePress}
                    className={classes.interactiveText}
                >
                    <div className={classes.textWrapper}>
                        <Typography
                            id={`school-selection-search-result-${index}-name-text`}
                            className={classes.name}
                            component="div"
                        >
                            {school.name}
                        </Typography>
                        <Typography
                            id={`school-selection-search-result-${index}-address-street-text`}
                            className={classes.address}
                            component="div"
                        >
                            {school.street}
                        </Typography>
                        <Typography
                            id={`school-selection-search-result-${index}-address-city-region-text`}
                            className={classes.address}
                            component="div"
                        >
                            {school.city}, {school.state}, {school.zip}
                        </Typography>
                    </div>
                </ButtonBase>
                <ButtonBase
                    id={`school-selection-search-result-${index}-select-button`}
                    onClick={handlePress}
                >
                    {isSelected ? (
                        <RadioButtonCheckedIcon />
                    ) : (
                        <RadioButtonUncheckedIcon />
                    )}
                </ButtonBase>
            </div>
        </div>
    );
}

const adminDescription =
    // eslint-disable-next-line max-len
    "Enter the school name or address below and we'll connect it with your Classroom Rewards profile so you can start earning, giving and redeeming rewards. Please note, only one school administrator can be associated with each educational institution.";
const educatorDescription =
    // eslint-disable-next-line max-len
    "Enter the school name or address below and we'll connect it with your Classroom Rewards profile.";

export default function RewardsEnrollSelectSchool({
    updateControllerValues,
    userType,
}: RewardsEnrollSelectSchoolProps) {
    const classes = useStyles();
    const isAdmin = userType === 'Administrator';
    const { query } = useRouter();
    const { selectedSchoolIds: idsFromQuery } = query as RewardsControllerState;
    const [cachedSchools, setCachedSchools] = useCachedSchools();
    const [selectedSchools, setSelectedSchools] = useState<SimplifiedSchool[]>(
        []
    );
    const onRowPress = (school: SchoolResult) => {
        setSelectedSchools([school]);
    };
    const [searchString, setSearchString] = useState('');
    const [submittedSearchString, setSubmittedSearchString] = useState('');
    function onUpdateSearch(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
        setSearchString(event.target.value);
    }
    const {
        isFetching: isSearching,
        schools,
        clearSchools,
    } = useSchoolSearch({
        partialAddress: submittedSearchString,
    });
    function handleSubmit(e: any) {
        e.preventDefault();
        setSubmittedSearchString(searchString);
        setSelectedSchools([]);
        clearSchools();
    }

    const nextScreen = () => {
        setCachedSchools([...cachedSchools, ...selectedSchools]);
        setSearchString('');
        setSubmittedSearchString('');
        clearSchools();
        const selectedSchoolsToSubmit = [...selectedSchools];
        setSelectedSchools([]);
        if (isAdmin) {
            // replace selected school (admins only get 1)
            updateControllerValues({
                enrollStep: 'confirmSchool',
                selectedSchoolIds: selectedSchoolsToSubmit
                    .map((s) => s.id)
                    .join(','),
                enrollSubStep: '',
            });
        } else {
            // add to schools
            updateControllerValues({
                enrollStep: 'confirmSchool',
                selectedSchoolIds: [
                    ...splitIds(idsFromQuery),
                    ...selectedSchoolsToSubmit.map((s) => s.id),
                ].join(','),
                enrollSubStep: '',
            });
        }
    };

    useEffect(() => {
        if (schools.length > 0) {
            const selectedSchoolsToSubmit = [...selectedSchools];
            if (isAdmin) {
                // replace selected school (admins only get 1)
                updateControllerValues({
                    enrollStep: 'selectSchool',
                    selectedSchoolIds: selectedSchoolsToSubmit
                        .map((s) => s.id)
                        .join(','),
                    enrollSubStep: 'schoolSearched',
                });
            } else {
                // add to schools
                updateControllerValues({
                    enrollStep: 'selectSchool',
                    selectedSchoolIds: [
                        ...splitIds(idsFromQuery),
                        ...selectedSchoolsToSubmit.map((s) => s.id),
                    ].join(','),
                    enrollSubStep: 'schoolSearched',
                });
            }
        }
    }, [schools]);

    const selectedSchoolIds = selectedSchools.map((s) => s.id); // memoize?

    const nextButtonEnabled =
        selectedSchoolIds.length > 0 ||
        (searchString.length > 0 && !isSearching && schools.length === 0) ||
        (searchString.length > 0 && searchString !== submittedSearchString);

    function onNextButtonPressed() {
        if (selectedSchoolIds.length > 0) {
            nextScreen();
        } else if (searchString.length > 0) {
            setSubmittedSearchString(searchString);
        }
    }

    return (
        <FullWidthCard
            hasManualShadow
            noPadding
            className={classes.enrollFormCard}
            id="classroom-rewards-number-card"
        >
            <Collapse in={submittedSearchString === ''}>
                <Typography className={classes.schoolSearchDescription}>
                    {isAdmin ? adminDescription : educatorDescription}
                </Typography>
            </Collapse>
            <form className={classes.searchInputForm} onSubmit={handleSubmit}>
                <InputBase
                    id="school-selection-search-input"
                    className={classes.searchInput}
                    placeholder="School name or city, state"
                    onChange={onUpdateSearch}
                    value={searchString}
                    startAdornment={
                        <ButtonBase
                            onClick={handleSubmit}
                            id="school-selection-search-button"
                            className={classes.searchIconBtn}
                        >
                            <img
                                id="school-search-icon-img"
                                src={require('../../assets/icons/iconSearchThin.svg')}
                                className={classes.searchIcon}
                                alt="search icon"
                            />
                        </ButtonBase>
                    }
                />
            </form>
            <Collapse
                in={isEducatorOrAdmin(userType) && submittedSearchString !== ''}
            >
                <Typography className={classes.schoolNotFoundDisclaimer}>
                    Not finding the school you're looking for? Try searching by
                    the city, state the school is located in or the school name.
                </Typography>
            </Collapse>
            <Collapse in={submittedSearchString !== ''}>
                <div className={classes.list}>
                    {isSearching && schools.length === 0 && (
                        <div className={classes.loading}>
                            <CircularProgress
                                size="1rem"
                                style={{ color: COLORS.textBlack }}
                            />
                        </div>
                    )}
                    {schools.map((s, index) => (
                        <SchoolRow
                            index={index}
                            key={s.id}
                            school={s}
                            onPress={onRowPress}
                            isSelected={
                                !!selectedSchoolIds.find(
                                    (selId) => selId === s.id
                                )
                            }
                        />
                    ))}
                </div>
            </Collapse>
            <div className={classes.nextButtonWrapper}>
                <LoadingButton
                    fullWidth
                    id="school-selection-search-results-next-button"
                    variant="contained"
                    classes={{
                        root: classes.nextButton,
                    }}
                    type="submit"
                    disabled={!nextButtonEnabled}
                    loading={false}
                    onClick={onNextButtonPressed}
                >
                    Continue
                </LoadingButton>
            </div>
        </FullWidthCard>
    );
}
