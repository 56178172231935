import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { GUTTER_WIDTH_SLIM } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthRow from 'src/components/full-width-row';
import { getFavorites } from 'src/lib/api-client/favorites';
import { useQuery } from 'react-query';
import ScrollableRow from 'src/components/scrollable-row';
import {
    combineFavorites,
    FullDonationRecipient,
} from 'src/lib/utils/favorite-utils';
import useRouter from 'src/hooks/useRouter';
import { makeSearchString } from 'src/lib/utils/url-utils';

const useStyles = makeStyles({
    row: {
        marginBottom: '2rem',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
    },
    giveFavoriteText: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        fontSize: '1.10rem',
        textAlign: 'center',
        marginTop: '0.7rem',
    },
    recentActivityEmpty: {
        fontSize: '1.0625rem',
        fontWeight: 500,
        textAlign: 'center',
        padding: '2rem',
        letterSpacing: '-0.024rem',
        lineHeight: '1.25rem',
    },
    sectionTitle: {
        fontWeight: 500,
        marginLeft: GUTTER_WIDTH_SLIM,
    },
    addToFavorites: {
        fontWeight: 'bold',
        color: COLORS.brandCyan,
        marginRight: GUTTER_WIDTH_SLIM,
        cursor: 'pointer',
    },
    titleWrapper: {
        marginTop: '1.8rem',
        marginBottom: '.85rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    favoritesWrapper: {
        backgroundColor: COLORS.primaryWhite,
    },
    noActivityIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1.5rem',
    },
    noActivityIcon: {
        width: '111px',
        height: '111px',
    },
});

const useCardStyles = makeStyles({
    favoriteWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 0.5rem',
    },
    giveFavoriteText: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        fontSize: '1.10rem',
        textAlign: 'center',
        marginTop: '0.7rem',
    },
    favoriteIcons: {
        marginTop: '1rem auto 0',
    },
    favoriteText: {
        fontSize: '0.9rem',
        fontWeight: 500,
        padding: '0 5px',
        marginTop: '0.4rem',
        letterSpacing: '-0.30pt',
        lineHeight: '1rem',
        minHeight: '2rem',
        lineClamp: 2,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        width: '7rem',
        textAlign: 'center',
    },
    favoriteIneerText: {
        fontSize: '0.9rem',
        overflow: 'hidden',
        fontWeight: 500,
        width: '7rem',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        letterSpacing: '-0.30pt',
        lineHeight: '1rem',
        padding: '0 0 0 12px',
    },
    favoriteTextContainer: {
        marginTop: '0.4rem',
    },
});

type FavoriteCardProps = {
    onGiveClicked: (f: FullDonationRecipient) => void;
    favorite: FullDonationRecipient;
    index: number;
};
const FavoriteCard = ({
    favorite,
    onGiveClicked,
    index,
}: FavoriteCardProps) => {
    const classes = useCardStyles();

    const { firstName, lastName, type, schoolName } = favorite;

    function cardGiveClicked() {
        onGiveClicked(favorite);
    }

    return (
        <Box className={classes.favoriteWrapper}>
            <img
                className={classes.favoriteIcons}
                src={
                    type === 'user' || type === undefined
                        ? require('../assets/icons/iconClassroomrewardsEducator.svg')
                        : require('../assets/icons/iconClassroomrewardsSchool.svg')
                }
                alt="rewards favorite icon"
            />
            <Typography
                className={classes.favoriteText}
                id={`give-to-favorite-${index}-text`}
            >
                {type === 'user' || type === undefined ? (
                    <>
                        <div className={classes.favoriteTextContainer}>
                            <div>
                                {firstName} {lastName}
                            </div>
                            <div> {schoolName} </div>
                        </div>
                    </>
                ) : (
                    <div>{schoolName}</div>
                )}
            </Typography>
            <Typography
                className={classes.giveFavoriteText}
                id={`give-to-favorite-${index}-cta`}
                onClick={cardGiveClicked}
            >
                Give now
            </Typography>
        </Box>
    );
};
type FavoritesActivityProps = {
    onAddFavoritesPressed?: () => void;
    onManageFavoritesPressed?: () => void;
};

/** used for loading and displaying enrolled user's favorites */
export function FavoritesActivity({
    onAddFavoritesPressed,
    onManageFavoritesPressed,
}: FavoritesActivityProps) {
    const classes = useStyles();
    const [hasFavorites, setHasFavorites] = useState(false);
    // const [serviceActiveIndex, setServiceActiveIndex] = React.useState(0);
    const [favs, setFavs] = useState<FullDonationRecipient[]>([]);
    const { history, pathname, query } = useRouter();
    const hide = Object.keys(query).length === 0;
    const { data: favorites, isFetching: isFavsFetching } = useQuery(
        ['favorites'],
        getFavorites
    );

    useEffect(() => {
        if (!isFavsFetching && favorites) {
            const { favoriteEducators, favoriteInstitutions } = favorites;
            const favsCombined = combineFavorites(
                favoriteEducators,
                favoriteInstitutions
            );
            if (favsCombined.length > 0) {
                // only display five favorites in the carousel
                setFavs(favsCombined.slice(0, 5));
                setHasFavorites(true);
            }
        }
    }, [favorites, isFavsFetching]);

    function editFavorites() {
        history.push({
            pathname,
            search: makeSearchString({
                myFavorites: 'open',
            }),
        });
    }

    function onGiveClicked(recipient: FullDonationRecipient) {
        if (recipient?.id && recipient?.type) {
            history.push({
                pathname,
                search: makeSearchString({
                    donateStep: 'setAmount',
                    recipientType:
                        recipient.type === 'institution' ? 'school' : 'user',
                    recipientId: recipient.id,
                    recipientName: `${recipient.firstName} ${recipient.lastName}`,
                    recipientSchool: recipient.schoolName,
                    recipientAddress: `${recipient.city}, ${recipient.state}, ${recipient.zip}`,
                }),
            });
        } else {
            history.push({
                pathname,
                search: makeSearchString({ donateStep: 'search' }),
            });
        }
    }
    // const handleServiceChangeIndex = (index: number) => {
    //   setServiceActiveIndex(index);
    // };

    return (
        <Box>
            {onManageFavoritesPressed && (
                <Box className={classes.titleWrapper} flexDirection="row">
                    <Typography
                        className={classes.sectionTitle}
                        id="favorites-activity-title"
                    >
                        Give to Your Favorites
                    </Typography>
                    <Typography
                        className={classes.addToFavorites}
                        id="favorites-activity-add"
                        onClick={
                            hasFavorites
                                ? onManageFavoritesPressed
                                : onAddFavoritesPressed
                        }
                    >
                        {hasFavorites ? 'Manage' : 'Add favorites'}
                    </Typography>
                </Box>
            )}
            {hasFavorites ? (
                <>
                    {!hide && (
                        <Typography className={classes.recentActivityEmpty}>
                            Or choose from your favorites
                        </Typography>
                    )}
                    <Box className={classes.favoritesWrapper}>
                        <ScrollableRow id="give-to-favorites-ctas-scrollable-row">
                            {/* <SwipeableViews
              id="favorites-activity-swipeable"
              enableMouseEvents
              index={serviceActiveIndex}
              onChangeIndex={handleServiceChangeIndex}
            > */}
                            {(favs || []).map((f, index) => (
                                <FavoriteCard
                                    key={f.id}
                                    index={index}
                                    favorite={f}
                                    onGiveClicked={onGiveClicked}
                                />
                            ))}
                            {/* </SwipeableViews> */}
                        </ScrollableRow>
                    </Box>
                    {!hide && (
                        <Typography
                            className={classes.giveFavoriteText}
                            onClick={editFavorites}
                        >
                            Edit favorites
                        </Typography>
                    )}
                </>
            ) : (
                <FullWidthRow className={classes.row}>
                    <div className={classes.noActivityIconContainer}>
                        <img
                            className={classes.noActivityIcon}
                            src={require('../assets/icons/iconNoActivity.svg')}
                            alt="no activity icon"
                        />
                    </div>
                    {onManageFavoritesPressed ? (
                        <Typography className={classes.recentActivityEmpty}>
                            You do not have favorite Teachers or Schools saved.
                            Choose Add Favorites above to search to save.
                        </Typography>
                    ) : (
                        <Typography className={classes.recentActivityEmpty}>
                            You do not have favorite Teachers or Schools saved.
                            Search for a school or teacher above and add them to
                            your favorites list.
                        </Typography>
                    )}
                </FullWidthRow>
            )}
        </Box>
    );
}

export default FavoritesActivity;
