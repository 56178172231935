/* eslint-disable max-len */
import React from 'react';

const IconCheckmark = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2858 11C21.2858 16.6399 16.64 21.2956 11.0001 21.2956C5.36015 21.2956 0.714355 16.6399 0.714355 11C0.714355 5.36009 5.36015 0.714294 11.0001 0.714294C16.6301 0.714294 21.2858 5.36009 21.2858 11ZM8.87403 15.3604C9.16931 15.7245 9.49412 15.9017 9.90752 15.9017C10.3012 15.9017 10.6556 15.7049 10.8918 15.3407L15.4884 8.26371C15.6459 8.02749 15.7541 7.77157 15.7541 7.53535C15.7541 6.97431 15.262 6.5806 14.7108 6.5806C14.3663 6.5806 14.0612 6.76761 13.8249 7.15148L9.87799 13.4509L8.06692 11.1969C7.81101 10.8819 7.55509 10.7539 7.23028 10.7539C6.66924 10.7539 6.22632 11.1969 6.22632 11.7579C6.22632 12.0237 6.3149 12.2599 6.51176 12.5158L8.87403 15.3604Z"
      fill="#101820"
    />
  </svg>
);

export default IconCheckmark;
