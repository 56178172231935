// creating-shipping-recipient-information// creating-shipping-package-information

import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    ButtonBase,
    makeStyles,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import ValidationTextField from 'src/components/validation-text-field';
import { validateZipCode } from 'src/lib/utils/user-utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { phoneNumberValidationShipping } from 'src/lib/validations';
import USStates from 'src/constants/us-states';
import { Country, State, City } from 'country-state-city';
import ReactInputMask from 'react-input-mask';
import { usePlacesWidget } from 'react-google-autocomplete';
import LoadingOverlay from 'src/components/loading-overlay';
import ConfirmationDialog from 'src/components/shipping-services/creating-shipment/confirmation-dialog';
import { getShipmentValidation } from 'src/lib/api-client/shipment-services';
import ShippingErrorDialog from 'src/components/shipping-services/shipping-errors/shipping-error-dialog';
import { phoneNumberFormat } from 'src/lib/utils/string-utils';
import ShippingInfoContext, {
    ShippingFields,
} from 'src/lib/contexts/shipping-info-context';

const useStyles = makeStyles((theme) => ({
    recipientContainer: {
        margin: '24px',
    },
    recipientInformation: {
        padding: '24px',
        background: '#FFFFFF',
        borderRadius: '15px',
    },
    recipientYourInformation: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    recipientYourInformationText: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '-0.200px',
        color: '#000000',
    },
    editBtn: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#086DD2',
    },
    recipientNameInformation: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color: '#1E1E1E',
    },
    recipientNameInformationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    recipientPrimaryText: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#979797',
    },
    recipientAddressContainer: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#1E1E1E',
    },
    addressAlign: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#1E1E1E',
        wordBreak: 'break-all',
    },
    headerTitle: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-1px',
    },
    headerSubTitle: {
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '25.96px',
        letterSpacing: '-0.6px',
    },
    createShippingRecipientHeaderTitle: {
        margin: '24px 0',
    },
    createShippingRecipientSearchAddressContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
    },
    searchAllAddressBtn: {
        height: '40px',
        width: '50%',
        borderRadius: '20px',
        background: '#ffffff',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '16.52px',
            color: '#000000',
        },
    },
    sameAsSender: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#086DD2',
    },
    createShippingRecipientContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '24px',
    },
    createShippingRecipientFieldContainer: {
        display: 'flex',
        gap: 15,
        marginBottom: '24px',
    },
    uploadBtn: {
        height: '30px',
        width: '108px',
        borderRadius: '14.5px',
        background: '#FFFFFF',
        '& span': {
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '12px',
            letterSpacing: '0px',
            textAlign: 'center',
        },
    },
    nextBtn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        marginTop: '7px',
        fontVariantLigatures: 'none',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    iconInfo: {

    },
    logoIconConatiner: {
        display: 'inline-flex',
        gap: 12,
        alignItems: 'center',
        margin: '6px 0px 30px',
    },
    formConatiner: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormLabel-root': {
            color: '#444444 !important',
        },
    },
    menuPaper: {
        maxHeight: 300,
    },
    formControl: {
        minWidth: '50%',
    },
    errorAlign: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        textAlign: 'center',
        color: '#c0392b',
    },
}));
const validationSchema = yup.object({
    firstName: yup.string().required('First name is a required field.')
        .matches(/^ *[a-zA-Z]+( [a-zA-Z]+)* *$/, "First name is invalid"),
    lastName: yup.string().required('Last name is a required field.')
        .matches(/^ *[a-zA-Z]+( [a-zA-Z]+)* *$/, "Last name is invalid"),
    phoneNumber: phoneNumberValidationShipping.nullable()
        .optional(),
    address: yup.string().required('Street address is a required field.'),
    city: yup.string().required('City is a required field.'),
    zipCode: yup.string().required('Zip code is a required field.')
        .matches(/^(?:\d{5}|\d{5}-\d{4})$/, "Zip code is invalid"),
    state: yup.string().required('State is a required field.'),
    email: yup
        .string()
        .nullable()
        .optional()
        .matches(/^ *[\w-_.]+@([\w-]+\.)+[\w-]{2,4} *$/g, "Email is not valid."),
});


interface recipientProps {
    senderInfo: any,
    setSenderInfo: (data: any) => void,
    setRecipientInfo: (data: any) => void,
    recipientInfo: any,
}
const CreateShippingRecipientInformation = ({ senderInfo, setSenderInfo, setRecipientInfo, recipientInfo }:
    recipientProps) => {
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [activeField, setActiveField] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);
    const [errorTitle, setErrorTitle] = useState<undefined | string>(undefined);
    const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);
    const [responseData, setResponseData] = useState({});
    const { sender, recipient, inReviewStage, updateRecipient, updateSender } = useContext(ShippingInfoContext)
    const handleDialogClose = () => {
        setIsConfirmationRequired(false);
        setError(false)
        setErrorMsg(undefined)
        setErrorTitle(undefined)
    };
    const fields = ["firstName", "lastName", "email", "phoneNumber", "companyName",
        "address", "city", "state", "zipCode", "aptNumb"];

    useEffect(() => {
        if (recipient.fullname) {
            const data = {
                firstName: recipient.fullname.split(' ')[0],
                lastName: recipient.fullname.split(' ')[1],
                companyName: recipient.companyname,
                address: recipient.street1,
                aptNumb: recipient.street2,
                phoneNumber: phoneNumberFormat(recipient.phonenumber),
                city: recipient.city,
                state: recipient.state,
                zipCode: recipient.postalcode,
                email: recipient.email,
            }
            setRecipientInfo(data);
            updateRecipient({ ...ShippingFields })

        }
        if (sender.fullname) {
            const data = {
                firstName: sender.fullname.split(' ')[0],
                lastName: sender.fullname.split(' ')[1],
                companyName: sender.companyname,
                address: sender.street1,
                aptNumb: sender.street2,
                phoneNumber: phoneNumberFormat(sender.phonenumber),
                city: sender.city,
                state: sender.state,
                zipCode: sender.postalcode,
                email: sender.email,
            }
            setSenderInfo(data);
            updateSender({ ...ShippingFields })
        }
    }, [recipient])
    const sendData = (data: any, isUseModifyAddress = false) => {
        setRecipientInfo(data)
        if (inReviewStage) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=CreateShippingReviewInformation',
            })
        } else {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=CreateShippingPackageInformation',
            })
        }
    }
    const handleNavigation = async (currentValues: any) => {
        setError(false);
        // setIsLoading(true)
        console.log('currentValues', { currentValues });
        const bodyData = {
            fullName: `${currentValues?.firstName} ${currentValues?.lastName}`,
            companyName: currentValues?.companyName,
            street1: currentValues?.address,
            street2: currentValues?.aptNumb,
            city: currentValues?.city,
            state: currentValues?.state,
            postalcode: currentValues?.zipCode,
            phoneNumber: currentValues?.phoneNumber,
            countrycode: 1,
            country: "US",
            email: currentValues?.email,
        }
        console.log({ bodyData })
        setIsLoading(true);
        const responseDataValues = await getShipmentValidation(bodyData);
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'save',
            element_location: 'customer information',
        });
        setIsLoading(false);

        if (responseDataValues?.result === 'Success') {
            setIsConfirmationRequired(false);
            sendData(currentValues)
        }
        else if (responseDataValues?.result === 'Verify' && responseDataValues?.modifiedAddress) {
            setIsConfirmationRequired(true);
            setResponseData({ ...responseDataValues, oldAddress: currentValues });
        }
        else if (responseDataValues?.result === 'Warning') {
            setErrorMsg(responseDataValues?.messages[0])
            setError(true);
        }
        else if (responseDataValues?.result === 'Failure') {
            setErrorTitle('Invalid Address');
            setErrorMsg("The address provided is invalid and could not be used. Please check again.")
            setError(true);
        }
        else {
            setError(true);
            setErrorMsg("We couldn’t validate the provided address at this time. Please try again later.")

        }
    }

    const habdleEditSenderInfo = () => {
        localStorage.setItem('EditMode', 'ON');
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        })
    }
    const { values, setValues, handleSubmit, handleChange, errors, touched, setFieldError, setFieldValue } =
        useFormik({
            initialValues: {
                firstName: recipientInfo?.firstName ? recipientInfo?.firstName : '',
                lastName: recipientInfo?.lastName ? recipientInfo?.lastName : '',
                email: recipientInfo?.email ? recipientInfo?.email : '',
                phoneNumber: recipientInfo?.phoneNumber ? recipientInfo?.phoneNumber : '',
                companyName: recipientInfo?.companyName ? recipientInfo?.companyName : '',
                address: recipientInfo?.address ? recipientInfo?.address : '',
                city: recipientInfo?.city ? recipientInfo?.city : '',
                state: recipientInfo?.state ? recipientInfo?.state : '',
                zipCode: recipientInfo?.zipCode ? recipientInfo?.zipCode : '',
                aptNumb: recipientInfo?.aptNumb ? recipientInfo?.aptNumb : '',
            },
            validationSchema,
            enableReinitialize: true,
            onSubmit: (currentValues) => {
                const phoneNumber = (currentValues?.phoneNumber || '').replace(
                    /[^0-9]/gi,
                    ''
                );
                handleNavigation(currentValues);
            },
        });
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY,
        onPlaceSelected: (place) => {
            const ADDRESS = {
                street_address: ["street_number", 'route'],
                postal_code: 'postal_code',
                city: "locality",
                sublocality: "sublocality",
                state: "administrative_area_level_1",
            }
            const addressData = place?.address_components || [];
            console.log('place', { place });
            const zipCode = (addressData.find(data => data.types.includes(ADDRESS.postal_code)) || {}).short_name;
            const city = (addressData.find(data => data.types.includes(ADDRESS.city)) || {}).long_name;
            const sublocality =
                (addressData.find(data => data.types.includes(ADDRESS.sublocality)) || {}).long_name;
            const state = (addressData.find(data => data.types.includes(ADDRESS.state)) || {}).short_name;

            const streetAddress = (addressData.find(data =>
                data.types.includes(ADDRESS.street_address[1])) || {}).long_name;
            const route = (addressData.find(data =>
                data.types.includes(ADDRESS.street_address[0])) || {}).long_name;

            const fullStreetAddress = route ? `${route} ${streetAddress}` : streetAddress;
            handleChange({ target: { value: state ?? '', name: "state" } });
            setFieldValue("city", city ?? sublocality ?? '');
            setFieldValue("address", fullStreetAddress);
            setFieldValue("zipCode", zipCode ?? '');

        },
        options: {
            componentRestrictions: { country: ["us"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
        },
    });
    const changeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.type === 'focus' ? e?.target?.name : '';
        setFieldError(fieldName, '');
        setActiveField(fieldName);
    };
    const sameAsSender = () => {
        setValues(senderInfo);
    }
    const handleZipChange = (e: any) => {
        const zipStatus = validateZipCode(e);
        if (zipStatus) {
            if (e.target.value.length === 7 && !e.target.value.includes('-')) {
                // eslint-disable-next-line
                e.target.value = e.target.value.slice(0, 5) + '-' + e.target.value.slice(5);
                handleChange({ target: { value: e.target.value, name: e.target.name } });
            }
            else {
                handleChange({ target: { value: e.target.value, name: e.target.name } });
            }
        } else {
            e.preventDefault();
        }
    }
    useEffect(() => {
        const clearForm = localStorage.getItem('clearForm');
        if (clearForm === 'true') {
            fields.map(field => setFieldValue(field, ''));
        }
        localStorage.setItem('clearForm', 'false');
    }, []);
    return (
        <div className={classes.recipientContainer}>
            <div className={classes.recipientInformation}>
                <div className={classes.recipientYourInformation}>
                    <Typography className={classes.recipientYourInformationText}>Your information</Typography>
                    <ButtonBase className={classes.editBtn} onClick={habdleEditSenderInfo}>Edit</ButtonBase>
                </div>
                <div className={classes.recipientNameInformationContainer}>
                    <Typography className={classes.recipientNameInformation}>
                        {senderInfo.firstName} {senderInfo.lastName}
                    </Typography>
                </div>
                <div className={classes.recipientAddressContainer}>
                    <Typography className={classes.addressAlign}>{senderInfo.companyName}</Typography>
                    <Typography className={classes.addressAlign}>
                        {senderInfo.address}&nbsp;{senderInfo.aptNumb}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {senderInfo.city},&nbsp;{senderInfo.state}&nbsp;{senderInfo.zipCode}
                    </Typography>
                    <Typography className={classes.addressAlign}>
                        {phoneNumberFormat(senderInfo.phoneNumber)}
                    </Typography>
                    <Typography className={classes.addressAlign}>{senderInfo.email}</Typography>
                </div>
            </div>
            <div className={classes.createShippingRecipientHeaderTitle}>
                {/* <Typography className={classes.headerTitle}>Sender Information</Typography> */}
                <Typography className={classes.headerSubTitle}>Next, where is your shipment headed?</Typography>
            </div>
            <div className={classes.createShippingRecipientSearchAddressContainer}>
                <ButtonBase className={classes.sameAsSender} onClick={() => sameAsSender()}>Same as sender</ButtonBase>
            </div>
            <form onSubmit={handleSubmit} className={classes.formConatiner}>
                <div className={classes.createShippingRecipientFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-recipient-first-name-input"
                        name="firstName"
                        label='First name*'
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        inputProps={{ maxLength: 15 }}
                    />
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-recipient-last-name-input"
                        name="lastName"
                        label='Last name*'
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        inputProps={{ maxLength: 20 }}
                    />
                </div>
                <div className={classes.createShippingRecipientFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-recipient-company-name-input"
                        name="companyName"
                        label='Company name'
                        inputProps={{ maxLength: 35 }}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                    />
                </div>
                <div className={classes.createShippingRecipientFieldContainer}>
                    <ValidationTextField

                        fullWidth
                        id="create-shipping-recipient-street-address-input"
                        name="address"
                        label='Street address*'
                        inputProps={{ maxLength: 35 }}
                        value={values.address}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        inputRef={ref}
                    />
                </div>
                <div className={classes.createShippingRecipientFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-recipient-aptNumb-input"
                        name="aptNumb"
                        label='Apt., floor, suite, etc.'
                        inputProps={{ maxLength: 35 }}
                        value={values.aptNumb}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                    />
                </div>
                <div className={classes.createShippingRecipientFieldContainer}>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-recipient-city-input"
                        name="city"
                        label='City*'
                        inputProps={{ maxLength: 30 }}
                        value={values.city}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                        InputLabelProps={{ shrink: !!values.city }}
                    />
                </div>
                <div className={classes.createShippingRecipientFieldContainer}>
                    <FormControl className={classes.formControl}>
                        {/* <InputLabel id="demo-simple-select-label">State*</InputLabel> */}
                        <ValidationTextField
                            fullWidth
                            id="create-shipping-sender-state-input"
                            name="state"
                            label='State*'
                            select
                            inputProps={{ maxLength: 30 }}
                            value={values.state}
                            onChange={handleChange}
                            onBlur={(e: any) => changeLabel(e)}
                            onFocus={(e: any) => changeLabel(e)}
                            error={touched.state && Boolean(errors.state)}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: !!values.state }}
                        >
                            {USStates.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option  >
                            ))}
                        </ValidationTextField>
                        {touched.state && Boolean(errors.state) ?
                            <FormHelperText style={{ color: "#f44336" }}>
                                state is a required field.</FormHelperText>
                            : null}
                    </FormControl>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-recipient-zipCode-input"
                        name="zipCode"
                        type="text"
                        inputProps={{ maxLength: 10 }}
                        label='Zip code*'
                        value={values.zipCode}
                        onChange={handleZipChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.zipCode && Boolean(errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                        InputLabelProps={{ shrink: !!values.zipCode }}
                    />
                </div>
                <div className={classes.createShippingRecipientFieldContainer}>
                    <ReactInputMask
                        mask="(999) 999-9999"
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        name="phoneNumber"
                        inputMode="tel"
                        type="phone"
                        value={values.phoneNumber}
                    >
                        {() => <ValidationTextField
                            fullWidth
                            id="create-shipping-recipient-phonenumber-input"
                            name="phoneNumber"
                            label='Phone number'
                            inputProps={{ maxLength: 15 }}
                            value={values.phoneNumber}
                            inputMode="tel"
                            type="phone"
                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                        />}
                    </ReactInputMask>
                    <ValidationTextField
                        fullWidth
                        id="create-shipping-recipient-email-input"
                        name="email"
                        label='Email'
                        inputProps={{ maxLength: 50 }}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={(e: any) => changeLabel(e)}
                        onFocus={(e: any) => changeLabel(e)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                    />
                </div>
                <ButtonBase className={classes.nextBtn} type="submit"><span>Confirm</span></ButtonBase>
            </form>
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
            {isConfirmationRequired &&
                <ConfirmationDialog open={isConfirmationRequired} handleClose={handleDialogClose}
                    data={responseData} sendData={sendData} />
            }
            {
                error &&
                <ShippingErrorDialog
                    title={errorTitle}
                    errorDescription={errorMsg}
                    open={error}
                    handleClose={handleDialogClose} />
            }
        </div>

    )
}

export default CreateShippingRecipientInformation
