/* eslint-disable arrow-body-style */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    ButtonBase,
    InputBase,
    makeStyles,
    Paper,
    Typography,
    Divider,
    CircularProgress,
} from '@material-ui/core';
import { useInfiniteQuery } from 'react-query';
import { ReviewStars } from 'src/components/product-star-rating';
import COLORS from 'src/lib/colors';
import CustomSelect from 'src/components/custom-select';
import { ProductReview as ProductReviewType } from 'src/lib/api-client/adept-mind-types';
import { getProductReviews } from 'src/lib/api-client/adept-mind';
import useDebounce from 'src/hooks/useDebounce';
import ProductReviewItem from './product-review-item';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        minHeight: '200px',
    },
    searchInputContainer: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        margin: '.95rem 1.25rem .5rem',
        padding: '0.25rem 0',
        borderRadius: '1.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
        backgroundColor: COLORS.primaryWhite,
    },
    rating: {
        fontWeight: 'bold',
    },
    searchInput: {
        paddingLeft: '0.1rem',
    },
    searchIconBtn: {
        marginRight: '0.1rem',
    },
    list: {
        color: 'blue',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    loadMoreButton: {
        padding: 0,
        border: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        color: COLORS.textBlack,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    backToTopButton: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        border: 'none',
        color: '#086DD2',
        fontWeight: 800,
        backgroundColor: 'transparent',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    chevronUpIcon: {
        width: 20,
        marginTop: '3px',
    },
    reviewsLoading: {
        width: '100%',
        padding: '1rem',
        textAlign: 'center',
    },
    noReviewMessage: {
        margin: 0,
        marginTop: '1rem',
        color: '#CC0000',
        textAlign: 'center',
    },
    reviewsAlign: {
        whiteSpace: 'nowrap',
    },
    starWrapper: {
        '& img': {
            width: '2rem',
        },
    },
    noReview: {
        fontSize: '1.5rem',
        lineHeight: '50px',
    },
});

function ProductReview(props: {
    rating: number;
    numReviews: number;
    productId: string;
    setIsReviewsLoaded: (setIsReviewsLoaded: boolean) => void;
}) {
    const classes = useStyles();
    const { rating, numReviews, productId, setIsReviewsLoaded } = props;
    const [sortOption, setSortOption] = useState({
        value: 'most_recent',
        label: 'Most recent reviews',
    });
    const ratingRef = useRef<HTMLHeadingElement | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const handleSelectChange = (value: any) => {
        setSortOption(value);
    };
    const { data, hasNextPage, fetchNextPage, isFetching, isError } =
        useInfiniteQuery(
            [
                'product-reviews',
                {
                    productId,
                    sortBy: sortOption.value,
                    searchQuery: debouncedSearchQuery,
                },
            ],
            getProductReviews,
            {
                getNextPageParam: (lastPage, allPages) => {
                    if (lastPage && lastPage.length >= 10) {
                        return allPages.length + 1;
                    }
                    return undefined;
                },
            }
        );
    useEffect(() => {
        setIsReviewsLoaded(isFetching);
    }, [isFetching]);
    const reviews = useMemo(() => {
        const combinedReviews: ProductReviewType[] = [];
        data?.pages?.forEach((page) => {
            if (page) {
                combinedReviews.push(...page);
            }
        });
        return combinedReviews;
    }, [data?.pages]);

    const reviewsDisplay = () => {
        if (reviews && reviews.length > 0) {
            return reviews.map((review, index) => (
                <React.Fragment key={review.sku}>
                    <ProductReviewItem
                        rating={parseFloat(review.review_score)}
                        text={review.text}
                        firstName={review.first_name}
                        lastName={review.last_name}
                        title={review.title}
                        timestamp={review.published_at}
                    />
                    {index + 1 !== 10 && index + 1 !== reviews.length && (
                        <Divider color={COLORS.fordGray} />
                    )}
                </React.Fragment>
            ));
        }
        if (searchQuery && !isFetching) {
            return (
                <p className={classes.noReviewMessage}>
                    No reviews found. Please search again.
                </p>
            );
        }
        if (!isFetching && isError) {
            return (
                <p className={classes.noReviewMessage}>
                    Unable to populate reviews. Check again later.
                </p>
            );
        }
        return null;
    };

    return (
        <>
            <Paper ref={ratingRef} className={classes.container} elevation={3}>
                <Typography
                    className={classes.rating}
                    variant="h2"
                    component="p"
                >
                    {numReviews > 0 ? rating : null}
                </Typography>
                <div className={classes.starWrapper}>
                    <ReviewStars ratingValue={rating} />
                </div>
                <Typography variant="body1" className={classes.reviewsAlign}>
                    {numReviews > 0 ? (
                        `${numReviews?.toLocaleString().replace(/,/g, ',')} ${numReviews > 1 ? 'Reviews' : 'Review'
                        }`
                    ) : (
                        <b className={classes.noReview}>No reviews available</b>
                    )}
                </Typography>
                {numReviews !== 0 ? (
                    <>
                        <InputBase
                            id="product-search-input"
                            className={classes.searchInputContainer}
                            placeholder="Search reviews"
                            inputMode="search"
                            type="search"
                            value={searchQuery}
                            aria-label='Search'
                            onChange={(e) => setSearchQuery(e.target.value)}
                            inputProps={{
                                className: classes.searchInput,
                            }}
                            startAdornment={
                                <ButtonBase
                                    id="product-search-button"
                                    className={classes.searchIconBtn}
                                >
                                    <img
                                        id="product-search-icon-img"
                                        src={require('../../../assets/icons/iconSearchThin.svg')}
                                        alt="search icon"
                                    />
                                </ButtonBase>
                            }
                        />
                        <CustomSelect
                            selectedItem={sortOption}
                            fieldName="Sort by"
                            options={[
                                {
                                    value: 'most_recent',
                                    label: 'Most recent reviews',
                                },
                                {
                                    value: 'highest_ratings',
                                    label: 'Highest ratings',
                                },
                                {
                                    value: 'lowest_ratings',
                                    label: 'Lowest ratings',
                                },
                            ]}
                            onChange={handleSelectChange}
                        />
                    </>
                ) : null}
            </Paper>
            {reviewsDisplay()}
            {isFetching && (
                <div className={classes.reviewsLoading}>
                    <CircularProgress
                        size="1.5rem"
                        style={{ color: COLORS.textBlack }}
                    />
                </div>
            )}
            <div className={classes.buttonContainer}>
                {hasNextPage && (
                    <button
                        className={classes.loadMoreButton}
                        type="button"
                        onClick={() => fetchNextPage()}
                    >
                        {`See ${numReviews - reviews?.length
                            } more reviews for this product`}
                    </button>
                )}
                {numReviews > 0 ? (
                    <button
                        className={classes.backToTopButton}
                        type="button"
                        onClick={() => {
                            if (ratingRef) {
                                ratingRef?.current?.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }
                        }}
                    >
                        Back to top{' '}
                        <img
                            className={classes.chevronUpIcon}
                            src={require('../../../assets/icons/iconBlueChevronUp.svg')}
                            alt="chevron"
                        />
                    </button>
                ) : null}
            </div>
        </>
    );
}

export default ProductReview;
