import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { makeStyles, Typography, Box } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import ScrollableRow from 'src/components/scrollable-row';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import { atRecommendationRecentlyViewItems } from 'src/lib/api-client/adobe';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import useStoreData from 'src/hooks/useStoreData';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';

const useStyles = makeStyles({
    container: {
        padding: '15px 20px 0px 20px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
        paddingBottom: '10px',
    },
    link: {
        color: COLORS.linkText,
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflow: 'hidden',
    },
    image: {
        border: `1px solid ${COLORS.whiteSmoke}`,
        borderRadius: '10px',
        width: '160px',
        height: '140px',
        padding: '20px',
        margin: '2px 10px 0px',
    },
});

export default function RecentlyViewedItems() {
    const classes = useStyles();
    const { push, location } = useRouter();
    const { fromPath, setFromPath, end } = useProductBrowseContext();
    const { setSearchSeeAllLink } = useTabsUIStateContext()
    const { mainStore } = useStoreData();
    const seeAllLink = `/deals/recently-viewed/BI3000426`;

    const { data: recentlyViewedItems, refetch } = useQuery(
        ['atRecommendationRecentlyViewItems'],
        () =>
            atRecommendationRecentlyViewItems(
                'RECENTLY_VIEWED_DOTCOM',
                mainStore?.storeNumber,
                mainStore?.address?.postal_code
            )
    );

    const handleSeeAllClick = () => {
        end();
        push(ROUTES.shop);
        setSearchSeeAllLink({
            src: seeAllLink,
            show: true,
        })
    }

    const handleOnClick = (itemurUrl: string) => {
        end();
        push(ROUTES.shop);
        setSearchSeeAllLink({
            src: itemurUrl,
            show: true,
        })
    }


    useEffect(() => {
        refetch();
    }, []);

    return (
        <>
            {recentlyViewedItems?.length !== 0 && (
                <Box className={classes.container}>
                    <Box className={classes.row}>
                        <Typography className={classes.title}>
                            Recently viewed
                        </Typography>
                        <Typography className={classes.link} onClick={handleSeeAllClick}> See all</Typography>
                    </Box>

                    <Box className={classes.imageContainer}>
                        <ScrollableRow id="recently-viewed-list-scrollable-row">
                            {recentlyViewedItems?.map((item: any) => (
                                <Box onClick={() => handleOnClick(item.url)}>
                                    <img
                                        src={item.image}
                                        className={classes.image}
                                        alt="recently viewed item"
                                    />
                                </Box>
                            ))}
                        </ScrollableRow>
                    </Box>
                </Box>
            )}
        </>
    );
}
