/* eslint-disable import/prefer-default-export */

/**
 * Assorted helper functions to associate with workbox and other service worker functions.
 * As well as fallbacks to the native iOS app.
 */
import { useEffect, useState } from 'react';
import { Workbox } from 'workbox-window';
import platform from 'platform-detect';
import Log from 'src/lib/logging';
import useLocalStorage, {
    LOCAL_STORAGE_KEYS,
    removeFromLocalStorage,
} from 'src/hooks/useLocalStorage';
import useFirebaseRemoteConfigValue from 'src/hooks/useFirebaseRemoteConfigValue';
import { REMOTE_CONFIG_KEYS } from 'src/lib/firebase/config';
import useDynamicLink from 'src/hooks/useDynamicLink';
import useRouter from 'src/hooks/useRouter';
import { dynamicLinkSupportedPathsType } from 'src/lib/utils/dynamic-link-utils';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { openInNewTab } from './nav-utils';

const isIos = platform.ios;
const isWindows = platform.windows;

// Allows us to re-ask on new sign-in
export function clearInstallPrompt() {
    removeFromLocalStorage(LOCAL_STORAGE_KEYS.INSTALL_PROMPT);
}

// export const guestMode = () => {
//     const isGuestMode = sessionStorage.getItem('guest') === 'ON';
//     return isGuestMode;
// };

// export const storeMode = () => {
//     const isStoreMode = sessionStorage.getItem('storeModeDismissed') === 'true';
//     return isStoreMode;
// };

// export const setIsStoreModeOn = (state: boolean) => {
//     sessionStorage.setItem('isStoreModeOn', JSON.stringify(state));
// };

// export const isStoreModeOn = (): boolean =>
//     JSON.parse(sessionStorage.getItem('isStoreModeOn') || 'false');

// preserve the opt-out preferences for each user
function useSawInstallPrompt() {
    const [sawInstallData, setSawInstallData] = useLocalStorage<string | null>(
        LOCAL_STORAGE_KEYS.INSTALL_PROMPT,
        null
    );
    function setSawInstallPrompt(reason = '1') {
        setSawInstallData(reason);
    }
    const sawInstallPrompt = !!sawInstallData;
    return { sawInstallPrompt, setSawInstallPrompt };
}

export function useIOSAppInstall() {
    const { pathname } = useRouter();
    const { doDynamicLink, dynamicLink } = useDynamicLink(
        pathname as dynamicLinkSupportedPathsType
    );
    const { remoteConfigValue } = useFirebaseRemoteConfigValue(
        REMOTE_CONFIG_KEYS.show_ios_install_prompt
    );
    const remoteConfigCanInstall =
        remoteConfigValue && remoteConfigValue?.asBoolean();
    const { sawInstallPrompt, setSawInstallPrompt } = useSawInstallPrompt();
    function close() {
        setSawInstallPrompt('ios');
    }
    function onAcceptIOSInstall() {
        openInNewTab('https://l.staplesconnect.com/app');
        // doDynamicLink();
        close();
    }
    const isIOSInstallPromptAvailable =
        process.env.REACT_APP_IOS_APP_STORE_LINK !== undefined &&
        remoteConfigCanInstall &&
        isIos &&
        !isWindows &&
        !sawInstallPrompt;

    console.log({
        remoteConfigCanInstall,
        isIos,
        isWindows,
        sawInstallPrompt,
    });
    useEffect(() => {
        // keeping this for now for easier debugging in the wild
        Log.ios(`iOS install prompt ${isIOSInstallPromptAvailable ? 'was' : 'was not'
            } displayable (all must be true):  
     App store link set in build: ${process.env.REACT_APP_IOS_APP_STORE_LINK !== undefined
            }
     Flag enabled in firebase: ${remoteConfigCanInstall}
     iOS device detected: ${isIos}
     Windows device not detected: ${!isWindows}
     Install prompt not already shown during this user session: ${!sawInstallPrompt}`);
    }, [
        isIOSInstallPromptAvailable,
        remoteConfigCanInstall,
        isIos,
        isWindows,
        process.env.REACT_APP_IOS_APP_STORE_LINK,
    ]);

    return {
        isIOSInstallPromptAvailable:
            process.env.REACT_APP_IOS_APP_STORE_LINK !== undefined &&
            remoteConfigCanInstall &&
            isIos &&
            !isWindows,
        onAcceptIOSInstall,
        onDenyIOSInstall: close,
    };
}

export function usePWAInstall() {
    const { sawInstallPrompt, setSawInstallPrompt } = useSawInstallPrompt();
    const isPWASupported = 'serviceWorker' in navigator;
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    useEffect(() => {
        if (window.globalCapturedInstallPromptEvent && deferredPrompt == null) {
            const e = window.globalCapturedInstallPromptEvent;
            Log.serviceWorker('beforeinstallprompt captured prior to load');
            // preventDefault already called for globalCapturedInstallPromptEvent
            Log.serviceWorker(e);
            setDeferredPrompt(e);
            Log.serviceWorker('beforeinstallprompt deferred, clearing state');
            window.globalCapturedInstallPromptEvent = null;
        }
    });
    useEffect(() => {
        const handler = (e: any) => {
            Log.serviceWorker('beforeinstallprompt');
            Log.serviceWorker(e);
            // Prevent the prompt from appearing immediately
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
        };
        window.addEventListener('beforeinstallprompt', handler);
        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);
    const isBeforeInstallPromptAvailable = deferredPrompt !== null;
    /** prompts the user to install the PWA iff their browser supports it */
    function promptInstallSafe() {
        if (deferredPrompt) {
            deferredPrompt.prompt();
        }
    }

    function onAcceptInstall() {
        // hide additional prompts until we sign in again
        setSawInstallPrompt('pwa-install');
        if (window.location.origin === 'https://qe101.staples.com') {
            return window.open(
                'https://play.google.com/store/apps/details?id=com.staplesconnect.qe_app.twa',
                '_blank'
            );
        }
        return window.open(
            'https://play.google.com/store/apps/details?id=com.staplesconnect.app.twa',
            '_blank'
        );
        // probably log this to analytics
    }

    function onDenyInstall() {
        // hide additional prompts until we sign in again
        setSawInstallPrompt();
        // probably log this to analytics
    }

    // navigator.standalone === iOS, otherwise fallback to media query
    const isAlreadyInstalled =
        (navigator as any)?.standalone ||
        matchMedia('(display-mode: standalone)').matches;
    const isPromptAvailable =
        isPWASupported &&
        isBeforeInstallPromptAvailable &&
        !isAlreadyInstalled &&
        !sawInstallPrompt;
    useEffect(() => {
        // keeping this for now for easier debugging in the wild
        Log.serviceWorker(`PWA install prompt ${isPromptAvailable ? 'was' : 'was not'
            } displayed (all must be true):  
    current browser supports service workers: ${isPWASupported}
    install prompt event available from browser: ${isBeforeInstallPromptAvailable}
    install prompt not already shown during this user session: ${!sawInstallPrompt}
    PWA not already installed: ${!isAlreadyInstalled}`);
    }, [isPromptAvailable]);
    return {
        isPWASupported,
        isAlreadyInstalled,
        isPromptAvailable,
        onAcceptInstall,
        onDenyInstall,
    };
}

export function usePWAUpdate() {
    const { sawInstallPrompt, setSawInstallPrompt } = useSawInstallPrompt();
    const [showReloadPrompt, setShowReloadPrompt] = useState<boolean>(false);
    const [onAcceptReload, setOnAcceptReload] = useState<() => void>(
        () => () => null
    );
    const [wb, setWB] = useState<Workbox | null>(null);
    useEffect(() => {
        if (
            (process.env.NODE_ENV === 'production' ||
                process.env.REACT_APP_OVERRIDE_SW_INSTALL) &&
            'serviceWorker' in navigator
        ) {
            Log.serviceWorker('serviceWorker detected. setting up Workbox');
            setWB(new Workbox('/somniapp/assets/service-worker.js'));
        }
    }, []);
    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    const handleWaitingEvent = (waitingEvent: any) => {
        Log.serviceWorker('handleWaitingEvent');
        Log.serviceWorker(waitingEvent);

        // `event.wasWaitingBeforeRegister` will be false if this is
        // the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously
        // updated service worker is still waiting.
        // we may want to customize the UI prompt accordingly.
        setShowReloadPrompt(true);
    };

    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        if (wb && !isWaiting) {
            wb.addEventListener('waiting', handleWaitingEvent);
            setIsWaiting(true);
            setOnAcceptReload(() => () => {
                Log.serviceWorker('On reload accepted');
                const isTWA =
                    sessionStorage.getItem('isTWA') === 'true' ||
                    new URLSearchParams(window.location.search).get(
                        'utm_source'
                    ) === 'trusted-web-activity';
                sessionStorage.setItem('isTWA', `${isTWA}`);
                if (isTWA) {
                    window.open(
                        'https://play.google.com/store/apps/details?id=com.staplesconnect.app.twa',
                        '_blank'
                    );
                } else if (wb) {
                    // Assuming the user accepted the update, set up a listener
                    // that will reload the page as soon as the previously waiting
                    // service worker has taken control.
                    wb.addEventListener('controlling', (controlEvent: any) => {
                        Log.serviceWorker('controlling');
                        Log.serviceWorker(controlEvent);
                        // eslint-disable-next-line no-debugger
                        // we don't care about removing this listener
                        // because the window is destroyed on reload
                        window.location.reload();
                    });
                    // tell the SW to skip waiting
                    wb.messageSW({ type: 'SKIP_WAITING' });
                }
            });
        }
    }, []);

    function onDenyReload() {
        setShowReloadPrompt(false);
        setSawInstallPrompt('pwa-update');
    }
    return {
        showReloadPrompt: showReloadPrompt && !sawInstallPrompt,
        onDenyReload,
        onAcceptReload,
    };
}

export const SetSomniAppCookies = () => {
    const isOmniappCookiesPresent = Cookies.get('SOMNIAPP') === 'Y';
    console.log(
        '🚀 ~ file: app-utils.ts:258 ~ SetSomniAppCookies ~ isOmniappCookiesPresent:',
        isOmniappCookiesPresent
    );
    if (!isOmniappCookiesPresent) {
        Cookies.set('SOMNIAPP', 'Y', {
            domain: process.env.REACT_APP_Domain,
            expires: 54 * 60 * 60 * 24 * 7,
        });
    }
};

export const removeSomniAppCookies = () => {
    const isOmniappCookiesPresent = Cookies.get('SOMNIAPP') === 'Y';
    console.log(
        '🚀 ~ file: app-utils.ts:258 ~ SetSomniAppCookies ~ isOmniappCookiesPresent:',
        isOmniappCookiesPresent
    );
    if (isOmniappCookiesPresent) {
        Cookies.set('SOMNIAPP', 'Y', {
            domain: process.env.REACT_APP_Domain,
            expires: new Date('Thu, 01 Jan 1970 00:00:00 UTC'),
        });
    }
};

export const removeSmsession = () => {
    const isSmSessionCookiesPresent = Cookies.get('SMSESSION');
    if (isSmSessionCookiesPresent) {
        Cookies.set('SMSESSION', 'LOGGEDOFF', {
            domain: process.env.REACT_APP_Domain,
            expires: new Date('Thu, 01 Jan 1970 00:00:00 UTC'),
        });
    }
};

export const removeGustsessionCookies = () => {
    const xdeviceid = Cookies.get('xdeviceid');
    const ctxToken = Cookies.get('ctx-token');
    const ctxId = Cookies.get('ctx-id');

    if (xdeviceid) {
        Cookies.set('xdeviceid', xdeviceid, {
            domain: process.env.REACT_APP_Domain,
            expires: new Date('Thu, 01 Jan 1970 00:00:00 UTC'),
        });
    }

    if (ctxToken) {
        Cookies.set('ctx-token', ctxToken, {
            domain: process.env.REACT_APP_Domain,
            expires: new Date('Thu, 01 Jan 1970 00:00:00 UTC'),
        });
    }

    if (ctxId) {
        Cookies.set('ctx-id', ctxId, {
            domain: process.env.REACT_APP_Domain,
            expires: new Date('Thu, 01 Jan 1970 00:00:00 UTC'),
        });
    }
};

export const precache = (reload = true) => {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names?.forEach((name) => {
                caches?.delete(name);
            });
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        if (reload) {
            window?.location?.reload();
        }
    }
};

export const akamaiTelemetryHeader = {
    'Akamai-BM-Telemetry':
        ((window as any) &&
            (window as any).bmak &&
            (window as any).bmak.get_telemetry()) ||
        '',
};

export const keepLocalAndSessionStorage = () => {
    console.log(
        '🚀 ~ keepLocalAndSessionStorage ~ keepLocalAndSessionStorage:'
    );
};

export const numberWithCommas = (x: string) =>
    x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const removeStplSessionIdUniqueId = () => {
    const StplSessionIdUniqueId = sessionStorage.getItem(
        'StplSessionIdUniqueId'
    );
    if (StplSessionIdUniqueId) {
        console.log(
            '🚀 ~ SetStplSessionIdUniqueId ~ isStaplesIdPresent:',
            StplSessionIdUniqueId
        );
        sessionStorage.removeItem('StplSessionIdUniqueId');
    }
};

export const getStplSessionIdUniqueId = () => {
    const StplSessionIdUniqueId =
        sessionStorage.getItem('StplSessionIdUniqueId') || null;
    console.log(
        '🚀 ~ getStplSessionIdUniqueId ~ getStplSessionIdUniqueId:',
        StplSessionIdUniqueId
    );
    return StplSessionIdUniqueId;
};

export const SetStplSessionIdUniqueId = () => {
    const isStaplesIdPresent = !!sessionStorage.getItem(
        'StplSessionIdUniqueId'
    );
    console.log(
        '🚀 ~ getStplSessionIdUniqueId ~ getStplSessionIdUniqueId:',
        isStaplesIdPresent
    );
    if (!isStaplesIdPresent) {
        console.log(
            '🚀 ~ SetStplSessionIdUniqueId ~ isStaplesIdPresent:',
            isStaplesIdPresent
        );
        sessionStorage.setItem('StplSessionIdUniqueId', uuidv4());
    }
};

export const todayDate = () => {
    const today: Date = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    // eslint-disable-next-line prefer-template
    const formatteDate = mm + '/' + dd + '/' + yyyy;
    return formatteDate
}

export function parseDate(dateString: string) {
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
}