import React from 'react';
import IconInterface from './IconInterface';


function IconRecentPurchase({ width = 40, height = 40 }: IconInterface) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 40C9 40 0 31 0 20C0 9 9 0 20 0C31 0 40 9 40 20C40 31 31 40 20 40ZM20 1.42857C9.78571 1.42857 1.42857 9.78571 1.42857 20C1.42857 30.2143 9.78571 38.5714 20 38.5714C30.2143 38.5714 38.5714 30.2143 38.5714 20C38.5714 9.78571 30.2143 1.42857 20 1.42857Z" fill="#CC0000"/>
<path d="M20 28.0714C18.8571 28.0714 17.7143 27.8571 16.6429 27.4286C15.6429 27 14.7143 26.3571 13.9286 25.5714C13.1429 24.7857 12.5 23.8571 12.0714 22.8571C11.6429 21.7857 11.4286 20.7143 11.4286 19.5C11.4286 18.2857 11.6429 17.2142 12.0714 16.1428C12.5 15.1428 13.1429 14.2143 13.9286 13.4286C14.7143 12.6429 15.6429 12 16.6429 11.5714C18.7857 10.7143 21.2143 10.7143 23.2857 11.5714C24.2857 12 25.2143 12.6429 26 13.4286C26.7857 14.2143 27.4286 15.1428 27.8571 16.1428C28.2857 17.2142 28.5 18.2857 28.5 19.5C28.5 20.7143 28.2857 21.7857 27.8571 22.8571C27.7143 23.2143 27.2857 23.3571 26.9286 23.2143C26.5714 23.0714 26.4286 22.6429 26.5714 22.2857C26.9286 21.4286 27.1429 20.5 27.1429 19.5C27.1429 18.5 26.9286 17.5714 26.5714 16.7143C26.2143 15.8571 25.7143 15.0714 25.0714 14.4286C24.4286 13.7857 23.6429 13.2857 22.7857 12.9285C21 12.2142 19 12.2142 17.2143 12.9285C16.3571 13.2857 15.5714 13.7857 14.9286 14.4286C14.2857 15.0714 13.7857 15.8571 13.4286 16.7143C13.0714 17.5714 12.8571 18.5 12.8571 19.5C12.8571 20.5 13.0714 21.4286 13.4286 22.2857C13.7857 23.1429 14.2857 23.9286 14.9286 24.5714C15.5714 25.2143 16.3571 25.7143 17.2143 26.0714C18.0714 26.4285 19 26.6429 20 26.6429C20.4286 26.6429 20.7143 26.9286 20.7143 27.3571C20.7143 27.7857 20.4286 28.0714 20 28.0714Z" fill="#CC0000"/>
<path d="M26.5715 24.4286C26.2858 24.4286 26.0001 24.2857 25.9286 23.9999L24.3572 20.3571C24.2143 20 24.3572 19.5714 24.7143 19.4286C25.0715 19.2857 25.5001 19.4286 25.6429 19.7857L27.2143 23.4286C27.3572 23.7857 27.2143 24.2142 26.8572 24.3571C26.7858 24.4285 26.6429 24.4286 26.5715 24.4286Z" fill="#CC0000"/>
<path d="M26.5714 24.4286C26.2857 24.4286 26 24.2857 25.9286 24C25.7857 23.6429 25.9286 23.2143 26.2857 23.0715L29.9285 21.5C30.2857 21.3571 30.7143 21.5 30.8571 21.8571C31 22.2143 30.8571 22.6429 30.5 22.7857L26.8571 24.3571C26.7857 24.4286 26.6428 24.4286 26.5714 24.4286Z" fill="#CC0000"/>
<path d="M19.9286 23.7857C18.9286 23.7857 17.8571 23.3572 17.0714 22.6429C16.7857 22.3572 16.7857 21.9286 17.0714 21.6429C17.3571 21.3572 17.7857 21.3572 18.0714 21.6429C18.7143 22.2857 19.8571 22.5715 20.5714 22.2858C20.9286 22.1429 21.1428 21.7857 21.1428 21.3572C21.1428 20.8572 20.7857 20.6429 19.5714 20.2857C18.5 19.9286 17.1428 19.5 17.0714 18C17.0714 17.0715 17.5714 16.2858 18.4286 15.8572C19.5 15.3572 20.9286 15.5714 22.0714 16.5C22.3571 16.7143 22.4286 17.2143 22.1428 17.5C21.9286 17.7857 21.4286 17.8571 21.1428 17.5714C20.2857 16.8571 19.5 16.8572 19 17.0714C18.7857 17.1429 18.5 17.4285 18.5 17.9285C18.5 18.3571 18.8571 18.5714 20 18.9286C21.0714 19.2857 22.5714 19.7143 22.5714 21.3572C22.5714 22.4286 22.0714 23.2143 21.1428 23.6428C20.7857 23.7143 20.3571 23.7857 19.9286 23.7857Z" fill="#CC0000"/>
<path d="M19.7857 25.2143C19.3571 25.2143 19.0714 24.9286 19.0714 24.5V23.1429C19.0714 22.7143 19.3571 22.4286 19.7857 22.4286C20.2143 22.4286 20.5 22.7143 20.5 23.1429V24.5C20.5 24.8572 20.1428 25.2143 19.7857 25.2143Z" fill="#CC0000"/>
<path d="M19.7857 16.8571C19.3571 16.8571 19.0714 16.5714 19.0714 16.1428V14.7858C19.0714 14.3572 19.3571 14.0715 19.7857 14.0715C20.2143 14.0715 20.5 14.3572 20.5 14.7858V16.1428C20.5 16.5714 20.1428 16.8571 19.7857 16.8571Z" fill="#CC0000"/>
</svg>
    );
}

export default IconRecentPurchase;