import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconShield({
    width = 80,
    height = 80,
    fillColor = 'fillRed',
}: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M40 80C18 80 0 62 0 40C0 18 18 0 40 0C62 0 80 18 80 40C80 62 62 80 40 80ZM40 2.85714C19.5714 2.85714 2.85714 19.5714 2.85714 40C2.85714 60.4286 19.5714 77.1429 40 77.1429C60.4286 77.1429 77.1429 60.4286 77.1429 40C77.1429 19.5714 60.4286 2.85714 40 2.85714ZM40.4286 63.5714C40.1428 63.5714 39.8571 63.5714 39.7143 63.4286C18.8571 50.8571 20.8571 29.1428 21.5714 24.8571C21.5714 25.1428 21.5714 25.5714 21.8571 25.8572C21.5714 25.4286 21.5714 25 21.7143 24.5714C21.8572 24 22.5714 23.5714 23.1429 23.5714C26 23.2857 37.4286 21 39.2857 17.7143C39.8571 16.8571 41.2857 16.8571 41.7143 17.7143C43.5714 21 54.8571 23.2857 57.8571 23.5714C58.4286 23.5714 59 24 59.2857 24.5714C59.2857 24.7143 59.2857 24.8571 59.2857 24.8571C60 29.2857 62 50.8571 41.1428 63.4286C41 63.4286 40.7143 63.5714 40.4286 63.5714ZM24.2857 26.1429C23.7143 31.5714 23.1428 49.4286 40.4286 60.4286C57.8571 49.4286 57.2857 31.4286 56.5714 26.1429C53.2857 25.5714 44.1428 24 40.4286 20.5714C36.7143 24 27.5714 25.5714 24.2857 26.1429ZM39.4285 48.4286C39 48.4286 38.5715 48.2857 38.4286 48L31.5714 40.2857C31 39.7143 31.1429 38.8571 31.7143 38.2857C32.2857 37.7143 33.1428 37.8571 33.7143 38.4286L39.2857 44.7143L48.4286 32.5714C48.8572 32 49.8571 31.8572 50.4286 32.2857C51 32.7143 51.1429 33.7143 50.7143 34.2857L40.5715 47.8571C40.2858 48.1429 39.8571 48.4286 39.4285 48.4286Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconShield;
