/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    Typography,
    Backdrop,
    withStyles,
    makeStyles,
} from '@material-ui/core';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import useUserInfo from 'src/hooks/useUserInfo';
import useCardCanvas from 'src/hooks/useCardCanvas';
import COLORS from 'src/lib/colors';
import useDebounce from 'src/hooks/useDebounce';
import { getLoyaltyAccountDetails } from 'src/services/easyRewardsServices';
import { Body } from 'src/components/UI';
import { getCustomerPriceTypes } from 'src/lib/api-client/wallet-activities';
import { useQuery } from 'react-query';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { Player } from '@lottiefiles/react-lottie-player';
import { IconEasyRewardsHorizontal } from 'src/components/Icons';

const RADIUS = 1;

const PatternBackdrop = withStyles({
    root: {
        backgroundImage: `url(bgs/bgIllustrationDarkbackground.svg)`,
        backgroundColor: COLORS.homeBlack,
    },
})(Backdrop);

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        overflow: 'visible',
    },
    contentWrapper: {
        display: 'flex',
        overflow: 'hidden',
    },
    verticalTextWrapper: {
        width: '5rem',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        borderTopLeftRadius: `${RADIUS}rem`,
        borderBottomLeftRadius: `${RADIUS}rem`,
    },
    verticalText: {
        transform: 'rotate(-90deg) translate(16px, 0)',
        color: COLORS.primaryWhite,
        fontWeight: 'bold',
        transformOrigin: 'center',
        width: '20rem',
        display: 'block',
        position: 'absolute',
        left: '-8rem',
        top: '50%',
        textAlign: 'center',
    },
    verticalTextBg: {
        left: 0,
        right: 0,
        height: '100%',
        position: 'absolute',
        backgroundColor: COLORS.greyMetallic,
        overflow: 'hidden',
        borderRadius: '50%',
        transform: 'scale(1.5) translateX(-30%)',
    },
    closeIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        width: '20px',
        height: '20px',
    },
    centerColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        overflow:'hidden',
    },
    userName:{
        width: '260px',
        textAlign: 'center',
        marginTop: '13px !important',
    },
    staplesLogo: {
        height: '1.2rem',
        marginTop: '.5rem',
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 400,
        margin: '13px 0px 10px 0px',
    },
    rewardsNumber: {
        fontSize: '1.7rem',
        color: COLORS.brandRed,
        marginBottom: '0.4rem',
        letterSpacing: '0.1rem',
    },
    renderedCardNumber: {
        height: '6rem',
        width: '337px',
    },
    memberTypeContainer: {
        display: 'flex',
        alignItems: 'center',
        color: 'grey',
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '.5rem',
        fontSize: '.75rem',
        paddingBottom: '.5rem',
    },
    cardCornerPillLeft: {
        position: 'relative',
        left: '-1.1rem',
        height: '45%',
    },
    cardCornerPillRight: {
        position: 'relative',
        right: '-1.1rem',
        bottom: '-10.6rem',
        height: '45%',
    },
});

function MemberCardController() {
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const userHasMembershipNumber = !!userInfo?.rewardsNumber;
    const [isDismissed, setDismissed] = useState(false);
    const [rewardstiercode, setRewardstiercode] = useState('');
    const [isLandscape, setIsLandscape] = useState(false);
    const { data: customerPriceTypeData } = useQuery(
        ['CustomerPriceTypes', userInfo?.rewardsNumber],
        () => getCustomerPriceTypes(userInfo?.rewardsNumber),
        {
            ...NO_REFETCH,
            enabled: !!userInfo?.rewardsNumber,
        }
    );

    getLoyaltyAccountDetails({
        config: {
            enabled: !!userInfo?.rewardsNumber,
            onSuccess: (res) => {
                setRewardstiercode(res?.accounts[0]?.rewardstiercode);
            },
        },
        rewardsNumber: userInfo?.rewardsNumber,
    });

    useEffect(() => {
        window.addEventListener('resize', ()=> {
            setIsLandscape(window.innerWidth > window.innerHeight)
        })
    }, []);

    useEffect(() => {
        if (!isLandscape) {
            setDismissed(false);
        }
    }, [isLandscape]);

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.MEMBER_CARD,
        isLandscape && !isDismissed && userHasMembershipNumber
    );
    const delayedIsActiveScreen = useDebounce(
        isLandscape && !isDismissed && userHasMembershipNumber,
        500
    );

    const { canvasRef, cardRedraw } = useCardCanvas(userInfo?.rewardsNumber);

    return (
        <Dialog
            open={delayedIsActiveScreen}
            classes={{
                paper: classes.paper,
            }}
            onAnimationEnd={cardRedraw}
            onTransitionEnd={cardRedraw}
            BackdropComponent={PatternBackdrop}
            scroll="body"
        >
            <div className={classes.contentWrapper}>
                    <Player autoplay loop className={classes.cardCornerPillLeft}
                        src={require('../assets/lotties/rewards-enrollment/lg_digital_card_corner-l_pills.json')} />
                    <DialogContent className={classes.centerColumn}>
                        <Body className={classes.title}>
                            <span tabIndex={0} aria-label="Easy Rewards digital card" style={{margin: "13px 0px -9px 0px"}}>
                                <IconEasyRewardsHorizontal
                                    width={120}
                                    height={25}
                                    fillColor="fillRed"
                                />  
                            </span>  
                            <b tabIndex={0} aria-label={`Your Easy Rewards # is ${userInfo?.rewardsNumber}`} className={classes.rewardsNumber}>
                                {userInfo?.rewardsNumber}
                            </b>
                        </Body>
                        <canvas
                            aria-label="Your personal Easy Rewards barcode is here to scan at checkout when you are in a Staples store"
                            tabIndex={0}
                            ref={canvasRef}
                            className={classes.renderedCardNumber}
                        />
                        <Body 
                            tabIndex={0} size={26} weight='bold' className={classes.userName}>
                            {userInfo?.firstName} {userInfo?.lastName}
                        </Body>
                        {customerPriceTypeData?.data?.customerPriceType ===
                            'Chamber' ? (
                            <Body tabIndex={0} aria-label="You are a CHAMBER MEMBER" size={14} weight='bold' margin="0 0 10px 0" className={classes.memberTypeContainer}>
                                CHAMBER MEMBER
                            </Body>
                        ) : (
                            <Body tabIndex={0} size={14} weight='bold' margin="0 0 10px 0" className={classes.memberTypeContainer}>
                                {userInfo?.rewardsMemberType === 'Associate' || rewardstiercode === 'ARW'
                                    ? 
                                    <>
                                        <img
                                        src={require("../assets/icons/memberStaplesLogo.svg")}
                                        alt={`You are a ${userInfo?.rewardsMemberType === 'Associate' || rewardstiercode === 'ARW'
                                        ? 'VALUED ASSOCIATE': 'MEMBER'}`} 
                                        style={{width: '25px', marginRight: '0.4rem'}}
                                        />
                                        {'VALUED ASSOCIATE'}
                                    </>
                                    : 'MEMBER'}
                            </Body>
                        )}

                        <Typography tabIndex={0} aria-label="Rotate phone to go back" className={classes.subtitle}>
                            <img style={{marginRight: '0.4rem'}} src={require('../assets/icons/rotate-phone.svg')} alt="rotate phone icon" />
                            Rotate phone to go back
                        </Typography>
                    </DialogContent>
                    <img tabIndex={0} aria-label="Close button " className={classes.closeIcon} src={require('../assets/icons/close-icon.svg')} alt="rotate phone icon" />
                    <Player autoplay loop className={classes.cardCornerPillRight}
                        src={require('../assets/lotties/rewards-enrollment/lg_digital_card_corner-r_pills.json')} />
            </div>
        </Dialog>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedRewardsController = () => <MemberCardController />;
export default WrappedRewardsController;
