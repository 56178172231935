/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useRef, useState } from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { IconCheckmark } from './Icons';

interface CustomSelect {
    // id?: string;
    // className?: string;
    fieldName: string;
    selectedItem?: { value: any; label: string } | null;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange: Function;
    options: { value: any; label: string }[];
}

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
    searchInputContainer: {
        display: 'flex',
        width: '100%',
        backgroundColor: COLORS.primaryWhite,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    mostRecentReviews: {
        width: '100%',
        display: 'inline-flex',
        borderRadius: '20rem',
        justifyContent: 'space-between',
        backgroundColor: COLORS.primaryWhite,
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    sortByTitle: {
        textAlign: 'left',
        marginLeft: '1.313rem',
        fontSize: '1rem',
    },
    sortByTitleAlign: {
        margin: '0 3px',
    },
    input: {
        flexGrow: 1,
        border: 'none',
        userSelect: 'none',
        borderRadius: '1.5rem',
        paddingLeft: '1.2rem',
        fontSize: '1rem',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:disabled': {
            backgroundColor: 'white',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    listContainer: {
        boxSizing: 'content-box',
        backgroundColor: COLORS.primaryWhite,
        borderRadius: '1.5rem',
        listStyle: 'none',
        transitionDuration: '500ms',
        transitionTimingFunction: 'ease-in-out',
        marginTop: '0.5rem',
        overflow: 'hidden',
        padding: '0.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    close: {
        height: 0,
        padding: 0,
        border: `0px solid transparent`,
    },
    listItem: {
        boxSizing: 'border-box',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    listButton: {
        fontSize: '1rem',
        padding: '0.5rem',
        width: '100%',
        justifyContent: 'space-between',
        borderRadius: '1.5rem',
        color: '#b9b9b9',
        transitionDuration: '100ms',
        '&:hover': {
            color: COLORS.secondaryNeutralBlack,
        },
    },
    selected: {
        color: COLORS.secondaryNeutralBlack,
    },
    chevronButton: {
        marginRight: '0.75rem',
    },
});

const CustomSelect = (props: CustomSelect) => {
    const { selectedItem, onChange, options, fieldName } = props;
    const value = selectedItem?.value;
    const label = selectedItem?.label;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLUListElement>(null);
    const closeSelectBox = () => {
        setIsOpen((prevValue) => {
            if (!prevValue && containerRef?.current) {
                containerRef.current.style.height = `${containerRef.current.scrollHeight}px`;
            } else if (prevValue && containerRef?.current) {
                containerRef.current.style.height = '0px';
            }
            return !prevValue;
        });
    };
    return (
        <div className={classes.container}>
            <ButtonBase
                className={classes.searchInputContainer}
                onClick={closeSelectBox}
            >
                <fieldset className={classes.mostRecentReviews}>
                    <legend className={classes.sortByTitle}>
                        <span>{fieldName}</span>
                    </legend>
                    <input
                        // TODO change this to use a variable
                        placeholder="Click to sort"
                        disabled
                        className={classes.input}
                        type="text"
                        value={label}
                    />

                    <img
                        className={classes.chevronButton}
                        src={
                            isOpen
                                ? require('../assets/icons/iconChevronUp.svg')
                                : require('../assets/icons/iconChevronDown.svg')
                        }
                        alt="chevron"
                    />
                </fieldset>
            </ButtonBase>
            <ul
                ref={containerRef}
                className={`${classes.listContainer} ${
                    isOpen ? null : classes.close
                }`}
            >
                {options.map((item) => (
                    <li className={classes.listItem}>
                        <ButtonBase
                            className={`${classes.listButton}  ${
                                value === item.value ? classes.selected : null
                            }`}
                            onClick={() => {
                                onChange(item);
                                closeSelectBox();
                            }}
                        >
                            <span>{item.label}</span>
                            {value === item.value && <IconCheckmark />}
                        </ButtonBase>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CustomSelect;
