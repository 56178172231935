/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */
const ENCODED_CHAR_SET = '134679ACDEFGHIJKLMNPQRTWXY';
const DECODED_CHAR_SET = '0123456789abcdefghijklmnop';
const CHARACTER_MAPPING: { [key: string]: string } = {
  '0': '1',
  '1': '3',
  '2': '4',
  '3': '6',
  '4': '7',
  '5': '9',
  '6': 'A',
  '7': 'C',
  '8': 'D',
  '9': 'E',
  a: 'F',
  b: 'G',
  c: 'H',
  d: 'I',
  e: 'J',
  f: 'K',
  g: 'L',
  h: 'M',
  i: 'N',
  j: 'P',
  k: 'Q',
  l: 'R',
  m: 'T',
  n: 'W',
  o: 'X',
  p: 'Y',
};
const BASE = ENCODED_CHAR_SET.length;
const ORGANIZATION = 1;

const encodeLong = (number: number, length: number): string => {
  const converted = number.toString(BASE).padStart(length, '0');
  let output = '';
  for (const char of converted) {
    output += CHARACTER_MAPPING[char];
  }
  return output;
};

const encodeDate = (date: string, length: number): string => {
  const dt = new Date(Date.parse(date));
  const year = dt.getFullYear();
  // calculate the day of the year
  const timestamp1 = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate());
  const timestamp2 = Date.UTC(dt.getFullYear(), 0, 0);
  const differenceInMilliseconds = timestamp1 - timestamp2;
  const differenceInDays = differenceInMilliseconds / 1000 / 60 / 60 / 24;
  const dayOfYear = differenceInDays.toString();
  const shiftedDate = parseInt(dayOfYear, 10) + (year << 9);
  return encodeLong(shiftedDate, length);
};

const getChecksum = (barcode: string): string => {
  let checksum = 13;
  for (let i = 0; i < barcode.length; i += 1) {
    const charValue = barcode.charCodeAt(i);
    checksum ^= charValue * (i + 1);
  }
  const truncatedChecksum = (checksum % BASE).toString(BASE);
  return CHARACTER_MAPPING[truncatedChecksum];
};

export const encodeBarcode = (
  store: number,
  register: number,
  transaction: number,
  date: string
): string => {
  const start = 'T';
  const encodedOrganization = encodeLong(ORGANIZATION, 3);
  const encodedStore = encodeLong(store, 3);
  const encodedRegister = encodeLong(register, 3);
  const encodedTransaction = encodeLong(transaction, 5);
  const encodedDate = encodeDate(date, 5);
  const barcodeString =
    start +
    encodedOrganization +
    encodedStore +
    encodedRegister +
    encodedTransaction +
    encodedDate;
  const checksum = getChecksum(barcodeString);
  return barcodeString + checksum;
};

// console.log(encodeBarcode(8506, 5, 721, "13-JUL-22")) => T113HK71191133P4AWPD9
