/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
// common validations across formik schemas

// eslint-disable-next-line max-len
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneRegExpShipping = /^\(\d{3}\) \d{3}-\d{4}$/;
export const phoneNumberValidation = yup
  .string()
  .matches(phoneRegExp, 'Phone Number is invalid.');

export const phoneNumberValidationShipping = yup
    .string()
    .matches(phoneRegExpShipping, 'Phone number is invalid.');
