/* eslint-disable max-len */
import React from 'react';
import IconInterface from './IconInterface';

function IconCornerSplash({ height = 32, width = 50 }: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 50 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_447_1366"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="50"
                height="32"
            >
                <path d="M49.1594 0H3.8147e-06V32H49.1594V0Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_447_1366)">
                <path
                    d="M17.6783 3.31564C15.2451 3.31564 13.2726 1.34315 13.2726 -1.09004C13.2726 -3.52324 15.2451 -5.49573 17.6783 -5.49573C20.1115 -5.49573 22.084 -3.52324 22.084 -1.09004C22.084 1.34315 20.1115 3.31564 17.6783 3.31564Z"
                    fill="#0FBAFF"
                />
                <path
                    d="M33.5514 8.83461L29.0685 4.35176C27.4251 2.70831 24.7632 2.70831 23.1197 4.35176C21.4763 5.99521 21.4763 8.65716 23.1197 10.3006L27.6026 14.7835C29.246 16.4269 31.9079 16.4269 33.5514 14.7835C35.1929 13.14 35.1929 10.4781 33.5514 8.83461Z"
                    fill="#0058D8"
                />
                <path
                    d="M17.8231 21.0888C15.3899 21.0888 13.4174 19.1163 13.4174 16.6831C13.4174 14.25 15.3899 12.2775 17.8231 12.2775C20.2563 12.2775 22.2288 14.25 22.2288 16.6831C22.2288 19.1163 20.2563 21.0888 17.8231 21.0888Z"
                    fill="#3E009F"
                />
                <path
                    d="M12.0014 4.91275L3.75522 -3.33345C2.11176 -4.9769 -0.550165 -4.9769 -2.19362 -3.33345C-3.83707 -1.69 -3.83707 0.971928 -2.19362 2.61538L6.05258 10.8616C7.69603 12.505 10.358 12.505 12.0014 10.8616C13.6449 9.22006 13.6449 6.5562 12.0014 4.91275Z"
                    fill="#5B0069"
                />
                <path
                    d="M13.8394 24.3546L5.59323 16.1084C3.94978 14.465 1.28785 14.465 -0.355606 16.1084C-1.99906 17.7519 -1.99906 20.4138 -0.355606 22.0572L7.89059 30.3034C9.53405 31.9469 12.196 31.9469 13.8394 30.3034C15.4829 28.66 15.4829 25.9981 13.8394 24.3546Z"
                    fill="#CC0000"
                />
            </g>
        </svg>
    );
}

export default IconCornerSplash;
