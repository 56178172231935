/* eslint-disable  import/no-cycle */
/* eslint import/namespace: [2, { allowComputed: false }] */

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { getSubscriptionKey } from './sign-on';
import { akamaiTelemetryHeader, getStplSessionIdUniqueId, removeSmsession, removeStplSessionIdUniqueId } from '../utils/app-utils';

const LOCAL_LOGIN_DETAILS = 'login-details';

export const setLoginDetails = (details: any) => {
    localStorage.setItem(LOCAL_LOGIN_DETAILS, JSON.stringify({ ...details }))
}

export const getLoginDetails = () => {
    if (localStorage.getItem(LOCAL_LOGIN_DETAILS)) {
        return JSON.parse(localStorage.getItem(LOCAL_LOGIN_DETAILS) as any);
    }

    return null
}

interface ISession {
    getSessionStartedAt: () => number;
    getIsTimedOut: () => boolean;
    killSession: () => void;
}

interface IRefreshToken {
    "access_token": string;
    "token_type": string;
    "expires_in": string;
    "refresh_token": string;
    "refresh_token_expires_in": string;
    "refresh_token_issued_at": number;
    "refresh_token_status": string;
    "access_token_issued_at"?: number;
    "rmToken"?: string;
    "userName"?: string;
    "sub": string;
    "tenant": string;
    "msg"?: string;
    "ssoToken"?: string;
}

/**
 * Attempt to add the token stored in localstorage to all axios requests.
 * Adjust this method we ever chsange our storage policy for our AuthService
 */
export const COMMON_HEADERS = {
    tenant: 'DOTCOM',
    'Ocp-Apim-Trace': true,
    mode: 'no-cors',
    'request-context': 'appId=cid-v1:53c134c3-0943-4db0-aa8e-09dc5222473e',
    credentials: 'include',
    'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Cache-Control': 'no-cache',
}

export class SessionManager {
    static "access_token": string;

    static "token_type": string;

    static "expires_in": string;

    static "refresh_token": string;

    static "ssoToken": string;

    static "refresh_token_expires_in": string;

    static "refresh_token_issued_at": number;

    static "refresh_token_status": string;

    static sub: string;

    static rmToken: string;

    static tenant: string;

    static session: any;

    static "access_token_issued_at": number;

    static isLoggedIn = Boolean(getLoginDetails()?.access_token);

    static userName: string;

    static userToken: string;

    static isUserTokenLoading = false;

    static isUserMigrated = false;

    static isForgotPassword = false;

    static getUserData() {
        return getLoginDetails()
    }

    static setUserData(modifyData: any) {
        setLoginDetails({
            ...SessionManager.getUserData()
            , ...modifyData,
        })
    }

    static setTokens(tokens: any) {
        SessionManager.access_token = tokens.access_token
        SessionManager.token_type = tokens.token_type
        SessionManager.expires_in = tokens.expires_in
        SessionManager.refresh_token = tokens.refresh_token
        SessionManager.refresh_token_expires_in = tokens.refresh_token_expires_in
        SessionManager.refresh_token_issued_at = tokens.refresh_token_issued_at
        SessionManager.refresh_token_status = tokens.refresh_token_status
        SessionManager.sub = tokens.sub
        SessionManager.tenant = tokens.tenant;
        SessionManager.rmToken = tokens.rmToken;
        SessionManager.access_token_issued_at = tokens.access_token_issued_at;
        SessionManager.userName = tokens.userName;
        SessionManager.ssoToken = tokens.ssoToken;
        SessionManager.setUserData({ ...tokens });
    }

    static setIsUserMigrated(data: { isUserMigrated: boolean, userName: string }) {
        SessionManager.isUserMigrated = data.isUserMigrated;
        SessionManager.userName = data.userName;
        SessionManager.setUserData({ ...data });
    }

    static getIsUserMigrated() {
        return SessionManager.isUserMigrated;
    }

    static setUserToken(userToken: string) {
        const modifyData = { ...SessionManager.getUserData(), userToken };
        setLoginDetails({ ...modifyData });
        SessionManager.userToken = userToken;
    }

    static initSession(session: ISession) {
        SessionManager.session = session;
    }

    static setUserTokenLoading(value: boolean) {
        SessionManager.isUserTokenLoading = value;
    }

    static getTokens() {
        return {
            access_token: SessionManager.access_token,
            token_type: SessionManager.token_type,
            expires_in: SessionManager.expires_in,
            refresh_token: SessionManager.refresh_token,
            refresh_token_expires_in: SessionManager.refresh_token_expires_in,
            refresh_token_issued_at: SessionManager.refresh_token_issued_at,
            refresh_token_status: SessionManager.refresh_token_status,
            sub: SessionManager.sub,
            tenant: SessionManager.tenant,
            rmToken: SessionManager.rmToken,
            access_token_issued_at: SessionManager.access_token_issued_at,
            userName: SessionManager.userName,
            isLoggedIn: SessionManager.isLoggedIn,
            isUserTokenLoading: SessionManager.isUserTokenLoading,
            isUserMigrated: SessionManager.isUserMigrated,
            ssoToken: SessionManager.ssoToken,
        }
    }
}

export const getTimeDiff = (date: Date, key = 'mins') => {
    const diffMs = (new Date().valueOf() - date.valueOf());
    const days = Math.floor(diffMs / 86400000);
    const hours = Math.floor((diffMs % 86400000) / 3600000);
    const mins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    switch (key) {
        case 'days':
            return days;
        case 'hours':
            return (days * 24) + hours;
        case 'mins':
            return (hours * 60) + mins;
        default:
            return 0
    }
}

export const getMinutes = (seconds: number) => Math.floor(seconds / 60);

const wait = (sec: number) => new Promise(r => setTimeout(r, sec * 1000))

export const axiosInstance: AxiosInstance = axios.create({
    headers: {
        // always add the api key (needed for API Management)
        'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) =>
({
    ...config,
    headers: {
        ...config.headers,
        ...COMMON_HEADERS,
    },
}));

export const getUserToken = async (): Promise<any> => {
    try {

        SessionManager.setUserTokenLoading(true);
        const res = await axiosInstance.get(
            `${process.env.REACT_APP_USER_TOKEN_URL?.replace(
                '%accesstoken%',
                // eslint-disable-next-line
                SessionManager.access_token as any
            )}`
        );
        if (res.data?.userToken) {
            SessionManager.setUserToken(res.data.userToken)
            SessionManager.setUserTokenLoading(false);
            return { status: true }
        }
        SessionManager.setUserTokenLoading(false);
        return { status: false }
    } catch (e) {
        return { status: false }
    }
}

export const callApi = async (instance: AxiosInstance, url: string, params: any) => {
    try {
        const response = await instance.post<IRefreshToken>(url, params);
        SessionManager.session?.killSession()
        if (!response?.data?.refresh_token) {
            console.log(response.data?.msg);
            return { status: false, message: response.data?.msg };
        }
        const refreshInstance = response?.data;
        refreshInstance.access_token_issued_at = new Date().valueOf();
        refreshInstance.rmToken = SessionManager.rmToken;
        refreshInstance.userName = SessionManager.userName;
        SessionManager.setTokens(refreshInstance);
        SessionManager.isLoggedIn = true;
        if (refreshInstance?.ssoToken) {
            Cookies.set('SMSESSION', refreshInstance?.ssoToken,
                { domain: process.env.REACT_APP_Domain });
            Cookies.set('SOMNIAPP', 'Y',
                {
                    domain: process.env.REACT_APP_Domain,
                    expires: 54 * 60 * 60 * 24 * 7,
                });
            console.log({ sso: refreshInstance?.ssoToken })
        }
        await getUserToken();
        // useRefreshToken(getMinutes(Number(refreshInstance.expires_in)) - 2, new Date().valueOf() );
        return {
            status: true, data: response,
            newTime: getMinutes(Number(refreshInstance.expires_in)) - 2,
            newDateValue: new Date().valueOf(),
        }
    }
    catch (error) {
        console.log(error);
        return { status: false, data: null, error }
    }
}

// async function refreshToken() {
//     console.log('refreshToken');
//     const subscriptionKey = await getSubscriptionKey("StaplesIDP");
//     if (!subscriptionKey) {
//         console.log('subscription key service failed');
//         return { status: false, data: null };
//     };
//     if (!process.env.REACT_APP_ACCESS_TOKEN) {
//         return { status: false, data: null };
//     }

//     axiosInstance.interceptors.request.use((config: any) => ({
//         ...config,
//         headers: {
//             ...config.headers, ...COMMON_HEADERS,
//             'client_id': subscriptionKey,
//             'tenant': 'DOTCOM',
//             'Ocp-Apim-Trace': true,
//             // ...bearerToken,
//         },
//     }))

//     const url = process.env.REACT_APP_ACCESS_TOKEN;
//     const params = new URLSearchParams();
//     params.append('refresh_token', SessionManager.refresh_token);

//     return callApi(axiosInstance, url, params);
// }

export async function refreshRememberMe() {
    const subscriptionKey = await getSubscriptionKey("StaplesIDP");
    console.log("refreshRememberMe", refreshRememberMe)
    if (!subscriptionKey) {
        console.log('subscription key service failed');
        return { data: null, status: false }
    };
    if (!process.env.REACT_APP_REFRESH_TOKEN) {
        return { data: null, status: false }
    }

    axiosInstance.interceptors.request.use((config: any) => ({
        ...config,
        headers: {
            ...config.headers, ...COMMON_HEADERS,
            'client_id': subscriptionKey,
            ...akamaiTelemetryHeader,
        },
    }))

    const url = process.env.REACT_APP_REFRESH_TOKEN;
    const params = {
        "username": SessionManager.userName,
        "rmToken": SessionManager.rmToken,
        "requestUrl": "https://qe101.staples.com/app/sso/login",
        "stplSessionId": getStplSessionIdUniqueId(),
        "nudataPayload": "",
    }

    return callApi(axiosInstance, url, params);
}


export const updateToken = async (): Promise<any> => {
    const subscriptionKey = await getSubscriptionKey("StaplesIDP");
    const SmsessionToken = Cookies.get('SMSESSION') || SessionManager?.ssoToken;
    if (!subscriptionKey) {
        return { status: false, data: null };
    };
    if (!process.env.REACT_APP_UPDATE_TOKEN) {
        return { data: null, status: false }
    }
    const getSubscriptionKeyInstance: AxiosInstance = axios.create({
        headers: {
            // always add the api key (needed for API Management)
            'content-type': 'application/json',
            'Cache-Control': 'no-cache',
            'client_id': subscriptionKey,
            'SMSESSION': SmsessionToken,
            ...akamaiTelemetryHeader,
        },
    })
    const body = {
        sessiontoken: SmsessionToken,
        username: SessionManager.userName,
    }
    try {
        const res: any = await getSubscriptionKeyInstance.post(
            `${process.env.REACT_APP_UPDATE_TOKEN}`, body
        );
        SessionManager.session?.killSession()
        if (res?.data?.ssoToken) {
            const refreshInstance = res?.data;
            refreshInstance.access_token_issued_at = new Date().valueOf();
            refreshInstance.rmToken = SessionManager.rmToken;
            refreshInstance.userName = SessionManager.userName;
            SessionManager.setTokens({ ...SessionManager.getUserData(), ...refreshInstance });
            Cookies.set('SMSESSION', res?.data?.ssoToken,
                { domain: process.env.REACT_APP_Domain });
            await getUserToken();
            return {
                status: true, message: res.refresh_token_status,
                newTime: getMinutes(Number(refreshInstance.expires_in)) - 2,
                newDateValue: new Date().valueOf(),
            }
        }

        return { status: false, message: res.error_description }
    } catch (error) {
        return { status: false, message: error };
    }
}


export async function useRefreshToken(time: number, issueAt: number, resetTime = false) {
    let isTimedOut = false
    let sessionStartedAt = issueAt;

    if (resetTime) {
        SessionManager.session?.killSession()
    }

    SessionManager.initSession({
        getSessionStartedAt: () => sessionStartedAt,
        getIsTimedOut: () => isTimedOut,
        killSession: () => {
            isTimedOut = true;
        },
    })

    do {
        // eslint-disable-next-line no-await-in-loop
        await wait(5);
        const mins: any = getTimeDiff(new Date(sessionStartedAt));
        if (mins >= time) {
            // eslint-disable-next-line no-await-in-loop
            const res: any = await updateToken();
            if (!res.status) {
                // eslint-disable-next-line no-await-in-loop
                const useRefreshRememberMeResponse: any = await refreshRememberMe()
                if (!useRefreshRememberMeResponse?.status) {
                    SessionManager.session?.killSession()
                    setLoginDetails({})
                    isTimedOut = true;
                    removeSmsession();
                    removeStplSessionIdUniqueId();
                    window.location.replace(`/somniapp/login`);

                } else {
                    sessionStartedAt = useRefreshRememberMeResponse?.newDateValue;
                    isTimedOut = false;
                }
            } else {
                sessionStartedAt = res?.newDateValue;
                isTimedOut = false;
            }
        }
    } while (!isTimedOut)
    return null;
}
