/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import {
    IconClose,
    IconEasyRewardsHorizontal,
    IconSmallCheckmark,
    IconStaplesRound,
} from 'src/components/Icons';
import { Body, Button, Heading, BackToTopFAB } from 'src/components/UI';
import {
    activateOffer,
    getFeaturedLoyaltyOffers,
    getLoyaltyOffers,
    LoyaltyOfferResponse,
    updateLoyaltyOfferCache,
} from 'src/services/easyRewardsServices';
import LoadingOverlay from 'src/components/loading-overlay';
import AlertMessage from 'src/screens/alert-message';
import {
    eventMetricsMap,
    useEventsReporter,
    useGuestMode,
    useRouter,
    useStoreData,
    useUserInfo,
} from 'src/hooks';
import ROUTES from 'src/lib/routes';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { COACH_SCREENS_ROUTES } from 'src/routers/routes';
import { toastAtom } from '../OfferList/OfferCard';
import OfferList from '../OfferList';
import styles from './styles.module.scss';

function LoginOverlay() {
    const { setGuestModal } = useUserUpdateContext();
    return (
        <div className={styles.signInContainer}>
            <IconEasyRewardsHorizontal />
            <Body weight="normal" size={18}>
                Sign in to activate your offers
            </Body>
            <Button
                onClick={() =>
                    setGuestModal({ open: true, closeBehavior: 'onboarding' })
                }
                variant="contained-rounded"
                bgColor="bgPurple"
                color="textWhite"
                fullWidth
            >
                Sign in
            </Button>
            <Body weight="normal" size={18}>
                Don't have an account?{' '}
                <Button
                    onClick={() =>
                        setGuestModal({
                            open: true,
                            initialScreen: '/create',
                            closeBehavior: 'onboarding',
                        })
                    }
                    variant="text"
                    color="textLinkBlue"
                    display="inlineFlex"
                    fontWeight="normal"
                    fontSize={18}
                >
                    Join for free
                </Button>
            </Body>
        </div>
    );
}

export function AllOffersActivationAnimation() {
    const [showAnimation, setShowAnimation] = useState(false);

    console.log('showAnimation', showAnimation);

    useEffect(() => {
        if (!showAnimation) {
            setTimeout(() => setShowAnimation(true), 500);
        }
    }, []);

    return (
        <AnimatePresence>
            {showAnimation && (
                <motion.div
                    className={styles.animationContainer}
                    animate={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}
                    exit={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}
                    transition={{ duration: 1 }}
                >
                    <Player
                        autoplay
                        onEvent={(event: PlayerEvent) => {
                            if (event === 'complete') {
                                setShowAnimation(false);
                            }
                        }}
                        src={require('../../../assets/lotties/celebration_check_mark.json')}
                    />
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Heading type="h6" size={30} weight="light">
                            All offers are{' '}
                            <Body
                                size={30}
                                weight="bold"
                                color="textPurple"
                                display="inline"
                            >
                                activated!
                            </Body>
                        </Heading>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

interface PageHeaderProps {
    showActivateAllBtn: boolean;
    activateAllOffers: () => void;
    isUserLoggedIn: boolean;
}

function PageHeader({
    showActivateAllBtn,
    activateAllOffers,
    isUserLoggedIn,
}: PageHeaderProps) {
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();
    const { isGuestModeOn, enterGuestMode, isGuestModeLoading } =
        useGuestMode();
    const { isStoreModeOn, isUserInStore, enterStoreMode } = useStoreData();
    const { replace } = useRouter();

    if (isGuestModeLoading) {
        return <LoadingOverlay open />;
    }

    return (
        <div className={styles.headerContainer}>
            <Button
                className={styles.closeButton}
                variant="icon"
                onClick={() => {
                    sessionStorage.removeItem('route');
                    if (!isUserLoggedIn && !isGuestModeOn) {
                        enterGuestMode().then((success) => {
                            if (success) {
                                replace(COACH_SCREENS_ROUTES.location);
                            }
                        });
                    } else {
                        if (!isStoreModeOn && isUserInStore) {
                            enterStoreMode();
                        }
                        replace(ROUTES.storeMode);
                    }
                }}
            >
                <IconClose />
            </Button>
            <div className={styles.header}>
                <Heading type="h6" size={18} weight="normal" color="textWhite">
                    Your in-store offers
                </Heading>
                {isUserLoggedIn &&
                    (showActivateAllBtn ? (
                        <Button
                            variant="text"
                            fontWeight="normal"
                            fontSize={12}
                            tabIndex={2}
                            ariaLabel="Activate your offers"
                            decoration="textUnderline"
                            onClick={() => {
                                activateAllOffers();
                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'activate all',
                                        element_location: 'Offers - For You',
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                            }}
                        >
                            Activate all
                        </Button>
                    ) : (
                        <Body
                            color="textWhite"
                            weight="bold"
                            size={12}
                            tabIndex={2}
                            ariaLabel="All offers are activated"
                            className={styles.allOffersActivatedText}
                        >
                            <IconSmallCheckmark
                                bgFillColor="fillWhite"
                                fillColor="fillPurple"
                            />
                            All offers are activated
                        </Body>
                    ))}
            </div>
        </div>
    );
}

function StoreModeOfferPage() {
    const listRef = useRef(null);
    const [toastMessage, setToastMessage] = useAtom(toastAtom);
    const { userInfo, isFetchingUserInfo } = useUserInfo();
    const { isUserInStore } = useStoreData();
    const isUserLoggedIn: boolean = SessionManager?.isLoggedIn && !!userInfo;
    const { mutateAsync: handleActivateOffer } = activateOffer();
    const { replace } = useRouter();

    const offerConfig = useMemo(
        () => ({
            select: (data: LoyaltyOfferResponse) => {
                data?.offers?.sort((a, b) => a.ranking - b.ranking);
                return data;
            },
            enabled: isUserLoggedIn,
            refetchOnMount: false,
        }),
        [isUserLoggedIn]
    );
    const {
        data: massOffers,
        refetch: refetchMassLoyaltyOffers,
        isLoading: isMassOffersLoading,
    } = getLoyaltyOffers({
        offerType: 'MASS',
        config: offerConfig,
    });

    const {
        data: targetedOffers,
        refetch: refetchTargetedLoyaltyOffers,
        isLoading: isTargetedOffersLoading,
    } = getLoyaltyOffers({
        offerType: 'TARGETED',
        config: offerConfig,
    });

    const {
        data: featuredOffers,
        refetch: refetchFeaturedLoyaltyOffers,
        isLoading: isFeaturedOffersLoading,
    } = getFeaturedLoyaltyOffers({
        config: offerConfig,
    });

    const anyOffersLoading = useMemo(
        () =>
            isFeaturedOffersLoading ||
            isTargetedOffersLoading ||
            isMassOffersLoading,
        [isFeaturedOffersLoading, isTargetedOffersLoading, isMassOffersLoading]
    );

    const inStoreOffers = useMemo(() => {
        const filteredFeaturedInStoreOffers =
            featuredOffers?.offers
                ?.filter((offer) => offer?.channel?.includes('Retail'))
                ?.map((offer) => {
                    const transformedOffer = { ...offer };
                    transformedOffer.channel = '';
                    return transformedOffer;
                }) || [];
        const filteredTargetedInStoreOffers =
            targetedOffers?.offers
                ?.filter((offer) => offer?.channel?.includes('Retail'))
                ?.map((offer) => {
                    const transformedOffer = { ...offer };
                    transformedOffer.channel = '';
                    return transformedOffer;
                }) || [];
        const filteredMassInStoreOffers =
            massOffers?.offers
                ?.filter((offer) => offer?.channel?.includes('Retail'))
                ?.map((offer) => {
                    const transformedOffer = { ...offer };
                    transformedOffer.channel = '';
                    return transformedOffer;
                }) || [];
        return {
            featured: filteredFeaturedInStoreOffers,
            targeted: filteredTargetedInStoreOffers,
            mass: filteredMassInStoreOffers,
        };
    }, [massOffers, targetedOffers, featuredOffers]);

    const allInactiveOfferIds = useMemo(() => {
        const targetedOfferIdsToActivate =
            targetedOffers?.offers
                ?.filter((item) => !item.activated)
                ?.map((item) => item.offerid) || [];
        const massOfferIdsToActivate =
            massOffers?.offers
                ?.filter((item) => !item.activated)
                ?.map((item) => item.offerid) || [];
        const featuredOfferIdsToActivate =
            featuredOffers?.offers
                ?.filter((item) => !item.activated)
                ?.map((item) => item.offerid) || [];
        return [
            ...targetedOfferIdsToActivate,
            ...featuredOfferIdsToActivate,
            ...massOfferIdsToActivate,
        ];
    }, [massOffers, targetedOffers, featuredOffers]);

    const refetchLoyaltyOffers = () =>
        Promise.all([
            refetchFeaturedLoyaltyOffers(),
            refetchTargetedLoyaltyOffers(),
            refetchMassLoyaltyOffers(),
        ]);

    const activateAllOffers = () => {
        ['MASS', 'TARGETED', 'FEATURED'].forEach((offerCacheKey) => {
            updateLoyaltyOfferCache({
                offerType: offerCacheKey as 'TARGETED' | 'MASS' | 'FEATURED',
                updater: (oldData) => {
                    if (oldData && oldData.offers) {
                        const newData: typeof oldData = {
                            ...oldData,
                        };
                        newData.offers.map((item) => {
                            item.activated = true;
                            return item;
                        });
                        return newData;
                    }
                    return oldData as LoyaltyOfferResponse;
                },
            });
        });
        handleActivateOffer(allInactiveOfferIds)
            .then((res) => {
                if (
                    res.data?.some((activateRes: any) => !activateRes?.success)
                ) {
                    throw new Error(
                        "All offers couldn't be activated, try again."
                    );
                }
            })
            .catch(() => {
                setToastMessage("All offers couldn't be activated, try again.");
            })
            .finally(() => {
                refetchLoyaltyOffers();
            });
    };

    useEffect(() => {
        if (isUserInStore === false && isUserLoggedIn) {
            // replace(`${ROUTES.rewardsWallet}?activateAll=true`);
            replace(ROUTES.rewardsWallet, {
                activateAll: true,
            });
        }
    }, [isUserInStore, isUserLoggedIn]);

    useEffect(() => {
        if (!anyOffersLoading && isUserLoggedIn && isUserInStore) {
            activateAllOffers();
        }
    }, [anyOffersLoading, isUserLoggedIn, isUserInStore]);

    if ((anyOffersLoading && isUserLoggedIn) || isFetchingUserInfo) {
        return <LoadingOverlay open />;
    }

    return (
        <>
            <PageHeader
                activateAllOffers={activateAllOffers}
                showActivateAllBtn={!!allInactiveOfferIds.length}
                isUserLoggedIn={isUserLoggedIn}
            />
            {isUserLoggedIn ? (
                <>
                    <div ref={listRef} className={styles.offerListContainer}>
                        <BackToTopFAB containerRef={listRef} />
                        <OfferList
                            variant="vertical"
                            offerCacheKey="FEATURED"
                            data={inStoreOffers.featured}
                            activateOffer={handleActivateOffer}
                            isDataLoading={isFeaturedOffersLoading}
                        />
                        <OfferList
                            variant="vertical"
                            offerCacheKey="TARGETED"
                            data={inStoreOffers.targeted}
                            activateOffer={handleActivateOffer}
                            isDataLoading={isTargetedOffersLoading}
                        />
                        <OfferList
                            variant="vertical"
                            offerCacheKey="MASS"
                            data={inStoreOffers.mass}
                            activateOffer={handleActivateOffer}
                            isDataLoading={isMassOffersLoading}
                        />
                        <AlertMessage
                            open={!!toastMessage}
                            icon={<IconStaplesRound />}
                            handleClose={() => setToastMessage('')}
                            btnName={''}
                            message={toastMessage}
                            handleBtnClick={() => setToastMessage('')}
                            status={''}
                        />
                    </div>
                    <AllOffersActivationAnimation />
                </>
            ) : (
                <LoginOverlay />
            )}
        </>
    );
}

export default StoreModeOfferPage;
