import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import COLORS from 'src/lib/colors';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import clsx from 'clsx';
import LoadingButton from './loading-button';

export type HandleCloseFn = (closeReason?: 'try-again') => void;
export type ErrorDialogProps = {
    open: boolean;
    handleClose: HandleCloseFn;
    handlePrimaryBtnClick?: () => void;
    handleSecondaryBtnClick?: () => void;
    primaryBtnLoading?: boolean;
    errorTitle?: string;
    errorDescription?: string;
    // if children are passed, errorDescription is ignored
    children?: any;
    primaryBtnLabel?: string;
    primaryBtnId?: string;
    hidePrimaryBtn?: boolean;
    disablePrimaryBtn?: boolean;
    secondaryBtnLabel?: string;
    hideSecondaryBtn?: boolean;
    secondaryBtnId?: string;
    secondaryBtnLoading?: boolean;
    hideCloseBtn?: boolean;
    showRedPrimaryBtn?: boolean;
};

const defaultTitle = 'Error';
const defaultErrorDescription =
    'Something unexpected happened. Please try again or contact member services.';
const memberServicesURL = 'https://www.staplesconnect.com/contact-us';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        width: '100%',
    },
    errorTitleWrapper: {
        padding: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    errorTitle: {
        fontSize: '1.2rem',
        fontWeight: 500,
        letterSpacing: '-0.5pt',
        alignSelf: 'center',
        marginBottom: '1rem',
        padding: '0 1rem',
        textAlign: 'center',
    },
    errorTitlePadding: { marginTop: '2rem' },
    dialogContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeIcon: {
        justifyContent: 'flex-end',
    },
    errorIcon: {
        width: '6.5rem',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        marginTop: '1rem',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
    },
    actionsWrapper: {
        flexDirection: 'column',
    },
    primaryButton: {
        width: '90%',
        marginTop: '1rem',
        marginBottom: '1rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover, &:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    primaryEmailButton: {
        width: '90%',
        marginTop: '1rem',
        marginBottom: '1rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover, &:active': {
            backgroundColor: COLORS.brandRed,
        },
    },
    secondaryButton: {
        color: COLORS.brandCyan,
        marginLeft: '0',
        width: '90%',
        marginBottom: '1rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        borderColor: COLORS.homeBlack,
        borderWidth: '0.1rem',
        textTransform: 'none',
        fontSize: '1rem',
    },
});

export const GenericErrorContent = ({
    errorDescription,
}: {
    errorDescription: string;
}) => {
    const classes = useStyles();
    return (
        <>
            <img
                className={classes.errorIcon}
                src={require('../assets/icons/iconGenericError.svg')}
                alt="generic error"
            />
            <DialogContentText className={classes.dialogContentText}>
                {errorDescription}
            </DialogContentText>
        </>
    );
};

/**
 * Generic Error Dialog.
 * This dialog is currently being used in the OptimalDeviceDialog.
 * If there are future style changes to the error dialog, we may need to refactor the OptimalDeviceDialog.
 */
export default function ErrorDialog(props: ErrorDialogProps) {
    const {
        errorTitle = defaultTitle,
        errorDescription = defaultErrorDescription,
        open,
        handleClose,
        handlePrimaryBtnClick,
        handleSecondaryBtnClick,
        children,
        primaryBtnLoading = false,
        primaryBtnLabel = 'Try Again',
        primaryBtnId = 'match-not-found-try-again-button',
        hidePrimaryBtn,
        disablePrimaryBtn,
        secondaryBtnLabel = 'Contact member services',
        secondaryBtnLoading = false,
        secondaryBtnId = 'match-not-found-contact-member-services-button',
        hideSecondaryBtn,
        hideCloseBtn,
        showRedPrimaryBtn = false,
    } = props;
    const classes = useStyles();
    const { analyticsCustomEvent } = useEventsReporter();

    const onPrimaryBtnClick = () => {
        handleClose('try-again');
        if (handlePrimaryBtnClick) {
            handlePrimaryBtnClick();
            analyticsCustomEvent(eventMetricsMap.account_creation, {
                click_text: 'first_visit',
                element_location: 'account sign-in',
            });
        }
    };

    const onSecondaryBtnClick = () => {
        if (handleSecondaryBtnClick) {
            handleSecondaryBtnClick();
        } else {
            openInNewTab(memberServicesURL);
            analyticsCustomEvent(eventMetricsMap.outbound, {
                click_text: 'memberServicesURL',
                link_domain: 'staplesconnect.com',
                link_url: memberServicesURL,
            });
            optimoveEvents(eventMetricsMap.scm_outbound, {
                click_text: 'memberServicesURL',
                link_domain: 'staplesconnect.com',
                link_url: memberServicesURL,
            });
        }
    };

    return (
        <Dialog
            open={open}
            // onClose={() => !hideCloseBtn && handleClose()}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle
                disableTypography
                id="error-dialog-title"
                className={classes.errorTitleWrapper}
            >
                {!hideCloseBtn && (
                    <Button
                        fullWidth
                        onClick={() => handleClose()}
                        className={classes.closeIcon}
                        id="close-button"
                    >
                        <img
                            src={require('../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </Button>
                )}
                <Typography
                    className={clsx(
                        classes.errorTitle,
                        hideCloseBtn && classes.errorTitlePadding
                    )}
                >
                    {errorTitle}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContentWrapper}>
                {children || (
                    <DialogContentText className={classes.dialogContentText}>
                        {errorDescription}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions className={classes.actionsWrapper}>
                {!hidePrimaryBtn && (
                    <LoadingButton
                        id={primaryBtnId}
                        variant="contained"
                        onClick={onPrimaryBtnClick}
                        disabled={disablePrimaryBtn}
                        classes={{
                            root: showRedPrimaryBtn
                                ? classes.primaryEmailButton
                                : classes.primaryButton,
                        }}
                        loading={primaryBtnLoading}
                    >
                        {primaryBtnLabel}
                    </LoadingButton>
                )}
                {!hideSecondaryBtn && (
                    <LoadingButton
                        id={secondaryBtnId}
                        onClick={onSecondaryBtnClick}
                        variant={!hidePrimaryBtn ? 'text' : 'contained'}
                        classes={{
                            root: !hidePrimaryBtn
                                ? classes.secondaryButton
                                : classes.primaryButton,
                        }}
                        loading={secondaryBtnLoading}
                    >
                        {secondaryBtnLabel}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
}
