// TODO legacy code remove if found after 5/15/2024

import React, { useState, useLayoutEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Heading } from 'src/components/UI';
import { useLocalStorage, LOCAL_STORAGE_KEYS, useRouter } from 'src/hooks';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { mergeClasses } from 'src/stylesheets/utils';
import { REWARDS_ENROLLMENT_TRACKER } from '../types';
import styles from './styles.module.scss';

function FavoriteSelectionIntroScreen() {
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);

    const { replace } = useRouter();

    const [
        { completedEnrollmentSteps, skippedEnrollmentSteps },
        setEnrollmentSteps,
    ] = useLocalStorage<REWARDS_ENROLLMENT_TRACKER>(
        LOCAL_STORAGE_KEYS.ENROLLMENT_TRACKER,
        {
            completedEnrollmentSteps: {},
            skippedEnrollmentSteps: {},
        }
    );

    useLayoutEffect(() => {
        if (
            completedEnrollmentSteps[REWARDS_ENROLLMENT_ROUTES.favoritesIntro]
        ) {
            replace(REWARDS_ENROLLMENT_ROUTES.favorites);
        }
    }, []);

    return (
        <div className={styles.favoriteSelectionIntroScreenContainer}>
            <Heading type="h3" size={30} weight="normal">
                <AnimatePresence
                    onExitComplete={() => {
                        setEnrollmentSteps({
                            skippedEnrollmentSteps: {
                                ...skippedEnrollmentSteps,
                            },
                            completedEnrollmentSteps: {
                                ...completedEnrollmentSteps,
                                [REWARDS_ENROLLMENT_ROUTES.favoritesIntro]:
                                    true,
                            },
                        });
                        replace(REWARDS_ENROLLMENT_ROUTES.favorites);
                    }}
                >
                    {!isAnimationComplete && (
                        <>
                            <motion.span
                                key={1}
                                className={mergeClasses(
                                    styles.textPurple,
                                    styles.bold
                                )}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: { duration: 1 },
                                }}
                                exit={{
                                    opacity: 0,
                                    transition: { duration: 1 },
                                }}
                            >
                                Great!{' '}
                            </motion.span>
                            <motion.span
                                key={2}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: { delay: 1, duration: 1 },
                                }}
                                exit={{
                                    opacity: 0,
                                    transition: { duration: 1 },
                                }}
                                onAnimationComplete={() =>
                                    setIsAnimationComplete(true)
                                }
                            >
                                Now let's get your personalized recommendations.
                            </motion.span>
                        </>
                    )}
                </AnimatePresence>
            </Heading>
        </div>
    );
}

export default FavoriteSelectionIntroScreen;
