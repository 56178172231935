import React, { useState } from 'react';
import { DialogContentText, makeStyles } from '@material-ui/core';
import ErrorDialog, { ErrorDialogProps } from 'src/components/error-dialog';
import COLORS from 'src/lib/colors';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';

type GiveNowError = null | 'pay' | 'give';

/** common state separated for consistency */
export const useGiveNowError = (onInviteClicked?: () => void) => {
  const [variant, setVariant] = useState<GiveNowError>(null);
  function handlePrimaryBtnClick() {
    if (variant === 'pay' && onInviteClicked) {
      onInviteClicked();
    }
    setVariant(null);
  }
  function handleClose() {
    setVariant(null);
  }
  return {
    variant,
    setVariant,
    handlePrimaryBtnClick,
    open: variant !== null,
    handleClose,
  };
};

const useStyles = makeStyles({
  errorIcon: {
    width: '6.5rem',
  },
  dialogContentText: {
    color: COLORS.secondaryNeutralCharcoal,
    textAlign: 'center',
    marginTop: '1rem',
    letterSpacing: '-0.5pt',
    lineHeight: '1.2rem',
  },
});

const ERROR_PAY_WITH_TITLE = "You'll need Rewards first...";
const ERROR_ZERO_AMOUNT_TITLE = "Classroom Rewards limit reached";
const ERROR_PAY_WITH_DESC =
  // eslint-disable-next-line max-len
  "It looks like you haven't received any rewards to pay with yet. Encourage your supporters to join the Classroom Rewards program.";
const ERROR_GIVE_WITH_TITLE = 'Oops! Not enough rewards...';
const ERROR_GIVE_WITH_DESC =
  // eslint-disable-next-line max-len
  "It looks like you don't have any rewards to give. Make eligible purchases at your local Staples store to earn rewards to give back to your schools and teachers.";

type ErrorDialogZeroMoneyGiveNowProps = ErrorDialogProps & {
  variant: GiveNowError;
};

export default function ErrorDialogZeroMoneyGiveNow(
  props: ErrorDialogZeroMoneyGiveNowProps
) {
  const classes = useStyles();
  const { customEvent } = useEventsReporter();
  const { variant, open, ...rest } = props;
  const errorTitle =
    variant === 'pay' ? ERROR_PAY_WITH_TITLE : ERROR_GIVE_WITH_TITLE;
  const description =
    variant === 'pay' ? ERROR_PAY_WITH_DESC : ERROR_GIVE_WITH_DESC;
  const primaryBtnLabel = variant === 'pay' ? 'Invite supporters now' : 'Close';
  const primaryBtnId =
    variant === 'pay'
      ? 'give-now-error-invite-supporters-button'
      : 'give-now-error-close-button';
  const handlePrimaryBtnClick = () => {
    if (variant === 'pay') {
      customEvent(eventMetricsMap.invite_supporter_clicked);
    }
    if (props.handlePrimaryBtnClick) {
      props.handlePrimaryBtnClick();
    }
  };
  const errorOpen = useIsActiveScreen(SCREEN_NAMES.GIVE_NOW_ERROR, open);
  return (
    <ErrorDialog
      open={errorOpen}
      hideCloseBtn={variant !== 'pay'}
      hideSecondaryBtn
      errorTitle={errorTitle}
      primaryBtnLabel={primaryBtnLabel}
      primaryBtnId={primaryBtnId}
      handlePrimaryBtnClick={handlePrimaryBtnClick}
      {...rest}
    >
      <img
        className={classes.errorIcon}
        src={require('../assets/icons/iconErrorApple.svg')}
        alt="not enough rewards error icon"
      />
      <DialogContentText
        id="give-now-error-error-dialog-description"
        className={classes.dialogContentText}
      >
        {description}
      </DialogContentText>
    </ErrorDialog>
  );
}
