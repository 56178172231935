import React from 'react';
import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import COLORS from 'src/lib/colors';

const BlackCheckbox = withStyles({
  root: {
    color: COLORS.secondaryNeutralBlack,
    '&$checked': {
      color: COLORS.secondaryNeutralBlack,
    },
  },
  checked: {},
})((checkboxprops: CheckboxProps) => (
  <Checkbox
    {...checkboxprops}
    color="default"
    icon={<CircleUnchecked />}
    checkedIcon={<CircleCheckedFilled />}
  />
));

export default BlackCheckbox;
