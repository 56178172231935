import { Typography, withStyles } from '@material-ui/core';

const DiagonalStrikethroughText = withStyles({
  root: {
    background:
      // eslint-disable-next-line max-len
      'linear-gradient(to left bottom, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)',
    height: '1.25rem',
    marginLeft: '.25rem',
  },
})(Typography);

export default DiagonalStrikethroughText;
