import React from 'react';
import { ButtonBase, Chip, makeStyles, withStyles } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import COLORS from 'src/lib/colors';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
// import {useUserUpdateContext} from 'src/lib/contexts/user-update-context';
// import { trackEvent } from 'src/lib/api-client/beacons-adeptmind';
import { TermSuggestion } from 'src/lib/api-client/adept-mind-types';
import { SearchProductsResult } from 'src/lib/api-client/adept-mind';

const useFilterSortButtonsStyles = makeStyles({
    wrapper: {
        marginTop: '0',
        display: 'flex',
        flexDirection: 'column',
    },
    quickFilters: {
        display: 'flex',
        paddingBottom: '.75rem',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflowX: 'scroll',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        alignSelf: 'center',
    },
    quickFilter: {
        marginLeft: '.5rem',
        marginBottom: '.25rem',
    },
    filterSortButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '8px',
    },
    filterSortIcon: {
        height: '1rem',
        marginRight: '.2rem',
    },
    filterBtn: {
        fontSize: '.95rem',
        padding: '.5rem',
    },
    sortBtn: {
        marginLeft: '.75rem',
        fontSize: '.95rem',
        padding: '.5rem',
    },
});

const QuickFilterChip = withStyles({
    root: {
        textTransform: 'capitalize',
        color: COLORS.textBlack,
        borderColor: COLORS.textBlack,
    },
    outlinedPrimary: {
        color: COLORS.primaryWhite,
        borderColor: COLORS.primaryWhite,
        backgroundColor: COLORS.textBlack,
        '&:focus': {
            backgroundColor: `${COLORS.textBlack} !important`,
        },
        '&:hover': {
            backgroundColor: `${COLORS.textBlack} !important`,
        },
        '&:active': {
            boxShadow: 'none',
        },
    },
})(Chip);

const termSuggestionEq = (a: TermSuggestion, b: TermSuggestion) =>
    a.term === b.term && a.type === b.type;

type FilterSortButtonsProps = {
    lastResult?: SearchProductsResult | null;
};
export default function FilterSortButtons({
    lastResult,
}: FilterSortButtonsProps) {
    const classes = useFilterSortButtonsStyles();
    const {
        goToPage,
    } = useProductBrowseContext();
    function onSortClick() {
        goToPage({ type: 'sort', pageDetails: { lastResult } });
    }


    return (
        <div className={classes.wrapper}>
            <div className={classes.filterSortButtons}>
                <ButtonBase onClick={onSortClick} className={classes.sortBtn}>
                    <img
                        src={require('../../../assets/icons/iconSort.svg')}
                        alt="sort"
                        className={classes.filterSortIcon}
                    />
                    Sort
                </ButtonBase>
            </div>
        </div>
    );
}