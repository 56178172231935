
import React, { useContext, useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useRouter from 'src/hooks/useRouter';

import { deleteShipment, deleteTracking, getShipmentListDetails } from 'src/lib/api-client/shipment-services';
import FullWidthCard from '../../cards/full-width-card';
import ROUTES from '../../../lib/routes';
import { phoneNumberFormat } from '../../../lib/utils/string-utils';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';
import DeleteStatusDialog from './delete-status-dialog';

const useStyles = makeStyles((theme) => ({
    shippingAddressContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: 'none',
        margin: '0px 23px',
        '& .MuiCardContent-root': {
            padding: 0,
        },
    },
    shippingReceiverAddressContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    shippingNamePackages: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    shippingReceiverName: {
        display: 'flex',
        flexDirection: 'column',
    },
    shippingGoingTo: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '-0.47px',
        color: '#1E1E1E',
    },
    shippingName: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    shippingReceiverPackagesContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    shippingPackagesName: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        color: '#1E1E1E',
    },
    shippingPackageCount: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '-0.6px',
        color: '#101820',
        textAlign: 'right',
        marginTop: '10px',
    },
    shippingReceiverAddressDetails: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    shippingSenderAddressContainer: {
        margin: '30px 0 30px',
    },
    shippingSenderName: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        color: '#1E1E1E',
    },
    shippingSenderNameDetails: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    shippingSenderInfoDetails: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    shippingNeedDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '39px',
    },
    shippingNeedDetailsText: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#1E1E1E',
        width: '203px',
    },
    shippingStartShipmentText: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#086DD2',
    },
    shippingDeleteLabelContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '9px',
        marginBottom: '30px',
    },
    shippingDeleteLabel: {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '14.16px',
        letterSpacing: '0.16px',
        color: '#CC0000',
    },
}))

const ShippingServicesSenderReceiverAddress = ({ data, activeTab, updatedData }: any) => {
    const { recipientdetails, packagedetails, shipperdetails, shimpmentstatus,
    }: any = data;
    const address = data?.recipientdetails
    const { analyticsCustomEvent } = useEventsReporter();
    const { history } = useRouter();
    const { updateRecipient, updateSender, updateOpenScanToTrack, openScanToTrack } = useContext(ShippingInfoContext)
    const [deleteStatusDialogOpen, setDeleteStatusDialogOpen] = useState(false);
    const [deleteStatusSuccess, setDeleteStatusSuccess] = useState(false);
    const [deleteTrackingSuccess, setDeleteTrackingSuccess] = useState(false);
    const [deleteTrackingDialogOpen, setDeleteTrackingDialogOpen] = useState(false);
    const handleDeleteStatusModel = () => {
        setDeleteStatusDialogOpen(true);
    }
    const handleOnDeleteTrackingModel = () => {
        setDeleteTrackingDialogOpen(true)
    }
    const handleCloseDialog = () => {
        setDeleteStatusDialogOpen(false);
        setDeleteTrackingDialogOpen(false)
    }
    const handleOnDeleteStatus = async () => {
        const deleteShipmentResponse = await deleteShipment(shimpmentstatus?.shipmentnumber);
        if (deleteShipmentResponse?.isSuccess === true) {
            setDeleteStatusSuccess(true);
            setDeleteStatusDialogOpen(false);
            updatedData();
        }
    }

    const handleOnDeleteTracking = async () => {
        const deleteTrackingResponse = await deleteTracking(shimpmentstatus?.shipmenttrackingnumbers[0]);
        if (deleteTrackingResponse?.issuccess === true) {
            setDeleteTrackingSuccess(true);
            setDeleteTrackingDialogOpen(false);
            updatedData();
        }
    }

    const classes = useStyles();
    const handleStartShipment = () => {
        updateRecipient(recipientdetails)
        updateSender(shipperdetails)
        // openInNewTab("https://shipping.staples.com/")
        analyticsCustomEvent(eventMetricsMap.start_shipment, {
            click_text: 'start shipment',
            element_location: 'labels and shipment - created',
        });
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=CreateShippingRecipientInformation',
        })
    }
    const scanAnotherLabel = () => {
        updateOpenScanToTrack(true);
        history.push({
            pathname: ROUTES.shippingServices,
        });

    }
    return (
        <FullWidthCard className={classes.shippingAddressContainer}>
            {data?.shipmenttype === "APPSHIPMENT" ? <>
                <div className={classes.shippingReceiverAddressContainer}>
                    <div className={classes.shippingNamePackages}>
                        <div className={classes.shippingReceiverName}>
                            <Typography className={classes.shippingGoingTo}>Shipping to</Typography>
                            <Typography className={classes.shippingName}>{recipientdetails?.fullname}</Typography>
                        </div>
                        <div className={classes.shippingReceiverPackagesContainer}>
                            <Typography className={classes.shippingPackagesName}>Packages</Typography>
                            <Typography className={classes.shippingPackageCount}>{packagedetails?.length}</Typography>
                        </div>
                    </div>
                    <div className={classes.shippingReceiverAddressDetails}>
                        <Typography className={classes.shippingReceiverAddressDetails}>
                            {recipientdetails.companyname}</Typography>
                        <Typography className={classes.shippingReceiverAddressDetails}>
                            {recipientdetails?.street1}</Typography>
                        {recipientdetails?.street2 ? <Typography className={classes.shippingReceiverAddressDetails}>
                            {recipientdetails?.street2}
                        </Typography>
                            : null}

                        <Typography className={classes.shippingReceiverAddressDetails}>
                            {recipientdetails.city}, {recipientdetails.state} {recipientdetails.postalcode}</Typography>
                        <Typography className={classes.shippingReceiverAddressDetails}>
                            {phoneNumberFormat(recipientdetails?.phonenumber)}</Typography>
                        <Typography className={classes.shippingReceiverAddressDetails}>
                            {recipientdetails?.email}</Typography>
                    </div>
                </div>
                <div className={classes.shippingSenderAddressContainer}>
                    <Typography className={classes.shippingSenderName}>Your Information</Typography>
                    <Typography className={classes.shippingSenderNameDetails}>{shipperdetails?.fullname}</Typography>
                    <div className={classes.shippingSenderInfoDetails}>
                        <Typography className={classes.shippingSenderInfoDetails} >
                            {shipperdetails?.street1}</Typography>
                        {shipperdetails?.street2 ? <Typography className={classes.shippingSenderInfoDetails}>
                            {shipperdetails?.street2}</Typography> : null}
                        <Typography className={classes.shippingReceiverAddressDetails}>
                            {shipperdetails.city}, {shipperdetails.state} {shipperdetails.postalcode}</Typography>
                        <Typography className={classes.shippingSenderInfoDetails}>
                            {phoneNumberFormat(shipperdetails?.phonenumber)}</Typography>
                        <Typography className={classes.shippingReceiverAddressDetails}>
                            {shipperdetails?.email}</Typography>
                    </div>
                </div>
                <div className={classes.shippingNeedDetails}>
                    <Typography className={classes.shippingNeedDetailsText}>
                        Need to send another shipment to the same address as above?
                    </Typography>
                    <Typography className={classes.shippingStartShipmentText}
                        onClick={handleStartShipment}>Start shipment</Typography>
                </div>
            </> : <>
                <div className={classes.shippingReceiverAddressContainer}>
                    <div className={classes.shippingNamePackages}>
                        <div className={classes.shippingReceiverName}>
                            <Typography className={classes.shippingGoingTo}>Shipping to</Typography>
                            <Typography className={classes.shippingName}>
                                {`${address.city}, ${address.state}`}</Typography>
                        </div>
                        <div className={classes.shippingReceiverPackagesContainer}>
                            <Typography className={classes.shippingPackagesName}>Packages</Typography>
                            <Typography className={classes.shippingPackageCount}>1</Typography>
                        </div>

                    </div>
                </div>
                <div className={classes.shippingNeedDetails} style={{ marginTop: '25px' }}>
                    <Typography className={classes.shippingNeedDetailsText}>
                        Need to send scan another shipment?
                    </Typography>
                    <Typography className={classes.shippingStartShipmentText}
                        onClick={scanAnotherLabel}>Scan label</Typography>
                </div>
            </>
            }
            {activeTab === "create" ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <ButtonBase className={classes.shippingDeleteLabelContainer} onClick={handleDeleteStatusModel}>
                        <img src={require('../../../assets/icons/icon_delete.svg')} alt="delete" />
                        <Typography className={classes.shippingDeleteLabel}>Delete Label</Typography>
                    </ButtonBase>
                    {deleteStatusDialogOpen && (
                        <DeleteStatusDialog
                            open={deleteStatusDialogOpen}
                            onClose={handleCloseDialog}
                            onDelete={handleOnDeleteStatus}
                            message={`This will erase your label and new one 
                            will need to be created for your shipment.`}
                            title={'Are you sure you want to delete this shipment label?'}
                            buttonLabel={'Delete Label'}
                        />
                    )}
                </div>
            ) : null}
            {activeTab === 'delivered' || activeTab === 'shipped' ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {data?.shipmenttype === 'APPSHIPMENT' ?
                        <ButtonBase className={classes.shippingDeleteLabelContainer} onClick={handleDeleteStatusModel}>
                            <img src={require('../../../assets/icons/icon_delete.svg')} alt="delete" />
                            <Typography className={classes.shippingDeleteLabel}>Delete Shipment</Typography>
                        </ButtonBase> :
                        <ButtonBase className={classes.shippingDeleteLabelContainer}
                            onClick={handleOnDeleteTrackingModel}>
                            <img src={require('../../../assets/icons/icon_delete.svg')} alt="delete" />
                            <Typography className={classes.shippingDeleteLabel}>Delete Tracking</Typography>
                        </ButtonBase>}
                    {deleteStatusDialogOpen && (
                        <DeleteStatusDialog
                            open={deleteStatusDialogOpen}
                            onClose={handleCloseDialog}
                            onDelete={handleOnDeleteStatus}
                            message={`Deleting this shipment status will not stop the shipment.
                            to view the future statuses of this shipment, you will need to enter
                             your tracking number at UPS.com`}
                            title={'Are you sure you want to delete this shipment status?'}
                            buttonLabel={'Delete Shipment'}
                        />
                    )}
                    {deleteTrackingDialogOpen && (
                        <DeleteStatusDialog
                            open={deleteTrackingDialogOpen}
                            onClose={handleCloseDialog}
                            onDelete={handleOnDeleteTracking}
                            message={`Deleting this shipment status will not stop the shipment.
                            to view the future statuses of this shipment, you will need to enter
                             your tracking number at UPS.com`}
                            title={'Are you sure you want to delete this shipment status?'}
                            buttonLabel={'Delete Tracking'}
                        />
                    )}
                </div>
            ) : null}
        </FullWidthCard>
    )
}

export default ShippingServicesSenderReceiverAddress;