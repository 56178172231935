import debug from 'debug';

const appDebug = debug('app');

function createDebug(namespace: string) {
  const newDebug = appDebug.extend(namespace);
  // eslint-disable-next-line no-console
  newDebug.log = console.log.bind(console);
  return newDebug;
}

export default {
  log: createDebug('log'),
  auth: createDebug('auth'),
  authInternal: createDebug('authInternal'),
  userManager: createDebug('userManager'),
  localStorage: createDebug('localStorage'),
  axios: createDebug('axios'),
  bloomreach: createDebug('bloomreach'),
  worker: createDebug('worker'),
  geolocation: createDebug('geolocation'),
  serviceWorker: createDebug('sw'),
  serviceWorkerFile: createDebug('swf'),
  perf: createDebug('perf'),
  firebase: createDebug('firebase'),
  env: createDebug('env'),
  ios: createDebug('ios'),
  screens: createDebug('screens'),
  toast: createDebug('toast'),
  lists: createDebug('lists'),
  product: createDebug('product'),
  eventsMetricsManager: createDebug('eventsMetricsManager'),
};
