import React from 'react';
import { mergeClasses } from 'src/stylesheets/utils';
import styles from './styles.module.scss';

interface ProgressPillProps {
    filled: boolean;
}

function ProgressPill({ filled }: ProgressPillProps) {
    return (
        <div
            className={mergeClasses([
                filled ? styles.bgPurple : styles.bgLightgray,
                styles.fullRounded,
                styles.pill,
            ])}
        />
    );
}

interface ProgressBarProps {
    bgColor?: string;
    max: number;
    current: number;
}

function ProgressBar({ bgColor = 'default', max, current }: ProgressBarProps) {
    return (
        <div className={styles.container}>
            {[...new Array(max)].map((_item, index) => (
                <ProgressPill filled={index < current} />
            ))}
        </div>
    );
}

export default ProgressBar;
