import { useEffect, useState, useCallback, useRef } from 'react';

const defaultOptions = {
  cancelOnUnmount: true,
};

/**
 * An async-utility hook that accepts a callback function and an interval time (in milliseconds), then delays the
 * execution of the given function by the defined time and repeats the function every few seconds.
 */
const useInterval = (
  fn: () => void,
  milliseconds: number,
  options = defaultOptions
) => {
  const opts = { ...defaultOptions, ...(options || {}) };
  const interval = useRef<any>();
  const callback = useRef(fn);
  const [isCleared, setIsCleared] = useState(false);

  // the clear method
  const clear = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
      setIsCleared(true);
    }
  }, []);

  // if the provided function changes, change its reference
  useEffect(() => {
    if (typeof fn === 'function') {
      callback.current = fn;
    }
  }, [fn]);

  // when the milliseconds change, reset the interval
  useEffect(() => {
    if (typeof milliseconds === 'number') {
      if (interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        callback.current();
      }, milliseconds);
    }
    return clear;
  }, [milliseconds]);

  // when component unmounts clear the interval
  useEffect(
    () => () => {
      if (opts.cancelOnUnmount) {
        clear();
      }
    },
    []
  );

  return [isCleared, clear];
};

export default useInterval;
