import { WebStorageStateStore } from 'oidc-client';

const authConfig = {
  authority: `http://localhost:3000/login`,
  client_id: process.env.REACT_APP_AKAMAI_CLIENT_ID,
  redirect_uri:  `/login`, // process.env.REACT_APP_AKAMAI_REDIRECT_URI,
  post_logout_redirect_uri:
    process.env.REACT_APP_AKAMAI_POST_LOGOUT_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid profile email address phone',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export default authConfig;
