import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Typography,
    makeStyles,
    Collapse,
    InputBase,
    ButtonBase,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import { CommonStepProps } from 'src/screens/donation-flow/types';
import FavoritesActivity from 'src/components/favorites-activty';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';

const useStyles = makeStyles({
    mainCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
    },
    schoolSearchTitle: {
        fontWeight: 500,
        fontSize: '1rem',
        textAlign: 'center',
        padding: '.75rem 2.75rem 0rem',
    },
    schoolSearchDescription: {
        textAlign: 'center',
        fontSize: '.8rem',
        padding: '.5rem 2.75rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    searchInputForm: {
        width: '100%',
        display: 'contents',
    },
    searchInput: {
        display: 'flex',
        flexGrow: 1,
        margin: '.95rem 1.25rem .5rem',
        padding: '0.25rem 0',
        borderRadius: '1.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    searchIconBtn: {
        width: '1.75rem',
        height: '1.75rem',
        marginLeft: '.5rem',
        marginRight: '.5rem',
    },
    searchIcon: {
        width: '1.75rem',
        height: '1.75rem',
    },
    noFavoritesIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '4rem',
    },
    noFavoritesDetails: {
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        paddingTop: '0.5rem',
        paddingBottom: '2rem',
        fontWeight: 500,
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
    },
});

export function DonationSearchBegin({
    updateControllerValues,
    isClassRommReward,
}: CommonStepProps) {
    const classes = useStyles();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();

    const [searchString, setSearchString] = useState('');
    const [submittedSearchString, setSubmittedSearchString] = useState('');
    function onUpdateSearch(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
        setSearchString(event.target.value);
    }
    function handleSubmit(e: any) {
        e.preventDefault();
        customEvent(eventMetricsMap.giving_school_search);
        customEvent(eventMetricsMap.wallet_classroomrewards_schoolsearch);
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: 'search performed',
            element_location: 'search',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: 'search performed',
            element_location: 'search',
        });
        setSubmittedSearchString(searchString);
    }

    useEffect(() => {
        if (submittedSearchString.length > 0) {
            updateControllerValues({
                donateStep: 'searchResults',
                schoolSearch: submittedSearchString,
            });
            setSubmittedSearchString('');
        }
    }, [submittedSearchString]);

    return (
        <>
            <FullWidthCard
                hasManualShadow
                className={classes.mainCard}
                id="donation-flow-search-card"
            >
                <Collapse in={submittedSearchString === ''}>
                    <Typography className={classes.schoolSearchTitle}>
                        Looking for a teacher or educator? First, search for
                        their school.
                    </Typography>
                    <Typography className={classes.schoolSearchDescription}>
                        Enter the name, address or ZIP code of the recipient
                        school in the search bar below
                    </Typography>
                </Collapse>
                <form
                    className={classes.searchInputForm}
                    onSubmit={handleSubmit}
                >
                    <InputBase
                        id="donation-school-selection-search-input"
                        className={classes.searchInput}
                        placeholder="School name, city, state or zip code"
                        onChange={onUpdateSearch}
                        value={searchString}
                        startAdornment={
                            <ButtonBase
                                onClick={handleSubmit}
                                id="donation-school-selection-search-button"
                                className={classes.searchIconBtn}
                            >
                                <img
                                    id="donation-school-search-icon-img"
                                    src={require('../../assets/icons/iconSearchThin.svg')}
                                    className={classes.searchIcon}
                                    alt="search icon"
                                />
                            </ButtonBase>
                        }
                    />
                </form>
            </FullWidthCard>
            {isClassRommReward ? (
                <div style={{ height: '300px' }} />
            ) : (
                <FullWidthCard
                    hasManualShadow
                    className={classes.mainCard}
                    id="favorites-card"
                >
                    <FavoritesActivity />
                </FullWidthCard>
            )}
        </>
    );
}

export default DonationSearchBegin;
