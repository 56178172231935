import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
    rewardsDonationEducatorURL,
    rewardsDonationInstitutionURL,
} from 'src/services/urls';

export type DonationResponseCode =
    | 'TransactionComplete'
    | 'InvalidIdentifier'
    | 'CollectionAccountNotFound'
    | 'DestinationNotFound'
    | 'DestinationNotEducator'
    | 'SourceNotFound'
    | 'InsufficientFunds'
    | 'DonationAccountNotFound'
    | 'GiftLimitReached';

export type DonationResponse = {
    isSuccess: boolean;
    code: DonationResponseCode;
    errorMessage?: string;
    errorCode?: string;
};

/**
 * @param institutionWalletId format: 'classroomrewards:v1:cousino-high-school:warren:mi'
 * @param amount (in cents)
 */
export async function donateToInstitution(
    institutionWalletId: string,
    amount: number
): Promise<null | DonationResponse> {
    const donation = await axiosInstance.post(rewardsDonationInstitutionURL, {
        destinationIdentifier: institutionWalletId,
        pointsAmount: amount,
    });
    if (donation.data) {
        return donation.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when donating via ${rewardsDonationInstitutionURL}`
    );
    Log.axios(donation);
    return null;
}

/**
 * @param recipientWalletId format: '355B5E57-3396-4C3F-ADA4-FE2419D954D5'
 * @param amount (in cents)
 */
export async function donateToEducator(
    recipientWalletId: string,
    amount: number
): Promise<null | DonationResponse> {
    const donation = await axiosInstance.post(rewardsDonationEducatorURL, {
        destinationIdentifier: recipientWalletId,
        pointsAmount: amount,
    });
    if (donation.data) {
        return donation.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when donating via ${rewardsDonationEducatorURL}`
    );
    Log.axios(donation);
    return null;
}
