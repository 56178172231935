/* eslint-disable import/prefer-default-export */
import { LANDING_SCREENS } from 'src/lib/routes';

export const isOnLandingScreen = (
  query: Record<string, any>,
  pathname: string
) =>
  Object.keys(query).length === 0 && // no other query == no enrollment or donations happening
  (!!LANDING_SCREENS.find((screen) => pathname === screen) as boolean);

export function enableZoom() {
  // Check if the viewport meta tag exists
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport) {
    // If it does exist, set the content attribute to enable zooming
    viewport.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=10, user-scalable=yes'
    );
  }
}
