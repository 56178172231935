// eslint-disable-next-line import/prefer-default-export
export const en = {
  title: 'Staples Connect',
  login: 'login',
  signup: 'signup',
  logout: 'logout',
  profile: 'profile',
  'other-page': 'To other Page',
  back: 'Back to home page',
} as any;
