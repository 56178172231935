import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import {
    Typography,
    makeStyles,
    TextField,
    withStyles,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    RadioProps,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { useQuery } from 'react-query';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import { INDUSTRIES } from 'src/lib/constants';
import FullWidthCard from 'src/components/cards/full-width-card';
import { phoneNumberValidation } from 'src/lib/validations';
import {
    CommonStepProps,
    getRewardsErrorType,
} from 'src/screens/rewards-enroll-flow/types';
import useUserInfo from 'src/hooks/useUserInfo';
import {
    AccountCreateRequest,
    createRewardsAccount,
    createSRWAccount,
} from 'src/lib/api-client/rewards-enrollment';
import LoadingButton from 'src/components/loading-button';
import InfoButton, { COMMON_INFO_TEXT } from 'src/components/info-button';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import useError from './use-error';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '2.5rem 1.2rem 1.5rem',
    },
    inputMargin: {
        marginTop: theme.spacing(2.3),
    },
    radioMargin: {
        marginTop: theme.spacing(3),
    },
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        justifyContent: 'center',
        paddingBottom: '2rem',
        minHeight: '100vh',
    },
    backIcon: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: '2rem',
    },
    enrollFormCard: {
        marginTop: '1rem',
        marginLeft: '24px',
        marginRight: '24px',
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
    },
    formDescription: {
        fontSize: '1rem',
        textAlign: 'center',
        marginTop: '0.2rem',
        padding: '0 0.6rem',
        lineHeight: '1.2rem',
        fontWeight: 500,
    },
    nameTooltipContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: '0.3rem 0 -3rem',
        zIndex: 9999,
        top: '10px',
        position: 'relative',
    },
    selectIconWrapper: {
        marginTop: '1.9rem',
        display: 'flex',
        justifyContent: 'center',
        outline: 'none' /* for removing the blue outline */,
    },
    selectOption: {
        whiteSpace: 'pre-wrap',
    },
    radioWrapper: {
        justifyContent: 'space-between',
    },
    tooltipPosition: {
        height: '26px',
        top: '10px',
    },
    nextButton: {
        textTransform: 'none',
        marginTop: '3rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
}));

const ValidationTextField = withStyles({
    root: {
        '& label + .MuiInput-formControl': {
            marginTop: '0.7rem',
        },
        '& label.Mui-focused, & label.Mui-error': {
            color: COLORS.secondaryNeutralCharcoal,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: COLORS.secondaryNeutralBlack,
            borderWidth: '2px',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: COLORS.brandRed,
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderColor: 'red',
        },
    },
})(TextField);

const RadioLabel = withStyles({
    root: {
        '&.Mui-focused, &.MuiFormLabel-root': {
            color: COLORS.secondaryNeutralCharcoal,
        },
    },
})(FormLabel);

const SelectLabel = withStyles({
    root: {
        '&.Mui-focused': {
            color: COLORS.secondaryNeutralCharcoal,
        },
    },
})(InputLabel);

const SelectInput = withStyles({
    root: {
        '& .MuiInput-underline:before': {
            borderBottomColor: COLORS.secondaryNeutralBlack,
            borderWidth: '2px',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: COLORS.brandRed,
        },
        '& .MuiSvgIcon-root': {
            display: 'none',
        },
    },
})(FormControl);

const BlackRadio = withStyles({
    root: {
        color: COLORS.secondaryNeutralBlack,
        '&$checked': {
            color: COLORS.secondaryNeutralBlack,
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const validationSchema = yup.object({
    firstName: yup.string().required('First name is a required field.'),
    lastName: yup.string().required('Last name is a required field.'),
    phoneNumber: phoneNumberValidation.required(
        'Phone number is a required field.'
    ),
    companyName: yup.string().when('shopFor', {
        is: (value: string) => value && value === 'Business',
        then: yup.string().required('Organization is a required field.'),
        otherwise: yup.string().notRequired(),
    }),
    email: yup
        .string()
        .required('Email is a required field.')
        .email('Enter a valid email address.'),
});

export function RewardsEnrollSignUp({
    userType,
    updateControllerValues,
    onError,
    flowName,
}: CommonStepProps) {
    const classes = useStyles();
    const { showError } = useError(onError);
    const [activeField, setActiveField] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectOpen, setSelectOpen] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();
    const isSRW = flowName === 'SRW';
    const { userInfo } = useUserInfo();
    function navigateToEnrollNextStep() {
        if (isSRW) {
            updateControllerValues({ enrollStep: 'srwConfirmation' });
        } else {
            updateControllerValues({
                enrollStep:
                    userType === 'Supporter' ? 'success' : 'selectSchool',
            });
        }
    }

    const [pendingRequest, setPendingRequest] =
        useState<AccountCreateRequest | null>(null);
    const {
        values,
        handleSubmit,
        handleChange,
        errors,
        touched,
        setFieldError,
    } = useFormik({
        initialValues: {
            firstName: userInfo && userInfo.firstName ? userInfo.firstName : '',
            lastName: userInfo && userInfo.lastName ? userInfo.lastName : '',
            email: userInfo && userInfo.email ? userInfo.email : '',
            // TODO: STP-1310 reenable phone number
            phoneNumber:
                userInfo && userInfo.phone
                    ? userInfo.phone.replace('+1', '').replace(/[^0-9]/gi, '')
                    : '',
            shopFor: 'Myself',
            companyName: '',
            industry: '',
            // values needed for the api call
            userType,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (currentValues) => {
            const phoneNumber = (currentValues?.phoneNumber || '').replace(
                /[^0-9]/gi,
                ''
            );
            const industry =
                currentValues?.shopFor === 'Business' ? selectedIndustry : '';
            const companyName =
                currentValues?.shopFor === 'Business'
                    ? currentValues?.companyName
                    : '';
            setPendingRequest({
                ...currentValues,
                phoneNumber,
                companyName,
                industry,
            });
        },
    });

    const { data, error, isFetching } = isSRW
        ? useQuery(
              ['createSRWAccount', pendingRequest],
              () => createSRWAccount(pendingRequest as AccountCreateRequest),
              { enabled: !!pendingRequest }
          )
        : useQuery(
              ['createRewardsAccount', pendingRequest],
              () =>
                  createRewardsAccount(pendingRequest as AccountCreateRequest),
              { enabled: !!pendingRequest }
          );
    useEffect(() => {
        if (!isFetching) {
            setPendingRequest(null);
            if (data) {
                const { isSuccess } = data;
                if (isSuccess) {
                    analyticsCustomEvent(
                        eventMetricsMap.classroom_rewards_enroll,
                        {
                            click_text: 'sign up - success',
                            element_location: 'account creation',
                            sign_details: values?.shopFor,
                        }
                    );
                    optimoveEvents(
                        eventMetricsMap.scm_classroom_rewards_enroll,
                        {
                            click_text: 'sign up - success',
                            element_location: 'account creation',
                            sign_details: values?.shopFor,
                        }
                    );
                    navigateToEnrollNextStep();
                } else {
                    analyticsCustomEvent(
                        eventMetricsMap.classroom_rewards_enroll,
                        {
                            click_text: 'sign up - failure',
                            element_location: 'account creation',
                            sign_details: values?.shopFor,
                        }
                    );
                    optimoveEvents(
                        eventMetricsMap.scm_classroom_rewards_enroll,
                        {
                            click_text: 'sign up - failure',
                            element_location: 'account creation',
                            sign_details: values?.shopFor,
                        }
                    );
                    showError('signUp', getRewardsErrorType(data.code));
                }
            } else if (error) {
                analyticsCustomEvent(eventMetricsMap.classroom_rewards_enroll, {
                    click_text: 'sign up - failure',
                    element_location: 'account creation',
                    sign_details: values?.shopFor,
                });
                optimoveEvents(eventMetricsMap.scm_classroom_rewards_enroll, {
                    click_text: 'sign up - failure',
                    element_location: 'account creation',
                    sign_details: values?.shopFor,
                });
                showError('signUp', 'unknown'); // Could not find an account
            }
        }
    }, [data, error, isFetching]);

    const changeLabel = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        setFieldError(fieldName, '');
        setActiveField(fieldName);
    };

    return (
        <FullWidthCard
            hasManualShadow
            className={classes.enrollFormCard}
            id="rewards-enroll-signup-card"
        >
            <Typography
                className={classes.formDescription}
                id="rewards-enroll-signup-title"
            >
                First, we'll need a few details.
            </Typography>
            <form
                onSubmit={handleSubmit}
                className={classes.root}
                id="rewards-enroll-signup-form"
            >
                <ValidationTextField
                    fullWidth
                    id="rewards-enroll-signup-first-name-input"
                    name="firstName"
                    label={
                        values.firstName || activeField === 'firstName'
                            ? 'First Name'
                            : 'First Name*'
                    }
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={(e) => changeLabel(e)}
                    onFocus={(e) => changeLabel(e)}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                />
                {userType !== 'Supporter' && (
                    <div className={classes.nameTooltipContainer}>
                        <InfoButton
                            buttonId="rewards-enroll-signup-info-first-name-tooltip"
                            tooltipTitle={
                                COMMON_INFO_TEXT.rewardsSignUpFirstName
                            }
                            tooltipPlacement="top-end"
                            tooltipCta=""
                            buttonSize="2rem"
                        />
                    </div>
                )}
                <ValidationTextField
                    fullWidth
                    id="rewards-enroll-signup-last-name-input"
                    name="lastName"
                    label={
                        values.lastName || activeField === 'lastName'
                            ? 'Last Name'
                            : 'Last Name*'
                    }
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={(e) => changeLabel(e)}
                    onFocus={(e) => changeLabel(e)}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    className={classes.inputMargin}
                />
                <ValidationTextField
                    fullWidth
                    id="rewards-enroll-signup-email-address-input"
                    name="email"
                    label={
                        values.email || activeField === 'email'
                            ? 'Email Address'
                            : 'Email Address*'
                    }
                    value={values.email}
                    onChange={handleChange}
                    onBlur={(e) => changeLabel(e)}
                    onFocus={(e) => changeLabel(e)}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    className={classes.inputMargin}
                    inputMode="email"
                    type="email"
                />
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <InputMask
                            mask="999-999-9999"
                            onChange={handleChange}
                            onBlur={(e) => {
                                changeLabel(e);
                            }}
                            onFocus={(e) => changeLabel(e)}
                            name="phoneNumber"
                            type="text"
                            value={values.phoneNumber}
                        >
                            {() => (
                                <ValidationTextField
                                    fullWidth
                                    id="rewards-enroll-signup-phone-number-input"
                                    name="phoneNumber"
                                    label={
                                        values.phoneNumber ||
                                        activeField === 'phoneNumber'
                                            ? 'Phone number'
                                            : 'Phone number*'
                                    }
                                    inputMode="tel"
                                    type="phone"
                                    value={values.phoneNumber}
                                    error={
                                        touched.phoneNumber &&
                                        Boolean(errors.phoneNumber)
                                    }
                                    helperText={
                                        touched.phoneNumber &&
                                        errors.phoneNumber
                                    }
                                    className={classes.inputMargin}
                                />
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={2}>
                        <InfoButton
                            buttonId="rewards-enroll-signup-why-phone-tooltip"
                            buttonType="text"
                            tooltipTitle={COMMON_INFO_TEXT.rewardsSignUpPhone}
                            tooltipPlacement="top-end"
                            tooltipCta=""
                        />
                    </Grid>
                </Grid>
                <FormControl
                    component="fieldset"
                    className={classes.radioMargin}
                    id="rewards-enroll-signup-shop-for"
                    fullWidth
                >
                    <RadioLabel>I primarily shop for:</RadioLabel>
                    <RadioGroup
                        row
                        id="shop-for-radio-group"
                        aria-label="shopFor"
                        name="shopFor"
                        className={classes.radioWrapper}
                    >
                        <FormControlLabel
                            id="rewards-enroll-signup-shop-for-radio-group-option-myself"
                            onChange={handleChange}
                            checked={values.shopFor === 'Myself'}
                            value="Myself"
                            control={<BlackRadio />}
                            label="Myself"
                        />
                        <FormControlLabel
                            id="rewards-enroll-signup-shop-for-radio-group-option-business"
                            onChange={handleChange}
                            checked={values.shopFor === 'Business'}
                            value="Business"
                            control={<BlackRadio />}
                            label="A business"
                        />
                    </RadioGroup>
                </FormControl>
                {values.shopFor === 'Business' && (
                    <>
                        <ValidationTextField
                            fullWidth
                            id="rewards-enroll-signup-organization-input"
                            name="companyName"
                            label={
                                values.companyName ||
                                activeField === 'companyName'
                                    ? 'Organization'
                                    : 'Organization*'
                            }
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={(e) => changeLabel(e)}
                            onFocus={(e) => changeLabel(e)}
                            error={
                                touched.companyName &&
                                Boolean(errors.companyName)
                            }
                            helperText={
                                touched.companyName && errors.companyName
                            }
                            className={classes.inputMargin}
                            inputProps={{ maxLength: 30 }}
                        />
                        <Grid container>
                            <Grid item xs={10}>
                                <SelectInput
                                    className={classes.inputMargin}
                                    fullWidth
                                    id="rewards-enroll-signup-industry-select"
                                >
                                    <SelectLabel htmlFor="age-simple">
                                        Industry
                                    </SelectLabel>
                                    <Select
                                        open={selectOpen}
                                        onClose={() => setSelectOpen(false)}
                                        onOpen={() => setSelectOpen(true)}
                                        value={selectedIndustry}
                                        onChange={(e: any) => {
                                            setSelectedIndustry(e.target.value);
                                        }}
                                    >
                                        {INDUSTRIES.map((industry, index) => (
                                            <MenuItem
                                                id={`rewards-enroll-signup-industry-select-item-${index}`}
                                                key={`item-${industry}`}
                                                value={industry}
                                                className={classes.selectOption}
                                            >
                                                {industry}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </SelectInput>
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    tabIndex={0}
                                    role="button"
                                    onKeyPress={() => setSelectOpen(true)}
                                    onClick={() => setSelectOpen(true)}
                                    id="rewards-enroll-signup-industry-select-open-button"
                                    className={classes.selectIconWrapper}
                                >
                                    <img
                                        src={require('../../assets/icons/iconCaret.svg')}
                                        alt="carrot-icon"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )}
                <LoadingButton
                    fullWidth
                    loading={isFetching}
                    id="rewards-enroll-signup-signup-button"
                    variant="contained"
                    classes={{
                        root: classes.nextButton,
                    }}
                    type="submit"
                >
                    Sign up
                </LoadingButton>
            </form>
        </FullWidthCard>
    );
}
export default RewardsEnrollSignUp;
