import React from 'react';
import { useQuery } from 'react-query';
import { Box, makeStyles, Typography, ButtonBase } from '@material-ui/core';
import { GUTTER_WIDTH_SLIM } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthRow from 'src/components/full-width-row';
import { DonationActivity } from 'src/services/types';
import {
    fmtDollarsAndCents,
    formatCommasWithDecimal,
} from 'src/lib/utils/string-utils';
import { formatDistanceToNow, format, isBefore, isAfter } from 'date-fns';
import { getRewardsActivities } from 'src/lib/api-client/rewards-activities';
import useUserInfo from 'src/hooks/useUserInfo';
import InfoButton, { COMMON_INFO_TEXT } from 'src/components/info-button';
import { getDonationText, iconforActivity } from 'src/lib/utils/type-utils';

const activityDescription = (d: DonationActivity) => {
    const { dollars, cents } = fmtDollarsAndCents(d.pointsAmount);
    let toReturn = `You gave $${dollars}.${cents} of your Classroom Rewards to ${d.sourceOrDestination}.`;
    if (d.donationType === 'Credit') {
        toReturn = `A supporter gave you $${dollars}.${cents} of Classroom Rewards!`;
    } else if (d.donationType === 'BackendAdjustment') {
        toReturn = `Staples has gifted you $${dollars}.${cents} of Classroom Rewards!`;
    } else if (d.donationType === 'Earned') {
        toReturn = `You earned $${dollars}.${cents} in Classroom Rewards!`;
    }
    return toReturn;
};

// STP-386 reuse this date logic for main activity list
const dateDescription = (d: DonationActivity) => {
    const activityDate = Date.parse(d.activityDate);
    const twentyThreeHoursAgo = new Date(Date.now());
    twentyThreeHoursAgo.setHours(twentyThreeHoursAgo.getHours() - 23);

    const lessThanMinute = new Date(Date.now());
    lessThanMinute.setSeconds(lessThanMinute.getSeconds() - 30);

    if (isBefore(activityDate, twentyThreeHoursAgo)) {
        // if older than 23 hours ago, format as '5.1.21 • 1:30PM'
        return format(activityDate, 'M.d.yy • h:mmaa');
    }

    if (isAfter(activityDate, lessThanMinute)) {
        // if less than 1 minute ago, don't replace anything
        return formatDistanceToNow(activityDate, { addSuffix: true });
    }

    // otherwise format as '2h ago'
    return formatDistanceToNow(activityDate, { addSuffix: true })
        .replace('about ', '')
        .replace(' hours', 'h')
        .replace(' hour', 'h')
        .replace(' minutes', 'm')
        .replace(' minute', 'm');
};

const useStyles = makeStyles({
    row: {
        marginBottom: '2rem',
        flexDirection: 'column',
    },
    activityWrapper: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: `${COLORS.secondaryNeutralLightGray} solid 1px`,
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    recentActivityEmpty: {
        fontSize: '1.0625rem',
        fontWeight: 500,
        textAlign: 'center',
        padding: '2rem',
        letterSpacing: '-0.024rem',
        lineHeight: '1.25rem',
    },
    icon: {
        marginLeft: GUTTER_WIDTH_SLIM,
        width: '3.7rem',
    },
    rightContent: {
        paddingLeft: '1rem',
        paddingRight: GUTTER_WIDTH_SLIM,
        paddingBottom: '1rem',
    },
    amountText: {
        paddingTop: '1rem',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        letterSpacing: '-0.4pt',
    },
    descriptionText: {
        fontSize: '0.8rem',
        letterSpacing: '-0.4pt',
    },
    dateText: {
        paddingTop: '.35rem',
        fontSize: '0.85rem',
        letterSpacing: '0',
    },
    sectionTitle: {
        fontWeight: 500,
        marginLeft: GUTTER_WIDTH_SLIM,
    },
    sectionCTA: {
        fontWeight: 'bold',
        marginRight: GUTTER_WIDTH_SLIM,
        color: COLORS.brandCyan,
        cursor: 'pointer',
        fontSize: '1rem',
    },
    titleWrapper: {
        marginTop: '1.8rem',
        marginBottom: '.85rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

type RecentActivityProps = {
    onSeeAllPressed: () => void;
};

/** used for loading and displaying classroom reward summary */
export function RecentActivity({ onSeeAllPressed }: RecentActivityProps) {
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const { data: recentActivityData, isFetching: isFetchingRewardsActivity } =
        useQuery(
            'rewardsActivities',
            () => getRewardsActivities({ limit: 3 }),
            {
                refetchOnWindowFocus: false,
                refetchOnMount: true,
                refetchOnReconnect: false,
            }
        );
    if (
        isFetchingRewardsActivity ||
        !recentActivityData ||
        recentActivityData.length === 0
    ) {
        return (
            <Box>
                <Box className={classes.titleWrapper}>
                    <Typography
                        className={classes.sectionTitle}
                        id="recent-activity-title"
                    >
                        Recent Activity
                    </Typography>
                </Box>
                <FullWidthRow
                    className={classes.row}
                    style={{
                        padding: isFetchingRewardsActivity ? '0' : '0.5rem 0',
                    }}
                    loading={isFetchingRewardsActivity}
                >
                    <Typography className={classes.recentActivityEmpty}>
                        No recent activity. Shop at your local Staples store to
                        earn and give Classroom Rewards.
                    </Typography>
                </FullWidthRow>
            </Box>
        );
    }

    return (
        <Box>
            <Box className={classes.titleWrapper}>
                <Typography
                    className={classes.sectionTitle}
                    id="recent-activity-title"
                >
                    Recent Activity
                </Typography>
                {recentActivityData.length > 2 && (
                    <ButtonBase
                        disableRipple
                        className={classes.sectionCTA}
                        onClick={onSeeAllPressed}
                        id="recent-activity-cta"
                    >
                        See all
                    </ButtonBase>
                )}
            </Box>
            <FullWidthRow className={classes.row}>
                {userInfo?.giftLimitReached && (
                    <Box
                        id="recent-activity-max-limit"
                        className={classes.activityWrapper}
                    >
                        <img
                            id="recent-activity-max-limit-icon-image"
                            src={require('../assets/icons/iconErrorGivingMax.svg')}
                            alt="max limit icon"
                            className={classes.icon}
                        />

                        <Box className={classes.rightContent}>
                            <Typography
                                id="recent-activity-max-limit-text"
                                className={classes.amountText}
                            >
                                Donation limit met
                            </Typography>
                            <Typography
                                id="recent-activity-max-limit-description-text"
                                className={classes.descriptionText}
                            >
                                You've received your max donation limit.{' '}
                                <InfoButton
                                    tooltipTitle={
                                        COMMON_INFO_TEXT.donationLimit
                                    }
                                />
                            </Typography>
                        </Box>
                    </Box>
                )}
                {recentActivityData.slice(0, 3).map((item, index) => (
                    <Box
                        key={item.id}
                        id={`recent-activity-${index}`}
                        className={classes.activityWrapper}
                    >
                        <img
                            id={`recent-activity-${index}-icon-image`}
                            src={iconforActivity(item.donationType)}
                            alt="recent activity icon"
                            className={classes.icon}
                        />
                        <Box className={classes.rightContent}>
                            <Typography
                                id={`recent-activity-${index}-amount-text`}
                                className={classes.amountText}
                            >
                                $
                                {formatCommasWithDecimal(
                                    item.pointsAmount / 100
                                )}{' '}
                                {getDonationText(item.donationType)}
                            </Typography>
                            <Typography
                                id={`recent-activity-${index}-description-text`}
                                className={classes.descriptionText}
                            >
                                {activityDescription(item)}
                            </Typography>
                            <Typography
                                id={`recent-activity-${index}-date-text`}
                                className={classes.dateText}
                            >
                                {dateDescription(item)}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </FullWidthRow>
        </Box>
    );
}

export default RecentActivity;
