import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

/**
 * @returns The breakpoint value of the current screen (xs, md, lg, etc).
 *
 * Originally from https://next.material-ui.com/components/use-media-query/#migrating-from-withwidth
 *
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: any, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

/**
 * @returns true if we are on mobile screens or other thin width devices
 * @see useWidth
 */
export function useIsMobileWidth() {
  return useWidth() === 'xs';
}

export default useWidth;
