import React, { useState, useEffect } from 'react';
import { Typography, makeStyles, ButtonBase } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import COLORS from 'src/lib/colors';
import {
    getFavorites,
    addFavorite,
    deleteFavorite,
} from 'src/lib/api-client/favorites';
import { useQuery, useMutation } from 'react-query';
import { isFavorite, combineFavorites } from 'src/lib/utils/favorite-utils';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';

const useStyles = makeStyles({
    favIconWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '1rem',
        alignItems: 'center',
    },
    favIcon: {
        width: '1rem',
        height: '1rem',
    },
    favAdd: {
        marginLeft: '.5rem',
        fontSize: '.75rem',
        color: COLORS.brandCyan,
    },
    favDelete: {
        marginLeft: '.5rem',
        fontSize: '.75rem',
        color: COLORS.vividRed,
    },
});

type FavoriteButtonProps = {
    favoriteId?: string | null;
    index?: number;
    style?: any;
};

export function FavoriteButton({
    favoriteId,
    index,
    style = {},
}: FavoriteButtonProps) {
    const [isGettingFavorites, setIsGettingFavorites] = useState(true);
    const [isFav, setIsFav] = useState(false);
    const { notifyUserFavoriteUpdate } = useUserUpdateContext();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const classes = useStyles();

    const { data: favorites } = useQuery(['favorites'], getFavorites, {
        enabled: isGettingFavorites,
    });

    useEffect(() => {
        if (favorites && favoriteId) {
            setIsFav(
                isFavorite(
                    combineFavorites(
                        favorites.favoriteEducators,
                        favorites.favoriteInstitutions
                    ),
                    favoriteId
                )
            );
            setIsGettingFavorites(false);
        }
    }, [favorites]);

    const { mutate: mutateAddFavorite } = useMutation(addFavorite, {
        onSuccess: async () => {
            // Removing corresponding setState calls since the state was not being used
            setIsGettingFavorites(true);
            notifyUserFavoriteUpdate();
        },
    });

    const { mutate: mutateDeleteFavorite } = useMutation(deleteFavorite, {
        onSuccess: async () => {
            // Removing corresponding setState calls since the state was not being used
            setIsGettingFavorites(true);
            notifyUserFavoriteUpdate();
        },
    });

    function handleToggleFavorite() {
        if (isFav) {
            mutateDeleteFavorite(favoriteId);
            // Removing corresponding setState calls since the state was not being used
        } else {
            customEvent(eventMetricsMap.favorited);
            analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
                click_text: 'add favorites',
                element_location: 'search results',
            });
            optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
                click_text: 'add favorites',
                element_location: 'search results',
            });
            mutateAddFavorite(favoriteId);
            // Removing corresponding setState calls since the state was not being used
        }
    }

    return (
        <ButtonBase
            className={classes.favIconWrapper}
            id={`donation-school-search-result-favorite-${index}`}
            onClick={handleToggleFavorite}
            style={style}
        >
            <img
                className={classes.favIcon}
                src={
                    isFav
                        ? require('../assets/icons/iconFavStarRemove.svg')
                        : require('../assets/icons/iconFavStarAdd.svg')
                }
                alt={isFav ? 'Remove favorite' : 'Add favorite'}
            />
            <Typography className={isFav ? classes.favDelete : classes.favAdd}>
                {isFav ? 'Remove favorite' : 'Add favorite'}
            </Typography>
        </ButtonBase>
    );
}

export default FavoriteButton;
