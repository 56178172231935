import React from 'react';
import { Box, Collapse, makeStyles } from '@material-ui/core';
import { useScreenScrollContext } from 'src/lib/contexts/screen-scroll-context';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import COLORS from 'src/lib/colors';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';

type NavTopProps = {
    position?: any;
    bgImageSrc?: string;
    bgColor?: string;
    marginBottom?: string;
    children?: any;
    minHeight?: string;
    maxHeight?: string;
    className?: string;
    id?: string;
    withTopScroll?: boolean;
    noBorderRadius?: boolean;
};

const DEFAULT_BACKGROUND_COLOR = COLORS.homeBlack;

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: ({ minHeight }: any) => minHeight || '10rem',
        maxHeight: ({ maxHeight }: any) => maxHeight || '15rem',
        width: '100%',
        flexShrink: 1,
        backgroundImage: ({ bgImageSrc }: any) =>
            `url(${bgImageSrc})` || 'none',
        backgroundColor: ({ bgColor }: any) =>
            bgColor || DEFAULT_BACKGROUND_COLOR,
        marginBottom: ({ marginBottom }: any) => marginBottom,
        borderRadius: ({ isMobileWidth, noBorderRadius }: any) =>
            isMobileWidth || noBorderRadius ? 0 : '.5rem',
        animation: 'margin-bottom 100ms',
        zIndex: 1,
    },
});

function StickyTopScroll({ bgColor }: { bgColor?: string }) {
    const { show } = useScreenScrollContext();
    const { activeScreen } = useScreenOrderContext();
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                width: '100%',
                zIndex: 2500,
                pointerEvents: 'none',
            }}
        >
            <Collapse
                in={show && activeScreen === SCREEN_NAMES.BASE}
                style={{
                    backgroundColor: bgColor || DEFAULT_BACKGROUND_COLOR,
                    width: '100%',
                    height: '3rem',
                }}
            >
                &nbsp;
            </Collapse>
        </div>
    );
}

/**
 * Contains a background image, upper content, and negative margin to overlay content.
 * It also triggers overlay scroll
 */
export default function NavTop({
    position,
    children,
    bgImageSrc = require('../assets/bgs/bgIllustrationDarkbackground.svg'),
    bgColor,
    marginBottom,
    minHeight,
    maxHeight,
    withTopScroll,
    noBorderRadius,
    id,
    className = '',
}: NavTopProps) {
    const isMobileWidth = useIsMobileWidth();
    const classes = useStyles({
        bgImageSrc,
        bgColor,
        minHeight,
        maxHeight,
        isMobileWidth,
        noBorderRadius,
    });
    return (
        <div>
            {withTopScroll && <StickyTopScroll bgColor={bgColor} />}
            <Box
                className={`${classes.container} ${className}`}
                style={{
                    marginBottom,
                    position: position ?? '',
                }}
                id={id}
            >
                {children}
            </Box>
        </div>
    );
}
