import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem',
  },
  content: {
    paddingRight: '0.5rem',
  },
  ctaLabel: {
    fontWeight: 'bold',
    color: COLORS.brandCyan,
    paddingRight: '0.5rem',
  },
});

export default useStyles;
