import React, { useLayoutEffect } from 'react';
import { useGuestMode, useRouter, useUserInfo, useUserState } from 'src/hooks';
import ROUTES from 'src/lib/routes';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import LoadingOverlay from 'src/components/loading-overlay';
import { getLoyaltyAccountDetails } from 'src/services/easyRewardsServices';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';

const showEnrollment = (): boolean => {
    // Check when enrollment flow was last accessed
    const currentTimestamp = Date.now();
    const rewardsLastAccessString = localStorage.getItem(
        'rewardsLastAccessDate'
    );
    const rewardsLastAccessTimestamp = rewardsLastAccessString
        ? parseInt(rewardsLastAccessString, 10)
        : currentTimestamp;
    const timeElapsed = currentTimestamp - rewardsLastAccessTimestamp;

    if (!rewardsLastAccessString || timeElapsed > 86_400_000) {
        localStorage.setItem(
            'rewardsLastAccessDate',
            currentTimestamp.toString()
        );
        return true;
    }
    return false;
};

function UserUseCaseHandler() {
    const [userState, setUserState] = useUserState();
    const { replace } = useRouter();
    const { userInfo, isFetchingUserInfo } = useUserInfo();
    const { guestModal } = useUserUpdateContext();
    const { isGuestModeOn } = useGuestMode();
    const {
        data: loyaltyAccountDetails,
        isLoading: isLoyaltyAccountDetailsLoading,
    } = getLoyaltyAccountDetails({
        rewardsNumber: userInfo?.rewardsNumber,
        config: {
            enabled: !!userInfo?.rewardsNumber,
        },
    });

    useLayoutEffect((): any => {
        if (isGuestModeOn) {
            replace(ROUTES.home);
        } else if (!isFetchingUserInfo && !isLoyaltyAccountDetailsLoading) {
            if (userInfo?.isonboardingdone || !showEnrollment()) {
                if (guestModal?.closeBehavior === 'onboarding') {
                    const redirectRoute = guestModal?.previousRoute || ROUTES.rewardsWallet;
                    replace(redirectRoute);
                } else {
                    replace(ROUTES.home);
                }
            } else if (userState.userType === 'new') {
                setUserState({ userType: null });
                replace(REWARDS_ENROLLMENT_ROUTES.newAccountIntro);
            } else if (
                userInfo?.rewardsNumber &&
                loyaltyAccountDetails?.accounts[0]
                    ?.termsandconditionaccepted === 'N'
            ) {
                setUserState({
                    userType: 'migrated',
                });
                replace(REWARDS_ENROLLMENT_ROUTES.enrollmentConfirmation);
            } else if (userInfo && !userInfo?.rewardsNumber) {
                setUserState({
                    userType: 'non-migrated',
                });
                replace(REWARDS_ENROLLMENT_ROUTES.welcome);
            } else {
                replace(REWARDS_ENROLLMENT_ROUTES.welcome);
            }
        }
    }, [isFetchingUserInfo, isLoyaltyAccountDetailsLoading]);
    return <LoadingOverlay theme="light" open />;
}

export default UserUseCaseHandler;
