/* eslint-disable max-len */
import React from 'react';

function DownChevron() {
    return (
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon/UI/Chevron-Link/down">
                <path id="chevron-CTAlink-down" d="M10.1016 2.40117C10.462 2.74724 10.4898 3.29178 10.1848 3.66838L10.1016 3.75882L6.10156 7.59882C5.74108 7.94488 5.17385 7.9715 4.78156 7.67868L4.68735 7.59882L0.687348 3.75882C0.296824 3.38391 0.296824 2.77608 0.687348 2.40117C1.04783 2.05511 1.61506 2.02849 2.00735 2.32131L2.10156 2.40117L5.39445 5.5616L8.68735 2.40117C9.04783 2.05511 9.61506 2.02849 10.0074 2.32131L10.1016 2.40117Z" fill="black" />
            </g>
        </svg>
    );
}

export default DownChevron;
