import React from 'react';
import clsx from 'clsx';
import { Card, CardProps, CardContent, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export type AppCardProps = CardProps & {
  loading?: boolean;
  noPadding?: boolean;
  hasManualShadow?: boolean;
};

const useStyles = makeStyles({
  basicBorderRadius: {
    borderRadius: '15px',
  },
  noPadding: {
    padding: '0px',
    paddingBottom: '0px !important',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    userSelect: "none",
  },
});

/**
 * Baseline card used across the app.
 * Adding an onClick property gives the card a default elevation
 * Use "loading" for a simple loading experience
 */
export default function AppCard({
  children,
  onClick,
  loading,
  noPadding,
  className,
  hasManualShadow,
  ...rest
}: AppCardProps) {
  const classes = useStyles();
  return (
    <Card
      elevation={hasManualShadow || onClick ? 3 : 0}
      className={clsx(classes.basicBorderRadius, className)}
      onClick={onClick}
      {...rest}
    >
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rect"
          width="100%"
          height="100%"
          style={{ minHeight: 'inherit', borderRadius: '15px' }}
        >
          <CardContent
            component="div"
            classes={{ root: noPadding ? classes.noPadding : undefined }}
          >
            {children}
          </CardContent>
        </Skeleton>
      ) : (
        <CardContent
          component="div"
          classes={{ root: noPadding ? classes.noPadding : undefined }}
        >
          {children}
        </CardContent>
      )}
    </Card>
  );
}
