import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    borderRadius: 150,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#c8c8c8',
    width: '90%',
    minHeight: 34,
    overflow: 'hidden',
  },
  countContainer: {
    fontSize: '1rem',
    margin: 0,
    flexGrow: 1,
    textAlign: 'center',
    border: 'solid white',
    borderWidth: '0px 1px',
    fontWeight: 500,
  },
  button: {
    display: 'flex',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#c8c8c8',
  },
  leftChevron: {
    transform: 'rotate(180deg)',
  },
});

export default useStyles;
