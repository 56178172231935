import React from 'react';
import IconInterface from './IconInterface';

function IconStore({ className }: IconInterface) {
    return (
        <svg
            className={className}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                fill="white"
            />
            <path
                d="M42.875 32.57C42.875 33.065 42.48 33.465 41.98 33.465C41.48 33.465 41.085 33.07 41.085 32.57C41.085 32.02 41.5 31.675 41.98 31.675C42.46 31.675 42.875 32.01 42.875 32.57ZM41.26 32.57C41.26 33.02 41.57 33.295 41.98 33.295C42.39 33.295 42.7 33.015 42.7 32.57C42.7 32.125 42.38 31.845 41.98 31.845C41.595 31.83 41.27 32.13 41.26 32.52V32.57ZM42.31 32.375C42.31 32.48 42.25 32.575 42.155 32.615L42.365 33H42.175L42.005 32.66H41.845V32.99H41.685V32.105H42.01C42.22 32.11 42.31 32.235 42.31 32.375ZM41.845 32.265V32.52H41.995C42.065 32.525 42.13 32.475 42.14 32.405V32.39C42.14 32.31 42.1 32.265 41.99 32.265H41.845Z"
                fill="#CC0000"
            />
            <path
                d="M36.675 16.545H12.91C10.655 16.545 8.935 18.375 8.935 20.63V33.345C8.935 33.39 8.975 33.435 9.02 33.435H12.095C12.14 33.435 12.185 33.395 12.185 33.345V20.44H37.405V33.345C37.405 33.39 37.445 33.43 37.49 33.435H40.565C40.61 33.435 40.65 33.395 40.65 33.345V20.63C40.65 18.375 38.93 16.545 36.675 16.545Z"
                fill="#CC0000"
            />
        </svg>
    );
}

export default IconStore;
