import React from 'react';
import {
    Button,
    Dialog,
    DialogProps,
    makeStyles,
    Typography,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Divider,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { EmailShareButton, LinkedinShareButton } from 'react-share';
import clsx from 'clsx';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useToastMessageContext } from 'src/lib/contexts/toast-message-context';

export type ShareDialogProps = DialogProps & {
    inviteType: 'general' | 'giving' | 'receiving' | 'inviting';
    amount?: number;
    onBasePage?: boolean;
};

// icon radius in rem
const ICON_RADIUS = 3;

const useStyles = makeStyles({
    paper: { borderRadius: '1rem', margin: '1rem' },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: `-${ICON_RADIUS}rem`,
        padding: '0 1.5rem',
        borderRadius: '1rem',
        backgroundColor: '#fff',
    },
    shareTitleWrapper: {
        padding: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    shareTitle: {
        fontSize: '1.2rem',
        fontWeight: 500,
        letterSpacing: '-0.5pt',
        alignSelf: 'center',
        marginBottom: '1rem',
    },
    dialogContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeIcon: {
        justifyContent: 'flex-end',
    },
    errorIcon: {
        width: '6.5rem',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
    },
    actionsWrapper: {
        width: '100%',
        justifyContent: 'flex-start',
    },
    shareButton: {
        fontWeight: 'bold',
        fontSize: '1.0625rem',
        textTransform: 'unset',
        justifyContent: 'flex-start',
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&:active': {
            backgroundColor: 'unset',
        },
    },

    shareButtonIcon: {
        marginRight: '0.5rem',
        height: '1.75rem',
    },
    rsShareButton: {
        width: '100%',
        display: 'flex',
    },
    rsIcon: {
        marginLeft: '0.4rem',
        marginRight: '0.5rem',
        height: '1.75rem',
        verticalAlign: 'bottom',
    },
    rsText: {
        fontWeight: 'bold',
        fontSize: '1.0625rem',
        verticalAlign: 'bottom',
    },
    actionContainer: {
        width: '100%',
    },
    buttonContainer: {
        width: '100%',
    },
    buttonLabel: {
        width: '100%',
    },
    dividerContainer: {
        width: '100%',
    },
});

/**
 * Share dialog to display options to share with others (text, email, LinkedIn)
 */
export default function ShareDialog(props: ShareDialogProps) {
    const { onClose, inviteType, amount, open, onBasePage, ...rest } = props;
    const classes = useStyles();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();

    function close(event: any) {
        if (onClose) {
            onClose(event, 'backdropClick');
        }
    }

    const staplesUrl = process.env.REACT_APP_PUBLIC_URL || '';

    let subject = 'Join Staples Connect Today';
    if (inviteType === 'inviting') {
        subject = 'Please join Staples Classroom Rewards so I can support you!';
    }
    const body = (): string => {
        switch (inviteType) {
            case 'general': {
                // eslint-disable-next-line max-len
                return `Enroll in the Classroom Rewards program, earn rewards, and donate those rewards to teachers and schools in your community! \n\n ${staplesUrl}`;
            }
            case 'inviting': {
                // eslint-disable-next-line max-len
                return `I want to give the Classroom Rewards dollars I earn from shopping at Staples to you! Use them like cash to buy the classroom supplies you and your students require. The Staples Connect App makes this process easy. Simply go to ${staplesUrl}, register for an account, and enroll as an educator to start receiving funds from me and other supporters.`;
            }
            case 'giving': {
                // eslint-disable-next-line max-len
                return `I just contributed $${amount} of my rewards through the Staples Connect app to help support supplies needed for my local schools and teachers. Enroll in the Classroom Rewards program, earn rewards, and donate those rewards to teachers and schools in your community! \n\n ${staplesUrl}`;
            }
            case 'receiving': {
                // eslint-disable-next-line max-len
                return `I just received $${amount} in Classroom Rewards from my community using the Staples Connect app. Help support your local schools and teachers today in getting them the supplies they need. Enroll in the Classroom Rewards program, earn rewards, and donate those rewards to teachers and schools in your community! \n\n ${staplesUrl}`;
            }
            default: {
                // eslint-disable-next-line max-len
                return `Enroll in the Classroom Rewards program, earn rewards, and donate those rewards to teachers and schools in your community! \n\n ${staplesUrl}`;
            }
        }
    };

    function openSMSMobile() {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: 'text',
            element_location: 'share staples connect',
        });
        window.open(`sms:?&body=${body()}`, '_self');
        return false;
    }
    const openEmail = () => {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: 'email',
            element_location: 'share staples connect',
        });
        window.location.href = `mailto: ?subject=${subject}&body=${body()}`;
    };

    const sheetOpenOnBasePage = useIsActiveScreen(
        SCREEN_NAMES.BASE_SHARE_SHEET,
        onBasePage && open
    );

    return (
        <Dialog
            open={onBasePage ? sheetOpenOnBasePage : open}
            classes={{
                paper: classes.paper,
            }}
            onClose={onClose}
            scroll="body"
            {...rest}
        >
            <DialogTitle
                disableTypography
                id="share-dialog-title"
                className={classes.shareTitleWrapper}
            >
                <Button
                    fullWidth
                    onClick={close}
                    className={classes.closeIcon}
                    id="share-close-button"
                >
                    <img
                        src={require('../assets/icons/iconCloseBlack.svg')}
                        alt="close icon"
                    />
                </Button>
                <Typography className={classes.shareTitle}>
                    Share Staples Connect!
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContentWrapper}>
                <DialogContentText className={classes.dialogContentText}>
                    Help Contribute to your local schools and teachers with
                    Staples Connect app. Enroll in the Classroom Rewards
                    program, earn rewards and donate those rewards to teachers
                    and schools in your community!
                </DialogContentText>
                <DialogActions className={classes.actionsWrapper}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className={classes.actionContainer}
                    >
                        <Box pb={1} className={classes.buttonContainer}>
                            <Button
                                fullWidth
                                onClick={openSMSMobile}
                                className={classes.shareButton}
                                id="share-text-button"
                            >
                                <img
                                    src={require('../assets/icons/iconText.svg')}
                                    alt="text"
                                    className={classes.shareButtonIcon}
                                />
                                Text
                            </Button>
                        </Box>
                        <Box className={classes.dividerContainer}>
                            <Divider color={COLORS.fordGray} />
                        </Box>
                        <Box pt={2} pb={2} className={classes.buttonContainer}>
                            <Button
                                fullWidth
                                onClick={openEmail}
                                className={classes.shareButton}
                                id="share-email-button"
                            >
                                <img
                                    src={require('../assets/icons/iconEmail.svg')}
                                    alt="text"
                                    className={classes.shareButtonIcon}
                                />
                                Email
                            </Button>
                        </Box>
                        <Box className={classes.dividerContainer}>
                            <Divider color={COLORS.fordGray} />
                        </Box>
                        <Box pt={2} pb={1} className={classes.buttonContainer}>
                            <LinkedinShareButton
                                title={subject}
                                url={staplesUrl}
                                source={body()}
                                className={clsx(
                                    classes.shareButton,
                                    classes.rsShareButton
                                )}
                                id="share-linkedin-button"
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.classroom_rewards,
                                        {
                                            click_text: 'LinkedIn',
                                            element_location:
                                                'share staples connect',
                                        }
                                    );
                                }}
                            >
                                <img
                                    src={require('../assets/icons/iconLinkedInNoFill.svg')}
                                    alt="text"
                                    className={classes.rsIcon}
                                />
                                <span className={classes.rsText}>LinkedIn</span>
                            </LinkedinShareButton>
                        </Box>
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export type ShareListDialogProps = DialogProps & {
    listName: string;
    url: string;
    handleClose?: () => void;
};

export function ShareListDialog(props: ShareListDialogProps) {
    const { handleClose, listName, url, open, ...rest } = props;
    const classes = useStyles();
    // const subject = `I've shared my ${listName}`;
    // const body = `Check out the list I created on Staples Connect: ${listName}\n${url}`;

    const { setToastMessage } = useToastMessageContext();

    // function openSMSMobile() {
    //   window.open(`sms:?&body=${body}`, '_self');
    //   return false;
    // }

    function close() {
        if (handleClose) handleClose();
    }

    return (
        <Dialog
            open={open}
            classes={{
                paper: classes.paper,
            }}
            onClose={close}
            scroll="body"
            {...rest}
        >
            <DialogTitle
                disableTypography
                id="share-dialog-title"
                className={classes.shareTitleWrapper}
            >
                <Button
                    fullWidth
                    onClick={close}
                    className={classes.closeIcon}
                    id="share-close-button"
                >
                    <img
                        src={require('../assets/icons/iconCloseBlack.svg')}
                        alt="close icon"
                    />
                </Button>
                <Typography className={classes.shareTitle}>
                    Share list
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContentWrapper}>
                <DialogContentText className={classes.dialogContentText}>
                    {listName}
                </DialogContentText>
                <DialogActions className={classes.actionsWrapper}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className={classes.actionContainer}
                    >
                        <Box pb={1} className={classes.buttonContainer}>
                            <Button
                                fullWidth
                                onClick={() => {
                                    navigator.clipboard.writeText(url);
                                    setToastMessage({ title: 'Link Copied!' });
                                    close();
                                }}
                                className={classes.shareButton}
                                id="share-text-button"
                            >
                                <img
                                    src={require('../assets/icons/iconSharedCopyLink.svg')}
                                    alt="text"
                                    className={classes.shareButtonIcon}
                                />
                                Copy link
                            </Button>
                        </Box>
                        {/* <Box className={classes.dividerContainer}>
              <Divider color={COLORS.fordGray} />
            </Box>
            <Box pb={1} className={classes.buttonContainer}>
              <Button
                fullWidth
                onClick={openSMSMobile}
                className={classes.shareButton}
                id="share-text-button"
              >
                <img
                  src={require('../assets/icons/iconText.svg')}
                  alt="text"
                  className={classes.shareButtonIcon}
                />
                Text
              </Button>
            </Box>
            <Box className={classes.dividerContainer}>
              <Divider color={COLORS.fordGray} />
            </Box>
            <Box pt={2} pb={2} className={classes.buttonContainer}>
              <EmailShareButton
                subject={subject}
                url={body}
                className={clsx(classes.shareButton, classes.rsShareButton)}
                id="share-email-button"
              >
                <img
                  src={require('../assets/icons/iconEmail.svg')}
                  alt="text"
                  className={classes.rsIcon}
                />
                <span className={classes.rsText}>Email</span>
              </EmailShareButton>
            </Box>
            <Box className={classes.dividerContainer}>
              <Divider color={COLORS.fordGray} />
            </Box>
            <Box pt={2} pb={1} className={classes.buttonContainer}>
              <LinkedinShareButton
                title={subject}
                url={url || ''}
                source={body}
                className={clsx(classes.shareButton, classes.rsShareButton)}
                id="share-linkedin-button"
              >
                <img
                  src={require('../assets/icons/iconLinkedInNoFill.svg')}
                  alt="text"
                  className={classes.rsIcon}
                />
                <span className={classes.rsText}>LinkedIn</span>
              </LinkedinShareButton>
            </Box> */}
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
