/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { getProtectionPlans } from 'src/lib/api-client/protection-plans';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import clsx from 'clsx';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { useHistory } from 'react-router-dom';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import moment from 'moment';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useStoreData } from 'src/hooks';
import { Store } from 'src/services/types';
import Carousel from '../Carousel';
import COLORS from '../../lib/colors';
import FullWidthCard from '../cards/full-width-card';
import AppLink from '../link';
import ROUTES from '../../lib/routes';
import StoreDetailsDrawer from '../store-details-drawer';

const useStyles = makeStyles((theme) => ({
    titleWrapper: {
        marginTop: '1.8rem',
        marginBottom: '.85rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    sectionTitle: {
        fontFamily: 'StaplesNormsBold',
        fontSize: '1.125rem',
        marginLeft: '1.5rem',
    },
    cardHeader: {
        display: 'flex',
        userSelect: 'none',
    },
    contaniner: {
        padding: '20px',
        [theme.breakpoints.down(390)]: {
            padding: '15px',
        },
        userSelect: 'none',
    },
    description: {
        marginLeft: '10px',
        marginBottom: '10px',
        color: COLORS.secondaryNeutralBlack,
    },
    skuImage: {
        height: '75px',
        width: 'auto',
        [theme.breakpoints.down(410)]: {
            height: '70px',
        },
    },
    helperText: {
        color: '#585858',
        fontSize: '14px',
    },
    helperTextDsc: {
        color: '#585858',
        fontSize: '14px',
        width: '173px',
        [theme.breakpoints.down(400)]: {
            fontSize: '12px',
        },
    },
    expiry: {
        color: '#858585',
        marginTop: '5px',
        fontSize: '12px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        [theme.breakpoints.down(400)]: {
            fontSize: '14px',
        },
    },
    transaction: {
        marginTop: '15px',
        color: '#101820',
        '& span:first-child': {
            fontWeight: 500,
        },
        '& span:nth-child(2)': {
            fontWeight: 400,
            marginLeft: '10px',
        },
        [theme.breakpoints.down(390)]: {
            fontSize: '14px',
        },
    },
    storeInfo: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    store: {
        color: '#086DD2',
        fontSize: '12px',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
    },
    storeAddress: {
        fontSize: '12px',
    },
    actions: {
        color: COLORS.brandCyan,
        fontSize: '14px',
        display: 'flex',
        marginTop: '15px',
        justifyContent: 'space-between',
    },

    action: {
        color: COLORS.brandCyan,
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    call: {
        marginTop: '10px',
        display: 'block',
        color: COLORS.primaryWhite,
        fontSize: '14px',
    },
    cardFooter: {
        background:
            'linear-gradient(276.38deg, #CC0000 -1.51%, #9A0000 101.46%)',
        padding: '10px 20px',
    },
    chat: {
        color: COLORS.primaryWhite,
        fontSize: '14px',
        lineHeight: '20px',
    },
    supportTitle: {
        fontSize: '22px',
        fontWeight: 700,
        color: COLORS.primaryWhite,
        [theme.breakpoints.down(390)]: {
            fontSize: '18px',
        },
    },
    reg: {
        fontSize: '12px',
    },
    expired: {
        color: COLORS.brandRed,
    },
    fileStatus: {
        fontSize: '16px',
        fontWeight: 500,
    },
    footerLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    learnMore: {
        marginTop: '15px',
        display: 'inline-block',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    protectionContainer: {
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '20px',
    },
    emptyState: {
        marginBottom: '20px',
        marginLeft: '16px',
        marginRight: '16px',
    },
    activeText: {
        color: '#CC0000',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0px',
    },
}));
export function ProtectionPlans() {
    const classes = useStyles();
    const { setStoreLinkInNative } = useTabsUIStateContext();
    const [isLoading, setLoading] = useState(false);
    const [protectionPlanData, setProtectionPlanData] = useState([]);
    const { analyticsCustomEvent } = useEventsReporter();
    const { push: goto } = useHistory();
    const asurionUrl = `${process.env.REACT_APP_DOTCOM_URL}/staples`;
    const staplesProtection = `${process.env.REACT_APP_DOTCOM_URL}/stores/tech/pcsupport`;
    const { mainStore, additionalStores, updateMainStore } = useStoreData();
    const [storeDetailsModalOpen, setStoreDetailsModalOpen] = useState(false);

    const onStoreSelected = (store: Store) => {
        updateMainStore(store);
        // setSearchModalOpen(false);
    };
    const getProtectionPlansData = async () => {
        setLoading(true);
        const response: any = await getProtectionPlans();
        if (response.length) {
            setProtectionPlanData(response);
        }
        setLoading(false);
    };
    useEffect(() => {
        getProtectionPlansData();
    }, []);
    const viewReceipt = () => {
        goto(ROUTES.previousOrders);
    };
    const navigateToStore = () => {
        setStoreDetailsModalOpen(true);
    };
    const formatTransactionNumber = (traId: string) => {
        if (!traId) {
            return '';
        }
        const splitData = traId?.split('.');
        const storeNumber = `0000${splitData[1]}`.slice(-4);
        const date = moment(splitData[2]).format('MMDDYY');
        const transactionNumber = `00000${splitData[4]}`.slice(-5);
        const rgisterNumber = `00${splitData[3]}`.slice(-2);
        return `${storeNumber}-${date}-${transactionNumber}-${rgisterNumber}`;
    };

    if (isLoading) {
        return null;
    }

    return (
        <>
            <Box className={classes.titleWrapper}>
                <Typography className={classes.sectionTitle}>
                    Protection & Tech Help Plans
                </Typography>
            </Box>
            {protectionPlanData?.length ? (
                <>
                    <Carousel
                        animateHeight={false}
                        activeColor={COLORS.brandRed}
                        content={protectionPlanData?.map(
                            ({
                                status,
                                skudescription,
                                subscriptionstartdate,
                                subscriptionsenddate,
                                posid,
                                ereceipttransactionnumber,
                                storenumber,
                                storecity,
                                storestate,
                                storezipcode,
                                skuimageurl,
                                storeactive,
                            }: any) => (
                                <FullWidthCard
                                    noPadding
                                    id="protection-plans-container"
                                    className={classes.protectionContainer}
                                >
                                    <div className={classes.contaniner}>
                                        <div className={classes.cardHeader}>
                                            {skuimageurl ? (
                                                <img
                                                    className={classes.skuImage}
                                                    src={skuimageurl}
                                                    alt="protection plan"
                                                />
                                            ) : (
                                                <img
                                                    className={classes.skuImage}
                                                    src={require('../../assets/icons/iconAsurion.svg')}
                                                    alt="protection plan"
                                                />
                                            )}
                                            <Box>
                                                <div
                                                    className={
                                                        classes.description
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.title
                                                        }
                                                    >
                                                        {skudescription}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.helperText
                                                        }
                                                    >
                                                        <div>
                                                            powered by Asurion
                                                        </div>
                                                        <div
                                                            className={clsx(
                                                                classes.expiry,
                                                                status !==
                                                                    'Active' &&
                                                                    classes.expired
                                                            )}
                                                        >
                                                            exp &nbsp;
                                                            {format(
                                                                new Date(
                                                                    subscriptionsenddate
                                                                ),
                                                                'MM/dd/yyyy'
                                                            )}
                                                        </div>
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </div>
                                        <Typography
                                            className={classes.transaction}
                                        >
                                            <span>Plan #</span>
                                            <span>
                                                {formatTransactionNumber(posid)}
                                            </span>
                                        </Typography>
                                        {storenumber &&
                                        storecity &&
                                        storestate &&
                                        storezipcode ? (
                                            <div className={classes.storeInfo}>
                                                <Typography
                                                    className={classes.store}
                                                    onClick={() =>
                                                        navigateToStore()
                                                    }
                                                >
                                                    Staples Store #{storenumber}{' '}
                                                    {!storeactive ? (
                                                        <span
                                                            className={
                                                                classes.activeText
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color: '#000000',
                                                                }}
                                                            >
                                                                &nbsp;-{' '}
                                                            </span>
                                                            Permanently closed
                                                        </span>
                                                    ) : null}
                                                </Typography>
                                                {storeactive ? (
                                                    <Typography
                                                        className={
                                                            classes.storeAddress
                                                        }
                                                    >
                                                        {storecity},{' '}
                                                        {storestate}{' '}
                                                        {storezipcode}
                                                    </Typography>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        <Typography className={classes.expiry}>
                                            Purchased on &nbsp;
                                            {format(
                                                new Date(subscriptionstartdate),
                                                'MM/dd/yyyy'
                                            )}
                                        </Typography>
                                        <div className={classes.actions}>
                                            {status === 'Active' ? (
                                                <ButtonBase
                                                    className={classes.action}
                                                    onClick={() => {
                                                        openInNewTab(
                                                            asurionUrl
                                                        );
                                                        analyticsCustomEvent(
                                                            eventMetricsMap.stores,
                                                            {
                                                                click_text:
                                                                    'file a claim',
                                                                element_location:
                                                                    'promotion tiles',
                                                                tile_category:
                                                                    'asurian carousel',
                                                            }
                                                        );
                                                    }}
                                                >
                                                    File a claim
                                                </ButtonBase>
                                            ) : (
                                                <Typography
                                                    className={clsx(
                                                        classes.fileStatus,
                                                        classes.expired
                                                    )}
                                                >
                                                    EXPIRED
                                                </Typography>
                                            )}
                                            <ButtonBase
                                                onClick={() => {
                                                    viewReceipt();
                                                    analyticsCustomEvent(
                                                        eventMetricsMap.stores,
                                                        {
                                                            click_text:
                                                                'view receipt',
                                                            element_location:
                                                                'promotion tiles',
                                                            tile_category:
                                                                'asurian carousel',
                                                        }
                                                    );
                                                }}
                                                className={classes.action}
                                            >
                                                View Receipt
                                            </ButtonBase>
                                        </div>
                                    </div>
                                    {status === 'Active' ? (
                                        <AppLink
                                            href="tel:8447287854"
                                            className={classes.footerLink}
                                        >
                                            <div className={classes.cardFooter}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.chat}
                                                >
                                                    Chat, call or come in for
                                                    help with a
                                                </Typography>
                                                <Typography
                                                    variant="h3"
                                                    className={
                                                        classes.supportTitle
                                                    }
                                                >
                                                    Staples
                                                    <sup
                                                        className={classes.reg}
                                                    >
                                                        &reg;
                                                    </sup>{' '}
                                                    Total Support Plan.
                                                </Typography>
                                                <Typography
                                                    className={classes.call}
                                                >
                                                    Protection and Tech Help
                                                    questions? <br />
                                                    <u>Chat with Asurion</u>
                                                </Typography>
                                            </div>
                                        </AppLink>
                                    ) : null}
                                </FullWidthCard>
                            )
                        )}
                        dots={protectionPlanData.length}
                        styles={{
                            containerStyle: {
                                width: '100%',
                            },
                        }}
                    />
                    {storeDetailsModalOpen ? (
                        <StoreDetailsDrawer
                            mainStore={mainStore}
                            nearbyStores={additionalStores}
                            onStoreSelected={onStoreSelected}
                            open={storeDetailsModalOpen}
                            ignoreIsActiveScreen
                            handleClose={() => setStoreDetailsModalOpen(false)}
                        />
                    ) : null}
                </>
            ) : (
                <FullWidthCard
                    noPadding
                    id="protection-plans-container"
                    className={classes.emptyState}
                >
                    <div className={classes.contaniner}>
                        <div className={classes.cardHeader}>
                            <img
                                aria-hidden="true"
                                style={{ marginTop: '5px' }}
                                className={classes.skuImage}
                                src={require('../../assets/icons/iconAsurion.svg')}
                                alt="protection plan"
                            />
                            <Box>
                                <div className={classes.description}>
                                    <Typography className={classes.title}>
                                        Staples
                                        <sup className={classes.reg}>
                                            &reg;
                                        </sup>{' '}
                                        Total Support Plan
                                    </Typography>
                                    <Typography
                                        className={classes.helperTextDsc}
                                    >
                                        <div>
                                            Protection for you and your <br />
                                            computer, starting at <br /> only
                                            $199.99.
                                        </div>
                                    </Typography>
                                    <ButtonBase
                                        className={classes.learnMore}
                                        onClick={() => {
                                            setStoreLinkInNative({
                                                src: staplesProtection,
                                                show: true,
                                            });
                                            analyticsCustomEvent(
                                                eventMetricsMap.stores,
                                                {
                                                    click_text: 'learn more',
                                                    element_location:
                                                        'promotion tiles',
                                                    tile_category:
                                                        'asurian carousel',
                                                }
                                            );
                                        }}
                                    >
                                        Learn more
                                    </ButtonBase>
                                </div>
                            </Box>
                        </div>
                    </div>
                </FullWidthCard>
            )}
        </>
    );
}

export default ProtectionPlans;
