import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    ButtonBase,
} from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '15px',
        padding: 24,
    },
    titleWrapper: {
        marginBottom: '13px',
        fontSize: '17px',
        fontWeight: 700,
        lineHeight: '20.6px',
        letterSpacing: '-0.4px',
        color: '#101820',
        padding: 0,
    },
    dialogContent: {
        maxWidth: '250px',
        marginBottom: '24px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.4px',
        padding: 0,
        textAlign: 'center',
        color: '#101820',
        '& span': {
            color: '#086DD2',
        },
    },
    bthCta: {
        height: '40px',
        width: '90%',
        borderRadius: '20px',
        background: '#000000',
        '& span': {
            fontSize: '17px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.4px',
            textAlign: 'center',
            color: '#FFFFFF',
        },
    },
});

const walletSuspendedDialog = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { history } = useRouter();
    const classes = useStyles();
    const backToHomeHandle = () => {
        history.push({
            pathname: ROUTES.home,
        });
        onClose();
    };
    return (
        <Dialog
            open={open}
            aria-labelledby="wallet-suspended-dialog-title"
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle
                disableTypography
                id="wallet-suspended-dialog-title-id"
                className={classes.titleWrapper}
            >
                Account Suspended
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                Your account is suspended from Classroom Rewards. For more
                information, please contact us &nbsp;<span>here.</span>
            </DialogContent>
            <ButtonBase className={classes.bthCta} onClick={backToHomeHandle}>
                <span>Back to Home</span>
            </ButtonBase>
        </Dialog>
    );
};

export default walletSuspendedDialog;
