import { useEffect, useCallback, useMemo } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { RewardsDetails, User } from 'src/services/types';
import {
    getAvailablePayoutAmount,
    isAccountSetupFinished,
} from 'src/lib/utils/account-utils';
import { getOidcUserFromSessionStorageParsed } from 'src/lib/utils/oidc-utils';
import useUserInfo from 'src/hooks/useUserInfo';
import eventMetricsMap, {
    eventMetricsEventsType,
} from 'src/hooks/useEventsReporter/event-types';
import Log from 'src/lib/logging';
import { useAnalytics } from 'reactfire';

// TODO: STP-1945
// returning "0" if id is present for now, to avoid having non-obfuscated id in event data
const obfuscateId = (id: string | undefined) => (id ? '0' : id);

const debugLog = (
    eventType: string,
    eventName: string | null,
    eventParams: facebook.Pixel.CustomParameters | undefined,
    info: Record<string, unknown>
) => {
    // eslint-disable-next-line no-console
    Log.eventsMetricsManager(
        `${eventType || 'Unknown eventType'} ${eventName || 'Unknown eventName'}
Params: ${JSON.stringify(eventParams || {})}
Additional Info: ${JSON.stringify(info || {})}`
    );
};

type UserMarketingDetails = {
    userType: User['userType'];
    classroomRewardsEnrolled: boolean;
    staplesRewardsNumber: User['rewardsNumber'];
    akamaiSubId: string | undefined;
    lifetimeDonations: RewardsDetails['lifetimeDonationAmount'];
    availableToDonate: RewardsDetails['availableDonationAmount'];
    rewardsAvailable: RewardsDetails['payoutAvailableAmount'];
};
// TODO TRACKING
export function useEventsReporter() {
    const { userInfo } = useUserInfo();
    const oidcSub = getOidcUserFromSessionStorageParsed()?.profile?.sub;
    const analytics = useMemo(() => {
        // utm_source=trusted-web-activity
        const isTWA =
            sessionStorage.getItem('isTWA') === 'true' ||
            new URLSearchParams(window.location.search).get('utm_source') ===
            'trusted-web-activity';
        sessionStorage.setItem('isTWA', `${isTWA}`);
        return isTWA ? firebase.app('APK').analytics() : useAnalytics();
    }, []);

    const userMarketingDetails: UserMarketingDetails | undefined = useMemo(
        () =>
            userInfo
                ? {
                    userType: userInfo.userType,
                    classroomRewardsEnrolled:
                        isAccountSetupFinished(userInfo),
                    staplesRewardsNumber: userInfo.rewardsNumber,
                    akamaiSubId: obfuscateId(oidcSub),
                    lifetimeDonations:
                        userInfo.rewardsDetails?.lifetimeDonationAmount || 0,
                    availableToDonate:
                        userInfo.rewardsDetails?.availableDonationAmount || 0,
                    rewardsAvailable: getAvailablePayoutAmount(
                        userInfo.rewardsDetails
                    ),
                }
                : undefined,
        [userInfo, oidcSub]
    );

    // const loadedPixel: boolean = useMemo(
    //   () =>
    //     process.env.REACT_APP_FACEBOOK_PIXEL_ID
    //       ? typeof window !== 'undefined' && !!window.fbq
    //       : false,
    //   [window.fbq, process.env.REACT_APP_FACEBOOK_PIXEL_ID]
    // );
    const loadedApptentive = useMemo(
        () =>
            process.env.REACT_APP_APPTENTIVE_APP_ID
                ? typeof window !== 'undefined' && !!window.ApptentiveSDK
                : false,
        [window.ApptentiveSDK, process.env.REACT_APP_APPTENTIVE_APP_ID]
    );

    // const fbq = useCallback(
    //   (
    //     eventType: 'track' | 'trackCustom',
    //     eventName: eventMetricsEventsType | null,
    //     marketingDetails: UserMarketingDetails | undefined
    //   ) => {
    //     if (loadedPixel && eventName) {
    //       window.fbq(
    //         eventType,
    //         eventName,
    //         marketingDetails as facebook.Pixel.CustomParameters
    //       );
    //     }
    //   },
    //   [loadedPixel]
    // );

    const apptentive = useCallback(
        (
            action: 'log-event' | 'identify-person',
            eventName: eventMetricsEventsType | null,
            marketingDetails: UserMarketingDetails | undefined | any
        ) => {
            if (loadedApptentive && eventName) {
                if (action === 'identify-person') {
                    window.ApptentiveSDK.identifyPerson({
                        unique_token:
                            marketingDetails?.akamaiSubId || 'anonymous',
                        custom_data: marketingDetails,
                    });
                } else if (action === 'log-event') {
                    window.ApptentiveSDK.engage(eventName);
                }
            }
        },
        [loadedApptentive]
    );

    const reportUserDetailsChange = useCallback((): void => {
        apptentive('identify-person', null, userMarketingDetails);
    }, [userMarketingDetails, apptentive]);

    const customEvent = useCallback(
        (eventName: eventMetricsEventsType, parameter = {}): void => {
            // fbq('trackCustom', eventName, userMarketingDetails);
            apptentive('log-event', eventName, userMarketingDetails);
            // if (Object.keys(parameter).length > 0) {
            //   analytics.logEvent(eventName, userMarketingDetails, parameter);
            // } else {
            //   analytics.logEvent(eventName, userMarketingDetails);
            // }

            debugLog('customEvent', eventName, userMarketingDetails, {
                loadedApptentive,
            });
            debugLog('customEvent', eventName, userMarketingDetails, {});
        },
        // [userMarketingDetails, fbq, apptentive, loadedApptentive, loadedPixel]
        [userMarketingDetails, apptentive, loadedApptentive]
    );

    const analyticsCustomEvent = (eventName: any, parameter: any = {}) => {
        const gaDataWithVersion = {
            ...parameter,
            appVersion: process.env.REACT_APP_VERSION,
        };
        console.log(
            '🚀 ~ file: index.tsx:155 ~ useEventsReporter ~ ghg:',
            parameter,
            gaDataWithVersion
        );
        analytics.logEvent(eventName, gaDataWithVersion);
        if (parameter?.click_text) {
            apptentive('identify-person', eventName, {
                click_text: parameter?.click_text,
            });
        } else {
            apptentive('log-event', eventName, parameter);
        }
    };

    const analyticsScreenCustomNames = (parameter: any) => {
        analytics.setUserProperties(parameter);
    };

    // [userMarketingDetails, fbq, apptentive, loadedApptentive, loadedPixel]
    const pageView = useCallback((location: string): void => {
        // fbq('track', eventMetricsMap.PageView, undefined);
        apptentive('log-event', eventMetricsMap.PageView, undefined);
        // analytics.logEvent('page_view', { path_name: location });
        // debugLog('pageView', `${eventMetricsMap.PageView}/page_view`, undefined, {
        //   loadedPixel,
        //   loadedApptentive,
        // });
        debugLog(
            'pageView',
            `${eventMetricsMap.PageView}/page_view`,
            undefined,
            {}
        );
    }, []);

    useEffect(() => {
        reportUserDetailsChange();
    }, [userMarketingDetails, loadedApptentive]);

    // // eslint-disable-next-line @typescript-eslint/no-empty-function
    // const pageView = () => {};

    // return useMemo(() => ({ customEvent, pageView }), [customEvent, pageView]);
    // function customEvent(
    //   eventName: eventMetricsEventsType,
    //   parameter = {}
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    // ): void {}
    // return useMemo(() => ({ customEvent, pageView }), [customEvent, pageView]);
    // return { pageView, customEvent, analyticsCustomEvent };
    return useMemo(
        () => ({
            customEvent,
            pageView,
            reportUserDetailsChange,
            analyticsCustomEvent,
            analyticsScreenCustomNames,
        }),
        [
            customEvent,
            pageView,
            reportUserDetailsChange,
            analyticsCustomEvent,
            analyticsScreenCustomNames,
        ]
    );
}

export default useEventsReporter;
