import useLocalStorage, { LOCAL_STORAGE_KEYS } from 'src/hooks/useLocalStorage';

export type ListSimplifiedSchool = {
    name: string;
    id: number;
};

export type SimplifiedSchool = {
    name: string;
    id: string;
};

export function splitIds(queryString?: string): string[] {
    if (!queryString || queryString === '') {
        return [];
    }
    return queryString.split(',').filter((s) => s !== '');
}

export function inferSchools(
    cachedSchools: { name: string; id: string }[],
    selectedSchoolIds: string[]
): SimplifiedSchool[] {
    return selectedSchoolIds.reduce((agg, s) => {
        const alreadyAdded = agg.find((a) => a.id === s) !== undefined;
        const cachedResult = (cachedSchools || []).find((c) => c.id === s);
        if (cachedResult && !alreadyAdded) {
            return [...agg, cachedResult];
        }
        return agg;
    }, [] as SimplifiedSchool[]);
}

export function useCachedSchools() {
    return useLocalStorage<SimplifiedSchool[]>(
        LOCAL_STORAGE_KEYS.CACHED_SCHOOLS,
        []
    );
}
