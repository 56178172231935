import React, { useState } from 'react';
import { removeSomniAppCookies } from 'src/lib/utils/app-utils';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useGuestMode, useRouter } from 'src/hooks';
import LoadingOverlay from 'src/components/loading-overlay';
import { guestTokenLogin } from 'src/lib/api-client/guest-api';
import { COACH_SCREENS_ROUTES } from 'src/routers/routes';
import styles from './styles.module.scss';
import { Body, Button } from '../../components/UI';
import BrandIframe from '../../components/BrandIframe';
import { eventMetricsMap, useEventsReporter } from '../../hooks';

interface ShopAsGuestProps {
    referrer?: string;
}

export function ShopAsGuest({ referrer = 'Back' }: ShopAsGuestProps) {
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { enterGuestMode } = useGuestMode();
    const { push, pathname } = useRouter();

    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();

    const handleGuest = () => {
        setIsLoading(true);
        analyticsCustomEvent(eventMetricsMap.continue_as_guest, {
            click_text: 'continue as guest',
            element_location:
                referrer === 'Back' ? 'all in one place' : 'account sign-in',
        });
        analyticsScreenCustomNames({
            logged_in: 'guest',
        });
        enterGuestMode().then((success) => {
            if (success) {
                push(COACH_SCREENS_ROUTES.location);
            }
            setIsLoading(false);
        });
        // guestTokenLogin().then((res) => {
        //     if (res?.access_token) {
        //         sessionStorage.setItem('guest', 'ON');
        //         push(COACH_SCREENS_ROUTES.location)
        //     }
        //     setIsLoading(false);
        // }).catch((e) => {
        //     setIsLoading(false);
        //     console.log("error", e)
        // })
    };

    return (
        <>
            <Button
                className={styles.guest}
                variant="text"
                color="textLinkBlue"
                onClick={handleGuest}
            >
                <Body size={14} weight="normal">
                    Shop as a guest
                </Body>
            </Button>
            {isLoading ? (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            ) : null}
        </>
    );
}
export default ShopAsGuest;
