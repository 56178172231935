/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Box, Drawer, makeStyles } from '@material-ui/core';
import Header from 'src/components/header';
import COLORS from 'src/lib/colors';
import useStoreData from 'src/hooks/useStoreData';
import BrandIframe from 'src/components/BrandIframe';
import ROUTES from 'src/lib/routes';
import { useRouter } from 'src/hooks';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';

const useStyles = makeStyles({
    iframeContainer: {
        height: 'calc(100vh - 145px)',
    },
    iframe: {
        width: '100vw',
        height: '100vh',
        border: 'none',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: COLORS.whiteSmoke,
        margin: 'auto',
    },
    headerLogo: {
        margin: '0 auto',
    },
    spinnerContainer: {
        top: '0',
        bottom: '0',
        width: '100vw',
        height: '86vh',
        background: COLORS.primaryWhite,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            maxWidth: '6rem',
            maxHeight: '6rem',
        },
    },
});

function PreviousOrders() {
    const classes = useStyles();
    const { history, location, push } = useRouter();
    const { mainStore } = useStoreData();
    const [navigateTo, setNavigation] = useState('');

    const handleClick = () => {
        localStorage.removeItem('previousOrdersFallBackPath');
        if (navigateTo === 'home') {
            return push(ROUTES.home);
        }
        return history.goBack();
    };

    return (
        <Box>
            <Header backButton onClickHandler={handleClick} />
            <Box className={classes.iframeContainer}>
                <BrandIframe
                    title="prev-order-history"
                    id="prev-order-history-iframe"
                    src={`${process.env.REACT_APP_ORDER_HISTORY_URL}&storeId=${mainStore?.storeNumber}`}
                    setNavigation={setNavigation}
                />
            </Box>
        </Box>
    );
}

export default () => <PreviousOrders />;
