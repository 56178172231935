/* eslint-disable max-len */
import React from 'react';
import { Typography, Box } from '@material-ui/core';
import AppLink from 'src/components/link';
import Carousel from 'src/components/Carousel';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import useStyles from './styles';

const infoCardData = {
  staples_rewards: [
    {
      title: 'New members get Base benefits',
      body: 'Join and get Base benefits like free, fast delivery, $35 minimum.',
    },
    {
      title: 'No membership fee',
      body: 'Staples Rewards is free – no hidden fees or costs.',
    },
    {
      title: 'Spend and unlock more benefits',
      body: 'The more you spend with Staples the better benefits you get.',
    },
    {
      title: 'Enjoy your benefits for a year',
      body: [
        'Learn more about ',
        <AppLink
          style={{
            color: COLORS.brandCyan,
          }}
          target="_blank"
          href="https://www.staples.com/grs/rewards/programoverview"
        >
          Staples Rewards Tiers.
        </AppLink>,
      ],
    },
  ],
  classroom_rewards: [
    {
      title: 'For Parents & Supporters',
      body: 'We’re helping you thank teachers for everything they do just by stocking up on the supplies you need.',
    },
    {
      title: 'For Teachers, Educators & School Administrators',
      // eslint-disable-next-line max-len
      body: 'We’re saying thanks with a program designed to reward teachers like you. You’ll also earn rewards on your own purchases!',
    },
  ],
};

const RewardsCarousel = ({
  type,
}: {
  type: 'staples_rewards' | 'classroom_rewards';
}) => {
  const classes = useStyles();
  type InfoCardType = {
    title: string;
    body: React.ReactNode;
  };
  return (
    <Box id="user-type-selection-area">
      <Carousel
        content={infoCardData[type].map(({ title, body }: InfoCardType) => (
          <FullWidthCard
            className={classes.rewardCardSub}
            id="classroom-rewards-number-card"
          >
            <Typography
              variant="h5"
              id="enrollment-content"
              className={classes.newMemberBoxTitle}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              id="enrollment-content"
              className={classes.subBoxText}
            >
              {body}
            </Typography>
          </FullWidthCard>
        ))}
        dots={infoCardData[type].length}
        activeColor={COLORS.brandRed}
        styles={{
          containerStyle: {
            width: '100%',
          },
          slideContainer: {
            padding: '0 20px',
          },
        }}
      />
    </Box>
  );
};

export default RewardsCarousel;
