import { useState } from 'react';

const useDragScroll = (initElement: HTMLElement | null = null) => {
  const [elementRef, setElementRef] = useState<HTMLElement | null>(initElement);
  const [scrolled, setScrolled] = useState(false);
  const [scrollState, setScrollState] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  });
  const onMouseDown = (event: React.MouseEvent) => {
    setScrollState((oldValue) => ({
      ...oldValue,
      isScrolling: true,
      clientX: event.clientX,
    }));
  };
  const onMouseUp = () => {
    if (elementRef) {
      setScrollState((oldValue) => ({
        ...oldValue,
        isScrolling: false,
        scrollX: elementRef.scrollLeft,
      }));
      if (scrolled) {
        setTimeout(() => setScrolled(false), 500);
      }
    }
  };

  const onMouseLeave = () => {
    if (elementRef) {
      setScrolled(false);
      setScrollState((oldValue) => ({
        ...oldValue,
        isScrolling: false,
        scrollX: elementRef.scrollLeft,
      }));
    }
  };

  const onMouseMove = (event: React.MouseEvent) => {
    if (scrollState.isScrolling && elementRef) {
      const { clientX, scrollX } = scrollState;
      setScrolled(true);
      elementRef.scrollLeft = scrollX - (event.clientX - clientX);
    }
  };

  const setElement = (id: string) => {
      if(!elementRef) {
          setElementRef(document.getElementById(id));
      }
  }

  return {
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    onMouseMove,
    setElement,
    scrolled,
  };
};

export default useDragScroll;
