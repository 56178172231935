/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { userInfo } from 'os';

const useStyles = makeStyles({
  wrapper: {
    fontSize: '1.2rem',
    alignSelf: 'center',
    borderRadius: '0.9rem',
    height: '3.6rem',
    color: '#000',
    margin: '0 1.5rem 1.5rem',
    display: 'flex',
    gap: '4px',
  },
  twoBtnWrapper: {
    fontSize: '14px',
    alignSelf: 'center',
    borderRadius: '30px',
    background: '#fff',
    height: '40px',
    color: '#000',
    margin: '0 1.5rem 1.5rem',
    display: 'flex',
  },
  leftActive: {
    backgroundColor: `${COLORS.homeBlack}!important`,
    color: '#FFF',
  },
  midActive: {
    backgroundColor: `${COLORS.homeBlack} !important`,
    color: '#FFF',
  },
  rightActive: {
    backgroundColor: `${COLORS.homeBlack} !important`,
    color: '#FFF',
  },
  leftBtnWrapper: {
    backgroundColor: '#FFF',
    cursor: 'pointer',
    height: '3.6rem',
    textAlign: 'center',
    padding: '10px',
    lineHeight: '0.8rem',
    borderRadius: '0.9rem 0 0 0.9rem',
    borderRight: '4px solid transparent',
    verticalAlign: 'top',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
  },
  leftTwoBtnActive: {
    backgroundColor: `${COLORS.homeBlack}!important`,
    color: '#FFF',
  },
  rightTwoBtnActive: {
    backgroundColor: `${COLORS.homeBlack} !important`,
    color: '#FFF',
  },
  nonActiveWrapper: {
    backgroundColor: '#FFF',
    cursor: 'pointer',
    height: '40px',
    display: 'inline-block',
    textAlign: 'center',
    padding: '10px',
    lineHeight: '16px',
    borderRadius: '30px',
    flexGrow: 1,
  },
  midBtnWrapper: {
    backgroundColor: '#FFF',
    cursor: 'pointer',
    height: '3.6rem',
    textAlign: 'center',
    padding: '10px',
    lineHeight: '0.8rem',
    borderRight: '4px solid transparent',
    verticalAlign: 'top',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
  },
  rightBtnWrapper: {
    backgroundColor: '#FFF',
    cursor: 'pointer',
    height: '3.6rem',
    textAlign: 'center',
    padding: '10px',
    lineHeight: '0.8rem',
    borderRadius: '0rem 0.9rem 0.9rem 0rem',
    verticalAlign: 'top',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
  },
  bodyText: {
    lineHeight: '14.16px',
    fontWeight: 400,
    fontSize: '13px',
  },

});

type SwitchButtonProps = {
  activeTabName: string;
  toggleActiveTab: (tabName: string) => void;
  user?: any;
  isUserConfirmed?: string;
  userWalletId?: string | undefined | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SwitchButton(props: SwitchButtonProps) {
  const classes = useStyles();
  const { activeTabName, user, isUserConfirmed, userWalletId } = props;
  const handleToggle = (event: any) => {
    const tabName = event.target.id;
    props.toggleActiveTab(tabName);
  };
  const classRoomRewardsEnrolled = localStorage?.getItem('classRoomRewardsEnrolled') === 'true';
  const classRoomRewardsDonation = localStorage?.getItem('classRoomRewards') === 'true';
  const threeTabs = <>
    <div className={classes.wrapper}>
      <div
        className={`${classes.leftBtnWrapper} ${activeTabName === 'digital' && classes.leftActive
          }`}
      >
        <Typography
          id="digital"
          variant="body1"
          className={classes.bodyText}
          onClick={handleToggle}
        >
          Digital Coupons
        </Typography>
      </div>
      <div
        className={`${classes.midBtnWrapper} ${activeTabName === 'classroom' && classes.midActive}`}
      >
        <Typography
          id="classroom"
          variant="body1"
          className={classes.bodyText}
          onClick={handleToggle}
        >
          Classroom Rewards
        </Typography>
      </div>
      <div
        className={`${classes.rightBtnWrapper} ${activeTabName === 'staples' && classes.rightActive}`}
      >
        <Typography
          id="staples"
          variant="body1"
          className={classes.bodyText}
          onClick={handleToggle}
        >
          Staples Rewards
        </Typography>
      </div>

    </div>
  </>
  const switchTabs = <>
    <div className={classes.twoBtnWrapper}>
      <div
        className={`${classes.nonActiveWrapper} ${activeTabName === 'digital' && classes.leftTwoBtnActive
          }`}
      >
        <Typography
          id="digital"
          variant="body1"
          className={classes.bodyText}
          onClick={handleToggle}
        >
          Digital Coupons
        </Typography>
      </div>
      <div
        className={`${classes.nonActiveWrapper} ${activeTabName === 'staples' && classes.rightTwoBtnActive}`}
      >
        <Typography
          id="staples"
          variant="body1"
          className={classes.bodyText}
          onClick={handleToggle}
        >
          Staples Rewards
        </Typography>
      </div>

    </div>
  </>
  return (
    <>
      {
        // eslint-disable-next-line
        (classRoomRewardsEnrolled || (userWalletId && classRoomRewardsDonation))
          ? threeTabs
          : (isUserConfirmed === 'Confirmed' && user !== 'Supporter')
            ? threeTabs
            : switchTabs
      }

    </>
  );
}

export default SwitchButton;
