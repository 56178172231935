import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles((theme) => ({
    backButton: {
        padding: 0,
        background: 'none',
        border: 'none',
        position: 'absolute',
        left: '1rem',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    eReceiptsContainerMain: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: '100vh',
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem 0',
        background: '#101820',
        width: '100%',
        position: 'sticky',
        height: '90px',
        top: 0,
        zIndex: 1,
    },
    headerContent: {
        color: COLORS.primaryWhite,
        fontSize: 22,
        fontWeight: 700,
    },
    accordionContainer: {
        boxShadow: 'none',
        margin: '0  !important',
        marginBottom: '2px !important',
        border: 'none',
    },
    accordionSummary: {
        minHeight: 'unset !important',
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            justifyContent: 'space-between',
        },
        '& .Mui-expanded': {
            margin: '12px 0',
        },
    },
    accordionDetails: {
        flexDirection: 'column',
        background: '#f2f2f2',
        padding: '0 1rem',
    },
    filterHeadingContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: COLORS.primaryWhite,
    },
    filterHeading: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: '#858585',
        fontWeight: 500,
        transitionDuration: '0.5s',
    },
    filterHeadingDark: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: COLORS.homeBlack,
        fontWeight: 500,
        transitionDuration: '0.5s',
    },
    filterIcon: {
        marginLeft: '0.5rem',
    },
    radioContainer: {
        background: '#F3F3F3',
        padding: '1rem',
        flexDirection: 'column',
    },
    radioButton: {
        '&.MuiTypography-body1': {
            fontWeight: 500,
        },
    },
    purchaseReceiptContent: {
        display: 'flex',
        alignItems: 'center',
    },
    purchaseIcon: {
        height: 56,
        width: 56,
        marginRight: '1rem',
    },
    productPrice: {
        fontSize: 18,
        fontWeight: 700,
    },
    productPurchaseDetails: {
        fontSize: 12,
        fontWeight: 400,
        color: ' #858585',
        display: 'flex',
        alignItems: 'center',
    },
    productPurchaseDetailsAddress: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '15ch',
    },
    productRewardsSavingContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.5rem',
    },
    productRewardsSaving: {
        fontSize: 14,
        color: '#585858',
    },
    coinIcon: {
        height: 16,
        width: 16,
        marginRight: '0.5rem',
    },
    loadMoreButton: {
        width: '100%',
        padding: '1rem',
        textAlign: 'center',
        color: COLORS.brandCyan,
        fontSize: 14,
        fontWeight: 500,
        border: 'none',
        background: 'none',
        lineHeight: '16.64px',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem',
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500,
    },

    // PRODUCT DETAILS
    productContainer: {
        width: '100%',
        padding: '1rem',
        marginTop: '1rem',
        background: COLORS.primaryWhite,
        borderRadius: 15,
    },
    detailsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        [theme.breakpoints.down(380)]: {
            gap: 5,
        },
    },
    chipAndImageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // marginRight: '1rem',
    },
    productImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 81,
        minWidth: 81,
        maxHeight: 81,
        maxWidth: 81,
        overflow: 'hidden',
        '& > img': {
            maxWidth: '100%',
        },
    },
    productDetails: {
        flexGrow: 1,
    },
    productName: {
        fontSize: 17,
    },
    moreProductDetails: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        [theme.breakpoints.down(380)]: {
            gap: 5,
        },
        // justifyContent: 'space-between',
    },
    ctaContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // marginRight: '1rem',
    },
    linkButton: {
        padding: 0,
        background: 'none',
        border: 'none',
        color: COLORS.brandCyan,
        fontFamily: "'StaplesNorms', 'Roboto', 'Helvetica Neue', sans-serif",
        textDecoration: 'none',
        fontWeight: 500,
    },

    // Purchase summary
    purchaseSummaryHeading: {
        fontWeight: 700,
        lineHeight: '20.06px',
        marginBottom: '15px',
        fontSize: '17px',
    },
    purchaseSummarySubtotal: {
        display: 'flex',
        gap: 5,
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '20.86px',
        '&#purchaseSummarySubtotalInfo': {
            left: '50% !important',
        },
    },
    purchaseSummarySubtotalValue: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '20.06px',
    },
    purchaseSummaryRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiTypography-body1': {
            color: '#000000',
            fontSize: '14px',
            lineHeight: '1rem',
        },
    },
    purchaseSummaryRowForStoreAddress: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#000000',
        alignItems: 'flex-start',
        '& .MuiTypography-body1': {
            fontSize: '14px',
            lineHeight: '1rem',
        },
    },
    purchaseSummaryStoreAddressAlign: {
        color: '#086DD2',
        fontWeight: 500,
        width: '162px',
        lineHeight: '16px',
        textAlign: 'right',
    },
    greenText: {
        color: '#81C671',
    },
    redText: {
        color: '#CC0000',
    },
    grayText: {
        color: '#999999',
    },
    couponSavingsBanner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: COLORS.primaryWhite,
        padding: '0.5rem',
        background: '#81C671',
        whiteSpace: 'break-spaces',
    },
    transactionBarcodeContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1.5rem 0 1rem 0',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
            maxWidth: '100%',
            height: 'auto',
        },
        '& > p': {
            fontSize: 16,
            color: '#101820',
            margin: '0.1rem',
            fontWeight: 400,
        },
    },
    chip: {
        backgroundColor: '#086DD2',
    },
    productOrderNumberTitle: {
        fontSize: 18,
        color: '#101820',
        fontWeight: 700,
    },
    productOrderNumber: {
        fontSize: 18,
        color: '#101820',
        fontWeight: 400,
    },
    productOnlineContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '29px 0px',
    },
    supportContainer: {
        marginTop: '1.5rem',
    },
    protectionTitle: {
        margin: 0,
        fontSize: 16,
        color: '#585858',
    },
    protectionSubtitle: {
        display: 'block',
        margin: 0,
        marginTop: -5,
        fontSize: 10,
        color: '#585858',
    },
    supportCta: {
        marginTop: '1.5rem',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    protectionPhoneLink: {
        display: 'block',
        margin: 0,
        fontSize: 12,
        color: '#086DD2',
        textDecoration: 'none',
    },
    tAndC: {
        margin: 0,
        fontSize: 12,
        color: COLORS.brandCyan,
        textDecoration: 'none',
    },
    joinNowButton: {
        fontWeight: 'bold',
        color: COLORS.brandCyan,
        lineHeight: '22px',
    },
    signInTitle: {
        fontSize: '18px',
        fontWeight: 500,
        textAlign: 'center',
        marginTop: '15px',
    },
    signInSubTitle: {
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'center',
        width: '220px',
        margin: '20px auto',
    },
    signInCardContainer: {
        height: '255px',
        maxWidth: '327px',
        borderRadius: '15px',
        padding: '24px 26px',
    },
    staplesConectImageAlign: {
        margin: 'auto',
        display: 'block',
        marginTop: '0.5rem',
    },
    ereceiptIconAlign: {
        height: '21px',
    },
    eReceiptsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '40px 24px 10px',
    },
    signInCardTextContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
    },
    eReceiptsMainText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500,
        width: '278px',
        flexDirection: 'column',
        margin: '40px auto',
        lineHeight: '22px',
        gap: '8px',
    },
    eReceiptsSignUpBtn: {
        borderRadius: '1.5rem',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '25rem',
        height: '40px',
        textTransform: 'none',
        fontSize: '1.063rem',
        fontWeight: 500,
        background: COLORS.brandRed,
        color: COLORS.primaryWhite,
        marginBottom: '0.5rem',
        '&:hover': {
            backgroundColor: COLORS.darkRed,
        },
        '&:active': {
            backgroundColor: COLORS.darkRed,
        },
    },
    eReceiptsSubMainContainer: {
        overflow: 'auto',
        scrollBehavior: 'smooth',
    },
    coinIconRewards: {
        width: 13.65,
        marginRight: '3.65px',
    },
    staplesRewardsEarnedAligned: {
        display: 'flex',
        alignItems: 'center',
        color: '#101820',
        fontWeight: 500,
    },
    MuiTooltipPopper: {
        width: 13.65,
    },
    root: {
        left: '12px !important',
    },
    tooltipContent: {
        padding: '0 !important',
        lineHeight: '15px !important',
    },
    tooltip: {
        padding: '0px 12px',
        width: '280px',
        fontWeight: 400,
        fontSize: '14px !important',
        color: '#FFFFFF !important',
        letterSpacing: '-0.37px',
        margin: '24px 0 10px',
    },
    tooltipTitleStyle: {
        fontSize: '14px !important',
        color: '#FFFFFF !important',
        paddingTop: '0.4375rem',
        letterSpacing: 0,
        lineHeight: '14px',
    },
    tooltipRoot: {
        padding: '0 !important',
        fontWeight: 400,
        fontSize: 14,
        color: '#FFFFFF',
    },
    arrow: {
        left: '60px !important',
    },
    tooltipButtonStyle: {
        margin: '4px 0',
    },
    checkBoxAlign: {
        padding: '0',
        width: '24px',
        marginRight: '12px',
    },
    radio: {
        '&$checked': {
            color: '#101820',
        },
    },
    filterTitle: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '19px',
        letterSpacing: '0px',
        color: '#000000',
        marginBottom: '10px',
    },
    fieldsAlign: {
        borderBottom: '1px solid #DBDBDB',
        marginBottom: '24px',
        paddingBottom: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export default useStyles;
