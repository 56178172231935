import React, { useState, useMemo } from 'react';
import { UserData } from 'react-oidc';
import ROUTES from 'src/lib/routes';
import {
    Box,
    makeStyles,
    Typography,
    IconButton,
    Fade,
    Button,
    Input,
    Checkbox,
} from '@material-ui/core';
import {
    LOCAL_STORAGE_KEYS,
    saveToLocalStorage,
} from 'src/hooks/useLocalStorage';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import useUserInfo from 'src/hooks/useUserInfo';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import useRouter from 'src/hooks/useRouter';
import ScrollContainer from 'src/components/screen-scroll';
import BlackCheckbox from '../black-checkbox';

const useStyles = makeStyles({
    mainContainer: {
        background: COLORS.whiteSmoke,
    },
    continueButton: {
        height: '2.6rem',
        borderRadius: '1.2rem',
        width: '100%',
        marginTop: '25px',
        background: COLORS.homeBlack,
        color: COLORS.primaryWhite,
        textTransform: 'none',
        fontSize: '17px',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    nameAndRole: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: COLORS.primaryWhite,
    },
    editIcon: {},
    inputWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputWrapperInfo: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '12px',
    },
    name: {
        fontSize: '1.3rem',
        marginTop: '0.6rem',
    },
    textAlign: {
        marginBottom: '2rem',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '-0.40px',
        textAlign: 'left',
    },
    subTextAlign: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '-0.40px',
        textAlign: 'left',
    },
    profileContainer: {
        marginTop: '1rem',
        padding: '0.5rem',
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    topRowTitle: {
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        fontSize: '1.1rem',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '2rem 0',
        alignItems: 'center',
    },
    bottomButton: {
        color: COLORS.brandCyan,
        fontSize: '1.1rem',
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        marginTop: '1rem',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    appVersionText: {
        color: COLORS.fordGray,
        fontSize: '1rem',
        letterSpacing: '-0.6pt',
        marginTop: '1.2rem',
        display: 'flex',
        flexDirection: 'row',
    },
    appVersionTitle: {
        fontWeight: 600,
        paddingRight: '0.2rem',
    },
    associatedSchoolContainer: {
        marginTop: '1.5rem',
    },
    schoolDivider: {
        backgroundColor: COLORS.gray80,
        width: '100%',
    },
    backIcon: {
        fontSize: '1.3rem',
        color: COLORS.primaryWhite,
    },
    backButton: {
        left: '1rem',
        position: 'relative',
    },
    closeButton: {
        right: '1rem',
        position: 'relative',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    checkBoxAlign: {
        padding: '0',
        width: '24px',
    },
});

export function Consent() {
    const [isChecked, setChecked] = useState(true);
    sessionStorage.setItem(
        LOCAL_STORAGE_KEYS.AUTH_REDIRECT,
        window.location.pathname
    );

    const classes = useStyles();
    const { userInfo, isFetchingUserInfo } = useUserInfo();

    const router = useRouter();

    const handleOnBackClick = () => {
        router.push(ROUTES.userManageProfile);
    };
    const handleCheckBox = (e: any) => {
        setChecked(e.target.checked);
    };
    return (
        <>
            <Box className={classes.mainContainer}>
                <NavTop
                    bgImageSrc={require('../../assets/bgs/bgRedUserProfile.svg')}
                    marginBottom="-15.5rem"
                    minHeight="18rem"
                />
                <div className={classes.headerContainer}>
                    <Fade in>
                        <IconButton
                            onClick={handleOnBackClick}
                            size="small"
                            className={classes.backButton}
                        >
                            <ArrowBackIosRounded className={classes.backIcon} />
                        </IconButton>
                    </Fade>
                    <Fade in>
                        <IconButton
                            onClick={handleOnBackClick}
                            size="small"
                            className={classes.closeButton}
                        >
                            <img
                                src={require('../../assets/icons/whiteCloseBtn.svg')}
                                alt="whiteCloseBtn"
                            />
                        </IconButton>
                    </Fade>
                </div>

                <div className={classes.nameAndRole}>
                    <img
                        src={require('../../assets/icons/icon-marketing.svg')}
                        alt="icon-marketing"
                    />
                    <Typography
                        className={classes.name}
                        id="user-profile-name-text"
                    >
                        Marketing Consent
                    </Typography>
                </div>
                <FullWidthCard
                    cardContainerClassName={classes.profileContainer}
                    hasManualShadow
                >
                    <form>
                        <Typography
                            className={classes.textAlign}
                            id="user-profile-user-type-text"
                        >
                            Update your preference related to being included in
                            important marketing messages.
                        </Typography>
                        <div className={classes.inputWrapperInfo}>
                            <div>
                                <Checkbox
                                    onChange={handleCheckBox}
                                    name="marketing-consent-checked"
                                    checked={isChecked}
                                    className={classes.checkBoxAlign}
                                    style={{
                                        color: '#101820',
                                    }}
                                />
                            </div>
                            <Typography
                                className={classes.subTextAlign}
                                id="user-profile-user-type-text"
                            >
                                Keep me connected to the Staples Connect
                                community through exclusive content, offers and
                                events that are distinct from other Staples
                                properties and services.
                            </Typography>
                        </div>
                        {!isChecked ? (
                            <div className={classes.inputWrapper}>
                                <Button
                                    id="zipcode-continue-button"
                                    variant="contained"
                                    className={classes.continueButton}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        ) : null}
                    </form>
                </FullWidthCard>
            </Box>
        </>
    );
}

export default () => (
    <UserData.Consumer>
        {() => (
            <ScrollContainer>
                <Consent />
            </ScrollContainer>
        )}
    </UserData.Consumer>
);
