import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ReviewStars } from 'src/components/product-star-rating';

export interface ProductReviewItem {
  rating: number;
  title?: string;
  text?: string;
  firstName?: string;
  lastName?: string;
  timestamp?: string;
}

const useStyles = makeStyles({
  reviewContainer: {
    margin: '2rem 0',
  },
  something: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  date: {
    color: '#b9b9b9',
  },
  buyerName: {
    marginTop: '0.5rem',
  },
  verifiedBuyer: {
    color: '#2d9246',
  },
});

const ProductReviewItem = (props: ProductReviewItem) => {
  const classes = useStyles();
  const { rating, text, firstName, lastName, title, timestamp } = props;
  let name;
  if (!firstName && !lastName) name = 'Anonymous';
  else if (!lastName) name = firstName;
  else if (!firstName) name = lastName;
  else name = `${firstName} ${lastName}`;
  return (
    <Box className={classes.reviewContainer}>
      <div className={classes.something}>
        <span>
          <ReviewStars ratingValue={rating} />
        </span>
        <Typography className={classes.date} variant="caption">
          {timestamp &&
            `Posted ${new Intl.DateTimeFormat('en-US', {
              day: 'numeric',
              year: 'numeric',
              month: 'short',
            }).format(new Date(timestamp))}`}
        </Typography>
      </div>
      <Typography variant="h6">{title || 'No title'}</Typography>
      {text && <Typography variant="body2">{text}</Typography>}
      <Typography className={classes.buyerName} variant="subtitle2">
        {name}
      </Typography>
      <Typography className={classes.verifiedBuyer} variant="caption">
        Verified Buyer
      </Typography>
    </Box>
  );
};

export default ProductReviewItem;
