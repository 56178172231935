import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { makeStyles, ButtonBase, Typography, Button } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import useRouter from 'src/hooks/useRouter';
import {
    CommonStepProps,
    ClaimSchoolsControllerState,
} from 'src/screens/claim-schools-flow/types';
import { GUTTER_WIDTH } from 'src/theme';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingButton from 'src/components/loading-button';
import {
    useCachedSchools,
    inferSchools,
    splitIds,
} from 'src/lib/utils/school-utils';
import {
    claimSchools,
    deleteClaimedSchool,
} from 'src/lib/api-client/rewards-enrollment';
import useUserInfo from 'src/hooks/useUserInfo';
import useError from 'src/screens/claim-schools-flow/use-error';

const useStyles = makeStyles({
    // TODO: enrollFormCard can be deduplicated
    enrollFormCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
        padding: '1rem 0',
        minHeight: '3 0rem',
        display: 'flex',
        flexDirection: 'column',
    },
    schoolSearchDescription: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '1rem',
        padding: '1rem 2.75rem 0rem',
        letterSpacing: '-0.39pt',
        lineHeight: '1.3rem',
    },
    selectedSchoolsList: {
        margin: '0 2rem',
        flexGrow: 1,
        minHeight: '10rem',
    },
    buttonWrapper: {
        margin: '4rem 2rem .5rem',
    },
    nextButton: {
        textTransform: 'none',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    addAnotherSchool: {
        marginTop: '1rem',
        textTransform: 'none',
        color: COLORS.brandCyan,
        width: '100%',
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
});

const useSchoolRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'flex-start',
        padding: '1rem',
        minHeight: '2.5rem',
    },
    name: {
        fontSize: '1.1rem',
        display: 'block',
        textTransform: 'none',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    iconWrapper: {},
    icon: {
        width: '1.7rem',
        height: '1.7rem',
    },
});

type SimplifiedSchool = {
    name: string;
    id: string;
};

type SelectedSchoolRowProps = {
    index: number;
    school: SimplifiedSchool;
    onDelete: (selectedSchool: SimplifiedSchool) => void;
};
function SelectedSchoolRow({
    school,
    onDelete,
    index,
}: SelectedSchoolRowProps) {
    const classes = useSchoolRowStyles();
    function handlePress() {
        onDelete(school);
    }
    return (
        <div
            id={`school-selection-confirm-result-${index}`}
            className={classes.row}
        >
            <Typography
                id={`school-selection-confirm-result-${index}-name-text`}
                className={classes.name}
                component="div"
            >
                {/* eslint-disable-next-line prefer-template */}
                {school.name}
            </Typography>
            <ButtonBase
                id={`school-selection-confirm-result-${index}-remove-button`}
                className={classes.iconWrapper}
                onClick={handlePress}
            >
                <img
                    className={classes.icon}
                    alt="delete"
                    src={require('../../assets/icons/iconDelete.svg')}
                />
            </ButtonBase>
        </div>
    );
}

type ConfirmSchoolProps = CommonStepProps;

function ConfirmSchool({
    userType,
    updateControllerValues,
    onError,
}: ConfirmSchoolProps) {
    const classes = useStyles();
    const { hadError, setHadError, showError } = useError(onError);
    const [pendingSchoolIds, setPendingSchoolIds] = useState<string[]>([]);
    const [removedSchool, setRemovedSchool] = useState('');

    const {
        data: claimSchoolsResp,
        error,
        isFetching,
    } = useQuery(
        ['claimSchools', pendingSchoolIds.join(',')],
        () => claimSchools(pendingSchoolIds),
        {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            enabled: pendingSchoolIds.length > 0 && !hadError,
        }
    );
    const { isFetching: isFetchingDeleteSchool } = useQuery(
        'deleteSchool',
        () => deleteClaimedSchool(removedSchool),
        {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            enabled: removedSchool !== '',
        }
    );

    useEffect(() => {
        if (isFetchingDeleteSchool) {
            setRemovedSchool('');
        }
    }, [isFetchingDeleteSchool]);

    const isAdmin = userType === 'Administrator';
    const { query } = useRouter();
    const { selectedSchoolIds, claimSchoolStep } =
        query as ClaimSchoolsControllerState;
    const [cachedSchools] = useCachedSchools();
    const { refetchUserInfo } = useUserInfo();
    const [selectedSchools, setSelectedSchools] = useState(
        inferSchools(cachedSchools, splitIds(selectedSchoolIds))
    );
    useEffect(() => {
        setSelectedSchools(
            inferSchools(cachedSchools, splitIds(selectedSchoolIds))
        );
    }, [cachedSchools, selectedSchoolIds, claimSchoolStep]);

    function onSchoolRemoved(school: SimplifiedSchool) {
        if (selectedSchools.length === 1) {
            updateControllerValues({
                isSchoolSaved: 'false',
            });
            showError('confirmSchool', 'min-schools');
        }
        setSelectedSchools(
            (oldSchools) => oldSchools.filter((s) => s.id !== school.id)
            // eslint-disable-next-line function-paren-newline
        );
        setRemovedSchool(school.id);
    }
    useEffect(() => {
        updateControllerValues({
            selectedSchoolIds: selectedSchools.map((s) => s.id).join(','),
        });
        setHadError(false);
    }, [selectedSchools]);

    const nextScreen = () => {
        setHadError(false);
        setPendingSchoolIds(selectedSchools.map((s) => s.id));
    };

    const selectAnotherSchool = () => {
        if (selectedSchools.length <= 4) {
            updateControllerValues({
                selectedSchoolIds: selectedSchools.map((s) => s.id).join(','),
                claimSchoolStep: 'selectSchool',
            });
        } else {
            showError('confirmSchool', 'max-schools');
        }
    };

    useEffect(() => {
        let inferredError = false;
        if (!isFetching && claimSchoolsResp) {
            updateControllerValues({
                isSchoolSaved: 'true',
            });
            setPendingSchoolIds([]);
            if (claimSchoolsResp.isSuccess) {
                // refetchUserInfo user data
                refetchUserInfo();
                window.open('/user-profile', '_self');
            } else {
                inferredError = true;
            }
        }
        if (!isFetching && error) {
            inferredError = true;
        }
        if (inferredError) {
            let schoolName = 'One of your schools';
            if (selectedSchools.length > 0) {
                schoolName = selectedSchools[0].name;
            }
            showError('confirmSchool', 'school-claimed', schoolName);
        }
    }, [claimSchoolsResp, error, isFetching]);

    return (
        <FullWidthCard
            hasManualShadow
            noPadding
            className={classes.enrollFormCard}
            id="classroom-rewards-number-card"
        >
            <Typography className={classes.schoolSearchDescription}>
                Selected school{selectedSchools.length > 1 ? 's' : ''}:
            </Typography>
            <div className={classes.selectedSchoolsList}>
                {selectedSchools.map((s: SimplifiedSchool, index) => (
                    <SelectedSchoolRow
                        school={s}
                        key={s.id}
                        index={index}
                        onDelete={onSchoolRemoved}
                    />
                ))}
            </div>
            <div className={classes.buttonWrapper}>
                <LoadingButton
                    fullWidth
                    id="school-selection-confirm-result-next-button"
                    variant="contained"
                    classes={{
                        root: classes.nextButton,
                    }}
                    type="submit"
                    disabled={selectedSchools.length === 0}
                    loading={isFetching}
                    onClick={nextScreen}
                >
                    Continue
                </LoadingButton>
                {!isAdmin && (
                    <Button
                        id="school-selection-confirm-result-add-more-button"
                        className={classes.addAnotherSchool}
                        onClick={selectAnotherSchool}
                        disabled={isFetching}
                    >
                        Add another school
                    </Button>
                )}
            </div>
        </FullWidthCard>
    );
}

export default ConfirmSchool;
