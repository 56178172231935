/* eslint-disable import/prefer-default-export */

export function isArrayEqual(a: any[], b: any[]) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export const sumArray = (array: number[]) => array.reduce((a, b) => a + b, 0);

export const groupBy = <T>(groupSize: number, data: T[]) => {
  if (!data?.length) {
    return [];
  }
  const result = [];
  for (let i = 0; i < data.length; i += groupSize)
    result.push(data.slice(i, i + groupSize));
  return result;
};
