/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React from 'react';

function IconBiDirectionalArrow({ height = '20', width = '20' }: { height?: string, width?: string }) {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" fill="#CC0000" />
            <g clipPath="url(#clip0_271_319)">
                <path d="M19.5082 33.4523L19.5082 16.6102C19.5082 16.2997 19.7602 16.0477 20.0707 16.0477C20.3812 16.0477 20.6332 16.2997 20.6332 16.6102L20.6332 33.4523C20.6332 33.7624 20.3812 34.0148 20.0707 34.0148C19.7602 34.0148 19.5082 33.7628 19.5082 33.4523Z" fill="white" />
                <path d="M16 29.9433C16 29.7993 16.0544 29.6548 16.1651 29.545C16.3838 29.3254 16.7407 29.3263 16.9603 29.5459L20.0702 32.6568L23.1811 29.545C23.4007 29.3263 23.7575 29.3263 23.9771 29.545C24.1967 29.7646 24.1967 30.1215 23.9771 30.3411L20.468 33.8502C20.3627 33.9555 20.2192 34.0144 20.0698 34.0144C19.9204 34.0144 19.7773 33.9555 19.6715 33.8493L16.1633 30.3402C16.0535 30.2313 15.9991 30.0873 15.9991 29.9428L16 29.9433Z" fill="white" />
                <path d="M29.3663 33.3898L29.3663 16.5481C29.3663 16.2376 29.6183 15.9856 29.9288 15.9856C30.2393 15.9856 30.4913 16.2376 30.4913 16.5481L30.4913 33.3898C30.4913 33.6998 30.2393 33.9523 29.9288 33.9523C29.6183 33.9523 29.3663 33.7003 29.3663 33.3898Z" fill="white" />
                <path d="M25.8581 20.0567C25.8581 19.9127 25.913 19.7687 26.0228 19.6589L29.5315 16.1503C29.7511 15.9307 30.107 15.9307 30.3266 16.1503L33.8353 19.6589C34.0549 19.8785 34.0549 20.2345 33.8353 20.4541C33.6157 20.6737 33.2597 20.6737 33.0401 20.4541L29.9288 17.3432L26.818 20.4541C26.5984 20.6737 26.2424 20.6737 26.0228 20.4541C25.913 20.3443 25.8581 20.2007 25.8581 20.0563L25.8581 20.0567Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_271_319">
                    <rect width="18" height="18" fill="white" transform="translate(34 16) rotate(90)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconBiDirectionalArrow;
