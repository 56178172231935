/* eslint-disable no-underscore-dangle */
import { useFirebaseApp } from 'reactfire';
import { useQuery } from 'react-query';
import { REMOTE_CONFIG_KEYS } from 'src/lib/firebase/config';
import { parseEnvAsInt, parseTypeAsInt } from 'src/lib/utils/dotenv-utils';
import Log from 'src/lib/logging';

const DEFAULT_MINIMUM_FETCH_INTERVAL = 43200000; // twelve hours

const useFirebaseRemoteConfigValue = (key: string) => {
  if (!(REMOTE_CONFIG_KEYS as any)[key]) {
    Log.firebase(`WARN: unknown key: ${key}. Please update REMOTE_CONFIG_KEYS`);
  }
  const firebaseApp = useFirebaseApp();
  const remoteConfig = firebaseApp.remoteConfig();
  remoteConfig.setLogLevel(
    parseTypeAsInt(
      process.env.REACT_APP_FIREBASE_LOG_LEVEL,
      ['debug', 'error', 'silent'],
      'silent'
    )
  );
  remoteConfig.defaultConfig = {
    [REMOTE_CONFIG_KEYS.show_ios_install_prompt]: false,
  };
  const minimumFetchInterval = parseEnvAsInt(
    process.env.REACT_APP_FIREBASE_MINIMUM_FETCH_INTERVAL,
    DEFAULT_MINIMUM_FETCH_INTERVAL
  );
  remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchInterval;

  const queryResp = useQuery(
    ['fetchRemoteConfig', key],
    async () => {
      await remoteConfig.fetchAndActivate();
      const newValue = remoteConfig.getValue(key);
      Log.firebase(
        `Remote config key "${key}" fetched and activated: "${JSON.stringify(
          newValue
        )}"`
      );
      return newValue;
    },
    { cacheTime: minimumFetchInterval, staleTime: minimumFetchInterval }
  );

  return { remoteConfig, remoteConfigValue: queryResp?.data, queryResp };
};

export default useFirebaseRemoteConfigValue;
