import React from 'react';

function IconNoInternet(): JSX.Element {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M50 100C22.5 100 0 77.5 0 50C0 22.5 22.5 0 50 0C77.5 0 100 22.5 100 50C100 77.5 77.5 100 50 100Z" 
                fill="#CC0000"
            />
            <path 
                d="M76.25 39.2857C75.7143 39.2857 75.3572 39.1072 75 38.75C68.3929 32.1429 59.4643 28.3928 50 28.3928C40.5357 28.3928 31.6072 32.1429 25 38.75C24.2857 39.4643 23.2143 39.4643 22.5 38.75C21.7857 38.0357 21.7857 36.9643 22.5 36.25C29.8214 28.9285 39.6429 24.8214 50 24.8214C60.3572 24.8214 70.1786 28.9285 77.5001 36.25C78.2144 36.9643 78.2144 38.0357 77.5001 38.75C77.1429 39.1072 76.7858 39.2857 76.25 39.2857Z" 
                fill="white"
            />
            <path 
                d="M68.0357 49.8214C67.5 49.8214 67.1429 49.6429 66.7858 49.2857C62.3215 44.8214 56.4286 42.3215 50 42.3215C43.5715 42.3215 37.6786 44.8214 33.2143 49.2857C32.5 50 31.4286 50 30.7143 49.2857C30 48.5714 30 47.5 30.7143 46.7857C35.8929 41.6072 42.6786 38.75 50 38.75C57.3215 38.75 64.1071 41.6072 69.2857 46.7857C70 47.5 70 48.5714 69.2857 49.2857C68.9286 49.6429 68.5714 49.8214 68.0357 49.8214Z" 
                fill="white"
            />
            <path 
                d="M59.8214 60.1786C59.2857 60.1786 58.9286 60 58.5714 59.6429C56.25 57.3214 53.2143 56.0714 50 56.0714C46.7857 56.0714 43.75 57.3214 41.4286 59.6429C40.7143 60.3571 39.6429 60.3571 38.9286 59.6429C38.2143 58.9286 38.2143 57.8572 38.9286 57.1429C41.9643 54.1072 45.8929 52.5 50 52.5C54.2857 52.5 58.2143 54.1072 61.0715 57.1429C61.7858 57.8572 61.7858 58.9286 61.0715 59.6429C60.7143 60 60.3572 60.1786 59.8214 60.1786Z" 
                fill="white"
            />
            <path 
                d="M50 72.5C50.6904 72.5 51.25 71.9404 51.25 71.25C51.25 70.5596 50.6904 70 50 70C49.3097 70 48.75 70.5596 48.75 71.25C48.75 71.9404 49.3097 72.5 50 72.5Z" 
                fill="white"
            />
            <path 
                d="M50 74.1072C48.3929 74.1072 46.9643 72.8571 46.9643 71.0714C46.9643 69.2857 48.2143 68.0357 50 68.0357C51.7857 68.0357 53.0357 69.2857 53.0357 71.0714C53.0357 72.8571 51.6071 74.1072 50 74.1072ZM50 70.5357C49.6429 70.5357 49.4643 70.8928 49.4643 71.0714C49.4643 71.25 49.8214 71.6072 50 71.6072C50.3571 71.6072 50.5357 71.25 50.5357 71.0714C50.5357 70.8928 50.3571 70.5357 50 70.5357Z" 
                fill="white"
            />
            <mask id="mask0_12395_2377" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <path 
                    d="M50 100C22.5 100 0 77.5 0 50C0 22.5 22.5 0 50 0C77.5 0 100 22.5 100 50C100 77.5 77.5 100 50 100Z" 
                    fill="#CC0000"
                />
            </mask>
            <g mask="url(#mask0_12395_2377)">
                <path 
                    d="M14.8356 86.79C14.2849 86.79 13.9178 86.6064 13.5507 86.2393C12.8164 85.5051 12.8164 84.4037 13.5507 83.6695L83.6696 13.5507C84.4038 12.8164 85.5051 12.8164 86.2393 13.5507C86.9735 14.2849 86.9735 15.3862 86.2393 16.1205L16.1204 86.2393C15.7533 86.6064 15.3863 86.79 14.8356 86.79Z" 
                    fill="white"
                />
            </g>
        </svg>
    );
}

export default IconNoInternet;
