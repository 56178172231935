/* eslint-disable comma-dangle */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import axios, { AxiosInstance } from 'axios';

import Log from 'src/lib/logging';

const axiosInstanceSearchProduct: AxiosInstance = axios.create({
    headers: {
        // always add the api key (needed for API Management)
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

export async function getListOfSearchItems(item: any, sorts = 0) {
    const result = await axiosInstanceSearchProduct.get(
        // eslint-disable-next-line max-len
        `${process.env.REACT_APP_DOTCOM_URL}/searchux/common/api/v1/searchProxy?isAjax=true&term=${item}&sby=${sorts}`
    );
    if (result.data) {
        return result.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching home carousels ${process.env.REACT_APP_DOTCOM_URL}/searchux/common/api/v1/searchProxy`
    );
    Log.axios(result);
    return null;
}

