/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';


function IconCollapse() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.449 23.4736C19.0808 23.4736 22.9158 19.6304 22.9158 15.0068C22.9158 10.375 19.0725 6.54004 14.4407 6.54004C9.81714 6.54004 5.98218 10.375 5.98218 15.0068C5.98218 19.6304 9.82544 23.4736 14.449 23.4736ZM14.449 22.0625C10.531 22.0625 7.40161 18.9248 7.40161 15.0068C7.40161 11.0889 10.5227 7.95117 14.4407 7.95117C18.3586 7.95117 21.4963 11.0889 21.5046 15.0068C21.5129 18.9248 18.3669 22.0625 14.449 22.0625ZM18.5247 14.2266L15.2708 17.688C14.7478 18.2358 14.1418 18.2441 13.6272 17.688L10.365 14.2266C10.1243 13.9692 10.116 13.5874 10.3401 13.355C10.5891 13.0894 10.9958 13.0811 11.2366 13.3384L14.449 16.7417L17.6614 13.3384C17.8938 13.0811 18.2922 13.0977 18.5496 13.355C18.782 13.5791 18.7737 13.9692 18.5247 14.2266Z" fill="#101820" />
        </svg>
    );
}

export default IconCollapse;
