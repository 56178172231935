import React from 'react';
import { Collapse, Typography } from '@material-ui/core';
import { useToastMessageContext } from 'src/lib/contexts/toast-message-context';
import COLORS from 'src/lib/colors';

export default function GlobalToast() {
  const { toastIsOpen, activeMessage, dismissToast } = useToastMessageContext();
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        zIndex: 3500,
        pointerEvents: 'none',
      }}
    >
      <Collapse in={toastIsOpen}>
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            width: '100%',
            height: '7rem',
            padding: '2rem',
          }}
          role="button"
          tabIndex={0}
          onClick={dismissToast}
          onKeyDown={dismissToast}
        >
          <Typography
            style={{
              color: COLORS.primaryWhite,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {activeMessage?.title}
          </Typography>
          {activeMessage?.details && (
            <Typography
              style={{
                color: COLORS.primaryWhite,
                textAlign: 'center',
              }}
            >
              {activeMessage?.details}
            </Typography>
          )}
        </div>
      </Collapse>
    </div>
  );
}
