/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconSolidCheckmark({
    height = 20,
    width = 20,
    bgFillColor = 'fillPurple',
    fillColor = 'fillWhite',
}: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="10"
                cy="10"
                r="10"
                className={color_classes[bgFillColor]}
                fill="#5B0069"
            />
            <path
                d="M13.5242 6.5196C13.8068 6.19354 14.3002 6.1583 14.6262 6.44088C14.9227 6.69778 14.9787 7.1289 14.7742 7.45014L14.705 7.54293L9.38676 13.6755C9.03003 14.0322 8.46871 14.0596 8.0805 13.7578L7.98728 13.6755L5.22882 10.917C4.92373 10.6119 4.92373 10.1173 5.22882 9.81217C5.50618 9.53481 5.94021 9.50959 6.24605 9.73652L6.33368 9.81217L8.65625 12.1344L13.5242 6.5196Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconSolidCheckmark;
