/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import {
    Box, ButtonBase, makeStyles, Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import moment from 'moment';
import ShippingServicesAccordianDetails from './shipping-services-accordian-details';
import { stringCapitalize } from '../../../lib/utils/string-utils';

const useStyles = makeStyles((theme) => ({
    accordionContainer: {
        margin: '0px 0px 5px !important',
        boxShadow: 'none',
        background: 'none',
        // height: '115px',
        '& .MuiAccordionSummary-root': {
            padding: '24px',
            background: '#FFFFFF',
        },
    },
    accordionDetails: {
        padding: "0px",
    },
    shippingName: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '-0.47px',
        color: '#101820',
    },
    shippingAddress: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0',
        color: '#1E1E1E',
    },
    shippingDate: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        color: '#858585',
        marginTop: '5px',
    },
    shippingData: {
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
        },
    },
    deliveredStatus: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#000000',
    },
    shippedStatus: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#000000',
    },
    deliveredStatusName: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#000000',
    },
}))


const ShippingServicesAccordion = ({ key, data, updatedData, activeTab }: any) => {
    const classes = useStyles();
    const [expandedKey, setExpadedKey] = useState(-1);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [shipmentStatus, setShipmentStatus] = useState<any>('Varied');

    useEffect(() => {
        const allPackagesStatusCodes =
            data?.shimpmentstatus?.shipmenttrackingstatus.map((status: any) =>
                status.package[0].activity.status.statusCode);
        const allPackagesHaveSameStatusCode =
            allPackagesStatusCodes.every((statusCode: any) => statusCode === allPackagesStatusCodes[0]);

        if (allPackagesHaveSameStatusCode) {
            switch (allPackagesStatusCodes[0]) {
                case '003':
                    setShipmentStatus('Shipped');
                    break;
                case '021':
                    setShipmentStatus('Out for Delivery');
                    break;
                default:
                    setShipmentStatus('In Transit');
            }
        }
    }, [data]);
    const handleExpandCollapse = (index: number) => {
        if (expandedKey === index) {
            setExpadedKey(-1)
        } else {
            setExpadedKey(index)
        }
    }

    return (
        <Accordion
            key={key}
            ref={scrollRef}
            onChange={(_event, expanded) => {
                handleExpandCollapse(key)
            }}
            expanded={expandedKey === key}
            className={classes.accordionContainer}
            aria-label={`shipping_data_${key}`}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.shippingData}>
                {
                    data?.shipmenttype === "APPSHIPMENT" ? <>
                        <Typography className={classes.shippingName}>
                            {data?.recipientdetails?.fullname}
                        </Typography>
                        <Typography className={classes.shippingAddress}>
                            {data?.recipientdetails?.street1}
                        </Typography>
                    </> : <>
                        {data?.shimpmentstatus?.shipmenttrackingstatus?.map((packageItem: any, index: any) => (
                            <>
                                <Typography className={classes.shippingName}>
                                    {stringCapitalize(data?.recipientdetails?.city)} ,&nbsp;
                                    {stringCapitalize(data?.recipientdetails?.state)}
                                </Typography>
                                <Typography className={classes.shippingAddress}>
                                    Scanned package
                                </Typography>
                            </>
                        ))}
                    </>

                }

                {activeTab === 'create' ?
                    <Typography className={classes.shippingDate}>
                        {data?.shipmentcreateddate ?
                            `as of ${moment(data?.shipmentcreateddate).format("MMM DD, YYYY")}` : null}
                    </Typography> : null}
                {activeTab === 'shipped' ?
                    <Typography className={classes.shippingDate}>
                        <span className={classes.shippedStatus}>Status</span>&nbsp;&nbsp;
                        <span className={classes.deliveredStatusName}>{shipmentStatus}</span>&nbsp;&nbsp;
                        {data?.date}
                    </Typography> : null}
                {activeTab === 'delivered' ?
                    <Typography className={classes.shippingDate}>
                        <span className={classes.deliveredStatus}>Delivered</span>
                        &nbsp;&nbsp; {data?.shimpmentstatus ?
                            `on ${moment(data?.shimpmentstatus?.shipmenttrackingstatus[0]?.package[0]?.activity?.date)
                                .format("MMM DD, YYYY")}` : null}
                    </Typography> : null}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <ShippingServicesAccordianDetails
                    shippingData={data}
                    activeTab={activeTab}
                    updatedData={updatedData}
                />
            </AccordionDetails>
        </Accordion>
    );
}

export default ShippingServicesAccordion;
