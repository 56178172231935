/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { useQuery } from 'react-query';
import { SwipeableDrawer, Typography } from '@material-ui/core';
import useUserInfo from 'src/hooks/useUserInfo';
import useCardCanvas from 'src/hooks/useCardCanvas';
import { getCustomerPriceTypes } from 'src/lib/api-client/wallet-activities';
import { Body } from 'src/components/UI';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { Player } from '@lottiefiles/react-lottie-player';
import { IconEasyRewardsHorizontal } from 'src/components/Icons';
import styles from './styles.module.scss';

interface UserRewardsDigitalCardDrawerProps {
    open: boolean;
    toggleOpen: (value: boolean) => void;
    rewardstiercode: string;
    useDrawer?: boolean; // New prop to control drawer usage
}

function UserRewardsDigitalCardDrawer({
    open,
    toggleOpen,
    rewardstiercode,
    useDrawer = true, // Default to true if not provided
}: UserRewardsDigitalCardDrawerProps) {
    const { userInfo } = useUserInfo();
    const { canvasRef } = useCardCanvas(userInfo?.rewardsNumber);
    const { data: customerPriceTypeData } = useQuery(
        ['CustomerPriceTypes', userInfo?.rewardsNumber],
        () => getCustomerPriceTypes(userInfo?.rewardsNumber),
        {
            ...NO_REFETCH,
            enabled: !!userInfo?.rewardsNumber,
        }
    );

    const cardContent = (
        <div className={styles.contentWrapper}>
            {useDrawer ? (
                <div
                    className={styles.indicator}
                    role="button"
                    aria-label="Close drawer"
                    tabIndex={0} // Make focusable
                />
            ) : null}

            <Body className={styles.title}>
                <span
                    style={{ textAlign: 'center', margin: "13px 0px -9px 0px" }}
                    aria-label="Easy Rewards digital card"
                    tabIndex={0} // Make focusable
                >
                    <IconEasyRewardsHorizontal
                        width={110}
                        height={25}
                        fillColor="fillRed"
                    />
                </span>
                <b
                    className={styles.rewardsNumber}
                    aria-label={`Your Easy Rewards number is ${userInfo?.rewardsNumber}`}
                    tabIndex={0} // Make focusable
                >
                    {userInfo?.rewardsNumber}
                </b>
            </Body>

            <canvas
                ref={canvasRef}
                className={styles.renderedCardNumber}
                aria-roledescription="Your personal Easy Rewards barcode is here to scan at checkout when you are in a Staples store"
                tabIndex={0} // Make focusable
            />

            <div className={styles.container}>
                <Body
                    className={styles.memberName}
                    size={26}
                    weight="bold"
                    margin="30px 0px 15px 0"
                    aria-label={`${userInfo?.firstName} ${userInfo?.lastName}`}
                    tabIndex={0} // Make focusable
                >
                    {userInfo?.firstName} {userInfo?.lastName}
                </Body>

                {customerPriceTypeData?.data?.customerPriceType === 'Chamber' ? (
                    <div aria-label="You are a CHAMBER MEMBER" tabIndex={0} className={styles.memberTypeContainer}>
                        <Body
                            size={16}
                            weight="bold"
                            margin="0 0 38px 0"
                            aria-hidden="true" // Hide inner content from screen readers
                        >
                            CHAMBER MEMBER
                        </Body>
                    </div>
                ) : (
                    <div
                        aria-label={userInfo?.rewardsMemberType === 'Associate' || rewardstiercode === 'ARW' ?
                            "You are a VALUED ASSOCIATE" :
                            "You are a MEMBER"}
                        tabIndex={0}
                        className={styles.memberTypeContainer}
                    >
                        <Body
                            size={16}
                            weight="bold"
                            margin="0 0 38px 0"
                            aria-hidden="true" // Hide inner content from screen readers
                        >
                            {userInfo?.rewardsMemberType === 'Associate' || rewardstiercode === 'ARW' ?
                                "VALUED ASSOCIATE" :
                                "MEMBER"}
                        </Body>
                    </div>
                )}


                {useDrawer ? (
                    <Body className={styles.subtitle} size={13} margin="12px 0 25px 0">
                        <p tabIndex={0} aria-label="How do I use my card?" style={{ margin: '0px' }}>
                            How do I use my card?
                        </p>
                        <a
                            tabIndex={0} // Links are inherently focusable
                            aria-label="Learn more link"
                            href="https://www.staples.com/grs/rewards/programoverview"
                            className={styles.learnMore}
                        >
                            Learn more
                        </a>
                    </Body>
                ) : null}

                <Player
                    autoplay
                    loop
                    style={{ marginRight: '-4px' }}
                    className={styles.cardCornerPill}
                    src={require('../../../assets/lotties/rewards-enrollment/sm_digital_card_corner_pills.json')}
                    aria-label="Rewards animation"
                // tabIndex={0} // Make focusable if necessary
                />
            </div>

            {userInfo?.rewardsMemberType === 'Associate' && (
                <Body className={styles.benefitWrapper}>
                    <a
                        href="https://associateconnection.staples.com/psp/psext/EXTRANET2/ASSOCIATE/c/STP_EXTR_LINK_MENU.STP_RWD_ASSOC_RWRD.GBL?Page=STP_RWD_API_EMPLOY&Action=U"
                        className={styles.benefitText}
                        aria-label="View my associate benefits link"
                        tabIndex={0} // Make focusable
                    >
                        View my associate benefits
                    </a>
                </Body>
            )}
        </div>
    );


    return useDrawer ? (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={() => toggleOpen(false)}
            onOpen={() => toggleOpen(true)}
        >
            {cardContent}
        </SwipeableDrawer>
    ) : (
        <div>{cardContent}</div>
    );
}

export default UserRewardsDigitalCardDrawer;
