import React, { useState } from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import useNotifications from 'src/hooks/useNotifications';
import CustomSwitch from '../CustomSwitch';
import useStyles from './styles';

function NotificationSwitch() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {
        areNotificationsAllowed,
        askNotificationPermission,
        hasNotificationSupport,
    } = useNotifications();
    const [checked, setChecked] = React.useState(areNotificationsAllowed());
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && !areNotificationsAllowed()) {
            const permission = await askNotificationPermission();
            setOpen(permission === 'denied');
            setChecked(permission === 'granted');
        }
    };
    return hasNotificationSupport && !areNotificationsAllowed() ? (
        <>
            <div className={classes.notificationSwitchContainer}>
                <CustomSwitch checked={checked} onChange={handleChange} />
                <span style={{ marginLeft: '0.5rem' }}>
                    Notify me of new digital coupons
                </span>
            </div>
            <Dialog open={open}>
                <Typography style={{ padding: '1rem' }}>
                    You previously opted-out of notifications. If you wish to
                    opt-in to notifications, you can turn them on in your
                    browser settings.
                </Typography>
                <Button type="button" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </Dialog>
        </>
    ) : null;
}

export default NotificationSwitch;

// No image for couponType
