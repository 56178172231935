/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { useState, forwardRef, useEffect } from 'react';
import { Container, useScrollTrigger } from '@material-ui/core';
// import { NAV_BAR_HEIGHT } from 'src/components/nav-bottom';
import ScreenScrollContext from 'src/lib/contexts/screen-scroll-context';
// import { useIsMobileWidth } from 'src/hooks/useWidth';
import useRouter from 'src/hooks/useRouter';
import { isEmpty } from 'src/lib/utils/obj-utils';
import ScreenWidth from 'src/components/screen-width';

/** Shared scroll component logic for screens that need to scroll independently */
const ScreenScroll = forwardRef(
    (
        // eslint-disable-next-line react/require-default-props
        props: {
            ignoreNav?: boolean;
            children?: React.ReactElement<any, any>;
            isHomePage?: boolean;
        },
        ref: any
    ) => {
        // const isMobileWidth = useIsMobileWidth();
        const [scrollTarget, setScrollTarget] = useState<Node | undefined>(
            undefined
        );
        const { query } = useRouter();

        useEffect(() => {
            if (ref?.current) {
                setScrollTarget(ref.current as Node);
            }
        }, [ref]);

        const showOnScroll = useScrollTrigger({
            threshold: 150,
            target: scrollTarget,
            disableHysteresis: true,
        });

        const show = showOnScroll && isEmpty(query);
        return (
            <ScreenScrollContext.Provider value={{ show }}>
                <Container
                    style={{
                        padding: 0,
                        overflowY: props.isHomePage ? 'hidden' : 'scroll',
                        maxWidth: '100%',
                        height: 'auto',
                        flexGrow: 1,
                    }}
                    ref={ref}
                    data-testid="screen-container"
                >
                    <ScreenWidth isHomePage={props.isHomePage}>
                        {props.children}
                    </ScreenWidth>
                </Container>
            </ScreenScrollContext.Provider>
        );
    }
);

export default ScreenScroll;
