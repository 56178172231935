/* eslint-disable import/prefer-default-export */
import axiosInstance from 'src/lib/api-client/custom-axios';
import Log from 'src/lib/logging';
/* eslint-disable */
const jsonpAdapter = require('axios-jsonp');


export async function suggestions(query: string): Promise<any> {
  const result = await axiosInstance.get(`${process.env.REACT_APP_SUGGESTIONS_URL}/?q=${query}&account_id=${process.env.REACT_APP_QA_ACCOUNT_ID}&request_type=suggest`, {
    adapter: jsonpAdapter,
  });
  if (result.data) {
    return result.data;
  }
  Log.axios(
    // eslint-disable-next-line max-len
    `received an unexpected response when fetching home carousels 
      ${process.env.REACT_APP_SUGGESTIONS_URL}/?q=${query}&account_id=5073`
  );
  Log.axios(result);
  return null;
}





