/* eslint-disable max-len */
import React from 'react';
import IconInterface from './IconInterface';

function IconUpArrow({ height = 16, width = 16 }: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C8.27614 0 8.5 0.223858 8.5 0.5V15.5C8.5 15.7761 8.27614 16 8 16C7.72386 16 7.5 15.7761 7.5 15.5V0.5C7.5 0.223858 7.72386 0 8 0Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 0.146447C7.84171 -0.0488155 8.15829 -0.0488155 8.35355 0.146447L15.3536 7.14645C15.5488 7.34171 15.5488 7.65829 15.3536 7.85355C15.1583 8.04882 14.8417 8.04882 14.6464 7.85355L8 1.20711L1.35355 7.85355C1.15829 8.04882 0.841709 8.04882 0.646447 7.85355C0.451184 7.65829 0.451184 7.34171 0.646447 7.14645L7.64645 0.146447Z"
                fill="white"
            />
        </svg>
    );
}

export default IconUpArrow;
