/* eslint-disable import/prefer-default-export */
import { v4 as uuidv4 } from 'uuid';

export type Template = { [key: string]: any };
const parseTemplate = (template: Template) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(template)) {
    if (value === '[UUID]') {
      // eslint-disable-next-line no-param-reassign
      template[key] = uuidv4();
    }
  }
  return template;
};

/**
 * When loading lists of things through react query, we can provide placeholders.
 * This function generates these placeholders for us.
 * This is different from placeholder APIs, which are just apis we're uncertain about.
 */
export function generatePlaceholderData(template: Template, amount = 1) {
  if (amount > 1) {
    const placeholders = [];
    for (let i = 0; i < amount; i += 1) {
      placeholders.push(parseTemplate({ ...template }));
    }
    return placeholders;
  }
  return template;
}
