/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { UserData } from 'react-oidc';
import { IAuthenticatorContext } from 'react-oidc/lib/makeAuth';
import ROUTES from 'src/lib/routes';
import {
    Box,
    ButtonBase,
    makeStyles,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    Drawer,
    TextField,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { InUserDataConsumer } from 'src/lib/types';
import { getUserPasscodeInfo } from 'src/lib/api-client/user-info';
import AppLink from 'src/components/link';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import ProfileImage from 'src/components/profile-image';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingOverlay from 'src/components/loading-overlay';
import { RewardsAccountUserType } from 'src/services/types';
import { fullName } from 'src/lib/utils/user-utils';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import ScrollContainer from 'src/components/screen-scroll';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import NativeIframeWithHeaderFooter from 'src/components/NativeIframeWithHeaderFooter';
import { useQuery } from 'react-query';
import { getUserLists, isListEnabled } from 'src/lib/api-client/lists';
import BrandIframe from 'src/components/BrandIframe';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Cookies from 'js-cookie';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { rewardsPostLoyaltyAccountURL } from 'src/services/urls';
import {
    eventMetricsMap,
    LOCAL_STORAGE_KEYS,
    useEventsReporter,
    useGuestMode,
    useLocalStorage,
    useLocalUserInfo,
    useRouter,
    useStoreData,
    useUserInfo,
    useUserState,
} from 'src/hooks';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import {
    numberWithCommas,
    precache,
    removeGustsessionCookies,
    removeSmsession,
    removeSomniAppCookies,
    removeStplSessionIdUniqueId,
} from 'src/lib/utils/app-utils';
import Header from 'src/components/header';
import { Body, Heading, Pill } from 'src/components/UI';
import {
    getCustomerPriceTypes,
    getStaplesRewardsData,
} from 'src/lib/api-client/wallet-activities';
import { getLoyaltyAccountDetails } from 'src/services/easyRewardsServices';
import layout_classes from 'src/stylesheets/layout_classes.module.scss';
import {
    DigitalWallet,
    DownChevron,
    IconEasyRewardsHorizontal,
    IconStoreMode,
} from 'src/components/Icons';
import { mergeClasses } from 'src/stylesheets/utils';
import useIntroScreen from 'src/hooks/useIntroScreen ';
import { CACHE_ONLY, NO_REFETCH } from '../lib/utils/react-query-utils';
import UserRewardsDigitalCardDrawer from './EasyRewards/UserRewardsDigitalCardDrawer';
import GuestLoginDrawer from './Login/GuestLoginDrawer/GuestLoginDrawer';

const useStyles = makeStyles({
    midCard: {
        width: '87%',
    },
    mainContainer: {
        background: COLORS.whiteSmoke,
    },

    activityContainer: {
        padding: 20,
        borderRadius: 15,
        marginTop: 16,
        margin: '16px',
        backgroundColor: COLORS.primaryWhite,
    },

    nameAndRole: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: COLORS.primaryWhite,
    },
    row: {
        marginBottom: '16px',
        height: '43px',
    },
    titleAndInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    content: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#101820',
        position: 'relative',
        '&#optimove-visitor-id-text': {
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            lineHeight: '1.1rem',
        },
    },

    border: {
        padding: '16px 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        height: '65px',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },

    profileImage: {
        height: '6.5rem',
        width: '6.5rem',
    },
    name: {
        fontSize: '30px',
        fontWeight: 500,
        lineHeight: '34px',
        letterSpacing: '-0.6px',
        marginTop: '0.6rem',
        marginBottom: '0.6rem',
    },
    role: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19px',
        marginTop: '2px',
    },
    profileContainer: {
        margin: '20px 16px',
        padding: '20px',
        boxShadow: 'none',
        borderRadius: '15px',
        textAlign: 'left',
        backgroundColor: COLORS.primaryWhite,
        display: 'inherit',
        width: 'calc(100% - 32px)',
    },
    profileListContainer: {
        paddingTop: '0rem',
        paddingLeft: '1.5rem',
        listStyleType: 'disc',
    },
    profileListItem: {
        padding: '0',
        display: 'list-item',
    },
    settingsContainer: {
        margin: '20px 16px 30px',
        padding: '0',
        boxShadow: 'none',
        '& .MuiCardContent-root': {
            padding: '0px',
        },
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    topRowTitle: {
        fontWeight: 700,
        letterSpacing: '-0.2px',
        fontSize: '18px',
        lineHeight: '22px',
        marginBottom: '30px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0rem 1rem 2rem',
        alignItems: 'start',
    },
    bottomButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        fontWeight: 400,
        letterSpacing: '-0.408px',
        marginTop: '1rem',
        textAlign: 'left',
        '&:hover': {
            textDecoration: 'none',
        },
        '&:first-child': {
            marginTop: '0.5rem',
        },
        userSelect: 'none',
    },
    appVersionText: {
        color: COLORS.fordGray,
        fontSize: '1rem',
        letterSpacing: '-0.6pt',
        marginTop: '1.2rem',
        display: 'flex',
        flexDirection: 'row',
        userSelect: 'none',
    },
    appVersionTitle: {
        fontWeight: 600,
        paddingRight: '0.2rem',
        userSelect: 'none',
    },
    associatedSchoolContainer: {
        marginTop: '1.5rem',
    },
    schoolDivider: {
        backgroundColor: COLORS.gray80,
        width: '100%',
    },
    settingsDivider: {
        backgroundColor: 'rgba(0,0,0,0.1)',
        width: '100%',
    },
    backIcon: {
        fontSize: '1.3rem',
        color: COLORS.primaryWhite,
    },
    backButton: {
        left: '1rem',
        position: 'relative',
    },
    cardItem: {
        color: COLORS.textBlack,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    logoHeading: {
        display: 'block',
        paddingLeft: 0,
        paddingTop: 0,
    },
    logoHeadingText: {
        fontSize: '1.1rem',
        fontWeight: 700,
        marginTop: '-3px',
        userSelect: 'none',
    },
    listContainer: {
        padding: 0,
        marginBottom: -16,
    },
    listItems: {
        padding: '1rem 0',
        userSelect: 'none',
    },
    listLastItem: {
        paddingBottom: 0,
    },
    itemText: {
        fontSize: '1rem',
        fontWeight: 700,
    },
    availableText: {
        color: '#4CCC65',
    },
    coin: {
        verticalAlign: 'top',
        paddingRight: '5px',
    },
    warningLoyaltyIcon: {
        position: 'absolute',
        marginLeft: '0.5rem',
        top: '1.5rem',
    },
    warningLoyaltyText: {
        marginLeft: '4.2rem',
        paddingTop: '0.5rem',
    },
    profileNavIcon: {
        height: '1.2rem',
        width: '1.2rem',
        marginTop: '-30px',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: COLORS.whiteSmoke,
        margin: 'auto',
    },
    otpForm: {
        '& .MuiTextField-root': {
            width: '100%',
        },
        '& .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiInput-underline:before': {
            display: 'none',
        },
        '& .Mui-error > input': {
            margin: '15px 0 0 0',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#000000 !important',
            fontWeight: '700',
        },
    },
    ctaBtn: {
        height: '50px',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        margin: '20px 0 0 0',
        width: '100%',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
    },
});

const useCardItemStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        gap: '20px',
        alignItems: 'center',
    },
    container: {
        width: '100%',
    },
    logo: {
        // marginTop: '4px',
    },
    titleAndChevronIcon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        userSelect: 'none',
    },
    title: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        color: '#101820',
    },
    icon: {
        width: '1.2rem',
        height: '1.2rem',
        marginTop: '0.3rem',
    },
    content: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#101820',
        position: 'relative',
        userSelect: 'none',
    },
    pillWrapper: {
        backgroundColor: '#0058D8',
        color: '#fff',
        fontSize: '10px',
        fontWeight: 700,
        letterSpacing: '0.5px',
        width: 'fit-content',
        marginBottom: '0.5rem',
        userSelect: 'none',
    },
});

type UserProfileProps = {
    context: IAuthenticatorContext;
};
type CardItemProps = {
    baseId: string;
    logo?: string | JSX.Element;
    title: string;
    iconChevron?: string;
    children: any;
    pill?: boolean;
};

function CardItem({
    baseId,
    logo,
    title,
    iconChevron,
    children,
    pill,
}: CardItemProps) {
    const classes = useCardItemStyles();

    return (
        <div className={classes.row} id={`${baseId}-row`}>
            {logo && typeof logo === 'string' ? (
                <img className={classes.logo} src={logo} alt="title-icon" />
            ) : (
                logo
            )}
            <div className={classes.container}>
                {pill ? (
                    <Pill className={classes.pillWrapper}>
                        STAPLES REWARDS BALANCES EXPIRE APR 30
                    </Pill>
                ) : (
                    ''
                )}
                <div className={classes.titleAndChevronIcon}>
                    <Heading size={18} weight="bold" type="h6">
                        {title}
                    </Heading>
                    {iconChevron && (
                        <img
                            className={classes.icon}
                            src={iconChevron}
                            alt="chevron"
                        />
                    )}
                </div>
                <div className={classes.content}>{children}</div>
            </div>
        </div>
    );
}

function getUserTypeLabel(userType?: RewardsAccountUserType) {
    switch (userType) {
        case 'Educator':
            return 'Teacher or Educator';
        case 'Administrator':
        case 'Supporter':
            return userType;
        case undefined:
        default:
            return 'Registered User';
    }
}

export function GuestPage({ context }: UserProfileProps) {
    sessionStorage.setItem(
        LOCAL_STORAGE_KEYS.AUTH_REDIRECT,
        window.location.pathname
    );
    const [sawInstallData] = useLocalStorage<string | null>(
        LOCAL_STORAGE_KEYS.INSTALL_PROMPT,
        null
    );
    const classes = useStyles();
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { navBottomActiveTab, setNavBottomActiveTab, setGuestModal } =
        useUserUpdateContext();
    const { push, location, prevPath, history } = useRouter();
    const { userInfo } = useUserInfo();
    const { isGuestModeOn, exitGuestMode } = useGuestMode();
    const { enterStoreMode } = useStoreData();
    const { showIntro, setShowIntro } = useIntroScreen();

    /* const { data } = useQuery(['getUserLists'], () => getUserLists(), {
        refetchOnMount: true,
    }); */

    const { data: listEnabled } = useQuery(
        ['isListEnabled'],
        () => isListEnabled('StaplesConnectRetailList'),
        {
            refetchOnMount: true,
        }
    );

    /* const { data: otpEnabled } = useQuery(
        ['otpEnabled'],
        () => isListEnabled('OTPCode'),
        {
            refetchOnMount: true,
        }
    ); */

    /* const { data: customerPriceTypeData } = useQuery(
        ['CustomerPriceTypes', userInfo?.rewardsNumber],
        () => getCustomerPriceTypes(userInfo?.rewardsNumber),
        {
            ...NO_REFETCH,
            enabled: !!userInfo?.rewardsNumber,
        }
    ); */
    const [rewardstiercode, setRewardstiercode] = useState('');
    getLoyaltyAccountDetails({
        config: {
            enabled: !!userInfo?.rewardsNumber,
            onSuccess: (res) => {
                setRewardstiercode(res?.accounts[0]?.rewardstiercode);
            },
        },
        rewardsNumber: userInfo?.rewardsNumber,
    });

    const hostName = window?.location?.host;
    let urlPath = `https://www.staples.com/stores`;
    let staplesHelpCenterUrlPath = `https://www.staples.com/hc`;

    if (hostName === 'qe1.staples.com') {
        urlPath = 'https://qe1.staples.com/stores';
        staplesHelpCenterUrlPath = 'https://qe1.staples.com/hc';
    }

    if (
        hostName === 'qe-app.staplesconnect.com' ||
        hostName === 'qe101.staples.com' ||
        hostName === 'de-app.staplesconnect.com'
    ) {
        urlPath = 'https://qe101.staples.com/stores';
        staplesHelpCenterUrlPath = 'https://qe101.staples.com/hc';
    }
    const [loyaltyData, setLoyaltyData] = useState<any>({});

    const { prepareForLogout } = useScreenOrderContext();
    const { fromPath } = useProductBrowseContext();
    const {
        linkInNative,
        setLinkInNative,
        profileLinkInNative,
        setProfileLinkInNative,
    } = useTabsUIStateContext();
    const { userInfo: localUserInfo, patchLocalUserInfo } = useLocalUserInfo();

    const { data: staplesRewardsDetail } = useQuery(
        ['details', userInfo?.rewardsNumber],
        () => getStaplesRewardsData(userInfo?.rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );

    const getCouponsRewardsCategory = useMemo(() => {
        const couponsRewardsData =
            staplesRewardsDetail?.data?.couponDataList?.findIndex(
                (couponsData: any) => couponsData.category === 'Reward'
            ) !== -1;
        return couponsRewardsData;
    }, [staplesRewardsDetail]);

    console.log(
        '🚀 ~ file: user-profile.tsx:439 ~ UserProfile ~ staplesRewardsDetail:',
        staplesRewardsDetail,
        getCouponsRewardsCategory
    );

    useEffect(() => {
        if (userInfo) {
            const loyaltyPayload = {
                accountIdentifier: userInfo?.rewardsNumber,
                identifierType: 'REWARDS',
            };
            axiosInstance
                .post(`${rewardsPostLoyaltyAccountURL}`, loyaltyPayload)
                .then((loyaltyRes: any) => {
                    const accounts = loyaltyRes?.data?.accounts;
                    setLoyaltyData(
                        accounts && accounts[0].walletinfo?.balances
                    );
                })
                .catch((error) => {
                    setLoyaltyData({});
                });
        }
    }, [userInfo]);

    useEffect(() => {
        if (
            navBottomActiveTab === ROUTES.userProfile &&
            fromPath === ROUTES.userProfile
        ) {
            if (linkInNative.show || profileLinkInNative.show) {
                push(`${ROUTES.userProfile}?link`);
            }
        }
    }, [navBottomActiveTab]);

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    const handleLogout = () => {
        // sessionStorage.removeItem('guest');
        exitGuestMode();
        const { isUserMigrated, userName } = SessionManager;
        const pwaUpdateApp = sessionStorage.getItem('pwaUpdateApp');
        let userMigratedData = {
            isUserMigrated: false,
            userName: SessionManager.userName,
        };
        if (isUserMigrated) {
            userMigratedData = {
                isUserMigrated,
                userName,
            };
        }
        analyticsCustomEvent(eventMetricsMap.account_info, {
            click_text: 'sign out',
            element_location: 'user profile',
        });
        optimoveEvents(eventMetricsMap.scm_account_info, {
            click_text: 'sign out',
            element_location: 'user profile',
        });
        const localStorageKeys = [];
        for (
            let keyIndex = 0, len = localStorage.length;
            keyIndex < len;
            keyIndex += 1
        ) {
            localStorageKeys.push(localStorage.key(keyIndex));
        }
        localStorageKeys.forEach((key) => {
            if (key && !key.includes('persistent')) {
                localStorage.removeItem(key);
            }
        });
        sessionStorage.clear();
        if (!showIntro.isintroShownOn) {
            setShowIntro({ isintroShownOn: false });
        }
        if (sawInstallData) {
            localStorage.setItem(
                'app.staplesconnect.INSTALL_PROMPT',
                sawInstallData
            );
        }
        if (pwaUpdateApp) {
            sessionStorage.setItem('pwaUpdateApp', pwaUpdateApp);
        }

        Cookies.set('DirectCustomerNumber', '');
        SessionManager.session?.killSession();
        removeStplSessionIdUniqueId();
        SessionManager.isLoggedIn = false;
        removeSmsession();
        removeGustsessionCookies();
        window.location.replace('/somniapp/login');
        if (localUserInfo?.hasFinishedOnboarding) {
            patchLocalUserInfo({ hasFinishedOnboarding: true, zipCode: '' });
        }
        if (isUserMigrated) {
            SessionManager.setIsUserMigrated(userMigratedData);
        }
        removeSomniAppCookies();
        prepareForLogout();
        precache(false);
    };

    const userTypeLabel = useMemo(
        () => getUserTypeLabel(userInfo?.userType),
        [userInfo?.userType]
    );

    const handleOnBackClick = () => {
        history.goBack();
        setProfileLinkInNative({ ...linkInNative, show: false });
    };

    const handleOnClickManage = () => {
        let URL = 'https://www.staples.com/gus/sdc/profileinfoV2';

        if (hostName === 'qe1.staples.com') {
            URL = 'https://qe1.staples.com/gus/sdc/profileinfoV2';
        } else if (hostName === 'qe101.staples.com') {
            URL = 'https://qe101.staples.com/gus/sdc/profileinfoV2';
        }
        setProfileLinkInNative({
            src: URL,
            show: true,
        });

        analyticsCustomEvent(eventMetricsMap.account_info, {
            click_text: 'view all',
            element_location: 'user profile',
        });
        push(`${ROUTES.userProfile}?link`);
    };

    const handleWindowBackEvent = () => {
        setLinkInNative({ src: '', show: false });
        setProfileLinkInNative({ src: '', show: false });
    };

    useEffect(() => {
        window.addEventListener('popstate', handleWindowBackEvent);
        return () =>
            window.removeEventListener('popstate', handleWindowBackEvent);
    }, []);

    const [openDrawer, setOpenDrawer] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [retrivePasscode, setRetrivePasscode] = useState(false);
    const [
        isUserRewardsDigitalCardDrawerOpen,
        toggleIsUserRewardsDigitalCardDrawerOpen,
    ] = useState(false);

    const handleOpenDrawer = async () => {
        setRetrivePasscode(true);
        setGuestModal({
            open: true,
            closeBehavior: 'onboarding',
            previousRoute: prevPath,
        });
        try {
            const userInfoData = await getUserPasscodeInfo();
            setInputValue(userInfoData?.passcode);
            setRetrivePasscode(false);
        } catch (errorData) {
            setRetrivePasscode(false);
        }
        analyticsCustomEvent(eventMetricsMap.account, {
            click_text: 'sign in',
        });
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleAllSet = () => {
        if (inputValue.trim() !== '' && inputValue.trim().length === 6) {
            setError(false);
            handleCloseDrawer();
        } else {
            setError(true);
        }
    };
    const [otherLocationName, setOtherLocationName] = useState('');
    const getOtherBalanceName = () => {
        let res = '';
        const isClassroomRewardsApplicable =
            userInfo?.walletId &&
            userInfo?.rewardsDetails?.payoutAvailableAmount > 0;
        const isStaplesRewardsApplicable =
            getCouponsRewardsCategory && userInfo?.rewardsNumber;

        if (isClassroomRewardsApplicable) {
            res = 'Classroom Rewards';
        }
        if (isStaplesRewardsApplicable) {
            if (isClassroomRewardsApplicable) {
                // Add ' & ' only if Classroom Rewards is also present
                res += ' & ';
            }
            res += 'Staples Rewards';
        }

        setOtherLocationName(res);
    };

    useEffect(() => {
        if (userInfo) {
            getOtherBalanceName();
        }
    }, [userInfo]);

    useEffect(() => {
        if ((location as any)?.state?.showStaplesAccountPage) {
            handleOnClickManage();
        }
    }, []);

    if (profileLinkInNative.show) {
        return (
            <>
                <NavTop
                    withTopScroll
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('../assets/bgs/header-bg.svg')}
                    noBorderRadius
                    minHeight="0"
                >
                    <Box className={classes.cartHeader}>
                        <ButtonBase onClick={() => handleOnBackClick()}>
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                        <Typography className={classes.title}>
                            Account
                        </Typography>
                    </Box>
                </NavTop>
                <BrandIframe
                    src={profileLinkInNative.src}
                    title="user-profile-page"
                    id="profile-screen"
                />
            </>
        );
    }
    if (linkInNative.show) {
        return (
            <>
                <Header
                    backButton
                    onClickHandler={() => {
                        history.goBack();
                        setLinkInNative({ src: '', show: false });
                    }}
                />
                <BrandIframe
                    src={linkInNative.src}
                    title="user-profile-page"
                    id="profile-screen"
                />
            </>
        );
    }

    return (
        <>
            {retrivePasscode && <LoadingOverlay open={retrivePasscode} />}

            <Box
                className={mergeClasses(
                    classes.mainContainer,
                    layout_classes.pbNav
                )}
            >
                <NavTop
                    bgImageSrc={require('../assets/bgs/bgRedUserProfile.svg')}
                    marginBottom="-10.5rem"
                    minHeight="210px"
                />
                <div className={classes.nameAndRole}>
                    <Heading
                        type="h3"
                        size={30}
                        weight="normal"
                        className={classes.name}
                    >
                        Hello, Guest
                    </Heading>
                </div>

                {/* {userInfo?.rewardsNumber && ( */}
                <div className={classes.activityContainer}>
                    <Body size={18}>Sign in for:</Body>
                    <Body size={16} margin="10px 0" weight="light">
                        faster checkouts, managing your orders, creating
                        shopping lists, and more.
                    </Body>
                    <ButtonBase
                        type="button"
                        className={classes.ctaBtn}
                        onClick={handleOpenDrawer}
                    >
                        Sign in
                    </ButtonBase>
                </div>
                {/* )} */}

                {/* {listEnabled?.isenabled && ( */}
                <FullWidthCard
                    cardContainerClassName={classes.settingsContainer}
                    hasManualShadow
                    onClick={() =>
                        isGuestModeOn
                            ? push(ROUTES.previousOrders)
                            : push(ROUTES.lists)
                    }
                >
                    <CardItem
                        baseId="user-profile-lists"
                        logo={require('../assets/icons/e-reciep-black.svg')}
                        title="Purchase History"
                        iconChevron={require('../assets/icons/iconChevron.svg')}
                        children={'Online and In-store'}
                    />
                </FullWidthCard>
                {/* )} */}
                <FullWidthCard
                    cardContainerClassName={classes.settingsContainer}
                    hasManualShadow
                    onClick={() => {
                        analyticsCustomEvent(eventMetricsMap.account, {
                            event_name: 'account',
                            click_text: 'view store mode',
                        });
                        sessionStorage.removeItem('storeModeDismissed');
                        enterStoreMode();
                        push(ROUTES.storeMode);
                    }}
                >
                    <CardItem
                        baseId="View-Store-Mode"
                        logo={<IconStoreMode />}
                        title="View store mode"
                    >
                        {null}
                    </CardItem>
                </FullWidthCard>

                <Box className={classes.buttonContainer}>
                    <AppLink
                        id="user-profile-help-center-button"
                        className={classes.bottomButton}
                        onClick={() => {
                            const src = `${staplesHelpCenterUrlPath}`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'help & FAQ',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Help & FAQ',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'help & FAQ',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Help & FAQ',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            setLinkInNative({ src, show: true });
                            push(`${ROUTES.userProfile}?link`);
                        }}
                    >
                        Visit help center
                    </AppLink>
                    <AppLink
                        id="user-profile-terms-and-conditions-button"
                        className={classes.bottomButton}
                        onClick={() => {
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/hc?id=52e40651-0852-4ad7-a532-45017c287d50`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            push(`${ROUTES.userProfile}?link`);
                            setLinkInNative({ src, show: true });
                        }}
                    >
                        Terms & conditions
                    </AppLink>
                    <AppLink
                        id="user-profile-easy-rewards-terms-and-conditions-button"
                        className={classes.bottomButton}
                        onClick={() => {
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            push(`${ROUTES.userProfile}?link`);
                            setLinkInNative({ src, show: true });
                        }}
                    >
                        Easy Rewards terms & conditions
                    </AppLink>

                    <AppLink
                        id="user-profile-privacy-policy-button"
                        className={classes.bottomButton}
                        onClick={() => {
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/hc?id=dbb94c10-973c-478b-a078-00e58f66ba32`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'privacy notice',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Privacy notice',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'privacy notice',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Privacy notice',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            push(`${ROUTES.userProfile}?link`);
                            setLinkInNative({ src, show: true });
                        }}
                    >
                        Privacy notice
                    </AppLink>
                    <AppLink
                        id="user-profile-dont-sell-my-personal-info-button"
                        className={classes.bottomButton}
                        onClick={() => {
                            const src =
                                'https://submit-irm.trustarc.com/services/validation/2394f394-3df4-4051-bfa1-50c2cfa45f02';
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: `don't sell my peronsal info`,
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: "Don't sell my personal info",
                                link_domain: 'submit-irm.trustarc.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: `don't sell my peronsal info`,
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: "Don't sell my personal info",
                                link_domain: 'submit-irm.trustarc.com',
                                link_url: src,
                            });
                            push(`${ROUTES.userProfile}?link`);
                            setLinkInNative({ src, show: true });
                        }}
                    >
                        Do not sell or share my personal info
                    </AppLink>
                    <AppLink
                        id="user-profile-california-policy-button"
                        className={classes.bottomButton}
                        onClick={() => {
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'california notice',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'California notice',
                                link_domain: 'staplesconnect.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'california notice',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'California notice',
                                link_domain: 'staplesconnect.com',
                                link_url: src,
                            });
                            push(`${ROUTES.userProfile}?link`);
                            setLinkInNative({ src, show: true });
                        }}
                    >
                        California notice
                    </AppLink>
                    {isGuestModeOn ? (
                        <ButtonBase
                            id="user-profile-sign-out-button"
                            className={clsx(classes.bottomButton)}
                            onClick={handleOpenDrawer}
                        >
                            Sign in
                        </ButtonBase>
                    ) : (
                        <ButtonBase
                            id="user-profile-sign-out-button"
                            className={clsx(classes.bottomButton)}
                            onClick={handleLogout}
                        >
                            Sign out
                        </ButtonBase>
                    )}

                    <Typography className={classes.appVersionText}>
                        <Typography className={classes.appVersionTitle}>
                            Version:{' '}
                        </Typography>
                        <span id="app_version_id_text">
                            {process.env.REACT_APP_VERSION}
                        </span>
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default (props: InUserDataConsumer<UserProfileProps>) => (
    <UserData.Consumer>
        {(context) => (
            <ScrollContainer>
                <GuestPage {...props} context={context} />
            </ScrollContainer>
        )}
    </UserData.Consumer>
);
