import React, { useEffect, useState } from 'react';
import bwipjs from 'bwip-js';
import { encodeBarcode } from 'src/lib/utils/receiptBarcode';
import useStyles from './styles';

interface TransactionBarcodeProps {
  store: number;
  register: number;
  transaction: number;
  date: string;
}

const TransactionBarcode = ({
  store,
  register,
  transaction,
  date,
}: TransactionBarcodeProps) => {
  const classes = useStyles();
  const [barcode] = useState<string>(
    encodeBarcode(store, register, transaction, date)
  );
  useEffect(() => {
    bwipjs.toCanvas(`transactionBarcode-${barcode}`, {
      bcid: 'code93', // Barcode type
      text: barcode, // Text to encode
      scale: 1, // 3x scaling factor
      height: 13, // Bar height, in millimeters
      includecheck: true,
      textxalign: 'center', // Always good to set this
    });
  }, [store, register, transaction, date]);
  return (
    <div className={classes.transactionBarcodeContainer}>
      <canvas id={`transactionBarcode-${barcode}`} style={{ width: '100%' }} />
      <p>{encodeBarcode(store, register, transaction, date)}</p>
    </div>
  );
};

export default TransactionBarcode;
