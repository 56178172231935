import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getStoreInventory } from 'src/lib/api-client/staples-stores';
import { Store } from 'src/services/types';
import Log from 'src/lib/logging';
import { mapStoresInventory } from 'src/lib/utils/store-utils';
import useStoreData from './useStoreData';

type UseStoreDataWithInventoryOpts = {
    // The item number to include for inventory checking
    itemNumber: string;
};

/**
 * Returns stores based on the passed search param.
 * @param opts.itemNumber The item number to include for inventory checking
 */
export default function useStoreDataWithInventory(
    opts: UseStoreDataWithInventoryOpts
) {
    const [retrieveStoreInventory, setRetrieveStoreInventory] = useState(false);

    const { mainStore, additionalStores } = useStoreData();
    const [mainStoreWithInventory, setMainStoreWithInventory] = useState<
        Store | undefined
    >();

    const [additionalStoresWithInventory, setAdditionalStoresWithInventory] =
        useState<Store[]>();

    const { itemNumber } = opts;
    const enabled = retrieveStoreInventory;
    // The base stores data without inventory data
    const stores = [mainStore, ...additionalStores] as Store[];

    const [storesWithInventory, setStoresWithInventory] = useState<Store[]>(
        (stores || []) as Store[]
    );

    function clearStores() {
        setStoresWithInventory([]);
    }
    const storeIds = stores?.map((s) => s?.storeNumber);
    const inventoryCheckingEnabled =
        enabled && !!itemNumber && !!storeIds && storeIds?.length > 0;

    const {
        data: storeInventoryData,
        error: storeInventoryError,
        isFetching: storeInventoryIsFetching,
    } = useQuery(
        ['storeInventory', storeIds],
        async () => getStoreInventory(itemNumber || '', storeIds || []),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            enabled: false,
        }
    );

    // Merge stores results with inventory
    useEffect(() => {
        if (storeInventoryError) {
            Log.log('error fetching store inventory');
            Log.log(storeInventoryError);
        } else if (
            !storeInventoryIsFetching &&
            !!storeInventoryData &&
            storeInventoryData.length > 0 &&
            itemNumber &&
            inventoryCheckingEnabled
        ) {
            // Map the inventory data with the store data
            const updatedStoreData = stores
                ? mapStoresInventory(stores, storeInventoryData)
                : [];
            setStoresWithInventory(updatedStoreData);
        }
    }, [storeInventoryData, storeInventoryError, stores, itemNumber]);

    useEffect(() => {
        if (
            !storeInventoryIsFetching &&
            !storeInventoryError &&
            storesWithInventory?.length > 0
        ) {
            const storeInventory = storesWithInventory?.find(
                (s: Store) => s?.storeNumber === mainStore?.storeNumber
            );
            const nearbyStoresWithInvetory = storesWithInventory?.filter(
                (s: Store) => s?.storeNumber !== mainStore?.storeNumber
            );
            setMainStoreWithInventory(storeInventory);
            setAdditionalStoresWithInventory(nearbyStoresWithInvetory);
            clearStores();
            setRetrieveStoreInventory(false);
        }
    }, [storeInventoryIsFetching, storeInventoryError, storesWithInventory]);

    return {
        mainStoreWithInventory,
        additionalStoresWithInventory,
        isFetching: storeInventoryIsFetching,
        error: storeInventoryError,
        storesWithInventory,
        setRetrieveStoreInventory,
        clearStores,
    };
}
