/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect } from 'react';
import COLORS from 'src/lib/colors';
import {
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import Header from './header';
import { Body, Heading, Button } from './UI';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        padding: '15px',
        alignItems: 'center',
        overflow: 'hidden',
        '& #common-dialog-title': {
            marginTop: '10px',
            paddingBottom: '8px',
        },
    },
    titleWrapper: {
        padding: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {

        letterSpacing: '-0.5pt',
        alignSelf: 'center',
    },
    titlePadding: { marginTop: '2rem' },
    dialogContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeIcon: {
        justifyContent: 'flex-end',
        position: 'absolute',
        width: '24px',
        height: '24px',
        top: '10px',
        right: '0px',
        background: '#ffffff',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        margin: '0',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
    },
    actionsWrapper: {
        flexDirection: 'column',
        width: '100%',
    },
    primaryButton: {
        width: '90%',
        textTransform: 'none',
    },
    vericalGap: {
        width: '100%',
        height: '.2rem',
    },
});

export type OutOfStockPopUpProps = {
    open: boolean;
    onClose: () => void;
    message: string;
    title: string;
    buttonText?: string;
    closeIcon?: boolean;
    onBtnClick?: () => void;
};

const CommonDialog = (props: OutOfStockPopUpProps) => {
    const { open, onClose, message, title, buttonText, closeIcon = true, onBtnClick } = props;
    const classes = useStyles();

    return (
        <Dialog
            tabIndex={0}
            open={open}
            onClose={onClose}
            BackdropProps={{ invisible: true }}
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle tabIndex={0} disableTypography id="common-dialog-title">
                <Heading

                    aria-label="Success"
                    className={classes.title}
                    size={18}
                    weight='normal'
                    type='h2'
                >
                    {title}
                </Heading>
            </DialogTitle>
            <DialogContent tabIndex={0} aria-label="Please check your email for the password reset link." className={classes.dialogContentWrapper}>
                <Body className={classes.dialogContentText} size={16} weight='light'>
                    {message}
                </Body>
            </DialogContent>
            <DialogActions className={classes.actionsWrapper}>
                <Button
                    tabIndex={0}
                    aria-selected aria-label="Ok"
                    variant="contained-rounded"
                    onClick={onBtnClick || onClose}
                    className={
                        classes.primaryButton
                    }
                    fontWeight="normal"
                    bgColor="bgRed"
                >
                    {buttonText || 'OK'}
                </Button>
            </DialogActions>

            {closeIcon && <Button
                tabIndex={0}
                role='button'
                aria-label='Close'
                fullWidth
                onClick={onClose}
                className={classes.closeIcon}
                id="close-button"
            >
                <img src={require('../assets/icons/iconCloseBlack.svg')} alt="close" />
            </Button>}
        </Dialog>
    );
};

export default CommonDialog;