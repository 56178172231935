/* eslint-disable max-len */
import React from 'react';

function IconPasswordOpen() {
    return (
        <svg
            width="21"
            height="13"
            viewBox="0 0 21 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.3323 10.2442C8.43287 10.2442 6.88818 8.6995 6.88818 6.80007C6.88818 4.90064 8.43287 3.35596 10.3323 3.35596C12.2317 3.35596 13.7764 4.90064 13.7764 6.80007C13.7764 8.6995 12.2317 10.2442 10.3323 10.2442ZM10.3323 4.04478C8.81344 4.04478 7.57701 5.28035 7.57701 6.80007C7.57701 8.31978 8.81344 9.55536 10.3323 9.55536C11.8511 9.55536 13.0876 8.31978 13.0876 6.80007C13.0876 5.28035 11.8511 4.04478 10.3323 4.04478Z"
                fill="#999999"
            />
            <path
                d="M10.3323 12.9994C6.06939 12.9994 2.1276 10.6884 0.0439124 6.96789C-0.0146375 6.8637 -0.0146375 6.73627 0.0439124 6.63123C2.1276 2.91159 6.06939 0.600586 10.3323 0.600586C14.5953 0.600586 18.5371 2.91159 20.6208 6.63209C20.6793 6.73627 20.6793 6.8637 20.6208 6.96875C18.5371 10.6893 14.5953 13.0003 10.3323 13.0003V12.9994ZM0.741345 6.79999C2.72688 10.2045 6.38366 12.3106 10.3323 12.3106C14.281 12.3106 17.9378 10.2045 19.9233 6.79999C17.9378 3.39548 14.281 1.28941 10.3323 1.28941C6.38366 1.28941 2.72688 3.39548 0.741345 6.79999Z"
                fill="#999999"
            />
        </svg>
    );
}

export default IconPasswordOpen;
