/* eslint-disable max-len */
import React from 'react';


function IconExpand() {
    return (

        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.4492 6.54004C19.0811 6.54004 22.916 10.3833 22.916 15.0068C22.916 19.6387 19.0728 23.4736 14.4409 23.4736C9.81738 23.4736 5.98242 19.6387 5.98242 15.0068C5.98242 10.3833 9.82568 6.54004 14.4492 6.54004ZM14.4492 7.95117C10.5312 7.95117 7.40186 11.0889 7.40186 15.0068C7.40186 18.9248 10.5229 22.0625 14.4409 22.0625C18.3589 22.0625 21.4966 18.9248 21.5049 15.0068C21.5132 11.0889 18.3672 7.95117 14.4492 7.95117ZM18.5249 15.7871L15.271 12.3257C14.748 11.7778 14.1421 11.7695 13.6274 12.3257L10.3652 15.7871C10.1245 16.0444 10.1162 16.4263 10.3403 16.6587C10.5894 16.9243 10.9961 16.9326 11.2368 16.6753L14.4492 13.272L17.6616 16.6753C17.894 16.9326 18.2925 16.916 18.5498 16.6587C18.7822 16.4346 18.7739 16.0444 18.5249 15.7871Z" fill="#101820" />
        </svg>

    );
}

export default IconExpand;
