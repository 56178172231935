import React, { useState, useEffect } from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useRouter from 'src/hooks/useRouter';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import FullWidthCard from 'src/components/cards/full-width-card';
import { makeSearchString } from 'src/lib/utils/url-utils';
import {
    RewardsAccountUserType,
    RewardsAccountConfirmState,
} from 'src/services/types';
import { makeEnrollQuery } from 'src/lib/utils/user-utils';
// Promotion Code for future use if needed
// import PromotionDialog, {
//   isPromotionRunning,
// } from 'src/components/PromotionDialog';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        justifyContent: 'center',
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: '.85rem',
        marginTop: '1.75rem',
        marginLeft: GUTTER_WIDTH,
    },
    rewardsCTA: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        justifyContent: 'center',
    },
    rewardsModalIcon: {
        alignSelf: 'center',
    },
    rewardsMainIconWrapper: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '5rem',
        height: '5rem',
        margin: '0 auto',
        marginBottom: '-3.5rem',
        zIndex: 99999,
    },
    rewardsWrapper: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rewardsTitle: {
        color: '#101820',
        fontWeight: 'bold',
        fontSize: '1.35rem',
        marginTop: '1.8rem',
        textAlign: 'center',
    },
    rewardsSubTitle: {
        color: '#101820',
        fontWeight: 'bold',
        fontSize: '1.10rem',
        textAlign: 'center',
    },
    rewardsIconsWrapper: {
        display: 'flex',
    },
    rewardsIcons: {
        margin: '2rem 0 1rem 0',
        width: '3.3rem',
    },
    iconSpace: {
        width: '.5rem',
    },
    rewardsDescription: {
        textAlign: 'center',
        marginTop: '0.4rem',
        letterSpacing: '-0.30pt',
        padding: '0 1.2rem',
        lineHeight: '1.3rem',
    },
    enrollNowButton: {
        paddingTop: '.1rem',
        paddingBottom: '.1rem',
        marginTop: '3rem',
        textTransform: 'none',
        minHeight: '2.5rem',
        borderRadius: '1.885rem',
        width: '92%',
        background: COLORS.rewardsYellow,
        color: COLORS.textBlack,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.rewardsYellow,
        },
        '&:active': {
            backgroundColor: COLORS.rewardsYellow,
        },
    },
    topNavContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        background: COLORS.rewardsYellow,
        backgroundImage: 'url(bgs/classroomRewardsBg.svg)',
        marginBottom: '-110px',
        height: '22rem',
    },
});

type RewardsUnenrolledProps = {
    userType?: RewardsAccountUserType;
    userConfirmState?: RewardsAccountConfirmState;
    switchTab?: any;
};

function RewardsUnenrolled({
    userType,
    userConfirmState,
    switchTab,
}: RewardsUnenrolledProps) {
    const classes = useStyles();
    const { history, pathname } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    // START Promotion Code for future use if needed
    // const [promotionDialogOpen, setPromotionDialogOpen] = useState(false);
    // useEffect(() => {
    //   if (
    //     isPromotionRunning &&
    //     pathname === '/rewards' &&
    //     history.location?.search === ''
    //   )
    //     setPromotionDialogOpen(true);
    // }, []);
    // const handlePromotionClose = () => {
    //   setPromotionDialogOpen(false);
    // };
    // END
    function beginOrContinueEnrollFlow() {
        if (userConfirmState !== 'Confirmed') {
            analyticsCustomEvent(eventMetricsMap.classroom_rewards_enroll, {
                click_text: 'enroll now',
                element_location: 'top banner',
            });
            optimoveEvents(eventMetricsMap.scm_classroom_rewards_enroll, {
                click_text: 'enroll now',
                element_location: 'top banner',
            });
            history.push({
                pathname,
                search: makeSearchString(
                    makeEnrollQuery(userType, userConfirmState)
                ),
            });
        }
    }

    const hasPendingEnrollment = !!userType;
    return (
        <Box className={classes.screen}>
            <div className={classes.topNavContainer}>{switchTab()}</div>
            <Box display="flex" className={classes.rewardsMainIconWrapper}>
                <img
                    className={classes.rewardsModalIcon}
                    src="/icons/iconClassroomrewardsModal.svg"
                    alt="rewards modal icon"
                />
            </Box>
            <FullWidthCard
                hasManualShadow
                className={classes.rewardsCTA}
                id="classroom-rewards-landing-card"
            >
                <Box display="flex" className={classes.rewardsWrapper}>
                    <Typography
                        className={classes.rewardsTitle}
                        id="classroom-rewards-landing-title"
                    >
                        Join Classroom Rewards today!
                    </Typography>
                    <Box display="flex">
                        <img
                            className={classes.rewardsIcons}
                            src="/icons/iconClassroomrewardsEducator.svg"
                            alt="rewards educator icon"
                        />
                        <div className={classes.iconSpace}>&nbsp;</div>
                        <img
                            className={classes.rewardsIcons}
                            src="/icons/iconClassroomrewardsSchool.svg"
                            alt="rewards school icon"
                        />
                    </Box>
                    <Typography
                        className={classes.rewardsSubTitle}
                        id="classroom-rewards-landing-category1-title"
                    >
                        Teachers, Educators & School Administrators
                    </Typography>
                    <Typography
                        className={classes.rewardsDescription}
                        id="classroom-rewards-landing-category1-description"
                    >
                        Sign up to start earning and redeeming rewards for
                        essential school and classroom supplies.
                    </Typography>
                    <img
                        className={classes.rewardsIcons}
                        src="/icons/iconClassroomrewardsSupporter.svg"
                        alt="rewards supporters icon"
                    />
                    <Typography
                        className={classes.rewardsSubTitle}
                        id="classroom-rewards-landing-category2-title"
                    >
                        Parents & Supporters
                    </Typography>
                    <Typography
                        className={classes.rewardsDescription}
                        id="classroom-rewards-landing-category2-description"
                    >
                        Sign up to start earning rewards you can give to the
                        teacher, educator or school of your choice.{' '}
                    </Typography>
                    <Button
                        id="classroom-rewards-enroll-button"
                        variant="contained"
                        classes={{
                            root: classes.enrollNowButton,
                        }}
                        onClick={beginOrContinueEnrollFlow}
                    >
                        {hasPendingEnrollment
                            ? 'Complete enrollment'
                            : 'Enroll now'}
                    </Button>
                </Box>
            </FullWidthCard>
            {/* Promotion Code for future use if needed */}
            {/* <PromotionDialog
        handleClose={handlePromotionClose}
        open={promotionDialogOpen}
        handlePromotionClick={beginOrContinueEnrollFlow}
        type="unenrolled"
      /> */}
            {/* END */}
        </Box>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedRewardsUnenrolled = (props: RewardsUnenrolledProps) => (
    <RewardsUnenrolled {...props} />
);
export default WrappedRewardsUnenrolled;
