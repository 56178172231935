import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Player } from '@lottiefiles/react-lottie-player';
import COLORS from 'src/lib/colors';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import {
  getScanCouponData,
  getAddCouponData,
  getStaplesRewardsData,
} from 'src/lib/api-client/wallet-activities';
import useUserInfo from 'src/hooks/useUserInfo';

import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { isListEnabled } from 'src/lib/api-client/lists';
import Carousel from '../Carousel';
import ScannableCoupon, { UnenrolledCoupon } from './ScannableCoupon';
import NotificationSwitch from './NotificationSwitch';
import useStyles from './styles';
import LoadingOverlay from '../loading-overlay';
import { useProductBrowseContext } from "../../lib/contexts/product-browse-context";

function ScannableCouponDialog() {
  const classes = useStyles();
  const { userInfo, isFetchingUserInfo } = useUserInfo();
  const [showError, setShowError] = useState(false);
  const { isScannbleDeepLink, staplesScannableCouponDeepLink } =
    useUserUpdateContext();
  const { history, query } = useRouter();
  console.log("🚀 ~ ScannableCouponDialog ~ query:", query)
  const rewardsNumber = userInfo?.rewardsNumber || '0';
  const couponNumber: string | undefined = query?.couponNumber;
  const couponCode = couponNumber;
  const isRouteFromDeepLink = query?.deepLink === 'true';
  const [isSaveCouponCall, setSaveCouponCall] = useState(false);
  const [isSaveCouponFetch, setSaveCouponFetch] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const { fromPath } = useProductBrowseContext();
  // const tabIndex = query?.tabIndex;
  const { data: isSaveCouponsEnabled } = useQuery(
    ['isSaveCouponsEnabled'],
    () => isListEnabled('SaveCoupons'),
    {
      refetchOnMount: true,
    }
  );
  const filterAndSetCouponList = (couponDataList: any) =>
    couponDataList.filter(
      (coupon: any) =>
        coupon.category === 'Coupon' && ((!coupon?.couponInfo?.isRedeemed || coupon?.couponInfo?.couponClass.toLowerCase() === 'storecredit'))
    );
  const addScanCoupon = () => {
    if (couponCode?.length === 5) {
      return getScanCouponData({ rewardsNumber, couponCode });
    }
    return getAddCouponData({ rewardsNumber, couponCode });
  }

  const {
    data: response,
    isFetched,
    isFetching,
    status,
    refetch: fetchCouponData,
  } = isRouteFromDeepLink && !isSaveCouponCall
      ? useQuery(
        ['scanDetailsCoupons', rewardsNumber],
        addScanCoupon,
        {
          ...NO_REFETCH,
          enabled: !isFetchingUserInfo,
          onError: (err) => {
            setShowError(true);
          },
        }
      )
      : useQuery(
        ['details', rewardsNumber],
        () => getStaplesRewardsData(rewardsNumber),
        {
          //   ...CACHE_ONLY,
        }
      );
  useEffect(() => {
    if (!isScannbleDeepLink && isRouteFromDeepLink) {
      staplesScannableCouponDeepLink();
    }
  }, []);
  useEffect(() => {
    if (isSaveCouponCall) {
      fetchCouponData();
      setSaveCouponCall(false);
      setTimeout(() => {
        setSaveCouponFetch(false);
      }, 500)
    }
  }, [isSaveCouponCall])
  const couponList: any = useMemo(() => {
    if (status === 'success' && isFetched) {
      let isCouponAvaible = true;
      if (isRouteFromDeepLink && couponNumber) {
        const couponNumberLength = couponNumber.length;
        if (couponNumberLength === 5) {
          isCouponAvaible =
            response?.data?.couponDataList?.findIndex(
              (coupon: any) =>
                !coupon?.couponInfo?.isRedeemed &&
                (coupon?.couponInfo?.couponClass === 'StoreQRSingleUse' ||
                  coupon?.couponInfo?.couponClass === 'StoreQRMultiUse' ||
                  coupon?.couponInfo?.couponClass === 'NewUserQRCode') &&
                couponNumber === coupon?.couponNumber?.substring(0, couponNumberLength)
            ) !== -1;
        } else if (couponNumberLength === 16) {
          isCouponAvaible =
            response?.data?.couponDataList?.findIndex(
              (coupon: any) =>
                !coupon?.couponInfo?.isRedeemed &&
                (coupon?.couponInfo?.couponClass === 'WebCoupon') &&
                couponNumber === coupon?.couponNumber?.substring(0, couponNumberLength)
            ) !== -1;
        } else {
          isCouponAvaible = false;
        }
      }
      if (isCouponAvaible) {
        const resData =
          response?.data?.couponDataList?.sort((a: any, b: any) =>
            a.couponInfo.priority > b.couponInfo.priority ? 1 : -1
          ) || [];
        return filterAndSetCouponList(resData);
      }
      return [];
    }
    return null;
  }, [isFetched, status, response]);

  const couponMap = (coupon: any) => {
    const { couponInfo } = coupon;
    return (
      <ScannableCoupon
        rewardsNumber={rewardsNumber}
        couponApplicable={couponInfo.couponApplicable}
        couponNumber={coupon.couponNumber}
        couponLimitedTime={couponInfo.isLimitedTime}
        availableUntil={couponInfo.availableUntil}
        expirationDate={couponInfo.expirationDate}
        descriptionText1={couponInfo.descriptionText1}
        descriptionText2={couponInfo.descriptionText2}
        isSaved={couponInfo.isSaved}
        validForDotcom={couponInfo.validForDotcom}
        validForRetail={couponInfo.validForRetail}
        terms={couponInfo.terms}
        isCouponNumberDisplayed={coupon.isCouponNumberDisplayed}
        imageURL={couponInfo.imageURL}
        setSaveCouponCall={setSaveCouponCall}
        setSaveCouponFetch={setSaveCouponFetch}
        couponClass={couponInfo.couponClass}
        isSaveCouponsEnabled={isSaveCouponsEnabled?.isenabled}
      />
    );
  };

  const getDefaultIndex = () => {
    if (isRouteFromDeepLink && couponNumber?.length === 5) {
      const index = couponList?.findIndex(
        (coupon: any) =>
          !coupon?.couponInfo?.isRedeemed &&
          (coupon?.couponInfo?.couponClass === 'StoreQRSingleUse' ||
            coupon?.couponInfo?.couponClass ===
            'StoreQRMultiUse') &&
          couponNumber === coupon.couponNumber.substring(0, 5)
      );
      return index >= 0 ? index : 0;
    }
    if (isRouteFromDeepLink && couponNumber?.length === 16) {
      const index = couponList?.findIndex(
        (coupon: any) =>
          !coupon?.couponInfo?.isRedeemed &&
          (coupon?.couponInfo?.couponClass === 'WebCoupon') &&
          couponNumber === coupon.couponNumber.substring(0, 16))

      return index >= 0 ? index : 0;
    }
    if (couponNumber) {
      const index = couponList?.findIndex(
        (coupon: any) => couponNumber === coupon.couponNumber
      );
      return index >= 0 ? index : 0;
    }
    return 0;
  };
  const handleClose = () => {
    if (couponNumber) {
      if (query?.tabIndex === "1") {
        history.push(ROUTES.rewardsWallet);
      } else if (ROUTES.stores === fromPath) {
        history.push(ROUTES.stores)
      } else {
        history.push(`${ROUTES.rewardsWallet}?tabIndex=2`);
      }
    } else {
      history.push(ROUTES.home);
    }
  };
  const handleCloseNoResponsePopUp = () => {
    setShowError(false);
    history.push(ROUTES.home);
  };
  if (isRouteFromDeepLink && (couponList?.length === 0 || showError)) {
    return (
      <Dialog
        open
        onClose={handleCloseNoResponsePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unable to redeem coupon. Coupon not found.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoResponsePopUp}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <div className={classes.carouselContainer}>
      {isSaveCouponFetch &&
        <LoadingOverlay variant="wordmark" theme="transparent-light" open />}
      <ButtonBase className={classes.closeButton} onClick={handleClose}>
        <img
          src={require('../../assets/icons/iconCrossWhite.svg')}
          alt="close"
        />
      </ButtonBase>
      <NotificationSwitch />
      {rewardsNumber && couponList ? (
        <Carousel
          type="number"
          dots={
            couponList?.length
          }
          dotPosition="top"
          dotStyle={{
            margin: '1rem 0',
          }}
          activeColor={COLORS.brandRed}
          defaultIndex={defaultIndex > 0 ? defaultIndex : getDefaultIndex()}
          styles={{
            root: {
              flexGrow: 1,
              overflow: 'hidden',
            },
            containerStyle: {
              height: '100%',
              paddingBottom: 20,
            },
            slideContainer: {
              height: '100%',
              overflow: 'hidden',
              // padding: '0 1.5rem 1.5rem 1.5rem',
            },
          }}
          content={
            couponList?.map(couponMap)
          }
          setDefaultIndex={setDefaultIndex}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Player
            autoplay
            loop
            src={require('../../assets/lotties/stapleRevealWordmark.json')}
          />
        </div>
      )}
    </div>
  );
}

export default () => <ScannableCouponDialog />;
