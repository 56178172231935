/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import axios from 'axios';
import {
    userAppMessageURL,
    userAppVersionCheckURL,
    userUpdateAppMessageURL,
} from 'src/services/urls';

export async function getApplicationPopUpMessages(): Promise<any | null> {
    const applicationPopUpData = await axiosInstance.get(
        `${userAppMessageURL}`
    );
    if (applicationPopUpData?.data?.appMessage?.length > 0) {
        return applicationPopUpData?.data?.appMessage;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching associated
 schools via ${userAppMessageURL}`
    );
    Log.axios(applicationPopUpData);
    return null;
}

export async function updateApplicationMessagesPopUp(
    AppMessageId: number,
    IsActionTaken: boolean
): Promise<null> {
    const messageUpdate = await axiosInstance.post(
        `${userUpdateAppMessageURL}`,
        { AppName: 'PWA', AppMessageId, IsActionTaken }
    );
    if (messageUpdate.data) {
        return messageUpdate?.data?.appMessage;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when donating via
     ${userUpdateAppMessageURL}`
    );
    Log.axios(messageUpdate);
    return null;
}

export async function getApplicationVersion(): Promise<any> {
    const applicationVerionData: any = await axios.get(
        `${userAppVersionCheckURL}`,
        {
            headers: {
                // always add the api key (needed for API Management)
                'Ocp-Apim-Subscription-Key':
                    process.env.REACT_APP_PUBLIC_VERSION_CHECK_API_KEY || '',
                'content-type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        }
    );
    if (applicationVerionData?.data?.appVersion) {
        return applicationVerionData.data.appVersion;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching application version
  ${userAppVersionCheckURL}`
    );
    Log.axios(applicationVerionData);
    return null;
}
