import React, { useState, useEffect } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { makeSearchString } from 'src/lib/utils/url-utils';
import ErrorDialog, { HandleCloseFn } from 'src/components/error-dialog';
import Steps from 'src/screens/donation-flow/steps';
import {
    DonateControllerState,
    UpdateControllerValuesFn,
} from 'src/screens/donation-flow/types';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import ROUTES from 'src/lib/routes';

type ExpectedQuery = DonateControllerState;

const SlideTransition = (props: any) => <Slide direction="up" {...props} />;
const SLIDE_TRANSITION_DURATION = 300;

function DonationFlowController() {
    const { query, history, pathname } = useRouter();
    const {
        donateStep,
        donateAmount,
        recipientName,
        recipientSchool,
        recipientType,
    } = query as ExpectedQuery;
    const [previousStep, setPreviousStep] = useState(donateStep);
    const [isFinished, setIsFinished] = useState(false);
    const [inProgress, setInProgress] = useState(
        donateStep !== undefined && !isFinished
    );
    const [transitionDuration, setTransitionDuration] = useState(0);
    useEffect(() => {
        if (donateStep !== previousStep) {
            setTransitionDuration(SLIDE_TRANSITION_DURATION);
            setInProgress(donateStep !== undefined && !isFinished);
            setPreviousStep(donateStep);
        }
    }, [donateStep, previousStep, isFinished]);

    const [showError, setShowError] = useState(false);
    const handleCloseErrorDialog: HandleCloseFn = (reason) => {
        // eslint-disable-next-line no-console
        console.log(reason);
        setShowError(false);
    };

    function onError(errorReason: string) {
        // eslint-disable-next-line no-console
        console.log(errorReason);
        // TODO: STP-371 show different errors based on errorReason
        setShowError(true);
    }

    const { notifyUserUpdate } = useUserUpdateContext();
    const updateControllerValues: UpdateControllerValuesFn = (newValues) => {
        if (newValues.donateStep === 'success') {
            // refresh user state as soon as we go to the success screen
            // to give more time to load the new user data behind the scenes
            notifyUserUpdate();
        }
        history.push({
            pathname,
            search: makeSearchString({ donateStep, ...newValues }),
        });
    };

    const finishFlow = () => {
        setIsFinished(true);
        if (pathname === ROUTES.rewardsWallet) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            history.push({
                pathname: ROUTES.rewardsWallet,
                search: '',
            });
        } else {
            history.push({
                pathname: ROUTES.rewards,
                search: '',
            });
        }
    };

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.DONATION_FLOW,
        inProgress
    );

    return (
        <Dialog
            fullScreen
            open={isActiveScreen}
            transitionDuration={transitionDuration}
            TransitionComponent={SlideTransition}
            TransitionProps={{ in: isActiveScreen }}
            onExited={() => setIsFinished(false)}
        >
            <div>
                <Steps
                    recipientName={recipientName}
                    recipientSchool={recipientSchool}
                    recipientType={recipientType}
                    donateAmount={donateAmount}
                    donateStep={donateStep}
                    isFinished={isFinished}
                    finishFlow={finishFlow}
                    updateControllerValues={updateControllerValues}
                    onError={onError}
                />
                <ErrorDialog
                    open={showError}
                    handleClose={handleCloseErrorDialog}
                />
            </div>
        </Dialog>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedDonationFlowController = () => <DonationFlowController />;
export default WrappedDonationFlowController;
