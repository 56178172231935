import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        width: '28px',
        zIndex: 100,
        color: 'white',
    },
    scannerContainer: {
        //   position: 'relative',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: 'black',
        '& > video': {
            height: '100vh',
        },
        '& > div:last-child': {
            display: 'none !important',
        },
        //   border: '3px solid blue',
    },
    viewFinder: {
        position: 'absolute',
        height: '25vh',
        width: '80vw',
        border: '5px solid white',
        borderRadius: '40px',
        borderStyle: 'none solid',
    },
    cameraCanvas: {
        position: 'absolute',
        top: 0,
        height: '100vh',
        width: '100vw',
    },
    dialog: {
        maxWidth: '375px',
        borderRadius: '1rem',
    },
    contentWrapper: {
        padding: '.75rem 1.5rem',
        paddingTop: 0,
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        letterSpacing: '-0.39pt',
        textAlign: 'center',
    },
    desc: {
        fontSize: '.85rem',
        textAlign: 'center',
        margin: '1rem 0',
    },
    buttons: {
        padding: '1rem 0 .75rem',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        [theme.breakpoints.down(420)]: {
            flexDirection: 'column',
        },
    },
    tryAgainBtn: {
        height: '2.5rem',
        padding: '.5rem 1.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        [theme.breakpoints.down(420)]: {
            marginBottom: '.5rem',
        },
    },
    errorDialogCloseButton: {
        marginTop: '1rem',
        marginRight: '1rem',
        width: '28px',
        alignSelf: 'flex-end',
    },
}));

export default useStyles;
