import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import COLORS from 'src/lib/colors';
import clsx from 'clsx';

type ScrollableRowProps = {
    children: any;
    id?: string;
    showShadow?: boolean;
    fullScreen?: boolean;
    scrollProps?: {
        onMouseDown: (event: React.MouseEvent) => void;
        onMouseLeave: () => void;
        onMouseMove: (event: React.MouseEvent) => void;
        onMouseUp: () => void;
        setElement: (id: string) => void;
    };
    className?: string;
};

const DESKTOP_SHADOW_WIDTH = 2; // rem

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflow: 'hidden',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        paddingLeft: '1rem',
        paddingBottom: '1rem',
        marginBottom: '-1rem',
        userSelect: 'none',
    },
    fullScreen: {
        paddingLeft: '0',
    },
    rightSpace: {
        width: '2rem',
    },
    shadow: {
        position: 'relative',
        minWidth: '1rem',
        width: `${DESKTOP_SHADOW_WIDTH}rem`,
        marginLeft: `-${DESKTOP_SHADOW_WIDTH / 2}rem`,
        marginRight: `-${DESKTOP_SHADOW_WIDTH / 2}rem`,
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 2, // because of the map preview
        // eslint-disable-next-line max-len
        background: `linear-gradient(90deg, ${COLORS.whiteSmokeTransparent} 0%, ${COLORS.whiteSmoke} 50%, ${COLORS.whiteSmokeTransparent} 100%);`,
    },
});

/** used for screens that require horizontal scrolling, for example, with cards  */
export default function ScrollableRow({
    children,
    id,
    showShadow = true,
    fullScreen = false,
    scrollProps,
    className,
}: ScrollableRowProps) {
    const classes = useStyles();
    const isMobileWidth = useIsMobileWidth();

    useEffect(() => {
        if (id) {
            scrollProps?.setElement(id);
        }
    }, [id]);
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div
                className={showShadow ? classes.shadow : ''}
                style={{
                    display: isMobileWidth ? 'none' : 'block',
                }}
            >
                &nbsp;
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    className={clsx(
                        fullScreen && classes.fullScreen,
                        classes.container, className
                    )}
                    id={id}
                    onMouseDown={scrollProps?.onMouseDown}
                    onMouseUp={scrollProps?.onMouseUp}
                    onMouseLeave={scrollProps?.onMouseLeave}
                    onMouseMove={scrollProps?.onMouseMove}
                >
                    {children}
                    <div className={classes.rightSpace}>&nbsp;</div>
                </Box>
                <div style={{ width: '100%', height: '.5rem' }}>&nbsp;</div>
            </div>
            <div
                className={showShadow ? classes.shadow : ''}
                style={{
                    display: isMobileWidth ? 'none' : 'block',
                }}
            >
                &nbsp;
            </div>
        </div>
    );
}
