import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useUserInfo, useRouter } from 'src/hooks';
import { useCartCountContext } from '../lib/contexts/cart-count-context';
import { SessionManager } from '../lib/api-client/sessionManager';

function useAdobeAnalytics() {
    const { pathname } = useRouter();
    const { activeIframeId } = useCartCountContext();
    const { userInfo } = useUserInfo();

    const updateUserOnPathChange = () => {
        const cusId = SessionManager?.sub;
        const cookies = document.cookie.split(';');
        const filteredCookie = cookies?.filter((cookie) => {
            if (cookie.replace(/\s/, '').indexOf('AMCV_') >= 0) {
                return cookie;
            }
            return null;
        })?.[0];
        const ecId = filteredCookie?.split('=')?.[1] ?? '';
        window.Analytics.traffic.pagename = `staples_somni_android_app:${pathname?.replace(
            '/',
            ''
        )}`;
        window.Analytics.global.visitor = {
            devicetype: 'Mobile',
            useragent: `${window.navigator.userAgent}(staples_somni_android_app)`,
            email: userInfo?.email,
            currency: 'USD',
            loggedin: userInfo && !!userInfo.email,
            pageurl: `${window.location.href}?ecId=${ecId}`,
            devicename: 'Android',
            customerID: cusId,
            rewardsNumber: userInfo?.rewardsNumber,
        };
        /* eslint-disable */
        if (!activeIframeId) {
            (window as any).ae &&
                (window as any).ae.adapter &&
                (window as any).ae.adapter.contentloaded();
        }
    };

    const updateGenericLink = (textValue: any) => {
        (window as any).ae &&
            (window as any).ae.adapter &&
            (window as any).ae.adapter.linkTrack({
                type: 'GENERIC_LINK',
                payload: {
                    text: `${textValue}`,
                },
            });
    };

    const loginSuccessDataToAdobe = () => {
        // eslint-disable-next-line no-unused-expressions
        (window as any).ae && (window as any).ae.adapter && (window as any).ae.adapter.handleSuccess && (window as any)?.ae?.adapter?.handleSuccess({
            message: 'success',
            type: 'LOGIN',
            payload: {
                pagename: 'login-page',
                domain: 'dotcom or pni',
            },
        });
    }
    
    const loginFailedDataToAdobe = (errorCode: number, error: string) => {
        // eslint-disable-next-line no-unused-expressions
        (window as any).ae && (window as any).ae.adapter && (window as any).ae.adapter.handleSuccess && (window as any).ae?.adapter?.handleSuccess({
            message: 'error',
            type: 'LOGIN',
            payload: {
                pagename: 'login-page',
                domain: 'dotcom or pni',
                errors: [{ "code": errorCode, "desc": error }],
            },
        });
    }

    const addCartDataToAdobe = ({
        location,
        cartId,
        cartItems,
        ctxId,
        cartTotal,
    }: any) => {
        (window as any).ae &&
            (window as any).ae.adapter &&
            (window as any).ae.adapter.ctaResponse &&
            (window as any).ae.adapter.ctaResponse({
                ctaId: ctxId,
                location,
                message: 'SUCCESS',
                type: 'ADD_TO_CART',
                cartId,
                cartInit: false,
                payload: {
                    cardName: location,
                    assemblyaddon: false,
                    items: cartItems,
                    linkType: 'ADDTOCART',
                    term: '',
                    protectionplan: false,
                    cartTotal,
                    locator: {
                        secondary: location,
                        location,
                        recType: location,
                    },
                },
            });
    };

    return {
        updateGenericLink,
        updateUserOnPathChange,
        addCartDataToAdobe,
        loginSuccessDataToAdobe,
        loginFailedDataToAdobe,
    };
}

export default useAdobeAnalytics;
