import React from 'react';
import IconInterface from './IconInterface';


function IconScanner({ width = 40, height = 40 }: IconInterface) {
    return (
        <svg width={width} height={height} viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.5 70C16.2 70 0.5 54.3 0.5 35C0.5 15.7 16.2 0 35.5 0C54.8 0 70.5 15.7 70.5 35C70.5 54.3 54.8 70 35.5 70ZM35.5 2.5C17.575 2.5 3 17.075 3 35C3 52.925 17.575 67.5 35.5 67.5C53.425 67.5 68 52.925 68 35C68 17.075 53.425 2.5 35.5 2.5Z" fill="#CC0000"/>
            <path d="M56.8125 47.7125C56.125 47.7125 55.5625 47.15 55.5625 46.4625V23.5375C55.5625 22.85 56.125 22.2875 56.8125 22.2875C57.5 22.2875 58.0625 22.85 58.0625 23.5375V46.4625C58.0625 47.15 57.5 47.7125 56.8125 47.7125Z" fill="#CC0000"/>
            <path d="M53.0625 47.7125C52.375 47.7125 51.8125 47.15 51.8125 46.4625V23.5375C51.8125 22.85 52.375 22.2875 53.0625 22.2875C53.75 22.2875 54.3125 22.85 54.3125 23.5375V46.4625C54.3125 47.15 53.75 47.7125 53.0625 47.7125Z" fill="#CC0000"/>
            <path d="M14.3 47.7125C13.6125 47.7125 13.05 47.15 13.05 46.4625V23.5375C13.05 22.85 13.6125 22.2875 14.3 22.2875C14.9875 22.2875 15.55 22.85 15.55 23.5375V46.4625C15.55 47.15 14.9875 47.7125 14.3 47.7125Z" fill="#CC0000"/>
            <path d="M18.05 47.7125C17.3625 47.7125 16.8 47.15 16.8 46.4625V23.5375C16.8 22.85 17.3625 22.2875 18.05 22.2875C18.7375 22.2875 19.3 22.85 19.3 23.5375V46.4625C19.3 47.15 18.7375 47.7125 18.05 47.7125Z" fill="#CC0000"/>
            <path d="M24.3 47.7125C23.6125 47.7125 23.05 47.15 23.05 46.4625V23.5375C23.05 22.85 23.6125 22.2875 24.3 22.2875C24.9875 22.2875 25.55 22.85 25.55 23.5375V46.4625C25.55 47.15 24.9875 47.7125 24.3 47.7125Z" fill="#CC0000"/>
            <path d="M28.05 47.7125C27.3625 47.7125 26.8 47.15 26.8 46.4625V23.5375C26.8 22.85 27.3625 22.2875 28.05 22.2875C28.7375 22.2875 29.3 22.85 29.3 23.5375V46.4625C29.3 47.15 28.7375 47.7125 28.05 47.7125Z" fill="#CC0000"/>
            <path d="M31.8 47.7125C31.1125 47.7125 30.55 47.15 30.55 46.4625V23.5375C30.55 22.85 31.1125 22.2875 31.8 22.2875C32.4875 22.2875 33.05 22.85 33.05 23.5375V46.4625C33.05 47.15 32.4875 47.7125 31.8 47.7125Z" fill="#CC0000"/>
            <path d="M36.8 47.7125C36.1125 47.7125 35.55 47.15 35.55 46.4625V23.5375C35.55 22.85 36.1125 22.2875 36.8 22.2875C37.4875 22.2875 38.05 22.85 38.05 23.5375V46.4625C38.05 47.15 37.4875 47.7125 36.8 47.7125Z" fill="#CC0000"/>
            <path d="M40.55 47.7125C39.8625 47.7125 39.3 47.15 39.3 46.4625V23.5375C39.3 22.85 39.8625 22.2875 40.55 22.2875C41.2375 22.2875 41.8 22.85 41.8 23.5375V46.4625C41.8 47.15 41.2375 47.7125 40.55 47.7125Z" fill="#CC0000"/>
            <path d="M46.8 47.7125C46.1125 47.7125 45.55 47.15 45.55 46.4625V23.5375C45.55 22.85 46.1125 22.2875 46.8 22.2875C47.4875 22.2875 48.05 22.85 48.05 23.5375V46.4625C48.05 47.15 47.4875 47.7125 46.8 47.7125Z" fill="#CC0000"/>
        </svg>

    );
}

export default IconScanner;