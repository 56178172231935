/* eslint-disable max-len */
import * as React from 'react';

function SvgComponent(
    props: React.SVGProps<SVGSVGElement> & { fillColor: string }
) {
    const { fillColor = '#101820' } = props;
    return (
        <svg width="26"
             height="26"
             viewBox="0 0 26 26"
             {...props} fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.1529 23.9928H5.33441"
                stroke={fillColor}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M22.4874 8.535V22.8603C22.4152 23.5095 21.8742 24 21.2178 24"
                stroke={fillColor}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M5.26953 23.9927C4.62034 23.9927 4.07213 23.5023 4 22.8531V8.52773H22.4874"
                stroke={fillColor}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M17.3155 9.97059V6.47222C17.3155 4.00531 15.4978 2 13.2473 2C11.004 2 9.17908 3.9981 9.17908 6.47222V9.97781"
                stroke={fillColor}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

    );
}

export default SvgComponent;
