import React from 'react';
import IconInterface from './IconInterface';

function IconStoreMode({ width = 24, height = 24 }: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.2505 22.5H22.5005V12.75C22.5005 12.336 22.1645 12 21.7505 12C21.3365 12 21.0005 12.336 21.0005 12.75V22.5H15.7505V17.1C15.7505 16.098 15.3605 15.1566 14.6525 14.4486C13.9445 13.7406 13.0025 13.35 12.0005 13.35C10.9985 13.35 10.0565 13.74 9.34909 14.4486C8.64049 15.1572 8.25049 16.0986 8.25049 17.1V22.5H3.00049V12.75C3.00049 12.336 2.66449 12 2.25049 12C1.83649 12 1.50049 12.336 1.50049 12.75V22.5H0.750488C0.336488 22.5 0.000488281 22.836 0.000488281 23.25C0.000488281 23.664 0.336488 24 0.750488 24H23.2505C23.6645 24 24.0005 23.664 24.0005 23.25C24.0005 22.836 23.6645 22.5 23.2505 22.5ZM9.75049 22.5V17.1C9.75049 16.4988 9.98449 15.9342 10.4099 15.5094C11.2595 14.6586 12.7421 14.658 13.5917 15.5094C14.0171 15.9336 14.2511 16.4982 14.2511 17.0994V22.4994H9.75109L9.75049 22.5Z"
                fill="#231F20"
            />
            <path
                d="M3.56569 10.902C4.47949 10.902 5.34889 10.557 6.01369 9.9306C6.12289 9.828 6.22429 9.7188 6.31849 9.6054C6.42529 9.7506 6.54349 9.888 6.67309 10.0176C7.34569 10.6902 8.23969 11.0604 9.19069 11.0604C10.1417 11.0604 11.0357 10.6902 11.7083 10.0176C11.8379 9.888 11.9561 9.7506 12.0623 9.6054C12.1565 9.7194 12.2585 9.828 12.3671 9.9306C13.0319 10.557 13.9013 10.902 14.8151 10.902C15.7289 10.902 16.5989 10.557 17.2637 9.9306C17.3729 9.828 17.4749 9.7194 17.5685 9.6054C17.6753 9.75 17.7935 9.888 17.9231 10.0176C18.5957 10.6896 19.4897 11.0598 20.4401 11.0598C21.3905 11.0598 22.2851 10.6896 22.9571 10.0176C23.6297 9.345 23.9999 8.451 23.9999 7.5C23.9999 7.4454 23.9939 7.3908 23.9819 7.3374L22.6133 1.1808C22.5407 0.849 22.3553 0.5484 22.0895 0.333C21.8249 0.1194 21.4925 0.0012 21.1505 0H2.84809C2.50849 0.0012 2.17489 0.1194 1.91089 0.3336C1.64689 0.5466 1.46149 0.8472 1.38889 1.1772L0.0184883 7.3374C0.00348828 7.4058 -0.00251172 7.4754 0.00228828 7.545C0.0562883 8.457 0.452288 9.3042 1.11769 9.9312C1.78249 10.5576 2.65189 10.9026 3.56629 10.9026L3.56569 10.902ZM2.85049 1.5L21.1487 1.503L22.4993 7.5762C22.4801 8.0982 22.2677 8.5854 21.8969 8.9568C21.1187 9.735 19.7615 9.735 18.9839 8.9568C18.5957 8.568 18.3809 8.0502 18.3809 7.5C18.3809 7.0944 18.0587 6.7626 17.6537 6.75H17.6303C17.2355 6.75 16.9061 7.0578 16.8821 7.4544C16.8503 7.9836 16.6205 8.4756 16.2353 8.8386C15.4643 9.564 14.1677 9.5646 13.3967 8.8386C13.0109 8.475 12.7817 7.9836 12.7499 7.455C12.7253 7.0506 12.3875 6.7302 11.9789 6.75C11.5733 6.762 11.2511 7.0944 11.2511 7.5C11.2511 8.0502 11.0369 8.5674 10.6481 8.9568C9.86989 9.7344 8.51209 9.7344 7.73509 8.9568C7.34569 8.5674 7.13149 8.0502 7.13149 7.5C7.13149 7.0944 6.80929 6.7626 6.40369 6.75C5.97769 6.7332 5.65669 7.0506 5.63269 7.455C5.60089 7.9836 5.37109 8.475 4.98529 8.8386C4.21489 9.5646 2.91709 9.5646 2.14669 8.8386C1.78429 8.4972 1.55989 8.0436 1.50769 7.5516L2.85049 1.5Z"
                fill="#231F20"
            />
        </svg>
    );
}

export default IconStoreMode;
