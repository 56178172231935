import React, { Children, useEffect, useState } from 'react';
import {
    Button,
    ButtonBase,
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
    RadioGroup,
    Radio,
    RadioProps,
    FormControlLabel,
    withStyles,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import clsx from 'clsx';

const useStyles = makeStyles({
    paper: { borderRadius: '15px', margin: '1rem', padding: '10px 25px 30px 25px', color: '#101820' },
    borderBox: {
        border: '1px solid #cccccc', padding: '15px',
        borderRadius: '15px', display: 'flex', marginBottom: '20px',
    },
    subTitle: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '19px',
        paddingBottom: '5px',
    },
    radioButton: {
        fontSize: 28,
        color: '#101820',
    },
    title: {
        fontSize: '17px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '-0.39px',
        paddingBottom: '15px',
    },
    addressDetails: {
        fontSize: '14px',
        fontWight: '400',
        lineHeight: '20px',
    },
    titlePadding: { marginTop: '2rem' },
    dialogContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        padding: '0 !important',
    },
    closeIcon: {
        justifyContent: 'flex-end',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        marginTop: '1rem',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
    },
    actionsWrapper: {
        flexDirection: 'column',
    },
    primaryButton: {
        width: '90%',
        margin: '2.5rem 5% 1rem 5%',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    primaryButtonRed: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        marginTop: '10px',
        fontVariantLigatures: 'none',
        '&.Mui-disabled': {
            background: '#979797',
        },
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },

    },
    active: {
        border: `1px solid ${COLORS.secondaryNeutralBlack}`,
    },
});
const BlackRadio = withStyles({
    root: {
        color: COLORS.secondaryNeutralBlack,
        '&$checked': {
            color: COLORS.secondaryNeutralBlack,
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
export type ConfirmationDialogProps = {
    open: boolean;
    onComplete?: () => void;
    handleClose?: () => void;
    data: any;
    sendData: any;
};

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
    const { open, handleClose, onComplete, data, sendData } = props;
    const [addressData, setAddressData] = useState({});
    const [selectedValue, setSelectedValue] = React.useState('suggested');
    const classes = useStyles();
    const handleOldAddress = (event: any) => {
        setSelectedValue(event.target.value);
        setAddressData(data.oldAddress)
    };
    const useAddress =() => {
        const modifiedData = {
            firstName: data?.oldAddress?.firstName,
            lastName: data?.oldAddress?.lastName,
            companyName: data?.oldAddress?.companyName,
            address: data?.modifiedAddress?.street1,
            aptNumb: data?.modifiedAddress?.street2,
            city: data?.modifiedAddress?.city,
            state: data?.modifiedAddress?.state,
            zipCode: data?.modifiedAddress?.postalCode?.substring(0,5),
            phoneNumber: data?.oldAddress?.phoneNumber,
            countrycode: 1,
            country: "US",
            email: data?.oldAddress?.email,
        }
        setAddressData(modifiedData);
    }
    const handleUseAddress = (event: any) => {
        setSelectedValue(event.target.value);
        useAddress();
    }

    useEffect(() => {
        useAddress();
    },[data])
    const handleAddressClick = () => sendData(addressData);
    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="sender-information-dialog-title"
                classes={{
                    paper: classes.paper,
                }}
            ><Button
                fullWidth
                onClick={handleClose}
                className={classes.closeIcon}
                id="close-button"
            >
                <img src={require('../../../assets/icons/iconCloseBlack.svg')} alt="close icon" />
            </Button>
                <DialogContent className={classes.dialogContentWrapper}>

                    <Typography className={classes.title}>Please review your address below</Typography>
                    <RadioGroup
                        id="address-radio-group"
                        aria-label="addressInfo"
                        name="addressInfo"
                        value={selectedValue}
                    >
                        <div className={clsx(classes.borderBox, selectedValue === "suggested" && classes.active )}>
                            <FormControlLabel
                                id="radio-suggested-address"
                                onChange={handleUseAddress}
                                value="suggested"
                                label={
                                    <div>
                                        <Typography className={classes.subTitle}>Suggested</Typography>
                                        <Typography className={classes.addressDetails}>
                                            {data?.modifiedAddress?.street1}<br />
                                            {data?.modifiedAddress?.city}, {data?.modifiedAddress?.state},
                                            {data?.modifiedAddress?.country},  
                                            {data?.modifiedAddress?.postalCode}</Typography>
                                    </div>
                                }
                                control={<BlackRadio />}

                            />


                        </div>
                        <div className={clsx(classes.borderBox, selectedValue === "current" && classes.active )}>
                            <FormControlLabel
                                id="radio-current-address"
                                onChange={handleOldAddress}
                                value="current"
                                label={
                                    <div>
                                        <Typography className={classes.subTitle}>Current</Typography>
                                        <Typography className={classes.addressDetails}>
                                            {data?.oldAddress?.address}<br />
                                            {data?.oldAddress?.city}, {data?.oldAddress?.state},
                                            {data?.modifiedAddress?.country},
                                            {data?.oldAddress?.zipCode}</Typography>
                                    </div>
                                }
                                control={<BlackRadio />}
                            />

                        </div>
                    </RadioGroup>
                    <ButtonBase
                        id="confirm-address"
                        onClick={handleAddressClick}
                        className={classes.primaryButtonRed}
                        disabled={selectedValue === ''}
                    >
                        <span>Confirm</span>
                    </ButtonBase>
                    {/* <ButtonBase
                        onClick={handleOldAddress}
                        className={classes.primaryButtonRed}
                    >
                        <span>No, use address I entered</span>
                    </ButtonBase> */}
                </DialogContent>
            </Dialog>
        </>
    );
}
