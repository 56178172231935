import React from 'react';
import {
    Typography,
    makeStyles,
    ButtonBase,
    Dialog,
    Box,
    Button,
} from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
    paper: {
        background: 'transparent',
    },
    container: {
        backgroundImage:
            "url('../../assets/icons/recycleFirstVisitBg.svg') !important",
        background: '#000000D9',
    },
    nextButton: {
        textTransform: 'none',
        minHeight: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    imageContainer: {
        position: 'relative',
        margin: '0 auto',
        backgroundImage:
            "url('../../assets/icons/OvalForFirstVisitRecycle.png')",
        width: '107px',
        height: '72px',
        background: '#ffffff',
        borderRadius: '45%',
    },
    recycleOvalIm: {
        padding: '10px 13px',
    },
    recyclelImg: {
        padding: '10px 13px',
    },
    boxContainer: {
        height: '367px',
        display: 'flex',
        flexDirection: 'column',
        padding: '76px 26px 37px',
        background: '#ffffff',
        borderRadius: '15px',
    },
    headerAlign: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        textAlign: 'center',
        width: '16.93rem',
    },
    footerAlign: {
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        textAlign: 'center',
        marginTop: '56px',
        width: '16.93rem',
    },
    title: {
        fontWeight: 700,
        fontSize: '21px',
    },
    subTitle: {
        fontWeight: 400,
        fontSize: '16px',
    },
    description: {
        fontWeight: 400,
        fontSize: '21px',
        lineHeight: '25px',
        letterSpacing: '-0.3375000059604645px',
    },
    rewardsSummaryIconWrapper: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '6rem',
        height: '6rem',
        margin: '0 auto',
        marginBottom: '-4.5rem',
        zIndex: 99999,
    },
});

const FirstVisitRecycleCoupon = ({
    open,
    handleClose,
    firstVisitRecycleCoupon,
}: {
    open: boolean;
    handleClose: () => void;
    firstVisitRecycleCoupon: any;
}) => {
    const classes = useStyles();
    const { history } = useRouter();
    const navigateGoCoupons = () => {
        history.push(ROUTES.rewardsWallet);
        handleClose();
    };
    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="list-create-edit-dialog-title"
            classes={{
                paper: classes.paper,
            }}
            className={classes.container}
        >
            <Box className={classes.rewardsSummaryIconWrapper}>
                <img
                    className={classes.recyclelImg}
                    src={require('../../assets/icons/recycle-frame.svg')}
                    alt="recycle-frame"
                />
            </Box>

            <Box display="flex" className={classes.boxContainer}>
                <div className={classes.headerAlign}>
                    <Typography className={classes.title}>
                        Thank you for recycling!
                    </Typography>
                    <Typography className={classes.subTitle}>
                        You have completed your first recycling visit.
                    </Typography>
                </div>
                <div className={classes.footerAlign}>
                    <Typography className={classes.description}>
                        {firstVisitRecycleCoupon?.couponInfo?.descriptionText1}
                    </Typography>
                    <Button
                        onClick={navigateGoCoupons}
                        fullWidth
                        id="classroom-enrollment-success-finish-button"
                        variant="contained"
                        classes={{
                            root: classes.nextButton,
                        }}
                    >
                        Go to coupons
                    </Button>
                </div>
            </Box>
        </Dialog>
    );
};
export default FirstVisitRecycleCoupon;
