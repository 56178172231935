import React from 'react';
import IconInterface from './IconInterface';


function IconWeeklyAd({ width = 40, height = 40 }: IconInterface) {
    return (
        <svg width={width} height={height} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5 40C15.1571 40 10.1357 37.9214 6.35714 34.1429C2.57857 30.3643 0.5 25.3429 0.5 20C0.5 14.6571 2.57857 9.63571 6.35714 5.85714C10.1357 2.07857 15.1571 0 20.5 0C25.8429 0 30.8643 2.07857 34.6429 5.85714C38.4214 9.63571 40.5 14.6571 40.5 20C40.5 25.3429 38.4214 30.3643 34.6429 34.1429C30.8643 37.9214 25.8429 40 20.5 40ZM20.5 1.42857C15.5357 1.42857 10.8786 3.36429 7.37143 6.87143C3.86429 10.3786 1.92857 15.0357 1.92857 20C1.92857 24.9643 3.86429 29.6214 7.37143 33.1286C10.8786 36.6357 15.5429 38.5714 20.5 38.5714C25.4571 38.5714 30.1214 36.6357 33.6286 33.1286C37.1357 29.6214 39.0714 24.9571 39.0714 20C39.0714 15.0429 37.1357 10.3786 33.6286 6.87143C30.1214 3.36429 25.4571 1.42857 20.5 1.42857Z" fill="#CC0000"/>
        <path d="M17.5072 33.05C17.3215 33.05 17.1429 32.9786 17 32.8429L7.65717 23.5C7.52146 23.3643 7.45003 23.1857 7.45003 22.9929C7.45003 22.8 7.5286 22.6214 7.65717 22.4857L19.2286 10.9214C19.3215 10.8286 19.4429 10.7572 19.5715 10.7286L27.3215 8.94287C27.5572 8.88573 27.8143 8.95716 27.9857 9.13573L31.3715 12.5214C31.5429 12.6929 31.6143 12.95 31.5643 13.1857L29.7786 20.9357C29.75 21.0643 29.6857 21.1857 29.5857 21.2786L18.0215 32.8429C17.8786 32.9857 17.7 33.05 17.5143 33.05H17.5072ZM9.17146 22.9929L17.5072 31.3286L28.4215 20.4143L30.0786 13.25L27.25 10.4214L20.0857 12.0786L9.17146 22.9929Z" fill="#CC0000"/>
        <path d="M25.8143 16.6214C25.3143 16.6214 24.8214 16.4357 24.4429 16.0571C23.6857 15.3 23.6857 14.0714 24.4429 13.3143C25.2 12.5571 26.4286 12.5571 27.1857 13.3143C27.9429 14.0714 27.9429 15.3 27.1857 16.0571C26.8071 16.4357 26.3143 16.6214 25.8143 16.6214ZM25.8143 14.1785C25.6857 14.1785 25.55 14.2285 25.4571 14.3285C25.2571 14.5285 25.2571 14.85 25.4571 15.05C25.6571 15.25 25.9786 15.25 26.1786 15.05C26.3786 14.85 26.3786 14.5285 26.1786 14.3285C26.0786 14.2285 25.95 14.1785 25.8214 14.1785H25.8143Z" fill="#CC0000"/>
        <path d="M17.9001 25.7C17.6001 25.7 17.3001 25.6428 17.0072 25.5214C15.7501 25.0143 14.7858 23.4857 14.7715 21.9785C14.7715 21.5857 15.0786 21.2571 15.4786 21.2571C15.8501 21.2714 16.1929 21.5714 16.2001 21.9643C16.2143 22.8857 16.8286 23.9071 17.5501 24.2C17.9429 24.3571 18.2786 24.2714 18.6215 23.9285C18.9858 23.5643 18.8715 23.1643 18.2929 22.0714C17.7858 21.1143 17.0929 19.8 18.1715 18.6785C18.8143 18.0143 19.7286 17.8 20.6215 18.1071C21.7786 18.5071 22.5858 19.6785 22.7286 21.15C22.7643 21.5428 22.4786 21.8928 22.0858 21.9285C21.7072 21.9643 21.3429 21.6785 21.3072 21.2857C21.2001 20.1928 20.6429 19.6214 20.1501 19.45C19.9429 19.3785 19.5429 19.3071 19.2001 19.6643C18.8929 19.9857 19.0215 20.3785 19.5572 21.4C20.0786 22.3928 20.8001 23.7571 19.6286 24.9285C19.1215 25.4357 18.5215 25.6928 17.9001 25.6928V25.7Z" fill="#CC0000"/>
        <path d="M15.3928 25.8999C15.2071 25.8999 15.0285 25.8285 14.8857 25.6928C14.6071 25.4142 14.6071 24.9642 14.8857 24.6857L15.8285 23.7428C16.1071 23.4642 16.5571 23.4642 16.8357 23.7428C17.1143 24.0214 17.1143 24.4714 16.8357 24.7499L15.8928 25.6928C15.75 25.8357 15.5714 25.8999 15.3857 25.8999H15.3928Z" fill="#CC0000"/>
        <path d="M21.2714 20.0214C21.0857 20.0214 20.9071 19.95 20.7643 19.8143C20.4857 19.5357 20.4857 19.0857 20.7643 18.8071L21.7071 17.8643C21.9857 17.5857 22.4357 17.5857 22.7143 17.8643C22.9928 18.1428 22.9928 18.5928 22.7143 18.8714L21.7714 19.8143C21.6285 19.9571 21.45 20.0214 21.2643 20.0214H21.2714Z" fill="#CC0000"/>
        </svg>
    );
}

export default IconWeeklyAd;