/* eslint-disable import/namespace */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Typography,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import useEventsReporter from 'src/hooks/useEventsReporter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useUserInfo from 'src/hooks/useUserInfo';
import COLORS from 'src/lib/colors';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import Carousel from 'src/components/Carousel';
import ScrollableRow from 'src/components/scrollable-row';
import PaginationDots from 'src/components/pagination-dots';
import { CACHE_ONLY } from 'src/lib/utils/react-query-utils';
import CouponsCard from './CouponsCard';

const useStyles = makeStyles({
    rewardLogo: {
        marginBottom: '15px',
        textAlign: 'center',
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '1rem',
    },
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        marginBottom: '7rem',
    },
    accordionWrapper: {
        background: COLORS.whiteSmoke,
        margin: '0px !important',
        borderTop: '1px solid #d9d9d6 !important',
    },
    accordionContainer: {
        background: '#E7E7E7',
        color: '#A8A8A8',
        textAlign: 'center',
        minHeight: '300px',
        margin: '0 auto',
        flexDirection: 'column',
    },
    helpText: {
        fontSize: '12px',
        textAlign: 'center',
    },
    tabTitle: {
        fontSize: '17px',
    },
    accordionEmptyText: {
        fontSize: '15px',
        textAlign: 'center',
        margin: 'auto',
    },
    accordionSummary: {
        minHeight: '50px !important',
        borderBottom: '1px solid #d9d9d6',
        padding: '0 24px',
    },
    accordionTab: {
        padding: '0 24px',
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        background: '#E7E7E7',
        paddingBottom: '20px',
    },
    backIcon: {
        fontSize: '1.7rem',
    },
    backIconHidden: {
        fontSize: '1.7rem',
        color: 'transparent',
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    backIconBalance: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
        color: 'transparent',
    },
    paginationWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        paddingTop: '.5rem',
    },
    addCouponsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '15px',
    },
    eligibility: {
        color: COLORS.brandCyan,
        marginLeft: 'auto',
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingTop: '0.5rem',
        cursor: 'pointer',
        display: 'flex',
        '& img': {
            marginRight: '5px',
        },
    },
});

function WalletTabs() {
    const classes = useStyles();
    const { userInfo, isFetchingUserInfo } = useUserInfo();
    const { customEvent } = useEventsReporter();
    const [staplesCouponList, setStaplesCouponList] = useState<any>([]);
    const [inkCouponList, setInkCouponList] = useState<any>([]);
    const [storeCouponList, setStoreCouponList] = useState<any>([]);
    const [serviceCouponList, setServiceCouponList] = useState<any>([]);
    const [inkActiveIndex, setInkActiveIndex] = React.useState(0);
    const [staplesRewardsActiveIndex, setStaplesRewardsActiveIndex] =
        React.useState(1);
    const [storeActiveIndex, setStoreActiveIndex] = React.useState(0);
    const [serviceActiveIndex, setServiceActiveIndex] = React.useState(0);
    const [expandIndexes, setExpandIndexes] = React.useState<any>([]);
    const { push: goto } = useHistory();
    const handleInkChangeIndex = (index: number) => {
        customEvent(eventMetricsMap.wallet_swipecoupon);
        setInkActiveIndex(index);
    };
    const handleStoreChangeIndex = (index: number) => {
        customEvent(eventMetricsMap.wallet_swipecoupon);
        setStoreActiveIndex(index);
    };
    const handleServiceChangeIndex = (index: number) => {
        customEvent(eventMetricsMap.wallet_swipecoupon);
        setServiceActiveIndex(index);
    };
    const handleStaplesRewardsChangeIndex = (index: number) => {
        customEvent(eventMetricsMap.wallet_swipecoupon);
        setStaplesRewardsActiveIndex(index);
    };
    const { data: staplesRewardsDetail } = useQuery(
        ['details', userInfo?.rewardsNumber],
        () => getStaplesRewardsData(userInfo?.rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );
    const handleExpandCollepse = (panel: string) => {
        let panelList = [];
        if (expandIndexes?.includes(panel)) {
            panelList = expandIndexes?.filter((x: string) => x !== panel);
        } else {
            panelList = [...expandIndexes, panel];
        }
        setExpandIndexes(panelList);
    };

    const goToCheckEligibility = () => {
        goto('/stores#Ink-recycle-summary-card');
    };

    useEffect(() => {
        if (staplesRewardsDetail?.data?.couponDataList) {
            const stapleCoupons: Array<string> = [];
            const storeCoupons: Array<string> = [];
            const serviceCoupons: Array<string> = [];
            const inkCoupons: Array<string> = [];
            let panelList: Array<string> = [];
            staplesRewardsDetail?.data?.couponDataList.forEach((el: any) => {
                if (
                    el.couponInfo.couponType === 'C' ||
                    el.couponInfo.couponType === 'M' ||
                    el.couponInfo.couponType === 'P'
                ) {
                    stapleCoupons.push(el);
                    panelList = [...expandIndexes, ...panelList, 'panel1'];
                }
                if (el.couponInfo.couponType === 'I') {
                    inkCoupons.push(el);
                    panelList = [...expandIndexes, ...panelList, 'panel2'];
                }
                if (
                    el.couponInfo.couponType === 'CP' ||
                    el.couponInfo.couponType === 'TC'
                ) {
                    serviceCoupons.push(el);
                    panelList = [...expandIndexes, ...panelList, 'panel4'];
                }
            });
            setExpandIndexes(panelList);
            setStaplesCouponList(stapleCoupons);
            setInkCouponList(inkCoupons);
            setStoreCouponList(storeCoupons);
            setServiceCouponList(serviceCoupons);
        }
    }, [staplesRewardsDetail?.data?.couponDataList?.length]);
    return (
        <div id="walletTab" className={classes.screen}>
            <div className={classes.contentWrapper}>
                <div style={{ height: '15px' }} />
                <div className={classes.rewardLogo}>
                    <img
                        src={require('../../assets/icons/StaplesRewardsLogo.svg')}
                        alt="rewardlogo"
                    />
                </div>
                <Typography className={classes.helpText}>
                    Note: Reward amount must be redeemed in full at time of
                    purchase
                </Typography>
            </div>
            <Accordion
                className={classes.accordionWrapper}
                expanded={expandIndexes?.includes('panel1')}
                onChange={() => handleExpandCollepse('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="staples-rewards-coupon-content"
                    id="staples-rewards-coupon-header"
                    className={classes.accordionSummary}
                >
                    <Typography className={classes.tabTitle}>
                        Staples Rewards
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionContainer}>
                    {staplesCouponList.length > 0 ? (
                        <Carousel
                            activeColor={COLORS.brandRed}
                            content={staplesCouponList?.map(
                                (item: any, index: number) => (
                                    <Box
                                        className={classes.addCouponsContainer}
                                    >
                                        <CouponsCard
                                            type="staplesRewards"
                                            sequenceId={
                                                (index + 1).toString()
                                                    .length === 1
                                                    ? `0${index + 1}`
                                                    : index + 1
                                            }
                                            key={index}
                                            couponData={item}
                                        />
                                    </Box>
                                )
                            )}
                            dots={staplesCouponList.length}
                            styles={{
                                containerStyle: {
                                    width: '100%',
                                    height: 'auto',
                                },
                            }}
                        />
                    ) : (
                        <Typography className={classes.accordionEmptyText}>
                            No Checks Available yet.
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion
                className={classes.accordionWrapper}
                expanded={expandIndexes?.includes('panel2')}
                onChange={() => handleExpandCollepse('panel2')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="ink-toner-coupon-content"
                    id="ink-toner-coupon-header"
                    className={classes.accordionTab}
                >
                    <Typography className={classes.tabTitle}>
                        Ink & Toner Recycling
                    </Typography>
                    {!inkCouponList.length ? (
                        <Typography
                            className={classes.eligibility}
                            onClick={() => goToCheckEligibility()}
                        >
                            <img
                                src={require('../../assets/icons/iconWarning.svg')}
                                alt="warning"
                            />
                            Check eligibility
                        </Typography>
                    ) : null}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionContainer}>
                    {inkCouponList.length > 0 ? (
                        <Carousel
                            activeColor={COLORS.brandRed}
                            content={inkCouponList?.map(
                                (item: any, index: number) => (
                                    <Box
                                        className={classes.addCouponsContainer}
                                    >
                                        <CouponsCard
                                            type="inkRewards"
                                            sequenceId={
                                                (index + 1).toString()
                                                    .length === 1
                                                    ? `0${index + 1}`
                                                    : index + 1
                                            }
                                            key={index}
                                            couponData={item}
                                        />
                                    </Box>
                                )
                            )}
                            dots={inkCouponList.length}
                            styles={{
                                containerStyle: {
                                    width: '100%',
                                    height: 'auto',
                                },
                            }}
                        />
                    ) : (
                        <Typography className={classes.accordionEmptyText}>
                            No Checks Available yet.
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>

            <Accordion
                className={classes.accordionWrapper}
                expanded={expandIndexes?.includes('panel4')}
                onChange={() => handleExpandCollepse('panel4')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="service-rewards-coupon-content"
                    id="service-rewards-coupon-header"
                    className={classes.accordionTab}
                >
                    <Typography className={classes.tabTitle}>
                        Service Rewards
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionContainer}>
                    {serviceCouponList.length > 0 ? (
                        <Carousel
                            activeColor={COLORS.brandRed}
                            content={serviceCouponList?.map(
                                (item: any, index: number) => (
                                    <Box
                                        className={classes.addCouponsContainer}
                                    >
                                        <CouponsCard
                                            type="serviceRewards"
                                            sequenceId={
                                                (index + 1).toString()
                                                    .length === 1
                                                    ? `0${index + 1}`
                                                    : index + 1
                                            }
                                            key={index}
                                            couponData={item}
                                        />
                                    </Box>
                                )
                            )}
                            dots={serviceCouponList.length}
                            styles={{
                                containerStyle: {
                                    width: '100%',
                                    height: 'auto',
                                },
                            }}
                        />
                    ) : (
                        <Typography className={classes.accordionEmptyText}>
                            No Checks Available yet.
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default WalletTabs;
