/* eslint-disable import/prefer-default-export */
import axiosInstance from 'src/lib/api-client/custom-axios';
import { AxiosResponse } from 'axios';
import {
    rewardsGetPurchaseHistory,
    rewardsGetTransactionDetails,
} from 'src/services/urls';

// TODO Removed hardcoded rewardsNumber
export const getPurchaseHistory = async (
    startDate: string,
    endDate: string,
    rewardsNumber: string,
    orderType: string,
    productType: string
): Promise<AxiosResponse<any>> =>
    axiosInstance.post(`${rewardsGetPurchaseHistory}`, {
        startDate,
        endDate,
        rewardsNumber,
        orderType,
        productType,
    });

export const getTransactionDetails = async (
    rewardsNumber: string,
    transactionNumber: number,
    storeNumber: number
): Promise<AxiosResponse<any>> =>
    axiosInstance.get(
        `${rewardsGetTransactionDetails}/${rewardsNumber}/${transactionNumber}/${storeNumber}`
    );
