import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { UserData } from 'react-oidc';
import { IAuthenticatorContext } from 'react-oidc/lib/makeAuth';
import ROUTES from 'src/lib/routes';
import {
    Box,
    ButtonBase,
    Divider,
    makeStyles,
    Typography,
    IconButton,
    Fade,
    Avatar,
    Button,
} from '@material-ui/core';
import {
    LOCAL_STORAGE_KEYS,
    saveToLocalStorage,
} from 'src/hooks/useLocalStorage';
import useAuthFromContext from 'src/lib/auth/use-auth-from-context';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import Create from '@material-ui/icons/Create';
import { InUserDataConsumer } from 'src/lib/types';
import useUserInfo from 'src/hooks/useUserInfo';
import AppLink from 'src/components/link';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import ProfileImage from 'src/components/profile-image';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingOverlay from 'src/components/loading-overlay';
import { RewardsAccountUserType, User } from 'src/services/types';
import { getUserAssociatedSchools } from 'src/lib/api-client/locations';
import InfoButton from 'src/components/info-button';
// TODO: STP-1310 reenable phone number
// import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import { useCachedSchools } from 'src/lib/utils/school-utils';
import { fullName } from 'src/lib/utils/user-utils';
import useRouter from 'src/hooks/useRouter';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import ScrollContainer from 'src/components/screen-scroll';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { Form, Formik, FormikHelpers, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import ValidationTextField from '../validation-text-field';

const helpCenterURL = 'https://www.staplesconnect.com/help';
const termsAndConditionsURL = 'https://www.staplesconnect.com/terms-conditions';
const privacyPolicyURL = 'https://www.staplesconnect.com/privacypolicy';
// eslint-disable-next-line max-len
const dontSellPersonalInfoURL =
    'https://submit-irm.trustarc.com/services/validation/f079d43b-9446-4b1a-8ee9-c2bc24658561';
const californiaNoticeURL = 'https://www.staplesconnect.com/californianotice';

const nameInfo =
    'To prevent fraud, please contact member services to change your name in your profile.';
const infoCtaText = 'Visit Help Center';
const schoolAssociationInfo =
    'Please visit the Help Center to contact member services to make changes to your claimed school.';
const infoButtonSize = '1.8rem';

const useStyles = makeStyles({
    mainContainer: {
        background: COLORS.whiteSmoke,
    },
    continueButton: {
        height: '2.6rem',
        borderRadius: '1.2rem',
        width: '92%',
        background: COLORS.fordGray,
        color: COLORS.secondaryNeutralCharcoal,
        textTransform: 'none',
        fontSize: '1.2rem',
        '&:hover': {
            backgroundColor: COLORS.fordGray,
        },
        '&:active': {
            backgroundColor: COLORS.fordGray,
        },
    },
    activeContinueBtn: {
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
    },
    nameAndRole: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: COLORS.primaryWhite,
    },
    profileImage: {
        height: '6.5rem',
        width: '6.5rem',
    },
    editIcon: {},
    inputWrapper: {
        marginBottom: '1.6rem',
        display: 'flex',
        alignItems: 'flex-end',
    },
    name: {
        fontSize: '1.3rem',
        marginTop: '0.6rem',
    },
    role: {
        lineHeight: '1.2rem',
        marginBottom: '2rem',
        textAlign: 'center',
    },
    helpInfo: {
        marginTop: '1rem',
        marginBottom: '2rem',
        textAlign: 'center',
        fontSize: '0.8rem',
    },
    profileContainer: {
        marginTop: '2rem',
        padding: '0.5rem',
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    topRowTitle: {
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        fontSize: '1.1rem',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '2rem 0',
        alignItems: 'center',
    },
    bottomButton: {
        color: COLORS.brandCyan,
        fontSize: '1.1rem',
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        marginTop: '1rem',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    appVersionText: {
        color: COLORS.fordGray,
        fontSize: '1rem',
        letterSpacing: '-0.6pt',
        marginTop: '1.2rem',
        display: 'flex',
        flexDirection: 'row',
    },
    appVersionTitle: {
        fontWeight: 600,
        paddingRight: '0.2rem',
    },
    associatedSchoolContainer: {
        marginTop: '1.5rem',
    },
    schoolDivider: {
        backgroundColor: COLORS.gray80,
        width: '100%',
    },
    backIcon: {
        fontSize: '1.3rem',
        color: COLORS.primaryWhite,
    },
    backButton: {
        left: '1rem',
        position: 'relative',
    },
});

type UserProfileProps = {
    context: IAuthenticatorContext;
};

/* const useCardRowStyles = makeStyles({
    row: {
        marginTop: '1.3rem',
    },
    titleAndInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        fontWeight: 500,
        fontSize: '1.1rem',
        letterSpacing: '-0.4pt',
    },
    content: {
        fontSize: '1.1rem',
        letterSpacing: '-0.4pt',
        lineHeight: '0.8rem',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
}); */

export function Confirm({ context }: UserProfileProps) {
    const router = useRouter();
    sessionStorage.setItem(
        LOCAL_STORAGE_KEYS.AUTH_REDIRECT,
        window.location.pathname
    );

    const classes = useStyles();
    const { userInfo, isFetchingUserInfo } = useUserInfo();

    const [formValid, setFormValid] = useState(false);
    const [pin, setPin] = useState('');

    function handlePinChange(e: React.ChangeEvent<HTMLInputElement>) {
        // We are wrapping this to omit type="number"
        // which causes formik to remove leading zeros
        // eslint-disable-next-line radix
        e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 6);
        setPin(e.target.value);
        if (e.target.value.length === 6) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }

    if (isFetchingUserInfo && !userInfo) {
        return <LoadingOverlay variant="wordmark" theme="light" open />;
    }

    const handleOnBackClick = () => {
        router.push(ROUTES.accountSecurity);
    };
    function onDoneButtonPressed() {
        if (/* isValid &&  */ formValid) {
            console.log(pin);
        }
    }

    return (
        <>
            <Box className={classes.mainContainer}>
                <NavTop
                    bgImageSrc={require('../../assets/bgs/bgRedUserProfile.svg')}
                    marginBottom="-15.5rem"
                    minHeight="18rem"
                />
                <Fade in>
                    <IconButton
                        onClick={handleOnBackClick}
                        size="small"
                        className={classes.backButton}
                    >
                        <ArrowBackIosRounded className={classes.backIcon} />
                    </IconButton>
                </Fade>
                <div className={classes.nameAndRole}>
                    <Avatar
                        src={require('../../assets/icons/iconPersonalData.png')}
                        className={classes.profileImage}
                        alt="profile"
                    />
                    <Typography
                        className={classes.name}
                        id="user-profile-name-text"
                    >
                        Let’s confirm it’s you
                    </Typography>
                    {/*  <Typography className={classes.name} id="user-profile-name-text">
                        {fullName(userInfo)}
                    </Typography>
                    <Typography className={classes.role} id="user-profile-user-type-text">
                    {userInfo?.email}
                    </Typography> */}
                </div>
                <FullWidthCard
                    cardContainerClassName={classes.profileContainer}
                    hasManualShadow
                >
                    {/* <form> */}
                    <Typography
                        className={classes.role}
                        id="user-profile-user-type-text"
                    >
                        We’ve sent a PIN # to your email address. Please enter
                        it below to verify your identity.
                    </Typography>
                    <div className={classes.inputWrapper}>
                        <ValidationTextField
                            fullWidth
                            id="list-name-input"
                            name="pin"
                            label="Enter PIN # here"
                            value={pin}
                            onChange={handlePinChange}
                            inputMode="numeric"
                            type="number"
                        />
                    </div>
                    <div className={classes.inputWrapper}>
                        <Button
                            type="submit"
                            id="zipcode-continue-button"
                            variant="contained"
                            className={`${classes.continueButton} ${
                                formValid ? classes.activeContinueBtn : ''
                            }`}
                            onClick={onDoneButtonPressed}
                        >
                            Verify
                        </Button>
                    </div>
                    <Typography
                        className={classes.helpInfo}
                        id="user-profile-user-type-text"
                    >
                        Didn’t recieve PIN # or it expired Send again
                    </Typography>

                    {/* </form> */}
                </FullWidthCard>
            </Box>
        </>
    );
}

export default (props: InUserDataConsumer<UserProfileProps>) => (
    <UserData.Consumer>
        {(context) => (
            <ScrollContainer>
                <Confirm {...props} context={context} />
            </ScrollContainer>
        )}
    </UserData.Consumer>
);
