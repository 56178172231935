import React from 'react';
import { useCreateImportListContext } from 'src/lib/contexts/create-import-list-context';
import ScreenWidth from 'src/components/screen-width';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import IndividualList from 'src/screens/lists/individual-list/individual-list';

type ViewItemsProps = {
  onBack: () => void;
};

/** Renders and animates the list of steps as they are added by the flow controller */
const ViewItems = (props: ViewItemsProps) => {
  const { onBack } = props;
  const { importedListDetails } = useCreateImportListContext();
  const { end: endImportListFlow } = useCreateImportListContext();
  const { notifyUserListUpdate } = useUserUpdateContext();

  function saveImportedList() {
    endImportListFlow();
    notifyUserListUpdate();
  }
  return (
    <ScreenWidth>
      {importedListDetails && (
        <IndividualList
          list={importedListDetails}
          onBack={onBack}
          onSave={saveImportedList}
          justCreated
          fromFlow="import"
        />
      )}
    </ScreenWidth>
  );
};

export default ViewItems;
