/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconPenEditVector(
    {
        height = 14,
        width = 14,
        fillColor = 'fillWhite',
    }: IconInterface
) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.17827 2.78098L8.20034 3.77929L1.42615 10.5297V12.5739H3.44652L10.2445 5.77589L11.2428 6.77419L4.04075 14H0V9.95925L7.17827 2.78098ZM11.2428 6.77419L7.17827 2.78098L9.55518 0.404075C9.82456 0.134692 10.1598 0 10.5609 0C10.962 0 11.3002 0.142615 11.5756 0.427844L13.5722 2.44822C13.8415 2.73345 13.9762 3.06995 13.9762 3.45774C13.9762 3.84552 13.8336 4.17455 13.5484 4.44482L11.2428 6.77419Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconPenEditVector;
