import React, { useEffect, useState } from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    makeStyles,
} from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { optimoveEvents } from 'src/screens/optimove';
import ROUTES from 'src/lib/routes';
import COLORS from 'src/lib/colors';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import {
    NavIconHome,
    NavIconStores,
    NavIconShop,
    NavIconUserProfile,
    NavIconCouponsAndRewards,
} from 'src/components/Icons';
import eventMetricsMap, {
    eventMetricsEventsType,
} from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';

export const NAV_BAR_HEIGHT = '85px';

const useStyles = makeStyles({
    mobileNavBar: {
        height: NAV_BAR_HEIGHT,
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        borderTop: 'solid #CCCCCC 1px',
    },
    navBar: {
        height: NAV_BAR_HEIGHT,
        margin: 'auto',
        position: 'sticky',
        bottom: '1rem',
        zIndex: 1,
        width: '100%',
        maxWidth: '30rem',
        borderRadius: '1rem',
        // boxShadow = Mui-paper3
        boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    navBarButton: {
        // minHeight: '3rem',
        alignItems: 'center',
        color: '#757575',
        height: '85px',
        paddingTop: '15px',
        '& .MuiBottomNavigationAction-label': {
            borderTop: 'none',
        },
    },
    mobileNavBarButton: {
        height: '85px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        color: '#757575',
        paddingLeft: '.15rem',
        paddingRight: '.15rem',
        minHeight: '100%',
        minWidth: 'unset',
        paddingTop: '15px !important',
        '& .MuiBottomNavigationAction-label': {
            borderTop: 'none',
        },
        '&.Mui-selected': {
            paddingTop: '15px !important',
        },
    },
    root: {
        '& .MuiBottomNavigationAction-label': {
            height: '30px',
            color: '#757575',
            fontSize: '12px',
        },
    },
    classroomRewardsNavButton: {
        marginTop: '1rem',
    },
    selected: {
        color: COLORS.textBlack,
        fontWeight: 700,
        fontSize: '0.75rem !important',
        boxShadow: 'inset 0px 4px 0px -1px #CC0000',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        '& .Mui-selected': {
            color: `${COLORS.textBlack}!important`,
            fontSize: '0.75rem !important',
        },
    },
    selectedPage: {
        borderTop: '3px solid #CC0000',
        color: COLORS.brandRed,
        fontWeight: 'bold',
        fontSize: '0.75rem !important',
    },
});
// eslint-disable no-nested-ternaries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function NavBottom() {
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const isMobileWidth = useIsMobileWidth();
    // detect root url location so initial highlighted button is correct
    const { location, push } = useRouter();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const { setNavBottomActiveTab } =
        useUserUpdateContext();
    const { fromPath, setFromPath } = useProductBrowseContext();

    function handleChange(event: any, newPath: any) {
        push(newPath);
        setNavBottomActiveTab(location.pathname);
        setFromPath(location.pathname);
    }

    function isCurrentPath(route: string) {
        return (
            location.pathname === route ||
            (location?.state as any)?.from === route
        );
    }

    const classes = useStyles();
    const actionButtonClasses = {
        root: isMobileWidth ? classes.mobileNavBarButton : classes.navBarButton,
    };

    function trackButtonTapEvent(
        eventType: eventMetricsEventsType,
        path: string
    ) {
        setCurrentPath(path);
        customEvent(eventType);
        analyticsCustomEvent(eventMetricsMap.navigation, {
            click_text: eventType,
            element_location: 'bottom navigation',
        });
        optimoveEvents(eventMetricsMap.scm_navigation, {
            click_text: eventType,
            element_location: 'bottom navigation',
        });
    }

    const handleShopTabClick = () => {
        if (ROUTES.shop !== fromPath) setFromPath(ROUTES.shop);
        const searchedQuery = localStorage.getItem('searchedQuery');
        if (searchedQuery && searchedQuery !== '') {
            push(`${ROUTES.productDetailsPage}?searchString=${searchedQuery}`, {
                from: ROUTES.shop,
            });
        } else {
            trackButtonTapEvent(eventMetricsMap.shop, ROUTES.shop);
        }
    };

    useEffect(() => {
        if ((location?.state as any)?.from) {
            setCurrentPath((location?.state as any)?.from);
        }
    }, [location?.state]);

    return (
        <BottomNavigation
            className={isMobileWidth ? classes.mobileNavBar : classes.navBar}
            showLabels
            value={currentPath}
            onChange={handleChange}
        >
            <BottomNavigationAction
                id="bottom-nav-home-button"
                classes={actionButtonClasses}
                className={`${isMobileWidth ? classes.root : ''} ${
                    isCurrentPath(ROUTES.home) ? classes.selected : ''
                }`}
                label="Home"
                value={ROUTES.home}
                selected={isCurrentPath(ROUTES.home)}
                icon={
                    <NavIconHome
                        fillColor={
                            isCurrentPath(ROUTES.home)
                                ? COLORS.brandRed
                                : '#757575'
                        }
                    />
                }
                onClick={() => {
                    trackButtonTapEvent(eventMetricsMap.home, ROUTES.home);
                    if (ROUTES.home !== fromPath) setFromPath(ROUTES.home);
                }}
            />
            <BottomNavigationAction
                id="bottom-nav-lists-button"
                classes={actionButtonClasses}
                className={`${isMobileWidth ? classes.root : ''} ${
                    isCurrentPath(ROUTES.shop) ? classes.selected : ''
                }`}
                label="Shop"
                value={ROUTES.shop}
                selected={isCurrentPath(ROUTES.shop)}
                icon={
                    <NavIconShop
                        fillColor={
                            isCurrentPath(ROUTES.shop)
                                ? COLORS.brandRed
                                : '#757575'
                        }
                    />
                }
                onClick={handleShopTabClick}
            />
            <BottomNavigationAction
                id="bottom-nav-profile-button"
                classes={actionButtonClasses}
                label="Coupons & Rewards"
                value={ROUTES.rewardsWallet}
                selected={isCurrentPath(ROUTES.rewardsWallet)}
                className={`
                    ${isMobileWidth ? classes.root : ''}
                    ${
                        isCurrentPath(ROUTES.rewardsWallet)
                            ? classes.selected
                            : ''
                    }
                `}
                icon={
                    <NavIconCouponsAndRewards
                        fillColor={
                            isCurrentPath(ROUTES.rewardsWallet)
                                ? COLORS.brandRed
                                : '#757575'
                        }
                    />
                }
                onClick={() => {
                    trackButtonTapEvent(
                        eventMetricsMap['coupons & rewards'],
                        ROUTES.rewardsWallet
                    );
                    if (ROUTES.rewardsWallet !== fromPath)
                        setFromPath(ROUTES.rewardsWallet);
                }}
            />

            <BottomNavigationAction
                id="bottom-nav-store-button"
                classes={actionButtonClasses}
                label="Stores"
                value={ROUTES.stores}
                selected={isCurrentPath(ROUTES.stores)}
                className={`
                    ${isMobileWidth ? classes.root : ''}
                    ${isCurrentPath(ROUTES.stores) ? classes.selected : ''}`}
                icon={
                    <NavIconStores
                        fillColor={
                            isCurrentPath(ROUTES.stores)
                                ? COLORS.brandRed
                                : '#757575'
                        }
                    />
                }
                onClick={() =>
                    trackButtonTapEvent(eventMetricsMap.stores, ROUTES.stores)
                }
            />

            <BottomNavigationAction
                id="bottom-nav-store-button"
                classes={actionButtonClasses}
                label="Account"
                value={ROUTES.userProfile}
                selected={isCurrentPath(ROUTES.userProfile)}
                className={`${isMobileWidth ? classes.root : ''} 
                ${isCurrentPath(ROUTES.userProfile) ? classes.selected : ''}`}
                icon={
                    <NavIconUserProfile
                        fillColor={
                            isCurrentPath(ROUTES.userProfile)
                                ? COLORS.brandRed
                                : '#757575'
                        }
                    />
                }
                onClick={() => {
                    trackButtonTapEvent(
                        eventMetricsMap.account,
                        ROUTES.userProfile
                    );
                    if (ROUTES.userProfile !== fromPath)
                        setFromPath(ROUTES.userProfile);
                }}
            />
        </BottomNavigation>
    );
}
