/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect } from 'react';
import {
    Typography,
    makeStyles,
    Box,
    Slider,
    Tooltip,
    withStyles,
    ClickAwayListener,
    Button,
    TextField,
    ButtonBase,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { useQuery } from 'react-query';
import { Player } from '@lottiefiles/react-lottie-player';
import NumberFormat from 'react-number-format';
import {
    donateToEducator,
    donateToInstitution,
    DonationResponse,
    DonationResponseCode,
} from 'src/lib/api-client/rewards-donation';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingButton from 'src/components/loading-button';
import {
    CommonStepProps,
    DonateControllerState,
} from 'src/screens/donation-flow/types';
import { fmtDollarsAndCents, isBlank } from 'src/lib/utils/string-utils';
import useUserInfo from 'src/hooks/useUserInfo';
import useRouter from 'src/hooks/useRouter';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import DonationErrorDialog from 'src/screens/donation-flow/donation-error-dialog';
import DonationExceedDialog from 'src/screens/donation-flow/donation-exceed-dialog';
import { useFirebaseMessagingContext } from 'src/lib/contexts/firebase-messaging-context';

const useStyles = makeStyles({
    mainCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
    },
    title: {
        color: COLORS.secondaryNeutralBlack,
        fontSize: '1.40rem',
        textAlign: 'center',
        marginTop: '5rem',
        padding: '0 2rem',
        lineHeight: '1.6rem',
        letterSpacing: '-0.5pt',
    },
    availableAmount: {
        fontWeight: 'bold',
    },
    givingAmountWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontWeight: 'bold',
        marginTop: '3rem',
        letterSpacing: '-0.6pt',
    },
    amountTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        justifyContent: 'center',
    },
    enterAmountText: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold',
        marginTop: '1rem',
        letterSpacing: '-0.3pt',
        color: COLORS.brandCyan,
        fontSize: '1rem',
    },
    amountSign: {
        fontSize: '2rem',
    },
    amount: {
        fontSize: '5.2rem',
        marginTop: '-1.5rem',
    },
    decimalAmount: {
        fontSize: '2.1rem',
        marginTop: '-0.2rem',
    },
    rewardsSummaryIcon: {
        alignSelf: 'center',
        marginTop: '3.8rem',
    },
    rewardsSummaryIconWrapper: {
        marginBottom: '-5rem',
    },
    sliderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    giveNowButton: {
        width: '72%',
        marginBottom: '3rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: COLORS.disabledBackgroundGray,
            color: COLORS.disabledTextGray,
        },
    },
    amountForm: {
        margin: '2rem 0',
        padding: '0 0.8rem',
    },
    gotItButton: {
        textTransform: 'inherit',
        color: COLORS.brandCyan,
        padding: 0,
    },
});

const InputTextField = withStyles({
    root: {
        borderWidth: '2px',
        '& label.Mui-focused, & label.Mui-error': {
            color: COLORS.secondaryNeutralCharcoal,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: COLORS.secondaryNeutralBlack,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: COLORS.secondaryNeutralBlack,
        },
    },
})(TextField);

const CustomizedSlider = withStyles({
    root: {
        width: '80%',
        color: COLORS.fordGray,
        height: 8,
    },
    thumb: {
        height: 25,
        width: 25,
        backgroundColor: COLORS.primaryWhite,
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
    },
    track: {
        height: 12,
        borderRadius: 4,
        color: COLORS.rewardsYellow,
        marginTop: -1.4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const HtmlTooltip = withStyles(() => ({
    tooltipPlacementTop: {
        marginBottom: '0.2rem',
    },
    tooltip: {
        fontSize: '0.8rem',
        letterSpacing: '-0.4pt',
        color: COLORS.secondaryNeutralBlack,
        boxShadow: '0.01rem 0.01rem 2.1rem 1rem rgba(0, 0, 0, 0.1)',
        backgroundColor: COLORS.primaryWhite,
        textAlign: 'center',
        borderRadius: '0.8rem',
        maxWidth: '5.6rem',
        padding: '0.4rem',
        lineHeight: '1rem',
        '& .MuiTooltip-arrow': {
            color: COLORS.primaryWhite,
            left: '0.8rem !important',
        },
    },
}))(Tooltip);

const CustomThumbComponent = (props: any) => (
    <div {...props}>
        <img
            src={require('../../assets/icons/sliderDot.svg')}
            alt="sliderDot"
        />
    </div>
);

export default function DonationSetAmount({
    updateControllerValues,
    handleGoBack,
    setEnterAmount,
}: CommonStepProps) {
    const { userInfo } = useUserInfo();
    const classes = useStyles();
    const playerRef = useRef<Player>(null);
    const [giveAmountView, setGiveAmountView] = useState('slider');
    const [tooltipOpen, setTooltipOpen] = useState(true);
    const [sliderValue, setSliderValue] = useState<number>(0);
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const [inputValueChanged, setInputValueChanged] = useState(false);
    const [donationResponseCode, setDonationResponseCode] = useState<
        DonationResponseCode | null | 'Unknown'
    >();
    const availableAmount =
        (userInfo?.rewardsDetails?.availableDonationAmount || 0) / 100;
    const sliderMinValue = 0;
    let sliderStepSize = 0.5;

    if (availableAmount >= 5) {
        sliderStepSize = 1;
    }

    const { query } = useRouter();

    // Floating point numbers are weird
    // https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
    // https://stackoverflow.com/questions/14490496/how-to-multiply-in-javascript-problems-with-decimals
    const originalValue = sliderValue * 100;
    const amountInCents = parseInt(originalValue.toPrecision(12), 10);
    const { subscribe } = useFirebaseMessagingContext();
    const [openDonationExceedDialog, setDonationExceedDialog] = useState(false);
    const [donationExceedDialogMessage, setDonationExceedDialogMessage] =
        useState('');
    const {
        recipientType,
        recipientId,
        recipientName,
        recipientSchool,
        recipientAddress,
    } = query as DonateControllerState;
    const { isFetching, refetch: postDonation } = useQuery(
        ['donateAmount'],
        async () =>
            recipientType === 'school'
                ? donateToInstitution(recipientId as string, amountInCents)
                : donateToEducator(recipientId as string, amountInCents),
        {
            // only call the api if donatingCents has been set
            enabled: false, // will only be called when refetch is called manually
            retry: false,
            onSettled: (res: DonationResponse | null | undefined) => {
                if (res?.isSuccess) {
                    setSliderValue(0);
                    customEvent(eventMetricsMap.give_success);
                    customEvent(
                        eventMetricsMap.wallet_classroomrewards_givesuccess
                    );
                    updateControllerValues({
                        donateStep: 'success',
                        donateAmount: `${sliderValue}`,
                        recipientType,
                        recipientName,
                        recipientSchool,
                        recipientAddress,
                    });
                    subscribe();
                } else if (res?.isSuccess === false) {
                    const msg = res?.errorMessage || '';
                    setDonationExceedDialog(true);
                    setDonationExceedDialogMessage(msg);
                } else {
                    setDonationResponseCode(res?.code || 'Unknown');
                }
            },
        }
    );
    useEffect(() => {
        if (giveAmountView === 'slider') {
            setEnterAmount(false);
        } else {
            setEnterAmount(true);
        }
    }, [giveAmountView]);
    const handleDonationExceedDialog = () => {
        setDonationExceedDialog(!openDonationExceedDialog);
        setDonationExceedDialogMessage('');
    };
    const { dollars: givingDollars, cents: givingCents } = fmtDollarsAndCents(
        (sliderValue as number) * 100
    );

    const handleCloseMaxContributionDialog = () => {
        setDonationResponseCode(null);
    };

    const handleGiveToAnother = () => {
        setDonationResponseCode(null);
        if (handleGoBack) {
            handleGoBack();
        }
    };

    const handleFormSubmit = () => {
        if (amountInCents) {
            analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
                click_text: 'give now - success',
                element_location: 'donate',
                donation_amount: sliderValue,
            });
            optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
                click_text: 'give now - success',
                element_location: 'donate',
                donation_amount: sliderValue,
            });
            postDonation();
        }
    };

    const triggerAnimation = (value: number) => {
        if (playerRef.current) {
            const animationPercent = (value / availableAmount) * 100;
            playerRef.current.setSeeker(animationPercent);
        }
    };

    const handleSliderChange = (event: any, newValue: any) => {
        triggerAnimation(newValue);
        setSliderValue(newValue);
        setTooltipOpen(false);
    };

    const handleKeyPress = (event: any) => {
        if (event.charCode === 13) {
            event.preventDefault();
        }
    };

    const handleInputChange = (event: any) => {
        setInputValueChanged(true);
        if (event.target.value > availableAmount) {
            return;
        }

        triggerAnimation(parseInt(event.target.value, 10));
        setSliderValue(event.target.value);
    };

    const withValueLimit = (inputObj: any) => {
        const { value } = inputObj;
        if (value <= availableAmount) return inputObj;
    };

    return (
        <Box>
            <Box className={classes.rewardsSummaryIconWrapper}>
                <Player
                    ref={playerRef}
                    autoplay={false}
                    controls
                    src={require('../../assets/lotties/giving-heart-size.json')}
                    style={{ height: '7.5rem', width: '7.5rem' }}
                />
            </Box>
            <FullWidthCard
                hasManualShadow
                className={classes.mainCard}
                id="giving-amount-card"
            >
                <Typography
                    className={classes.title}
                    id="giving-amount-max-amount-text"
                >
                    You have{' '}
                    <span className={classes.availableAmount}>
                        ${availableAmount.toFixed(2)}
                    </span>{' '}
                    available to give.
                </Typography>
                {giveAmountView === 'slider' ? (
                    <>
                        <Typography
                            className={classes.givingAmountWrapper}
                            id="giving-amount-selected-amount-text"
                        >
                            <span className={classes.amountSign}>$</span>
                            <span className={classes.amount}>
                                {givingDollars}
                            </span>
                            <span className={classes.decimalAmount}>
                                {givingCents}
                            </span>
                        </Typography>
                        <div className={classes.sliderWrapper}>
                            <ClickAwayListener
                                onClickAway={() => setTooltipOpen(false)}
                            >
                                <HtmlTooltip
                                    placement="top-start"
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={() => setTooltipOpen(false)}
                                    open={tooltipOpen}
                                    title={
                                        <>
                                            <span id="giving-amount-tooltip-text">
                                                Slide button to choose amount.
                                            </span>
                                            <br />
                                            <Button
                                                onClick={() =>
                                                    setTooltipOpen(false)
                                                }
                                                classes={{
                                                    root: classes.gotItButton,
                                                }}
                                                id="giving-amount-tooltip-link"
                                            >
                                                Got it!
                                            </Button>
                                        </>
                                    }
                                    arrow
                                >
                                    <CustomizedSlider
                                        id="giving-amount-selected-amount-slider"
                                        onChange={(e, newValue) =>
                                            handleSliderChange(e, newValue)
                                        }
                                        step={sliderStepSize}
                                        max={availableAmount}
                                        ThumbComponent={CustomThumbComponent}
                                        getAriaLabel={(index) =>
                                            index === 0
                                                ? 'Minimum price'
                                                : 'Maximum price'
                                        }
                                        defaultValue={sliderMinValue}
                                        value={sliderValue}
                                    />
                                </HtmlTooltip>
                            </ClickAwayListener>
                        </div>
                        <div className={classes.amountTextWrapper}>
                            <ButtonBase
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.classroom_rewards,
                                        {
                                            click_text: 'enter amount manually',
                                            element_location: 'donate',
                                        }
                                    );
                                    optimoveEvents(
                                        eventMetricsMap.scm_classroom_rewards,
                                        {
                                            click_text: 'enter amount manually',
                                            element_location: 'donate',
                                        }
                                    );
                                    setGiveAmountView('manual');
                                }}
                                className={classes.enterAmountText}
                                id="giving-amount-manually-enter-link"
                            >
                                Enter amount manually
                            </ButtonBase>
                        </div>
                    </>
                ) : (
                    <>
                        <form className={classes.amountForm} autoComplete="off">
                            <NumberFormat
                                placeholder="Donation amount"
                                customInput={InputTextField}
                                id="giving-amount-manual-amount-input"
                                name="sliderValue"
                                value={
                                    inputValueChanged
                                        ? (sliderValue as number)
                                        : ''
                                }
                                fullWidth
                                decimalScale={2}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                isAllowed={withValueLimit}
                                onValueChange={({ value: v }) =>
                                    handleInputChange({ target: { value: v } })
                                }
                            />
                        </form>
                        <div className={classes.amountTextWrapper}>
                            <ButtonBase
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.classroom_rewards,
                                        {
                                            click_text: 'select amount',
                                            element_location: 'donate',
                                        }
                                    );
                                    optimoveEvents(
                                        eventMetricsMap.scm_classroom_rewards,
                                        {
                                            click_text: 'select amount',
                                            element_location: 'donate',
                                        }
                                    );
                                    setGiveAmountView('slider');
                                }}
                                className={classes.enterAmountText}
                                id="giving-amount-manual-entry-select-link"
                            >
                                Select Amount
                            </ButtonBase>
                        </div>
                    </>
                )}
            </FullWidthCard>
            <Box className={classes.buttonWrapper}>
                <LoadingButton
                    loading={isFetching}
                    id="giving-amount-give-now-button"
                    variant="contained"
                    classes={{
                        root: classes.giveNowButton,
                    }}
                    type="submit"
                    disabled={
                        !(Number(sliderValue) > 0) ||
                        giveAmountView === 'manual'
                    }
                    onClick={handleFormSubmit}
                >
                    Give now
                </LoadingButton>
            </Box>
            <DonationErrorDialog
                open={!isBlank(donationResponseCode)}
                handleClose={handleCloseMaxContributionDialog}
                handleGiveToAnother={handleGiveToAnother}
                recipientName={recipientName || ''}
                errorType={donationResponseCode}
            />
            <DonationExceedDialog
                open={openDonationExceedDialog}
                onClose={handleDonationExceedDialog}
                message={donationExceedDialogMessage}
            />
        </Box>
    );
}
