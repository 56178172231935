 import React from 'react';
import IconInterface from './IconInterface';
 
 
function IconDollarCoin({ width = 40, height = 40 }: IconInterface) {
    return (
        <svg width={width} height={height} viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.5 70C16.25 70 0.5 54.25 0.5 35C0.5 15.75 16.25 0 35.5 0C54.75 0 70.5 15.75 70.5 35C70.5 54.25 54.75 70 35.5 70ZM35.5 2.5C17.625 2.5 3 17.125 3 35C3 52.875 17.625 67.5 35.5 67.5C53.375 67.5 68 52.875 68 35C68 17.125 53.375 2.5 35.5 2.5Z" fill="#CC0000"/>
            <path d="M35.875 49C32.375 49 28.625 47.6249 26 44.9999C25.5 44.4999 25.5 43.7499 26 43.2499C26.5 42.7499 27.25 42.7499 27.75 43.2499C30.625 46.1249 35.6249 47.25 38.8749 45.875C40.8749 45 41.8749 43.375 41.8749 40.875C41.8749 37.75 39.2499 36.75 34.9999 35.375C31.1249 34.125 26.75 32.75 26.625 28C26.625 25.125 28.125 22.4999 30.875 21.2499C33.75 19.8749 38.4999 19.75 43.1249 23.625C43.6249 24.125 43.7499 24.875 43.2499 25.375C42.7499 25.875 42 26 41.5 25.5C37.75 22.375 34.1249 22.5 31.9999 23.5C30.1249 24.375 29.1249 26 29.2499 28C29.2499 30.75 31.75 31.75 35.875 33C39.75 34.25 44.4999 35.625 44.4999 40.875C44.4999 44.25 42.8749 46.8749 39.8749 48.1249C38.7499 48.7499 37.375 49 35.875 49Z" fill="#CC0000"/>
            <path d="M35.375 54.25C34.625 54.25 34.125 53.75 34.125 53V48C34.125 47.25 34.625 46.75 35.375 46.75C36.125 46.75 36.625 47.25 36.625 48V53C36.625 53.625 36 54.25 35.375 54.25Z" fill="#CC0000"/>
            <path d="M35.375 22.7501C34.625 22.7501 34.125 22.2501 34.125 21.5001V16.5001C34.125 15.7501 34.625 15.2501 35.375 15.2501C36.125 15.2501 36.625 15.7501 36.625 16.5001V21.5001C36.625 22.2501 36 22.7501 35.375 22.7501Z" fill="#CC0000"/>
        </svg>

    );
}
 
export default IconDollarCoin;