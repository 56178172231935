import React, { useEffect, useState } from 'react';
import {
    Typography,
    makeStyles,
    ButtonBase,
    Box,
    Container,
} from '@material-ui/core';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import FullWidthCard from 'src/components/cards/full-width-card';
import useRouter from 'src/hooks/useRouter';
import { useQuery } from 'react-query';
import {
    combineFavorites,
    FullDonationRecipient,
} from 'src/lib/utils/favorite-utils';
import FavoriteButton from 'src/components/favorites-button';
import { getFavorites } from 'src/lib/api-client/favorites';
import { makeSearchString } from 'src/lib/utils/url-utils';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        background: COLORS.rewardsYellow,
        minHeight: '100vh',
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backIcon: {
        fontSize: '1.7rem',
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    closeIcon: {
        width: '2rem',
        height: '2rem',
        margin: '2rem 1.25rem 1.5rem 0rem',
        fontSize: '1.7rem',
        position: 'absolute',
        left: 0,
    },
    backIconBalance: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
        color: 'transparent',
    },
    mainCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
    },
    paginationWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        paddingTop: '.5rem',
    },
    favoritesRows: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 2,
        margin: '0rem .5rem',
        height: 'auto',
        overflowY: 'scroll',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    screenDescription: {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 600,
        padding: '0rem 4rem 1rem',
        letterSpacing: '-0.30pt',
        lineHeight: '1.3rem',
    },
    favButton: {
        alignSelf: 'center',
        backgroundColor: 'black',
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '85%',
        fontWeight: 500,
        margin: '1.75rem',
        fontSize: '0.95rem',
        letterSpacing: '0',
    },
});

const useFavoriteRowStyles = makeStyles({
    row: {
        width: '100%',
        display: 'flex',
        flexShrink: 0,
        padding: '1rem, 1rem, 0rem, 1rem',
        minHeight: '3.5rem',
        marginRight: '-1rem',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    leftWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '16px',
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        display: 'flex',
        flexGrow: 1,
    },
    address: {
        fontSize: '1rem',
        display: 'flex',
        flexGrow: 1,
    },
    rightWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 1,
        padding: '0px 0px 0px 0px !important',
        marginLeft: '16px',
        justifyContent: 'space-between',
        paddingBottom: '1rem',
        borderBottom: `1px solid ${COLORS.secondaryNeutralLightGray}`,
    },
    favoriteButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        height: '1rem',
        padding: '0rem',
        visibility: 'hidden',
    },
    giveFavoriteText: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        fontSize: '1rem',
        textAlign: 'center',
        marginBottom: '1rem',
        marginRight: '.5rem',
    },
    giveNowButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        height: '1rem',
        fontWeight: 'bold',
        padding: '0rem',
        paddingRight: '1rem',
    },
});

type FavoriteRowProps = {
    onGiveClicked: (f: FullDonationRecipient) => void;
    favorite: FullDonationRecipient;
    index: number;
};

function FavoriteRow({ index, favorite, onGiveClicked }: FavoriteRowProps) {
    const classes = useFavoriteRowStyles();
    const {
        firstName,
        lastName,
        id,
        schoolName,
        state,
        street,
        city,
        zip,
        type,
    } = favorite;
    const educatorFullName = `${firstName} ${lastName}`;

    function cardGiveClicked() {
        onGiveClicked(favorite);
    }
    return (
        <>
            <div className={classes.row} id={`favorite-${index}-row`}>
                <div className={classes.leftWrapper}>
                    <Typography
                        className={classes.name}
                        id={`favorite-${index}-name-text`}
                    >
                        {type === 'user' ? educatorFullName : schoolName}
                    </Typography>
                    <Typography
                        className={classes.address}
                        id={`favorite-${index}-address-street-text`}
                    >
                        {type === 'user' ? schoolName : street}
                    </Typography>
                    <Typography
                        className={classes.address}
                        id={`favorite-${index}-address-city-state-zip-text`}
                    >
                        {city}, {state} {zip}
                    </Typography>
                </div>
            </div>
            <div className={classes.rightWrapper}>
                <FavoriteButton favoriteId={id} index={index} />
                <Typography
                    className={classes.giveFavoriteText}
                    id={`give-to-favorite-${index}-cta`}
                    onClick={cardGiveClicked}
                >
                    Give Now
                </Typography>
            </div>
        </>
    );
}

type MyFavoritesProps = {
    closeMyFavorites: () => void;
};

const MyFavorites = (props: MyFavoritesProps) => {
    const { closeMyFavorites } = props;
    const classes = useStyles();
    const { pathname, history } = useRouter();
    const [favs, setFavs] = useState<FullDonationRecipient[]>([]);
    const { data: favorites, isFetching } = useQuery('favorites', getFavorites);

    function handleClose() {
        closeMyFavorites();
    }
    function onGiveClicked(recipient: FullDonationRecipient) {
        if (recipient?.id && recipient?.type) {
            history.push({
                pathname,
                search: makeSearchString({
                    donateStep: 'setAmount',
                    recipientType:
                        recipient.type === 'institution' ? 'school' : 'user',
                    recipientId: recipient.id,
                    recipientName: `${recipient.firstName} ${recipient.lastName}`,
                    recipientSchool: recipient.schoolName,
                    recipientAddress: `${recipient.city}, ${recipient.state}, ${recipient.zip}`,
                }),
            });
        } else {
            history.push({
                pathname,
                search: makeSearchString({ donateStep: 'search' }),
            });
        }
    }
    const handleAddFavorites = () => {
        history.push({
            pathname,
            search: makeSearchString({ donateStep: 'search' }),
        });
    };
    useEffect(() => {
        if (!isFetching && favorites) {
            const { favoriteEducators, favoriteInstitutions } = favorites;
            const favsCombined = combineFavorites(
                favoriteEducators,
                favoriteInstitutions
            );
            if (favsCombined.length > 0) {
                setFavs(favsCombined);
            } else {
                handleClose();
            }
        }
    }, [favorites, isFetching]);

    return (
        <Box className={classes.screen} id="my-favorites-screen">
            <NavTop
                minHeight="16rem"
                marginBottom="-12rem"
                bgImageSrc={require('../assets/bgs/bgIllustrationYellowbackground.svg')}
                bgColor={COLORS.rewardsYellow}
            >
                <Box className={classes.commonNav}>
                    <ButtonBase
                        onClick={handleClose}
                        className={classes.closeIcon}
                        id="my-favorites-close-button"
                    >
                        <img
                            src={require('../assets/icons/iconBack.svg')}
                            alt="close icon"
                        />
                    </ButtonBase>
                    <Typography className={classes.screenTitle}>
                        My Favorites
                    </Typography>
                </Box>
            </NavTop>
            <Container maxWidth="md">
                <FullWidthCard
                    hasManualShadow
                    noPadding
                    className={classes.mainCard}
                    id="donation-flow-search-results-card"
                >
                    <Box className={classes.favoritesRows}>
                        {(favs || []).map((f, index) => (
                            <FavoriteRow
                                key={f.id}
                                index={index}
                                favorite={f}
                                onGiveClicked={onGiveClicked}
                            />
                        ))}
                    </Box>
                </FullWidthCard>
                <ButtonBase
                    className={classes.favButton}
                    id="recycle-visit"
                    onClick={handleAddFavorites}
                >
                    Add more favorites
                </ButtonBase>
            </Container>
        </Box>
    );
};

export default MyFavorites;
