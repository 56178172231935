import React, { useState } from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import useUserInfo from 'src/hooks/useUserInfo';
import { createRewardsAccount } from 'src/lib/api-client/rewards-enrollment';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import FullWidthCard from 'src/components/cards/full-width-card';
import { CommonStepProps } from 'src/screens/rewards-enroll-flow/types';
import { RewardsAccountUserType } from 'src/services/types';
import RewardsEnrollConfirmSignUp from './rewards-confirm-screen-sign-up';

const useStyles = makeStyles({
    screen: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    userTypeCard: {
        marginTop: '1rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
        marginBottom: '2rem',
        justifyContent: 'center',
    },
    userTypeWrapper: {
        display: 'flex',
        flexFlow: 'row',
        padding: '1rem 0',
    },
    userTypeTitle: {
        color: COLORS.secondaryNeutralBlack,
        fontWeight: 'bold',
        fontSize: '1.10rem',
        textAlign: 'left',
        paddingLeft: '1rem',
    },
    userTypeIconWrapper: {
        alignSelf: 'center',
    },
    userTypeIcons: {
        width: '3.8rem',
    },
    userTypeDescription: {
        fontSize: '0.9rem',
        textAlign: 'left',
        marginTop: '0.2rem',
        letterSpacing: '-0.30pt',
        paddingLeft: '1rem',
        lineHeight: '1rem',
    },
});

type UserTypeOption = {
    userType: RewardsAccountUserType;
    title: string;
    image: string;
    description: string;
};

const userTypes: UserTypeOption[] = [
    {
        userType: 'Supporter',
        title: 'Parent or Supporter',
        image: require('../../assets/icons/iconClassroomrewardsSupporter.svg'),
        description: 'Parent, guardian or other generous supporter.',
    },
    {
        userType: 'Educator',
        title: 'Teacher or Educator',
        image: require('../../assets/icons/iconClassroomrewardsEducator.svg'),
        description:
            'Preschool, K-12 or post-secondary educational institution.',
    },
    {
        userType: 'Administrator',
        title: 'School Administrator',
        image: require('../../assets/icons/iconClassroomrewardsSchool.svg'),
        description:
            // eslint-disable-next-line max-len
            'You are responsible for making purchases on behalf of a Preschool, K-12 or post-secondary educational institution.',
    },
];

type RewardsEnrollProps = CommonStepProps;

export function RewardsEnrollStep1({
    updateControllerValues,
}: RewardsEnrollProps) {
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const [selectedRole, setSelectedRole] = useState<any>('');
    const { analyticsCustomEvent } = useEventsReporter();

    const navigateToEnrollNextStepForUserTypeWithRewardNumber = (
        userType: RewardsAccountUserType
    ) => {
        if (userType === 'Supporter') {
            createRewardsAccount({ userType }).then((data) => {
                if (data?.isSuccess) {
                    updateControllerValues({ enrollStep: 'success' });
                }
            });
        } else {
            createRewardsAccount({ userType }).then((data) => {
                if (data?.isSuccess) {
                    updateControllerValues({
                        enrollStep: 'selectSchool',
                        userType,
                    });
                }
            });
        }
    };

    function navigateToEnrollNextStep(userType: RewardsAccountUserType) {
        setSelectedRole('');
        if (userInfo?.rewardsNumber) {
            navigateToEnrollNextStepForUserTypeWithRewardNumber(userType);
        } else {
            updateControllerValues({ enrollStep: 'step2', userType });
        }
    }

    return (
        <Box className={classes.screen} id="user-type-selection-area">
            {userTypes.map((item, index) => (
                <FullWidthCard
                    hasManualShadow
                    className={classes.userTypeCard}
                    id={`classroom-rewards-user-type-card-${index}`}
                    key={item.title}
                    onClick={() => {
                        analyticsCustomEvent(
                            eventMetricsMap.classroom_rewards_enroll,
                            {
                                click_text: item.userType,
                                element_location: 'introduce yourself',
                            }
                        );
                        optimoveEvents(
                            eventMetricsMap.scm_classroom_rewards_enroll,
                            {
                                click_text: item.userType,
                                element_location: 'introduce yourself',
                            }
                        );
                        setSelectedRole(item.userType);
                    }}
                >
                    <Box className={classes.userTypeWrapper}>
                        <Box className={classes.userTypeIconWrapper}>
                            <img
                                className={classes.userTypeIcons}
                                src={item.image}
                                alt="rewards school icon"
                            />
                        </Box>
                        <Box>
                            <Typography
                                className={classes.userTypeTitle}
                                id={`classroom-rewards-user-type-card-${index}-title-text`}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                className={classes.userTypeDescription}
                                id={`classroom-rewards-user-type-card-${index}-description-text`}
                            >
                                {item.description}
                            </Typography>
                        </Box>
                    </Box>
                </FullWidthCard>
            ))}
            {selectedRole && (
                <RewardsEnrollConfirmSignUp
                    open={selectedRole}
                    selectedRole={selectedRole}
                    onConfirm={navigateToEnrollNextStep}
                    onBack={() => setSelectedRole('')}
                />
            )}
        </Box>
    );
}

export default RewardsEnrollStep1;
