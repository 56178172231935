import React, { useState } from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import COLORS from 'src/lib/colors';
import GetStartedWithClassroomRewards from 'src/components/get-started-with-classroom-rewards';
import ClassroomRewardsSummary from 'src/components/classroom-rewards-summary';
import RecentActivity from 'src/components/recent-activity';
import { fmtDollarsAndCents } from 'src/lib/utils/string-utils';
import {
    RewardsAccountUserType,
    RewardsDetails,
} from 'src/services/types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useDonationFlow from 'src/hooks/useDonationFlow';
import RewardsPromoCardDialog from 'src/components/rewards-promo-card-dialog';
import AllRecentActivityDialog from 'src/components/all-recent-activity-dialog/all-recent-activity-dialog';
import { format } from 'date-fns';
import InfoButton, { COMMON_INFO_TEXT } from 'src/components/info-button';
import AvailableToDonateCard from 'src/components/available-to-donate-card';
import GiveNowErrorDialog, {
    useGiveNowError,
} from 'src/components/give-now-error-dialog';
import useAllRecentActivity from 'src/hooks/useAllRecentActivity';
import usePromoCard from 'src/hooks/usePromoCard';
import ShareDialog from 'src/components/share-dialog';
import { getAvailablePayoutAmount } from 'src/lib/utils/account-utils';
import FavoritesActivity from 'src/components/favorites-activty';
import useRouter from 'src/hooks/useRouter';
import { makeSearchString } from 'src/lib/utils/url-utils';
import PullRefresh from 'src/components/pull-refresh';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import ROUTES from 'src/lib/routes';
import useUserInfo from 'src/hooks/useUserInfo';

const useStyles = makeStyles((theme) => ({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        justifyContent: 'center',
    },
    backToCommunityAmountWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        letterSpacing: '-0.6pt',
    },
    backToCommunityAmount: {
        fontSize: '2.8rem',
    },
    payoutAvailableAmount: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        letterSpacing: '-0.6pt',
    },
    expirationDisclaimer: {
        marginTop: '.5rem',
        marginBottom: '-1rem',
        display: 'flex',
    },
    expirationDisclaimerText: {
        fontWeight: 500,
        marginRight: '.5rem',
        color: COLORS.primaryWhite,
    },
    amountSign: {
        fontSize: '1.8rem',
        color: COLORS.primaryWhite,
    },
    amount: {
        fontSize: '4rem',
        color: COLORS.primaryWhite,
        [theme.breakpoints.down(520)]: {
            fontSize: '3.6rem',
        },
    },
    decimalAmount: {
        fontSize: '1.9rem',
        color: COLORS.primaryWhite,
    },
    backToCommunityDecimalAmount: {
        fontSize: '1.2rem',
        color: COLORS.primaryWhite,
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 500,
        marginTop: '-1rem',
        letterSpacing: '-0.6pt',
        color: COLORS.primaryWhite,
        [theme.breakpoints.down(520)]: {
            fontSize: '1.25rem',
        },
    },
    giveNowButton: {
        width: '75%',
        marginTop: '2rem',
        marginBottom: '8.5rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    sectionTitle: {
        fontWeight: 500,
        fontSize: '1.1rem',
        marginBottom: '.85rem',
        marginTop: '1.75rem',
        marginLeft: '1.5rem',
    },
    backToCommunityWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 0,
    },
    backToCommunityLeft: {
        flex: 3,
    },
    backToCommunityRight: {
        flex: 1,
    },
    backToCommunityText: {
        fontWeight: 500,
        fontSize: '1rem',
        letterSpacing: '-0.4pt',
        lineHeight: '1.4rem',
    },
    backToCommunityTextGiveNow: {
        fontWeight: 'bold',
        color: COLORS.brandCyan,
    },
    topNavContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        background: COLORS.brandRed,
        marginBottom: "-110px",
    },
    topNavSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

type RewardsEnrolledEducatorProps = {
    rewardsDetails?: RewardsDetails;
    userType?: RewardsAccountUserType;
    userName?: string;
    switchTab?: any;
};

function RewardsEnrolledEducator({
    rewardsDetails,
    userType,
    userName,
    switchTab,
}: RewardsEnrolledEducatorProps) {
    const classes = useStyles();
    const { history } = useRouter();
    const router = useRouter();
    const {
        openAllRecentActivity,
        closeAllRecentActivity,
        allRecentActivityOpen,
    } = useAllRecentActivity();
    const { openDonationFlow } = useDonationFlow();
    const { analyticsCustomEvent } = useEventsReporter();
    const { classroomWalletRewardsTab } = useUserUpdateContext();
    const { openPromoCard, closePromoCard, promoCardOpen } = usePromoCard();
    const { promoCard } = rewardsDetails || {};
    const promoCardExpiration = promoCard?.expiration;
    const expirationDate = promoCardExpiration
        ? new Date(promoCardExpiration)
        : new Date();

    const [showShareDialog, setShowShareDialog] = useState(false);
    const classRoomRewards = localStorage?.getItem('classRoomRewards') === 'true';
    const classRoomRewardsEnrolled = localStorage?.getItem('classRoomRewardsEnrolled') === 'true';
    const { userInfo } = useUserInfo();
    const isAmountZero = userInfo?.rewardsDetails?.availableDonationAmount;

    function openShareDialog() {
        setShowShareDialog(true);
    }

    function closeShareDialog() {
        setShowShareDialog(false);
    }

    const { variant, setVariant, handlePrimaryBtnClick, open, handleClose } =
        useGiveNowError(openShareDialog);

    const availablePayoutAmount = getAvailablePayoutAmount(rewardsDetails);

    const { dollars: payoutDollars, cents: payoutCents } = fmtDollarsAndCents(
        availablePayoutAmount
    );

    function payWithRewards() {
        if (availablePayoutAmount === 0) {
            setVariant('pay');
        } else {
            router.push(ROUTES.rewardsWallet);
            classroomWalletRewardsTab();
        }
    }
    function giveNow() {
        if (!rewardsDetails?.availableDonationAmount) {
            setVariant('give');
        } else {
            analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
                click_text: 'give now',
                element_location: 'top banner',
            });
            optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
                click_text: 'give now',
                element_location: 'top banner',
            });
            openDonationFlow();
        }
    }

    function addFavorites() {
        openDonationFlow();
    }

    function manageFavorites() {
        history.push({
            search: makeSearchString({ myFavorites: 'open' }),
        });
    }

    function seeAllRecentActivities() {
        openAllRecentActivity();
    }

    console.log(classRoomRewardsEnrolled, 'DSP')
    return (
        <PullRefresh className="hiddenScrollForPullToRefresh">
            <Box className={classes.screen}>
                <div className={classes.topNavContainer}>
                    {switchTab()}
                    <div className={classes.topNavSubContainer}>
                        <Typography className={classes.payoutAvailableAmount}>
                            <span className={classes.amountSign}>$</span>
                            <span className={classes.amount}>
                                <span id="amount-text">{payoutDollars}</span>
                                <sup className={classes.decimalAmount}>{payoutCents}</sup>
                            </span>
                        </Typography>
                        <Typography className={classes.screenTitle}>
                            Classroom Rewards available
                        </Typography>
                        {promoCardExpiration && (
                            <div className={classes.expirationDisclaimer}>
                                <Typography className={classes.expirationDisclaimerText}>
                                    Expires on {format(expirationDate, 'MM/dd/yy')}
                                </Typography>
                                <InfoButton tooltipTitle={COMMON_INFO_TEXT.expiration} />
                            </div>
                        )}
                        <Button
                            id="rewards-enrolled-pay-button"
                            variant="contained"
                            classes={{
                                root: classes.giveNowButton,
                            }}
                            onClick={payWithRewards}
                        >
                            Pay with Classroom Rewards
                        </Button>
                    </div>
                </div>
                <>
                    {(userType !== 'Supporter' && classRoomRewards) &&
                        <>
                            <ClassroomRewardsSummary
                                userType={userType}
                                rewardsSummaryData={rewardsDetails}
                            />
                            <Typography className={classes.sectionTitle}>
                                Give Back to Your Community
                            </Typography>
                            <AvailableToDonateCard
                                cardId="classroom-rewards-back-to-community-card"
                                amountId="classroom-rewards-back-to-community-amount-text"
                                buttonId="rewards-back-to-community-give-now-button"
                                availableDonationAmount={rewardsDetails?.availableDonationAmount}
                                onGiveNowPressed={giveNow}
                            />
                            <RecentActivity onSeeAllPressed={seeAllRecentActivities} />
                            <FavoritesActivity
                                onAddFavoritesPressed={giveNow}
                                onManageFavoritesPressed={manageFavorites}
                            />
                            <GetStartedWithClassroomRewards
                                userType={userType}
                                userConfirmState="Confirmed"
                                onGiveNowPressed={giveNow}
                                onSharePressed={openShareDialog}
                            />
                            <AllRecentActivityDialog
                                open={allRecentActivityOpen}
                                onClose={closeAllRecentActivity}
                            />
                            <RewardsPromoCardDialog
                                open={promoCardOpen}
                                onClose={closePromoCard}
                                variant={userType === 'Administrator' ? 'school' : 'teacher'}
                                cardOwner={userName}
                                amountAvailable={availablePayoutAmount}
                                expiration={rewardsDetails?.promoCard?.expiration}
                                cardNumber={rewardsDetails?.promoCard?.number}
                                pin={rewardsDetails?.promoCard?.pin}
                            />
                        </>
                    }
                    <GiveNowErrorDialog
                        open={open}
                        variant={variant}
                        handleClose={handleClose}
                        handlePrimaryBtnClick={classRoomRewardsEnrolled && (isAmountZero !== 0) ?
                            handlePrimaryBtnClick : handleClose}
                    />
                    <ShareDialog
                        onBasePage
                        open={showShareDialog}
                        onClose={closeShareDialog}
                        inviteType="general"
                    />
                </>

            </Box>
        </PullRefresh>
    );
}

export default RewardsEnrolledEducator;
