import React, { useEffect, useState } from 'react';
import {
    ButtonBase,
    Tooltip,
    makeStyles,
    withStyles,
    Typography,
    ClickAwayListener,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import COLORS from 'src/lib/colors';
import clsx from 'clsx';

const BaseStyledTooltip = withStyles(() => ({
    tooltip: {
        marginTop: '0.6rem',
        fontSize: '0.8rem',
        letterSpacing: '-0.4pt',
        boxShadow: '0.01rem 0.01rem 2.1rem 1rem rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        borderRadius: '10px',
        padding: '5px 28px 12px 28px',
        lineHeight: '1rem',
        left: '10px',
        maxWidth: '280px',
        '& .MuiTooltip-arrow': {
            overflow: 'unset',
            left: '85% !important',
        },
    },
    arrow: {
        fontSize: '1.5625rem',
        '&::before': {
            borderRadius: '0.19rem',
        },
    },
}))(Tooltip);

const PrimaryStyledTooltip = withStyles({
    tooltip: {
        color: COLORS.primaryWhite,
        backgroundColor: COLORS.secondaryNeutralBlack,
        '& .MuiTooltip-arrow': {
            color: COLORS.secondaryNeutralBlack,
        },
    },
    arrow: {
        color: COLORS.secondaryNeutralBlack,
    },
})(BaseStyledTooltip);

const SecondaryStyledTooltip = withStyles({
    tooltip: {
        color: COLORS.secondaryNeutralBlack,
        backgroundColor: COLORS.primaryWhite,
        '& .MuiTooltip-arrow': {
            color: COLORS.primaryWhite,
        },
        maxWidth: '15rem',
    },
    arrow: {
        color: COLORS.primaryWhite,
    },
})(BaseStyledTooltip);

const TertiaryStyledTooltip = withStyles({
    tooltip: {
        color: COLORS.primaryWhite,
        backgroundColor: COLORS.greyMetallic,
        '& .MuiTooltip-arrow': {
            color: COLORS.greyMetallic,
        },
    },
    arrow: {
        color: COLORS.greyMetallic,
    },
})(BaseStyledTooltip);

// ButtonBase is incompatible with withStyles
const useStyles = makeStyles(() => ({
    button: {
        '& img': {
            width: ({ buttonSize }: any) => buttonSize || '1rem',
            height: ({ buttonSize }: any) => buttonSize || '1rem',
        },
    },
    tooltipContent: {
        padding: '0rem',
    },
    tooltipText: {
        color: 'inherit',
        fontSize: '0.875rem',
        lineHeight: '0.9375rem',
        paddingTop: '.8rem',
        paddingBottom: '.5rem',
    },
    tooltipButton: {
        fontWeight: 'bold',
        color: COLORS.brandCyan,
    },
    bottomButton: {
        color: COLORS.brandCyan,
        marginTop: '1rem',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    whyText: {
        marginTop: '1.9rem',
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        fontSize: '1.1rem',
        textTransform: 'none',
        padding: 0,
        justifyContent: 'flex-start',
    },
}));

interface ariaDetailsTypes {
    tabIndexText?: number,
    ariaSelectedText?: boolean,
    ariaLabelText?: string,
    tabIndexButton?: number,
    ariaSelectedButton?: boolean,
    ariaLabelButton?: string,
}

/** Basic tooltip info display, primarily used for expiration date explanation */
const InfoButton = ({
    buttonId = 'tooltip-trigger-button',
    buttonType = 'icon',
    tooltipTitle = '',
    tooltipTitleStyle,
    tooltipPlacement = 'top-end',
    tooltipCta = 'Got it!',
    tooltipOpen = false,
    buttonSize = '1rem',
    urlLink = '',
    secondary = false,
    showButton = true,
    onTooltipClose,
    primaryTooltipOnDarkBackground = false,
    customizedIcon = '',
    id = '',
    tooltipClasses,
    tooltipButtonStyle,
    preventOverflowToottip = false,
    flipToolTip = false,
    ariaDetails,
}: {
    buttonId?: string;
    buttonType?: string;
    tooltipTitle?: string;
    tooltipTitleStyle?: any;
    tooltipButtonStyle?: any;
    tooltipPlacement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
    tooltipCta?: string;
    tooltipOpen?: boolean;
    buttonSize?: string;
    urlLink?: string;
    secondary?: boolean;
    showButton?: boolean;
    onTooltipClose?: () => void;
    primaryTooltipOnDarkBackground?: boolean;
    customizedIcon?: string;
    id?: string;
    tooltipClasses?: any;
    preventOverflowToottip?: boolean;
    flipToolTip?: boolean;
    ariaDetails?: ariaDetailsTypes,
}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(tooltipOpen);
    const { analyticsCustomEvent } = useEventsReporter();
    function handleTooltipOpen() {
        setIsTooltipOpen(true);
    }

    function handleTooltipClose() {
        if (onTooltipClose) {
            onTooltipClose();
        }
        setIsTooltipOpen(false);
    }
    function onButtonClick() {
        analyticsCustomEvent(eventMetricsMap.account_info, {
            click_text: 'Visit Help Center',
            element_location: 'user profile',
        });
        optimoveEvents(eventMetricsMap.scm_account_info, {
            click_text: 'Visit Help Center',
            element_location: 'user profile',
        });
        if (urlLink.length > 0) {
            window.open(urlLink, '_blank');
        }
        handleTooltipClose();
    }
    const classes = useStyles({ buttonSize });
    // we need to hide on resize due to a bug within tooltips
    // freezing over time
    useEffect(() => {
        function handleResize() {
            handleTooltipClose();
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const PrimaryTooltip = primaryTooltipOnDarkBackground
        ? TertiaryStyledTooltip
        : PrimaryStyledTooltip;
    const StyledTooltip = secondary ? SecondaryStyledTooltip : PrimaryTooltip;
    let tooltipIcon;
    if (customizedIcon) {
        tooltipIcon = <img src={customizedIcon} alt="more info" />;
    } else if (primaryTooltipOnDarkBackground) {
        tooltipIcon = (
            <img
                src={require('../assets/icons/iconQuestionSmallBlue.svg')}
                alt="more info"
            />
        );
    } else {
        tooltipIcon = (
            <img
                src={require('../assets/icons/iconQuestionSmall.svg')}
                alt="more info"
            />
        );
    }

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <StyledTooltip
                id={id}
                placement={tooltipPlacement}
                classes={tooltipClasses}
                open={isTooltipOpen}
                PopperProps={{
                    style: { pointerEvents: 'unset' },
                    popperOptions: {
                        modifiers: {
                            flip: { enabled: flipToolTip },
                            preventOverflow: {
                                enabled: preventOverflowToottip,
                            },
                        },
                    },
                }}
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                interactive={urlLink.length > 0}
                title={
                    <div className={classes.tooltipContent}>
                        <Typography
                            className={clsx(
                                tooltipTitleStyle,
                                classes.tooltipText
                            )}
                            id="tooltip-text"
                        >
                            <p  style={{margin: '0px'}}
                                tabIndex={ariaDetails?.tabIndexText}
                                aria-selected={ariaDetails?.ariaSelectedText} 
                                aria-label={ariaDetails?.ariaLabelText} >
                                {tooltipTitle}
                            </p>
                        </Typography>
                        {tooltipCta !== '' && (
                            <ButtonBase
                                id="tooltip-close-button"
                                className={clsx(
                                    tooltipButtonStyle,
                                    classes.tooltipButton
                                )}
                                onClick={onButtonClick}
                            >   
                                <p  style={{margin: '0px'}}
                                    tabIndex={ariaDetails?.tabIndexButton}
                                    aria-selected={ariaDetails?.ariaSelectedButton}
                                    aria-label={ariaDetails?.ariaLabelButton}
                                > 
                                    {tooltipCta}
                                </p>
                            </ButtonBase>
                        )}
                    </div>
                }
            >
                {showButton ? (
                    <ButtonBase
                        id={buttonId}
                        onClick={handleTooltipOpen}
                        className={
                            buttonType === 'text'
                                ? classes.whyText
                                : classes.button
                        }
                    >
                        {buttonType === 'text' ? 'Why?' : tooltipIcon}
                    </ButtonBase>
                ) : (
                    <ButtonBase
                        id={buttonId}
                        onClick={handleTooltipOpen}
                        className={
                            buttonType === 'text'
                                ? classes.whyText
                                : classes.button
                        }
                    />
                )}
            </StyledTooltip>
        </ClickAwayListener>
    );
};

export const COMMON_INFO_TEXT = {
    expiration:
        'Your balance will expire within 6 months of your last received contribution.',
    donationLimit:
        'Teachers and schools can receive up to $1000 in rewards over a 6 month period.',
    rewardsSignUpFirstName:
        'In order to pay with Classroom Rewards, your first and last name must match your photo ID.',
    rewardsSignUpPhone:
        'This is how you will find your rewards number when checking out at your local Staples store.',
    findYourSupplyList:
        // eslint-disable-next-line max-len
        'Find your child’s exact school supply list provided by their teacher. Then shop in-store with an in-app, checkable shopping list!',
};

export default InfoButton;
