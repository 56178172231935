/* eslint-disable max-len */
import React from 'react';

function IconInfoAlert() {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group">
                <path id="Vector" d="M36 71C55.3299 71 71 55.33 71 36C71 16.67 55.3299 1 36 1C16.67 1 1 16.67 1 36C1 55.33 16.67 71 36 71Z" stroke="#F09E00" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <g id="Group_2">
                    <path id="Vector_2" d="M33.226 16.6463L17.2037 44.3869C15.9722 46.5258 17.5148 49.1963 19.9778 49.1963H52.0093C54.4723 49.1963 56.0149 46.5258 54.7834 44.3869L38.7611 16.6463C37.5426 14.5074 34.4575 14.5074 33.226 16.6463Z" stroke="#F09E00" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_3" d="M34.4573 42.4945C34.4573 42.2871 34.4832 42.0927 34.561 41.8982C34.6388 41.7167 34.7425 41.5352 34.8981 41.3797C35.1962 41.0815 35.5851 40.926 35.987 40.926C36.2073 40.926 36.3888 40.9778 36.5962 41.0426C36.7777 41.1204 36.9592 41.2241 37.0888 41.3667C37.6592 41.963 37.6592 43 37.0888 43.5833C36.9462 43.7259 36.7777 43.8296 36.5962 43.9074C36.4147 43.9852 36.2073 44.0371 35.987 44.0371C35.5851 44.0371 35.1962 43.8815 34.8981 43.5833C34.7425 43.4278 34.6258 43.2594 34.561 43.0649C34.4962 42.8705 34.4573 42.6889 34.4573 42.4945Z" fill="#F09E00" />
                    <path id="Vector_4" d="M36 38.5666V25.6555" stroke="#F09E00" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </g>
            </g>
        </svg>
    );
}

export default IconInfoAlert;
