import React, { useEffect } from 'react';
import {
  Backdrop,
  Dialog,
  DialogProps,
  DialogContent,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Player } from '@lottiefiles/react-lottie-player';
import COLORS from 'src/lib/colors';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';

const WhiteBackdrop = withStyles({
  root: {
    background: COLORS.primaryWhite,
  },
})(Backdrop);

const BlackBackdrop = withStyles({
  root: {
    background: COLORS.homeBlack,
  },
})(Backdrop);

const TransparentWhiteBackDrop = withStyles({
  root: {
    background: COLORS.primaryWhite,
    opacity: '0.6 !important',
  },
})(Backdrop);

const TransparentBlackBackDrop = withStyles({
  root: {
    background: COLORS.homeBlack,
    opacity: '0.6 !important',
  },
})(Backdrop);

const useStyles = makeStyles({
  paper: {
    width: '100%',
    height: '100%',
    background: 'transparent',
    boxShadow: 'none',
  },
  content: {
    background: 'transparent',
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  lottieWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '13rem',
    maxHeight: '13rem',
  },
  lottie: {
    color: 'white',
    width: '50%',
  },
});

interface CustomDialogProps extends DialogProps {
  src?: string;
  variant?: 'heart' | 'wordmark';
  theme?: 'light' | 'dark' | 'transparent-light' | 'transparent-dark';
}

const getBackDrop = (
  theme?: 'light' | 'dark' | 'transparent-light' | 'transparent-dark'
) => {
  switch (theme) {
    case 'light':
      return WhiteBackdrop;
    case 'transparent-light':
      return TransparentWhiteBackDrop;
    case 'transparent-dark':
      return TransparentBlackBackDrop;
    default:
      return BlackBackdrop;
  }
};

/** Large loading overlay for big data loads and sweeping changes */
export default function LoadingOverlay(props: CustomDialogProps) {
  const classes = useStyles();
  const { variant, theme } = props;
  const { requestScreen, revokeScreenRequest } = useScreenOrderContext();

  const backdrop = getBackDrop(theme);
  const src =
    variant === 'heart'
      ? require('../assets/lotties/loadingHeart1.json')
      : require('../assets/lotties/stapleRevealWordmark.json');

  useEffect(() => {
    requestScreen(SCREEN_NAMES.LOADING_OVERLAY);
    return () => revokeScreenRequest(SCREEN_NAMES.LOADING_OVERLAY);
  }, []);
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      BackdropComponent={backdrop}
      {...props}
    >
      <DialogContent className={classes.content}>
        <div className={classes.lottieWrapper}>
          <Player autoplay loop src={src} className={classes.lottie} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
