import axiosInstance from './custom-axios';

export const getProtectionPlans = async (): Promise<any> => {
    try {
        const dataRes: any = await axiosInstance.get(
            `${process.env.REACT_APP_PROTECTION_PLAN_DETAILS}`
        );
        return dataRes?.data;
    } catch (e: any) {
        return e;
    }
}
export default getProtectionPlans;

