import { makeSearchString } from 'src/lib/utils/url-utils';
import useRouter from 'src/hooks/useRouter';

export default function usePromoCard() {
    const { history, pathname, query } = useRouter();
    function openPromoCard() {
        history.push({
            pathname,
            search: makeSearchString({ promoCard: '1' }),
        });
    }

    function closePromoCard() {
        history.replace({
            pathname,
            search: undefined,
        });
    }
    const promoCardOpen = !!(query as any)?.promoCard;
    return {
        openPromoCard,
        closePromoCard,
        promoCardOpen,
    };
}
