export interface PurchaseReceiptType {
  storeNumber: number;
  totalCouponAmt: number;
  transationNumber: number;
  totalSpendAmt: string;
  totalEarnedAmt: string;
  transactionDate: string;
  storeAddress?: string;
  transactionType: string;
  expandedAc?: any;
  handleChange?: (transitionsNumber: number) => void | undefined;
}

export interface FilterOption {
  startDate: string;
  endDate: string;
}

const getPaddedDate = (date: number) => (date < 10 ? `0${date}` : `${date}`);

export const getFormattedDate = (date: Date) =>
  [
    getPaddedDate(date.getMonth() + 1),
    getPaddedDate(date.getDate()),
    date.getFullYear(),
  ].join('-');

export const getFilterOptions = () => {
  const CurrentDate = new Date();
  return [
    {
      label: 'Last 3 months',
      value: {
        endDate: getFormattedDate(CurrentDate),
        startDate: getFormattedDate(
          new Date(
            CurrentDate.getFullYear(),
            CurrentDate.getMonth() - 3,
            CurrentDate.getDate()
          )
        ),
      },
    },
    {
      label: 'Last 6 months',
      value: {
        endDate: getFormattedDate(CurrentDate),
        startDate: getFormattedDate(
          new Date(
            CurrentDate.getFullYear(),
            CurrentDate.getMonth() - 6,
            CurrentDate.getDate()
          )
        ),
      },
    },
    {
      label: `${CurrentDate.getFullYear()}`,
      value: {
        endDate: getFormattedDate(CurrentDate),
        startDate: getFormattedDate(
          new Date(CurrentDate.getFullYear() - 0, 0, 1)
        ),
      },
    },
    {
      label: `${CurrentDate.getFullYear() - 1}`,
      value: {
        endDate: `12-31-${CurrentDate.getFullYear() - 1}`,
        startDate: getFormattedDate(
          new Date(CurrentDate.getFullYear() - 1, 0, 1)
        ),
      },
    },
    {
      label: `${CurrentDate.getFullYear() - 2}`,
      value: {
        endDate: `12-31-${CurrentDate.getFullYear() - 2}`,
        startDate: getFormattedDate(
          new Date(CurrentDate.getFullYear() - 2, 0, 1)
        ),
      },
    },
    // {
    //   label: `${CurrentDate.getFullYear() - 3}`,
    //   value: {
    //     endDate: getFormattedDate(CurrentDate),
    //     startDate: getFormattedDate(
    //       new Date(CurrentDate.getFullYear() - 3, 0, 1)
    //     ),
    //   },
    // },
    // {
    //   label: `${CurrentDate.getFullYear() - 4}`,
    //   value: {
    //     endDate: getFormattedDate(CurrentDate),
    //     startDate: getFormattedDate(
    //       new Date(CurrentDate.getFullYear() - 4, 0, 1)
    //     ),
    //   },
    // },
  ];
};
