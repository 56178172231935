/* eslint-disable no-console */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import useRouter from 'src/hooks/useRouter';
import ErrorDialog, { HandleCloseFn } from 'src/components/error-dialog';
import MyFavorites from 'src/screens/my-favorites';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';

type ExpectedQuery = { myFavorites?: 'open' };

const SlideTransition = (props: any) => <Slide direction="up" {...props} />;
const SLIDE_TRANSITION_DURATION = 300;
/**
 * We require a zip code from the user at all times.
 * The user must enter a zip code manually before continuing.
 */
export default function MyFavoritesController() {
    const { query, history } = useRouter();
    const { myFavorites } = query as ExpectedQuery;
    const [transitionDuration, setTransitionDuration] = useState(0);
    const [isOpen, setIsOpen] = useState(myFavorites === 'open');
    const { notifyUserUpdate } = useUserUpdateContext();

    useEffect(() => {
        if (isOpen) {
            setTransitionDuration(SLIDE_TRANSITION_DURATION);
        }
    }, [isOpen]);

    const [showError, setShowError] = useState(false);
    const handleCloseErrorDialog: HandleCloseFn = (reason) => {
        // eslint-disable-next-line no-console
        console.log(reason);
        setShowError(false);
    };

    const closeMyFavorites = () => {
        setIsOpen(false);
        notifyUserUpdate();
        history.goBack();
    };

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.MY_FAVORITES,
        myFavorites === 'open'
    );

    return (
        <>
            <Dialog
                fullScreen
                open={isActiveScreen}
                transitionDuration={transitionDuration}
                TransitionComponent={SlideTransition}
                TransitionProps={{ in: isActiveScreen }}
            >
                <div>
                    <MyFavorites closeMyFavorites={closeMyFavorites} />
                    <ErrorDialog
                        open={showError}
                        handleClose={handleCloseErrorDialog}
                    />
                </div>
            </Dialog>
        </>
    );
}
