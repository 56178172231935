import React from 'react';
import ProgressBar from 'src/components/UI/ProgressBar';
import Button from 'src/components/UI/Button';
import { page_favorite_selection_bottom_right_button } from 'src/utils/stringConstants';
import styles from './styles.module.scss';

interface FavoriteSelectionFooterProps {
    variant: 'enrollment' | 'manage-favorites';
    selectedCategoriesLength: number;
    handleConfirm: () => void;
    isLoading: boolean;
}

function FavoriteSelectionFooter({
    variant = 'enrollment',
    selectedCategoriesLength,
    handleConfirm,
    isLoading,
}: FavoriteSelectionFooterProps) {
    return (
        <div
            className={
                variant === 'enrollment'
                    ? styles.footer
                    : styles['footer-centered']
            }
        >
            <Button
                disabled={!(selectedCategoriesLength > 0) || isLoading}
                onClick={handleConfirm}
                loading={isLoading}
                breakpoint={420}
                fullWidth
            >
                {page_favorite_selection_bottom_right_button}
            </Button>
        </div>
    );
}

export default FavoriteSelectionFooter;
