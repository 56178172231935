import React, { useState, useEffect } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { ImportListControllerState } from 'src/screens/import-list-flow/types';
import Steps from 'src/screens/import-list-flow/steps';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';

type ExpectedQuery = ImportListControllerState;

const SlideTransition = (props: any) => <Slide direction="left" {...props} />;
const SLIDE_TRANSITION_DURATION = 300;

function ImportListFlowController() {
    const { query } = useRouter();
    const { importListStep } = query as ExpectedQuery;
    const [previousStep, setPreviousStep] = useState(importListStep);
    const [isFinished, setIsFinished] = useState(false);
    const [inProgress, setInProgress] = useState(
        importListStep !== undefined && !isFinished
    );
    const [transitionDuration, setTransitionDuration] = useState(0);
    useEffect(() => {
        if (importListStep !== previousStep) {
            setTransitionDuration(SLIDE_TRANSITION_DURATION);
            setInProgress(importListStep !== undefined && !isFinished);
            setPreviousStep(importListStep);
        }
    }, [importListStep, previousStep, isFinished]);

    const isActiveScreen = useIsActiveScreen(
        SCREEN_NAMES.IMPORT_LIST_FLOW,
        inProgress
    );

    return (
        <Dialog
            fullScreen
            open={isActiveScreen}
            transitionDuration={transitionDuration}
            TransitionComponent={SlideTransition}
            TransitionProps={{ in: isActiveScreen }}
            onExited={() => setIsFinished(false)}
        >
            <Steps importListStep={importListStep} />
        </Dialog>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedImportListController = () => <ImportListFlowController />;
export default WrappedImportListController;
