import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
  AlternativesEntity,
  AppliedSegmentsEntityOrSegmentsEntity,
  AutocompleteSearchResult,
  CategoriesResult,
  Category,
  ProductsEntity,
  SearchResult,
  SuggestionsEntity,
  SimilarProductsResult,
  TermSuggestion,
  SortRequest,
  ProductReview,
} from 'src/lib/api-client/adept-mind-types';
// import { trackEvent } from 'src/lib/api-client/beacons-adeptmind';
import { trimLeadingZeros } from 'src/lib/utils/string-utils';

/** gets query suggestions and potential products from the given searchString  */
export async function autocompleteSearch({
  searchString,
  number = 3,
}: {
  searchString: string;
  number?: number;
}) {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_ADEPTMIND_API_AUTOCOMPLETE_URL}`,
    {
      query: searchString,
      sources: [
        {
          name: 'query_suggestion',
        },
        {
          name: 'product',
          fields: ['description', 'num_reviews', 'product_rating'],
        },
      ],
      number,
    }
  );
  if (result.data) {
    const autocomplete = result?.data as AutocompleteSearchResult;
    return autocomplete?.suggestions || [];
  }

  Log.axios(
    // eslint-disable-next-line max-len
    `received an unexpected response when searching products ${process.env.REACT_APP_ADEPTMIND_API_AUTOCOMPLETE_URL}`
  );
  Log.axios(result);
  return null;
}
/* eslint-disable camelcase */

// search params are sometimes completely overwritten when categories are selected
// with the exception of pagination parameters (size & start)
// and filter/sort segments, which have a higher priority than the category payload
type SearchParams =
  | any
  | {
    query?: string;
    semantic_segments?: any[]; // sometimes occurs in category browsing
    applied_term_suggestions?: any[]; // aka quick filters
    size: number;
    start: number;
    store_id?: string;
    segments?: any[]; // aka filter by category, brand, or price
    sort?: SortRequest[]; // just sorting by price for now
  };

export type SearchProductsResult = {
  products: ProductsEntity[];
  appliedQuickFilters: TermSuggestion[] | undefined;
  availableQuickFilters: TermSuggestion[];
  appliedFilters: AppliedSegmentsEntityOrSegmentsEntity[];
  availableFilters: any[];
  appliedSorting?: SortRequest[];
  pageStart?: number;
  nextPageStart?: number;
  totalProducts: number;
  // userGetIdentity?: {
  //   query_id: string,
  //   distinct_id: string,
  //   search_id: string,
  //   session_id: string
  // };
};

// load product results 10 at a time.
export const PAGE_SIZE = 10;

// const trackEventServiceCall = (userGetIdentity: {
//   query_id: string,
//   distinct_id: string,
//   search_id: string,
//   session_id: string
// }, pageId: any, itemData: any ) => {
//   const dataBody = {
//     action: "pagination",
//     category: "search",
//     distinct_id: userGetIdentity?.distinct_id || "",
//     query_id: userGetIdentity?.query_id || "",
//     search_id: userGetIdentity?.search_id || "",
//     session_id: userGetIdentity?.session_id || "",
//     data: {
//       page_id: pageId,
//       term: itemData,
//     },
//   }
//    trackEvent(dataBody);
// }
/* eslint-enable camelcase */
let searchQuery: string;
let prevAvailableFilters: SuggestionsEntity[];
export async function searchProducts({
  searchString,
  category,
  currentStoreId,
  quickFilters = [],
  filters = [],
  sorts = [],
  pageStart = 0,
}: // userGetIdentity = {
  //   query_id: "",
  //   distinct_id: "",
  //   search_id: "",
  //   session_id: "",
  // },
  {
    searchString?: string;
    category?: Category;
    currentStoreId?: any;
    quickFilters?: any[];
    filters?: any[];
    sorts?: any;
    pageStart?: number;
    // userGetIdentity?: {
    //   query_id: string,
    //   distinct_id: string,
    //   search_id: string,
    //   session_id: string
    // }
  }) {
  if (!searchString && !category) {
    return null;
  }
  let searchParams: SearchParams = {
    query: '',
    size: PAGE_SIZE,
    start: pageStart,
  };

  if (searchString) {
    searchParams.query = searchString;
  }
  // only apply categories if we're not searching for something more granular in the query
  if (!searchString && category?.payload) {
    searchParams = { ...searchParams, ...category.payload } as any;
  }

  if (currentStoreId) {
    searchParams.store_id = trimLeadingZeros(currentStoreId);
  } else {
    Log.axios('searching without store_id. results will take a long time');
  }

  // always make a request with baseResult params
  // const requests = [
  //   axiosInstance.post(
  //     `${process.env.REACT_APP_ADEPTMIND_API_SEARCH_URL}`,
  //     searchParams
  //   ),
  // ];

  const fullSearchParams = { ...searchParams };

  if (
    (filters && filters.length > 0) ||
    (quickFilters && quickFilters.length > 0) ||
    (sorts && sorts.length > 0)
  ) {
    fullSearchParams.segments = filters || [];
    fullSearchParams.applied_term_suggestions = quickFilters || [];
    fullSearchParams.sort = sorts || [];
    // requests.push(
    //   axiosInstance.post(
    //     `${process.env.REACT_APP_ADEPTMIND_API_SEARCH_URL}`,
    //     fullSearchParams
    //   )
    // );
  }

  // baseResult contains all available suggestions
  // fullResult contains filtered & sorted products  (if any, otherwise another reference of baseResult)
  // const results = await Promise.all(requests);
  // const fullResult = results.length === 1 ? results[0] : results[1];
  const fullResult = await axiosInstance.post(
    `${process.env.REACT_APP_ADEPTMIND_API_SEARCH_URL}`,
    fullSearchParams
  );

  if (fullResult?.data?.products) {
    const availableFilters = ((fullResult.data as SearchResult).suggestions ||
      []) as SuggestionsEntity[];
    // deduplicate filter values
    const safeAvailableFilters = availableFilters.reduce(
      (agg: SuggestionsEntity[], f: SuggestionsEntity) => {
        const alts = f?.alternatives || ([] as AlternativesEntity[]);
        const safeAlternatives = alts.reduce(
          (aggAlts: AlternativesEntity[], a: AlternativesEntity) => {
            if (
              aggAlts.find(
                (exisingAlt) =>
                  exisingAlt.text === a.text &&
                  `${exisingAlt.value}` === `${a.value}`
              )
            ) {
              return aggAlts;
            }
            return [...aggAlts, a];
          },
          [] as AlternativesEntity[]
        );
        return [...agg, { ...f, alternatives: safeAlternatives }];
      },
      [] as SuggestionsEntity[]
    ) as SuggestionsEntity[];
    if (searchString && searchQuery !== searchString) {
      searchQuery = searchString;
      prevAvailableFilters = safeAvailableFilters;
    } else if (
      searchParams.navigation_entry &&
      searchQuery !== searchParams.navigation_entry
    ) {
      searchQuery = searchParams.navigation_entry;
      prevAvailableFilters = safeAvailableFilters;
    }
    // extract item ids from the lists to get full product results
    const adjustedProducts = (fullResult.data as SearchResult).products || [];
    // const productIds = adjustedProducts.map((i) => i.prod_id);
    // const aggregateProducts = await axiosInstance.post(
    //         `${process.env.REACT_APP_ADEPTMIND_API_BATCH_PRODUCTS}`,
    //         {
    //           product_ids: productIds,
    //           store_id: currentStoreId,
    //           product_fields: [],
    //           generate_term_suggestions: false,
    //           generate_suggestions: false,
    //         }
    //       );
    // console.log(`scma30 aggregateProducts`, aggregateProducts);
    // const products = aggregateProducts
    //   .filter(
    //     (r) =>
    //       r.status === 'fulfilled' &&
    //       (r as any)?.value?.data?.products &&
    //       (r as any)?.value?.data?.products.length > 0
    //   )
    //   .map((r) => ((r as any)?.value?.data.products as ProductsEntity[])[0]);
    // const {products}: {products: ProductsEntity[]} = aggregateProducts.data;
    // if (products && products.length > 0) {
    //   adjustedProducts = adjustedProducts.map((ip) => {
    //     const product = products.find((p) => ip.prod_id === p.prod_id);
    //     if (product) {
    //       return product;
    //     }
    //     return ip;
    //   });
    // }

    // if (aggregateProducts.length !== products.length) {
    //   Log.axios(`not all products from inside the search endpoint succeeded`);
    // }

    // const rejectedRequests = aggregateProducts.filter(
    //   (r) => r.status === 'rejected'
    // );
    // if (rejectedRequests.length > 0) {
    //   Log.axios(`received the following rejected responses:`);
    //   rejectedRequests.forEach((r) => {
    //     Log.axios(r);
    //   });
    // }
    // const pageSizeForTrack =  (pageStart/PAGE_SIZE) + 1;
    // trackEventServiceCall( userGetIdentity, pageSizeForTrack, searchParams.navigation_entry )
    return {
      products: adjustedProducts,
      appliedQuickFilters: quickFilters, // could also be taken from applied_term_suggestions
      availableQuickFilters:
        (fullResult.data as SearchResult).term_suggestions || [],
      // appliedFilters values could also be grabbed by the segments in the result
      appliedFilters: filters || [],
      availableFilters: prevAvailableFilters,
      appliedSorting: sorts || [], // appliedSorting could also be taken from fullResult.data.sort
      // pageStart could also be taken from fullResult.data.start
      pageStart,
      nextPageStart: pageStart + PAGE_SIZE,
      totalProducts: (fullResult.data as SearchResult)?.payload?.doc_num || 0,
    };
  }

  Log.axios(
    // eslint-disable-next-line max-len
    `received an unexpected response when searching products ${process.env.REACT_APP_ADEPTMIND_API_SEARCH_URL}`
  );
  Log.axios(fullResult);
  return null;
}

export async function getProduct(
  productId?: string,
  storeId?: string,
  upc?: string
) {
  if (!storeId) {
    Log.axios('store ID required');
    return null;
  }
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_ADEPTMIND_API_BATCH_PRODUCTS}`,
    {
      product_ids: productId ? [productId] : [],
      product_fields: [],
      store_id: storeId,
      generate_term_suggestions: false,
      generate_suggestions: false,
      upc_lookup: !!upc,
      upc_number: upc,
    }
  );
  if (result?.data?.products && result.data.products.length > 0) {
    return result.data.products[0] as ProductsEntity;
  }

  Log.axios(
    // eslint-disable-next-line max-len
    `received an unexpected response when fetching product data ${process.env.REACT_APP_ADEPTMIND_API_BATCH_PRODUCTS}`
  );
  Log.axios(result);
  return null;
}

export async function getSimilarProducts(productId?: string) {
  if (!productId) {
    return null;
  }
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_ADEPTMIND_API_SIMILAR_PRODUCTS?.replace(
      '%ID%',
      productId
    )}`
  );
  if (result.data) {
    return result.data as SimilarProductsResult;
  }

  Log.axios(
    // eslint-disable-next-line max-len
    `received an unexpected response when fetching similar products ${process.env.REACT_APP_ADEPTMIND_API_SIMILAR_PRODUCTS}`
  );
  Log.axios(result);
  return null;
}

export async function getProductReviews({
  pageParam = 0,
  queryKey,
}: {
  pageParam?: number;
  queryKey: any[];
}) {
  const [, params] = queryKey;
  const {
    productId,
    sortBy,
    searchQuery: reviewSearchQuery,
  }: {
    productId: string;
    sortBy: string;
    searchQuery: string;
  } = params;
  const sortReqMap: any = {
    most_recent: {
      published_at: {
        order: 'desc',
      },
    },
    highest_ratings: {
      review_score: {
        order: 'desc',
      },
    },
    lowest_ratings: {
      review_score: {
        order: 'asc',
      },
    },
  };
  if (process.env.REACT_APP_ADEPTMIND_API_PRODUCT_REVIEWS_URL && productId) {
    const result = await axiosInstance.post(
      process.env.REACT_APP_ADEPTMIND_API_PRODUCT_REVIEWS_URL,
      {
        product_id: productId,
        size: 10,
        start: pageParam * 10,
        sort: [sortReqMap[sortBy]],
        query: reviewSearchQuery || undefined,
      }
    );
    if (result.data.reviews && result.data.reviews.length > 0) {
      return result.data.reviews as ProductReview[];
    }
  }
  return null;
}
