import React, { useEffect, useState } from 'react';
import {
    Dialog,
    ButtonBase,
    Accordion,
    AccordionSummary,
    Typography,
    Grid,
    AccordionDetails,
} from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { Store, StoreHourDetail, StoreHours } from 'src/services/types';
import { googleMapURL } from 'src/lib/utils/store-utils';
import moment from 'moment';
import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import { IconRightBackArrow } from '../Icons';
import styles from './styles.module.scss';
import { Body, Button, Heading } from '../UI';
import { MapPreview } from '../map-preview';

import IconCall from '../Icons/IconCall';
import IconLocation from '../Icons/IconLocation';
import AppLink from '../link';
import IconExpand from '../Icons/IconExpand';
import IconCollapse from '../Icons/IconCollapse';

type StoreSelectionProps = {
    open: boolean;
    onClose: () => void;
    mainStoreResult?: any;
    nearbyStores?: any;
    handleSetStore: (data: any) => void | undefined;
};

const getTodayDay = () => {
    switch (moment().day()) {
        case 0:
            return 'sunday';
        case 1:
            return 'monday';
        case 2:
            return 'tuesday';
        case 3:
            return 'wednesday';
        case 4:
            return 'thursday';
        case 5:
            return 'friday';
        case 6:
            return 'saturday';
        default:
            return 'monday';
    }
};

export default function StoreSelection({
    open,
    onClose,
    mainStoreResult,
    nearbyStores,
    handleSetStore,
}: StoreSelectionProps) {
    const [isExpand, setExpand] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();


    const handleExpandCollapse = () => {
        setExpand(!isExpand);
    };

    useEffect(() => {
        // @Screen name userinfo_set your store store selection
        if(open) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `userinfo_set your store store selection`,
                firebase_screen_class: `userinfo_set your store store selection`,
            });
        }        
    }, [open])

    const storeHoursDisplay = (storeData: Store) => {
        const todayData = storeData?.storeHours?.[getTodayDay()];
        return (
            <div className={styles.accordianContainer}>
                <div className={styles.accordianSummary}>
                    <Body
                        className={styles.address}
                        color="textBlack"
                        weight="light"
                    >
                        Store Hours:&nbsp;{' '}
                        {todayData?.open ? (
                            <>
                                {todayData?.open?.toLocaleLowerCase()} -{' '}
                                {todayData?.close?.toLocaleLowerCase()}
                            </>
                        ) : (
                            todayData?.open24Hr
                        )}
                    </Body>
                    <Button variant="text" onClick={handleExpandCollapse}>
                        {!isExpand ? <IconCollapse /> : <IconExpand />}
                    </Button>
                </div>
                {isExpand ? (
                    <div style={{ margin: '1rem 0' }}>
                        {storeData?.storeHours &&
                            Object.keys(storeData?.storeHours)?.map((key) => {
                                const data: StoreHourDetail = storeData
                                    ?.storeHours?.[
                                    key as keyof StoreHours
                                ] as StoreHourDetail;
                                if (key === 'weekday') return '';
                                return (
                                    <Body
                                        className={styles.hoursDisAlign}
                                        color="textBlack"
                                        weight="light"
                                    >
                                        {data?.dayShort}:&nbsp;
                                        {data?.open ? (
                                            <>
                                                {data?.open?.toLocaleLowerCase()}{' '}
                                                -{' '}
                                                {data?.close?.toLocaleLowerCase()}
                                            </>
                                        ) : (
                                            data?.open24Hr
                                        )}
                                    </Body>
                                );
                            })}
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <div className={styles.container}>
                <div className={styles.header}>
                    <ButtonBase
                        className={styles.closeButton}
                        onClick={onClose}
                        id="store-change-modal-search-close-button"
                    >
                        <IconRightBackArrow />
                    </ButtonBase>
                    <Heading
                        type="h2"
                        size={26}
                        className={styles.titleText}
                        weight="bold"
                    >
                        Staples stores near you
                    </Heading>
                </div>
                <MapPreview
                    mapHeight={160}
                    center={{
                        longitude: mainStoreResult?.longitude || 0,
                        latitude: mainStoreResult?.latitude || 0,
                    }}
                />

                <div className={styles.mainStoreAlign}>
                    <Body
                        size={16}
                        className={styles.yourStoreAlign}
                        color="textBlack"
                    >
                        Your store:
                    </Body>
                    <div>
                        <Body
                            size={20}
                            className={styles.cityStateAlign}
                            color="textBlack"
                            weight="bold"
                        >
                            {mainStoreResult?.address?.city},{' '}
                            {mainStoreResult?.address?.region}
                        </Body>
                        <Body
                            className={styles.address}
                            color="textBlack"
                            weight="light"
                        >
                            {mainStoreResult?.address?.address_1}
                        </Body>
                        <Body
                            className={styles.address}
                            color="textBlack"
                            weight="light"
                        >
                            {mainStoreResult?.address?.city},{' '}
                            {mainStoreResult?.address?.region}{' '}
                            {mainStoreResult?.address?.postal_code}
                        </Body>
                        <Body
                            className={styles.address}
                            color="textBlack"
                            weight="light"
                        >
                            Tel:{' '}
                            {formatPhoneNumber(mainStoreResult?.phoneNumber)}
                        </Body>

                        <div
                            className={styles.storeHoursAndLocation}
                            style={{ alignItems: 'flex-start' }}
                        >
                            {storeHoursDisplay(mainStoreResult)}
                            <div className={styles.btnAlign}>
                                <AppLink
                                    href={`tel:${mainStoreResult?.phoneNumber}`}
                                >
                                    <IconCall />
                                </AppLink>
                                <AppLink href={googleMapURL(mainStoreResult)}>
                                    <IconLocation />
                                </AppLink>
                            </div>
                        </div>
                        {/* </div> */}

                        <Button
                            onClick={() => handleSetStore(mainStoreResult)}
                            className={styles.button}
                        >
                            <span>Set as my store</span>
                        </Button>
                    </div>
                </div>

                <div className={styles.nearByStoreContainer}>
                    <Body
                        size={18}
                        color="textBlack"
                        className={styles.nearByStoreAlign}
                    >
                        Nearby stores
                    </Body>
                    <div>
                        {nearbyStores?.map((additionalStore: Store) => (
                            <>
                                <div className={styles.nearByStoreSubContainer}>
                                    <Body
                                        className={styles.address}
                                        color="textBlack"
                                        weight="light"
                                    >
                                        {additionalStore?.address?.address_1}
                                    </Body>
                                    <Body
                                        className={styles.address}
                                        color="textBlack"
                                        weight="light"
                                    >
                                        {additionalStore?.address?.city},{' '}
                                        {additionalStore?.address?.region}{' '}
                                        {additionalStore?.address?.postal_code}
                                    </Body>
                                    <Body
                                        className={styles.address}
                                        color="textBlack"
                                        weight="light"
                                    >
                                        Tel:{' '}
                                        {formatPhoneNumber(
                                            additionalStore?.phoneNumber
                                        )}
                                    </Body>
                                    <Body
                                        className={styles.address}
                                        color="textBlack"
                                        weight="light"
                                        margin="0.375rem 0 0"
                                    >
                                        {additionalStore?.distance?.toFixed(1)}
                                        &nbsp;miles away
                                    </Body>

                                    <div
                                        className={styles.storeHoursAndLocation}
                                    >
                                        <Button
                                            onClick={() =>
                                                handleSetStore(additionalStore)
                                            }
                                            className={styles.storeSetSlign}
                                            color="textLinkBlue"
                                            variant="text"
                                            fontWeight="normal"
                                        >
                                            Set as my store
                                        </Button>
                                        <div
                                            className={styles.btnAlign}
                                            style={{ marginBottom: '-5px' }}
                                        >
                                            <AppLink
                                                href={`tel:${additionalStore?.phoneNumber}`}
                                            >
                                                <IconCall />
                                            </AppLink>
                                            <AppLink
                                                href={googleMapURL(
                                                    additionalStore
                                                )}
                                            >
                                                <IconLocation />
                                            </AppLink>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
