import { compare, Operation } from 'fast-json-patch';
import Log from 'src/lib/logging';

export function combineObjects<T>(first: T, last: Partial<T>): T {
    if (!first || !last) {
        Log.axios(`Received null object during patch ${{ first, last }}`);
    }
    return {
        ...(first || {}),
        ...(last || {}),
    } as T;
}

// Returns patch operation array reflecting changed fields based on partial object of changes
// Merges changed objects with old object to assure unchanged keys are not removed
export function getPatchDataFromPartialDiff<T>(
    fullOldData: any,
    partialNewData: Partial<T>
): Operation[] {
    const fullUpdatedData = combineObjects(fullOldData, partialNewData);
    const patch = compare(
        fullOldData as Record<string, unknown>,
        fullUpdatedData as Record<string, unknown>
    );
    return patch;
}

/**
 * Shortcut for cases where we just want to replace the values of arbitrary field(s) in an object.
 * Useful for when we know what the original API object looks like
 * But it does not match a form
 */
export function buildPatchOperations(
    fieldVals: {
        field: string;
        value: any;
    }[]
): Operation[] {
    return fieldVals.map(({ field, value }) => ({
        op: 'replace',
        path: `/${field}`,
        value,
    }));
}
