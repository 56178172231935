import React, { useMemo, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import useUserInfo from 'src/hooks/useUserInfo';
import { getRewardsActivities } from 'src/lib/api-client/rewards-activities';
import useIsOnLandingScreen from 'src/hooks/useIsOnLandingScreen';
// import PromotionDialog from 'src/components/promotion-dialog';
import RewardsCelebrationModal from 'src/components/rewards-celebration-modal';
import useRouter from 'src/hooks/useRouter';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { userInfoAutoRefreshInterval } from 'src/lib/constants';
import useDonationFlow from 'src/hooks/useDonationFlow';
import { getRewardsEmailData } from 'src/lib/api-client/optimove-apis';
import moment from 'moment';
import { SessionManager } from 'src/lib/api-client/sessionManager';

/** Listens for updates to recent activity and displays celebration modal */
export default function RewardsReceivedController() {
    // do not show the dialog or start a query if we are in the middle of another flow
    const { isOnLandingScreen } = useIsOnLandingScreen();
    const { openDonationFlow } = useDonationFlow();
    const router = useRouter();

    const { userInfo, setUserInfo } = useUserInfo();
    // time we send request to get the updated rewards
    // saving this in a ref (since we don't want a rerender) so can update remote lastAccessDate on request success
    const lastRewardsFetchTime = useRef('');

    const lastAccessDateParsed = new Date( // coerce to valid date
        new Date(userInfo?.lastAccessDate || 0).getTime() || 0
    );
    const lastAccessDateTime = lastAccessDateParsed.getTime();

    const { data, refetch } = useQuery(
        ['recentReceivedActivities'],
        () => {
            lastRewardsFetchTime.current = new Date().toISOString();
            return getRewardsActivities({
                startDate: lastAccessDateParsed.toISOString(),
            });
        },
        {
            onSuccess: () => {
                setUserInfo({ lastAccessDate: lastRewardsFetchTime.current });
            },
            enabled: SessionManager.isLoggedIn,
            ...NO_REFETCH,
        }
    );

    const { classroomRewardsData, earnedRewardsData } = useMemo(
        () => ({
            classroomRewardsData: data?.filter(
                (d) =>
                    d.donationType === 'Credit' &&
                    new Date(d.activityDate).getTime() > lastAccessDateTime
            ),
            earnedRewardsData: data?.filter(
                (d) =>
                    d.donationType === 'Earned' &&
                    new Date(d.activityDate).getTime() > lastAccessDateTime
            ),
            // promotionRewards: data?.some(
            //   (d) =>
            //     d.donationType === 'BackendAdjustment' &&
            //     new Date(d.activityDate).getTime() > lastAccessDateTime
            // ),
        }),
        [data, lastAccessDateTime]
    );

    const setOptimoveEmailInToLocalStorage = (userData: any) => {
        const stringifiedValue = JSON.stringify(userData);
        window.localStorage.setItem('optimoveUserDetails', stringifiedValue);
    };

    const getOptimoveEmailInToLocalStorage = () => {
        const getDataFromLocalStorage: any = window.localStorage.getItem(
            'optimoveUserDetails'
        );
        if (
            getDataFromLocalStorage &&
            getDataFromLocalStorage !== 'undefined' &&
            getDataFromLocalStorage !== 'null'
        ) {
            const parsedValue = JSON.parse(getDataFromLocalStorage);
            return parsedValue;
        }
        return getDataFromLocalStorage;
    };
    useEffect(() => {
        const currentTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        const userOptimoveInfo = getOptimoveEmailInToLocalStorage();
        const getNextDate =
            userOptimoveInfo?.sentEmailToOptimoveTime &&
            moment(userOptimoveInfo?.sentEmailToOptimoveTime)
                .add(12, 'hours')
                .format('YYYY-MM-DD HH:mm:ss');
        const optimoveTImeCheck =
            userOptimoveInfo?.sentEmailToOptimoveTime &&
            moment(currentTime).isAfter(getNextDate);        
        if (
            (!userOptimoveInfo?.emailIdToOptimove || optimoveTImeCheck)
        ) {
            if (userInfo?.email) {
                window?.optimoveSDK?.API?.registerUser(
                    userInfo.email.toLowerCase(),
                    userInfo.email.toLowerCase()
                );   
                console.log({           
                    sentEmailId: userInfo.email,
                });     
                setOptimoveEmailInToLocalStorage({
                    sentEmailToOptimoveTime: currentTime,
                    emailIdToOptimove: userInfo.email.toLowerCase(),
                    rewardsNumber: userInfo?.rewardsNumber,
                });
            }
        }
    }, [userInfo?.email, router?.pathname, userInfo?.rewardsNumber]);

    if (!isOnLandingScreen) {
        return null;
    }

    return (
        <>
            <RewardsCelebrationModal
                rewardsType="classroom"
                rewardsData={classroomRewardsData}
                lastAccessDateTime={lastAccessDateTime}
            />
            <RewardsCelebrationModal
                rewardsType="earned"
                rewardsData={earnedRewardsData}
                lastAccessDateTime={lastAccessDateTime}
            />
            {/* <PromotionDialog
        open={!!promotionRewards}
        handlePromotionClick={openDonationFlow}
        type="deposit"
        lastAccessDateTime={lastAccessDateTime}
      /> */}
        </>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
// const WrappedRewardsController = () => <RewardsReceivedController />;
// export default WrappedRewardsController;
