import { DonationRecipientType } from 'src/services/types';
import { makeSearchString } from 'src/lib/utils/url-utils';
import useRouter from 'src/hooks/useRouter';
import useEventsReporter from './useEventsReporter';
import eventMetricsMap from './useEventsReporter/event-types';

export default function useDonationFlow() {
    const { history, pathname } = useRouter();
    const { customEvent } = useEventsReporter();

    /**
     * If recipient and recipientType are both defined,
     * then attempt to navigate directly to the donation amount screen.
     */
    function openDonationFlow(opts?: {
        recipient?: string;
        recipientType?: DonationRecipientType;
    }) {
        customEvent(eventMetricsMap.give_tapped);
        if (opts?.recipient && opts?.recipientType) {
            history.push({
                pathname,
                search: makeSearchString({
                    donateStep: 'setAmount',
                    recipientType: opts?.recipientType,
                    recipient: opts?.recipient,
                }),
            });
        } else {
            history.push({
                pathname,
                search: makeSearchString({ donateStep: 'search' }),
            });
        }
    }
    return { openDonationFlow };
}
