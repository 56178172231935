/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Dialog } from '@material-ui/core';
import { mergeClasses } from 'src/stylesheets/utils';
import { Body, Button, Heading } from 'src/components/UI';
import styles from './base.module.scss';

interface RewardsEnrollErrorDialogProps {
    showErrorDialog: boolean;
    setShowErrorDialog: (val: boolean) => void;
}
function RewardsEnrollErrorDialog({
    showErrorDialog,
    setShowErrorDialog,
}: RewardsEnrollErrorDialogProps) {
    return (
        <Dialog open={showErrorDialog}>
            <div
                className={mergeClasses(
                    styles.p4,
                    styles.flex,
                    styles.flexCol,
                    styles.rowGap4,
                    styles.alignCenter
                )}
            >
                <div>
                    <Heading type="h3" weight="bold">
                        We are not able to enroll you in Easy Rewards.
                    </Heading>
                    <Body>Please try again.</Body>
                </div>
                <Button
                    bgColor="bgBlack"
                    onClick={() => setShowErrorDialog(false)}
                >
                    Continue
                </Button>
            </div>
        </Dialog>
    );
}

export { RewardsEnrollErrorDialog };
