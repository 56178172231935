/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconCart({
    height = 35,
    width = 35,
    fillColor = 'fillRed',
}: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_1116_226)">
                <circle
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    className={color_classes[fillColor]}
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.375 24.75C14.9963 24.75 15.5 25.2537 15.5 25.875C15.5 26.4963 14.9963 27 14.375 27C13.7537 27 13.25 26.4963 13.25 25.875C13.25 25.2537 13.7537 24.75 14.375 24.75ZM21.125 24.75C21.7463 24.75 22.25 25.2537 22.25 25.875C22.25 26.4963 21.7463 27 21.125 27C20.5037 27 20 26.4963 20 25.875C20 25.2537 20.5037 24.75 21.125 24.75ZM25.6865 12.5828C25.4037 12.2139 24.9648 11.9983 24.5 12H11.5835L11.2835 10.245C11.1591 9.52362 10.532 8.99742 9.8 9.00001H8.75C8.33579 9.00001 8 9.33581 8 9.75002C8 10.1642 8.33579 10.5 8.75 10.5H9.8045L11.7995 22.131C11.9827 23.2119 12.9202 24.0022 14.0165 24H22.6842C23.0984 24 23.4342 23.6642 23.4342 23.25C23.4342 22.8358 23.0984 22.5 22.6842 22.5H14.0165C13.6516 22.4998 13.3398 22.2371 13.2777 21.8775L13.127 21H22.937C23.6206 20.9995 24.2173 20.5369 24.3882 19.875L25.9505 13.875C26.0683 13.4266 25.9707 12.949 25.6865 12.5828ZM22.937 19.5H12.8697L11.8407 13.5H24.5L22.937 19.5Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_1116_226"
                    x="0"
                    y="0"
                    width="35"
                    height="35"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1116_226"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1116_226"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}

export default IconCart;
