import { newSequenceValue } from 'src/lib/utils/list-utils';
/* eslint-disable import/prefer-default-export */
export function getStyle({ provided, style, isDragging }: any) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = provided
  ? {
      ...style,
      ...provided.draggableProps.style,
    }
  : { ...style };

  const marginBottom = 8;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  };
  return withSpacing;
}

export function reorder(list: any, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

// useful for any array of items that have a 'sequence' field
export function updateItemSequence(
  items: { sequence: number }[],
  destinationIndex: number,
  onCallback: (item: any, newSequenceNumber: number) => void
) {
  const newSequenceNumber = newSequenceValue(items, destinationIndex);
  onCallback(items[destinationIndex], newSequenceNumber);
  return items.map((i, index) => {
    if (index === destinationIndex) {
      return { ...i, sequence: newSequenceNumber };
    }
    return i;
  });
}
