/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import { GUTTER_WIDTH } from 'src/theme';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import { CommonStepProps } from 'src/screens/rewards-enroll-flow/types';
import { RewardsAccountUserType } from 'src/services/types';

const useStyles = makeStyles({
    screen: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        margin: '20px',
        maxWidth: '100%',
    },
    signUpText: {
        fontSize: '0.7rem',
        fontWeight: 600,
        color: COLORS.brandCyan,
        paddingLeft: '0',
        background: 'none !important',
        minWidth: 'auto',
    },
    haveAccountText: {
        fontSize: '0.7rem',
        textAlign: 'center',
        padding: '0 0.6rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    giveBackButton: {
        backgroundColor: COLORS.textBlack,
        color: COLORS.primaryWhite,
        borderRadius: '1.5rem',
        width: '100%',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    rewardText: {
        textAlign: 'center',
        fontSize: '0.97rem',
        lineHeight: '1.2rem',
    },
    rewardTitle: {
        color: COLORS.textBlack,
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textAlign: 'center',
        marginBottom: '10px',
    },
    rewardLogo: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    rewardCard: {
        padding: '0.5rem 1.2rem 0 1.2rem',
        // height: 'auto',
    },
});

export function SrwCardStep1(props: any) {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.screen} id="user-type-selection-area">
                <FullWidthCard
                    hasManualShadow
                    className={classes.rewardCard}
                    id="classroom-rewards-number-card"
                >
                    <Typography
                        variant="body1"
                        id="enrollment-content"
                        className={classes.rewardTitle}
                    >
                        WELCOME TO
                    </Typography>
                    <div className={classes.rewardLogo}>
                        <img
                            src={require('../../assets/icons/StaplesRewardsLogo.svg')}
                            alt="rewardlogo"
                        />
                    </div>

                    <Typography
                        variant="body1"
                        id="enrollment-content"
                        className={classes.rewardText}
                    >
                        {props.cardType === 'digital'
                            ? `Sign up for free today and unlock access to even more coupons, plus up to 5% back in rewards and more.`
                            : `Unlock free delivery, up to 5% back in rewards, exclusive member
              deals and other benefits - all at no cost to you.`}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={props.handleSRWGiveBack}
                        id="giveback"
                        className={classes.giveBackButton}
                    >
                        Join for free
                    </Button>
                    <Typography
                        className={classes.haveAccountText}
                        id="user-type-description"
                    >
                        {props.cardType === 'digital'
                            ? `Already enrolled?`
                            : `Already enrolled in Rewards?`}
                        <Button
                            id="step2-sign-up-button"
                            className={classes.signUpText}
                            onClick={props.navigateToRewardsSignIn}
                        >
                            {props.cardType === 'digital'
                                ? `Sign in now`
                                : `Sign in`}
                        </Button>
                    </Typography>
                </FullWidthCard>
            </Box>
        </>
    );
}

export default SrwCardStep1;
