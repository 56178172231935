/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Body, Button } from 'src/components/UI';
import { Coin } from 'src/components/Icons';
import { FormControl, Grid, Input, InputAdornment, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import IconUniDirectionalArrow from 'src/components/Icons/IconUniDirectionalArrow';
import IconBiDirectionalArrow from 'src/components/Icons/IconBiDirectionalArrow';
import IconGreenDollar from 'src/components/Icons/IconGreenDollar';
import styles from './style.module.scss';

interface PointCalculatorInterface {
    handleCalculatorBack: () => void;
}

function PointCalculator({ handleCalculatorBack }: PointCalculatorInterface) {
    const menuArray = [{label:'X',value:'*'}, {label:'%',value:'%'}];
    const [isBasic, setIsBasic] = useState(true);

    const toggleCalc = () => {
        setIsBasic(!isBasic);
    }

    
    const [point, setPoint] = useState<number>();
    const [doller, setDoller] = useState<number>();
    const handlePointsChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const poi = parseInt(e.target.value, 10);
        setPoint(poi);
        setDoller(poi / 200);
    }
    const handleDollerChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const dol = parseInt(e.target.value, 10);
        setDoller(dol);
        setPoint(dol * 200);
    }
    const [act, setAct] = useState('*');
    const [advDol, setAdvDol] = useState<number>();
    const [advDolType, setAdvDolType] = useState<number>();
    const [advPoint, setAdvPoint] = useState<number>();

    const handleAdvCal = () => {
        let res = 0;
        if(advDol && advDolType) {
            if(act === '*') {
                res = advDol * advDolType;
            } else {
                res = Math.round((advDolType / advDol) * 100);
            }
        }
        setAdvPoint(res);
    }
    useEffect(() => {
        handleAdvCal();
    }, [advDol, advDolType, act])
    

    const handleAdvDollerChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const dol = parseInt(e.target.value, 10);
        setAdvDol(dol);
        
    }

    const handleAdvDollerTypeChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const dol = parseInt(e.target.value, 10);
        setAdvDolType(dol);
    }
    const handleActionChange = (e: React.ChangeEvent<any>) => {
        setAct(e.target.value);
    }

    return (
        <>
            <div className={styles.offerHeader}>
                <Button
                    variant="text"
                    className={styles.arrowBtn}
                    fontWeight="bold"
                    onClick={handleCalculatorBack}
                >
                    <img
                        src={require('../../assets/icons/right-arrow.svg')}
                        alt="arrow icon"
                    />
                    Points Calculator
                </Button>

            </div>
            {isBasic ? (
                <>
                    <div className={styles.wrapper}>
                        <div className={styles.pointsWraper}>
                            <Body size={18} className={styles.msgText}>Enter points amount</Body>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    type='number'
                                    className={styles.inputBox}
                                    value={point}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handlePointsChange(event)}
                                    id="points"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Coin width='30' height='30' />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className={styles.iconWraper}><IconBiDirectionalArrow /></div>
                        <div className={styles.dollerWraper}>
                            <Body size={18} className={styles.msgText}>Or enter dollar amount</Body>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    id="doller"
                                    type='number'
                                    value={doller}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleDollerChange(event)}
                                    className={styles.inputBox}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconGreenDollar />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={styles.hint}>
                        <Body size={12} className={styles.hintText}>200 points = $1</Body>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.wrapper}>
                        <div className={styles.pointsWraper}>
                            <div className={styles.advWrapper}>
                                <div className={styles.seletWrap}>
                                    <Body size={18} className={styles.msgText}>Offer type</Body>
                                    <div className={styles.comboInput}>
                                        <div className={styles.dolInput}>
                                            <TextField
                                                type="number"
                                                variant="standard"
                                                value={advDolType}
                                                className={styles.dolInput}
                                                onChange={(e) => handleAdvDollerTypeChange(e)}
                                            />
                                        </div>
                                        <div className={styles.dolSelect}>
                                            <Select
                                                className={styles.dolSelectBox}
                                                variant='standard'
                                                value={act}
                                                defaultValue={menuArray[0]}
                                                onChange={(event:React.ChangeEvent<any>) => handleActionChange(event)}
                                            >
                                                {menuArray.map(menu => (<MenuItem value={menu.value}>{menu.label}</MenuItem>))}

                                            </Select>
                                        </div>
                                    </div>


                                </div>
                                <div >
                                    <Body size={18} className={styles.msgText}>Dollers spent</Body>
                                    <FormControl fullWidth>
                                        <OutlinedInput
                                            id="doller"
                                            type='number'
                                            value={advDol}
                                            className={styles.inputBox}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleAdvDollerChange(event)}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconGreenDollar />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>

                        </div>
                        <div className={styles.iconWraper}><IconUniDirectionalArrow /></div>
                        <div className={styles.dollerWraper}>
                            {/* <Body size={18} className={styles.msgText}>Or enter dollar amount</Body> */}
                            <FormControl fullWidth>
                                <OutlinedInput
                                    type='number'
                                    id="points"
                                    value={advPoint}
                                    readOnly
                                    className={styles.inputBox}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Coin width='30' height='30' />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={styles.hint}>
                        <Body size={12} className={styles.hintText}>Points you’ll earn</Body>
                        <Body size={12} className={styles.hintText}>
                            <span className={styles.hintWrap}>
                                <span className={styles.dlrText}>${(advPoint || 0) / 200}</span> back</span> |
                            <span className={styles.ponWrap}>200 points = $1</span>
                        </Body>
                    </div>
                </>
            )}

            <Button
                variant="text"
                className={styles.linkText}
                fontWeight="bold"
                onClick={toggleCalc}
            >
                {isBasic ? 'Advanced calculator' : 'Basic calculator'}
            </Button>
        </>
    )
}

export default PointCalculator