/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';

function DigitalWallet({
    height = 14,
    width = 22,
}) {
    return (
        <svg
        width={width}
        height={height}
            viewBox="0 0 22 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >

            <path
                d="M20.5232 1.00024L1 1.02409L1.01466 13.0244L20.5379 13.0006L20.5232 1.00024Z"
                fill="#CC0000" />
            <path
                d="M2.16637 14C0.976744 14 0.0125224 13.0295 0.0125224 11.8462L0 2.17889C0 0.989267 0.964222 0.0187835 2.15385 0.0187835L19.2281 0C20.4177 0 21.3819 0.964222 21.3819 2.15385L21.3945 11.8211C21.3945 13.0107 20.4302 13.975 19.2406 13.9812L2.16637 14ZM19.2281 1.25224L2.15385 1.27102C1.65295 1.27102 1.25224 1.678 1.25224 2.17889L1.26476 11.8462C1.26476 12.3408 1.67174 12.7478 2.16637 12.7478L19.2406 12.729C19.7352 12.729 20.1422 12.322 20.1422 11.8211L20.1297 2.15385C20.1297 1.65921 19.7227 1.25224 19.2281 1.25224Z"
                fill="black" />
            <path
                d="M13.9956 5H8.00333C7.73272 5.00107 7.47393 5.1043 7.28526 5.28645C7.09659 5.46859 6.99389 5.71434 7.00028 5.96834V8.97765C6.99997 8.9833 7.00207 8.98883 7.00611 8.99302C7.01015 8.99721 7.0158 8.99972 7.02182 9H7.79365C7.80555 9 7.81519 8.99095 7.81519 8.97978V5.92259H14.1792V8.97765C14.1798 8.98899 14.1897 8.99789 14.2018 8.99787H14.9771C14.9892 8.99789 14.9991 8.98899 14.9997 8.97765V5.96834C15.0058 5.71424 14.9029 5.4685 14.714 5.28639C14.5252 5.10428 14.2663 5.00108 13.9956 5"
                fill="white" />
            <path
                d="M13.9956 5H8.00333C7.73272 5.00107 7.47393 5.1043 7.28526 5.28645C7.09659 5.46859 6.99389 5.71434 7.00028 5.96834V8.97765C6.99997 8.9833 7.00207 8.98883 7.00611 8.99302C7.01015 8.99721 7.0158 8.99972 7.02182 9H7.79365C7.80555 9 7.81519 8.99095 7.81519 8.97978V5.92259H14.1792V8.97765C14.1798 8.98899 14.1897 8.99789 14.2018 8.99787H14.9771C14.9892 8.99789 14.9991 8.98899 14.9997 8.97765V5.96834C15.0058 5.71424 14.9029 5.4685 14.714 5.28639C14.5252 5.10428 14.2663 5.00108 13.9956 5"
                stroke="white"
                strokeWidth="0.0764705" />
        </svg>
    );
}

export default DigitalWallet;
