// eslint-disable-next-line no-var

import axiosInstance from 'src/lib/api-client/custom-axios';

// declare var optimoveSDK:any;
declare global {
  interface Window {
    optimoveSDK: any;
  }
}
// eslint-disable-next-line import/prefer-default-export
export const updateSDKPageVisit = (
  PageURL: string,
  PageTitle: string,
  PageCategory: string
) => {
  console.log(window.optimoveSDK, 'optimoveSDK');
  // SDK function
  window?.optimoveSDK?.API?.setPageVisit(PageURL, PageTitle, PageCategory);
};

export const optimoveEvents = (eventName: string, params: any) => {
  window?.optimoveSDK?.API?.reportEvent(eventName, params);
}
export const getMarketingData = async (usermail: string | undefined) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': '971695607105431182236aabfcb29791',
      'Ocp-Apim-Trace': 'true',
    },
  };
  try {
    const abc = await window?.optimoveSDK?.API?.registerUser(
      '12345678',
      'pradeeprajendra.more@staples.com'
    );
    console.log({ abc }, 'catching abc');
  } catch (e) {
    console.log({ e }, 'catching err');
  }

  const response: any = await axiosInstance.get(
    // eslint-disable-next-line max-len
    `https://de-gateway.staplesconnect.com/external/de/cfma-user/v1/optimovedetails/${usermail}`,
    headers
  );
  if (response) {
    response.data.mid = '12345678';
    let data: any = sessionStorage.getItem('optimoveData');
    data = data && JSON.parse(data);
    if (!data || data?.mid !== response?.mid) {
      sessionStorage.setItem('optimoveData', JSON.stringify(response));
    }

    // console.log(response, 'marketingData');
  }
};
