/* eslint-disable max-len */
import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconRoundThin({
    width = 24,
    height = 24,
    fillColor = 'fillPurple',
}: IconInterface) {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 54C12.15 54 0 41.85 0 27C0 12.15 12.15 0 27 0C41.85 0 54 12.15 54 27C54 41.85 41.85 54 27 54ZM27 1.92857C13.2107 1.92857 1.92857 13.2107 1.92857 27C1.92857 40.7893 13.2107 52.0714 27 52.0714C40.7893 52.0714 52.0714 40.7893 52.0714 27C52.0714 13.2107 40.7893 1.92857 27 1.92857Z" fill="#E0E0E0" />
        </svg>
    );
}

export default IconRoundThin;
