/* eslint-disable max-len */
import React from 'react';
import IconInterface from './IconInterface';

function IconCornerSplashRight({ height = 73, width = 90 }: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 90 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_1951_20978"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="90"
                height="73"
            >
                <path d="M0 0H69.425C80.4707 0 89.425 8.95431 89.425 20V73H0V0Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1951_20978)">
                <path
                    d="M47.9467 14.3953C52.4158 14.3953 56.0388 10.7724 56.0388 6.30325C56.0388 1.83412 52.4158 -1.78882 47.9467 -1.78882C43.4776 -1.78882 39.8546 1.83412 39.8546 6.30325C39.8546 10.7724 43.4776 14.3953 47.9467 14.3953Z"
                    fill="#0FBAFF"
                />
                <path
                    d="M18.7921 24.5322L27.0259 16.2984C30.0445 13.2798 34.9337 13.2798 37.9523 16.2984C40.9709 19.317 40.9709 24.2063 37.9523 27.2249L29.7185 35.4587C26.6999 38.4772 21.8107 38.4772 18.7921 35.4587C15.777 32.4401 15.777 27.5508 18.7921 24.5322Z"
                    fill="#0058D8"
                />
                <path
                    d="M47.6808 47.04C52.15 47.04 55.7729 43.417 55.7729 38.9479C55.7729 34.4788 52.15 30.8558 47.6808 30.8558C43.2117 30.8558 39.5887 34.4788 39.5887 38.9479C39.5887 43.417 43.2117 47.04 47.6808 47.04Z"
                    fill="#3E009F"
                />
                <path
                    d="M94.3234 0.397398C98.7926 0.397398 102.416 -3.22554 102.416 -7.69467C102.416 -12.1638 98.7926 -15.7867 94.3234 -15.7867C89.8543 -15.7867 86.2313 -12.1638 86.2313 -7.69467C86.2313 -3.22554 89.8543 0.397398 94.3234 0.397398Z"
                    fill="#3E009F"
                />
                <path
                    d="M58.3736 17.3288L73.5197 2.1827C76.5383 -0.835885 81.4275 -0.835885 84.4461 2.1827C87.4647 5.20129 87.4647 10.0905 84.4461 13.1091L69.3 28.2552C66.2815 31.2738 61.3922 31.2738 58.3736 28.2552C55.355 25.2402 55.355 20.3474 58.3736 17.3288Z"
                    fill="#5B0069"
                />
                <path
                    d="M90.9792 36.0749C95.4483 36.0749 99.0712 32.452 99.0712 27.9829C99.0712 23.5137 95.4483 19.8907 90.9792 19.8907C86.51 19.8907 82.8871 23.5137 82.8871 27.9829C82.8871 32.452 86.51 36.0749 90.9792 36.0749Z"
                    fill="#0058D8"
                />
                <path
                    d="M54.9977 53.0383L70.1438 37.8923C73.1623 34.8737 78.0516 34.8737 81.0702 37.8923C84.0888 40.9109 84.0888 45.8001 81.0702 48.8187L65.9241 63.9648C62.9055 66.9834 58.0163 66.9834 54.9977 63.9648C51.9791 60.9462 51.9791 56.0569 54.9977 53.0383Z"
                    fill="#CC0000"
                />
            </g>
        </svg>
    );
}

export default IconCornerSplashRight;
