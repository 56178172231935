import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio as MUIRadio,
    RadioGroup,
    RadioProps,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useStyles from './styles';
import { getFilterOptions } from './utils';

const Radio = (props: RadioProps) => {
    const classes = useStyles();
    return (
        <MUIRadio
            {...props}
            className={classes.radioButton}
            size="small"
            color="default"
        />
    );
};
interface IOrderType {
    online: boolean;
    inStore: boolean;
}

const ReceiptFilter = ({
    selectedOption,
    handleChange,
    orderType,
    productType,
    handleCheckBoxAndRadio,
}: {
    selectedOption: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderType: IOrderType;
    productType: boolean;
    handleCheckBoxAndRadio: (event: any) => void;
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();
    return (
        <Accordion
            onChange={(_event, isExpanded) => setExpanded(isExpanded)}
            onClick={() => {
                analyticsCustomEvent(eventMetricsMap.ereceipts, {
                    click_text: 'filters',
                });
                optimoveEvents(eventMetricsMap.scm_ereceipts, {
                    click_text: 'filters',
                });
            }}
            className={classes.accordionContainer}
        >
            <AccordionSummary className={classes.accordionSummary}>
                <div className={classes.filterHeadingContainer}>
                    <span
                        className={
                            expanded
                                ? classes.filterHeading
                                : classes.filterHeadingDark
                        }
                    >
                        Filters{' '}
                        <img
                            className={classes.filterIcon}
                            src={require('../../assets/icons/filterIcon.svg')}
                            alt="filter icon"
                        />
                    </span>
                    {expanded && (
                        <img
                            src={require('../../assets/icons/iconCloseGray.svg')}
                            alt="close icon"
                        />
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.radioContainer}>
                <div className={classes.filterTitle}>Order Type</div>
                <FormControl className={classes.fieldsAlign}>
                    <FormControlLabel
                        id="productTypeOption"
                        value="productType"
                        style={{ margin: 0 }}
                        control={
                            <Checkbox
                                onChange={handleCheckBoxAndRadio}
                                name="online"
                                checked={orderType.online}
                                className={classes.checkBoxAlign}
                                style={{
                                    color: '#101820',
                                }}
                            />
                        }
                        label="Online"
                    />
                    <FormControlLabel
                        id="productTypeOption"
                        value="productType"
                        style={{ margin: 0 }}
                        control={
                            <Checkbox
                                onChange={handleCheckBoxAndRadio}
                                name="inStore"
                                checked={orderType.inStore}
                                className={classes.checkBoxAlign}
                                style={{
                                    color: '#101820',
                                }}
                            />
                        }
                        label="In store"
                    />
                </FormControl>
                <div className={classes.filterTitle}>Order Date</div>
                <FormControl className={classes.fieldsAlign}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={selectedOption}
                        onChange={handleChange}
                    >
                        {getFilterOptions().map((option, index) => (
                            <FormControlLabel
                                value={index}
                                control={<Radio />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                    <FormLabel
                        component="legend"
                        className={classes.filterTitle}
                    >
                        Product Type
                    </FormLabel>
                    <RadioGroup
                        aria-label="productType"
                        name="productType"
                        // className={classes.group}
                        value={productType}
                    >
                        <FormControlLabel
                            value="productType"
                            control={<Radio onClick={handleCheckBoxAndRadio} />}
                            label="Protection and Tech Help (Asurion)"
                            checked={productType}
                        />
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
};

export default ReceiptFilter;
