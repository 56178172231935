/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SwipeableDrawer } from 'src/components/UI';
import { FavoriteOffersList } from 'src/services/easyRewardsServices';
import moment from 'moment';
import IconRight from 'src/components/Icons/iconRight';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';

interface InfoDrawerProps {
    offerList: FavoriteOffersList;
    open: boolean;
    setIsOpen: (value: boolean) => void;
    openOfferId?: string | null;
}
function InfoDrawer({
    offerList,
    open,
    setIsOpen,
    openOfferId,
}: InfoDrawerProps) {
    console.log('🚀 ~ offerList:', offerList);
    const [selectedAccordionId, setSelectedAccordionId] = useState<
        string | undefined | null
    >(openOfferId);
    const { analyticsCustomEvent } = useEventsReporter();
    const history = useHistory();

    useEffect(() => {
        if (open) {
            const unblock = history.block(() => {
                setIsOpen(false);
                return false;
            });
            return () => {
                unblock();
            };
        }
        return undefined;
    }, [open]);

    const sortOffer = (ofr:Record<string, any>) => {
        if (
            ofr &&
            ofr.length > 0 &&
            ofr.some((o:any) => o.subscribed)
        ) {
            return ofr.sort((a:any, b:any) => {
                if (a.subscribed &&!b.subscribed) {
                    return -1;
                } else if (!a.subscribed && b.subscribed) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        return ofr;
     }


    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
        >
            <div className={styles.drawerSubContainer}>
                {/* <div className={styles.indicator}>&nbsp;</div> */}
                <Typography className={styles.topHeaderText}>
                    See what's included in each category
                </Typography>
                {sortOffer(offerList)?.map((offer:any) => (
                    <Accordion
                        expanded={offer.id === selectedAccordionId}
                        onChange={() =>
                            setSelectedAccordionId((prevValue) =>
                                prevValue === offer.id ? null : offer.id
                            )
                        }
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            classes={{ content: styles.accordionSummaryAlign }}
                        >
                            {offer.subscribed ? <IconRight /> : null}{' '}
                            <Typography variant="h6">{offer.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.details}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    {offer?.digitalviewimage ? (
                                        <img
                                            src={offer?.digitalviewimage}
                                            alt="offerImg"
                                        />
                                    ) : null}
                                </Grid>

                                <Grid item xs={9}>
                                    <Typography className={styles.headerText}>
                                        {offer.digitaldescription}
                                    </Typography>
                                </Grid>
                                {offer?.subscriptionenddate ? (
                                    <Grid
                                        item
                                        xs={9}
                                        style={{ marginBottom: '4px' }}
                                    >
                                        <Typography className={styles.dateText}>
                                            Renews on&nbsp;
                                            {moment(
                                                offer?.subscriptionenddate
                                            )?.format('MM/DD/YY')}
                                        </Typography>
                                    </Grid>
                                ) : null}
                                <Grid item xs={12}>
                                    {offer?.includedlist ? (
                                        <Typography className={styles.desText}>
                                            <b>Earn more on the following:</b>
                                            &nbsp;
                                            {offer.includedlist?.replace(
                                                'Earn on the following:',
                                                ''
                                            )}
                                        </Typography>
                                    ) : null}
                                </Grid>
                                <Grid item xs={12}>
                                    {offer.offerdisclaimer ? (
                                        <Typography className={styles.desText}>
                                            <br />
                                            <b>Disclaimer:</b>&nbsp;
                                            {offer.offerdisclaimer}
                                        </Typography>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </SwipeableDrawer>
    );
}

export default InfoDrawer;
