import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ImportListSelectSchool from 'src/screens/import-list-flow/import-list-selectSchool';
import ScreenWidth from 'src/components/screen-width';
import { useCreateImportListContext } from 'src/lib/contexts/create-import-list-context';
import ViewLists from 'src/screens/import-list-flow/view-lists';
import ViewItems from 'src/screens/import-list-flow/view-items';
import {
    CommonStepProps,
    ImportListSteps,
} from 'src/screens/import-list-flow/types';
import {
    makeStyles,
    Box,
    Typography,
    Collapse,
    Fade,
    ButtonBase,
    Dialog,
    Container,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import PaginationDots from 'src/components/pagination-dots';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import useUserInfo from 'src/hooks/useUserInfo';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        background: COLORS.homeBlack,
        minHeight: '100vh',
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    backIcon: {
        fontSize: '1.7rem',
        color: '#fff',
    },
    closeIcon: {
        fontSize: '1.7rem',
        color: '#fff',
    },
    backIconHidden: {
        fontSize: '1.7rem',
        color: 'transparent',
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    closeButton: {
        margin: '2rem 1rem 1.5rem 0',
    },
    backIconBalance: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
        color: '#fff',
    },
    paginationWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        paddingTop: '.5rem',
    },
    stepDescription: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 2,
        margin: '0rem 2rem',
    },
    screenTitle: {
        color: COLORS.primaryWhite,
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    screenDescription: {
        textAlign: 'center',
        fontSize: '1.1rem',
        fontWeight: 600,
        padding: '0rem 4rem 1rem',
        letterSpacing: '-0.30pt',
        lineHeight: '1.3rem',
    },
});

type CurrentStepProps = CommonStepProps;

const Step = (props: CurrentStepProps) => {
    const { importListStep } = props;

    switch (importListStep as any) {
        case 'view-lists':
            return <ViewLists />;
        case 'select-school':
        default:
            return <ImportListSelectSchool />;
    }
};

const StepDescription = (props: CurrentStepProps) => {
    const { importListStep } = props;
    const classes = useStyles();
    let topText: { title: string; description?: string } = { title: '' };

    switch (importListStep as any) {
        case 'view-lists':
            topText = {
                title: "Select the list you'd like to import.",
            };
            break;
        case 'select-school':
        default:
            topText = {
                title: "Select your student's school.",
            };
            break;
    }

    return (
        <>
            <Typography className={classes.screenTitle}>
                {topText.title}
            </Typography>
            <Collapse in={!!topText.description}>
                <Typography
                    className={classes.screenDescription}
                    style={{ minHeight: '1rem' }}
                >
                    {topText.description}
                </Typography>
            </Collapse>
        </>
    );
};

const stepsFromStep: any = {
    selectSchool: ['select-school'],
    viewLists: ['select-school', 'view-lists'],
};

/** Renders and animates the list of steps as they are added by the flow controller */
const Steps = (props: CommonStepProps) => {
    const { currentPage, goBack, activePaginationIndex } =
        useCreateImportListContext();
    const { importListStep, ...rest } = props;
    const currentStep = importListStep as ImportListSteps | undefined;
    const classes = useStyles();

    const { userInfo } = useUserInfo();
    const [hasBuiltPriorSteps, setHasBuiltPriorSteps] = useState(false);

    function back() {
        goBack();
    }

    const [steps, setSteps] = useState<string[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (currentStep && hasBuiltPriorSteps) {
            if (steps.indexOf(currentStep) < 0) {
                setSteps((oldSteps) => [...oldSteps, currentStep]);
            } else {
                setActiveIndex(steps.indexOf(currentStep));
            }
        }
    }, [activeIndex, currentStep, steps, hasBuiltPriorSteps]);

    useEffect(() => {
        if (userInfo && !hasBuiltPriorSteps) {
            if (currentStep) {
                setSteps(stepsFromStep[currentStep] || []);
            } else {
                setSteps(['select-school']);
            }
            setHasBuiltPriorSteps(true);
        }
    }, [userInfo, hasBuiltPriorSteps, currentStep]);

    function onTransitionEnd() {
        if (activeIndex === 0) {
            setSteps(['select-school']);
        }
    }

    const handleChangeIndex = (index: number) => {
        setActiveIndex(index);
    };

    return (
        <Box className={classes.screen} id="import-lists-screens">
            <NavTop
                minHeight="16rem"
                marginBottom="-12rem"
                bgImageSrc=""
                bgColor={COLORS.homeBlack}
            >
                <Box className={classes.commonNav}>
                    <ButtonBase
                        onClick={() => goBack()}
                        className={classes.backButton}
                    >
                        <ArrowBackIosRoundedIcon className={classes.backIcon} />
                    </ButtonBase>
                    <Box className={classes.paginationWrapper}>
                        <Fade in={activePaginationIndex > 0}>
                            <div>
                                <PaginationDots
                                    dots={2}
                                    disabled
                                    index={activePaginationIndex - 1}
                                    onChangeIndex={handleChangeIndex}
                                    activeColor={COLORS.brandRed}
                                    inActiveColor={COLORS.primaryWhite}
                                />
                            </div>
                        </Fade>
                    </Box>
                    <ButtonBase
                        onClick={() => goBack()}
                        className={classes.closeButton}
                    >
                        <CancelIcon className={classes.closeIcon} />
                    </ButtonBase>
                </Box>
            </NavTop>
            <Box className={classes.stepDescription}>
                <StepDescription importListStep={currentPage?.type} />
            </Box>
            <Fade in={hasBuiltPriorSteps}>
                <SwipeableViews
                    disabled
                    id="import-list-swipeable"
                    enableMouseEvents={false}
                    onTransitionEnd={onTransitionEnd}
                    index={activeIndex}
                    style={{ flexGrow: 1, minHeight: 'calc(100vh - 12rem)' }}
                    containerStyle={{
                        flexGrow: 1,
                        minHeight: 'calc(100vh - 12rem)',
                    }}
                >
                    {steps.map((s) => (
                        <Container key={s} maxWidth="md">
                            <Step
                                importListStep={currentPage?.type}
                                {...rest}
                            />
                        </Container>
                    ))}
                </SwipeableViews>
            </Fade>
            <Dialog
                fullScreen
                open={currentPage?.type === 'view-items'}
                onExited={back}
            >
                <ScreenWidth>
                    <ViewItems onBack={back} />
                </ScreenWidth>
            </Dialog>
        </Box>
    );
};

export default Steps;
