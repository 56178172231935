import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
    getStoreInventory,
    searchStores,
} from 'src/lib/api-client/staples-stores';
import { Store } from 'src/services/types';
import Log from 'src/lib/logging';
import {
    generatePlaceholderData,
    Template,
} from 'src/lib/utils/placeholder-utils';
import { mapStoresInventory } from 'src/lib/utils/store-utils';

// number of rows to show when we're loading
const PLACEHOLDER_STORES = 20;

const placeholderStoresData = generatePlaceholderData(
    { id: '[UUID]' },
    PLACEHOLDER_STORES
) as Template[];

type UseStoreSearchOpts = {
    /** The string we're searching for */
    partialAddress?: string;
    /**
     * Set this to false to disable automatic refetching when the query mounts or changes query keys.
     * To refetch the query, use the refetch method returned from the useQuery instance.
     * Defaults to true.
     */
    enabled?: boolean;

    // Used to determine if an additional inventory check call is needed
    withInventory?: boolean;

    // The item number to include for inventory checking
    itemNumber?: string;
};

/**
 * Returns stores based on the passed search param.
 * @param opts.partialAddress the search string
 * @param opts.enabled (if false, the )
 * @param opts.withInventory Inventory checking will run when true
 * @param opts.itemNumber The item number to include for inventory checking
 */
export default function useStoreSearch(opts: UseStoreSearchOpts = {}) {
    const { partialAddress, enabled, withInventory, itemNumber } = opts;
    const [searchCount, setSearchCount] = useState(0);
    useEffect(() => {
        setSearchCount((oldVal) => oldVal + 1);
    }, [partialAddress]);
    const {
        data: storesData,
        error: storesError,
        isFetching,
    } = useQuery(
        ['storeSearch', partialAddress, searchCount],
        async () => searchStores(partialAddress),
        {
            placeholderData: placeholderStoresData,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled,
        }
    );

    const storeNumbers: string[] =
        storesData?.map((s: any) => s.storeNumber) || [];
    const shouldRunInventoryChecking =
        withInventory &&
        !!itemNumber &&
        storeNumbers.length > 0 &&
        !storesError;

    const {
        data: storeInventoryData,
        error: storeInventoryError,
        isFetching: storeInventoryIsFetching,
    } = useQuery(
        ['storeInventory', storeNumbers],
        async () => getStoreInventory(itemNumber || '', storeNumbers),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: false,
        }
    );

    const [stores, setStores] = useState<Store[]>(
        (storesData || []) as Store[]
    );

    // process stores results
    useEffect(() => {
        if (
            storesError ||
            (shouldRunInventoryChecking && storeInventoryError)
        ) {
            Log.log('error fetching stores');
            Log.log(storesError || storeInventoryError);
        } else if (storesData && storesData.length > 0) {
            if (withInventory && itemNumber) {
                // Map the inventory data with the store data
                const updatedStoreData = mapStoresInventory(
                    storesData as Store[],
                    storeInventoryData
                );

                setStores(updatedStoreData as Store[]);
            } else {
                setStores(storesData as Store[]);
            }
        }
    }, [storesData, storesError, storeInventoryData, storeInventoryError]);

    function clearStores() {
        setStores([]);
    }

    return {
        isFetching: withInventory ? storeInventoryIsFetching : isFetching,
        placeholderStoresData,
        error: storesError,
        stores,
        clearStores,
    };
}
