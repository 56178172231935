/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';

export const SCREEN_NAMES = {
  BASE: 'BASE',
  BASE_SHARE_SHEET: 'BASE_SHARE_SHEET', // share sheets on base pages
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  CLAIM_SCHOOL: 'CLAIM_SCHOOL',
  DONATION_FLOW: 'DONATION_FLOW',
  GIVE_NOW_ERROR: 'GIVE_NOW_ERROR',
  INSTALL_POPUP: 'INSTALL_POPUP',
  LIST_CREATE_EDIT_DIALOG: 'LIST_CREATE_EDIT_DIALOG',
  LOADING_OVERLAY: 'LOADING_OVERLAY',
  MEMBER_CARD: 'MEMBER_CARD',
  MY_FAVORITES: 'MY_FAVORITES',
  PRODUCT_BROWSE: 'PRODUCT_BROWSE',
  PROMO_CARD: 'PROMO_CARD',
  RECENT_REWARDS_ACTIVITY: 'RECENT_REWARDS_ACTIVITY',
  REWARDS_ENROLLMENT_FLOW: 'REWARDS_ENROLLMENT_FLOW',
  CLASSROOM_REWARDS_RECEIVED: 'CLASSROOM_REWARDS_RECEIVED',
  EARNED_FUNDS_RECEIVED: 'EARNED_FUNDS_RECEIVED',
  REQUIRE_ZIPCODE: 'REQUIRE_ZIPCODE',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  IMPORT_LIST_FLOW: 'IMPORT_LIST_FLOW',
  CHANGE_ZIP_CODE_DIALOG: 'CHANGE_ZIP_CODE_DIALOG',
  CUSTOM_PROMOTION: 'CUSTOM_PROMOTION',
  POINT_CELEBRATION_SCREEN: 'POINT_CELEBRATION_SCREEN',
};

/** organized in order of highest priority to lowest */
export const RENDER_PRIORITY = [
  SCREEN_NAMES.REQUIRE_ZIPCODE,
  SCREEN_NAMES.MY_FAVORITES,
  SCREEN_NAMES.TERMS_OF_SERVICE,
  SCREEN_NAMES.PRODUCT_BROWSE,
  SCREEN_NAMES.IMPORT_LIST_FLOW,
  SCREEN_NAMES.POINT_CELEBRATION_SCREEN,
  SCREEN_NAMES.LIST_CREATE_EDIT_DIALOG,
  SCREEN_NAMES.DONATION_FLOW,
  SCREEN_NAMES.REWARDS_ENROLLMENT_FLOW,
  SCREEN_NAMES.CLAIM_SCHOOL,
  SCREEN_NAMES.CLASSROOM_REWARDS_RECEIVED,
  SCREEN_NAMES.EARNED_FUNDS_RECEIVED,
  SCREEN_NAMES.LOADING_OVERLAY,
  SCREEN_NAMES.CHANGE_ZIP_CODE_DIALOG,
  SCREEN_NAMES.CHANGE_LOCATION,
  SCREEN_NAMES.RECENT_REWARDS_ACTIVITY,
  SCREEN_NAMES.PROMO_CARD,
  SCREEN_NAMES.BASE_SHARE_SHEET,
  SCREEN_NAMES.GIVE_NOW_ERROR,
  SCREEN_NAMES.MEMBER_CARD,
  SCREEN_NAMES.INSTALL_POPUP,
  SCREEN_NAMES.CUSTOM_PROMOTION,
  SCREEN_NAMES.BASE,
] as const;

export type ScreenName = typeof RENDER_PRIORITY[number];

export function addScreenInOrder(
  newScreen: ScreenName,
  currentScreens: ScreenName[]
): ScreenName[] {
  if (currentScreens.indexOf(SCREEN_NAMES.BASE) === -1) {
    Log.screens(
      'WARN: all screens must have a base screen but none were found'
    );
  }
  return RENDER_PRIORITY.filter(
    (s) => s === newScreen || currentScreens.find((c) => s === c)
  );
}
