/* eslint-disable react/button-has-type */
import React from 'react';
import useStyles from './styles';

type PaginationProps = {
  totalCount: number;
  index: number;
  onChangeIndex: any;
};

const PaginationNumbers = ({
  totalCount,
  index,
  onChangeIndex,
}: PaginationProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <button
        className={classes.button}
        onClick={() => onChangeIndex(index - 1)}
        disabled={index <= 0}
      >
        <img
          className={classes.leftChevron}
          src={require('../../../assets/icons/iconChevron.svg')} 
          alt="left chevron"
        />
      </button>
      <p className={classes.countContainer}>
        <b>{index + 1}</b>/{totalCount}
      </p>
      <button
        className={classes.button}
        onClick={() => onChangeIndex(index + 1)}
        disabled={index >= totalCount - 1}
      >
        <img
          src={require('../../../assets/icons/iconChevron.svg')} 
         alt="right chevron" />
      </button>
    </div>
  );
};

export default PaginationNumbers;
