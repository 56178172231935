/* eslint-disable max-len */
/* eslint-disable comma-dangle */
// eslint-disable max-len
// eslint-disable comma-dangle
import React, { useState, useMemo } from 'react';
import COLORS from 'src/lib/colors';
import {
    ButtonBase,
    CircularProgress,
    makeStyles,
    Typography,
} from '@material-ui/core';
import NavTop from 'src/components/nav-top';
// import {useUserUpdateContext} from 'src/lib/contexts/user-update-context';
import SearchForProduct from 'src/components/search-for-product-home';
import { CommonPageProps } from 'src/screens/product-browse/common-page';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import UpdateCurrentStore from 'src/components/update-current-store';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import { useInfiniteQuery, useQuery } from 'react-query';
import { GroupedVirtuoso, ListRange } from 'react-virtuoso';
import {
    PAGE_SIZE,
    PAGE_SIZE as SEARCH_PAGE_SIZE,
    searchProducts,
} from 'src/lib/api-client/adept-mind';
import useStoreData from 'src/hooks/useStoreData';
import {
    Category,
    ProductsEntity,
    TermSuggestion,
} from 'src/lib/api-client/adept-mind-types';
import ScreenWidth from 'src/components/screen-width';
import NoSearchResults from 'src/screens/product-browse/search-results/no-search-results';
import {
    DisclaimerRow,
    FilterSortButtonsRow,
    ProductRow,
    SearchResultRowItemProps,
} from 'src/screens/product-browse/search-results/search-result-row';
import ChooseListDialog from 'src/components/choose-list-dialog';
import useAddItem from 'src/hooks/useAddItem';
import { storeNameForProduct } from 'src/lib/utils/store-utils';
import { getListDetails } from 'src/lib/api-client/lists';
import { Store } from 'src/services/types';
import { sumArray } from 'src/lib/utils/array-utils';
import LoadingOverlay from 'src/components/loading-overlay';
import { removeDuplicates } from 'src/lib/utils/list-utils';
import { getListOfSearchItems } from 'src/lib/api-client/product-search-api';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import LocationNameTile from './location-name-tile';

const useStyles = makeStyles({
    screen: {
        height: '100vh',
        background: COLORS.primaryWhite,
        display: 'flex',
        flexDirection: 'column',
    },
    titleAndBack: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '13px',
    },
    backIcon: {},
    title: {
        width: '100%',
        textAlign: 'left',
        fontSize: '1.315rem',
        lineClamp: 1,
        color: COLORS.primaryWhite,
        fontWeight: 500,
    },
    buttonBalance: { width: '3rem' },
    form: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
    },
    searchWrapper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginRight: '15px',
    },
    cancelBtn: {
        color: COLORS.primaryWhite,
        fontWeight: 500,
        fontSize: '1.1rem',
        display: 'flex',
        flexShrink: 1,
        maxWidth: '6rem',
        marginRight: '.75rem',
    },
    updateCurrentStore: { marginTop: '.5rem', marginBottom: '1.25rem' },
    loadingWrapper: {
        marginTop: '.5rem',
        marginBottom: '-100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingSpinner: {
        color: COLORS.gray80,
    },
    loadingText: {
        fontSize: '1rem',
        marginLeft: '.5rem',
    },
    loadingNextPageWrapper: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchResultContainer: {
        gap: '24px',
        padding: '17px 18px',
    },
    productResultContainer: {},
    iconAlign: {
        marginRight: '17px',
    },
    homeIcons: {
        display: 'flex',
        width: '60px',
        justifyContent: 'space-between',
    },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ProductSearchResultsScreen(props: CommonPageProps) {
    const classes = useStyles();
    const { mainStore, mainStoreId } = useStoreData();
    const { pathname } = useRouter();
    const {
        currentIntent,
        searchString,
        goBack,
        currentPage,
        goToPage,
        filters,
        sorts,
        quickFilters,
        clearAllFilterAndSorting,
    } = useProductBrowseContext();

    function backButtonClicked() {
        clearAllFilterAndSorting();
        goBack();
    }
    const [isAddToListOpen, setIsAddToListOpen] = useState(false);
    const [rowItem, setRowItem] = useState<ProductsEntity>();
    const [pendingSearch, setPendingSearch] = useState<string>(searchString);

    const [pendingCategory, setPendingCategory] = useState<
        Category | undefined
    >(currentPage?.pageDetails?.category);
    // const {userGetIdentity} = useUserUpdateContext();
    // pageParam is the product offset (handled inside searchProducts)

    const {
        isFetching,
        isFetched,
        data: infiniteData,
        refetch,
    } = useQuery(
        ['searchProducts', pendingSearch, sorts],
        async () => getListOfSearchItems(pendingSearch, sorts),
        {
            ...NO_REFETCH,
        }
    );

    function handleSubmit() {
        setPendingSearch(searchString);
        setPendingCategory(undefined);
        clearAllFilterAndSorting();
        refetch();
    }
    console.log(
        '🚀 ~ file: search-results-screen.tsx:188 ~ ProductSearchResultsScreen ~ infiniteData:',
        infiniteData
    );

    const listId = `${currentIntent?.info?.listId || ''}`;
    console.log(
        '🚀 ~ file: search-results-screen.tsx:642 ~ ProductSearchResultsScreen ~ listId:',
        listId
    );
    const { refetch: refetchUserListDetails } = useQuery(
        ['userListDetails', listId, 'user-list'],
        async () =>
            getListDetails({
                listId,
            }),
        {
            keepPreviousData: true,
            enabled: !!listId,
            ...NO_REFETCH,
        }
    );

    const { commitToAddition } = useAddItem({
        onComplete: refetchUserListDetails,
        listArray: currentIntent?.info?.listId
            ? [currentIntent?.info?.listId]
            : [],
    });

    function onAddToListClick(rowData: ProductsEntity) {
        if (
            currentIntent?.type === 'add-to-existing-list' &&
            currentIntent?.info?.listId
        ) {
            commitToAddition(rowData, { quantity: 1 });
        } else {
            setRowItem(rowData);
            setIsAddToListOpen(true);
        }
    }
    function closeAddToList() {
        setIsAddToListOpen(false);
    }

    const items: any = infiniteData?.products || [];

    if (isFetching && items.length === 0) {
        return (
            <ScreenWidth>
                <div className={classes.screen}>
                    <NavTop
                        noBorderRadius
                        className={classes.searchResultContainer}
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../../../assets/bgs/header-bg.svg')}
                        minHeight="72px"
                    >
                        <div className={classes.form}>
                            <ButtonBase
                                onClick={backButtonClicked}
                                className={classes.iconAlign}
                            >
                                <img
                                    src={require('../../../assets/icons/iconBackButton.svg')}
                                    alt="back"
                                />
                            </ButtonBase>
                            <div className={classes.searchWrapper}>
                                <SearchForProduct
                                    handleSubmit={handleSubmit}
                                    mainStore={mainStore}
                                />
                            </div>
                            <div className={classes.homeIcons}>
                                <img
                                    src={require('../../../assets/icons/e-reciept.svg')}
                                    alt="staples icon"
                                />
                                <img
                                    src={require('../../../assets/icons/e-cart.svg')}
                                    alt="staples icon"
                                />
                            </div>
                        </div>
                    </NavTop>

                    <div
                        className={classes.loadingWrapper}
                        id="product-search-loading"
                    >
                        <CircularProgress
                            size="1rem"
                            className={classes.loadingSpinner}
                        />
                        <Typography className={classes.loadingText}>
                            Loading Results
                        </Typography>
                    </div>
                </div>
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isFetching}
                />
            </ScreenWidth>
        );
    }

    const itemProps: SearchResultRowItemProps = {
        onAddToListClick,
    };
    return (
        <ScreenWidth>
            <div className={classes.screen}>
                <NavTop
                    noBorderRadius
                    className={classes.searchResultContainer}
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('../../../assets/bgs/header-bg.svg')}
                    minHeight="72px"
                >
                    <div className={classes.form}>
                        <ButtonBase
                            onClick={backButtonClicked}
                            className={classes.iconAlign}
                        >
                            <img
                                src={require('../../../assets/icons/iconBackButton.svg')}
                                alt="back"
                            />
                        </ButtonBase>
                        <div className={classes.searchWrapper}>
                            <SearchForProduct
                                handleSubmit={handleSubmit}
                                mainStore={mainStore}
                            />
                        </div>
                        <div className={classes.homeIcons}>
                            <img
                                src={require('../../../assets/icons/e-reciept.svg')}
                                alt="staples icon"
                            />
                            <img
                                src={require('../../../assets/icons/e-cart.svg')}
                                alt="staples icon"
                            />
                        </div>
                    </div>
                </NavTop>

                {isFetched && infiniteData?.products?.length === 0 && (
                    <NoSearchResults
                        lastResult={items.length}
                        searchTerm={
                            searchString ||
                            currentPage?.pageDetails.category?.title ||
                            'your search'
                        }
                    />
                )}

                {infiniteData?.products?.length ? (
                    <FilterSortButtonsRow
                        index={0}
                        data={{ items, itemProps }}
                    />
                ) : null}
                {infiniteData?.products?.map((item: any, index: any): any => (
                    <ProductRow
                        data={{ items: [item], itemProps }}
                        index={index}
                        item={item}
                        titleText={currentPage?.pageDetails.category?.title}
                    />
                ))}
            </div>
            {rowItem && (
                <ChooseListDialog
                    open={isAddToListOpen}
                    handleClose={closeAddToList}
                    productItem={rowItem}
                    quantity={1}
                    storeId={mainStoreId}
                />
            )}
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isFetching}
            />
        </ScreenWidth>
    );
}
