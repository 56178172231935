import React, { useContext, useState } from 'react';
import {
    Box,
    makeStyles,
    ButtonBase,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import useRouter from 'src/hooks/useRouter';
import Header from 'src/components/header';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import useStoreData from 'src/hooks/useStoreData';
import BrandIframe from 'src/components/BrandIframe';
import CartCountContext from 'src/lib/contexts/cart-count-context';
import ROUTES from 'src/lib/routes';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import NavBottom from 'src/components/NavBottom';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { Button, Heading } from 'src/components/UI';
import AppLink from 'src/components/link';

const useStyles = makeStyles({
    iframeContainer: {
        // height: '100vh',
    },
    iframe: {
        width: '100vw',
        height: '100vh',
        border: 'none',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: COLORS.whiteSmoke,
        margin: 'auto',
    },
    headerLogo: {
        margin: '0 auto',
    },
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        overflow: 'visible',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        margin: '1rem 0 0.8rem',
        padding: '1rem',
    },
    dialogTitle: {
        fontSize: '20px !important',
        color: COLORS.darkGray,
        textAlign: 'center',
    },
    primaryButton: {
        width: '100%',
        textTransform: 'none',
        margin: ' 0.2rem 0.4rem',
    },
    smallLink: {
        fontSize: '.85rem',
        textDecoration: 'underline',
    },
    buttonDialogAction: {
        flexDirection: 'column',
        marginTop: '1rem',
    },
    leaveCheckout: {
        marginTop: '1rem',
        color: COLORS.darkGray,
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
    },
});

function OrderHistoryAndCart() {
    const classes = useStyles();
    const { history, replace } = useRouter();
    const { mainStore } = useStoreData();
    const [navigateTo, setNavigation] = useState('');
    const { ocpPage, setOcpPage } = useTabsUIStateContext();
    const [navigateOut, setNavigateOut] = useState(false);

    const handleClick = () => {
        setOcpPage(false);
        if (navigateTo === 'home') {
            return replace(REWARDS_ENROLLMENT_ROUTES.userUseCaseHandler);
        }
        const documentData: any = (
            document?.getElementById?.(
                'cart-details-iframe'
            ) as HTMLIFrameElement
        )?.contentWindow?.document?.body;

        if (
            documentData?.querySelectorAll('.checkout__checkout_container')
                .length
        ) {
            return setNavigateOut(true);
        }
        return history.goBack();
    };
    return (
        <Box>
            {ocpPage ? (
                <Header backButton onClickHandler={handleClick} />
            ) : (
                <NavTop
                    withTopScroll
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('../assets/bgs/header-bg.svg')}
                    noBorderRadius
                    minHeight="0"
                >
                    <Box className={classes.cartHeader}>
                        <ButtonBase
                            onClick={() => handleClick()}
                            aria-label="Go Back"
                            role="button"
                        >
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                        {/* <Typography className={classes.title}>Checkout</Typography> */}
                        <img
                            className={classes.headerLogo}
                            src={require('../assets/icons/iconStaplesLogo.svg')}
                            alt="staples connect logo"
                            id="staples-connect-logo"
                        />
                    </Box>
                </NavTop>
            )}
            <Box className={classes.iframeContainer}>
                <BrandIframe
                    title="cart-details"
                    id="cart-details-iframe"
                    src={process.env.REACT_APP_CART_URL!}
                    height="calc(100vh - 78px)"
                    setNavigation={setNavigation}
                />
            </Box>
            {ocpPage && <NavBottom />}

            <Dialog
                open={navigateOut}
                classes={{
                    paper: classes.paper,
                }}
                onClose={() => setNavigateOut(!navigateOut)}
                scroll="body"
            >
                <div className={classes.contentWrapper}>
                    <div
                        id="leave-cart-dialog-title"
                        style={{ padding: '0rem' }}
                    >
                        <Heading className={classes.dialogTitle} type="h3">
                            Are you sure you want to leave your cart?
                        </Heading>
                    </div>
                    <DialogContent>
                        <DialogActions className={classes.buttonDialogAction}>
                            <Button
                                variant="contained-rounded"
                                onClick={() => {
                                    setNavigateOut(!navigateOut);
                                }}
                                className={classes.primaryButton}
                                fontWeight="normal"
                                bgColor="bgRed"
                            >
                                Stay
                            </Button>
                            <AppLink
                                id="leave-checkout"
                                href="#"
                                className={classes.leaveCheckout}
                                onClick={() => {
                                    setNavigateOut(false);
                                    history.goBack();
                                }}
                            >
                                Leave
                            </AppLink>
                        </DialogActions>
                    </DialogContent>
                </div>
            </Dialog>
        </Box>
    );
}

export default () => <OrderHistoryAndCart />;
