/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-fallthrough */
/* eslint-disable camelcase */
import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from 'react';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { Html5QrcodeResult } from 'html5-qrcode/esm/core';
import {
    Button,
    ButtonBase,
    Dialog,
    Typography,
    IconButton,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useEventsReporter, eventMetricsMap } from 'src/hooks';
import { getProduct } from 'src/lib/api-client/adept-mind';
import useStoreData from 'src/hooks/useStoreData';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import platform from 'platform-detect';
import LoadingOverlay from '../loading-overlay';
import useStyles from './styles';

interface BarcodeScannerProps {
    isScannerOpen: boolean;
    onClose: () => void;
    shippingScan?: boolean;
    setScannerData?: any;
}

interface ScannerError {
    title: string;
    body: string;
    ctaText: string;
    redirectText?: string;
    redirectTextTest?: string;
    ctaAction: () => void;
    redirectAction?: () => void;
}

function trimStart(str: string, trimChar: string): string {
    let index = 0;
    for (; index < str.length; index += 1) {
        if (str.charAt(index) !== trimChar) {
            break;
        }
    }
    return str.slice(index);
}

// Verify UPC check digit
function verifyUpcCheckDigit(scannedCode: string): boolean {
    const scannedCheckDigit = Number(scannedCode[scannedCode.length - 1]);
    const code = scannedCode
        .slice(0, scannedCode.length - 1)
        .split('')
        .map(Number);
    const [evenSum, oddSum]: number[] = code.reduce(
        (accumulator: number[], currentValue: number, index) => {
            const newValue = [...accumulator];
            if ((index + 1) % 2 === 0) {
                newValue[0] += currentValue;
            } else {
                newValue[1] += currentValue;
            }
            return newValue;
        },
        [0, 0]
    );
    let calculatedCheckDigit = (oddSum * 3 + evenSum) % 10;
    calculatedCheckDigit =
        calculatedCheckDigit > 0
            ? 10 - calculatedCheckDigit
            : calculatedCheckDigit;
    return calculatedCheckDigit === scannedCheckDigit;
}

const Brcd = ({
    isScannerOpen,
    onClose,
    shippingScan,
    setScannerData,
}: //   setScannedCode,
BarcodeScannerProps) => {
    const classes = useStyles();
    const { analyticsCustomEvent } = useEventsReporter();
    const { fromPath, setFromPath, end } = useProductBrowseContext();
    const { push, location } = useRouter();
    const androidSettings =
        'intent:#Intent;action=android.settings.SETTINGS;end';
    const browserSettings = 'chrome://settings/content/camera';

    const scannerRef = useRef<HTMLDivElement | null>(null);
    const [showLoader, setShowLoader] = useState(false);
    const [scannerDialog, setScannerError] = useState<ScannerError | null>(
        null
    );
    const { mainStoreId = '' } = useStoreData();
    const [qrcodeRegionId] = useState('html5qr-code-full-region');

    const html5QrcodeScanner = useMemo(() => {
        if (scannerRef?.current && isScannerOpen) {
            return new Html5Qrcode(qrcodeRegionId, {
                formatsToSupport: [
                    Html5QrcodeSupportedFormats.QR_CODE,
                    Html5QrcodeSupportedFormats.UPC_A,
                    Html5QrcodeSupportedFormats.CODE_128,
                ],
                verbose: false,
            });
        }
        return null;
    }, [scannerRef?.current && isScannerOpen]);

    const onTryAgainClick = () => {
        setScannerError(null);
        html5QrcodeScanner?.resume();
    };

    const onRedirect = () => {
        if (platform?.phone) {
            window.open(androidSettings);
        } else {
            window.open(browserSettings);
        }
    };
    const testingOnRedirect = () => {
        const link = document.createElement('a');
        if (platform?.phone) {
            link.href = androidSettings;
        }
        link.click();
    };
    const isUPSTrackingNumber = (barcode: string) => {
        const pattern = /^1Z[A-Z0-9]{16}$/;
        return pattern.test(barcode);
    };

    const handleOnDetected = useCallback(
        async (decodedText: string, decodedResult: Html5QrcodeResult) => {
            const codeType = decodedResult.result.format?.format;
            let scannedAndVerifiedCode = decodedText;

            // Function to handle error if product is not returned in the api call
            const handleSearchError = () => {
                analyticsCustomEvent(eventMetricsMap.scan, {
                    click_text: 'scanned',
                    scan_result: 'error',
                });
                setShowLoader(false);
                setScannerError({
                    title: 'Whoops!',
                    body: 'We could not find this item. Please try scanning again.',
                    ctaText: 'Try again',
                    ctaAction: onTryAgainClick,
                });
                end();
                onClose();
            };

            try {
                html5QrcodeScanner?.pause();
                switch (codeType) {
                    case Html5QrcodeSupportedFormats.QR_CODE: {
                        // Handle QR code
                        const decodedURL = new URL(decodedText);
                        if (
                            decodedURL.origin ===
                            process.env.REACT_APP_COUPON_DEEPLINK_DOMAIN
                        ) {
                            return window.location.replace(decodedText);
                        }
                        throw new Error('Invalid URL scanned');
                    }
                    // @ts-ignore
                    case Html5QrcodeSupportedFormats.UPC_A:
                        if (!verifyUpcCheckDigit(decodedText)) {
                            return null;
                        }
                        scannedAndVerifiedCode = `00${trimStart(
                            decodedText,
                            '0'
                        )}`;
                        break;
                    // @ts-ignore
                    case Html5QrcodeSupportedFormats.CODE_128:
                        scannedAndVerifiedCode = decodedText;
                        break;
                    default:
                        throw new Error('Product not found');
                }

                setShowLoader(true);
                if (scannedAndVerifiedCode) {
                    if (shippingScan) {
                        setScannerData(decodedText);
                    } else if (isUPSTrackingNumber(scannedAndVerifiedCode)) {
                        setFromPath(
                            ROUTES.productDetailsPage === location.pathname
                                ? fromPath
                                : location.pathname
                        );
                        push(
                            `${ROUTES.shippingServices}?barcode=${scannedAndVerifiedCode}`,
                            setPreviousPath(location, fromPath)
                        );
                        end();
                        onClose();
                    } else {
                        setFromPath(
                            ROUTES.productDetailsPage === location.pathname
                                ? fromPath
                                : location.pathname
                        );
                        push(
                            `${ROUTES.productDetailsPage}?barcode=${scannedAndVerifiedCode}&isBarCodeScan=true`,
                            setPreviousPath(location, fromPath)
                        );
                        end();
                        onClose();
                        console.log({ scannedAndVerifiedCode });
                    }
                } else {
                    setShowLoader(false);
                    throw new Error('Product not found');
                }
                end();
                onClose();
                setShowLoader(false);
            } catch (error) {
                handleSearchError();
            }
            console.log({ scannedAndVerifiedCode });
            return null;
        },
        [html5QrcodeScanner]
    );

    // Handles the mounting and unmounting of the camera and scanner
    useEffect(() => {
        html5QrcodeScanner
            ?.start(
                {
                    facingMode: 'environment',
                },
                {
                    fps: 10, // Optional, frame per seconds for qr code scanning
                    qrbox: (viewfinderWidth, viewfinderHeight) => {
                        const minEdgePercentage = 0.7; // 70%
                        const minEdgeSize = Math.min(
                            viewfinderWidth,
                            viewfinderHeight
                        );
                        const qrboxSize = Math.floor(
                            minEdgeSize * minEdgePercentage
                        );
                        return {
                            width: qrboxSize,
                            height: shippingScan ? qrboxSize * 0.5 : qrboxSize,
                        };
                    },
                    aspectRatio: 1.7777778,
                },
                handleOnDetected,
                (err) => console.log('scanner err', err)
            )
            .catch((err) => {
                if (
                    err ===
                    'Error getting userMedia, error = NotAllowedError: Permission denied'
                ) {
                    setScannerError({
                        title: 'Camera access',
                        body: 'Please grant permission to use the camera',
                        ctaText: 'Close',
                        redirectText: 'Open Settings',
                        ctaAction: onClose,
                        redirectAction: onRedirect,
                    });
                }
            });

        return () => {
            html5QrcodeScanner?.stop();
        };
    }, [scannerRef?.current, isScannerOpen, html5QrcodeScanner]);

    return (
        <Dialog fullScreen open={isScannerOpen}>
            <IconButton
                onClick={onClose}
                size="small"
                className={classes.closeButton}
            >
                <ArrowBackIosRoundedIcon />
            </IconButton>
            <div
                className={classes.scannerContainer}
                ref={scannerRef}
                id={qrcodeRegionId}
            />
            <Dialog classes={{ paper: classes.dialog }} open={!!scannerDialog}>
                <ButtonBase
                    className={classes.errorDialogCloseButton}
                    onClick={onClose}
                >
                    <img
                        src={require('../../assets/icons/iconCloseBlack.svg')}
                        alt="close icon"
                    />
                </ButtonBase>
                <div className={classes.contentWrapper}>
                    {scannerDialog?.title && (
                        <Typography
                            className={classes.title}
                            id="search-dialog-title-text"
                        >
                            {scannerDialog?.title}
                        </Typography>
                    )}
                    {scannerDialog?.body && (
                        <Typography
                            className={classes.desc}
                            id="search-dialog-desc-text"
                        >
                            {scannerDialog?.body}
                        </Typography>
                    )}
                    {scannerDialog?.ctaText && (
                        <div className={classes.buttons}>
                            <Button
                                className={classes.tryAgainBtn}
                                onClick={scannerDialog?.ctaAction}
                            >
                                {scannerDialog?.ctaText}
                            </Button>
                            <Button
                                className={classes.tryAgainBtn}
                                onClick={scannerDialog?.redirectAction}
                            >
                                {scannerDialog?.redirectText}
                            </Button>
                        </div>
                    )}
                </div>
            </Dialog>
            <LoadingOverlay
                variant="wordmark"
                theme="light"
                open={showLoader}
            />
        </Dialog>
    );
};

export default Brcd;
