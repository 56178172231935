import React from 'react';
import { Container, Box } from '@material-ui/core';

/** Shared component for displaying screens at the same width */
export default function ScreenWidth({
    children,
    fullHeight,
    isHomePage,
    auto,
    grow,
    boxClassname,
}: any) {
    return (
        <Container
            maxWidth="md"
            style={{
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                // eslint-disable-next-line no-nested-ternary
                height: auto ? 'auto' : fullHeight ? '100%' : 'inherit',
                flexGrow: grow ? 1 : 0,
                overflow: 'auto',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    overflow: isHomePage ? 'hidden' : 'auto',
                    // marginBottom:'4rem',
                }}
                className={boxClassname}
                data-testid="screen-content"
            >
                {children}
            </Box>
        </Container>
    );
}
