/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
import { Grid, makeStyles } from "@material-ui/core";
import { FunctionComponent, useState } from "react";
import UserRewardsDigitalCardDrawer from "src/screens/EasyRewards/UserRewardsDigitalCardDrawer";
import IconNoInternet from "./Icons/IconNoInternet";
import { Body, Heading } from "./UI";

interface NoInternetProps {
    rewardstiercode: any,
    btnClick: any,
}

const useStyles = makeStyles(({
    nointWrapper: {
        backGroundColor: '#F1F1F2',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '7rem auto',
        gap: '1rem',
    },
    linkText: {
        color: '#086DD2',

    },
    bottomWrapper: {
        position: 'fixed',
        width: '100%',
        bottom: '-0.5rem',
        left: 0,
    },
}))

const NoInternet: FunctionComponent<NoInternetProps> = ({ rewardstiercode, btnClick }) => {
    const classes = useStyles();
    const [
        isUserRewardsDigitalCardDrawerOpen,
        toggleIsUserRewardsDigitalCardDrawerOpen,
    ] = useState(true);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={12} className={classes.nointWrapper}>
                    <div className={classes.wrapper}>
                        <div>
                            <IconNoInternet />
                        </div>
                        <div>
                            <Heading size={26} type="h2" weight="bold">
                                You are currently offline
                            </Heading>
                        </div>
                        <div onClick={() => btnClick()}>
                            <Body className={classes.linkText} size={16} weight="bold">
                                Try refreshing to connect
                            </Body>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.bottomWrapper}>
            <UserRewardsDigitalCardDrawer
                open={isUserRewardsDigitalCardDrawerOpen}
                toggleOpen={toggleIsUserRewardsDigitalCardDrawerOpen}
                rewardstiercode={rewardstiercode}
                useDrawer={false} // Pass useDrawer as false
            />
            </div>
        </>
    );
}

export default NoInternet;
