import React from 'react';
import {
    Typography,
    makeStyles,
    ButtonBase,
    Dialog,
    Box,
    Button,
    DialogTitle,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import COLORS from 'src/lib/colors';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { openInNewTab } from 'src/lib/utils/nav-utils';

const useStyles = makeStyles({
    paper: {
        background: 'transparent',
        maxWidth: '500px',
    },
    container: {
        background: '#000000CC',
    },
    titleWrapper: {
        padding: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeIcon: {
        justifyContent: 'flex-end',
        position: 'absolute',
        top: '6px',
        width: '24px',
        height: '24px',
        right: '0px',
    },
    getStartedBtn: {
        width: '274px',
        marginTop: '1rem',
        textTransform: 'none',
        minHeight: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
    },
    recycleOvalIm: {
        padding: '10px 13px',
    },
    recyclelImg: {
        padding: '10px 13px',
    },
    boxContainer: {
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 1.75rem 1.5rem',
        background: '#ffffff',
        borderRadius: '15px',
        alignItems: 'center',
        gap: '10px',
    },
    headerAlign: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        textAlign: 'center',
        width: '274px',
        maxHeight: '225px',
    },
    title: {
        fontWeight: 700,
        fontSize: '21px',
        marginTop: '20px',
        textAlign: 'center',
    },
    subTitle: {
        fontWeight: 400,
        fontSize: '16px',
    },
    description: {
        fontWeight: 400,
        fontSize: '21px',
        lineHeight: '25px',
        letterSpacing: '-0.3375000059604645px',
    },
    rewardsSummaryIconWrapper: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '6rem',
        height: '6rem',
        margin: '0 auto',
        marginBottom: '-4.5rem',
        zIndex: 99999,
    },
    imageAlign: {
        width: '274px',
        borderRadius: '15px',
    },
    learnMore: {
        color: COLORS.brandCyan,
        fontWeight: 500,
    },
    alignTextTwo: {
        fontWeight: 'bold',
    },
});
// StandardizingPopUpMessages
const StandardizingPopUpMessages = ({
    open,
    handleClose,
    popUpMessagesData,
    cliclOnCTA,
}: {
    open: boolean;
    handleClose: any;
    popUpMessagesData: any;
    cliclOnCTA: () => void;
}) => {
    const { actionTarget } = popUpMessagesData;
    const classes = useStyles();
    const { analyticsCustomEvent } = useEventsReporter();
    const { history } = useRouter();
    const navigateToTab = () => {
        if (
            actionTarget?.includes('https:') ||
            actionTarget?.includes('http:')
        ) {
            openInNewTab(actionTarget);
        } else {
            let navigateToRoute;
            switch (actionTarget?.toLowerCase()) {
                case 'gotofeaturedlists':
                    navigateToRoute = ROUTES.lists;
                    break;
                case 'gotolists':
                    navigateToRoute = ROUTES.lists;
                    break;
                case 'gotostores':
                    navigateToRoute = ROUTES.stores;
                    break;
                case 'givenow':
                    navigateToRoute = {
                        pathname: ROUTES.home,
                        search: makeSearchString({ donateStep: 'search' }),
                    };
                    break;
                case 'paywithclassroomrewards':
                    navigateToRoute = ROUTES.rewards;
                    break;
                case 'gotorewards':
                    navigateToRoute = ROUTES.rewardsWallet;
                    break;
                case 'gotoaccount':
                    navigateToRoute = ROUTES.userProfile;
                    break;
                case 'gotopurchasehistory':
                    navigateToRoute = ROUTES.previousOrders;
                    break;
                case 'gotowallet':
                    navigateToRoute = ROUTES.rewardsWallet;
                    break;
                case 'checkforscannedcoupon':
                    navigateToRoute = ROUTES.coupons;
                    break;
                case 'gotohome':
                    navigateToRoute = ROUTES.home;
                    break;
                default:
                    navigateToRoute = history.location;
            }
            if (navigateToRoute) {
                history.push(navigateToRoute);
            }
        }
        cliclOnCTA();
        analyticsCustomEvent(eventMetricsMap.pop_up, {
            click_text: popUpMessagesData.actionTitle,
            offer_description: popUpMessagesData.messageText1,
        });
        optimoveEvents(eventMetricsMap.pop_up, {
            click_text: popUpMessagesData.actionTitle,
            offer_description: popUpMessagesData.messageText1,
        });
    };
    const handleTerms = () => {
        if (popUpMessagesData?.terms) {
            openInNewTab(popUpMessagesData.terms);
            analyticsCustomEvent(eventMetricsMap.pop_up, {
                click_text: 'Terms & Conditions',
                offer_description: popUpMessagesData.messageText1,
            });
            optimoveEvents(eventMetricsMap.pop_up, {
                click_text: 'Terms & Conditions',
                offer_description: popUpMessagesData.messageText1,
            });
        }
    };
    return (
        <Dialog
            open={open}
            aria-labelledby="list-create-edit-dialog-title"
            classes={{
                paper: classes.paper,
            }}
            className={classes.container}
        >
            <Box display="flex" className={classes.boxContainer}>
                <DialogTitle
                    disableTypography
                    id="list-create-edit-dialog-title"
                    className={classes.titleWrapper}
                >
                    <Button
                        fullWidth
                        onClick={handleClose}
                        className={classes.closeIcon}
                        id="close-button"
                    >
                        <img
                            src={require('../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </Button>
                    <Typography className={classes.title}>
                        {popUpMessagesData.messageTitle}
                    </Typography>
                </DialogTitle>
                <img
                    src={
                        popUpMessagesData?.imgURL?.includes('https:')
                            ? popUpMessagesData?.imgURL
                            : require('../assets/icons/stdPopUpMessages.png')
                    }
                    alt="application pop up messages"
                    className={classes.imageAlign}
                />

                <div className={classes.headerAlign}>
                    <Typography className={classes.subTitle}>
                        <div>
                            {popUpMessagesData.messageText1}&nbsp;
                            {popUpMessagesData?.messageText2 ? (
                                <span className={classes.alignTextTwo}>
                                    {popUpMessagesData.messageText2}
                                </span>
                            ) : null}
                        </div>
                        {popUpMessagesData?.messageText3 ? (
                            <b>{popUpMessagesData.messageText3}</b>
                        ) : null}
                    </Typography>
                </div>
                <Button
                    onClick={navigateToTab}
                    fullWidth
                    id="classroom-enrollment-success-finish-button"
                    variant="contained"
                    classes={{
                        root: classes.getStartedBtn,
                    }}
                >
                    {popUpMessagesData.actionTitle}
                </Button>

                {popUpMessagesData?.terms ? (
                    <ButtonBase
                        onClick={handleTerms}
                        className={classes.learnMore}
                        id="learnMore"
                    >
                        Terms & Conditions
                    </ButtonBase>
                ) : null}
            </Box>
        </Dialog>
    );
};
export default StandardizingPopUpMessages;
