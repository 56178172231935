import React from 'react';
import COLORS from 'src/lib/colors';
import { ButtonBase, Divider, makeStyles, Typography } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { ProductsEntity } from 'src/lib/api-client/adept-mind-types';
import FullWidthCard from 'src/components/cards/full-width-card';
// import {useUserUpdateContext} from 'src/lib/contexts/user-update-context';
// import { trackEvent } from 'src/lib/api-client/beacons-adeptmind';
import {
    getProductOriginalPrice,
    getProductPrice,
    getProductQuantity,
    isProductOnSale,
} from 'src/lib/utils/products-utils';
import DiagonalStrikethroughText from 'src/components/diagonal-strikethrough-text';
import ProductStarRating from 'src/components/product-star-rating';
import FilterSortButtons from './filter-sort-buttons';

const FILTER_ROW_HEIGHT = 120;
const FILTER_NO_QUICKFILTERS_ROW_HEIGHT = 60;
const SEARCH_RESULT_ROW_HEIGHT = 230;
// optimization; calculate search result row height using react-virtuoso
const ROW_DISCLAIMER_HEIGHT = 55;

// currently unused in our search results screen, but may be put back in later if we need to virtualize
export const getRowHeight = (
    index: number,
    totalItems: number,
    quickFilters?: any[]
) => {
    if (index === 0) {
        if (!quickFilters || quickFilters.length === 0) {
            // eslint-disable-next-line no-console
            console.log('no quickfilters detected');
            return FILTER_NO_QUICKFILTERS_ROW_HEIGHT;
        }
        return FILTER_ROW_HEIGHT;
    }
    if (index >= totalItems) {
        return ROW_DISCLAIMER_HEIGHT;
    }
    return SEARCH_RESULT_ROW_HEIGHT;
};

const useSearchResultRowStyles = makeStyles({
    cardWrapper: {
        // this component is assigned height programmatically
        background: 'transparent',
    },
    card: {
        height: 'calc(100% - 1rem)',
        width: 'calc(100% - 2rem)',
        marginLeft: '1rem',
        marginRight: '1rem',
        marginTop: '1rem',
    },
    itemContent: {
        display: 'flex',
    },
    itemDetailsContainer: {
        flexDirection: 'column',
        display: 'flex',
        textAlign: 'left',
        marginLeft: '1.75rem',
        height: '100%',
    },
    itemImage: {
        height: '10rem',
        maxWidth: '5rem',
        minWidth: '5rem',
        objectFit: 'contain',
    },
    itemName: {
        marginTop: '.5rem',
        fontSize: '1.2rem',
        fontWeight: 500,
        lineClamp: 2,
        flexGrow: 1,
    },
    itemStoreContainer: {
        lineClamp: 2,
        fontSize: '1rem',
        fontWeight: 500,
        flexGrow: 1,
    },
    itemStoreName: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
    },
    prices: {
        display: 'flex',
        alignItems: 'center',
    },
    itemPrice: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
    },
    itemActions: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    addToList: {
        color: COLORS.brandCyan,
        fontWeight: 'bold',
    },
    disclaimerRowContent: {
        height: `${ROW_DISCLAIMER_HEIGHT}px`,
        display: 'flex',
        paddingBottom: '3rem',
    },
    disclaimerRowText: {
        marginTop: '2rem',
        fontSize: '.85rem',
        width: '100%',
        minHeight: '5rem',
        textAlign: 'center',
    },
    disclaimerRowLink: {
        fontSize: '.85rem',
        fontWeight: 'bold',
        color: COLORS.brandCyan,
    },
    filterSortRow: {
        // currently unused in our search results screen, but may be put back in later if we need to virtualize
        // height: `${FILTER_ROW_HEIGHT}px`,
        marginTop: '0',
        display: 'flex',
        flexDirection: 'column',
    },
});
export type SearchResultRowItemProps = {
    onAddToListClick: (item: ProductsEntity) => void;
    lastResult?: any;
};

type SearchResultRowProps = {
    data: { items: any; itemProps: SearchResultRowItemProps };
    index: number;
    style?: any;
};
type categoryPropsType = {
    titleText?: string;
}
export function FilterSortButtonsRow({
    data,
    index,
    style,
}: SearchResultRowProps) {
    const classes = useSearchResultRowStyles();
    const { lastResult } = data.itemProps;
    return (
        <div
            style={style}
            id={`search-result-row-${index}`}
            className={classes.filterSortRow}
        >
            <FilterSortButtons lastResult={lastResult} />
            <Divider />
        </div>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function DisclaimerRow({ data, index, style }: SearchResultRowProps) {
    const classes = useSearchResultRowStyles();
    // if no search results are available then no need to render disclaimer row
    if (data.items.length === 0) {
        return <div>&nbsp;</div>;
    }
    return (
        <div
            style={style}
            id={`search-result-row-${index}`}
            className={classes.disclaimerRowContent}
        >
            <Typography className={classes.disclaimerRowText}>
                Can't find the item you're looking for? <br />
                Try{' '}
                <a
                    href="staples.com"
                    target="_blank"
                    className={classes.disclaimerRowLink}
                >
                    Staples.com
                </a>
            </Typography>
        </div>
    );
}

export function ProductRow({
    data,
    index,
    style,
    item,
    titleText = "",
}: SearchResultRowProps & { item: any } & categoryPropsType) {
    const classes = useSearchResultRowStyles();
    const { analyticsCustomEvent } = useEventsReporter();

    const { onAddToListClick } =
        data.itemProps;


    function handleAddToListClick(e: any) {
        if (e?.preventDefault) {
            e.preventDefault();
        }
        if (onAddToListClick) {
            analyticsCustomEvent(eventMetricsMap.product_search, {
                click_text: 'add to list',
                element_location: 'search results',
                product: item.title,
                product_category: titleText,
            });
            optimoveEvents(eventMetricsMap.scm_product_search, {
                click_text: 'add to list',
                element_location: 'search results',
                product: item.title,
                product_category: titleText,
            });
            onAddToListClick(item);
        }
    }

    return (
        <div
            style={style}
            id={`search-result-row-${index}`}
            className={classes.cardWrapper}
        >
            <FullWidthCard hasManualShadow className={classes.card}>
                <div className={classes.itemContent}>

                    <img
                        className={classes.itemImage}
                        src={item.image || require('../../../assets/icons/iconNoItemImage.svg')}
                        alt="product"
                    />

                    <div className={classes.itemDetailsContainer}>
                        <Typography className={classes.itemName}>{item.title}</Typography>

                        <ProductStarRating
                            ratingValue={item.rating}
                            totalReviews={item.ratingCount}
                        />
                        <div className={classes.prices}>
                            <Typography className={classes.itemPrice}>
                                {item.price}
                            </Typography>
                            {item?.originalPrice && (
                                <DiagonalStrikethroughText>
                                    {item?.originalPrice}
                                </DiagonalStrikethroughText>
                            )}
                        </div>
                    </div>

                </div>
                <div className={classes.itemActions}>
                    <ButtonBase onClick={handleAddToListClick}>
                        <Typography className={classes.addToList}>Add to List</Typography>
                    </ButtonBase>
                </div>
            </FullWidthCard>
        </div>
    );
}

/**
 * due to the dynamic row logic, SearchResultRow also contains the filter options and the row disclaimer
 * currently unused in our search results screen, but may be put back in later if we need to virtualize
 */
export default function DynamicSearchResultRow({
    data,
    index,
    style,
}: SearchResultRowProps) {
    if (index > data.items.length) {
        return <DisclaimerRow data={data} index={index} style={style} />;
    }
    if (index === 0) {
        // filter sort options
        return <FilterSortButtonsRow data={data} index={index} style={style} />;
    }
    const item = data.items[index - 1];
    return <ProductRow data={data} index={index} style={style} item={item} />;
}