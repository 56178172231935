/* eslint-disable import/namespace */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import useRouter from 'src/hooks/useRouter';
import { Button, Typography } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useUserInfo from 'src/hooks/useUserInfo';
import PullRefresh from 'src/components/pull-refresh';
import RewardCard from 'src/components/reward-card';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { fullName, makeEnrollQuery } from 'src/lib/utils/user-utils';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useDonationFlow from 'src/hooks/useDonationFlow';
import {
  useGiveNowError,
} from 'src/components/give-now-error-dialog';
import ROUTES from 'src/lib/routes';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { getAvailablePayoutAmount } from 'src/lib/utils/account-utils';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useQuery } from 'react-query';
import { CACHE_ONLY, NO_REFETCH } from 'src/lib/utils/react-query-utils';
import { getCustomerPriceTypes, getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import WrappedRewards from 'src/screens/rewards-old/base';
import LoadingOverlay from 'src/components/loading-overlay';
import COLORS from 'src/lib/colors';
import Header from 'src/components/header';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import SrwMemberCard from '../srw-membership-card';
import SecondaryAccount from '../secondary-account';
import SwitchButton from '../switch-button';
import SrwCardStep1 from '../SrwCardStep1';
import WalletTabs from '../wallet-tabs';
import useStyles from './styles';
import DigitalCoupons from '../digital-coupons';
import RewardsCarousel from '../RewardsCarousel';
import WalletSuspendedDialog from './walletSuspendedDialog';
import classroomRewardsBg from '../../../assets/bgs/classroomRewardsBg.svg';

const pageNameToTabName = {
  digitalcoupons: 'digital',
  classroomrewards: 'classroom',
  staplesrewards: 'staples',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RewardsWalletBase() {
  const classes = useStyles();
  const isFinished = false;
  const isClassRommReward = true;
  const cardExpanded = true;

  const { customEvent, analyticsCustomEvent, analyticsScreenCustomNames } = useEventsReporter();
  const { userInfo } = useUserInfo();
  const { classroomWalletRewardsTab,
    walletRewardsTab,
    staplesRewardsWalletTab,
    isScannbleDeepLink, staplesScannableCouponDeepLink,
    walletStaplesRewardsTab,
    walletRewardsTabWithoutRewardsScreen,
    classroomWalletRewardsTabWithoutRewardsScreen,
    navBottomActiveTab, setNavBottomActiveTab } =
    useUserUpdateContext();
  const { openDonationFlow } = useDonationFlow();
  const [latestCouponsList, setLatestCouponsList] = useState([]);
  const [isAllCoupunsRedeemed, setIsAllCoupunsRedeemed] = useState(false);
  const [couponSavings, setCouponSavings] = useState(0);
  const rewardsNumber = userInfo?.rewardsNumber || '0';
  const [isWalletSuspend, setWalletSuspend] = useState(false);
  const { fromPath, setFromPath } = useProductBrowseContext();

  const filterAndSetCouponList = (couponDataList: any) => {
    if (!couponDataList?.length) return;
    const getCoupon: any = couponDataList?.filter(
      (coupon: any) => coupon.category === 'Coupon'
    );
    const getRedeemedCoupons =
      couponDataList?.filter(
        (coupon: any) =>
          coupon.category === 'Coupon' && coupon.couponInfo.isRedeemed
      ).length === getCoupon.length;
    if (getCoupon.length > 0 && getRedeemedCoupons) {
      setIsAllCoupunsRedeemed(getRedeemedCoupons);
    }
    const getCouponByPriority: any =
      getCoupon?.sort((a: any, b: any) =>
        a.couponInfo.priority > b.couponInfo.priority ? 1 : -1
      ) || [];
    setLatestCouponsList(getCouponByPriority);
  };

  const { query, history, pathname, push } = useRouter();
  console.log("🚀 ~ file: RewardsWalletBase.tsx:99 ~ RewardsWalletBase ~ pathname:", pathname)
  const { activeTab, setActiveTab } = useTabsUIStateContext()

  const {
    data: staplesRewardsDetail,
    isFetching,
    refetch,
  } = useQuery(
    ['details', rewardsNumber],
    () => getStaplesRewardsData(rewardsNumber),
    {
      ...CACHE_ONLY,
    }
  );

  const {
    data: customerPriceTypeData,
  } = useQuery(
    ['CustomerPriceTypes', userInfo?.rewardsNumber],
    () => getCustomerPriceTypes(userInfo?.rewardsNumber),
    {
      ...NO_REFETCH,
      enabled: !!userInfo?.rewardsNumber,
    }
  );

  useEffect(() => {
    if (navBottomActiveTab === ROUTES.rewardsWallet && fromPath === ROUTES.rewardsWallet) {
      setActiveTab('digital');
      setNavBottomActiveTab("")
    }
  }, [navBottomActiveTab])

  useEffect(() => {
    if (isScannbleDeepLink) {
      refetch();
      staplesScannableCouponDeepLink();
    }
    if (userInfo?.walletStatus === "SUSPENDED" &&
      activeTab === 'classroom') {
      setWalletSuspend(true)
    }
  }, []);

  useEffect(() => {
    if (userInfo?.walletStatus === "SUSPENDED" &&
      activeTab === 'classroom') {
      setWalletSuspend(true)
    }
  }, [activeTab, userInfo?.walletStatus]);
  useEffect(() => {
    if (staplesRewardsDetail?.data) {
      setCouponSavings(staplesRewardsDetail?.data?.couponSavings);
      filterAndSetCouponList(staplesRewardsDetail?.data?.couponDataList);
    }
  }, [staplesRewardsDetail?.data]);

  useEffect(() => {
    if (userInfo?.rewardsNumber) {
      analyticsScreenCustomNames({
        rewards_number: userInfo?.rewardsNumber,
      });
    } else {
      analyticsScreenCustomNames({
        rewards_number: '',
      });
    }
    if (userInfo?.preferredStoreId) {
      analyticsScreenCustomNames({
        store_id: userInfo?.preferredStoreId,
      });
    } else {
      analyticsScreenCustomNames({
        store_id: '',
      });
    }
  }, []);

  const toggleActiveTab = (tabName: any) => {
    let eventToLog;
    switch (tabName) {
      case 'classroom':
        eventToLog = eventMetricsMap.wallet_classroomrewards_toggleselected;
        break;
      case 'staples':
        eventToLog = eventMetricsMap.wallet_staplesrewards_toggleselected;
        break;
      case 'digital':
        eventToLog = eventMetricsMap.Wallet_DigitalCoupons_toggleselected;
        break;
      default:
        eventToLog = eventMetricsMap.wallet_staplesrewards_toggleselected;
    }
    customEvent(eventToLog);
    analyticsCustomEvent(eventMetricsMap.wallet, {
      click_text: eventToLog,
      element_location: 'wallet navigation',
    });
    optimoveEvents(eventMetricsMap.scm_wallet, {
      click_text: eventToLog,
      element_location: 'wallet navigation',
    });
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (pathname === '/wallet/digitalcoupons') {
      toggleActiveTab('digital');
    } else if (pathname === '/wallet/staplesrewards') {
      toggleActiveTab('staples');
    } else if (pathname === '/wallet/classroomrewards') {
      toggleActiveTab('classroom');
    }
  }, [pathname])
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [isRewardCard, setIsRewardCard] = useState(true);

  function openShareDialog() {
    setShowShareDialog(true);
  }
  function closeShareDialog() {
    setShowShareDialog(false);
  }

  const availablePayoutAmount = getAvailablePayoutAmount(
    userInfo?.rewardsDetails
  );
  const availableDonationAmount =
    userInfo?.rewardsDetails?.availableDonationAmount;
  const { variant, setVariant, handlePrimaryBtnClick, open, handleClose } =
    useGiveNowError(openShareDialog);
  const classRoomRewards = localStorage?.getItem('classRoomRewards') === 'true';

  function giveNow() {
    customEvent(eventMetricsMap.wallet_classroomrewards_givenow);
    analyticsCustomEvent(eventMetricsMap.wallet, {
      click_text: 'give now',
      element_location: 'available to donate',
      wallet_location: 'classroom rewards',
    });
    optimoveEvents(eventMetricsMap.scm_wallet, {
      click_text: 'give now',
      element_location: 'available to donate',
      wallet_location: 'classroom rewards',
    });
    if (!availableDonationAmount) {
      setVariant('give');
    } else {
      openDonationFlow();
    }
  }

  const updateControllerValues = (newValues: any) => {
    /* if (newValues.donateStep === 'success') {
      // refresh user state as soon as we go to the success screen
      // to give more time to load the new user data behind the scenes
      notifyUserUpdate();
    } */
    history.push({
      pathname,
      search: makeSearchString({ ...newValues }),
    });
  };

  const navigateToRewardsSignUp = () => {
    if (userInfo?.rewardsNumber) {
      history.push(ROUTES.rewards);
    } else if (userInfo) {
      const { userType, userConfirmState } = userInfo;
      if (userConfirmState !== 'Confirmed') {
        history.push({
          pathname,
          search: makeSearchString(makeEnrollQuery(userType, userConfirmState)),
        });
      }
    }
  };
  const navigateToRewardsSignIn = () => {
    updateControllerValues({ enrollStep: 'step2', flowName: 'SRW' });
  };

  function beginOrContinueEnrollFlow() {
    if (userInfo) {
      const { userType, userConfirmState } = userInfo;
      if (userConfirmState !== 'Confirmed') {
        history.push({
          pathname,
          search: makeSearchString(makeEnrollQuery(userType, userConfirmState)),
        });
      }
    }
  }

  const handleGiveBack = () => {
    if (userInfo?.rewardsNumber) {
      history.push(ROUTES.rewards);
    } else {
      customEvent(eventMetricsMap.wallet_staplesrewards_joinnow);
      analyticsCustomEvent(eventMetricsMap.wallet, {
        click_text: 'join now',
        element_location: 'wallet banner',
        wallet_location: 'staples rewards',
      });
      optimoveEvents(eventMetricsMap.scm_wallet, {
        click_text: 'join now',
        element_location: 'wallet banner',
        wallet_location: 'staples rewards',
      });
      history.push({
        pathname,
        search: makeSearchString({
          enrollStep: 'step2',
        }),
      });
    }
  };

  const handleSRWGiveBack = () => {
    history.push({
      pathname,
      search: makeSearchString({
        enrollStep: 'step2',
        flowName: 'SRW',
      }),
    });
  };

  useEffect(() => {
    if (walletRewardsTab) {
      setActiveTab('classroom');
      setIsRewardCard(false);
      classroomWalletRewardsTab();
    } else if (walletStaplesRewardsTab) {
      setActiveTab('staples');
      setIsRewardCard(false);
      staplesRewardsWalletTab();
    } else if (walletRewardsTabWithoutRewardsScreen) {
      setActiveTab('classroom');
      classroomWalletRewardsTabWithoutRewardsScreen()
    }
  }, [walletRewardsTab, walletStaplesRewardsTab, walletRewardsTabWithoutRewardsScreen]);
  const handleOnRefetchData = () => {
    refetch();
  };

  const handleCloseButton = () => {
    setIsRewardCard(true);
    window.scrollTo(0, 0);
    history.push({
      pathname: ROUTES.rewardsWallet,
      search: '',
    });
  };
  const getSwitchTab = () =>
    <>
      <div className={classes.rewardsWalletText}>
        <Typography
          id="welcome-message-text"
          variant="h1"
          className={`${classes.welcomeMessage}  ${classes.whiteTitle}`}
        >
          Coupons & Rewards
        </Typography>
      </div>
      <SwitchButton
        activeTabName={activeTab}
        toggleActiveTab={toggleActiveTab}
        user={userInfo?.userType}
        userWalletId={userInfo?.walletId}
        isUserConfirmed={userInfo?.userConfirmState}
      />
    </>


  const handleWalletSuspendClose = () => {
    setWalletSuspend(false);
  }

  return (
    <PullRefresh
      className="pullRefreshContainer"
      isPullable={activeTab === 'digital'}
      refetchData={handleOnRefetchData}
    >
      <Header />
      <div style={userInfo?.userType !== 'Supporter' && classRoomRewards ?
        {} : { backgroundColor: COLORS.brandRed, backgroundImage: `url(${classroomRewardsBg})` }}
        className={
          activeTab === 'staples' || activeTab === 'digital'
            ? `${classes.screen} ${activeTab === 'staples' && classes.srwSscreen}`
            : classes.classRoomScreen
        }
      >
        {activeTab === 'staples' ? (
          <div>
            <div className={classes.rewardsWalletText}>
              <Typography
                id="welcome-message-text"
                variant="h1"
                className={`${classes.welcomeMessage} ${classes.darkTitle}`}
              >
                Coupons & Rewards
              </Typography>
            </div>

            <SwitchButton
              activeTabName={activeTab}
              toggleActiveTab={toggleActiveTab}
              user={userInfo?.userType}
              userWalletId={userInfo?.walletId}
              isUserConfirmed={userInfo?.userConfirmState}
            />

            {/* based on enrollment component has to toggle */}
            <SecondaryAccount />
            {userInfo?.rewardsNumber !== null ? (
              <div className={classes.srwMmberCardWrapper}>
                <SrwMemberCard expanded={cardExpanded}
                  customerPriceTypeData={customerPriceTypeData} />
              </div>
            ) : (
              <div>
                <SrwCardStep1
                  handleGiveBack={handleGiveBack}
                  navigateToRewardsSignUp={navigateToRewardsSignUp}
                  handleSRWGiveBack={() => {
                    customEvent(eventMetricsMap.wallet_staplesrewards_joinnow);
                    analyticsCustomEvent(eventMetricsMap.wallet, {
                      click_text: 'join now',
                      element_location: 'wallet banner',
                      wallet_location: 'staples rewards',
                    });
                    optimoveEvents(eventMetricsMap.scm_wallet, {
                      click_text: 'join now',
                      element_location: 'wallet banner',
                      wallet_location: 'staples rewards',
                    });
                    handleSRWGiveBack();
                  }}
                  navigateToRewardsSignIn={navigateToRewardsSignIn}
                />
                <RewardsCarousel type="staples_rewards" />
              </div>
            )}
            {userInfo?.rewardsNumber !== null && <WalletTabs />}
          </div>
        ) : activeTab === 'classroom' ? (
          <div>
            {/* <SwitchButton
                activeTabName={activeTab}
                toggleActiveTab={toggleActiveTab}
              /> */}

            {/* {userInfo?.userConfirmState === 'Confirmed' ? ( */}
            <div>
              {isRewardCard ? (
                <WrappedRewards
                  switchTab={getSwitchTab}
                />
              ) : (
                <div className={classes.classRoomContainer}>
                  {getSwitchTab()}
                  <div className={classes.rewardCardWrapper}>
                    <RewardCard
                      variant={
                        userInfo?.userType === 'Administrator'
                          ? 'school'
                          : 'teacher'
                      }
                      cardOwner={fullName(userInfo)}
                      amountAvailable={availablePayoutAmount}
                      expiration={
                        userInfo?.rewardsDetails?.promoCard?.expiration
                      }
                      cardNumber={userInfo?.rewardsDetails?.promoCard?.number}
                      pin={userInfo?.rewardsDetails?.promoCard?.pin}
                    />
                  </div>
                  <Button
                    className={classes.blackButton}
                    onClick={handleCloseButton}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
            {/* ) : ( */}
            {/* <div>
                  <ClassroomRewardStep1
                    updateControllerValues={updateControllerValues}
                    handleGiveBack={() => {
                      customEvent(
                        eventMetricsMap.wallet_classroomrewards_givebacknow
                      );
                      beginOrContinueEnrollFlow();
                    }}
                    navigateToRewardsSignUp={navigateToRewardsSignUp}
                  /> */}
            {/* <Typography
                    id="welcome-message-text"
                    variant="h1"
                    className={`${classes.welcomeMessage} ${classes.whiteTitle}`}
                  >
                    Let's find your school
                  </Typography>
                  <DonationSearchBegin
                    isClassRommReward={isClassRommReward}
                    finishFlow={finishFlow}
                    updateControllerValues={updateControllerValues}
                    isFinished={isFinished} /> */}
            {/* </div> */}
            {/* )} */}
          </div>
        ) : activeTab === 'digital' ? (
          <div>
            <div className={classes.rewardsWalletText}>
              <Typography
                id="welcome-message-text"
                variant="h1"
                className={`${classes.welcomeMessage} ${classes.darkTitle}`}
              >
                Coupons & Rewards
              </Typography>
            </div>

            <SwitchButton
              activeTabName={activeTab}
              toggleActiveTab={toggleActiveTab}
              user={userInfo?.userType}
              userWalletId={userInfo?.walletId}
              isUserConfirmed={userInfo?.userConfirmState}
            />
            <SecondaryAccount />
            {userInfo?.rewardsNumber !== null ? (
              <div className={classes.srwMmberCardWrapper}>
                <SrwMemberCard expanded={cardExpanded}
                  customerPriceTypeData={customerPriceTypeData} />
              </div>
            ) : (
              <SrwCardStep1
                handleGiveBack={handleGiveBack}
                navigateToRewardsSignUp={navigateToRewardsSignUp}
                cardType="digital"
                handleSRWGiveBack={() => {
                  customEvent(eventMetricsMap.wallet_staplesrewards_joinnow);
                  analyticsCustomEvent(eventMetricsMap.wallet, {
                    click_text: 'join now',
                    element_location: 'wallet banner',
                    wallet_location: 'staples rewards',
                  });
                  optimoveEvents(eventMetricsMap.scm_wallet, {
                    click_text: 'join now',
                    element_location: 'wallet banner',
                    wallet_location: 'staples rewards',
                  });
                  handleSRWGiveBack();
                }}
                navigateToRewardsSignIn={navigateToRewardsSignIn}
              />
            )}
            <DigitalCoupons
              staplesRewardsDetail={staplesRewardsDetail}
              latestCouponsList={latestCouponsList}
              isAllCoupunsRedeemed={isAllCoupunsRedeemed}
              couponSavings={couponSavings}
              filterAndSetCouponList={filterAndSetCouponList}
            />
          </div>
        ) : null}
        {isFetching ? <LoadingOverlay
          variant="wordmark"
          theme="transparent-light"
          open={isFetching}
        /> : null}
      </div>
      <WalletSuspendedDialog open={isWalletSuspend}
        onClose={handleWalletSuspendClose} />
    </PullRefresh>
  );
}

export default () => <RewardsWalletBase />;