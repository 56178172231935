/* eslint-disable import/prefer-default-export */

import Log from 'src/lib/logging';
/** various conversions for parsing env vars */

/** parse an env var as an int, falling back if not set */
export const parseEnvAsInt = (str: string | undefined, fallback: number) => {
  let safeInt: number = fallback;
  try {
    if (str !== undefined) {
      safeInt = Number.parseInt(str, 10);
    }
  } catch {
    Log.env(`Invalid int value in env: ${str}, defaulting to ${fallback}`);
    safeInt = fallback;
  }
  if (Number.isNaN(safeInt)) {
    Log.env(
      `Invalid int value in env: ${str} (NaN), defaulting to ${fallback}`
    );
    safeInt = fallback;
  }

  return safeInt;
};

export const parseTypeAsInt = <T>(
  str: string | undefined,
  allowedTypes: T[],
  fallback: T
): T => {
  let safeEnum: T = fallback;
  if (allowedTypes.find((t) => (t as any) === str) !== undefined) {
    safeEnum = allowedTypes.find((t) => (t as any) === str) || fallback;
  } else {
    Log.env(
      `Invalid value in env: ${str}. Valid values ${JSON.stringify(
        allowedTypes
      )}. Defaulting to "${fallback}"`
    );
  }
  return safeEnum as T;
};
