 import React from 'react';
import IconInterface from './IconInterface';
 
 
function IconProfile({ width = 40, height = 40 }: IconInterface) {
    return (
        <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14" stroke="#CC0000" strokeWidth="2"/>
            <path d="M22.449 21.5072C21.6327 19.3351 19.8639 17.706 17.6871 16.8915C20.068 15.3982 20.8163 12.2758 19.3197 9.90005C17.8231 7.52433 14.7619 6.77767 12.3129 8.27098C9.86394 9.7643 9.18367 12.8867 10.6803 15.3303C11.0884 16.0091 11.6326 16.5521 12.3129 16.9594C10.1361 17.706 8.43537 19.403 7.55102 21.5751C7.41497 21.9145 7.55102 22.3217 7.95918 22.4575C8.09524 22.4575 8.16326 22.4575 8.23129 22.4575C8.5034 22.4575 8.77551 22.2538 8.84353 22.0502C10.2041 18.6563 14.0136 16.9594 17.415 18.3169C19.1156 18.9957 20.4762 20.3533 21.1565 22.0502C21.2925 22.3896 21.7007 22.5932 22.0408 22.4575C22.449 22.2538 22.585 21.9145 22.449 21.5072ZM11.2925 12.6152C11.2925 10.5788 12.9932 8.88188 15.034 8.88188C17.0748 8.88188 18.7755 10.5788 18.7755 12.6152C18.7755 14.6515 17.0748 16.3485 15.034 16.3485C12.9932 16.3485 11.2925 14.7194 11.2925 12.6152Z" fill="#CC0000" stroke="#CC0000" strokeWidth="0.25"/>
        </svg>

    );
}
 
export default IconProfile;