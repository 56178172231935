import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import FullWidthCard from 'src/components/cards/full-width-card';
import COLORS from 'src/lib/colors';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import { format } from 'date-fns';
import { saveDigitalCoupon } from 'src/lib/api-client/wallet-activities';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useUserInfo from 'src/hooks/useUserInfo';
import ScannableCouponDialog from 'src/components/ScannableCoupon/ScannableCouponDialog';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import { makeSearchString } from 'src/lib/utils/url-utils';

const useStyles = makeStyles((theme) => ({
    rewardCard: {
        minHeight: '180px',
        display: 'flex',
        cursor: 'pointer',
        gap: '1rem',
        justifyContent: 'space-between',
        padding: '12px',
    },
    addCoponsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '15px',
    },
    localStaplesCTALeftContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flex: '60%',
    },
    localStaplesCTATopText: {
        marginTop: '0.8rem',
        marginBottom: '.25rem',
        paddingLeft: '1rem',
        fontWeight: 'bold',
        fontSize: '12px',
        color: COLORS.secondaryNeutralCharcoal,
        [theme.breakpoints.down(362)]: {
            marginBottom: 0,
        },
    },
    localStaplesCTARightImage: {
        width: '26vw',
        height: 'auto',
        maxWidth: '140px',
        objectFit: ({ isMobileWidth }: any) =>
            isMobileWidth ? 'cover' : 'fill',
    },
    imageAlignment: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '35%',
    },
    desAlignPrice: {
        minWidth: '8rem',
        width: '35%',
        height: 'auto',
        objectFit: ({ isMobileWidth }: any) =>
            isMobileWidth ? 'cover' : 'fill',
        fontSize: '36px',
        color: COLORS.brandRed,
        fontWeight: 700,
        lineHeight: '1.3rem',
        letterSpacing: '-0.6pt',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '14px',
    },
    weeklyAdInfoText: {
        fontSize: '1rem',
        fontWeight: 500,

        [theme.breakpoints.down(362)]: {
            fontSize: '0.9rem',
        },
    },
    shortText1: {
        fontSize: '26px',
        color: COLORS.brandRed,
        fontWeight: 700,
    },
    fullwidthStyle: {
        borderRadius: '8px !important',
        marginBottom: '20px !important',
        marginLeft: '0px !important',
        marginRight: '0px !important',
        boxShadow: '0 0 4px #eee!important',
    },
    expDateAlign: {
        fontWeight: 400,
        fontSize: '12px',
        color: '#999999',
        marginTop: '8px',
    },
    saveExpDate: {
        fontWeight: 700,
        fontSize: '12px',
        color: '#999999',
        marginTop: '8px',
    },
    saveTextColor: {
        color: COLORS.homeBlack,
        fontWeight: 500,
    },
    removeButton: {
        color: COLORS.primaryWhite,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px 8px',
        width: '120px',
        background: `${COLORS.homeBlack} !important`,
        borderRadius: '20px',
        marginTop: '10px',
        gap: '10px',
    },
    saveButton: {
        color: COLORS.brandRed,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px 8px',
        width: '173px',
        borderRadius: '20px',
        background: 'transparent !important',
        marginTop: '10px',
        gap: '10px',
        border: `1px solid ${COLORS.brandRed}`,
        fontSize: '12px',
    },
    bottomInfoText: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px',
    },
    redeemInfoText: {
        fontWeight: 400,
        fontSize: '12px',
        color: '#999999',
    },
    tapVector: {
        fontWeight: 400,
        fontSize: '12px',
        color: '#086DD2',
        display: 'flex',
        alignItems: 'center',
    },
    imageAlign: {
        marginRight: '5px',
        height: '14px',
        width: '14px',
    },
    limitedTimeBtn: {
        height: '30px',
        width: '154px',
        borderRadius: '75px',
        background: '#086DD2',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0px',
        textlign: 'center',
        color: '#FFFFFF',
        marginTop: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
    },
}));

const CardContainer = ({
    couponInfo,
    couponNumber,
    tab,
}: {
    couponInfo: any;
    couponNumber: number;
    tab: any;
}) => {
    const isMobileWidth = useIsMobileWidth();
    const classes = useStyles({ isMobileWidth });
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { history } = useRouter();
    const { userInfo } = useUserInfo({});
    const rewardsNumber = userInfo?.rewardsNumber || '0';
    const { refetch: saveDigitalCouponQuery } = useQuery(
        ['details', rewardsNumber],
        () => {
            if (rewardsNumber) {
                const parameter = {
                    enrolled: rewardsNumber !== '0',
                    coupon_id: couponNumber,
                    rewards_number: rewardsNumber,
                    offer_title: `${couponInfo.descriptionText1} ${couponInfo.descriptionText2}`,
                };
                customEvent(eventMetricsMap.save_reward, parameter);
                analyticsCustomEvent(eventMetricsMap.wallet, {
                    click_text: 'save & extend reward',
                    element_location: 'available',
                    wallet_location: 'digital coupons',
                    offer_description: `${couponInfo.descriptionText1} ${couponInfo.descriptionText2}`,
                });
                optimoveEvents(eventMetricsMap.scm_wallet, {
                    click_text: 'save & extend reward',
                    element_location: 'available',
                    wallet_location: 'digital coupons',
                    offer_description: `${couponInfo.descriptionText1} ${couponInfo.descriptionText2}`,
                });
                return saveDigitalCoupon({
                    rewardsNumber,
                    couponCode: couponNumber,
                    isSaved: !couponInfo.isSaved,
                });
            }
            return null;
        },
        {
            enabled: false,
        }
    );
    const renderRedeemOption = (): any => {
        let renderRedeemText;
        if (couponInfo?.validForRetail && couponInfo?.validForDotcom) {
            renderRedeemText = (
                <span>
                    <img
                        src={require('../../assets/icons/shoppingBag.svg')}
                        alt="redeem in store icon"
                        className={classes.imageAlign}
                    />
                    <img
                        src={require('../../assets/icons/onlineRedeem.svg')}
                        alt="redeem online icon"
                        className={classes.imageAlign}
                    />
                    Redeem in-store or online
                </span>
            );
        } else if (couponInfo?.validForRetail) {
            renderRedeemText = (
                <span>
                    <img
                        src={require('../../assets/icons/onlineRedeem.svg')}
                        alt="redeem in store icon"
                        className={classes.imageAlign}
                    />
                    {!couponInfo?.isRedeemed
                        ? `Redeem in-store`
                        : `Redeemed in-store`}
                </span>
            );
        } else if (couponInfo?.validForDotcom) {
            renderRedeemText = (
                <span>
                    <img
                        src={require('../../assets/icons/shoppingBag.svg')}
                        alt="redeem online icon"
                        className={classes.imageAlign}
                    />
                    Redeem online
                </span>
            );
        } else {
            renderRedeemText = <span />;
        }
        return renderRedeemText;
    };
    const tabSwitch = (couponData: any) => {
        if (couponData.isRedeemed) {
            return 'redeemed';
        }
        if (couponData.isSaved) {
            return 'saved';
        }
        return 'available';
    };
    return (
        <FullWidthCard
            cardContainerClassName={classes.fullwidthStyle}
            noPadding
            id="classroom-rewards-number-card"
            onClick={() => {
                if (!couponInfo?.isRedeemed) {
                    history.push({
                        pathname: ROUTES.coupons,
                        search: makeSearchString({
                            couponNumber,
                            deepLink: false,
                        }),
                    });
                    analyticsCustomEvent(eventMetricsMap.wallet, {
                        click_text: 'view reward',
                        element_location: tabSwitch(couponInfo),
                        wallet_location: 'digital coupons',
                        offer_description: `${couponInfo?.descriptionText1} ${couponInfo?.descriptionText2}`,
                    });
                    optimoveEvents(eventMetricsMap.scm_wallet, {
                        click_text: 'view reward',
                        element_location: tabSwitch(couponInfo),
                        wallet_location: 'digital coupons',
                        offer_description: `${couponInfo?.descriptionText1} ${couponInfo?.descriptionText2}`,
                    });
                }
            }}
        >
            <div className={classes.rewardCard}>
                <div className={classes.imageAlignment}>
                    {couponInfo.couponApplicable === 'Basket' ? (
                        <Typography className={classes.desAlignPrice}>
                            <div>
                                {couponInfo?.descriptionText1.split(' ')[0]}
                            </div>
                            <div>
                                {couponInfo?.descriptionText1.split(' ')[1]}
                            </div>
                            {couponInfo?.descriptionText1.split(' ')[2] ? <div>
                                {couponInfo?.descriptionText1.split(' ')[2]}
                            </div> : null}
                        </Typography>
                    ) : (
                        <img
                            className={classes.localStaplesCTARightImage}
                            src={
                                couponInfo?.imageURL === null
                                    ? require('../../assets/icons/noCouponImage.svg')
                                    : couponInfo?.imageURL
                            }
                            alt="wallet coupon"
                        />
                    )}
                </div>
                <img
                    src={require('../../assets/icons/verticalDottedLine.svg')}
                    alt="rewards modal icon"
                />
                <Box className={classes.localStaplesCTALeftContent}>
                    <Typography className={classes.localStaplesCTATopText}>
                        {couponInfo?.title}
                    </Typography>
                    {couponInfo?.descriptionText1 &&
                        couponInfo?.couponApplicable !== 'Basket' && (
                            <Typography className={classes.shortText1}>
                                {couponInfo?.descriptionText1}
                            </Typography>
                        )}
                    <Typography className={classes.weeklyAdInfoText}>
                        {couponInfo?.descriptionText2}
                    </Typography>
                    {couponInfo?.expirationDate ? (
                        <Typography
                            className={
                                couponInfo?.isSaved && tab === 0 ? classes.saveExpDate : classes.expDateAlign
                            }
                        >
                            {`Exp ${format(new Date(couponInfo?.expirationDate), 'MM/dd/yy')}`}
                        </Typography>
                    
                    ) : null}
                    {couponInfo.isLimitedTime ? (
                        <div className={classes.limitedTimeBtn}>
                            {' '}
                            limited time only
                        </div>
                    ) : null}
                    {!couponInfo?.isRedeemed &&
                        !couponInfo?.isSaved &&
                        !couponInfo.isLimitedTime ? (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                saveDigitalCouponQuery();
                            }}
                            className={
                                couponInfo?.isSaved
                                    ? classes.removeButton
                                    : classes.saveButton
                            }
                        >
                            Save &#38; extend to{' '}
                            {format(
                                new Date(couponInfo?.expirationDate),
                                'MM/dd/yy'
                            )}
                        </Button>
                    ) : null}
                    {tab !== 1 &&
                        couponInfo?.isSaved &&
                        couponInfo?.couponClass !== 'LimitedTime' ? (
                        <Typography
                            className={`${classes.expDateAlign} ${classes.saveTextColor}`}
                        >
                            Saved! You have extended the expiration date.
                        </Typography>
                    ) : null}
                </Box>
            </div>
            <div className={classes.bottomInfoText}>
                <div className={classes.redeemInfoText}>
                    {renderRedeemOption()}
                    {/* {couponInfo?.validForRetail && (
            <span>
              <img
                src={require('../../assets/icons/onlineRedeem.svg')}
                alt="redeem in store icon"
                className={classes.imageAlign}
              />
              {!couponInfo?.isRedeemed
                ? `Redeem in-store`
                : `Redeemed in-store`}
            </span>
          )}
          {couponInfo?.validForDotcom && (
            <span>
              <img
                src={require('../../assets/icons/shoppingBag.svg')}
                alt="redeem online icon"
                className={classes.imageAlign}
              />
              Redeem online
            </span>
          )}
          {couponInfo?.validForRetail && couponInfo?.validForDotcom && (
            <span>
              <img
                src={require('../../assets/icons/shoppingBag.svg')}
                alt="redeem in store icon"
                className={classes.imageAlign}
              />
              <img
                src={require('../../assets/icons/onlineRedeem.svg')}
                alt="redeem online icon"
                className={classes.imageAlign}
              />
              Redeem in-store or online
            </span>
          )} */}
                </div>
                {!couponInfo?.isRedeemed ? (
                    <div className={classes.tapVector}>
                        <img
                            src={require('../../assets/icons/tapVector.svg')}
                            alt="Tap to use icon"
                            className={classes.imageAlign}
                        />
                        <span>Tap to use</span>
                    </div>
                ) : null}
            </div>
        </FullWidthCard>
    );
};

/** used for loading and displaying classroom reward summary */
export function DigitalCouponsWallet({ staplesRewardsDetail, tab }: any) {
    const isMobileWidth = useIsMobileWidth();
    const { userInfo } = useUserInfo({});
    const classes = useStyles({ isMobileWidth });

    return (
        <>
            <Box className={classes.addCoponsContainer}>
                {staplesRewardsDetail?.map(
                    ({ couponInfo, couponNumber }: any) => {
                        if (tab === 0) {
                            if (
                                userInfo?.rewardsNumber &&
                                !couponInfo?.isRedeemed
                            ) {
                                return (
                                    <CardContainer
                                        couponInfo={couponInfo}
                                        couponNumber={couponNumber}
                                        tab={tab}
                                    />
                                );
                            }
                            return couponInfo?.isNewCoupon &&
                                !couponInfo?.isRedeemed ? (
                                <CardContainer
                                    couponInfo={couponInfo}
                                    couponNumber={couponNumber}
                                    tab={tab}
                                />
                            ) : null;
                        }
                        if (userInfo?.rewardsNumber && tab === 1) {
                            return (
                                couponInfo?.isSaved &&
                                !couponInfo?.isRedeemed && (
                                    <CardContainer
                                        couponInfo={couponInfo}
                                        couponNumber={couponNumber}
                                        tab={tab}
                                    />
                                )
                            );
                        }
                        if (userInfo?.rewardsNumber && tab === 2) {
                            return (
                                couponInfo?.isRedeemed && (
                                    <CardContainer
                                        couponInfo={couponInfo}
                                        couponNumber={couponNumber}
                                        tab={tab}
                                    />
                                )
                            );
                        }
                        return couponInfo?.isNewCoupon &&
                            tab === 1 &&
                            couponInfo?.isSaved &&
                            !couponInfo?.isRedeemed ? (
                            <CardContainer
                                couponInfo={couponInfo}
                                couponNumber={couponNumber}
                                tab={tab}
                            />
                        ) : null;
                    }
                )}
            </Box>
        </>
    );
}

export default DigitalCouponsWallet;
