/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import NavTop from 'src/components/nav-top';
import { Box } from '@material-ui/core';
import { Body, Heading, Button, SwipeableDrawer } from 'src/components/UI';
import {
    useUserInfo,
    useRouter,
    useStickyNotification,
    useEventsReporter,
    eventMetricsMap,
} from 'src/hooks';
import { getLoyaltyAccountDetailsQueryKey } from 'src/services/easyRewardsServices';
import FullWidthCard from 'src/components/cards/full-width-card';
import {
    IconEasyRewardsHorizontal,
    IconStaplesLogo,
} from 'src/components/Icons';
import { mutateTermsAndConditions } from 'src/services/userServices';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { LOGIN_CREATE_NEW_USER } from 'src/utils/stringConstants';
import ROUTES from 'src/lib/routes';
import { validateUserInfo } from 'src/utils/userUtils';
import {Player} from "@lottiefiles/react-lottie-player";
import { RewardsEnrollErrorDialog } from '../CommonComponents';
import styles from './styles.module.scss';

function RewardsEnrollmentConfirmationScreen() {
    const { push } = useRouter();
    const [tosSource, setTosSource] = useState<string | null>(null);
    const { userInfo } = useUserInfo();
    const { addNotification } = useStickyNotification();
    const { analyticsCustomEvent } = useEventsReporter();
    const queryClient = useQueryClient();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const { mutate: setTermsAndConditions, isLoading } =
        mutateTermsAndConditions({
            onSuccess: () => {
                queryClient.invalidateQueries(getLoyaltyAccountDetailsQueryKey);
                push(REWARDS_ENROLLMENT_ROUTES.welcomeAnimation, {
                    onCompleteNavigateTo: REWARDS_ENROLLMENT_ROUTES.favorites,
                });
            },
            onError: () => setShowErrorDialog(true),
        });

    const handleAccept = () => {
        if (validateUserInfo(userInfo)) {
            analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
                click_text: 'accept',
                element_location: 'accept terms & conditions',
                logged_in_user: true, // Set the logged-in user property to true
            });
            setTermsAndConditions();
        } else {
            push(REWARDS_ENROLLMENT_ROUTES.missingInfoConfimation);
        }
    };

    const handleReject = () => {
        analyticsCustomEvent(eventMetricsMap.easy_rewards_enroll, {
            click_text: `I'll do this later`,
            element_location: 'accept terms & conditions',
            logged_in_user: true, // Set the logged-in user property to true
        });
        addNotification({
            id: 'loyaltyTOSNotification',
            group: 'loyaltyTOS',
        });
        if (validateUserInfo(userInfo)) {
            push(ROUTES.home);
        } else {
            push(REWARDS_ENROLLMENT_ROUTES.missingInfoConfimation);
        }
    };

    return !userInfo ? null : (
        <>
            <Box className={styles.screen}>
            <img aria-hidden="true"
                 src={require('src/assets/icons/img_migrated_pill_graphic.svg')}
                 style={{ position: 'absolute', top: '0px', right: '0px' }}
                 alt="start shipping"
            />
            <FullWidthCard
                    cardContainerClassName={styles.dialogContainer}
                >
                    <Heading className={styles.paddingBottom} type="h2" size={30} color={"black"} weight="bold">
                        Hi, {userInfo?.firstName}!
                    </Heading>
                    <div className={styles.confirmContainer}>
                        <Heading type="h4" size={18} weight="bold">
                            We're excited to introduce
                        </Heading>
                        <div>
                            <IconEasyRewardsHorizontal
                                width={300}
                                height={45}/>
                        </div>
                        <Body lineHeight={20} weight="light">
                            A loyalty program where it's fast to earn, easy to
                            redeem and personalized by you.
                        </Body>
                        <Body lineHeight={20} weight="light">
                            Your Staples Rewards<sup>®</sup> account has been
                            converted to Easy Rewards. Accept the terms to get
                            started.
                        </Body>
                        <Body size={12} weight="light">
                            By clicking Accept, you agree to Staples and Easy
                            Rewards{' '}
                            <Button
                                variant="text"
                                display="inlineFlex"
                                className={styles.subLink}
                                onClick={() =>
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`
                                    )
                                }
                            >
                                <Body
                                    size={12}
                                    color="textLinkBlue"
                                    weight="normal"
                                >
                                    {LOGIN_CREATE_NEW_USER.TERM_CONDITION}
                                </Body>
                            </Button>
                            ,{' '}
                            <Button
                                variant="text"
                                display="inlineFlex"
                                className={styles.subLink}
                                onClick={() =>
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/hc?id=dbb94c10-973c-478b-a078-00e58f66ba32`
                                    )
                                }
                            >
                                <Body
                                    size={12}
                                    color="textLinkBlue"
                                    weight="normal"
                                >
                                    {LOGIN_CREATE_NEW_USER.PRIVACY_POLICY}
                                </Body>
                            </Button>
                            , and{' '}
                            <Button
                                variant="text"
                                display="inlineFlex"
                                className={styles.subLink}
                                onClick={() =>
                                    setTosSource(
                                        `${process.env.REACT_APP_DOTCOM_URL}/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188`
                                    )
                                }
                            >
                                <Body
                                    size={12}
                                    color="textLinkBlue"
                                    weight="normal"
                                >
                                    {LOGIN_CREATE_NEW_USER.CALIFORNIA}
                                </Body>
                            </Button>
                            . You also agree to receive Staples promotional
                            communications. You may unsubscribe at any time.
                        </Body>
                        <Button
                            variant="contained-rounded"
                            bgColor="bgRed"
                            onClick={handleAccept}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Accept
                        </Button>
                        <Button variant="text" onClick={handleReject} className={styles.marginRight}>
                            <Body
                                size={14}
                                color="textLinkBlue"
                                weight="normal"
                            >
                                I'll do it later
                            </Body>
                        </Button>
                    </div>
                </FullWidthCard>
            </Box>
            <RewardsEnrollErrorDialog
                showErrorDialog={showErrorDialog}
                setShowErrorDialog={setShowErrorDialog}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => setTosSource(null)}
                onOpen={() => console.log('open')}
            >
                <iframe
                    id="privacy-link"
                    title="privacy link iFrame"
                    style={{ height: '90vh', width: '100%', marginTop: 16 }}
                    src={tosSource || '#'}
                />
            </SwipeableDrawer>
        </>
    );
}

export default RewardsEnrollmentConfirmationScreen;
