/* eslint-disable max-len */
import * as React from 'react';

function SvgComponent(
    props: React.SVGProps<SVGSVGElement> & { fillColor: string }
) {
    const { fillColor = '#101820' } = props;
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.39453 10.5337H24.6056V5.41467H1.39453V10.5337ZM25.3028 11.9483H0.697238C0.311665 11.9483 0 11.6322 0 11.241V4.70734C0 4.31618 0.311665 4 0.697238 4H25.3028C25.6883 4 26 4.31618 26 4.70734V11.241C26 11.6322 25.6883 11.9483 25.3028 11.9483Z"
                fill={fillColor}/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.2812 22.0266H5.51423C5.12866 22.0266 4.81699 21.7104 4.81699 21.3193C4.81699 20.9281 5.12866 20.6119 5.51423 20.6119H22.584V11.9485H3.41694V21.3193C3.41694 21.7104 3.10527 22.0266 2.7197 22.0266C2.33413 22.0266 2.02246 21.7104 2.02246 21.3193V11.2412C2.02246 10.85 2.33413 10.5338 2.7197 10.5338H23.2812C23.6668 10.5338 23.9785 10.85 23.9785 11.2412V21.3193C23.9785 21.7104 23.6668 22.0266 23.2812 22.0266Z"
                fill={fillColor}/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3948 22.0268C17.0092 22.0268 16.6976 21.7107 16.6976 21.3195V15.4833H9.30268V21.3195C9.30268 21.7107 8.99032 22.0268 8.60544 22.0268C8.21987 22.0268 7.9082 21.7107 7.9082 21.3195V14.7759C7.9082 14.3848 8.21987 14.0686 8.60544 14.0686H17.3948C17.7804 14.0686 18.0921 14.3848 18.0921 14.7759V21.3195C18.0921 21.7107 17.7804 22.0268 17.3948 22.0268Z"
                fill={fillColor}/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 22.0268C12.6144 22.0268 12.3027 21.7107 12.3027 21.3195V14.7759C12.3027 14.3848 12.6144 14.0686 13 14.0686C13.3855 14.0686 13.6972 14.3848 13.6972 14.7759V21.3195C13.6972 21.7107 13.3855 22.0268 13 22.0268Z"
                fill={fillColor}/>
        </svg>
    );
}

export default SvgComponent;
