import React, { useState, useEffect } from 'react';
import {
    Typography,
    Backdrop,
    withStyles,
    makeStyles,
    Dialog,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { optimoveEvents } from 'src/screens/optimove';
import useCardCanvas from 'src/hooks/useCardCanvas';
import COLORS from 'src/lib/colors';
import { fmtDollarsAndCents } from 'src/lib/utils/string-utils';
import { format } from 'date-fns';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import FullWidthCard from 'src/components/cards/full-width-card';
import clsx from 'clsx';
import InkWaterMark from '../../assets/icons/inkWaterMark.svg';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        overflow: 'visible',
        background: 'none',
    },
    amountAvailableWrapper: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'flex-start',
        fontWeight: 'bold',
        letterSpacing: '-0.6pt',
        color: '#101820',
    },
    amountSign: {
        fontSize: '1.6rem',
    },
    amount: {
        fontSize: '3rem',
        marginTop: '-1rem',
    },
    decimalAmount: {
        fontSize: '1.8rem',
        marginTop: '-0.4rem',
    },
    rewardLogo: {
        textAlign: 'left',
        paddingLeft: '1rem',
    },
    rewardLogoIcon: {
        width: '35%',
    },
    canvasWrapper: {
        background: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: 'auto',
        height: '150px',
        borderRadius: '0 0 1.2rem 1.2rem',
        padding: '0.9rem 2rem',
    },
    contentWrapper: {
        display: 'flex',
        background: '#F2F2F2',
        flexDirection: 'column',
        width: 'auto',
        height: '266px',
    },
    earnedTextLeft: {
        display: 'inline-block',
        width: '60%',
        paddingLeft: '1rem',
        verticalAlign: 'top',
        marginTop: '0.2rem',
    },
    amountWrapper: {
        display: 'inline-block',
        width: '40%',
        paddingRight: '1rem',
        textAlign: 'right',
    },
    title: {
        fontSize: '1rem',
        fontWeight: 400,
        margin: '13px 0px 10px 0px',
    },
    heading: {
        display: 'flex',
        background: COLORS.brandRed,
        flexDirection: 'column',
        width: 'auto',
        height: '30px',
    },
    earnedText: {
        minHeight: '87px',
        width: 'auto',
        paddingTop: '10px',
        background: '#f2f2f2',
    },
    inkRewardsWaterMark: {
        background: `url(${InkWaterMark}) no-repeat center`,
    },
    memberType: {
        fontWeight: 500,
        color: '#ffffff',
        fontSize: '12px',
        lineHeight: '16px',
        paddingTop: '4px',
        textAlign: 'left',
        paddingLeft: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rewardsNumber: {
        fontSize: '1.1rem',
        color: COLORS.brandRed,
    },
    renderedCardNumber: {
        height: '92px',
        width: '277px',
    },
    memberName: {
        fontWeight: 700,
        color: COLORS.homeBlack,
        fontSize: '1.05rem',
        lineHeight: '1.2',
        display: 'flex',
        textAlign: 'left',
        gap: '5px',
    },
    subtitle: {
        paddingTop: '.5rem',
        fontSize: '.75rem',
        paddingBottom: '1rem',
    },
    learnMore: {
        color: COLORS.brandCyan,
        textDecoration: 'none',
        fontWeight: 500,
    },
    closeButtonAlign: {
        width: '20px',
        height: '20px',
        color: '#000000 !important',
        marginRight: '10px',
        borderRadius: '50%',
        background: '#ffffff !important',
    },
    storeRedeemable: {
        color: COLORS.brandRed,
        textAlign: 'left',
        paddingLeft: '1rem',
        marginTop: '-15px',
        paddingBottom: '15px',
        fontSize: '10px',
    },
});

function CouponsCard(props: any) {
    const classes = useStyles();
    const [headerColor, setHeaderColor] = useState(COLORS.brandRed);
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const [open, setOpen] = useState(false);

    const { canvasRef } = useCardCanvas(`${props.couponData.couponNumber}`);
    const onClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpen(false);
    };
    useEffect(() => {
        switch (props.type) {
            case 'staplesRewards':
                setHeaderColor(COLORS.brandRed);
                break;
            case 'inkRewards':
                setHeaderColor(COLORS.successGreen);
                break;
            case 'storeRewards':
                setHeaderColor(COLORS.homeBlack);
                break;
            case 'serviceRewards':
                setHeaderColor(COLORS.successGreen);
                break;
            default:
                break;
        }
    });
    const expDate = props.couponData.couponInfo.expirationDate
        ? new Date(props.couponData.couponInfo.expirationDate)
        : new Date();

    const rewardMessage = () => {
        switch (props.type) {
            case 'staplesRewards':
                return 'staples rewards';
                break;
            case 'inkRewards':
                return 'ink & toner recycling';
                break;
            case 'storeRewards':
                return 'store bonus rewards';
                break;
            case 'serviceRewards':
                return 'service rewards';
                break;
            default:
                break;
        }
        return null;
    };
    const formatDollar = (amount: string, dollar = false, cents = false) => {
        const value = amount.toString().split('.');
        if (dollar) {
            return value[0];
        }
        if (cents) {
            return value[1];
        }
        return amount;
    };
    const getDateString = () => {
        /* const curExpDate:any = new Date(props.couponData.couponInfo.expirationDate);
          const todayDate:any = new Date();
          let days:any = Math.round((curExpDate - todayDate) / (1000*60*60*24));
          days = days !== 0 ? days : 'today';
          return `Expiring in ${days} days`; */
        let res = '';
        const todayDate: any = new Date();
        const curExpDate = new Date(
            props.couponData.couponInfo.expirationDate
        ).valueOf();
        const futurDate = new Date().setDate(new Date().getDate() + 7);
        if (curExpDate > futurDate) {
            res = `EXPIRES ${format(expDate, 'MM/dd/yyyy')}`;
        } else {
            const expireDate: any = new Date(
                props.couponData.couponInfo.expirationDate
            );
            const days: number = Math.floor(
                (expireDate - todayDate) / (1000 * 60 * 60 * 24)
            );
            if (days === 0) {
                res = `Expiring today`;
            } else if (days === 1) {
                res = `Expiring in ${days} day`;
            } else {
                res = `Expiring in ${days} days`;
            }
            res = `Expiring in ${days} days`;
        }
        return res;
    };
    const modalOpen = (e: any) => {
        e.preventDefault();
        customEvent(eventMetricsMap.wallet_tapcoupon);
        analyticsCustomEvent(eventMetricsMap.wallet, {
            click_text: 'reward selected',
            element_location: rewardMessage(),
            wallet_location: 'staples rewards',
            offer_description: props.couponData.couponInfo.couponValue,
        });
        optimoveEvents(eventMetricsMap.scm_wallet, {
            click_text: 'reward selected',
            element_location: rewardMessage(),
            wallet_location: 'staples rewards',
            offer_description: props.couponData.couponInfo.couponValue,
        });
        setOpen(true);
    };

    // Did not see any use for it. Remove if everthing works fine.
    // const handleOpen = (e: any) => {
    //     e.preventDefault();
    // }

    const dataRender = (
        <FullWidthCard noPadding id="view-digital-coupons-container">
            <div
                className={classes.contentWrapper}
                tabIndex={0}
                role="button"
                onClick={modalOpen}
                onKeyPress={modalOpen}
            >
                <div
                    className={classes.heading}
                    style={{
                        background: headerColor,
                        borderRadius: open ? '0.9rem 0.9rem 0 0' : '',
                    }}
                >
                    <Typography
                        className={classes.memberType}
                        style={{ paddingTop: open ? '4px' : '7px' }}
                    >
                        REWARD {props.sequenceId} - {getDateString()}
                        {open && (
                            <IconButton
                                className={classes.closeButtonAlign}
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Typography>
                </div>
                <div
                    className={clsx(
                        classes.earnedText,
                        props.type === 'inkRewards' &&
                            classes.inkRewardsWaterMark
                    )}
                >
                    <div className={classes.rewardLogo}>
                        <img
                            src={require('../../assets/icons/StaplesRewardsLogo.svg')}
                            alt="rewardlogo"
                            className={classes.rewardLogoIcon}
                        />
                    </div>
                    <div className={classes.earnedTextLeft}>
                        <Typography className={classes.memberName}>
                            Rewards Earned:
                        </Typography>
                    </div>
                    <div className={classes.amountWrapper}>
                        <Typography
                            className={classes.amountAvailableWrapper}
                            id="promo-card-dollar-amount-text"
                        >
                            <span className={classes.amountSign}>$</span>
                            <span className={classes.amount}>
                                {formatDollar(
                                    props.couponData.couponInfo.couponValue,
                                    true,
                                    false
                                )}
                            </span>
                            <span className={classes.decimalAmount}>
                                {formatDollar(
                                    props.couponData.couponInfo.couponValue,
                                    false,
                                    true
                                )}
                            </span>
                        </Typography>
                    </div>
                </div>
                
                {!props?.couponData?.couponInfo?.validForDotcom 
                && props?.couponData?.couponInfo?.validForRetail ? (
                    <Typography
                        className={classes.storeRedeemable}
                        id="store-redeemable"
                    >
                        Redeemable in-store only
                    </Typography>
                ) : null}

                {props?.couponData?.couponInfo?.validForDotcom 
                && !props?.couponData?.couponInfo?.validForRetail ? (
                    <Typography
                        className={classes.storeRedeemable}
                        id="store-redeemable"
                    >
                        Redeemable online only
                    </Typography>
                ) : null}

                <div className={classes.canvasWrapper}>
                    <canvas
                        ref={canvasRef}
                        className={classes.renderedCardNumber}
                    />
                    <Typography className={classes.subtitle}>
                        {props.couponData.couponNumber}
                    </Typography>
                </div>
            </div>
        </FullWidthCard>
    );
    return (
        <>
            {dataRender}
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="coupon-card-dialog-title"
                classes={{
                    paper: classes.paper,
                }}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
            >
                {dataRender}
            </Dialog>
        </>
    );
}

export default CouponsCard;
