/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect,
    useRef,
    useState,
    useMemo,
    useLayoutEffect,
} from 'react';
import {
    makeStyles,
    ButtonBase,
    Typography,
    Box,
    Fade,
    Button,
    Divider,
    CircularProgress,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import COLORS from 'src/lib/colors';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
// import {useUserUpdateContext} from 'src/lib/contexts/user-update-context';
// import { trackEvent } from 'src/lib/api-client/beacons-adeptmind';
import ChooseListDialog from 'src/components/choose-list-dialog';
import ScreenWidth from 'src/components/screen-width';
import GenericUserInputDialog from 'src/components/generic-user-input-dialog';
import ScrollContainer from 'src/components/screen-scroll';
import { CommonPageProps } from 'src/screens/product-browse/common-page';
import { ProductsEntity } from 'src/lib/api-client/adept-mind-types';
import Carousel from 'src/components/Carousel';
import {
    Intent,
    useProductBrowseContext,
} from 'src/lib/contexts/product-browse-context';
import { useQuery } from 'react-query';
import { getSimilarProducts, getProduct } from 'src/lib/api-client/adept-mind';
import {
    getProductModel,
    getProductOriginalPrice,
    getProductPrice,
    getProductQuantity,
    isProductOnSale,
} from 'src/lib/utils/products-utils';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import DiagonalStrikethroughText from 'src/components/diagonal-strikethrough-text';
import Log from 'src/lib/logging';
import SimilarProductItem from 'src/screens/product-browse/product-details/similar-product-item';
import ErrorDialog, { GenericErrorContent } from 'src/components/error-dialog';
import useStoreData from 'src/hooks/useStoreData';
import useAddItem from 'src/hooks/useAddItem';
import { storeNameForProduct } from 'src/lib/utils/store-utils';
import useUpdateItem from 'src/hooks/useUpdateItem';
import useStoreDataWithInventory from 'src/hooks/useStoreDataWithInventory';
import {
    FullListResult,
    ListItemType,
    Store,
    UpdateItemType,
} from 'src/services/types';
import StoreSearchModal from 'src/components/store-search-modal';
import useItemFromList from 'src/hooks/useItemFromList';
import { groupBy } from 'src/lib/utils/array-utils';
import ProductStarRating from 'src/components/product-star-rating';
import ProductReview from './product-review';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    commonNav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    backIcon: {
        fontSize: '1.7rem',
        color: COLORS.homeBlack,
    },
    backButton: {
        margin: '2rem 0 1.5rem 1rem',
    },
    backIconBalance: {
        fontSize: '1.7rem',
        margin: '2rem 0 1.5rem 1rem',
        color: COLORS.homeBlack,
    },
    closeIcon: {
        fontSize: '1.7rem',
        color: COLORS.homeBlack,
    },
    closeButton: {
        color: COLORS.homeBlack,
        fontSize: '1rem',
        marginRight: '1rem',
        width: '65px',
    },
    titleWrapper: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: COLORS.homeBlack,
    },
    itemName: {
        fontSize: '1.75rem',
        fontWeight: 'bold',
    },
    itemDescription: {
        fontSize: '1.4167rem',
    },
    productImageContainer: {
        textAlign: 'center',
    },
    productImage: {
        maxWidth: '100%',
        objectFit: 'contain',
        objectPosition: 'center bottom',
        flexGrow: 1,
    },
    productTitle: {
        fontSize: '1.4167rem',
        fontWeight: 500,
        padding: '2rem 2rem 0 2rem',
    },
    productModel: {
        fontSize: '1.1667rem',
        padding: '0.6667rem 2rem 0 2rem',
    },
    productStarRating: {
        padding: '0.6667rem 2rem 0 2rem',
    },
    prices: {
        padding: '0.6667rem 2rem 0 2rem',
        display: 'flex',
        alignItems: 'center',
    },
    productPrice: {
        fontSize: '1.75em',
        fontWeight: 'bold',
    },
    productQuantityText: {
        padding: '0.6667rem 2rem 0 0',
    },
    bold: { fontWeight: 500 },
    specificationKey: {
        fontWeight: 'bold',
        textAlign: 'left',
    },
    specificationValue: {
        textAlign: 'right',
    },
    quantityRowContainer: {
        padding: '0.6667rem 2rem 0 2rem',
    },
    storeQuantity: {
        paddingLeft: '2rem',
    },
    addToListButtonContainer: {
        padding: '2rem 3rem 0 3rem',
    },
    addToListButton: {
        borderRadius: '1.5rem',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '25rem',
        textTransform: 'none',
        fontSize: '1.2rem',
        background: COLORS.homeBlack,
        color: COLORS.primaryWhite,
        boxShadow: 'none !important',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
            color: COLORS.primaryWhite,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
            color: COLORS.primaryWhite,
        },
    },
    highlightContainer: {
        padding: '2rem 2rem 0 2rem',
    },
    highlightTitle: { fontSize: '1.4167rem', fontWeight: 500 },
    highlightDetails: {
        fontSize: '1rem',
    },
    highlightFooter: {
        fontSize: '0.75rem',
        padding: '1rem 1rem',
        textAlign: 'center',
    },
    productDetailsContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 0.5rem 1rem 2rem',
    },
    productDetailsTitle: {
        fontSize: '	1.4167rem',
        fontWeight: 'bold',
    },
    bottomDivider: {
        marginLeft: '2rem',
    },
    productDetailsText: {
        fontSize: '1rem',
        padding: '1rem 2rem',
    },
    productDescription: {
        fontSize: '1rem',
        padding: '1rem 2rem',
        whiteSpace: 'break-spaces',
    },
    similarProductsTitle: {
        marginBottom: '1rem',
        fontSize: '1.4167rem',
        fontWeight: 500,
        padding: '2rem 2rem 0 2rem',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    similarProductsLoading: {
        width: '100%',
        textAlign: 'center',
    },
    productLoadingScreen: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 1,
    },
    loader: {
        marginTop: '2rem',
    },
    changeStoreButton: {
        fontSize: '1rem',
        color: COLORS.brandCyan,
        fontWeight: 'bold',
        marginTop: '.5rem',
    },
});

const getSimilarProductsContent = (
    similarProducts: ProductsEntity[],
    handleSimilarProductClick: (item: ProductsEntity) => void,
    handleAddToListClick: (item: ProductsEntity) => void,
    handleSimilarProductReviewsClick: (item: ProductsEntity) => void,
    currentIntent: Intent | undefined,
    hideFeatures?: boolean
) =>
    groupBy(2, similarProducts).map(([firstProduct, secondProduct]) => (
        <div
            key={firstProduct?.prod_id + secondProduct?.prod_id}
            style={{ display: 'flex', flexDirection: 'row' }}
        >
            <SimilarProductItem
                key={firstProduct?.prod_id}
                productItem={firstProduct}
                handleSimilarProductClick={handleSimilarProductClick}
                handleSimilarProductReviewsClick={
                    handleSimilarProductReviewsClick
                }
                handleAddToListClick={handleAddToListClick}
                hideFeatures={hideFeatures}
            />

            {secondProduct !== undefined && (
                <SimilarProductItem
                    key={secondProduct?.prod_id}
                    productItem={secondProduct}
                    handleSimilarProductClick={handleSimilarProductClick}
                    handleSimilarProductReviewsClick={
                        handleSimilarProductReviewsClick
                    }
                    handleAddToListClick={handleAddToListClick}
                    hideFeatures={hideFeatures}
                />
            )}
        </div>
    ));

// function CommonNav({trackEventServiceCall}:any) {
function CommonNav() {
    const classes = useStyles();
    const { goBack, end } = useProductBrowseContext();
    function onClose() {
        end();
        // trackEventServiceCall();
    }
    function onBack() {
        goBack();
        // trackEventServiceCall();
    }
    return (
        <div className={classes.commonNav}>
            <Fade in>
                <ButtonBase onClick={onBack} className={classes.backButton}>
                    <ArrowBackIosRoundedIcon className={classes.backIcon} />
                </ButtonBase>
            </Fade>
            <div className={classes.titleWrapper}>
                <Typography className={classes.itemName}>
                    Item details
                </Typography>
                <Typography className={classes.itemDescription} />
            </div>

            <Button
                onClick={() => onClose()}
                className={classes.closeIcon}
                id="close-button"
            >
                <img
                    src={require('../../../assets/icons/iconCloseBlack.svg')}
                    alt="close icon"
                />
            </Button>
        </div>
    );
}

const similarProductStyles = {
    root: {
        padding: '0 30px',
    },
    slideContainer: {
        overflow: 'unset',
    },
};

type AddToListInfo = {
    open: boolean;
    productItem?: ProductsEntity;
    currentQuantity: number;
    storeId: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ProductDetailsDisplay(props: {
    loadedProduct: ProductsEntity;
    itemInfo?: ListItemType;
    reloadWithNewStore: (s: Store) => void;
    currentStoreId: any;
    currentStore?: Store;
    commitToAddition: (
        newProductItem: ProductsEntity,
        details: {
            quantity: number;
            storeId?: any;
        }
    ) => void;
    commitToUpdates: (
        itemId: number,
        fields: {
            key: UpdateItemType;
            value: string | number;
        }[]
    ) => void;
    itemFromList: ListItemType | null | undefined;
    listDetailsData: FullListResult | null | undefined;
    disableUpdateButton: boolean;
}) {
    const classes = useStyles();
    const { analyticsCustomEvent } = useEventsReporter();
    const { mainStore } = useStoreData();
    const {
        goToPage,
        currentIntent,
        currentPage,
        openReviews,
        setOpenReviews,
    } = useProductBrowseContext();
    // eslint-disable-next-line react/destructuring-assignment
    const {
        itemInfo,
        loadedProduct,
        reloadWithNewStore,
        currentStoreId,
        currentStore,
        commitToAddition,
        commitToUpdates,
        listDetailsData,
        itemFromList,
        disableUpdateButton,
    } = props;
    const productItem: ProductsEntity = loadedProduct;
    const swipeableViewsRef = useRef<any | null>(null);
    const productReviewRef = useRef<HTMLButtonElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [isUpdateQuantityDialogOpen, setIsUpdateQuantityDialogOpen] =
        useState(false);
    const [currentQuantity, setCurrentQuantity] = useState(
        currentPage?.pageDetails.quantity || 1
    );
    const [hasUpdatedValues, setHasUpdatedValues] = useState(false);

    const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
    const [isSpecificationsOpen, setIsSpecificationsOpen] = useState(false);
    const [isProductReviewsOpen, setIsProductReviewsOpen] =
        useState(openReviews);
    const [scrolledToReviews, setScrolledToReviews] = useState<boolean>(false);
    const [isReviewsLoaded, setIsReviewsLoaded] = useState<boolean>(false);
    const [similarProducts, setSimilarProducts] = useState<ProductsEntity[]>(
        []
    );
    const [addToListInfo, setAddToListInfo] = useState<AddToListInfo>({
        open: false,
        currentQuantity: 1,
        productItem,
        storeId: currentStoreId,
    });
    const hideFeatures = currentIntent?.type === 'hide-features';
    const {
        data: similarProductsData,
        error: similarProductsError,
        isFetching: similarProductsIsFetching,
    } = useQuery(
        ['similarProducts', productItem],
        () => getSimilarProducts(productItem?.product_id),
        {
            keepPreviousData: true,
            ...NO_REFETCH,
        }
    );

    useEffect(() => {
        if (openReviews) {
            setIsProductReviewsOpen(true);
            setScrolledToReviews(false);
        }
    }, [openReviews]);

    useLayoutEffect(() => {
        if (
            openReviews &&
            productReviewRef &&
            isReviewsLoaded &&
            !scrolledToReviews
        ) {
            setTimeout(() => {
                productReviewRef.current?.scrollIntoView({
                    behavior: 'smooth',
                });
                setScrolledToReviews(true);
                setOpenReviews(false);
            }, 1250);
        }
    }, [openReviews, productReviewRef, isReviewsLoaded, scrolledToReviews]);

    useEffect(() => {
        if (
            !similarProductsIsFetching &&
            !similarProductsError &&
            similarProductsData
        ) {
            // Limit to the first 5 similar products
            setSimilarProducts(
                (similarProductsData?.similar_products || []).slice(0, 5)
            );
        }
    }, [similarProductsIsFetching, similarProductsData, similarProductsError]);

    function scrollToTop() {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    function handleUpdateItemQuantity(newQuantity: number) {
        if (currentQuantity !== newQuantity) {
            setHasUpdatedValues(true);
            setCurrentQuantity(newQuantity);
        }
    }

    function addToList() {
        if (
            // if the user came from a list, add/update the current list
            currentIntent?.type === 'add-to-existing-list' &&
            currentIntent?.info?.listId
        ) {
            if (itemFromList) {
                // alway update quantity
                const updates = [
                    {
                        value: currentQuantity,
                        key: 'quantity' as UpdateItemType,
                    },
                ];
                if (currentStoreId) {
                    updates.push({
                        value: currentStoreId,
                        key: 'storeId' as UpdateItemType,
                    });
                }
                commitToUpdates(itemFromList.id, updates);
            } else {
                commitToAddition(productItem, {
                    quantity: currentQuantity,
                    storeId: currentStoreId,
                });
            }
        } else {
            // open list selection modal
            setAddToListInfo({
                open: true,
                currentQuantity,
                productItem,
                storeId: currentStoreId,
            });
        }
    }

    function closeAddToList() {
        setAddToListInfo((prev) => ({ ...prev, open: false }));
    }

    function handleSimilarProductClick(item: ProductsEntity) {
        if (!swipeableViewsRef?.current?.isSwiping) {
            goToPage({
                type: 'product-details',
                pageDetails: { fullProduct: item },
            });
            scrollToTop();
        }
    }

    function handleSimilarProductReviewsClick(item: ProductsEntity) {
        if (!swipeableViewsRef?.current?.isSwiping) {
            goToPage({
                type: 'product-details',
                pageDetails: { fullProduct: item },
                openReviews: true,
            });
        }
    }

    function handleSimilarProductAddToListClick(item: ProductsEntity) {
        if (!swipeableViewsRef?.current?.isSwiping) {
            if (
                currentIntent?.type === 'add-to-existing-list' &&
                currentIntent?.info?.listId
            ) {
                commitToAddition(item, {
                    quantity: 1,
                    storeId: currentStoreId,
                });
            } else {
                setAddToListInfo({
                    open: true,
                    currentQuantity: 1,
                    productItem: item,
                    storeId: currentStoreId,
                });
            }
        }
    }

    const currentStoreName =
        storeNameForProduct(currentStore) ||
        itemInfo?.storeName ||
        storeNameForProduct(mainStore);
    // needed for display only, cached here so that we can use it on store update
    // const {userGetIdentity} = useUserUpdateContext();
    const [currentStoreInventory, setCurrentStoreInventory] = useState(
        getProductQuantity(productItem)
    );
    const [isChangeStoreDialogOpen, setIsChangeStoreDialogOpen] =
        useState(false);
    const {
        isFetching: isFetchingStoresWithInventory,
        setRetrieveStoreInventory,
        mainStoreWithInventory,
        additionalStoresWithInventory,
    } = useStoreDataWithInventory({
        itemNumber: `${productItem?.prod_id}`,
    });
    function handleChangeStoreClick() {
        analyticsCustomEvent(eventMetricsMap.product_details, {
            click_text: 'change store',
            element_location: 'product details',
            product: productItem?.title,
        });
        optimoveEvents(eventMetricsMap.scm_product_search, {
            click_text: 'change store',
            element_location: 'product details',
            product: productItem?.title,
        });
        setIsChangeStoreDialogOpen(true);
        setRetrieveStoreInventory(true);
    }
    function onStoreSelected(store: Store) {
        setIsChangeStoreDialogOpen(false);
        reloadWithNewStore(store);
    }

    useEffect(() => {
        if (productItem) {
            // Limit to the first 5 similar products
            setCurrentStoreInventory(getProductQuantity(productItem));
        }
    }, [productItem]);

    if (!productItem) {
        return (
            <div className={classes.loading}>
                <CircularProgress
                    size="1rem"
                    style={{ color: COLORS.textBlack }}
                />
            </div>
        );
    }

    const imageContent = productItem?.images?.map(
        (image: string, index: number) => (
            <img
                className={classes.productImage}
                key={`product-swipeable-elem-image-${image}`}
                id={`product-swipeable-elem-image-${index}`}
                src={image}
                alt="product"
            />
        )
    );

    const productModel = getProductModel(productItem);
    const similarProductsContent = useMemo(
        () =>
            getSimilarProductsContent(
                similarProducts,
                handleSimilarProductClick,
                handleSimilarProductAddToListClick,
                handleSimilarProductReviewsClick,
                currentIntent,
                disableUpdateButton || hideFeatures
            ),
        [similarProducts, disableUpdateButton, hideFeatures]
    );
    // const trackEventServiceCall = () => {
    //   const data = {
    //     action:"click",
    //     category: "product-engagement",
    //     distinct_id: userGetIdentity?.distinct_id || "",
    //     query_id: userGetIdentity?.query_id || "",
    //     search_id: userGetIdentity?.search_id || "",
    //     session_id: userGetIdentity?.session_id || "",
    //   }
    //   trackEvent(data);
    // }
    return (
        <>
            <ScrollContainer ref={scrollContainerRef} ignoreNav>
                <ScreenWidth>
                    <div className={classes.screen} id="individual-list-screen">
                        <CommonNav />
                        {/* <CommonNav trackEventServiceCall={trackEventServiceCall}/> */}
                        <div className={classes.productImageContainer}>
                            {productItem?.images ? (
                                <Carousel
                                    content={imageContent}
                                    dots={productItem.images.length}
                                />
                            ) : (
                                <img
                                    className={classes.productImage}
                                    src={productItem?.image}
                                    alt="Product"
                                />
                            )}
                        </div>
                        <Divider color={COLORS.fordGray} />
                        <Typography className={classes.productTitle}>
                            {productItem?.title}
                        </Typography>
                        <Typography className={classes.productModel}>
                            Item #{productItem?.prod_id}
                            {productModel && `, Model #${productModel}`}
                        </Typography>
                        <div className={classes.productStarRating}>
                            <ProductStarRating
                                ratingValue={productItem?.product_rating || 0}
                                totalReviews={productItem?.num_reviews}
                                onClick={() => {
                                    setIsProductReviewsOpen(true);
                                    setTimeout(() => {
                                        productReviewRef.current?.scrollIntoView(
                                            {
                                                behavior: 'smooth',
                                            }
                                        );
                                    }, 100);
                                    analyticsCustomEvent(
                                        eventMetricsMap.product_details,
                                        {
                                            click_text: 'review stars',
                                            element_location: 'product details',
                                            product: productItem?.title,
                                        }
                                    );
                                    optimoveEvents(
                                        eventMetricsMap.scm_product_search,
                                        {
                                            click_text: 'review stars',
                                            element_location: 'product details',
                                            product: productItem?.title,
                                        }
                                    );
                                }}
                            />
                        </div>
                        <div className={classes.prices}>
                            <Typography className={classes.productPrice}>
                                ${getProductPrice(productItem)}
                            </Typography>
                            {isProductOnSale(productItem) && (
                                <DiagonalStrikethroughText>
                                    ${getProductOriginalPrice(productItem)}
                                </DiagonalStrikethroughText>
                            )}
                        </div>
                        {storeNameForProduct(mainStore) && (
                            <div className={classes.storeQuantity}>
                                <Typography
                                    className={classes.productQuantityText}
                                >
                                    {currentStoreInventory ? (
                                        <span>
                                            <span className={classes.bold}>
                                                {currentStoreInventory}
                                            </span>{' '}
                                            in stock at:{' '}
                                        </span>
                                    ) : (
                                        <div>Out of stock at </div>
                                    )}
                                    <span className={classes.bold}>
                                        {currentStoreName}
                                    </span>
                                </Typography>
                                <ButtonBase
                                    className={classes.changeStoreButton}
                                    id="change-store-button"
                                    onClick={handleChangeStoreClick}
                                    disabled={disableUpdateButton}
                                >
                                    Change Store
                                </ButtonBase>
                            </div>
                        )}

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className={classes.quantityRowContainer}
                        >
                            <Typography>Quantity: {currentQuantity}</Typography>

                            <Button
                                disabled={disableUpdateButton || hideFeatures}
                                onClick={() => {
                                    analyticsCustomEvent(
                                        eventMetricsMap.product_details,
                                        {
                                            click_text: 'quantity change',
                                            element_location: 'product details',
                                            product: productItem?.title,
                                        }
                                    );
                                    optimoveEvents(
                                        eventMetricsMap.scm_product_search,
                                        {
                                            click_text: 'quantity change',
                                            element_location: 'product details',
                                            product: productItem?.title,
                                        }
                                    );
                                    setIsUpdateQuantityDialogOpen(true);
                                }}
                            >
                                {disableUpdateButton || hideFeatures ? (
                                    <img
                                        src={require('../../../assets/icons/iconCaret.svg')}
                                        alt="caret"
                                    />
                                ) : (
                                    <img
                                        src={require('../../../assets/icons/iconCaretBlue.svg')}
                                        alt="caret"
                                    />
                                )}
                            </Button>
                        </Box>
                        <div className={classes.addToListButtonContainer}>
                            <Button
                                onClick={addToList}
                                className={classes.addToListButton}
                                disabled={
                                    disableUpdateButton ||
                                    (currentIntent?.info?.listId &&
                                        (!listDetailsData ||
                                            (itemFromList &&
                                                !hasUpdatedValues)))
                                }
                                variant="contained"
                            >
                                {itemFromList ? 'Update list' : 'Add to list'}
                            </Button>
                        </div>
                        {!!productItem?.bullets && (
                            <div className={classes.highlightContainer}>
                                <Typography className={classes.highlightTitle}>
                                    Highlights
                                </Typography>
                                {productItem?.bullets?.map(
                                    (bulletDetails: string) => (
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="baseline"
                                        >
                                            <Box style={{ margin: '-7 0' }}>
                                                &#8226;
                                            </Box>
                                            <Typography
                                                className={
                                                    classes.highlightDetails
                                                }
                                            >
                                                {bulletDetails}
                                            </Typography>
                                        </Box>
                                    )
                                )}
                                <Typography className={classes.highlightFooter}>
                                    Note: In-app prices may differ from
                                    in-store.
                                </Typography>
                            </div>
                        )}

                        {!!productItem?.description && (
                            <>
                                <Divider color={COLORS.fordGray} />

                                <Button
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.product_details,
                                            {
                                                click_text: 'product details',
                                                element_location:
                                                    'product details',
                                                product: productItem?.title,
                                            }
                                        );
                                        optimoveEvents(
                                            eventMetricsMap.scm_product_search,
                                            {
                                                click_text: 'product details',
                                                element_location:
                                                    'product details',
                                                product: productItem?.title,
                                            }
                                        );
                                        setIsProductDetailsOpen(
                                            !isProductDetailsOpen
                                        );
                                    }}
                                    disableRipple
                                    aria-controls="productDetailsCollapsible"
                                    aria-expanded={isProductDetailsOpen}
                                >
                                    <div
                                        className={
                                            classes.productDetailsContainer
                                        }
                                    >
                                        <Typography
                                            className={
                                                classes.productDetailsTitle
                                            }
                                        >
                                            Product Details
                                        </Typography>

                                        <img
                                            src={
                                                isProductDetailsOpen
                                                    ? require('../../../assets/icons/iconChevronUp.svg')
                                                    : require('../../../assets/icons/iconChevronDown.svg')
                                            }
                                            alt="chevron"
                                        />
                                    </div>
                                </Button>

                                <Divider
                                    className={classes.bottomDivider}
                                    color={COLORS.fordGray}
                                />
                            </>
                        )}
                        {isProductDetailsOpen && (
                            <Typography
                                className={classes.productDescription}
                                role="region"
                                id="productDetailsCollapsible"
                            >
                                {productItem?.description}
                            </Typography>
                        )}

                        {!!productItem?.specifications &&
                            Object.keys(productItem?.specifications).length !==
                                0 && (
                                <Button
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.product_details,
                                            {
                                                click_text: 'specifications',
                                                element_location:
                                                    'product details',
                                                product: productItem?.title,
                                            }
                                        );
                                        optimoveEvents(
                                            eventMetricsMap.scm_product_search,
                                            {
                                                click_text: 'specifications',
                                                element_location:
                                                    'product details',
                                                product: productItem?.title,
                                            }
                                        );
                                        setIsSpecificationsOpen(
                                            !isSpecificationsOpen
                                        );
                                    }}
                                    disableRipple
                                    aria-controls="productSpecificationsCollapsible"
                                    aria-expanded={isSpecificationsOpen}
                                >
                                    <div
                                        className={
                                            classes.productDetailsContainer
                                        }
                                    >
                                        <Typography
                                            className={
                                                classes.productDetailsTitle
                                            }
                                        >
                                            Specifications
                                        </Typography>

                                        <img
                                            src={
                                                isSpecificationsOpen
                                                    ? require('../../../assets/icons/iconChevronUp.svg')
                                                    : require('../../../assets/icons/iconChevronDown.svg')
                                            }
                                            alt="chevron"
                                        />
                                    </div>
                                </Button>
                            )}

                        {isSpecificationsOpen && (
                            <div
                                className={classes.productDetailsText}
                                id="productSpecificationsCollapsible"
                                role="region"
                            >
                                {Object.keys(productItem.specifications).map(
                                    (key) => (
                                        <Box
                                            key={key}
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="space-between"
                                        >
                                            <div
                                                className={
                                                    classes.specificationKey
                                                }
                                            >
                                                {key}:{' '}
                                            </div>
                                            <div
                                                className={
                                                    classes.specificationValue
                                                }
                                            >
                                                {
                                                    productItem.specifications[
                                                        key
                                                    ]
                                                }
                                            </div>
                                        </Box>
                                    )
                                )}
                            </div>
                        )}
                        <Divider
                            className={classes.bottomDivider}
                            color={COLORS.fordGray}
                        />

                        {/* PRODUCT REVIEW */}

                        <Button
                            onClick={() => {
                                analyticsCustomEvent(
                                    eventMetricsMap.product_details,
                                    {
                                        click_text: 'see more reviews',
                                        element_location: 'product details',
                                        product: productItem?.title,
                                    }
                                );
                                optimoveEvents(
                                    eventMetricsMap.scm_product_search,
                                    {
                                        click_text: 'see more reviews',
                                        element_location: 'product details',
                                        product: productItem?.title,
                                    }
                                );
                                setIsProductReviewsOpen(!isProductReviewsOpen);
                            }}
                            disableRipple
                            aria-controls="productReviewsCollapsible"
                            aria-expanded={isProductReviewsOpen}
                            ref={productReviewRef}
                        >
                            <div className={classes.productDetailsContainer}>
                                <Typography
                                    className={classes.productDetailsTitle}
                                >
                                    Reviews
                                </Typography>

                                <img
                                    src={
                                        isProductReviewsOpen
                                            ? require('../../../assets/icons/iconChevronUp.svg')
                                            : require('../../../assets/icons/iconChevronDown.svg')
                                    }
                                    alt="chevron"
                                />
                            </div>
                        </Button>
                        {isProductReviewsOpen && (
                            <div
                                className={classes.productDetailsText}
                                id="productSpecificationsCollapsible"
                                role="region"
                            >
                                <ProductReview
                                    rating={productItem.product_rating}
                                    numReviews={productItem.num_reviews}
                                    productId={productItem.product_id}
                                    setIsReviewsLoaded={setIsReviewsLoaded}
                                />
                            </div>
                        )}
                    </div>

                    {/* PRODUCT REVIEWS END */}

                    <Divider color={COLORS.fordGray} />

                    <div>
                        <Typography className={classes.similarProductsTitle}>
                            Similar Products:
                        </Typography>

                        {!similarProductsIsFetching &&
                            !similarProducts?.length && (
                                <Typography>
                                    No similar products found
                                </Typography>
                            )}

                        {similarProductsIsFetching ? (
                            <div className={classes.similarProductsLoading}>
                                <CircularProgress
                                    size="1rem"
                                    style={{ color: COLORS.textBlack }}
                                />
                            </div>
                        ) : (
                            <Carousel
                                content={similarProductsContent}
                                dots={similarProductsContent?.length}
                                styles={similarProductStyles}
                                swipeableViewsRef={swipeableViewsRef}
                            />
                        )}
                    </div>
                </ScreenWidth>
            </ScrollContainer>
            <GenericUserInputDialog
                open={isUpdateQuantityDialogOpen}
                title="Quantity"
                defaultInputValue={currentQuantity}
                handleClose={() => setIsUpdateQuantityDialogOpen(false)}
                handleInputChange={handleUpdateItemQuantity}
                handleSubmit={(newValue) => {
                    handleUpdateItemQuantity(newValue);
                    setIsUpdateQuantityDialogOpen(false);
                }}
                handleSecondaryBtnClick={() =>
                    setIsUpdateQuantityDialogOpen(false)
                }
            />
            <ChooseListDialog
                open={addToListInfo.open}
                handleClose={closeAddToList}
                productItem={addToListInfo.productItem}
                quantity={addToListInfo.currentQuantity}
                storeId={currentStoreId}
            />
            <StoreSearchModal
                ignoreIsActiveScreen
                isLoading={isFetchingStoresWithInventory}
                open={isChangeStoreDialogOpen}
                itemNumber={productItem.prod_id}
                withInventory
                includeMap={false}
                title="Check other stores"
                selectStoreCtaText="Shop this store"
                mainStore={mainStoreWithInventory}
                nearbyStores={additionalStoresWithInventory}
                onStoreSelected={onStoreSelected}
                handleClose={() => setIsChangeStoreDialogOpen(false)}
            />
        </>
    );
}

/* eslint-disable react/destructuring-assignment */
/**
 * This proxy component loads the product data if it determines that it's not available.
 * For example, when we browse to this screen from our lists view.
 */
export default function ProductDetailsScreenDataProxy(props: CommonPageProps) {
    // currentPage available inside props.page
    const { goBack, currentIntent } = useProductBrowseContext();
    const { mainStoreId } = useStoreData();
    // this page loads either product data or by productId.
    const { itemInfo } = props?.page?.pageDetails;
    const fullProductDataFromProps = props?.page?.pageDetails?.fullProduct;
    // fall back to the main store if we can't find the store info anywhere else
    const [overrideStore, setOverrideStore] = useState<Store>();

    const [storeId, setStoreId] = useState(
        overrideStore?.storeNumber || itemInfo?.storeId || mainStoreId
    );

    const productId =
        itemInfo?.itemNumber ||
        props?.page?.pageDetails?.productId ||
        fullProductDataFromProps?.prod_id;
    const [needsProductData, setNeedsProductData] = useState(
        props?.page?.pageDetails?.forceLoad
    );
    const [productData, setProductData] = useState<ProductsEntity>(
        fullProductDataFromProps
    );

    useEffect(() => {
        setProductData(fullProductDataFromProps);
    }, [fullProductDataFromProps?.prod_id]);

    const classes = useStyles();

    const {
        itemFromList,
        listDetailsData,
        refetchListDetailsData,
        isFetching: itemListIsFetching,
    } = useItemFromList({
        listId: `${currentIntent?.info?.listId || ''}`,
        productId,
    });

    const { commitToUpdates, isFetching: updateItemIsFetching } = useUpdateItem(
        {
            onComplete: () => refetchListDetailsData(),
        }
    );

    const { commitToAddition, isFetching: addItemIsFetching } = useAddItem({
        listArray: currentIntent?.info?.listId
            ? [currentIntent?.info?.listId]
            : [],
        onComplete: () => refetchListDetailsData(),
    });

    const { data, isFetched, refetch } = useQuery(
        ['product-details', productId, storeId],
        () => getProduct(productId, storeId),
        {
            enabled: needsProductData && !!storeId,
            ...NO_REFETCH,
        }
    );

    useEffect(() => {
        if (storeId) {
            refetch();
        }
    }, [storeId]);

    function reloadWithNewStore(store: Store) {
        setOverrideStore(store);
        setStoreId(store.storeNumber);
    }

    const [showNotFoundError, setShowNotFoundError] = useState(false);

    useEffect(() => {
        if (isFetched) {
            if (data) {
                Log.product('product loaded from query', data);
                setProductData(data);
                setNeedsProductData(false);
            } else {
                setShowNotFoundError(true);
            }
        }
    }, [data, isFetched]);

    function errorDialogClose() {
        goBack();
    }

    if (needsProductData) {
        return (
            <ScrollContainer ignoreNav>
                <ScreenWidth>
                    <div className={classes.screen} id="individual-list-screen">
                        <CommonNav />
                        <div className={classes.productLoadingScreen}>
                            {!showNotFoundError && (
                                <CircularProgress className={classes.loader} />
                            )}
                        </div>
                    </div>
                    <ErrorDialog
                        open={showNotFoundError}
                        errorTitle="Error"
                        primaryBtnLabel="OK"
                        hideSecondaryBtn
                        handleClose={errorDialogClose}
                    >
                        <GenericErrorContent errorDescription="Specific item not found. Shop our store for a full assortment." />
                    </ErrorDialog>
                </ScreenWidth>
            </ScrollContainer>
        );
    }
    return (
        <ProductDetailsDisplay
            loadedProduct={productData}
            itemInfo={itemInfo}
            reloadWithNewStore={reloadWithNewStore}
            currentStoreId={storeId}
            currentStore={overrideStore}
            commitToAddition={commitToAddition}
            commitToUpdates={commitToUpdates}
            itemFromList={itemFromList}
            listDetailsData={listDetailsData}
            disableUpdateButton={
                updateItemIsFetching || addItemIsFetching || itemListIsFetching
            }
        />
    );
}
