import React from 'react';

import COLORS from 'src/lib/colors';
import {
    createList,
    updateListDetails,
    saveFeaturedList,
    saveSharedList,
} from 'src/lib/api-client/lists';
import { useMutation } from 'react-query';
import { ListResult } from 'src/services/types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from 'src/components/loading-button';
import ValidationTextField from 'src/components/validation-text-field';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import Log from 'src/lib/logging';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { ListMode } from 'src/screens/lists/list.types';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';

const useStyles = makeStyles({
    paper: { borderRadius: '1rem', margin: '1rem' },
    titleWrapper: {
        padding: '0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: '-0.5pt',
        alignSelf: 'center',
        marginTop: '-1.6rem',
        marginBottom: '-.5rem',
    },
    titlePadding: { marginTop: '2rem' },
    dialogContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeIcon: {
        justifyContent: 'flex-end',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dialogContentText: {
        color: COLORS.secondaryNeutralCharcoal,
        textAlign: 'center',
        marginTop: '1rem',
        letterSpacing: '-0.5pt',
        lineHeight: '1.2rem',
    },
    actionsWrapper: {
        flexDirection: 'column',
    },
    primaryButton: {
        width: '90%',
        margin: '2.5rem 5% 1rem 5%',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    vericalGap: {
        width: '100%',
        height: '.2rem',
    },
});

export type ListCreateEditDialogProps = {
    open: boolean;
    onBasePage?: boolean;
    mode: ListMode; // note: delete is handled separately.
    onComplete: (success: boolean, mode: ListMode, data?: ListResult) => void;
    handleClose: () => void | undefined;
    editDetails?: {
        listId?: number | string;
        listName?: string;
        listDescription?: string;
    };
    lowestValue: number;
    type?: 'featured' | 'shared' | 'addListDirect';
    addList?: (type: string) => void;
};

type FormState = {
    name: string | null;
    description?: string | null;
};
export default function ListCreateEditDialog(props: ListCreateEditDialogProps) {
    const {
        mode,
        open,
        editDetails,
        onComplete,
        handleClose,
        lowestValue,
        onBasePage,
        type,
        addList,
    } = props;
    const classes = useStyles();

    const { notifyUserListUpdate } = useUserUpdateContext();

    const { customEvent } = useEventsReporter();

    const initialValues = {
        name:
            editDetails?.listName && type === 'featured'
                ? `My ${editDetails?.listName}`
                : editDetails?.listName || '',
        description: editDetails?.listDescription || '',
    };

    function onSuccess(data: ListResult | undefined) {
        Log.lists('onSuccess', data);
        if (data?.statusCode) {
            // status code only returned if there's an error
            onComplete(false, mode);
        } else {
            notifyUserListUpdate();
            onComplete(true, mode, data);
        }
    }
    function onError(err: any) {
        Log.lists('onError', err);
        onComplete(false, mode);
    }

    // ['createList', pendingData, editDetails?.listId],
    const { isLoading, mutate } = useMutation<any>(
        (values: any) => {
            if (mode === 'create') {
                if (type === 'featured') {
                    return saveFeaturedList(
                        editDetails?.listId,
                        values?.name || editDetails?.listName,
                        values?.description || ''
                    );
                }
                if (type === 'shared') {
                    customEvent(eventMetricsMap.share_list_saved);
                    if (
                        values?.name !== initialValues.name ||
                        values?.description !== initialValues.description
                    ) {
                        return saveSharedList(
                            editDetails?.listId,
                            values?.name,
                            values?.description
                        );
                    }
                    return saveSharedList(editDetails?.listId);
                }
                return createList({
                    name: values?.name,
                    description: values?.description,
                    lowestValue,
                });
            }
            if (mode === 'edit' && editDetails?.listId) {
                return updateListDetails({
                    id: editDetails?.listId,
                    name: values?.name || '',
                    description: values?.description || '',
                });
            }
            return Promise.resolve(null);
        },
        {
            onSuccess,
            onError,
        }
    );

    const onSubmit = (values: FormState) => {
        if (values?.name) {
            mutate(values as any);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: yup.object({
            name: yup
                .string()
                .nullable()
                .required('Name is required.')
                .max(40, 'Max Limit: 40 characters'),
            description: yup
                .string()
                .nullable()
                .max(200, 'Max Limit: 200 characters'),
        }),
        enableReinitialize: true,
        onSubmit,
    });

    function onClose() {
        formik.resetForm();
        if (handleClose) {
            handleClose();
        }
    }

    function onDoneButtonPressed() {
        if (formik.isValid) {
            onSubmit(formik.values);
            formik.setTouched({ name: true, description: true });
        }
        if (type === 'addListDirect') {
            addList?.('addToList');
        }
    }

    const clearError = (e: any) => {
        const fieldName = e.type === 'focus' ? e.target.name : '';
        formik.setFieldError(fieldName, '');
    };

    const dialogOpenOnBasePage = useIsActiveScreen(
        SCREEN_NAMES.LIST_CREATE_EDIT_DIALOG,
        onBasePage && open
    );
    /* eslint no-nested-ternary:1 */
    return (
        <Dialog
            open={onBasePage ? dialogOpenOnBasePage : open}
            onClose={onClose}
            aria-labelledby="list-create-edit-dialog-title"
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogTitle
                disableTypography
                id="list-create-edit-dialog-title"
                className={classes.titleWrapper}
            >
                <Button
                    fullWidth
                    onClick={onClose}
                    className={classes.closeIcon}
                    id="close-button"
                >
                    <img
                        src={require('../assets/icons/iconCloseBlack.svg')}
                        alt="close icon"
                    />
                </Button>
                <Typography
                    className={classes.title}
                    id="list-create-edit-dialog-title-text"
                >
                    {type === 'featured'
                        ? 'Save to my lists'
                        : type === 'shared'
                        ? 'Save List'
                        : mode === 'create'
                        ? 'Create a New List'
                        : 'Rename list'}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContentWrapper}>
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <ValidationTextField
                        fullWidth
                        id="list-name-input"
                        name="name"
                        label="List Name*"
                        disabled={isLoading}
                        onChange={formik.handleChange}
                        onFocus={(e) => clearError(e)}
                        value={formik.values.name}
                        error={formik.touched.name && !!formik.errors.name}
                        helperText={formik.touched.name && formik.errors.name}
                        inputProps={{ maxLength: 40 }}
                    />
                    <div className={classes.vericalGap}> &nbsp;</div>
                    <ValidationTextField
                        fullWidth
                        id="list-description-input"
                        name="description"
                        label="List Description (optional)"
                        disabled={isLoading}
                        onChange={formik.handleChange}
                        onFocus={(e) => clearError(e)}
                        value={formik.values.description}
                        error={
                            formik.touched.description &&
                            !!formik.errors.description
                        }
                        helperText={
                            formik.touched.description &&
                            formik.errors.description
                        }
                        inputProps={{ maxLength: 200 }}
                    />
                    <LoadingButton
                        fullWidth
                        id="list-create-edit-dialog-done-button"
                        variant="contained"
                        classes={{
                            root: classes.primaryButton,
                        }}
                        type="submit"
                        onClick={onDoneButtonPressed}
                        loading={isLoading}
                    >
                        {type === 'featured' ? 'Save' : 'Done'}
                    </LoadingButton>
                </form>
            </DialogContent>
        </Dialog>
    );
}
