import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useStoreData from 'src/hooks/useStoreData';
import { Store, StoreService } from 'src/services/types';
import { getStoresTsa } from 'src/lib/api-client/staples-stores';
import { useQuery } from 'react-query';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import FullWidthCard from '../cards/full-width-card';
import COLORS from '../../lib/colors';
import LoadingButton from '../loading-button';
import { openInNewTab } from '../../lib/utils/nav-utils';

const useStyles = makeStyles((theme) => ({
    header: {
        '& img': {
            width: '100%',
        },
    },
    description: {
        padding: '1rem',
        '& h4': {
            fontSize: '18px',
            fontFamily:'StaplesNormsBold',
            color: COLORS.secondaryNeutralBlack,
        },
    },
    reg: {
        fontSize: '12px',
    },
    ctaBtn: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        fontWeight: 500,
        margin: '1rem 0',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    action: {
        color: COLORS.brandCyan,
        fontSize: '14px',
        fontWeight: 500,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    tsaPre: {
        margin: '0px 16px 20px',
    },
    nearestStore: {
        color: COLORS.secondaryNeutralBlack,
        fontSize: '16px',
        fontWeight: 500,
        '& span': {
            color: COLORS.brandCyan,
        },
    },
}));

export function TsaPreCheck() {
    const classes = useStyles();
    const { mainStore } = useStoreData();
    const { setStoreLinkInNative } = useTabsUIStateContext();
    const [localStoreHasTsa, setLocalStoreHasTsa] = useState(false);
    const [nearestStoreWithTsa, setNearestStoreWithTsa] = useState<
        Store | any
    >();
    const { analyticsCustomEvent } = useEventsReporter();
    // eslint-disable-next-line max-len
    const getStarted = `${process.env.REACT_APP_DOT_COM_URL}/stores/services/tsa`;
    const differentLoc = `${process.env.REACT_APP_DOT_COM_URL}/stores/services/tsa#participating-locations`;
    const checkList = `https://www.staplesconnect.com/site/binaries/content/assets/staples-connect/mobile-app/travelchecklist.pdf`;
    useEffect(() => {
        if (
            (mainStore?.storeServices as unknown as StoreService[])?.some(
                (item) => item.id === 'tsa_precheck' || item.id === 'tsa_precheck_clear'
            )
        ) {
            setNearestStoreWithTsa('');
            setLocalStoreHasTsa(true);
        } else {
            setLocalStoreHasTsa(false);
        }
    }, [mainStore]);

    const fetchAPI = async (zipCode: any) => {
        const additionalStores: any = await getStoresTsa(zipCode);
        const nearestStore: any =
            additionalStores?.find((store: any) =>
                (store?.storeServices as unknown as StoreService[])?.find(
                    (item) => item.id === 'tsa_precheck' || item.id === 'tsa_precheck_clear'
                )
            ) || undefined;
        return setNearestStoreWithTsa(nearestStore);
    };

    useEffect(() => {
        fetchAPI(mainStore?.address?.postal_code);
    }, [mainStore?.address?.address_1]);

    return (
        <>
            <FullWidthCard
                noPadding
                id="tsa-precheck"
                className={classes.tsaPre}
            >
                <div className={classes.header}>
                    <img aria-hidden="true"
                        src={'https://de-app.staplesconnect.com/images/Home/tsa-mobile.png'}
                        alt="protection plan"
                    />
                </div>
                <Box className={classes.description}>
                    {localStoreHasTsa ? (
                        <Typography variant="h4">
                            Your local Staples
                            <sup className={classes.reg}>&reg;</sup> store
                            offers TSA PreCheck{' '}
                            <sup className={classes.reg}>&reg; &nbsp;</sup>
                            enrollment.
                        </Typography>
                    ) : null}
                    {!localStoreHasTsa && nearestStoreWithTsa ? (
                        <Typography className={classes.nearestStore}>
                            <strong>
                                {nearestStoreWithTsa.address.city},{' '}
                                {nearestStoreWithTsa.address.region}
                            </strong>
                            &nbsp;Staples is your closest store that offers TSA
                            PreCheck
                            <sup className={classes.reg}>&reg; &nbsp;</sup>
                            enrollment.
                        </Typography>
                    ) : null}

                    {!localStoreHasTsa && !nearestStoreWithTsa ? (
                        <Typography className={classes.nearestStore}>
                            TSA PreCheck{' '}
                            <sup className={classes.reg}>&reg;</sup>
                            &nbsp;enrollment is now available at participating
                            Staples<sup className={classes.reg}>&reg;</sup>{' '}
                            stores.
                        </Typography>
                    ) : null}
                    <LoadingButton
                        fullWidth
                        id="tsa-pre-get-started"
                        variant="contained"
                        classes={{
                            root: classes.ctaBtn,
                        }}
                        type="button"
                        onClick={() => {
                            setStoreLinkInNative({
                                src: getStarted,
                                show: true,
                            });
                            analyticsCustomEvent(eventMetricsMap.stores, {
                                click_text: 'apply now',
                                element_location: 'promotion tiles',
                                tile_category: 'tsa carousel',
                            });
                        }}
                        loading={false}
                    >
                        Get started
                    </LoadingButton>
                    <div className={classes.actions}>
                        <ButtonBase
                            id="tsa-pre-different-loc"
                            className={classes.action}
                            onClick={() => {
                                setStoreLinkInNative({
                                    src: differentLoc,
                                    show: true,
                                });
                                analyticsCustomEvent(eventMetricsMap.stores, {
                                    click_text: 'find different location',
                                    element_location: 'promotion tiles',
                                    tile_category: 'tsa carousel',
                                });
                            }}
                        >
                            {!localStoreHasTsa && !nearestStoreWithTsa
                                ? 'Find a nearby location'
                                : 'Find a different location'}
                        </ButtonBase>
                        <ButtonBase
                            id="tsa-pre-checklist"
                            className={classes.action}
                            onClick={() => {
                                openInNewTab(checkList);
                                analyticsCustomEvent(eventMetricsMap.stores, {
                                    click_text: 'travel checklist',
                                    element_location: 'promotion tiles',
                                    tile_category: 'tsa carousel',
                                });
                            }}
                        >
                            Travel checklist
                        </ButtonBase>
                    </div>
                </Box>
            </FullWidthCard>
        </>
    );
}
export default TsaPreCheck;
