// main screen scroll context
import { createContext, useContext } from 'react';

/**
 * Carries the useScrollTrigger value through to other components
 */
const ScreenScrollContext = createContext({
  show: false,
});

export function useScreenScrollContext() {
  return useContext(ScreenScrollContext);
}

export default ScreenScrollContext;
