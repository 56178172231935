import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { GUTTER_WIDTH } from 'src/theme';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import AppLink from 'src/components/link';
import COLORS from 'src/lib/colors';
import ScrollableCard from 'src/components/cards/scrollable-card';
import ScrollableRow from 'src/components/scrollable-row';
import {
    RewardsAccountUserType,
    RewardsAccountConfirmState,
} from 'src/services/types';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useDragScroll from 'src/hooks/useDragScroll';
// Promotion code
import { useIsMobileWidth } from 'src/hooks/useWidth';
import { isPromotionRunning } from './PromotionDialog';

const learningURL = 'https://www.staplesconnect.com/learning';

const useStyles = makeStyles({
    sectionTitle: {
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: '.85rem',
        marginTop: '1.75rem',
        marginLeft: '1.5rem',
    },
    promoCardContainer: {
        padding: '0px',
    },
    promoCardContainerImg: {
        width: '100%',
        height: '9.8rem',
        objectFit: 'cover',
        display: 'flex',
        flexGrow: 0,
    },
    promoCardContainerLowerContent: {
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: '0.888rem',
    },
    promoCardInfoText: {
        display: 'flex',
        flexGrow: 1,
        fontSize: '1rem',
        paddingRight: '1.5rem',
        fontWeight: 500,
    },
    promoCardCTA: {
        paddingTop: '0.888rem',
        paddingBottom: '0.333rem',
        color: COLORS.brandCyan,
        fontSize: '1rem',
        fontWeight: 'bold',
        '&:hover': {
            textDecoration: 'none',
        },
    },
});

type GetStartedWithClassroomRewardsProps = {
    userType?: RewardsAccountUserType;
    userConfirmState?: RewardsAccountConfirmState;
    onSharePressed?: () => void;
    onGiveNowPressed?: () => void;
};

type StateProps = {
    userType?: RewardsAccountUserType;
    userConfirmState?: RewardsAccountConfirmState;
};
function getHeadline({ userType, userConfirmState }: StateProps): string {
    if (userConfirmState !== 'Confirmed') {
        return 'Join Classroom Rewards today!';
    }
    if (userType === 'Supporter') {
        return 'Earn and give with Classroom Rewards today!';
    }
    return 'Receive and pay in-store with Classroom Rewards today!';
}
type ActionType =
    | 'go-to-rewards'
    | 'go-to-giving-flow'
    | 'share-invite-supporters'
    | 'share-invite-teachers'
    | 'discover-learning'
    | 'go-to-lists';

type ScrollingCardInfo = {
    body: string;
    ctaCopy: string;
    ctaAction: ActionType;
    image: string;
};

function getFirstScrollingCard({
    userType,
    userConfirmState,
}: StateProps): ScrollingCardInfo {
    // Promotion code
    if (isPromotionRunning) {
        return {
            // eslint-disable-next-line max-len
            body: '10% Back when you shop to give to your local teachers',
            ctaCopy: 'Donate now',
            ctaAction: 'go-to-rewards',
            image: require('../assets/cards/sameDayDelivery.png'),
        };
    }
    // if (isPromotionRunning && userConfirmState === 'Unconfirmed') {
    //   return {
    //     // eslint-disable-next-line max-len
    //     body: '10% Back when you shop to give to your local teachers',
    //     ctaCopy: 'Enroll now',
    //     ctaAction: 'go-to-rewards',
    //     image: require('../assets/cards/sameDayDelivery.png',
    //   };
    // }
    if (!userType && userConfirmState === 'Unconfirmed') {
        return {
            body: '5% Back when you shop to give to your local teachers',
            ctaCopy: 'Enroll now',
            ctaAction: 'go-to-rewards',
            image: require('../assets/cards/getStartedCardUnenrolled1.jpg'),
        };
    }
    if (userType && userConfirmState === 'Unconfirmed') {
        return {
            body: '5% Back when you shop to give to your local teachers',
            ctaCopy: 'Finish enrolling',
            ctaAction: 'go-to-rewards',
            image: require('../assets/cards/getStartedCardPartiallyEnrolled1.jpg'),
        };
    }
    if (userType === 'Supporter') {
        return {
            body: 'Shop your local store to earn Classroom Rewards to give to your teacher or school',
            ctaCopy: 'Give',
            ctaAction: 'go-to-giving-flow',
            image: require('../assets/cards/getStartedCardEnrolledSupporter1.jpg'),
        };
    }
    // teacher or admin
    return {
        body: 'Shop your local store and pay for purchases with Classroom Rewards',
        ctaCopy: 'View activity',
        ctaAction: 'go-to-rewards',
        image: require('../assets/cards/getStartedCardEnrolledTeacher1.jpg'),
    };
}

function getSecondScrollingCard({
    userType,
    userConfirmState,
}: StateProps): ScrollingCardInfo {
    if (userConfirmState === 'Unconfirmed') {
        return {
            body: 'Make a difference in your community together. Share the news about Classroom Rewards today!',
            ctaCopy: 'Share',
            ctaAction: 'share-invite-supporters',
            image: require('../assets/cards/getStartedCardUnenrolled2.jpg'),
        };
    }
    if (userConfirmState === 'Pending') {
        return {
            body: 'Make a difference in your community together. Share the news about Classroom Rewards today!',
            ctaCopy: 'Share',
            ctaAction: 'share-invite-supporters',
            image: require('../assets/cards/getStartedCardPartiallyEnrolled2.jpg'),
        };
    }
    if (userType === 'Supporter') {
        return {
            body: 'Teachers use your donated classroom rewards to pay for needed supplies. Invite them to join today!',
            ctaCopy: 'Share',
            ctaAction: 'share-invite-teachers',
            image: require('../assets/cards/getStartedCardEnrolledSupporter2.jpg'),
        };
    }
    // teacher or admin
    return {
        body:
            // eslint-disable-next-line max-len
            'Parents want to help your classes run smoothly so your students thrive. Share the news about Classroom Rewards today!',
        ctaCopy: 'Share',
        ctaAction: 'share-invite-supporters',
        image: require('../assets/cards/getStartedCardEnrolledTeacher2.jpg'),
    };
}

function getThirdScrollingCard(): ScrollingCardInfo {
    return {
        // eslint-disable-next-line max-len
        body: 'We’ve got your back with inspiration to make learning fun',
        ctaCopy: 'Discover',
        ctaAction: 'discover-learning',
        image: require('../assets/cards/bgCardLearning.png'),
    };
}

/** used for loading and displaying classroom reward summary */
export function GetStartedWithClassroomRewards({
    userType,
    userConfirmState,
    onGiveNowPressed,
    onSharePressed,
}: GetStartedWithClassroomRewardsProps) {
    const classes = useStyles();
    const isMobileWidth = useIsMobileWidth();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { history, pathname } = useRouter();
    const scrollProps = useDragScroll();
    function onCardPress(actionType: ActionType) {
        if (!scrollProps.scrolled) {
            if (onGiveNowPressed && actionType === 'go-to-giving-flow') {
                onGiveNowPressed();
            }
            if (actionType === 'go-to-rewards') {
                if (pathname === ROUTES.rewardsWallet) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    history.push(ROUTES.rewardsWallet);
                } else {
                    history.push(ROUTES.rewards);
                }
            }
            if (actionType === 'go-to-lists') {
                history.push(ROUTES.lists);
            }
            if (actionType === 'discover-learning') {
                openInNewTab(learningURL);
                analyticsCustomEvent(eventMetricsMap.outbound, {
                    click_text: 'learning',
                    link_domain: 'staplesconnect.com',
                    link_url: learningURL,
                });
                optimoveEvents(eventMetricsMap.scm_outbound, {
                    click_text: 'learning',
                    link_domain: 'staplesconnect.com',
                    link_url: learningURL,
                });
            }
            if (
                onSharePressed &&
                (actionType === 'share-invite-supporters' ||
                    actionType === 'share-invite-teachers')
            ) {
                customEvent(eventMetricsMap.share_tapped);
                onSharePressed();
            }
        }
    }

    const headline = useMemo(
        () => getHeadline({ userType, userConfirmState }),
        [userType, userConfirmState]
    );
    const firstCard = useMemo(
        () => getFirstScrollingCard({ userType, userConfirmState }),
        [userType, userConfirmState]
    );
    const secondCard = useMemo(
        () => getSecondScrollingCard({ userType, userConfirmState }),
        [userType, userConfirmState]
    );
    const thirdCard = getThirdScrollingCard();

    const onFirstCardPress = () => {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: firstCard.ctaCopy,
            element_location: 'Earn and Give Tiles',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: firstCard.ctaCopy,
            element_location: 'Earn and Give Tiles',
        });
        onCardPress(firstCard.ctaAction);
    };
    const onSecondCardPress = () => {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: secondCard.ctaCopy,
            element_location: 'Earn and Give Tiles',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: secondCard.ctaCopy,
            element_location: 'Earn and Give Tiles',
        });
        onCardPress(secondCard.ctaAction);
    };
    const onThirdCardPress = () => {
        analyticsCustomEvent(eventMetricsMap.classroom_rewards, {
            click_text: thirdCard.ctaCopy,
            element_location: 'Earn and Give Tiles',
        });
        optimoveEvents(eventMetricsMap.scm_classroom_rewards, {
            click_text: thirdCard.ctaCopy,
            element_location: 'Earn and Give Tiles',
        });
        onCardPress(thirdCard.ctaAction);
    };

    return (
        <div>
            <Typography className={classes.sectionTitle}>{headline}</Typography>
            <ScrollableRow
                id="classroom-rewards-cta-scrollable-row"
                scrollProps={scrollProps}
            >
                <ScrollableCard
                    minCardWidth={isMobileWidth ? '15.5rem' : '20rem'}
                    id="classroom-rewards-cta-teacher-enroll-card"
                    onClick={onFirstCardPress}
                    noPadding
                >
                    <img
                        className={classes.promoCardContainerImg}
                        src={firstCard.image}
                        alt="first card bg"
                    />
                    <div className={classes.promoCardContainerLowerContent}>
                        <Typography className={classes.promoCardInfoText}>
                            {firstCard.body}
                        </Typography>
                        <Typography className={classes.promoCardCTA}>
                            {firstCard.ctaCopy}
                        </Typography>
                    </div>
                </ScrollableCard>
                <ScrollableCard
                    id="classroom-rewards-cta-parent-enroll-card"
                    onClick={onSecondCardPress}
                    noPadding
                >
                    <img
                        className={classes.promoCardContainerImg}
                        src={secondCard.image}
                        alt="second card bg"
                    />
                    <div className={classes.promoCardContainerLowerContent}>
                        <Typography className={classes.promoCardInfoText}>
                            {secondCard.body}
                        </Typography>
                        <Typography className={classes.promoCardCTA}>
                            {secondCard.ctaCopy}
                        </Typography>
                    </div>
                </ScrollableCard>
                <ScrollableCard
                    id="discover-learning-card"
                    onClick={onThirdCardPress}
                    noPadding
                >
                    <img
                        className={classes.promoCardContainerImg}
                        src={thirdCard.image}
                        alt="third card bg"
                    />
                    <div className={classes.promoCardContainerLowerContent}>
                        <Typography className={classes.promoCardInfoText}>
                            {thirdCard.body}
                        </Typography>
                        <AppLink
                            id="home-staples-learning-button"
                            className={classes.promoCardCTA}
                            href={learningURL}
                            target="_blank"
                            onClick={() => {
                                analyticsCustomEvent(eventMetricsMap.outbound, {
                                    click_text: 'learning',
                                    link_domain: 'staplesconnect.com',
                                    link_url: learningURL,
                                });
                                optimoveEvents(eventMetricsMap.scm_outbound, {
                                    click_text: 'learning',
                                    link_domain: 'staplesconnect.com',
                                    link_url: learningURL,
                                });
                            }}
                        >
                            {thirdCard.ctaCopy}
                        </AppLink>
                    </div>
                </ScrollableCard>
            </ScrollableRow>
        </div>
    );
}

export default GetStartedWithClassroomRewards;
