/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { reorder } from 'src/lib/utils/draggable-list-utils';

function DraggableReorderableVariableHeightList({
  disabled,
  items: itemsFromProps,
  Item,
  itemProps,
  mutateDraggedItem,
  shouldUpdateItemsOnProps,
  sizeListStyle, // ?: React.CSSProperties | undefined
  isUserList = false,
}: any) {
  const [items, setItems] = useState<any>(itemsFromProps);
  useEffect(() => {
    // if (
    //   shouldUpdateItemsOnProps &&
    //   shouldUpdateItemsOnProps(items, itemsFromProps)
    // ) {
    //   // only set new items if we're
    // }
    if (itemProps && itemProps?.handleRefresh) {
      itemProps?.handleRefresh();
    }
    if (!isUserList) {
      setItems(itemsFromProps);
    }
  }, [itemsFromProps]);

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (result.source.index === result.destination.index) {
      return;
    }
    let newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    if (mutateDraggedItem) {
      newItems = mutateDraggedItem(newItems, result.destination.index);
    }
    setItems(newItems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId="droppable" isDropDisabled={disabled}>
          {(providedOuter) => (
            <div {...providedOuter.droppableProps} ref={providedOuter.innerRef}>
              {items.map((item: any, index: number) => (
                <Draggable
                  draggableId={item.id}
                  index={index}
                  key={item.id}
                  isDragDisabled={disabled}
                >
                  {(
                    provided: { innerRef: React.Ref<any> | undefined },
                    snapshot
                  ) => (
                    <Item
                      provided={provided}
                      isDragging={snapshot.isDragging}
                      style={sizeListStyle}
                      item={{ ...item }}
                      itemProps={itemProps}
                    />
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default DraggableReorderableVariableHeightList;
