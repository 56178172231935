/* eslint-disable import/namespace */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Box, ButtonBase, Typography, makeStyles } from '@material-ui/core';
import BrandIframe from 'src/components/BrandIframe';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import OpenPrintExternalDialog from 'src/components/open-print-external-dialog';
import PullRefresh from 'src/components/pull-refresh';
import COLORS from 'src/lib/colors';
import CommunityCard from 'src/components/cards/community-card';
// import GetStartedWithClassroomRewards from 'src/components/get-started-with-classroom-rewards';
import StoreSearchModal from 'src/components/store-search-modal';
import useStoreData from 'src/hooks/useStoreData';
import { Store } from 'src/services/types';
import useUserInfo from 'src/hooks/useUserInfo';
// import RewardsPromoCardDialog from 'src/components/rewards-promo-card-dialog';
// import { fullName, isEducatorOrAdmin } from 'src/lib/utils/user-utils';
// import AvailableToSpendCard from 'src/components/available-to-spend-card';
// import AvailableToDonateCard from 'src/components/available-to-donate-card';
// import useDonationFlow from 'src/hooks/useDonationFlow';
import usePromoCard from 'src/hooks/usePromoCard';
// import GiveNowErrorDialog, {
//     useGiveNowError,
// } from 'src/components/give-now-error-dialog';
// import ShippingServicesHome from 'src/components/shipping-services/shipping-services-list/shipping-services-home';
// import ShippingServicesContainer from 'src/components/shipping-services/shipping-services-list/shipping-services-container';
// import CreateShippingSenderInformation from 'src/components/shipping-services/creating-shipment/creating-shipping-sender-information';
// import ShareDialog from 'src/components/share-dialog';
import { getAvailablePayoutAmount } from 'src/lib/utils/account-utils';
import ScrollContainer from 'src/components/screen-scroll';
// import CreateShippingContainer from 'src/components/shipping-services/creating-shipment/creating-shipping-container';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import useEventsReporter from 'src/hooks/useEventsReporter';
// import ViewAllDigitalCoupons from 'src/components/view-all-digital-coupons';
// import Products from 'src/components/Products';
import PurchaseHistory from 'src/components/PurchaseHistory';
// import { IconReceipt } from 'src/lib/icons';
// import ShippingCarouselComponent from 'src/components/ShippingCarousel';
// import GiftCard from 'src/components/gift-card';
// import HomePageOrderAutoReStockList from 'src/components/home-page-order-autorestock/home-page-order-autorestock-list';
// import ShippingQuoteEstimatorContainer from 'src/components/shipping-services/shipping-get-quote/shipping-quote-estimator-container';
// import ShoppingCarouselComponent from 'src/components/shopping-carousel';
// import ScanAProduct from 'src/components/scan-a-product';
import { useQuery } from 'react-query';
import { atRecommendation } from 'src/lib/api-client/adobe';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import Header from 'src/components/header';

// import ShippingUps from 'src/components/shippingUps';
// import { addToCartProduct } from 'src/lib/api-client/cart-api';
import LoadingOverlay from 'src/components/loading-overlay';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
// import useAdobeAnalytics from 'src/hooks/use-adobe-analytics';
import axios from 'axios';
import { getEstimatedZipCode } from 'src/lib/api-client/google/geolocation';
import { IconClose } from 'src/components/Icons';
import {
    shoppingTypes,
    useProductBrowseContext,
} from 'src/lib/contexts/product-browse-context';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CreditCelebrationDialog from 'src/components/credit-celebration-dialog';
import NavTop from 'src/components/nav-top';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { mergeClasses } from 'src/stylesheets/utils';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import { SetSomniAppCookies } from 'src/lib/utils/app-utils';
import { getMarketingData, updateSDKPageVisit } from './optimove';
// import AlertMessage from './alert-message';
// import ShippingCard from '../components/shipping-card';
// import ReturnRewards from '../components/return-rewards/return-rewards';
import QuickLinksCard from '../components/cards/quick-links-card/quick-links.card';

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
    },
    sectionTitle: {
        fontWeight: 500,
        fontSize: '1.1rem',
        marginBottom: '.85rem',
        marginTop: '1.75rem',
        marginLeft: '1.5rem',
    },
    promoCardContainer: {
        padding: '0px',
    },
    promoCardContainerImg: {
        width: '100%',
        height: '9.8rem',
        objectFit: 'cover',
        display: 'flex',
        flexGrow: 0,
    },
    promoCardContainerLowerContent: {
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: '0.888rem',
    },
    promoCardInfoText: {
        display: 'flex',
        flexGrow: 1,
        fontSize: '1rem',
        paddingRight: '1.5rem',
        fontWeight: 500,
    },
    promoCardCTA: {
        paddingTop: '0.888rem',
        paddingBottom: '0.333rem',
        color: COLORS.brandCyan,
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    barcode: {
        width: '24px',
    },
    userProfileHome: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '0.5rem',
        // position: 'absolute',
        // top: '25px',
        // right: '25px',
        cursor: 'pointer',
        '& > button': {
            marginBottom: '0.5rem',
        },
    },
    homeIcons: {
        display: 'flex',
        width: '60px',
        justifyContent: 'space-between',
    },
    iframe: {
        width: '100vw',
        height: '100vh',
        border: 'none',
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    headerLogo: {
        margin: '0 auto',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
        color: '#ffffff',
        margin: 'auto',
    },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Home() {
    const classes = useStyles();
    const router = useRouter();
    const { closePromoCard, promoCardOpen } = usePromoCard();
    const homePageCMSURL = process.env.REACT_APP_DOT_COM_URL;
    const {
        storeServicesLink,
        setStoreServicesLink,
        storeLocatorUrl,
        setStoreLocatorUrl,
        homeLinkInNative,
        setHomeLinkInNative,
        dotComUrl,
        setDotComUrl,
        cartPage,
        printPage,
        handleSetCartPage,
        homePage,
        itemPage,
        setPrintPage,
    } = useTabsUIStateContext();

    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const {
        classroomWalletRewardsTab,
        navBottomActiveTab,
        setNavBottomActiveTab,
    } = useUserUpdateContext();
    const { userInfo } = useUserInfo();
    const rewardsNumber = userInfo?.rewardsNumber || '0';
    const [showLoading, setLoading] = useState(false);
    const [isLocationShared, setIsLocationShared] = useState(false);
    const { setShoppingType, setSelectedShoppingType, fromPath, setFromPath } =
        useProductBrowseContext();
    const [wasInBackground, setWasInBackground] = useState(false);
    const [quickLinkUrl, setQuickLinkUrl] = useState('');
    const [isIframeScrolled, setIsIframeScrolled] = useState(false);
    const { isWebViewLoading } = useCartCountContext();

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const shoppingType = JSON.parse(
        localStorage.getItem('shoppingType') || '{}'
    );
    const shopOnline = shoppingType.type === 'Shopping online';

    const {
        mainStore,
        additionalStores,
        error,
        isFetching,
        updateMainStore,
        storeFindErrorMessage,
    } = useStoreData();
    const { data: availableOneHourIteamsList } = useQuery(
        ['atRecommendation'],
        () =>
            atRecommendation(
                'APP_HOMEPAGE_BOPIS',
                mainStore?.storeNumber,
                mainStore?.address?.postal_code
            ),
        {
            ...NO_REFETCH,
        }
    );
    const PageURL = 'http://localhost:3000/home';
    const PageTitle = 'Home Page';
    const PageCategory = 'Home page';

    const { data: staplesRewardsDetail } = useQuery(
        ['details', rewardsNumber],
        () => getStaplesRewardsData(rewardsNumber)
    );
    const [storeCreditCoupon, setStoreCreditCoupon] = useState({});
    useEffect(() => {
        if (staplesRewardsDetail?.data) {
            const findStoreCreditCoupon =
                staplesRewardsDetail?.data?.couponDataList.find(
                    (couponData: any) =>
                        couponData?.couponInfo?.couponClass === 'StoreCredit'
                ) || {};
            setStoreCreditCoupon(findStoreCreditCoupon);
        }
    }, [staplesRewardsDetail?.data]);

    const handleOnIframeScroll = (event: Event) => {
        const target = event?.currentTarget as any;
        const scrollTop = target?.scrollY;
        if (scrollTop >= 50) {
            setIsIframeScrolled(true);
        } else if (scrollTop < 50) {
            setIsIframeScrolled(false);
        }
    };

    const refreshWebView = () => {
        SetSomniAppCookies();
        const documentData: any = document?.getElementById?.(
            'shopping-online-screen-iframe'
        );
        if (documentData?.src) {
            documentData.src = homePageCMSURL;
        }
        setTimeout(() => {
            const iframe = iframeRef?.current;
            if (iframe) {
                const iframeContentWindow = iframe?.contentWindow;
                iframeContentWindow?.removeEventListener(
                    'scroll',
                    handleOnIframeScroll,
                    true
                );
                iframeContentWindow?.addEventListener(
                    'scroll',
                    handleOnIframeScroll,
                    true
                );
            }
        }, 1000);
    };

    useEffect(() => {
        if (navBottomActiveTab === ROUTES.home && fromPath === ROUTES.home) {
            setShoppingType(shoppingTypes[0]);
            setHomeLinkInNative({ src: '', show: false });
            setStoreServicesLink('');
            setNavBottomActiveTab('');
            setStoreLocatorUrl('');
            setDotComUrl('');
            setQuickLinkUrl('');
            refreshWebView();
        }
    }, [navBottomActiveTab]);

    useEffect(() => {
        updateSDKPageVisit(PageURL, PageTitle, PageCategory);
        // getMarketingData(userInfo?.email);
    }, [userInfo?.email]);

    const storeCoordinates = async (
        latitude: any,
        longitude: any,
        radius: any,
        limit: any
    ) => {
        if (process.env.REACT_APP_STORE_LOCATION) {
            let url = process.env.REACT_APP_STORE_LOCATION as string;
            url += `?latitude=${latitude}&longitude=${longitude}&radius=${radius}&limit=${limit}`;
            try {
                const stores = await axios.get(url);
                return { status: true, data: stores.data };
            } catch (errorMsg) {
                console.error('Failed to get Co-Ordinates', errorMsg);
                return { status: false, data: null };
            }
        }
        return { status: false, data: null };
    };

    const sendAnalyticsEvent = async () => {
        const exactLocation = await getEstimatedZipCode();
        const { latitude, longitude } = exactLocation;
        const stores = await storeCoordinates(latitude, longitude, 0.2, 1);
        if (stores?.data[0]?.storeNumber && userInfo?.preferredStoreId) {
            analyticsCustomEvent(eventMetricsMap.store_visit, {
                store_number: stores?.data[0]?.storeNumber,
                home_store:
                    stores?.data[0]?.storeNumber === userInfo?.preferredStoreId
                        ? 'yes'
                        : 'no',
                rewards_number: userInfo?.rewardsNumber,
            });
        }
    };
    const requestPermission = async () => {
        const result = await navigator.permissions
            .query({
                name: 'geolocation',
            })
            .then((data) => {
                if (data.state === 'granted') {
                    if (SessionManager.isLoggedIn) {
                        sendAnalyticsEvent();
                    }
                    analyticsScreenCustomNames({
                        location_services: 'allow',
                    });
                    setIsLocationShared(false);
                } else {
                    setIsLocationShared(false);
                    analyticsScreenCustomNames({
                        location_services: 'not allow',
                    });
                }
                data.onchange = (permissionStatus: any) => {
                    if (permissionStatus?.currentTarget?.state === 'granted') {
                        analyticsCustomEvent(
                            eventMetricsMap.location_services,
                            {
                                click_text: 'allow',
                                element_location: 'pop-up',
                            }
                        );
                        sendAnalyticsEvent();
                    } else if (
                        permissionStatus?.currentTarget?.state === 'denied'
                    ) {
                        analyticsCustomEvent(
                            eventMetricsMap.location_services,
                            {
                                click_text: 'not allow',
                                element_location: 'pop-up',
                            }
                        );
                    }
                };
            });
    };
    const checkPermissionAndSendEvent = async () => {
        const data = await requestPermission();
    };
    useEffect(() => {
        if (userInfo?.preferredStoreId) {
            checkPermissionAndSendEvent();
        }
    }, [userInfo?.preferredStoreId]);

    // eslint-disable-next-line
    useEffect(() => {
        if (isLocationShared && SessionManager.isLoggedIn) {
            const handleVisibilityChange = () => {
                if (document.hidden) {
                    setWasInBackground(true);
                } else {
                    sendAnalyticsEvent();
                    setWasInBackground(false);
                }
            };

            document.addEventListener(
                'visibilitychange',
                handleVisibilityChange
            );
            return () => {
                document.removeEventListener(
                    'visibilitychange',
                    handleVisibilityChange
                );
            };
        }
    }, [wasInBackground]);

    const getShoppathname = localStorage.getItem('shoppingOnlineRetail');

    const setShoppingTypeDeepLink = () => {
        if (getShoppathname) {
            if (getShoppathname === '/home/retail') {
                setSelectedShoppingType(shoppingTypes[0]);
                localStorage.setItem(
                    'shoppingType',
                    JSON.stringify(shoppingTypes[0])
                );
            } else if (getShoppathname === '/home/online') {
                setSelectedShoppingType(shoppingTypes[1]);
                localStorage.setItem(
                    'shoppingType',
                    JSON.stringify(shoppingTypes[1])
                );
            }
            localStorage.removeItem('shoppingOnlineRetail');
        }
    };

    useEffect(() => {
        setShoppingTypeDeepLink();
    }, [getShoppathname, router.pathname]);

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    const availablePayoutAmount = getAvailablePayoutAmount(
        userInfo?.rewardsDetails
    );
    const availableDonationAmount =
        userInfo?.rewardsDetails?.availableDonationAmount;

    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [showShareDialog, setShowShareDialog] = useState(false);

    function onStoreSelected(store: Store) {
        updateMainStore(store);
        setSearchModalOpen(false);
    }

    const handleClickOnBackBtnOnline = () => {
        refreshWebView();
        const shopData = shoppingTypes[0];
        if (shopOnline) {
            localStorage.setItem('shoppingType', JSON.stringify(shopData));
            setShoppingType(shopData);
        }
        if (storeServicesLink) {
            setStoreServicesLink('');
        }
        handleSetCartPage(false);
        if (quickLinkUrl) {
            setQuickLinkUrl('');
        }
    };
    useEffect(() => {
        if (!isWebViewLoading) {
            setTimeout(() => {
                const iframe = iframeRef?.current;
                if (iframe) {
                    const iframeContentWindow = iframe?.contentWindow;
                    iframeContentWindow?.removeEventListener(
                        'scroll',
                        handleOnIframeScroll,
                        true
                    );
                    iframeContentWindow?.addEventListener(
                        'scroll',
                        handleOnIframeScroll,
                        true
                    );
                }
            }, 1200);
        }
    }, [isWebViewLoading]);

    useEffect(() => {
        setTimeout(() => {
            const iframe = iframeRef?.current;
            if (iframe) {
                const iframeContentWindow = iframe?.contentWindow;
                iframeContentWindow?.removeEventListener(
                    'scroll',
                    handleOnIframeScroll,
                    true
                );
                iframeContentWindow?.addEventListener(
                    'scroll',
                    handleOnIframeScroll,
                    true
                );
            }
        }, 1000);

        return () => {
            const iframe = iframeRef?.current;
            if (iframe) {
                const iframeContentWindow = iframe?.contentWindow;
                if (iframeContentWindow && router.pathname !== ROUTES.home) {
                    iframeContentWindow?.removeEventListener(
                        'scroll',
                        handleOnIframeScroll,
                        true
                    );
                }
            }
        };
    }, [iframeRef, quickLinkUrl]);

    useEffect(() => {
        if (quickLinkUrl.includes('printing')) {
            setPrintPage(true);
        }
    }, [quickLinkUrl]);

    const handleClickOnBackBtn = () => {
        refreshWebView();
        setPrintPage(false);
        setQuickLinkUrl('');
    };

    const [celebrationDialog, setCelebrationDialog] = useState(true);

    const closeCelebrationDialog = () => {
        setCelebrationDialog(!celebrationDialog);
    };
    const isStoreCreditOpenFristTime = (): boolean => {
        let res = false;
        const isOpenStatus =
            localStorage.getItem('isStoreCreditDialogOpen') || '{}';
        const parseStoreCreditData = JSON.parse(isOpenStatus);
        const couponStoreData: any = storeCreditCoupon;
        if (
            userInfo?.rewardsNumber &&
            ((parseStoreCreditData.open === true &&
                couponStoreData?.couponInfo?.couponValue !==
                    parseStoreCreditData?.value) ||
                !parseStoreCreditData?.open) &&
            Object.keys(storeCreditCoupon).length
        ) {
            res = true;
        } else {
            res = false;
        }
        return res;
    };

    // useEffect(() => {
    //     if (isStoreModeOn) {
    //         router.push(ROUTES.storeMode);
    //     }
    // }, [isStoreModeOn]);

    return (
        <PullRefresh className="hiddenScrollForPullToRefresh">
            {isStoreCreditOpenFristTime() && (
                <CreditCelebrationDialog
                    open={celebrationDialog}
                    onClose={closeCelebrationDialog}
                    coupon={storeCreditCoupon}
                />
            )}
            <Box className={mergeClasses(classes.screen)}>
                {cartPage ? (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <ButtonBase onClick={handleClickOnBackBtnOnline}>
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                            <img
                                className={classes.headerLogo}
                                src={require('../assets/icons/iconStaplesLogo.svg')}
                                alt="staples connect logo"
                                id="staples-connect-logo"
                            />
                        </Box>
                    </NavTop>
                ) : printPage ? (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <Typography className={classes.title}>
                                Print Shop
                            </Typography>
                            <ButtonBase onClick={handleClickOnBackBtn}>
                                <IconClose />
                            </ButtonBase>
                        </Box>
                    </NavTop>
                ) : (
                    <Header
                        position="fixed"
                        headerFull={!itemPage && !quickLinkUrl}
                        backButton={
                            (shopOnline || !!quickLinkUrl || !homePage) &&
                            router.pathname === ROUTES.home
                        }
                        onClickHandler={handleClickOnBackBtnOnline}
                    />
                )}
                {!cartPage && !quickLinkUrl && !printPage && (
                    <CommunityCard
                        headerFull={!itemPage && !quickLinkUrl}
                        storeData={mainStore}
                        error={error}
                        isFetching={isFetching}
                        openStoreSearch={() => setSearchModalOpen(true)}
                        storeFindErrorMessage={storeFindErrorMessage}
                    />
                )}

                <Box>
                    <StoreSearchModal
                        mainStore={mainStore}
                        nearbyStores={additionalStores}
                        onStoreSelected={onStoreSelected}
                        open={searchModalOpen}
                        handleClose={() => setSearchModalOpen(false)}
                    />
                </Box>
                <AnimatePresence>
                    {homePage && !isIframeScrolled && (
                        <QuickLinksCard setQuickLinkUrl={setQuickLinkUrl} />
                    )}
                </AnimatePresence>
                <Box
                    style={{
                        paddingTop: quickLinkUrl && !printPage ? '120px' : '0',
                    }}
                >
                    <BrandIframe
                        src={quickLinkUrl || process.env.REACT_APP_DOT_COM_URL}
                        title="Shopping-online-screen"
                        id="shopping-online-screen-iframe"
                        iframeRef={iframeRef}
                        height={
                            homePage && !isIframeScrolled
                                ? 'calc(100vh - 400px)'
                                : cartPage || printPage
                                ? 'calc(100vh - 78px)'
                                : 'calc(100vh - 232px)'
                        }
                    />
                </Box>
                <OpenPrintExternalDialog />
            </Box>
            {showLoading ? (
                <LoadingOverlay
                    id="loadingOverlay"
                    variant="wordmark"
                    theme="transparent-light"
                    open={showLoading}
                />
            ) : null}
        </PullRefresh>
    );
}

/**
 * Contains the users main store info, CTAs for classroom rewards, and supply lists.
 */
// shippingServicesLabelShipment
const HomeBaseRouter = () => {
    const match = useRouteMatch();
    const router = useRouter();
    const goBack = () => router.push(ROUTES.home);
    const { userInfo } = useUserInfo();

    return (
        <Switch>
            <Route path={`${match.path}/purchasehistory`}>
                <PurchaseHistory goBack={goBack} />
            </Route>
            <Route path={match.path}>
                <ScrollContainer isHomePage>
                    <Home />
                </ScrollContainer>
            </Route>
        </Switch>
    );
};

// const WrappedHomeBase = () => <HomeBaseRouter />;
export default HomeBaseRouter;
