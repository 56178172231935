import React, { useEffect, useState } from 'react';
import { UserData } from 'react-oidc';
import useInterval from 'src/hooks/useInterval';
import useAuthFromContext from 'src/lib/auth/use-auth-from-context';
import useUserInfo from 'src/hooks/useUserInfo';

/**
 * TODO: add session timeout UX for when we no longer have an active session
 * idea: maybe use the global toast message for this?
 */
const SessionTimeout = ({ context }: any) => {
    const { isLoading, oidcUser } = useAuthFromContext(context);
    const { userInfoError } = useUserInfo();
    const [isSessionTimeout, setIsSessionTimeout] = useState(
        !isLoading && oidcUser && oidcUser.expired
    );
    useInterval(() => {
        setIsSessionTimeout(!isLoading && oidcUser && oidcUser.expired);
    }, 1000);

    useEffect(() => {
        if (!isLoading && isSessionTimeout) {
            /* eslint-disable no-restricted-globals */
            /* eslint-disable no-alert */
            confirm('Your session has timed out, we must now refresh the page');
            window.location.reload();
        }
    }, [isSessionTimeout, isLoading, userInfoError]);
    return null;
};

export default (props: any) => (
    <UserData.Consumer>
        {(context) => <SessionTimeout {...props} context={context} />}
    </UserData.Consumer>
);
