import { createContext, useState, useContext, useRef } from 'react';
import useInterval from 'src/hooks/useInterval';
import { userInfoAutoRefreshInterval } from '../constants';

const UserLoginContext = createContext({
    selectedField: '',
    moreAccount: true,
    errorMessage: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSetSelectedField: (data: string) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSetMoreAccount: (data: boolean) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSetErrorMessage: (data: string) => {},
});

export function useUserLoginUpdateContextState() {
    const [selectedField, setSelectedField] = useState('');
    const [moreAccount, setMoreAccount] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSetSelectedField = (data: string) => setSelectedField(data);
    const handleSetMoreAccount = (data: boolean) => setMoreAccount(data);
    const handleSetErrorMessage = (data: string) => setErrorMessage(data);

    return {
        selectedField,
        handleSetSelectedField,
        moreAccount,
        handleSetMoreAccount,
        errorMessage,
        handleSetErrorMessage,
    };
}

export function userLoginUpdateContext() {
    return useContext(UserLoginContext);
}

export default UserLoginContext;
