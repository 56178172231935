/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
    Box,
    makeStyles,
    Typography,
    Card,
    CardContent,
    Drawer,
    ButtonBase,
    Grid,
    SwipeableDrawer,
} from '@material-ui/core';
import {
    shoppingTypes,
    useProductBrowseContext,
} from 'src/lib/contexts/product-browse-context';
import { optimoveEvents } from 'src/screens/optimove';
import FullWidthCard, {
    FullWidthCardProps,
} from 'src/components/cards/full-width-card';
import Log from 'src/lib/logging';
import HomeScreenInfo from 'src/components/home-screen-info';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useAdobeAnalytics from 'src/hooks/use-adobe-analytics';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { Store, StoreHourDetail, StoreHours } from 'src/services/types';
import { format, parse } from 'date-fns';
import moment from 'moment';
import { storeDetailsUrl } from 'src/lib/utils/store-utils';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import COLORS from 'src/lib/colors';
import useDragScroll from 'src/hooks/useDragScroll';
import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import { useRouter, useStoreData, useUserInfo } from 'src/hooks';
import ROUTES from 'src/lib/routes';
import { useQuery } from 'react-query';
import axiosInstance from 'src/lib/api-client/custom-axios';
import clsx from 'clsx';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import ScrollableRow from '../scrollable-row';
import ScrollableCard from './scrollable-card';
import InfoIconCard from './info-icon-card';
import AppLink from '../link';
import StoreDetailsDrawer from '../storeDetailsDrawer';
import { Body } from '../UI';
import styles from '../NavBottom/styles.module.scss';

type CommunityCardProps = FullWidthCardProps & {
    storeData?: Store | null;
    error: any;
    isFetching: boolean;
    openStoreSearch: () => void;
    storeFindErrorMessage: string;
    headerFull: boolean;
};
const storeServicesAllowToDis = [
    'Print and Marketing Services',
    'Recycling Services',
    'UPS Shipping & Drop-off',
    'TSA Precheck',
    'Passport Photos',
    'Amazon Returns',
    'Amazon Lockers',
    'iPostal1',
    'Tech Services',
    'ClipDrop',
    'Coworking',
];

const useStyles = makeStyles((theme) => ({
    accordianTitle: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#343434',
        fontFamily: 'StaplesNormsMedium',
    },
    openSign: {
        height: '10px',
        width: '10px',
        borderRadius: '3px',
        display: 'inline-block',
        marginRight: '8px',
    },
    storeDetailsLocation: {
        // margin: '20px 0px',
    },
    phone: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '30px',
        border: '0 !important',
        background: 'none',
        outline: '0 !important',
        textDecoration: 'none !important',
        color: '#585858 !important',
    },
    address: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        // margin: '10px 0',
    },
    learnMore: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',
        color: '#086dd2',
    },
    infoDetails: {
        height: '100%',
        padding: '0 10px 0 0',
        justifyContent: 'flex-start',
    },
    infoIcon: {
        height: '100%',
        justifyContent: 'center',
    },
    infoGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        [theme.breakpoints.down(370)]: {
            fontSize: '12px',
        },
    },
    infoContainer: {
        borderTop: '1px solid #F2F2F2',
        paddingTop: '24px',
        display: 'flex',
        gap: '14px',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    accordionDetailsContainer: {
        width: '100%',
        padding: '2rem',
    },
    container: {
        borderRadius: 0,
        margin: '0 !important',
        boxShadow: '0px 2px 4px rgb(0 0 0 / 3%)',
        paddingTop: ({ paddingTop }: any) => paddingTop || '120px',

        // paddingTop: '120px',
        borderBottom: '1px solid #ededed',
    },
    noLocationContainer: {
        marginTop: '1rem',
        textAlign: 'center',
        display: 'block',
    },
    noLocationIconContainer: {
        width: '3.3125rem',
    },
    noLocationTitle: {
        fontWeight: 500,
        textAlign: 'center',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    },
    updateLocation: {
        fontSize: '0.875rem',
        color: '#086dd2',
        fontWeight: 'bold',
        margin: '0.5rem',
        marginBottom: '1rem',
        cursor: 'pointer',
        textAlign: 'center',
    },
    communityTitle: {
        textAlign: 'center',
        marginTop: '.889rem',
        color: '#101820',
    },
    storeLocation: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        gap: 9,
        alignItems: 'center',
        [theme.breakpoints.down(360)]: {
            gap: 7,
        },
        [theme.breakpoints.down(320)]: {
            gap: 5,
        },
    },
    storeLocationText: {
        color: '#343434',
        fontFamily: 'StaplesNormsMedium',
        fontWeight: 500,
        fontSize: '1rem',
        display: 'flex',
        gap: 9,
        [theme.breakpoints.down(370)]: {
            fontSize: '0.84rem',
        },
        [theme.breakpoints.down(330)]: {
            fontSize: '0.8rem',
        },
    },
    storeOpenMessage: {
        textAlign: 'center',
        color: '#585858',
        fontSize: '12px',
        gap: 7,
        display: 'flex',
        alignItems: 'center',
    },
    ctas: {
        display: 'flex',
        width: '100%',
        padding: '20px 0px 0px',
        justifyContent: 'space-between',
        gap: '12px',
    },
    ctaText: {
        cursor: 'pointer',
        color: '#086dd2',
        fontSize: '14px',
        fontWeight: 500,
    },
    noStoresContainer: {
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },
    noStoresIconContainer: {
        textAlign: 'center',
        display: 'block',
    },
    noStoresText: {
        textAlign: 'center',
        fontSize: '1rem',
        paddingTop: '0.5rem',
        paddingLeft: '4rem',
        paddingRight: '4rem',
        fontWeight: 500,
    },
    storeInfoContainer: {
        display: 'flex',
        gap: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 25px',
        marginTop: '10px',
    },
    serviceContainer: {
        display: 'flex',
        gap: 9,
        alignItems: 'center',
        padding: '0 !important',
    },
    serviceIfoMainContainer: {
        display: 'flex',
        gap: 24,
        alignItems: 'center',
        boxShadow: 'none',
        overflow: 'initial',
        padding: '8px 0px',
    },
    serviceImageInfo: {
        width: 24,
    },
    servicetextInfo: {
        fontSize: 12,
        color: '#585858',
        lineHeight: '12px',
    },
    storeNoLocation: {
        display: 'flex',
        alignSelf: 'flex-start',
        gap: 9,
        alignItems: 'center',
        height: '50px',
        padding: '16px 24px',
        width: '100%',
    },
    paperDrawer: {
        borderRadius: ' 15px 15px 0px 0px',
    },
    drawerTitle: {
        fontWeight: 700,
        fontSize: '22px',
        textAlign: 'center',
        padding: '17px 0 4px 0',
    },
    shoppingTypeContainer: {
        display: 'flex',
        padding: '10px',
        margin: '10px 0',
        height: '124px',
        alignItems: 'center',
        borderBottom: '1px solid #F2F3F4',
        '&:last-child': {
            borderBottom: '0px',
        },
    },
    btnCta: {
        fontSize: '1.1667rem',
        background: COLORS.brandRed,
        textAlign: 'center',
        width: '175px',
        height: '36px',
        borderRadius: '28px',
        '& span': {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    exitContainer: {
        height: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '24px',
    },
    ctwText: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#000000',
        margin: '35px auto 16px',
    },
    tacoext: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#000000',
        marginBottom: '27px',
        width: '199px',
        textAlign: 'center',
    },
}));

function getDayOfWeek() {
    return format(new Date(), 'EEEE').toLowerCase();
}

function nextDayTime() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return format(currentDate, 'EEEE').toLowerCase();
}

/**
 * Helper function to determine if a store is currently open and when it closes
 * @returns {open}
 * @returns {closingTime} string in format of 9:00pm
 */
function describeStoreHours(
    storeHours: StoreHours,
    timezone?: string
): {
    open: boolean;
    closingTime: string;
    nextDayOpenTime: string;
} {
    const todaysHours = (storeHours as any)[getDayOfWeek()] as any;
    const nextDay: any = (storeHours as any)[nextDayTime()] as any;
    const dateOfClose = parse(todaysHours.close24Hr, 'HHmm', new Date());
    const nextDayTimeDis = parse(nextDay.open24Hr, 'HHmm', new Date());
    let now;
    if (timezone) {
        now = moment(new Date()).tz(timezone).format('HHmm');
    } else {
        now = new Date();
    }
    return {
        open: timezone ? now < todaysHours.close24Hr : now < dateOfClose,
        closingTime: moment(dateOfClose, 'HH:mm', true).isValid()
            ? format(dateOfClose, 'h:mmaaa')
            : 'Closed',
        nextDayOpenTime: moment(nextDayTimeDis, 'HH:mm', true).isValid()
            ? format(nextDayTimeDis, 'h:mmaaa')
            : 'Closed',
    };
}

/** Displays community information on the home screen */
export default function CommunityCard({
    openStoreSearch,
    storeData,
    error,
    isFetching,
    storeFindErrorMessage,
    headerFull,
}: CommunityCardProps) {
    const classes = useStyles({
        paddingTop: headerFull ? '120px' : '78px',
    });
    const scrollProps = useDragScroll();
    const { history } = useRouter();
    const { analyticsCustomEvent, customEvent } = useEventsReporter();
    const [storeName, setStoreName] = useState('');
    const newUser = localStorage.getItem('newUser');
    const { shoppingType, setShoppingType } = useProductBrowseContext();
    const { setStoreLocatorUrl } = useTabsUIStateContext();
    const { userInfo } = useUserInfo();
    const [storeOpenMessage, setStoreOpenMessage] = useState('');
    const [showShoppingDrawer, setShowShoppingDrawer] = useState({
        shoppingList: false,
        conformationTab: false,
    });
    const [shoppingListCount, setShoppingListCount] = useState(
        newUser === 'true' ? shoppingTypes?.slice(0, 2) : shoppingTypes
    );
    const [collapseExpand, setCollapseExpand] = useState(false);
    const [storeDetailsModalOpen, setStoreDetailsModalOpen] = useState(false);
    const { mainStore, additionalStores, updateMainStore } = useStoreData();
    const onStoreSelected = (store: Store) => {
        updateMainStore(store);
        setStoreDetailsModalOpen(false);
    };
    const { updateGenericLink } = useAdobeAnalytics();

    useEffect(() => {
        setShowShoppingDrawer({
            shoppingList: false,
            conformationTab: false,
        });
        updateGenericLink(shoppingType?.type);

        analyticsCustomEvent(eventMetricsMap.shopping_location, {
            click_text: shoppingType?.type,
        });
        console.log(
            '🚀 ~ file: community-card.tsx:303 ~ useEffect ~ shoppingType:',
            shoppingType
        );
    }, []);

    function safeOpenStoreSearch() {
        if (storeData) {
            analyticsCustomEvent(eventMetricsMap.home, {
                click_text: 'change location',
                element_location: 'community tile',
            });
            optimoveEvents(eventMetricsMap.scm_home, {
                click_text: 'change location',
                element_location: 'community tile',
            });
            openStoreSearch();
        }
    }

    function safeOpenStoreDetails() {
        if (storeData) {
            analyticsCustomEvent(eventMetricsMap.home, {
                click_text: 'store details',
                element_location: 'community tile',
            });
            optimoveEvents(eventMetricsMap.scm_home, {
                click_text: 'store details',
                element_location: 'community tile',
            });
            // openInNewTab(storeDetailsUrl(storeData, customEvent));
            setStoreDetailsModalOpen(true);
        }
    }

    const convertToInternationalCurrencySystem = (num: number) =>
        Math.abs(Number(num)) >= 1.0e9
            ? `${Math.round(Math.abs(Number(num)) / 1.0e9)}B`
            : Math.abs(Number(num)) >= 1.0e6
            ? `${Math.round(Math.abs(Number(num)) / 1.0e6)}M`
            : Math.abs(Number(num)) >= 1.0e3
            ? `${Math.round(Math.abs(Number(num)) / 1.0e3)}k`
            : Math.round(Math.abs(Number(num)));

    const handleLearnMore = () => {
        customEvent(eventMetricsMap.recycle_comm_learnmore);
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: 'learn more',
            element_location: 'community tile',
        });
        optimoveEvents(eventMetricsMap.scm_home, {
            click_text: 'learn more',
            element_location: 'community tile',
        });
        // window.open('https://www.staples.com/recycling', '_blank');
        history.push(ROUTES.stores);
    };

    const { data: response } = useQuery(
        ['recycle-data', userInfo?.preferredStoreId],
        () =>
            axiosInstance.get(
                `${process.env.REACT_APP_STORE}`.replace(
                    '%storeIdentifier%',
                    `${userInfo?.preferredStoreId}`
                )
            ),
        {
            enabled: !!userInfo?.preferredStoreId,
            keepPreviousData: true,
        }
    );
    const recycleData = response?.data?.recycling;

    useEffect(() => {
        if (storeData) {
            if (storeData?.storeHours) {
                const { open, closingTime, nextDayOpenTime } =
                    describeStoreHours(
                        storeData?.storeHours,
                        storeData?.timezone
                    );
                if (closingTime === 'Closed') {
                    setStoreOpenMessage(`Closed now`);
                } else if (open) {
                    setStoreOpenMessage(
                        `Open until ${closingTime.toUpperCase()}`
                    );
                } else if (nextDayOpenTime === 'Closed') {
                    setStoreOpenMessage(`Closed now`);
                } else {
                    setStoreOpenMessage(
                        `Opens at ${nextDayOpenTime.toUpperCase()}`
                    );
                }
            } else {
                setStoreOpenMessage('Temporarily Closed');
            }
        }
    }, [storeData]);

    useEffect(() => {
        if (error) {
            Log.log('error fetching stores');
            Log.log(error);
        } else if (storeData) {
            if (storeData?.address?.city) {
                setStoreName(
                    `${storeData?.address?.city}, ${storeData?.address?.region}`
                );
            }
        }
    }, [storeData, error]);
    function updateLocation() {
        openStoreSearch();
    }

    const getTheStoreName = (name: string) => {
        let storeNameDis = '';
        switch (name) {
            case 'Print and Marketing Services':
                storeNameDis = 'Print Services';
                break;
            case 'Recycling Services':
                storeNameDis = 'Recycling Services';
                break;
            case 'UPS Shipping & Drop-off':
                storeNameDis = 'UPS Shipping';
                break;
            case 'TSA Precheck':
                storeNameDis = 'TSA Precheck';
                break;
            case 'Passport Photos':
                storeNameDis = 'Passport Photos';
                break;
            case 'Amazon Returns':
                storeNameDis = 'Amazon Returns';
                break;
            case 'Amazon Lockers':
                storeNameDis = 'Amazon Lockers';
                break;
            case 'iPostal1':
                storeNameDis = 'iPostal1';
                break;
            case 'Tech Services':
                storeNameDis = 'Tech Services';
                break;
            case 'ClipDrop':
                storeNameDis = 'ClipDrop';
                break;
            case 'Coworking':
                storeNameDis = 'Coworking';
                break;
            default:
                storeNameDis = name;
                break;
        }
        return storeNameDis;
    };
    const getTheStoreNameByPriority = (name: string) => {
        let storePriorityDis = 0;
        switch (name) {
            case 'Print and Marketing Services':
                storePriorityDis = 1;
                break;
            case 'Recycling Services':
                storePriorityDis = 2;
                break;
            case 'UPS Shipping & Drop-off':
                storePriorityDis = 3;
                break;
            case 'TSA Precheck':
                storePriorityDis = 4;
                break;
            case 'Passport Photos':
                storePriorityDis = 5;
                break;
            case 'Amazon Returns':
                storePriorityDis = 6;
                break;
            case 'Amazon Lockers':
                storePriorityDis = 7;
                break;
            case 'iPostal1':
                storePriorityDis = 8;
                break;
            case 'Tech Services':
                storePriorityDis = 9;
                break;
            case 'ClipDrop':
                storePriorityDis = 10;
                break;
            case 'Coworking':
                storePriorityDis = 11;
                break;
            default:
                storePriorityDis = 12;
                break;
        }
        return storePriorityDis;
    };
    const getWidthByName = (storeServiceInfoName: string) => {
        let width = 'auto';
        if (storeServiceInfoName === 'TSA Precheck') {
            width = '75px';
        }
        return width;
    };

    const getStoreServices = () => {
        if (!storeData?.storeServices) return null;
        const filterdStoreServices: any = storeData?.storeServices
            .reduce((acc: any, data: any) => {
                if (storeServicesAllowToDis?.includes(data?.name)) {
                    const priority = getTheStoreNameByPriority(data?.name);
                    acc.push({ data, priority });
                }
                return acc;
            }, [])
            ?.sort((a: any, b: any) => a.priority - b.priority);
        console.log({ filterdStoreServices });
        return (
            <ScrollableRow
                id="store-info-scrollable-row"
                scrollProps={scrollProps}
                showShadow={false}
            >
                {filterdStoreServices?.map((storeServiceInfo: any) => {
                    console.log({
                        dataABC: storeServiceInfo?.data?.serviceIcon,
                        storeServiceInfo,
                    });
                    return (
                        <ScrollableCard
                            className={classes.serviceIfoMainContainer}
                            id="storeInfoContainerId"
                            noPadding
                        >
                            <CardContent
                                key={storeServiceInfo?.data?.id}
                                className={classes.serviceContainer}
                            >
                                <img
                                    src={
                                        storeServiceInfo?.data?.serviceIcon ||
                                        require('../../assets/icons/iconServicesPlaceHolder.svg')
                                    }
                                    alt="img"
                                    className={classes.serviceImageInfo}
                                />
                                <Typography
                                    className={classes.servicetextInfo}
                                    style={{
                                        width: getWidthByName(
                                            storeServiceInfo?.data?.name
                                        ),
                                    }}
                                >
                                    {getTheStoreName(
                                        storeServiceInfo?.data?.name
                                    )}
                                </Typography>
                            </CardContent>
                        </ScrollableCard>
                    );
                })}
            </ScrollableRow>
        );
    };

    const handleExpandCollapse = () => {
        if (sessionStorage.getItem('communityCard') === 'true') {
            sessionStorage.setItem('communityCard', '');
        } else {
            sessionStorage.setItem('communityCard', 'true');
        }
        setCollapseExpand(!collapseExpand);
        openStoreSearch();
    };
    const getTodayDay = () => {
        switch (moment().day()) {
            case 0:
                return 'Sunday';
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            default:
                return '';
        }
    };
    const navigateToMAp = () => {
        const storeUrl = `https://maps.google.com?q=${storeData?.address?.latitude},${storeData?.address?.longitude}`;
        openInNewTab(storeUrl);
    };

    const handleCtw = () => {
        openInNewTab('https://sameday.staples.com/');
        setShowShoppingDrawer({ shoppingList: false, conformationTab: false });
    };

    if (storeFindErrorMessage === 'Processing Error') {
        return (
            <FullWidthCard
                cardContainerClassName={classes.container}
                noPadding
                hasManualShadow
                id="community-card"
            >
                <div className={classes.noStoresContainer}>
                    <span className={classes.noStoresIconContainer}>
                        <img
                            src={require('../../assets/icons/iconNoStore.svg')}
                            alt="no stores icon"
                        />
                    </span>

                    <Typography
                        className={classes.noLocationTitle}
                        id="community-card-no-location-title"
                    >
                        We're sorry, but we could not find your location.
                    </Typography>
                    <Typography
                        id="community-card-update-location-button"
                        className={classes.updateLocation}
                        onClick={updateLocation}
                    >
                        Update Location
                    </Typography>
                </div>
                <HomeScreenInfo />
            </FullWidthCard>
        );
    }

    if (!storeData && !isFetching) {
        return (
            <FullWidthCard
                cardContainerClassName={classes.container}
                noPadding
                hasManualShadow
                id="community-card"
            >
                <Box className={classes.storeNoLocation}>
                    <img
                        src={require('../../assets/icons/iconLocation.svg')}
                        alt="location icon"
                    />
                    <Typography
                        className={classes.storeLocationText}
                        id="community-card-store-location-text"
                        onClick={updateLocation}
                        style={{ color: '#086DD2', cursor: 'pointer' }}
                    >
                        Select your location
                    </Typography>
                </Box>
            </FullWidthCard>
        );
    }
    const handleShoppingToggle = (shoppingData: any) => {
        localStorage.removeItem('newUser');
        setShoppingListCount(shoppingTypes);
        setShoppingType(shoppingData);
        updateGenericLink(shoppingData?.type);
        localStorage.setItem('shoppingType', JSON.stringify(shoppingData));
        if (shoppingData?.type === 'Same-day delivery') {
            setShowShoppingDrawer({
                shoppingList: false,
                conformationTab: true,
            });
        } else if (shoppingData?.type === 'Shopping online') {
            setShowShoppingDrawer({
                shoppingList: false,
                conformationTab: false,
            });
        } else {
            setShowShoppingDrawer({
                shoppingList: false,
                conformationTab: false,
            });
        }

        analyticsCustomEvent(eventMetricsMap.shopping_location_changed, {
            click_text: shoppingData?.type,
        });
    };
    return (
        <FullWidthCard
            cardContainerClassName={classes.container}
            loading={!storeName && (isFetching || !storeData)}
            noPadding
            hasManualShadow
            id="community-card"
        >
            <div className={classes.storeInfoContainer}>
                {/* <Box
                    className={classes.storeLocation}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowShoppingDrawer({
                            ...showShoppingDrawer,
                            shoppingList: true,
                        });
                    }}
                >
                    <img
                        src={require('../../assets/icons/shopping-type.svg')}
                        alt="location icon"
                    />
                    <Typography
                        className={classes.storeLocationText}
                        id="community-card-shop-option"
                    >
                        {shoppingType.type}
                    </Typography>
                    <img
                        src={require('../../assets/icons/iconExpand.svg')}
                        alt="icon collapse and expand"
                    />
                </Box> */}
                <Box
                    className={classes.storeLocation}
                    onClick={handleExpandCollapse}
                >
                    <img
                        src={require('../../assets/icons/iconLocation.svg')}
                        alt="location icon"
                    />
                    <Typography
                        className={classes.storeLocationText}
                        id="community-card-store-location-text"
                        aria-label={`Your Staples store is ${storeName}`}
                        tabIndex={2}
                    >
                        {storeName}
                    </Typography>
                </Box>
                <Box
                    className={classes.storeLocation}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowShoppingDrawer({
                            ...showShoppingDrawer,
                            shoppingList: true,
                        });
                    }}
                >
                    <Typography
                        className={classes.accordianTitle}
                        aria-label={storeOpenMessage}
                    >
                        <span
                            className={classes.openSign}
                            style={{
                                background: storeOpenMessage?.includes(
                                    'Opens at'
                                )
                                    ? '#CC0000'
                                    : '#5AE676',
                            }}
                        />
                        {storeOpenMessage}
                    </Typography>
                    <img
                        src={require('../../assets/icons/iconExpand.svg')}
                        alt="Expand store hours"
                        role="Button"
                        aria-label="Expand store hours"
                        style={{ transform: 'rotate(180deg)' }}
                    />
                </Box>
            </div>

            <SwipeableDrawer
                anchor="bottom"
                open={
                    showShoppingDrawer.shoppingList ||
                    showShoppingDrawer.conformationTab
                }
                classes={{
                    paper: classes.paperDrawer,
                }}
                onBackdropClick={() =>
                    setShowShoppingDrawer({
                        shoppingList: false,
                        conformationTab: false,
                    })
                }
                onClose={() => {
                    localStorage.removeItem('newUser');
                    setShowShoppingDrawer({
                        shoppingList: false,
                        conformationTab: false,
                    });
                }}
                onOpen={() => {
                    newUser === 'true'
                        ? true
                        : showShoppingDrawer.shoppingList ||
                          showShoppingDrawer.conformationTab;
                }}
                style={{ borderRadius: ' 15px 15px 0px 0px', margin: '0 1rem' }}
            >
                <div style={{ alignSelf: 'center' }}>
                    <img
                        src={require('../../assets/icons/shoppingDrawerLine.svg')}
                        alt="shoppingDrawerLine"
                    />
                </div>
                {showShoppingDrawer.shoppingList ? (
                    <Grid
                        container
                        direction="column"
                        // spacing={2}
                        className={classes.accordionDetailsContainer}
                    >
                        <Grid item>
                            <Typography className={classes.accordianTitle}>
                                <span
                                    className={classes.openSign}
                                    style={{
                                        background: storeOpenMessage?.includes(
                                            'Opens at'
                                        )
                                            ? '#CC0000'
                                            : '#5AE676',
                                    }}
                                />
                                {storeOpenMessage}
                            </Typography>
                        </Grid>
                        <Grid item style={{ padding: '5px 16px 16px' }}>
                            <Grid container direction="column">
                                {storeData?.storeHours &&
                                    Object.keys(storeData?.storeHours)?.map(
                                        (key) => {
                                            const data: StoreHourDetail =
                                                storeData?.storeHours?.[
                                                    key as keyof StoreHours
                                                ] as StoreHourDetail;
                                            if (key === 'weekday') return '';
                                            return (
                                                <Grid item key={key}>
                                                    <Grid
                                                        container
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Body
                                                            weight={
                                                                getTodayDay() ===
                                                                data?.day
                                                                    ? 'bold'
                                                                    : 'light'
                                                            }
                                                        >
                                                            {data?.day}
                                                        </Body>
                                                        <Body
                                                            weight={
                                                                getTodayDay() ===
                                                                data?.day
                                                                    ? 'bold'
                                                                    : 'light'
                                                            }
                                                        >
                                                            {data?.open} -{' '}
                                                            {data?.close}
                                                        </Body>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            onClick={navigateToMAp}
                            style={{
                                marginBottom: '10px',
                            }}
                        >
                            <InfoIconCard
                                icon={
                                    <img
                                        alt="locationOn"
                                        src={require(`../../assets/icons/locationOn.svg`)}
                                    />
                                }
                            >
                                <Typography className={classes.address}>
                                    {`${storeData?.address.address_1},`}
                                    {storeData?.address?.address_2.trim() ? (
                                        <>{storeData?.address.address_2}, </>
                                    ) : (
                                        ''
                                    )}
                                    <br />
                                    {`${storeData?.address?.city}, ${storeData?.address.region} ${storeData?.address.postal_code}`}
                                </Typography>
                            </InfoIconCard>
                        </Grid>
                        <Grid item>
                            <InfoIconCard
                                icon={
                                    <img
                                        alt="localPhone"
                                        src={require(`../../assets/icons/localPhone.svg`)}
                                    />
                                }
                            >
                                <AppLink
                                    className={classes.phone}
                                    id="phone"
                                    href={`tel:${storeData?.phoneNumber}`}
                                >
                                    {formatPhoneNumber(storeData?.phoneNumber)}
                                </AppLink>
                            </InfoIconCard>
                        </Grid>
                        <Grid item className={classes.storeDetailsLocation}>
                            <Box className={classes.ctas}>
                                <Typography
                                    id="community-card-change-store-button"
                                    className={classes.ctaText}
                                    onClick={safeOpenStoreSearch}
                                >
                                    Change location
                                </Typography>
                                <Typography
                                    id="community-card-store-details-button"
                                    className={classes.ctaText}
                                    onClick={safeOpenStoreDetails}
                                >
                                    Store details
                                </Typography>
                            </Box>
                        </Grid>
                        {/* <Grid item>
                         <Grid container className={classes.infoContainer}>
                             <div
                                 className={clsx(
                                     classes.infoGrid,
                                     classes.infoIcon
                                 )}
                             >
                                 <img
                                     alt="localPhone"
                                     src={require(`../../assets/icons/iconRecycleHomescreenGreen.svg`)}
                                 />
                             </div>
                             <div
                                 className={clsx(
                                     classes.infoGrid,
                                     classes.infoDetails
                                 )}
                             >
                                 <div className={classes.content}>
                                     Since 2021, this community kept{' '}
                                     <strong>
                                         {convertToInternationalCurrencySystem(
                                             recycleData?.storeContribution
                                         )}{' '}
                                         tons{' '}
                                     </strong>
                                     of greenhouse gas out of Earth's
                                     atmosphere.{' '}
                                     <ButtonBase
                                         onClick={handleLearnMore}
                                         className={classes.learnMore}
                                         id="learnMore"
                                     >
                                         Learn more
                                     </ButtonBase>
                                 </div>
                             </div>
                         </Grid>
                     </Grid> */}
                    </Grid>
                ) : null}
                {/* {showShoppingDrawer.shoppingList ? (
                    <div>
                        <Typography className={classes.drawerTitle}>
                            Select your shopping preference
                        </Typography>
                        {shoppingListCount.map((type: any) => (
                            <Box
                                onClick={() => {
                                    handleShoppingToggle(type);
                                }}
                                className={classes.shoppingTypeContainer}
                            >
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                    <img src={type.image} alt="location icon" />
                                </div>
                                <div style={{ flex: 2 }}>
                                    <Typography
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                        }}
                                        // className={classes.storeLocationText}
                                        id={type.id}
                                    >
                                        {type.type === 'Shopping in-store' &&
                                            'In store'}
                                        {type.type === 'Shopping online' &&
                                            'Online'}
                                        {type.type === 'Print Shop' &&
                                            'Print Shop'}
                                    </Typography>
                                    <Typography>{type.description}</Typography>
                                </div>
                            </Box>
                        ))}
                    </div>
                ) : null} */}
                {showShoppingDrawer?.conformationTab ? (
                    <div className={classes.exitContainer}>
                        <Typography className={classes.ctwText}>
                            Continue to website
                        </Typography>
                        <Typography className={classes.tacoext}>
                            This action is continued out of the Staples app.
                        </Typography>
                        <ButtonBase
                            className={classes.btnCta}
                            onClick={handleCtw}
                        >
                            <span>Continue to website</span>
                        </ButtonBase>
                    </div>
                ) : null}
            </SwipeableDrawer>
            {storeDetailsModalOpen ? (
                <StoreDetailsDrawer
                    mainStore={mainStore}
                    nearbyStores={additionalStores}
                    onStoreSelected={onStoreSelected}
                    open={storeDetailsModalOpen}
                    ignoreIsActiveScreen
                    handleClose={() => setStoreDetailsModalOpen(false)}
                />
            ) : null}
        </FullWidthCard>
    );
}
