/* eslint-disable max-len */
import { getShortDynamicLink } from 'src/lib/utils/dynamic-link-utils';
import { listCreateSharedURL, listGetSharedURL } from 'src/services/urls';
import axiosInstance from '../api-client/custom-axios';

/* eslint-disable import/prefer-default-export */
export const makeListQuery = () => ({
    importListStep: 'selectSchool',
});

/* eslint-disable import/prefer-default-export */
type ListWithId = {
    id?: string;
}[];

export function compareFields<T>(objA: T, objB: T, fields: string[]): boolean {
    if (!objA && !objB) return true;
    if (!objA || !objB) return false;
    // eslint-disable-next-line eqeqeq
    const mismatch = fields.find((f) => (objA as any)[f] != (objB as any)[f]);
    return mismatch !== undefined;
}

export function listsIsEqual<T>(
    a: T[],
    b: T[],
    comparisonFn: (aElem: T, index: any) => boolean
): boolean {
    if (a.length !== b.length) return false;
    const misMatch = a.find(comparisonFn);

    // return true if we did not find a mismatch
    return misMatch === undefined;
}

/** "equal" defined as same arrays with the same object order  */
export const listsWithIdsIsEqual = (a: ListWithId, b: ListWithId): boolean =>
    listsIsEqual(a, b, (aElem, index) => aElem.id !== b[index].id);

export function uniques<T>(
    list: T[],
    elementIsUnique: (prevUnique: T[], elem: T) => boolean
): T[] {
    return list.reduce((agg: T[], elem: T) => {
        if (elementIsUnique(agg, elem)) {
            return [...agg, elem];
        }
        return agg;
    }, [] as T[]);
}

export function countUniques<T>(
    list?: T[],
    elementIsUnique?: (prevUnique: T[], elem: T) => boolean
): number {
    if (!list || !elementIsUnique) {
        return 0;
    }
    return uniques(list, elementIsUnique).length;
}

export function removeDuplicates<T>(list: T[], field: string): T[] {
    const seen = new Set();
    const filteredArr = list.filter((el) => {
        const duplicate = seen.has((el as any)[field]);
        const nullOrUndefined =
            (el as any)[field] === null || (el as any)[field] === undefined;
        seen.add((el as any)[field]);
        return !duplicate && !nullOrUndefined;
    });

    return filteredArr;
}

// calculate the middle distance between two numbers
function middleDistance(a: number, b: number) {
    return (a + b) / 2;
}

export function newSequenceValue(
    items: { sequence: number }[],
    destinationIndex: number
) {
    if (!items) {
        // eslint-disable-next-line no-console
        console.error('invalid item array (array null or undefined');
        return 0;
    }
    if (items.length < destinationIndex) {
        // eslint-disable-next-line no-console
        console.error('invalid item array or index: ', {
            itemLength: items.length,
            destinationIndex,
        });
        return 0;
    }

    if (items.length === 0 || items.length === 1) {
        return 0;
    }

    if (destinationIndex === 0) {
        const nextItem = items[destinationIndex + 1];
        // top of the list = last value -5.
        return nextItem.sequence - 5;
    }
    const previousItem = items[destinationIndex - 1];
    if (destinationIndex === items.length - 1) {
        // bottom of the list = last value +5.
        return previousItem.sequence + 5;
    }
    const nextItem = items[destinationIndex + 1];
    // halfway point between the last and next item.
    // (the existing item is pushed down, not up)
    return middleDistance(previousItem.sequence, nextItem.sequence);
}

export const formatURL = (id: number, shareBy: string) => {
    const ibi = process.env.REACT_APP_SHARED_LINK_IBI;
    const link = process.env.REACT_APP_SHARED_LINK_BASE?.replace(
        '%listId%',
        `${id}`
    );
    const st = `${shareBy} from StaplesConnect`;
    // Image to show in the shared url preview
    const imgPath =
        'https://dev.staplesconnect.com/images/test/cfma/qa/share-list-preview-dynlinks.png';
    const url = `${process.env.REACT_APP_DEEPLINK_DOMAIN}/?ibi=${ibi}&st=${st}&si=${imgPath}&isi=${process.env.REACT_APP_IOS_APP_STORE_ID}&link=${link}`;
    return getShortDynamicLink(url);
};

export const buildLink = async (listId: number, imageUrl?: string) => {
    const createShareListResult = await axiosInstance.post(
        `${listCreateSharedURL}`,
        { ProductListId: listId, SharedImageUrl: imageUrl }
    );
    if (createShareListResult.data && createShareListResult.data.sharedListId) {
        const shareListId = createShareListResult.data.sharedListId;
        const shareListResult = await axiosInstance.get(
            `${listGetSharedURL}`.replace('%listId%', `${shareListId}`)
        );
        if (shareListResult.data) {
            const shareBy = shareListResult.data.sharedBy;
            const shortUrl = await formatURL(shareListId, shareBy);
            if (shortUrl) {
                return shortUrl;
            }
            throw new Error('Could not generate short link');
        }
    }
    throw new Error('No link found');
};
