/* eslint-disable no-console */

import useLocalStorage, { LOCAL_STORAGE_KEYS } from 'src/hooks/useLocalStorage';

/** Used only for data that we can't update and store via the API */
type LocalUserInfo = {
    /** true if the local user has finished onboarding */
    hasFinishedOnboarding?: boolean;
    /** needed for nearby searches (if preferred store is not found) */
    zipCode?: string;
    /** preferred store if manually set */
    preferredStoreId?: string;
    /** local copy of the last time we checked for rewards activity */
    lastAccessDate?: string;
    /** used to determine if we're still the same user */
    email?: string;
    /** needed if user has accepted TOS */
    acceptedTOS?: boolean;
    /** set to true if the user has viewed the tooltip for the first time */
    userViewedItemListToolTip?: boolean;
};

/**
 * Get and set any local user info we're storing about the user.
 * This should be used only for fields and values that
 * are not related to user data that we store via the middleware.
 * This state is cleared on logout.
 * (or whenever localstorage is invalidated,
 * for safari iOS this is after one week of not visiting the site)
 */
export default function useLocalUserInfo() {
    const [userInfo, setUserInfo] = useLocalStorage(
        LOCAL_STORAGE_KEYS.USER_INFO,
        {} as LocalUserInfo
    );
    function patchLocalUserInfo(partialUserInfo: Partial<LocalUserInfo>) {
        setUserInfo({ ...userInfo, ...partialUserInfo });
    }
    return { userInfo, setUserInfo, patchLocalUserInfo };
}
