/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';
import { useQuery } from 'react-query';
import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import useUserInfo from 'src/hooks/useUserInfo';
import axiosInstance from 'src/lib/api-client/custom-axios';
import COLORS from 'src/lib/colors';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import FullWidthCard from './cards/full-width-card';
import Carousel from './Carousel';
import Shape from '../assets/icons/Shape.svg';

const useStyles = makeStyles(() => ({
    addInfoContainer: {
        color: COLORS.primaryWhite,
        backgroundColor: '#81C671',
        marginTop: '0 !important',
        boxShadow: 'none !important',
        marginLeft: '0px',
        marginRight: 0,
        backgroundImage: `url(${Shape})`,
    },
    textcontainer: {
        display: 'inline-flex',
        flexDirection: 'column',
    },
    wrapper: {
        display: 'flex',
        columnGap: '1rem',
        padding: '1rem 1.5rem',
    },
    img: {
        marginTop: '7px',
    },
    addInfoWrapper: {
        backgroundColor: '#81C671',
        backgroundImage: `url(${Shape})`,
        boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    learnMore: {
        color: COLORS.primaryWhite,
        textDecoration: 'underline',
        marginBottom: '3px',
    },
}));

function HomeScreenInfo() {
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { history } = useRouter();
    const { data: response } = useQuery(
        ['recycle-data', userInfo?.preferredStoreId],
        () =>
            axiosInstance.get(
                `${process.env.REACT_APP_STORE}`.replace(
                    '%storeIdentifier%',
                    `${userInfo?.preferredStoreId}`
                )
            ),
        {
            enabled: !!userInfo?.preferredStoreId,
            keepPreviousData: true,
        }
    );
    const recycleData = response?.data?.recycling;

    const convertToInternationalCurrencySystem = (num: number) => {
        return Math.abs(Number(num)) >= 1.0e9
            ? Math.round(Math.abs(Number(num)) / 1.0e9) + 'B'
            : Math.abs(Number(num)) >= 1.0e6
            ? Math.round(Math.abs(Number(num)) / 1.0e6) + 'M'
            : Math.abs(Number(num)) >= 1.0e3
            ? Math.round(Math.abs(Number(num)) / 1.0e3) + 'k'
            : Math.round(Math.abs(Number(num)));
    };
    const handleLearnMore = () => {
        customEvent(eventMetricsMap.recycle_comm_learnmore);
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: 'learn more',
            element_location: 'community tile',
        });
        optimoveEvents(eventMetricsMap.scm_home, {
            click_text: 'learn more',
            element_location: 'community tile',
        });
        // window.open('https://www.staples.com/recycling', '_blank');
        history.push(ROUTES.stores);
    };

    const infos = [
        {
            name: 'info1',
            imageURL: require('../assets/icons/iconRecycleHomescreen.svg'),
            value: convertToInternationalCurrencySystem(
                recycleData?.storeContribution
            ),
        },
        {
            name: 'info2',
            imageURL: require('../assets/icons/iconRecycleHomescreen.svg'),
            value: convertToInternationalCurrencySystem(
                recycleData?.allStoresContribution
            ),
        },
    ];
    return (
        <>
            {infos && infos.length ? (
                <Box>
                    <div className={classes.addInfoWrapper}>
                        <Carousel
                            isAutoPlay
                            activeColor={COLORS.whiteSmoke}
                            content={infos?.map(
                                ({ name, value, imageURL }: any) => (
                                    <FullWidthCard
                                        hasManualShadow={false}
                                        cardContainerClassName={
                                            classes.addInfoContainer
                                        }
                                        noPadding
                                        id="home-screen-info-container"
                                    >
                                        <div className={classes.wrapper}>
                                            <div className={classes.img}>
                                                <img
                                                    alt={name}
                                                    src={imageURL}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes.textcontainer
                                                }
                                            >
                                                <div>
                                                    {`Since 2021, ${
                                                        name === 'info1'
                                                            ? 'this community'
                                                            : 'Staples Stores'
                                                    } kept `}
                                                    <strong>
                                                        {value} tons{' '}
                                                    </strong>{' '}
                                                    of greenhouse gas out of
                                                    Earth's atmosphere. &nbsp;{' '}
                                                    <ButtonBase
                                                        onClick={
                                                            handleLearnMore
                                                        }
                                                        className={
                                                            classes.learnMore
                                                        }
                                                        id="learnMore"
                                                    >
                                                        Learn more
                                                    </ButtonBase>
                                                </div>
                                            </div>
                                        </div>
                                    </FullWidthCard>
                                )
                            )}
                            dots={infos.length}
                            styles={{
                                containerStyle: {
                                    width: '100%',
                                },
                            }}
                            dotStyle={{
                                height: '1.5rem',
                                paddingBottom: '1rem',
                            }}
                        />
                    </div>
                </Box>
            ) : null}
        </>
    );
}

export default HomeScreenInfo;
