/* eslint-disable import/prefer-default-export */
export function storeToLocalStorage(key: string, query: string) {
    const recentlySearchedQueryArray = JSON.parse(
        localStorage.getItem(`${key}`) || '[]'
    );
    const recentlySearchedQuery = {
        term: query,
    };
    recentlySearchedQueryArray.unshift(recentlySearchedQuery);
    window.localStorage.setItem(
        `${key}`,
        JSON.stringify(recentlySearchedQueryArray)
    );
}
