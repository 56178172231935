import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useStyles = makeStyles({
    rewardCardSub: {
        padding: '20px 7px',
    },
    subBoxText: {
        textAlign: 'center',
        fontSize: '0.97rem',
        lineHeight: '1.2rem',
    },
    newMemberBoxTitle: {
        color: COLORS.textBlack,
        fontWeight: 'bold',
        fontSize: '1rem',
        textAlign: 'center',
        marginBottom: '10px',
    },
});
export default useStyles;
