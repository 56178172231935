import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog } from '@material-ui/core';
import YourPointsScreen from 'src/components/Coachmarks/YourPointsScreen/YourPoints';
import EarningAndSaving from 'src/components/Coachmarks/EarningAndSaving/EarningAndSaving';
import Membership from 'src/components/Coachmarks/Membership/Membership';
import CategoriesScreen from 'src/components/Coachmarks/CategoriesScreen/CategoriesScreen';
import YourOfferScreen from 'src/components/Coachmarks/YourOfferScreen/YourOfferScreen';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import LetsStartScreen from 'src/components/Coachmarks/LetsStartScreen';
import LetsGoScreen from 'src/components/Coachmarks/LetsGoScreen';
import styles from './styles.module.scss';


function Coachmarks({
    coachmarksData,
    setCoachmarksData,
    featuredOffers = null,
}: any) {
    const { analyticsCustomEvent } = useEventsReporter();
    const [flagForFavoriteList, setFlagForFavoriteList] = useState(true);

    const handleSkipAll = () => {
        const modifyData = { ...coachmarksData, completed: true }
        setCoachmarksData(modifyData);
        localStorage.setItem('coachmarks', JSON.stringify(modifyData))
    }
    const handleNext = (screenNumber: number) => {
        const modifyData = { ...coachmarksData, screen: screenNumber }
        setCoachmarksData(modifyData);
        localStorage.setItem('coachmarks', JSON.stringify(modifyData))
    }
    const rendorCoachScreen = () => {
        switch (coachmarksData?.screen) {
            case 0:
                return <LetsStartScreen handleNext={handleNext} />;
            case 1:
                return <YourPointsScreen handleNext={handleNext} handleSkipAll={handleSkipAll} />;
            case 2:
                return <EarningAndSaving handleNext={handleNext} handleSkipAll={handleSkipAll} />;
            case 3:
                return <Membership handleNext={handleNext} handleSkipAll={handleSkipAll} />;
            case 4:
                return <CategoriesScreen handleNext={handleNext} handleSkipAll={handleSkipAll}
                    flagForFavoriteList={flagForFavoriteList}
                    setFlagForFavoriteList={setFlagForFavoriteList}
                />
            case 5:
                return <YourOfferScreen handleNext={handleNext} handleSkipAll={handleSkipAll}
                    featuredOffers={featuredOffers?.offers?.[0]} />
            case 6:
                return <LetsGoScreen handleSkipAll={handleSkipAll} />
            default:
                return null;
        }
    }

    useEffect(() => {
        if (coachmarksData?.screen === 0) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks intro`,
                firebase_screen_class: `app_coachmarks intro`,
            });
        } else if (coachmarksData?.screen === 1) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks your points`,
                firebase_screen_class: `app_coachmarks your points`,
            });
        } else if (coachmarksData?.screen === 2) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks earn points`,
                firebase_screen_class: `app_coachmarks earn points`,
            });
        } else if (coachmarksData?.screen === 3) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks membership number`,
                firebase_screen_class: `app_coachmarks membership number`,
            });
        } else if (coachmarksData?.screen === 5) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks offers`,
                firebase_screen_class: `app_coachmarks offers`,
            });
        } else if (coachmarksData?.screen === 6) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `app_coachmarks let's go`,
                firebase_screen_class: `app_coachmarks let's go`,
            });
        }
    }, [coachmarksData?.screen])
    return (
        <Dialog
            open
            aria-labelledby="categories-dialog-title"
            classes={{
                paper: styles.paper,
                paperScrollPaper: coachmarksData?.screen === 4 &&
                    !flagForFavoriteList
                    ? styles.paperScrollPaper
                    : styles.paperScrollPaperWithMoreGap,
                scrollPaper: coachmarksData?.screen === 4 ? styles.scrollPaperBpc :
                    styles.scrollPaper,
            }}
        >
            {rendorCoachScreen()}
        </Dialog>
    );
}

export default Coachmarks;
