/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import {
    Box,
    Button,
    ButtonBase,
    Typography,
    makeStyles,
    Container,
} from '@material-ui/core';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import useRouter from 'src/hooks/useRouter';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ROUTES from 'src/lib/routes';

const useStyles = makeStyles({
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: COLORS.whiteSmoke,
        margin: 'auto',
    },
    container: {
        minHeight: 'calc(100vh - 155px)',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '40px',
    },
    textContainer: {
        gap: '15px',
    },
    message: {
        fontSize: '18px',
    },
    button: {
        borderRadius: '25px',
        height: '42px',
        color: 'white',
        fontWeight: 700,
        boxShadow: 'none',
        padding: '0 28px',
        fontSize: '16px',
        backgroundColor: COLORS.brandRed,
    },
    headerLogo: {
        margin: '0 auto',
    },
});

function EmptyCart() {
    const classes = useStyles();
    const { history, push } = useRouter();

    const handleClick = () => {
        history.goBack();
    };
    return (
        <Box>
            <NavTop
                bgColor={COLORS.brandRed}
                bgImageSrc={require('../../assets/bgs/header-bg.svg')}
                noBorderRadius
                minHeight="0"
            >
                <Box className={classes.cartHeader}>
                    <ButtonBase onClick={() => handleClick()} aria-label='Go Back'>
                        <ArrowBackIosRoundedIcon className={classes.backIcon} />
                    </ButtonBase>
                    {/* <Typography className={classes.title}>Cart</Typography> */}
                    <img
                        className={classes.headerLogo}
                        src={require('../../assets/icons/iconStaplesLogo.svg')}
                        alt="staples connect logo"
                        id="staples-connect-logo"
                    />
                </Box>
            </NavTop>
            <Box display="flex" className={classes.container}>
                <Box>
                    <img
                        alt="empty-cart"
                        src={require('../../assets/icons/emptyCart.svg')}
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    className={classes.textContainer}
                >
                    <Typography variant="subtitle1" className={classes.message}>
                        {' '}
                        Your cart is empty
                    </Typography>
                    <Button
                        variant="contained"
                        className={classes.button}
                        style={{ background: '#CC0000' }}
                        onClick={() => push(ROUTES.shop)}
                    >
                        Continue Shopping
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default () => <EmptyCart />;
