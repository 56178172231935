/* eslint-disable import/prefer-default-export */
import { Position } from 'src/lib/types';
import { DonationType } from 'src/services/types';

export const isDefinedAndNonNull = (u: unknown) =>
    u !== undefined && u !== null;

export function convertGeoPosToPos(from: GeolocationPosition): Position {
    return { latitude: from.coords.latitude, longitude: from.coords.longitude };
}

export const getDonationText = (donationType: DonationType): string => {
    switch (donationType) {
        case 'Debit':
            return 'Donated';
        case 'Earned':
            return 'Earned';
        case 'Expiry':
            return 'Expired';
        // case 'BackendAdjustment':
        //   return 'BackendAdjustment';
        default:
            return 'Received';
    }
};

export const iconforActivity = (donationType: DonationType) => {
    switch (donationType) {
        case 'Debit':
            return require('../../assets/icons/iconClassroomrewardsDonateGreen.svg');
        case 'Expiry':
            return require('../../assets/icons/expiryEvent.svg');
        // case 'BackendAdjustment':
        //   return require('../../assets/icons/ClassroomrewardsDonateGreen.svg';
        case 'Earned':
            return require('../../assets/icons/iconClassroomrewardsGreen.svg');
        default:
            return require('../../assets/icons/iconClassroomrewardsReceivedGray.svg');
    }
};
