
import React, { useEffect, useRef, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { isListEnabled } from 'src/lib/api-client/lists';
import { useQuery } from 'react-query';
import ShippingQuoteEstimator from './shipping-quote-estimator';
import ShippingRateDelivery from './shipping-rate-delivery';


const useStyles = makeStyles((theme) => ({
    QuoteShippingContainer: {
        overflowY: 'hidden',
        height: '100vh',
    },
    QuoteShippingHeader: {
        height: '114px',
        display: 'flex',
        flexDirection: 'column',
        background: '#101820',
        alignItems: 'center',
        gap: '15px',
        justifyContent: 'center',
        position: 'sticky',
        zIndex: 1,
    },
    startCreate: {
        height: '30px',
        width: '166px',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '24px',
        '& span': {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '11.8px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
    },
    QuoteShippingHeaderImgTextAlign: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    labelCreate: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '25.96px',
        color: '#FFFFFF',
        letterSpacing: '-0.47px',
    },
    backIconAlign: {
        position: 'absolute',
        left: '14px',
    },
    QuoteShippingMainSubContainer: {
        height: 'calc(100vh - 114px)',
        overflow: 'auto',
    },
}));

const ShippingQuoteEstimatorContainer = () => {
    const classes = useStyles();
    const { history, pathname, query } = useRouter();
    const [senderInfo, setSenderInfo] = useState({});
    const [recipientInfo, setRecipientInfo] = useState({});
    const [packageInfo, setPackageInfo] = useState({});
    const [shipmentRateInfo, setShipmentRateInfo] = useState({});
    const [scrolledToBottom, setScrolledToBottom] = useState(false);
    const { data: HolidayShippingEnabled } = useQuery(
        ['HolidayShippingEnabled'],
        () => isListEnabled('HolidayShipping'),
        {
            refetchOnMount: true,
        }
    );

    const [initialShipmentValues, setInitialShipmentValues] = useState({
        senderZipCode: "",
        recipientZipCode: "",
        weight: "",
        country: "US",
    })
    const { step } = query;
    console.log({ recipientInfo, senderInfo })
    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleScroll = (e: any) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
            if (bottom) {
                setScrolledToBottom(true);
            } else {
                setScrolledToBottom(false);
            }
        };

        const currentDiv = divRef.current;
        if (currentDiv) {
            currentDiv.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentDiv) {
                currentDiv.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    const getComponentBasedOnRoute = () => {
        switch (step) {
            case 'getEstimator':
                return <ShippingQuoteEstimator
                    setShipmentRateInfo={setShipmentRateInfo}
                    initialShipmentValues={initialShipmentValues}
                    setInitialShipmentValues={setInitialShipmentValues}
                />
            case 'shippingRateDelivery':
                return <ShippingRateDelivery
                    shipmentRateInfo={shipmentRateInfo}
                    initialShipmentValues={initialShipmentValues}
                    bottomShadow={scrolledToBottom}
                    HolidayShippingEnabled={HolidayShippingEnabled}
                />
            default:
                return null;
        }
    }
    const goBack = () => {
        let search;
        switch (step) {
            case 'getEstimator':
                search = "";
                break;
            case 'shippingRateDelivery':
                search = "getEstimator";
                break
            default:
                search = "";
        }
        if (search) {
            history.push({ pathname: ROUTES.getQuoteShippingInformation, search: search ? `step=${search}` : "" });
        } else {
            history.push({ pathname: ROUTES.shippingServices })
        }
    }

    return (
        <div className={classes.QuoteShippingContainer}>
            <div className={classes.QuoteShippingHeader}>
                <div className={classes.QuoteShippingHeaderImgTextAlign}>
                    <ButtonBase className={classes.backIconAlign} onClick={goBack}>
                        <img src={require('../../../assets/icons/iconBackWhite.svg')} alt="iconBackWhite" />
                    </ButtonBase>
                    <Typography className={classes.labelCreate}>
                        Delivery rates and estimates
                    </Typography>
                </div>
            </div>
            <div className={classes.QuoteShippingMainSubContainer} ref={divRef}>
                {getComponentBasedOnRoute()}
            </div>
        </div>
    )

}

export default ShippingQuoteEstimatorContainer;