import React from 'react';
import { ButtonBase, Divider, makeStyles } from '@material-ui/core';
import { AlternativesEntity } from 'src/lib/api-client/adept-mind-types';
import clsx from 'clsx';
import { CommonRowProps } from './types';
import {
  addMultiSelectFilterValueToPendingFilters,
  removeMultiSelectFilterValueFromPendingFilters,
  hasMultiSelectValueInPendingFilters,
} from './filter-utils';

const useMultiSelectFilterRowStyles = makeStyles({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  indented: {
    paddingLeft: '1.5rem',
  },
  title: {
    minHeight: '1.2rem',
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
    flexGrow: 1,
  },
  rowContent: {
    width: '100%',
    display: 'flex',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  icon: {
    marginRight: '1.5rem',
    marginLeft: '1rem',
    marginBottom: '-.5rem',
    marginTop: '-.5rem',
  },
});

// for use in conjunction with expanding-row
export default function MultiSelectFilterRow({
  filter,
  pendingFilters,
  updatePendingFilters,
}: CommonRowProps) {
  const classes = useMultiSelectFilterRowStyles();
  const alternatives = filter.alternatives || [];
  function onClick(a: AlternativesEntity, selected: boolean) {
    if (selected) {
      updatePendingFilters(
        removeMultiSelectFilterValueFromPendingFilters(
          pendingFilters,
          filter,
          a.value.$eq
        )
      );
    } else {
      updatePendingFilters(
        addMultiSelectFilterValueToPendingFilters(
          pendingFilters,
          filter,
          a.value.$eq
        )
      );
    }
  }
  return (
    <div>
      {alternatives.map((a, index) => {
        const val = a?.value?.$eq;
        if (!val) {
          // eslint-disable-next-line no-console
          console.log(
            'val for multiselect filter did not have proper formatting',
            { a, filter }
          );
        }
        const selected = hasMultiSelectValueInPendingFilters(
          pendingFilters,
          filter,
          val
        );
        return (
          <div
            key={`filter-option-${a.text}-${a.value}`}
            className={classes.row}
          >
            <ButtonBase
              id={`filter-option-${index}-button`}
              onClick={() => onClick(a, selected)}
            >
              <div className={classes.rowContent}>
                <div className={clsx(classes.title, classes.indented)}>
                  {a.text}
                </div>

                {selected && (
                  <img
                    className={classes.icon}
                    src={require('../../../assets/icons/iconBlackCheckmark.svg')}
                    alt="selected"
                  />
                )}
              </div>
            </ButtonBase>

            <div className={classes.indented}>
              <Divider />
            </div>
          </div>
        );
      })}
    </div>
  );
}
