import { useFormik } from 'formik';
import * as yup from 'yup';
import { createContext, useState, useContext, useEffect } from 'react';
import useRouter from 'src/hooks/useRouter';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { SearchProductsResult } from 'src/lib/api-client/adept-mind';
import { PendingFilters } from 'src/screens/product-browse/filter/types';
import {
    TermSuggestion,
    SortRequest,
    ProductsEntity,
} from 'src/lib/api-client/adept-mind-types';
import { ListItemType } from 'src/services/types';
import { CategoryRowData } from 'src/screens/product-browse/search-landing/categories-list';
import ROUTES from '../routes';

export const shoppingTypes = [
    {
        type: 'Shopping in-store',
        image: require('../../assets/icons/shopping-in-store.svg'),
        id: 'shopping-in-store',
        description: "Discover products and services at your local Staples.",
    },
    {
        type: 'Shopping online',
        image: require('../../assets/icons/shopping-online.svg'),
        id: 'shopping-online',
        description: "Enjoy free next-day delivery on your orders, no minimum.",
    },
    {
        type: 'Print Shop',
        image: require('../../assets/icons/print-shop.svg'),
        id: 'print-shop',
        description: "Let's create exactly what you need to get the word out.",
    },
]

// using ExpectedQuery only for the initial page display for simplicity
type ExpectedQuery = { productBrowse: any };
export type ProductBrowsePageType =
    | 'search-landing'
    | 'search-results'
    | 'filter'
    | 'sort'
    | 'product-details'
    | 'empty';

/** when an item is selected from product browse or full search */
type ProductDetailsFromSearchResults = { fullProduct: ProductsEntity };
/** when an item is selected from inside product details's related items */
type ProductDetailsFromSimilarProducts = { fullProduct: ProductsEntity };
/** when an item is selected from the list details or edit screens */
export type ProductDetailsFromListPage = {
    itemInfo?: ListItemType;
    listId?: number;
    forceLoad: boolean;
};
/** when an item is selected from the products returned in the autocomplete */
type ProductDetailsFromAutocompleteSearchResults = {
    productId: string;
    forceLoad: boolean;
};
/** PageDetails are unique to the given page and are changed with every goToPage */
export type PageDetails =
    | any // kept this any here for easier destructuring,
    // removing it will require helper functions & duck typing
    | ProductDetailsFromListPage
    | ProductDetailsFromSearchResults
    | ProductDetailsFromSimilarProducts
    | ProductDetailsFromAutocompleteSearchResults
    // used when selecting a category to browse by
    | { category: CategoryRowData }
    // lastResult is needed for the filter and sort screens (from the search results page)
    | { lastResult?: SearchProductsResult };
/** the Intent is meant to be shared from page to page until updated or flow is ended */
export type Intent = {
    type: 'add-to-existing-list' | 'hide-features';
    info?: { listId: any };
};

export type Page = {
    type: ProductBrowsePageType;
    pageDetails?: PageDetails;
    // this intent is the initial value passed to the the page, if any
    // for global intent, use the currentIntent value
    intent?: Intent;
    openReviews?: boolean;
};

type ShoppingType = {
    type: string;
    image: any;
    id: string;
    description: string;
};

type ProductBrowseContextState = {
    // handle our own page history stack
    // currently this is independent of the browser history,
    // but we could rewrite it to handle browser state changes
    fromPath: string;
    setFromPath: (newFromPath: string) => void;
    shoppingType: ShoppingType;
    setShoppingType: (newShoppingType: ShoppingType) => void;

    goToPage: (page: Page) => void;
    goBack: (page?: Page) => void;
    /** idempotent call to set controller to initial search experience */
    begin: () => void;
    /** clears page stack */
    end: () => void;
    setOpenReviews: React.Dispatch<React.SetStateAction<boolean>>;
    pages: Page[];
    currentPage?: Page;
    lastPoppedPage?: Page;
    isBrowsing: boolean;

    /** The current intent of the flow (currently has only one state; list already added) */
    currentIntent?: Intent;
    openReviews: boolean;
    /** for simplicity, search queries are kept separately from page history */
    setSearchString: (newSearch: string) => void;
    searchString: string;
    setFilters: (newFilters: PendingFilters) => void;
    filters: PendingFilters;
    setSorting: (newSorts: any) => void;
    sorts: any;
    setQuickFilters: (newQuickFilters: any[]) => void;
    quickFilters: TermSuggestion[];
    clearAllFilterAndSorting: () => void;
    // internal formik for handling the search string
    formik: any;
    // ReturnType<typeof useFormik> currently does not work due to a typescript error
    setSelectedShoppingType: (data: ShoppingType) => void;
};

/** Handles both browsing and searching */
const ProductBrowseContext = createContext<ProductBrowseContextState>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    goToPage: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    goBack: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    begin: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    end: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOpenReviews: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setFromPath: () => { },
    fromPath: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShoppingType: () => { },
    shoppingType: {
        type: '',
        image: '',
        id: '',
        description: '',
    },
    pages: [],
    currentPage: undefined,
    lastPoppedPage: undefined,
    isBrowsing: false,

    currentIntent: undefined,
    openReviews: false,

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSearchString: () => { },
    searchString: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setFilters: () => { },
    filters: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSorting: () => { },
    sorts: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setQuickFilters: () => { },
    quickFilters: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearAllFilterAndSorting: () => { },
    formik: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedShoppingType: () => { },
});

const initialPage: Page = {
    type: 'search-landing',
};

export function useProductBrowseContextState(): ProductBrowseContextState {
    const shoppingData = localStorage.getItem('shoppingType');
    const [fromPath, setFromPath] = useState<string | any>();
    const { history, pathname, query, push, location } =
        useRouter<ExpectedQuery>();
    const [shoppingType, setShoppingType] = useState<ShoppingType>(
        shoppingData ? JSON.parse(shoppingData) : shoppingTypes[0]
    );
    const [pages, setPages] = useState<Page[]>(
        query.productBrowse ? [initialPage] : []
    );
    const [currentIntent, setCurrentIntent] = useState<Intent | undefined>(
        undefined
    );
    const [openReviews, setOpenReviews] = useState<boolean>(false);
    const [lastPoppedPage, setLastPoppedPage] = useState<Page>(initialPage);
    const currentPage = pages.length > 0 ? pages[pages.length - 1] : undefined;
    function goToPage(newPage: Page) {
        if (newPage?.intent) {
            setCurrentIntent(newPage?.intent);
        }
        setOpenReviews(!!newPage?.openReviews);
        setPages((currentPages) => [...currentPages, newPage]);
    }

    // function setOpenReviews() {
    //   setOpenReviews(false);
    // }

    useEffect(() => {
        // If the user refreshes while browsing products within a list
        // We lose the intent, so we don't know what list they want to add the item to
        // To avoid confusing the user, push them back to the lists page so they can re-enter the flow
        if (
            query.productBrowse &&
            pathname.includes('lists') &&
            !currentIntent
        ) {
            setPages([]);
            history.replace({
                pathname,
            });
        }
    }, [query.productBrowse, pathname]);

    function goBack() {
        setOpenReviews(false);
        if (pages.length > 0) {
            setLastPoppedPage(pages[pages.length - 1]);
        }
        setPages((currentPages) => {
            const newPages = [...currentPages];
            newPages.pop();
            if (newPages.length === 0) {
                setCurrentIntent(undefined);
            }
            return newPages;
        });
    }

    // ensure productBrowse is in our query params when our state allows it
    useEffect(() => {
        if (pages.length === 0 && query.productBrowse) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { productBrowse, ...rest } = query;
            history.push({
                pathname,
                search: makeSearchString({ ...rest }),
            });
        }
        if (pages.length > 0 && !query?.productBrowse) {
            history.push({
                pathname,
                search: makeSearchString({ ...query, productBrowse: 'true' }),
            });
        }
    }, [pages, query]);
    const isBrowsing = !!query.productBrowse && !!currentPage;

    const [filters, setFilters] = useState<PendingFilters>([]);
    const [quickFilters, setQuickFilters] = useState<any>([]);
    const [sorts, setSorting] = useState<SortRequest[]>([]);
    function clearAllFilterAndSorting() {
        setFilters([]);
        setQuickFilters([]);
        setSorting([]);
    }
    const formik = useFormik({
        initialValues: {
            searchString: '',
        },
        validationSchema: yup.object({
            searchString: yup
                .string()
                .required('Please enter a search string')
                .max(150, 'Please shorten your search'),
        }),
        onSubmit: (e: any) => {
            if (e) {
                e.preventDefault();
            }
        },
    });

    // eslint-disable-next-line prefer-destructuring
    const searchString = formik.values.searchString;
    function setSearchString(val: string) {
        formik.setFieldValue('searchString', val);
        // STP-1489: Filters should be reset after a new search criteria
        setFilters([]);
    }

    function begin() {
        const path =
            location.pathname === ROUTES.productDetailsPage
                ? ROUTES.shop
                : location.pathname;
        setFromPath(path);
        push(ROUTES.shop);
        if (pages.length === 0 || currentPage?.type !== 'search-landing') {
            setLastPoppedPage(initialPage);
            setFilters([]);
            setSorting([]);
            setSearchString('');
            setPages([initialPage]);
            setCurrentIntent(undefined);
        } else {
            // eslint-disable-next-line no-console
            console.log('no-op: Already on search landing page');
        }
    }

    function end() {
        // goBack()
        if (pages.length > 0) {
            setLastPoppedPage(pages[pages.length - 1]);
        }
        setCurrentIntent(undefined);
        setPages([]);
    }
    const setSelectedShoppingType = (data: ShoppingType) => {
        setShoppingType(data);
    };
    return {
        fromPath,
        setFromPath,
        shoppingType,
        setShoppingType,
        isBrowsing,
        pages,
        currentPage,
        lastPoppedPage,
        goToPage,
        goBack,
        begin,
        end,
        currentIntent,
        openReviews,
        setOpenReviews,
        formik,
        setSearchString,
        searchString,
        setFilters,
        filters,
        setSorting,
        sorts,
        quickFilters,
        setQuickFilters,
        clearAllFilterAndSorting,
        setSelectedShoppingType,
    };
}

export function useProductBrowseContext() {
    return useContext(ProductBrowseContext);
}

export default ProductBrowseContext;
