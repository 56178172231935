import { Operation } from 'fast-json-patch';
import { AxiosResponse } from 'axios';
import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import { getOidcUserEmail } from 'src/lib/utils/oidc-utils';
import { NO_REFETCH } from 'src/lib/utils/react-query-utils';
import {
    rewardsTermsAndConditionsUpdateURL,
    rewardsUpdateUserAccountTypeURL,
    rewardsUserEmailLookupURL,
    userEnrollmentOnboardingDoneURL,
    userGetCurrentInfoURL,
} from './urls';
import { User, UserLookupResponse } from './types';

export function getUserInfo(): UseQueryResult<User> {
    const queryFunc = async (): Promise<User> =>
        (await axiosInstance.get<User>(userGetCurrentInfoURL)).data;
    return useQuery(['userInfo'], queryFunc, {
        enabled: SessionManager.isLoggedIn,
        ...NO_REFETCH,
    });
}

type PatchUserInfoConfig =
    | Omit<
          UseMutationOptions<AxiosResponse<any>, unknown, Operation[], unknown>,
          'mutationFn'
      >
    | undefined;
export function patchUserInfo(config: PatchUserInfoConfig) {
    const mutationFunc = (data: Operation[]) =>
        axiosInstance.patch(userGetCurrentInfoURL, data);

    return useMutation(mutationFunc, config);
}

type LookupUserEmailReturnType = UseMutationResult<
    UserLookupResponse,
    unknown,
    string,
    unknown
>;
type LookupUserEmailConfig =
    | Omit<
          UseMutationOptions<UserLookupResponse, unknown, string, unknown>,
          'mutationFn'
      >
    | undefined;
export function lookupUserEmail(
    config: LookupUserEmailConfig
): LookupUserEmailReturnType {
    const queryFunc = async (email: string): Promise<UserLookupResponse> =>
        (
            await axiosInstance.get(
                rewardsUserEmailLookupURL.replace('{email}', email),
                {
                    headers: {
                        // always add the api key (needed for API Management)
                        'Ocp-Apim-Subscription-Key':
                            process.env
                                .REACT_APP_PUBLIC_VERSION_CHECK_API_KEY || '',
                        'content-type': 'application/json',
                        'Cache-Control': 'no-cache',
                    },
                }
            )
        ).data;
    return useMutation(queryFunc, config);
}

type MutateTermsAndConditionsConfig =
    | Omit<
          UseMutationOptions<AxiosResponse<any>, unknown, void, unknown>,
          'mutationFn'
      >
    | undefined;
export function mutateTermsAndConditions(
    config?: MutateTermsAndConditionsConfig
) {
    const mutationFunc = () =>
        axiosInstance.post(rewardsTermsAndConditionsUpdateURL);
    return useMutation(mutationFunc, config);
}

export function mutateOnboardingDone() {
    const mutationFunc = () =>
        axiosInstance.post(userEnrollmentOnboardingDoneURL);
    return useMutation(mutationFunc);
}

interface UpdateUserAccountTypeRequestBody {
    customerType: 'consumer' | 'business' | 'teacher';
    organizationName?: string;
}
type UpdateUserAccountTypeConfig =
    | Omit<
          UseMutationOptions<
              AxiosResponse<any>,
              unknown,
              UpdateUserAccountTypeRequestBody,
              unknown
          >,
          'mutationFn'
      >
    | undefined;
export function updateUserAccountType(config?: UpdateUserAccountTypeConfig) {
    const mutationFunc = (
        customerAccountDetails: UpdateUserAccountTypeRequestBody
    ) =>
        axiosInstance.post(
            rewardsUpdateUserAccountTypeURL,
            customerAccountDetails
        );
    return useMutation(mutationFunc, config);
}
