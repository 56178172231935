import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Typography,
    makeStyles,
    SwipeableDrawer,
    Collapse,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import COLORS from 'src/lib/colors';
import { MapPreview } from 'src/components/map-preview';
import TransitionUp from 'src/components/store-search-modal/transition-up';
import SearchResults from 'src/components/store-search-modal/search-results';
import NearbyResults from 'src/components/store-search-modal/nearby-results';
import useStoreSearch from 'src/hooks/useStoreSearch';
import NoStoreResults from 'src/components/store-search-modal/no-store-result';
import { Store } from 'src/lib/api-client/types';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useRouter from 'src/hooks/useRouter';

const useStyles = makeStyles({
    dialogEdge: {
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // maxHeight: '520px',
        height: '100%',
        // overflow: 'hidden',
        flexShrink: 1,
        padding: '0 21px',
        '& > span': {
            background: '#D9D9D9',
            width: '20%',
            height: '7px',
            position: 'absolute',
            borderRadius: '5px',
            top: '8px',
            left: 'calc(50% - 40px)',
        },
    },
    titleAndClose: {
        width: '100%',
    },
    titleText: {
        fontWeight: 700,
        fontSize: '26px',
        textAlign: 'left',
        lineHeight: '32px',
        width: '100%',
        margin: '40px 0 20px 0',
    },
    closeButton: {
        position: 'absolute',
        right: '.25rem',
        top: '.25rem',
        width: '2.25rem',
        height: '2.25rem',
        '& img': {
            width: '1.5rem',
            height: '1.5rem',
        },
        zIndex: 100,
    },
    searchInputFormWrapper: {
        display: 'flex',
        width: '100%',
        flexShrink: 1,
    },
    searchInputForm: {
        width: '100%',
        display: 'contents',
    },
    searchInput: {
        width: '100%',
        margin: '1rem 2.25rem',
        padding: '0 0.25rem 0 0.75rem',
        borderRadius: '1.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    searchInputActive: {
        borderColor: COLORS.brandRed,
    },
    searchIconBtn: {
        width: '2.15rem',
        height: '1.65rem',
        padding: '0 .25rem',
        marginLeft: '-.5rem',
        marginRight: '.25rem',
    },
    searchIcon: {
        width: '1.65rem',
        height: '1.65rem',
    },
    paperDrawer: {
        borderRadius: ' 15px 15px 0px 0px',
        top: '8%',
    },
});

export default function StoreDetailsDrawer({
    isLoading = false,
    open,
    itemNumber,
    withInventory,
    title = 'Staples stores near you',
    selectStoreCtaText = 'Set as my store',
    handleClose,
    mainStore,
    nearbyStores,
    onStoreSelected,
    ignoreIsActiveScreen,
    includeMap = true,
    reportEvents = {
        open: eventMetricsMap.change_location_tapped,
        search: eventMetricsMap.search_complete,
        selected: eventMetricsMap.store_set,
        close: eventMetricsMap.change_location_close,
    },
}: any) {
    const classes = useStyles();
    const [isSearching, setIsSearching] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [submittedSearchString, setSubmittedSearchString] = useState('');
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { history, pathname } = useRouter();

    const {
        isFetching,
        placeholderStoresData,
        stores: storeResults,
        clearStores,
    } = useStoreSearch({
        partialAddress: submittedSearchString,
        enabled: submittedSearchString.length !== 0,
        withInventory,
        itemNumber,
    });

    function onUpdateSearch(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
        setSearchString(event.target.value);
    }

    useEffect(() => {
        setIsSearching(
            storeResults.length === 0 ||
                isFetching ||
                submittedSearchString !== ''
        );
    }, [storeResults.length, isFetching, submittedSearchString]);

    useEffect(() => {
        if (isSearching && !isFetching) {
            if (reportEvents?.search) {
                customEvent(reportEvents.search);
            }
        }
    }, [isSearching, isFetching]);

    function clear() {
        setSearchString('');
        setIsSearching(false);
        setSubmittedSearchString('');
        clearStores();
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        clearStores();
        setSubmittedSearchString(searchString);
    }

    function onClose() {
        clear();
        if (reportEvents?.close) {
            customEvent(reportEvents.close);
        }
        handleClose();
    }

    useEffect(() => {
        if (!open) {
            setIsSearching(false);
        }
        if (open) {
            const unblock = history.block(() => {
                onClose();
                return false;
            });
            return () => {
                unblock();
            };
        }
        return undefined;
    }, [open]);

    function onEnter() {
        if (reportEvents?.open) {
            customEvent(reportEvents.open);
        }
    }

    function handleStoreSelected(newStore: Store) {
        if (reportEvents?.selected) {
            customEvent(reportEvents.selected);
            analyticsCustomEvent(eventMetricsMap.stores, {
                click_text: 'set as my store',
                element_location: 'change location',
                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
            });
            optimoveEvents(eventMetricsMap.scm_stores, {
                click_text: 'set as my store',
                element_location: 'change location',
                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
            });
        }
        clear();
        onStoreSelected(newStore);
        onClose();
    }

    const hasStores = !!nearbyStores?.length || !!mainStore;
    const showMap = includeMap && hasStores;
    const showNearbyResults = !isFetching && hasStores && !isLoading;
    const showNoStoreResults = !isFetching && !hasStores && !isLoading;

    const isStoreDetailsDrawerOpen = useIsActiveScreen(
        SCREEN_NAMES.CHANGE_LOCATION,
        open && !ignoreIsActiveScreen
    );

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={ignoreIsActiveScreen ? open : isStoreDetailsDrawerOpen}
            onClose={onClose}
            onOpen={onEnter}
            classes={{
                paper: classes.paperDrawer,
            }}
            style={{ borderRadius: ' 15px 15px 0px 0px' }}
        >
            <div className={classes.container}>
                <span />
                <div className={classes.titleAndClose}>
                    <Typography className={classes.titleText}>
                        {title}
                    </Typography>
                </div>
                {showMap && (
                    <MapPreview
                        mapHeight={125}
                        center={{
                            longitude: mainStore?.longitude || 0,
                            latitude: mainStore?.latitude || 0,
                        }}
                    />
                )}

                {isSearching && (
                    <SearchResults
                        withInventory={withInventory}
                        isFetching={isFetching}
                        results={storeResults}
                        placeholderStoreResults={placeholderStoresData}
                        onStoreSelected={handleStoreSelected}
                        selectStoreCtaText={selectStoreCtaText}
                    />
                )}
                {!isSearching && showNearbyResults && (
                    <NearbyResults
                        withInventory={withInventory}
                        mainStore={mainStore}
                        nearbyStores={nearbyStores}
                        onStoreSelected={handleStoreSelected}
                        selectStoreCtaText={selectStoreCtaText}
                        mainWrapper
                    />
                )}
                {isLoading && <NoStoreResults subText="Loading..." />}
                {showNoStoreResults && (
                    <NoStoreResults subText="Please enter in a search criteria above and select your Staples store." />
                )}
            </div>
        </SwipeableDrawer>
    );
}
