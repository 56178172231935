import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { UserData } from 'react-oidc';
import { IAuthenticatorContext } from 'react-oidc/lib/makeAuth';
import ROUTES from 'src/lib/routes';
import {
    Box,
    ButtonBase,
    Divider,
    makeStyles,
    Typography,
    IconButton,
    Fade,
} from '@material-ui/core';
import useAuthFromContext from 'src/lib/auth/use-auth-from-context';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { InUserDataConsumer } from 'src/lib/types';
import useUserInfo from 'src/hooks/useUserInfo';
import AppLink from 'src/components/link';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import ProfileImage from 'src/components/profile-image';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingOverlay from 'src/components/loading-overlay';
import { RewardsAccountUserType, User } from 'src/services/types';
import { getUserAssociatedSchools } from 'src/lib/api-client/locations';
import InfoButton from 'src/components/info-button';
// TODO: STP-1310 reenable phone number
// import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import { useCachedSchools } from 'src/lib/utils/school-utils';
import { fullName } from 'src/lib/utils/user-utils';
import useRouter from 'src/hooks/useRouter';
import ScrollContainer from 'src/components/screen-scroll';

const useStyles = makeStyles({
    mainContainer: {
        background: COLORS.whiteSmoke,
    },
    nameAndRole: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: COLORS.primaryWhite,
        marginBottom: '65px',
        height: '93px',
    },
    profileImage: {
        height: '6.5rem',
        width: '6.5rem',
    },
    name: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '28px',
        letterSpacing: '-0.38px',
        textAlign: 'center',
    },
    email: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '-0.38px',
        textAlign: 'center',
    },
    profileContainer: {
        marginTop: '14PX',
        padding: '0.5rem',
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    topRowTitle: {
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        fontSize: '1.1rem',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '2rem 0',
        alignItems: 'center',
    },
    bottomButton: {
        color: COLORS.brandCyan,
        fontSize: '1.1rem',
        fontWeight: 500,
        letterSpacing: '-0.6pt',
        marginTop: '1rem',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    appVersionText: {
        color: COLORS.fordGray,
        fontSize: '1rem',
        letterSpacing: '-0.6pt',
        marginTop: '1.2rem',
        display: 'flex',
        flexDirection: 'row',
    },
    appVersionTitle: {
        fontWeight: 600,
        paddingRight: '0.2rem',
    },
    associatedSchoolContainer: {
        marginTop: '1.5rem',
    },
    schoolDivider: {
        backgroundColor: COLORS.gray80,
        width: '100%',
    },
    backIcon: {
        fontSize: '1.3rem',
        color: COLORS.primaryWhite,
    },
    backButton: {
        left: '1rem',
        position: 'relative',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        gap: '7px',
    },
    manageProfile: {
        marginBottom: '50px',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '-0.385px',
        textAlign: 'center',
    },
    textSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textTitle: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '19px',
        letterSpacing: '-0.44px',
        textAlign: 'left',
        marginBottom: 3,
    },
    textSubTitle: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '-0.44px',
        textAlign: 'left',
    },
});

export function UserManageProfile() {
    const classes = useStyles();
    const { userInfo } = useUserInfo();

    const router = useRouter();

    const handleOnBackClick = () => {
        router.push(ROUTES.userProfile);
    };
    const handleNavigation = (type: string) => {
        if (type === 'personalData') {
            router.push(ROUTES.personalData);
        } else if (type === 'accountSecuritry') {
            router.push(ROUTES.accountSecurity);
        } else if (type === 'marketingConsent') {
            router.push(ROUTES.consent);
        }
    };
    console.log({ userInfo });
    return (
        <>
            <Box className={classes.mainContainer}>
                <NavTop
                    bgImageSrc={require('../assets/bgs/bgRedUserProfile.svg')}
                    marginBottom="-15.5rem"
                    minHeight="18rem"
                />
                <Fade in>
                    <IconButton
                        onClick={handleOnBackClick}
                        size="small"
                        className={classes.backButton}
                    >
                        <ArrowBackIosRoundedIcon className={classes.backIcon} />
                    </IconButton>
                </Fade>
                <div className={classes.nameAndRole}>
                    <Typography
                        className={classes.manageProfile}
                        id="user-profile-name-text"
                    >
                        Manage Profile
                    </Typography>
                    <Typography
                        className={classes.name}
                        id="user-profile-name-text"
                    >
                        {fullName(userInfo)}
                    </Typography>
                    <Typography
                        className={classes.email}
                        id="user-profile-user-type-text"
                    >
                        {userInfo?.email}
                    </Typography>
                </div>
                <FullWidthCard
                    cardContainerClassName={classes.profileContainer}
                    hasManualShadow
                    onClick={() => handleNavigation('personalData')}
                >
                    <div className={classes.textContainer}>
                        <img
                            src={require('../assets/icons/personalData.svg')}
                            alt="personalData"
                        />
                        <div className={classes.textSubContainer}>
                            <div className={classes.textTitle}>
                                Personal Data
                            </div>
                            <div className={classes.textSubTitle}>
                                View your personal settings
                            </div>
                        </div>
                        <img
                            src={require('../assets/icons/profileBackIcon.svg')}
                            alt="profile action icon"
                        />
                    </div>
                </FullWidthCard>
                <FullWidthCard
                    cardContainerClassName={classes.profileContainer}
                    hasManualShadow
                    onClick={() => handleNavigation('accountSecuritry')}
                >
                    <div className={classes.textContainer}>
                        <img
                            src={require('../assets/icons/accountSecurity.svg')}
                            alt="accountSecurity"
                        />
                        <div className={classes.textSubContainer}>
                            <div className={classes.textTitle}>
                                Account Security
                            </div>
                            <div className={classes.textSubTitle}>
                                Update your email or password
                            </div>
                        </div>
                        <img
                            src={require('../assets/icons/profileBackIcon.svg')}
                            alt="profile action icon"
                        />
                    </div>
                </FullWidthCard>
                <FullWidthCard
                    cardContainerClassName={classes.profileContainer}
                    hasManualShadow
                    onClick={() => handleNavigation('marketingConsent')}
                >
                    <div className={classes.textContainer}>
                        <img
                            src={require('../assets/icons/marketingConsent.svg')}
                            alt="marketingConsent"
                        />
                        <div className={classes.textSubContainer}>
                            <div className={classes.textTitle}>
                                Marketing Consent
                            </div>
                            <div className={classes.textSubTitle}>
                                Update your preference
                                <br />
                                related to being included in
                                <br />
                                important marketing messages
                            </div>
                        </div>
                        <img
                            src={require('../assets/icons/profileBackIcon.svg')}
                            alt="profile action icon"
                        />
                    </div>
                </FullWidthCard>
            </Box>
        </>
    );
}

export default () => (
    <UserData.Consumer>
        {(context) => (
            <ScrollContainer>
                <UserManageProfile />
            </ScrollContainer>
        )}
    </UserData.Consumer>
);
