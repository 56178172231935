import React, { useContext, useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import { getShipmentListDetails } from 'src/lib/api-client/shipment-services';
import LoadingOverlay from 'src/components/loading-overlay';
import ShippingServicesCreateLabel from './shipping-services-create-label';
import ShippingServicesShippedLabel from './shipping-services-shipped-label';
import ShippingServicesDeliveredLabel from './shipping-services-delivered-label';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';

const useStyles = makeStyles((theme) => ({
    shippingServicesContainer: {
        overflowY: 'hidden',
    },
    shippingLoadingConatiner: {
        height: '100vh',
    },
    shipingLabelHeader: {
        height: '128px',
        display: 'flex',
        flexDirection: 'column',
        background: '#101820',
        alignItems: 'center',
        gap: '15px',
        paddingTop: '33px',
        position: 'sticky',
        zIndex: 1,
    },
    startShipment: {
        height: '30px',
        width: '166px',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '24px',
        '& span': {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '11.8px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
    },
    shipingLabelHeaderImgTextAlign: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    labelShipment: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '25.96px',
        color: '#FFFFFF',
        letterSpacing: '0px',
    },
    backIconAlign: {
        position: 'absolute',
        left: '20px',
    },
    tab: {
        flexGrow: 1,
        height: '40px',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14.16px',
        color: '#101820',
    },
    tabContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        width: '100%',
        border: '1px solid #D9D9D6',
        backgroundColor: '#FFFFFF',
        position: 'sticky',
        zIndex: 1,
    },
    activeTabAlign: {
        borderBottom: '3px solid #CC0000',
        width: '100%',
        position: 'absolute',
        bottom: '0px',
    },
}));

const ShippingServicesContainer = () => {
    const classes = useStyles();
    const { history } = useRouter();
    const [activeTab, setActiveTab] = useState('create');
    const [shipmentListData, setShipmentListData] = useState([]);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();
    const { updateFetchSenderInfo, shippingTab } = useContext(ShippingInfoContext);
    const handleTabNavigation = (tabName: string) => {
        setActiveTab(tabName);
    };

    const goBack = () => {
        history.push(ROUTES.shippingServices);
    };

    const handleStartShipment = () => {
        updateFetchSenderInfo(true);
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        });
        analyticsCustomEvent(eventMetricsMap.start_shipment, {
            click_text: 'start a shipment',
            element_location: 'labels and shipment - created',
        });
    };
    const getShipmentListData = async () => {
        setLoading(true);
        setError(false);
        const response = await getShipmentListDetails();
        if (response?.length) {
            const sortedShipmentList = response?.sort((a: any, b: any) => {
                const da = new Date(a.shipmentcreateddate).getTime();
                const db = new Date(b.shipmentcreateddate).getTime();
                return db - da;
            });
            if (sortedShipmentList) {
                setShipmentListData(sortedShipmentList);
            }
        } else {
            setShipmentListData([]);
        }
        if (response?.error) {
            setError(true);
        }
        setLoading(false);
    };
    const getShipmentListUpdatedData = () => {
        getShipmentListData();
    }
    useEffect(() => {
        getShipmentListData();
    }, [])

    useEffect(() => {
        setActiveTab(shippingTab)
    }, [shippingTab])

    if (isLoading) {
        return (
            <div className={classes.shippingLoadingConatiner}>
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            </div>
        );
    }
    return (
        <div className={classes.shippingServicesContainer}>
            <div className={classes.shipingLabelHeader}>
                <div className={classes.shipingLabelHeaderImgTextAlign}>
                    <ButtonBase
                        className={classes.backIconAlign}
                        onClick={goBack}
                    >
                        <img
                            src={require('../../../assets/icons/iconBackWhite.svg')}
                            alt="iconBackWhite"
                        />
                    </ButtonBase>
                    <Typography className={classes.labelShipment}>
                        Labels and tracking
                    </Typography>
                </div>
                <div>
                    <ButtonBase
                        className={classes.startShipment}
                        onClick={handleStartShipment}
                    >
                        <span>Start shipment</span>
                    </ButtonBase>
                </div>
            </div>
            <div className={classes.tabContainer}>
                <ButtonBase
                    className={classes.tab}
                    // style={{ borderBottom: activeTab === "create" ? "3px solid #CC0000" : "" }}
                    onClick={() => handleTabNavigation('create')}
                >
                    Created
                    {activeTab === 'create' ? (
                        <div className={classes.activeTabAlign} />
                    ) : null}
                </ButtonBase>
                <ButtonBase
                    className={classes.tab}
                    // style={{ borderBottom: activeTab === "shipped" ? "3px solid #CC0000" : "" }}
                    onClick={() => handleTabNavigation('shipped')}
                >
                    Shipped
                    {activeTab === 'shipped' ? (
                        <div className={classes.activeTabAlign} />
                    ) : null}
                </ButtonBase>
                <ButtonBase
                    className={classes.tab}
                    // style={{ borderBottom: activeTab === "delivered" ? "3px solid #CC0000" : "" }}
                    onClick={() => handleTabNavigation('delivered')}
                >
                    Delivered
                    {activeTab === 'delivered' ? (
                        <div className={classes.activeTabAlign} />
                    ) : null}
                </ButtonBase>
            </div>
            {activeTab === 'create' ? (
                <ShippingServicesCreateLabel
                    activeTab={activeTab}
                    shipmentListData={shipmentListData}
                    updatedData={getShipmentListUpdatedData}
                    setError={setError}
                    error={error}
                />
            ) : null}
            {activeTab === 'shipped' ? (
                <ShippingServicesShippedLabel
                    activeTab={activeTab}
                    shipmentListData={shipmentListData}
                    setError={setError}
                    error={error}
                    updatedData={getShipmentListUpdatedData}
                />
            ) : null}
            {activeTab === 'delivered' ? (
                <ShippingServicesDeliveredLabel
                    activeTab={activeTab}
                    shipmentListData={shipmentListData}
                    setError={setError}
                    error={error}
                    updatedData={getShipmentListUpdatedData}
                />) : null}
        </div>
    );
};

export default ShippingServicesContainer;
