import React from 'react';
import COLORS from 'src/lib/colors';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  paper: { borderRadius: '1rem',
   margin: '1rem',
   minHeight: '228px',
   width: '328px',
 },
  titleWrapper: {
    padding: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: '-0.5pt',
    alignSelf: 'center',
    marginTop: '-1.6rem',
    marginBottom: '-.5rem',
  },
  titlePadding: { marginTop: '2rem' },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    justifyContent: 'flex-end',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialogContentText: {
    color: COLORS.secondaryNeutralCharcoal,
    textAlign: 'center',
    marginTop: '1rem',
    letterSpacing: '-0.5pt',
    lineHeight: '1.2rem',
  },
  actionsWrapper: {
    flexDirection: 'column',
  },
  primaryButton: {
    width: '90%',
    margin: '11px 0 13px 0',
    height: '2.5rem',
    borderRadius: '1.885rem',
    background: COLORS.brandRed,
    color: COLORS.whiteSmoke,
    textTransform: 'none',
    fontSize: '1rem',
  },
  vericalGap: {
    width: '100%',
    height: '.2rem',
  },
});

export type DonationExceedDialogProps = {
  open: boolean;
  onClose: () => void;
  message: string;
};

export default function DonationExceedDialog(props: DonationExceedDialogProps) {
  const {
    open,
    onClose,
    message,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="donation-report-dialog-title"
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle
        disableTypography
        id="list-create-edit-dialog-title"
        className={classes.titleWrapper}
      >
        <Button
          fullWidth
          onClick={onClose}
          className={classes.closeIcon}
          id="close-button"
        >
          <img src={require('../../assets/icons/iconCloseBlack.svg')} alt="close icon" />
        </Button>
        <Typography
          className={classes.title}
          id="list-create-edit-dialog-title-text"
        >
          Daily Donation Limit
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
          <DialogContentText className={classes.dialogContentText}>
           {message}
           {/* Thank you for wanting to donate! Unfortunately this user has exceeded the limit for receiving donations
            today. Please try again in 24 hours. */}
          </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionsWrapper}>        
          <Button
            variant="contained"
            onClick={onClose}
            classes={{
              root: classes.primaryButton,
            }}
          >
            OK
          </Button>      
      </DialogActions>
    </Dialog>
  );
}