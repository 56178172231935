/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { UserData } from 'react-oidc';
import { IAuthenticatorContext } from 'react-oidc/lib/makeAuth';
import ROUTES from 'src/lib/routes';
import {
    Box,
    ButtonBase,
    makeStyles,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    Drawer,
    TextField,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { InUserDataConsumer } from 'src/lib/types';
import { getUserPasscodeInfo } from 'src/lib/api-client/user-info';
import AppLink from 'src/components/link';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import ProfileImage from 'src/components/profile-image';
import FullWidthCard from 'src/components/cards/full-width-card';
import LoadingOverlay from 'src/components/loading-overlay';
import { RewardsAccountUserType } from 'src/services/types';
import { fullName } from 'src/lib/utils/user-utils';
import { useScreenOrderContext } from 'src/lib/contexts/screen-order-context';
import ScrollContainer from 'src/components/screen-scroll';
import { SessionManager } from 'src/lib/api-client/sessionManager';
import NativeIframeWithHeaderFooter from 'src/components/NativeIframeWithHeaderFooter';
import { useQuery } from 'react-query';
import { getUserLists, isListEnabled } from 'src/lib/api-client/lists';
import BrandIframe from 'src/components/BrandIframe';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Cookies from 'js-cookie';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { rewardsPostLoyaltyAccountURL } from 'src/services/urls';
import {
    eventMetricsMap,
    LOCAL_STORAGE_KEYS,
    useEventsReporter,
    useGuestMode,
    useLocalStorage,
    useLocalUserInfo,
    useRouter,
    useStoreData,
    useUserInfo,
} from 'src/hooks';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import {
    numberWithCommas,
    precache,
    removeSmsession,
    removeSomniAppCookies,
    removeStplSessionIdUniqueId,
} from 'src/lib/utils/app-utils';
import Header from 'src/components/header';
import { Body, Heading, Pill } from 'src/components/UI';
import {
    getCustomerPriceTypes,
    getStaplesRewardsData,
} from 'src/lib/api-client/wallet-activities';
import { getLoyaltyAccountDetails } from 'src/services/easyRewardsServices';
import layout_classes from 'src/stylesheets/layout_classes.module.scss';
import {
    DigitalWallet,
    DownChevron,
    IconEasyRewardsHorizontal,
    IconStoreMode,
} from 'src/components/Icons';
import { mergeClasses } from 'src/stylesheets/utils';
import useIntroScreen from 'src/hooks/useIntroScreen ';
import styles from 'src/stylesheets/utilities.module.scss';
import { guestTokenLogin } from 'src/lib/api-client/guest-api';
import { CACHE_ONLY, NO_REFETCH } from '../lib/utils/react-query-utils';
import UserRewardsDigitalCardDrawer from './EasyRewards/UserRewardsDigitalCardDrawer';

const useStyles = makeStyles({
    digitalCard: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        // height: '2.3rem',
        padding: '0rem 0.7rem',
        columnGap: '0.5rem',
        alignItems: 'center',
        borderRadius: '14px',
        width: '91%',
        color: 'black',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
    },
    cardInside: {
        display: 'flex',
        columnGap: '0.8rem',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    midCard: {
        width: '87%',
    },
    mainContainer: {
        background: COLORS.whiteSmoke,
    },

    activityContainer: {
        padding: 20,
        borderRadius: 15,
        marginTop: 16,
        margin: '0 16px',
        backgroundColor: COLORS.primaryWhite,
    },

    nameAndRole: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: COLORS.primaryWhite,
    },
    row: {
        marginBottom: '16px',
        height: '43px',
    },
    titleAndInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    content: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#101820',
        position: 'relative',
        '&#optimove-visitor-id-text': {
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            lineHeight: '1.1rem',
        },
    },

    border: {
        padding: '16px 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        height: '65px',
    },
    topRowEdit: {
        fontWeight: 600,
        fontSize: '1.1rem',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },

    profileImage: {
        height: '6.5rem',
        width: '6.5rem',
    },
    name: {
        fontSize: '30px',
        fontWeight: 500,
        lineHeight: '34px',
        letterSpacing: '-0.6px',
        marginTop: '0.6rem',
    },
    role: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19px',
        marginTop: '2px',
    },
    profileContainer: {
        margin: '20px 16px',
        padding: '20px',
        boxShadow: 'none',
        borderRadius: '15px',
        textAlign: 'left',
        backgroundColor: COLORS.primaryWhite,
        display: 'inherit',
        width: 'calc(100% - 32px)',
    },
    profileListContainer: {
        paddingTop: '0rem',
        paddingLeft: '1.5rem',
        listStyleType: 'disc',
    },
    profileListItem: {
        padding: '0',
        display: 'list-item',
    },
    settingsContainer: {
        margin: '0px 16px 30px',
        padding: '0',
        boxShadow: 'none',
        '& .MuiCardContent-root': {
            padding: '0px',
        },
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    topRowTitle: {
        fontWeight: 700,
        letterSpacing: '-0.2px',
        fontSize: '18px',
        lineHeight: '22px',
        marginBottom: '30px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0rem 1rem 2rem',
        alignItems: 'start',
    },
    bottomButton: {
        color: COLORS.brandCyan,
        fontSize: '1rem',
        fontWeight: 400,
        letterSpacing: '-0.408px',
        marginTop: '1rem',
        textAlign: 'left',
        '&:hover': {
            textDecoration: 'none',
        },
        '&:first-child': {
            marginTop: '0.5rem',
        },
        userSelect: 'none',
    },
    appVersionText: {
        color: COLORS.fordGray,
        fontSize: '1rem',
        letterSpacing: '-0.6pt',
        marginTop: '1.2rem',
        display: 'flex',
        flexDirection: 'row',
        userSelect: 'none',
    },
    appVersionTitle: {
        paddingRight: '0.2rem',
        userSelect: 'none',
    },
    associatedSchoolContainer: {
        marginTop: '1.5rem',
    },
    schoolDivider: {
        backgroundColor: COLORS.gray80,
        width: '100%',
    },
    settingsDivider: {
        backgroundColor: 'rgba(0,0,0,0.1)',
        width: '100%',
    },
    backIcon: {
        fontSize: '1.3rem',
        color: COLORS.primaryWhite,
    },
    backButton: {
        left: '1rem',
        position: 'relative',
    },
    cardItem: {
        color: COLORS.textBlack,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    logoHeading: {
        display: 'block',
        paddingLeft: 0,
        paddingTop: 0,
    },
    logoHeadingText: {
        fontSize: '1.1rem',
        fontWeight: 700,
        marginTop: '-3px',
        userSelect: 'none',
    },
    listContainer: {
        padding: 0,
        marginBottom: -16,
    },
    listItems: {
        padding: '1rem 0',
        userSelect: 'none',
    },
    listLastItem: {
        paddingBottom: 0,
    },
    itemText: {
        fontSize: '1rem',
        fontWeight: 700,
    },
    availableText: {
        color: '#4CCC65',
    },
    coin: {
        verticalAlign: 'top',
        marginRight: '10px',
        backgroundImage: `url(${require('../assets/icons/iconCoin.png')})`,
        display: 'inline-block',
        width: '24px',
        height: '24px',
    },
    warningLoyaltyIcon: {
        position: 'absolute',
        marginLeft: '0.5rem',
        top: '1.5rem',
    },
    warningLoyaltyText: {
        marginLeft: '4.2rem',
        paddingTop: '0.5rem',
    },
    profileNavIcon: {
        height: '1.2rem',
        width: '1.2rem',
        marginTop: '-30px',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: COLORS.whiteSmoke,
        margin: 'auto',
    },
    otpForm: {
        '& .MuiTextField-root': {
            width: '100%',
        },
        '& .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiInput-underline:before': {
            display: 'none',
        },
        '& .Mui-error > input': {
            margin: '15px 0 0 0',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#000000 !important',
            fontWeight: '700',
        },
    },
    ctaBtn: {
        height: '50px',
        borderRadius: '1.885rem',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        margin: '20px 0 0 0',
        width: '100%',
        '&:hover': {
            backgroundColor: COLORS.brandRed,
        },
        '&:active': {
            backgroundColor: COLORS.brandRed,
        },
    },

    // Drawer styling
    exitContainer: {
        height: '450px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '24px',
        position: 'relative',
        '& > span': {
            background: '#D9D9D9',
            width: '50px',
            height: '3px',
            position: 'absolute',
            borderRadius: '5px',
            top: '5px',
        },
    },
    ctwText: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
        color: '#000000',
        padding: '50px 30px 30px',
        background: '#F2F3F4',
        width: '100%',
    },
    tacoext: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#000000',
        padding: '30px',
    },
    paperDrawer: {
        borderRadius: ' 15px 15px 0px 0px',
    },
    otpInput: {
        '& .MuiInputBase-input': {
            border: '1px solid rgba(0, 0, 0, 0.2)',
            width: '100%',
            borderRadius: ' 15px',
            minHeight: '70px',
            margin: '15px 0',
            fontSize: '32px',
            lineHeight: '24px',
            letterSpacing: '20px',
            padding: '0 28px',
            textAlign: 'center',
        },
    },
    smallTxt: {
        fontSize: '12px',
        lineHeight: '16px',
        padding: '10px 0',
        textAlign: 'center',
    },
    scancode: {
        display: 'flex',
        marginTop: '0.5rem !important',
        height: '1.7rem',
        columnGap: '5px',
    },
    scanIcon: {
        color: COLORS.purple,
        fontWeight: 900,
    },
    scanIconLast: {
        marginTop: '0.1rem',
        // marginLeft: '2rem',
    },
});

const useCardItemStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        gap: '20px',
        alignItems: 'flex-start',
    },
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    logo: {
        marginTop: '4px',
    },
    titleAndDescription: {
        userSelect: 'none',
    },
    title: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        color: '#101820',
    },
    icon: {
        width: '1.2rem',
        height: '1.2rem',
        marginTop: '0.3rem',
    },
    content: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#101820',
        position: 'relative',
        userSelect: 'none',
    },
    pillWrapper: {
        backgroundColor: '#0058D8',
        color: '#fff',
        fontSize: '10px',
        fontWeight: 700,
        letterSpacing: '0.5px',
        width: 'fit-content',
        marginBottom: '0.5rem',
        userSelect: 'none',
    },
});

type UserProfileProps = {
    context: IAuthenticatorContext;
};
type CardItemProps = {
    baseId: string;
    logo?: string | JSX.Element;
    title: string;
    iconChevron?: string;
    children: any;
    pill?: boolean;
    ariaLabel?: string;
};

function CardItem({
    baseId,
    logo,
    title,
    iconChevron,
    children,
    pill,
    ariaLabel,
}: CardItemProps) {
    const classes = useCardItemStyles();

    return (
        <div
            className={classes.row}
            id={`${baseId}-row`}
            aria-hidden={baseId === 'user-profile-lists'}
        >
            {logo && typeof logo === 'string' ? (
                <img
                    className={classes.logo}
                    aria-hidden="true"
                    src={logo}
                    alt="title-icon"
                />
            ) : (
                logo
            )}
            <div className={classes.container}>
                {pill ? (
                    <Pill className={classes.pillWrapper}>
                        STAPLES REWARDS BALANCES EXPIRE APR 30
                    </Pill>
                ) : (
                    ''
                )}

                <div className={classes.titleAndDescription}>
                    <Heading
                        size={18}
                        weight="bold"
                        type="h6"
                        role={
                            baseId === 'One-Time-Password-id'
                                ? 'none'
                                : 'button'
                        }
                        tabIndex={0}
                    >
                        {title}
                    </Heading>
                    <div className={classes.content}>{children}</div>
                </div>

                {iconChevron && (
                    <button
                        className={styles.ariaButton}
                        type="button"
                        aria-label={ariaLabel}
                    >
                        <img
                            aria-hidden="true"
                            className={classes.icon}
                            src={iconChevron}
                            alt="chevron"
                        />
                    </button>
                )}
            </div>
        </div>
    );
}

function getUserTypeLabel(userType?: RewardsAccountUserType) {
    switch (userType) {
        case 'Educator':
            return 'Teacher or Educator';
        case 'Administrator':
        case 'Supporter':
            return userType;
        case undefined:
        default:
            return 'Registered User';
    }
}

export function UserProfile({ context }: UserProfileProps) {
    sessionStorage.setItem(
        LOCAL_STORAGE_KEYS.AUTH_REDIRECT,
        window.location.pathname
    );
    const [sawInstallData] = useLocalStorage<string | null>(
        LOCAL_STORAGE_KEYS.INSTALL_PROMPT,
        null
    );
    const classes = useStyles();
    const { analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { navBottomActiveTab, setNavBottomActiveTab } =
        useUserUpdateContext();
    const { push, location, history } = useRouter();
    const { userInfo } = useUserInfo();
    const { enterStoreMode } = useStoreData();
    const { enterGuestMode } = useGuestMode();
    const { showIntro, setShowIntro } = useIntroScreen();


    const { data } = useQuery(['getUserLists'], () => getUserLists(), {
        refetchOnMount: true,
    });

    const { data: listEnabled } = useQuery(
        ['isListEnabled'],
        () => isListEnabled('StaplesConnectRetailList'),
        {
            refetchOnMount: true,
        }
    );

    const { data: otpEnabled } = useQuery(
        ['otpEnabled'],
        () => isListEnabled('OTPCode'),
        {
            refetchOnMount: true,
        }
    );

    const { data: customerPriceTypeData } = useQuery(
        ['CustomerPriceTypes', userInfo?.rewardsNumber],
        () => getCustomerPriceTypes(userInfo?.rewardsNumber),
        {
            ...NO_REFETCH,
            enabled: !!userInfo?.rewardsNumber,
        }
    );
    const [rewardstiercode, setRewardstiercode] = useState('');
    getLoyaltyAccountDetails({
        config: {
            enabled: !!userInfo?.rewardsNumber,
            onSuccess: (res) => {
                setRewardstiercode(res?.accounts[0]?.rewardstiercode);
            },
        },
        rewardsNumber: userInfo?.rewardsNumber,
    });

    const hostName = window?.location?.host;
    let urlPath = `https://www.staples.com/stores`;
    let staplesHelpCenterUrlPath = `https://www.staples.com/hc`;

    if (hostName === 'qe1.staples.com') {
        urlPath = 'https://qe1.staples.com/stores';
        staplesHelpCenterUrlPath = 'https://qe1.staples.com/hc';
    }

    if (
        hostName === 'qe-app.staplesconnect.com' ||
        hostName === 'qe101.staples.com' ||
        hostName === 'de-app.staplesconnect.com'
    ) {
        urlPath = 'https://qe101.staples.com/stores';
        staplesHelpCenterUrlPath = 'https://qe101.staples.com/hc';
    }
    const [loyaltyData, setLoyaltyData] = useState<any>({});

    const { prepareForLogout } = useScreenOrderContext();
    const { fromPath } = useProductBrowseContext();
    const {
        linkInNative,
        setLinkInNative,
        profileLinkInNative,
        setProfileLinkInNative,
    } = useTabsUIStateContext();
    const { userInfo: localUserInfo, patchLocalUserInfo } = useLocalUserInfo();

    const { data: staplesRewardsDetail } = useQuery(
        ['details', userInfo?.rewardsNumber],
        () => getStaplesRewardsData(userInfo?.rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );

    const getCouponsRewardsCategory = useMemo(() => {
        const couponsRewardsData =
            staplesRewardsDetail?.data?.couponDataList?.findIndex(
                (couponsData: any) => couponsData.category === 'Reward'
            ) !== -1;
        return couponsRewardsData;
    }, [staplesRewardsDetail]);

    console.log(
        '🚀 ~ file: user-profile.tsx:439 ~ UserProfile ~ staplesRewardsDetail:',
        staplesRewardsDetail,
        getCouponsRewardsCategory
    );

    useEffect(() => {
        if (userInfo) {
            const loyaltyPayload = {
                accountIdentifier: userInfo?.rewardsNumber,
                identifierType: 'REWARDS',
            };
            axiosInstance
                .post(`${rewardsPostLoyaltyAccountURL}`, loyaltyPayload)
                .then((loyaltyRes: any) => {
                    const accounts = loyaltyRes?.data?.accounts;
                    setLoyaltyData(
                        accounts && accounts[0].walletinfo?.balances
                    );
                })
                .catch((error) => {
                    setLoyaltyData({});
                });
        }
    }, [userInfo]);

    useEffect(() => {
        if (
            navBottomActiveTab === ROUTES.userProfile &&
            fromPath === ROUTES.userProfile
        ) {
            // setLinkInNative({
            //     src: '',
            //     show: false,
            // });
            // setProfileLinkInNative({
            //     src: '',
            //     show: false,
            // });
            // setNavBottomActiveTab('');
            if (linkInNative.show || profileLinkInNative.show) {
                push(`${ROUTES.userProfile}?link`)
            }

        }
    }, [navBottomActiveTab]);

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    const handleLogout = async () => {
        const { isUserMigrated, userName } = SessionManager;
        const uinfo = {
            preferredStoreId: userInfo?.preferredStoreId,
            zipCode: userInfo?.currentLocationZipCode,
        };
        const pwaUpdateApp = sessionStorage.getItem('pwaUpdateApp');
        let userMigratedData = {
            isUserMigrated: false,
            userName: SessionManager.userName,
        };

        if (isUserMigrated) {
            userMigratedData = {
                isUserMigrated,
                userName,
            };
        }

        // Analytics tracking
        analyticsCustomEvent(eventMetricsMap.account_info, {
            click_text: 'sign out',
            element_location: 'user profile',
        });
        optimoveEvents(eventMetricsMap.scm_account_info, {
            click_text: 'sign out',
            element_location: 'user profile',
        });
        analyticsScreenCustomNames({
            logged_in: false,
        });

        // Clearing local storage except persistent data
        const localStorageKeys = [];
        for (
            let keyIndex = 0, len = localStorage.length;
            keyIndex < len;
            keyIndex += 1
        ) {
            localStorageKeys.push(localStorage.key(keyIndex));
        }
        localStorageKeys.forEach((key) => {
            if (key && !key.includes('persistent')) {
                localStorage.removeItem(key);
            }
        });

        // Preserving specific settings
        sessionStorage.clear();
        if (!showIntro.isintroShownOn) {
            setShowIntro({ isintroShownOn: false });
        }
        if (sawInstallData) {
            localStorage.setItem(
                'app.staplesconnect.INSTALL_PROMPT',
                sawInstallData
            );
        }
        if (pwaUpdateApp) {
            sessionStorage.setItem('pwaUpdateApp', pwaUpdateApp);
        }

        // Cookie and session management cleanup
        Cookies.set('DirectCustomerNumber', '');
        removeStplSessionIdUniqueId();
        SessionManager.session?.killSession();
        SessionManager.isLoggedIn = false;
        removeSmsession();

        localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_INFO,
            JSON.stringify(uinfo)
        );

        // Handle onboarding status
        if (localUserInfo?.hasFinishedOnboarding) {
            patchLocalUserInfo({ hasFinishedOnboarding: true, zipCode: '' });
        }
        if (isUserMigrated) {
            SessionManager.setIsUserMigrated(userMigratedData);
        }

        // Final cleanup
        removeSomniAppCookies();
        prepareForLogout();
        precache(false);

        // await guestTokenLogin();
        // sessionStorage.setItem('guest', 'ON');
        await enterGuestMode();

        // Redirect to guest mode home screen
        window.location.replace('/somniapp/home');
    };

    const userTypeLabel = useMemo(
        () => getUserTypeLabel(userInfo?.userType),
        [userInfo?.userType]
    );

    const handleOnBackClick = () => {
        history.goBack();
        setProfileLinkInNative({ ...linkInNative, show: false });
    };

    const handleOnClickManage = () => {
        let URL = 'https://www.staples.com/gus/sdc/profileinfoV2';

        if (hostName === 'qe1.staples.com') {
            URL = 'https://qe1.staples.com/gus/sdc/profileinfoV2';
        } else if (hostName === 'qe101.staples.com') {
            URL = 'https://qe101.staples.com/gus/sdc/profileinfoV2';
        }
        setProfileLinkInNative({
            src: URL,
            show: true,
        });

        analyticsCustomEvent(eventMetricsMap.account_info, {
            click_text: 'view all',
            element_location: 'user profile',
        });
        push(`${ROUTES.userProfile}?link`);
    };

    const handleWindowBackEvent = () => {
        setLinkInNative({ src: '', show: false });
        setProfileLinkInNative({ src: '', show: false });
    };

    useEffect(() => {
        window.addEventListener("popstate", handleWindowBackEvent);
        return () => window.removeEventListener("popstate", handleWindowBackEvent);
    }, []);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [retrivePasscode, setRetrivePasscode] = useState(false);
    const [
        isUserRewardsDigitalCardDrawerOpen,
        toggleIsUserRewardsDigitalCardDrawerOpen,
    ] = useState(false);

    const handleOpenDrawer = async () => {
        setRetrivePasscode(true);
        try {
            const userInfoData = await getUserPasscodeInfo();
            setInputValue(userInfoData?.passcode);
            setOpenDrawer(true);
            setRetrivePasscode(false);
        } catch (errorData) {
            setRetrivePasscode(false);
        }
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleAllSet = () => {
        if (inputValue.trim() !== '' && inputValue.trim().length === 6) {
            setError(false);
            handleCloseDrawer();
        } else {
            setError(true);
        }
    };
    const [otherLocationName, setOtherLocationName] = useState('');
    const getOtherBalanceName = () => {
        let res = '';
        const isClassroomRewardsApplicable =
            userInfo?.walletId &&
            userInfo?.rewardsDetails?.payoutAvailableAmount > 0;
        const isStaplesRewardsApplicable =
            getCouponsRewardsCategory && userInfo?.rewardsNumber;

        if (isClassroomRewardsApplicable) {
            res = 'Classroom Rewards';
        }
        if (isStaplesRewardsApplicable) {
            if (isClassroomRewardsApplicable) {
                // Add ' & ' only if Classroom Rewards is also present
                res += ' & ';
            }
            res += 'Staples Rewards';
        }

        setOtherLocationName(res);
    };

    const handleViewStoreMode = () => {
        analyticsCustomEvent(eventMetricsMap.account, {
            click_text: 'view store mode',
        });

        enterStoreMode();
        push(ROUTES.storeMode);
    };

    useEffect(() => {
        if (userInfo) {
            getOtherBalanceName();
        }
    }, [userInfo]);

    useEffect(() => {
        if ((location as any)?.state?.showStaplesAccountPage) {
            handleOnClickManage();
        }
    }, []);

    // useEffect(() => {
    //     if (linkInNative.show) {
    //         const unblock = history.block(() => {
    //             setLinkInNative({ src: '', show: false });
    //             return false;
    //         });

    //         return () => {
    //             unblock();
    //         };
    //     }
    //     return undefined;
    // }, [linkInNative.show]);

    if (profileLinkInNative.show) {
        return (
            <>
                <NavTop
                    withTopScroll
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('../assets/bgs/header-bg.svg')}
                    noBorderRadius
                    minHeight="0"
                >
                    <Box className={classes.cartHeader}>
                        <ButtonBase onClick={() => handleOnBackClick()}>
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                        <Typography className={classes.title}>
                            Account
                        </Typography>
                    </Box>
                </NavTop>
                <BrandIframe
                    src={profileLinkInNative.src}
                    title="user-profile-page"
                    id="profile-screen"
                />
            </>
        );
    }
    if (linkInNative.show) {
        return (
            <>
                <Header
                    backButton
                    onClickHandler={() => {
                        history.goBack();
                        setLinkInNative({ src: '', show: false })
                    }
                    }
                />
                <BrandIframe
                    src={linkInNative.src}
                    title="user-profile-page"
                    id="profile-screen"
                />
            </>
        );
    }

    return (
        <>
            {retrivePasscode && <LoadingOverlay open={retrivePasscode} />}

            <Box className={mergeClasses(classes.mainContainer)}>
                <NavTop
                    bgImageSrc={require('../assets/bgs/bgRedUserProfile.svg')}
                    marginBottom="-16.5rem"
                    minHeight="310px"
                />
                <div
                    className={classes.nameAndRole}
                    data-visible-text={fullName(userInfo)}
                >
                    {/* <ProfileImage
                        userInfo={userInfo}
                        className={classes.profileImage}
                    /> */}
                    <Heading
                        type="h3"
                        size={30}
                        weight="bold"
                        id="user-name"
                        className={classes.name}
                        ariaLabel={`Hello ${fullName(userInfo)}`}
                    >
                        {fullName(userInfo)}
                    </Heading>
                    {customerPriceTypeData?.data?.customerPriceType ===
                        'Chamber' ? (
                        <Body
                            ariaLabel="You are a Chamber of Commerce Member"
                            id="user-title"
                        >
                            {' '}
                            Chamber of Commerce Member
                        </Body>
                    ) : (
                        <>
                            {userInfo?.rewardsMemberType === 'Associate' ||
                                rewardstiercode === 'ARW' ? (
                                <Body
                                    id="user-title"
                                    ariaLabel="You are a valued associate"
                                >
                                    Valued Associate
                                </Body>
                            ) : (
                                ``
                            )}
                        </>
                    )}
                    {/* <Heading type="h4" weight="normal" className={classes.role}>
                        {userTypeLabel}
                    </Heading> */}
                    {userInfo?.rewardsNumber && (
                        <div
                            onClick={() => {
                                toggleIsUserRewardsDigitalCardDrawerOpen(true);

                                analyticsCustomEvent(
                                    eventMetricsMap.easy_rewards,
                                    {
                                        click_text: 'show my card',
                                        element_location: 'account page',
                                        event_rewards_number:
                                            userInfo?.rewardsNumber?.padStart(
                                                10,
                                                '0'
                                            ) || '',
                                    }
                                );
                            }}
                            className={classes.digitalCard}
                        >
                            <div className={classes.cardInside}>
                                <DigitalWallet height={30} width={46} />
                                <div className={classes.midCard}>
                                    <Body
                                        className={classes.scancode}
                                        tabIndex={0}
                                        ariaLabel="Scan my easy rewards number"
                                        role="button"
                                    >
                                        Scan my{' '}
                                        <span className={classes.scanIcon}>
                                            Easy Rewards
                                        </span>{' '}
                                        number
                                    </Body>
                                    <Body
                                        weight="bold"
                                        size={16}
                                        color="textRed"
                                        margin="0 0 0.5rem 0"
                                    >
                                        {userInfo?.rewardsNumber}
                                    </Body>
                                </div>
                                <span
                                    className={classes.scanIconLast}
                                    role="button"
                                    aria-label="Bring up your Easy Rewards number to scan in store"
                                >
                                    <DownChevron />
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                {userInfo?.rewardsNumber && (
                    <div className={classes.activityContainer}>
                        <List className={classes.listContainer}>
                            <ListItem
                                className={classes.logoHeading}
                                role="none"
                                ContainerComponent="div"
                            >
                                <IconEasyRewardsHorizontal width={127} />
                                <Heading
                                    type="h6"
                                    size={18}
                                    weight="bold"
                                    className={classes.logoHeadingText}
                                >
                                    <span
                                        className={mergeClasses(
                                            styles.visuallyHidden,
                                            layout_classes.block
                                        )}
                                    >
                                        Easy Rewards{' '}
                                    </span>
                                    Activity
                                </Heading>
                            </ListItem>
                            <Divider />
                            {loyaltyData && Object.keys(loyaltyData).length ? (
                                <>
                                    <ListItem
                                        ContainerComponent="div"
                                        className={classes.listItems}
                                        role="none"
                                    >
                                        <span
                                            className={mergeClasses(
                                                styles.visuallyHidden,
                                                layout_classes.block
                                            )}
                                        >
                                            Your available points is{' '}
                                            {loyaltyData.usable} points - Thats
                                            like {loyaltyData.usablevalue}{' '}
                                            dollars in savings
                                        </span>
                                        <ListItemText aria-hidden="true">
                                            Available points
                                        </ListItemText>
                                        <ListItemSecondaryAction aria-hidden="true">
                                            <Body size={16} weight="bold">
                                                <span
                                                    className={classes.coin}
                                                />
                                                {loyaltyData?.usable &&
                                                    numberWithCommas?.(
                                                        loyaltyData.usable
                                                    )}
                                            </Body>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        className={classes.listItems}
                                        alignItems="center"
                                        aria-hidden="true"
                                    >
                                        <ListItemText aria-hidden="true">
                                            Available dollars
                                        </ListItemText>
                                        <ListItemSecondaryAction aria-hidden="true">
                                            <Body
                                                size={16}
                                                weight="bold"
                                                color="textGreen"
                                            >
                                                ${loyaltyData.usablevalue}
                                            </Body>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        className={classes.listItems}
                                        alignItems="center"
                                        role="none"
                                        ContainerComponent="div"
                                    >
                                        <span
                                            className={mergeClasses(
                                                styles.visuallyHidden,
                                                layout_classes.block
                                            )}
                                        >
                                            Your lifetime saving with Easy
                                            rewards is{' '}
                                            {loyaltyData.lifetimespendvalue}{' '}
                                            dollars
                                        </span>
                                        <ListItemText aria-hidden="true">
                                            Lifetime savings
                                        </ListItemText>
                                        <ListItemSecondaryAction aria-hidden="true">
                                            <Body size={16} weight="bold">
                                                $
                                                {loyaltyData.lifetimespendvalue}
                                            </Body>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </>
                            ) : (
                                <ListItem className={classes.listItems}>
                                    <ListItemText>
                                        <img
                                            className={
                                                classes.warningLoyaltyIcon
                                            }
                                            src={require('../assets/icons/iconWarningLoyalty.svg')}
                                            alt="warning Loyalty"
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            className={
                                                classes.warningLoyaltyText
                                            }
                                        >
                                            Activity cannot be retrieved. Please
                                            try again at a later time.
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            )}
                        </List>
                    </div>
                )}

                <ButtonBase
                    className={classes.profileContainer}
                    onClick={handleOnClickManage}
                >
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="column"
                    >
                        <Heading type="h6" size={18} weight="bold">
                            My Profile
                            <span
                                className={mergeClasses(
                                    styles.visuallyHidden,
                                    layout_classes.block
                                )}
                            >
                                Where you can change your profile settings,
                                payment methods, address, and view your lists.
                            </span>
                        </Heading>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            aria-describedby="Where you can change your profile settings, 
                            payment methods, address, and view your lists."
                        >
                            <List
                                className={classes.profileListContainer}
                                aria-hidden="true"
                            >
                                <ListItem className={classes.profileListItem}>
                                    <ListItemText>My Lists</ListItemText>
                                </ListItem>
                                <ListItem className={classes.profileListItem}>
                                    <ListItemText>
                                        Profile Settings
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.profileListItem}>
                                    <ListItemText>Payment Methods</ListItemText>
                                </ListItem>
                                <ListItem className={classes.profileListItem}>
                                    <ListItemText>Addresses</ListItemText>
                                </ListItem>
                            </List>
                            <img
                                aria-label="Go to your profile"
                                src={require('../assets/icons/iconChevron.svg')}
                                alt="navIcon"
                                className={classes.profileNavIcon}
                            />
                        </Box>
                    </Box>
                </ButtonBase>

                <FullWidthCard
                    cardContainerClassName={classes.settingsContainer}
                    hasManualShadow
                    onClick={() =>
                        push(ROUTES.previousOrders, {
                            from: ROUTES.userProfile,
                        })
                    }
                >
                    <CardItem
                        baseId="purchase-hitory-id"
                        logo={require('../assets/icons/e-reciep-black.svg')}
                        title="Purchase History"
                        iconChevron={require('../assets/icons/iconChevron.svg')}
                        ariaLabel="Go to purchase history"
                    >
                        Online and in-store
                    </CardItem>
                </FullWidthCard>

                {userInfo?.rewardsNumber ? (
                    <FullWidthCard
                        cardContainerClassName={classes.settingsContainer}
                        hasManualShadow
                        onClick={() => {
                            push(ROUTES.rewardsWallet);
                            const coachmarksData = {
                                completed: false,
                                screen: 0,
                            };
                            localStorage.setItem(
                                'coachmarks',
                                JSON.stringify(coachmarksData)
                            );
                            analyticsCustomEvent(eventMetricsMap.coachmarks, {
                                click_text: `review easy rewards`,
                                element_location: 'account',
                            });
                        }}
                    >
                        <CardItem
                            baseId="Review_Easy_Rewards"
                            logo={require('../assets/icons/coachmarksIcon.svg')}
                            title="Review Easy Rewards"
                            ariaLabel="Go to Review Easy Rewards"
                        >
                            Let our associates show you around Easy Rewards!
                        </CardItem>
                    </FullWidthCard>
                ) : null}

                {(userInfo?.walletId &&
                    userInfo?.rewardsDetails?.payoutAvailableAmount > 0) ||
                    (getCouponsRewardsCategory && userInfo?.rewardsNumber) ? (
                    <FullWidthCard
                        cardContainerClassName={classes.settingsContainer}
                        hasManualShadow
                        onClick={() =>
                            push(ROUTES.otherbalance, {
                                otherBalance: otherLocationName,
                            })
                        }
                    >
                        <CardItem
                            baseId="other-balance"
                            title="Other balances"
                            iconChevron={require('../assets/icons/iconChevron.svg')}
                            pill
                        >
                            {otherLocationName}
                        </CardItem>
                    </FullWidthCard>
                ) : null}

                <FullWidthCard
                    cardContainerClassName={classes.settingsContainer}
                    hasManualShadow
                    onClick={handleViewStoreMode}
                >
                    <CardItem
                        baseId="View-Store-Mode"
                        logo={<IconStoreMode />}
                        title="View store mode"
                    >
                        {null}
                    </CardItem>
                </FullWidthCard>

                {otpEnabled?.isenabled ? (
                    <FullWidthCard
                        cardContainerClassName={classes.settingsContainer}
                        hasManualShadow
                    >
                        <CardItem
                            baseId="One-Time-Password-id"
                            logo={require('../assets/icons/otp-icon.svg')}
                            title="One-Time-Password for account identification"
                        >
                            <div style={{ paddingTop: '5px' }}>
                                Generate a one time code that will verify your
                                identity and give you secure access to Staples
                                services.
                            </div>
                            <ButtonBase
                                type="button"
                                className={classes.ctaBtn}
                                onClick={handleOpenDrawer}
                            >
                                Get code
                            </ButtonBase>
                        </CardItem>
                    </FullWidthCard>
                ) : null}
                {openDrawer ? (
                    <Drawer
                        anchor="bottom"
                        open={openDrawer}
                        onClose={handleCloseDrawer}
                        classes={{
                            paper: classes.paperDrawer,
                        }}
                        style={{ borderRadius: ' 15px 15px 0px 0px' }}
                    >
                        <div className={classes.exitContainer}>
                            <span />
                            <Typography className={classes.ctwText}>
                                Let's make sure we're accessing the right
                                account
                            </Typography>
                            <Typography className={classes.tacoext}>
                                <div>
                                    Please provide the following single use code
                                    when prompted by a Staples Associate
                                </div>
                                <form className={classes.otpForm}>
                                    <TextField
                                        id="otp-input"
                                        className={classes.otpInput}
                                        disabled
                                        autoFocus
                                        inputProps={{ maxLength: 6 }}
                                        value={inputValue}
                                        //   onChange={(e) => setInputValue(e.target.value)}
                                        error={error}
                                        helperText={
                                            error
                                                ? 'Please enter a 6-digit value'
                                                : ''
                                        }
                                    />
                                    <Typography className={classes.smallTxt}>
                                        This code is valid for 15 minutes
                                    </Typography>
                                    <ButtonBase
                                        className={classes.ctaBtn}
                                        onClick={handleAllSet}
                                    >
                                        <span>All set</span>
                                    </ButtonBase>
                                </form>
                            </Typography>
                        </div>
                    </Drawer>
                ) : null}

                {listEnabled?.isenabled && (
                    <FullWidthCard
                        cardContainerClassName={classes.settingsContainer}
                        hasManualShadow
                        onClick={() => push(ROUTES.lists)}
                    >
                        <CardItem
                            baseId="user-profile-lists"
                            logo={require('../assets/icons/list.svg')}
                            title="Featured Lists"
                            iconChevron={require('../assets/icons/iconChevron.svg')}
                        >
                            {data?.length ?? 0} Personal List
                            {(data?.length ?? 0) > 1 && 's'}
                        </CardItem>
                    </FullWidthCard>
                )}

                <Box className={classes.buttonContainer}>
                    <AppLink
                        href="#"
                        id="user-profile-help-center-button"
                        className={classes.bottomButton}
                        onClick={(e) => {
                            e.preventDefault();
                            const src = `${staplesHelpCenterUrlPath}`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'help & FAQ',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Help & FAQ',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'help & FAQ',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Help & FAQ',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            setLinkInNative({ src, show: true });
                            push(`${ROUTES.userProfile}?link`);
                        }}
                    >
                        Visit help center
                    </AppLink>
                    <AppLink
                        id="user-profile-easy-rewards-terms-and-conditions-button"
                        className={classes.bottomButton}
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/lp/easy-rewards-terms-conditions`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            setLinkInNative({ src, show: true });
                            push(`${ROUTES.userProfile}?link`);
                        }}
                    >
                        Easy Rewards terms & conditions
                    </AppLink>
                    <AppLink
                        id="user-profile-terms-and-conditions-button"
                        href="#"
                        className={classes.bottomButton}
                        onClick={(e) => {
                            e.preventDefault();
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/hc?id=52e40651-0852-4ad7-a532-45017c287d50`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'terms & conditions',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Terms & conditions',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            setLinkInNative({ src, show: true });
                            push(`${ROUTES.userProfile}?link`);
                        }}
                    >
                        Terms & conditions
                    </AppLink>
                    <AppLink
                        id="user-profile-privacy-policy-button"
                        href="#"
                        className={classes.bottomButton}
                        onClick={(e) => {
                            e.preventDefault();
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/hc?id=dbb94c10-973c-478b-a078-00e58f66ba32`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'privacy notice',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'Privacy notice',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'privacy notice',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'Privacy notice',
                                link_domain: 'staples.com',
                                link_url: src,
                            });
                            setLinkInNative({ src, show: true });
                            push(`${ROUTES.userProfile}?link`);
                        }}
                    >
                        Privacy notice
                    </AppLink>
                    <AppLink
                        id="user-profile-dont-sell-my-personal-info-button"
                        href="#"
                        className={classes.bottomButton}
                        onClick={(e) => {
                            e.preventDefault();
                            const src =
                                'https://submit-irm.trustarc.com/services/validation/2394f394-3df4-4051-bfa1-50c2cfa45f02';
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: `don't sell my peronsal info`,
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: "Don't sell my personal info",
                                link_domain: 'submit-irm.trustarc.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: `don't sell my peronsal info`,
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: "Don't sell my personal info",
                                link_domain: 'submit-irm.trustarc.com',
                                link_url: src,
                            });
                            setLinkInNative({ src, show: true });
                            push(`${ROUTES.userProfile}?link`);
                        }}
                    >
                        Do not sell or share my personal info
                    </AppLink>
                    <AppLink
                        id="user-profile-california-policy-button"
                        href="#"
                        className={classes.bottomButton}
                        onClick={(e) => {
                            e.preventDefault();
                            const src = `${process.env.REACT_APP_DOTCOM_URL}/hc?id=d0641e76-dd9f-4d69-80d3-ff148ddcd188`;
                            analyticsCustomEvent(eventMetricsMap.account_info, {
                                click_text: 'california notice',
                                element_location: 'user profile',
                            });
                            analyticsCustomEvent(eventMetricsMap.outbound, {
                                click_text: 'California notice',
                                link_domain: 'staplesconnect.com',
                                link_url: src,
                            });
                            optimoveEvents(eventMetricsMap.scm_account_info, {
                                click_text: 'california notice',
                                element_location: 'user profile',
                            });
                            optimoveEvents(eventMetricsMap.scm_outbound, {
                                click_text: 'California notice',
                                link_domain: 'staplesconnect.com',
                                link_url: src,
                            });
                            setLinkInNative({ src, show: true });
                            push(`${ROUTES.userProfile}?link`);
                        }}
                    >
                        California notice
                    </AppLink>
                    <ButtonBase
                        role="link"
                        id="user-profile-sign-out-button"
                        className={clsx(classes.bottomButton)}
                        onClick={handleLogout}
                    >
                        Sign out
                    </ButtonBase>
                    <Typography className={classes.appVersionText}>
                        <Body weight="bold" className={classes.appVersionTitle}>
                            Version:{' '}
                        </Body>
                        <span id="app_version_id_text">
                            {process.env.REACT_APP_VERSION}
                        </span>
                    </Typography>
                </Box>
            </Box>
            <UserRewardsDigitalCardDrawer
                open={isUserRewardsDigitalCardDrawerOpen}
                toggleOpen={toggleIsUserRewardsDigitalCardDrawerOpen}
                rewardstiercode={rewardstiercode}
            />
        </>
    );
}

export default (props: InUserDataConsumer<UserProfileProps>) => (
    <UserData.Consumer>
        {(context) => (
            <ScrollContainer>
                <UserProfile {...props} context={context} />
            </ScrollContainer>
        )}
    </UserData.Consumer>
);
