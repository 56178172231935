/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEvent, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import { useRouter, useStoreData } from 'src/hooks';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { Body, Button } from './UI';
import { IconClose, IconClosePlain } from './Icons';

const useStyles = makeStyles(() => ({
    container: {
        position: 'sticky',
        top: 0,
        zIndex: 6000,
        backgroundColor: '#f7f7f7',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8rem',
    },
}));

export default function StoreModeNotificationHeader() {
    const classes = useStyles();
    const { push, location } = useRouter();
    const { setLoadIframeUrl } = useTabsUIStateContext();

    const { exitStoreMode } = useStoreData();
    const handleStoreModeClose = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        exitStoreMode();
        location.pathname === ROUTES.storeMode && push(ROUTES.home);
    };

    return (
        <Box className={classes.container} id="store-mode-container">
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 'auto',
                }}
                onClick={() => {
                    setLoadIframeUrl('');
                    push(ROUTES.storeMode);
                }}
            >
                <img
                    src={require('../assets/icons/icon_home.svg')}
                    style={{ marginRight: '1rem' }}
                    width={38.4}
                    height={40}
                    alt="icon-home"
                />
                <Box>
                    <Body size={13} weight="light" lineHeight={16}>
                        You're browsing in store mode.
                    </Body>
                    <Body
                        size={13}
                        weight="bold"
                        lineHeight={16}
                        color="textLinkBlue"
                    >
                        Just tap to return (home).
                    </Body>
                </Box>
            </Box>
            <Button variant="icon" onClick={handleStoreModeClose}>
                <IconClose fillColor="fillBlack" />
            </Button>
            {/* <img
                src={require('../assets/icons/close-icon.svg')}
                width={14}
                height={14}
                alt="close-icon"
                onClick={handleStoreModeClose}
            /> */}
        </Box>
    );
}
