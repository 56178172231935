/* eslint-disable no-useless-computed-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    Backdrop,
    Button,
    Dialog,
    DialogProps,
    makeStyles,
    withStyles,
    Typography,
} from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import moment from 'moment';
import { Player } from '@lottiefiles/react-lottie-player';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import styles from 'src/stylesheets/utilities.module.scss';
import { Body, Heading } from './UI';
import IconRewardPop from './Icons/IconRewardPopup';

export type RewardsPromoCardDialogProps = DialogProps & {
    coupon: Record<string, any>;
};

// icon radius in rem
const ICON_RADIUS = 3;
// overhang for lottie icon
const CIRCULAR_OVERHANG = '3.5';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: `${ICON_RADIUS + 3}rem 1rem 6rem 1rem`,
        overflow: 'visible',
    },
    container: {
        backgroundColor: 'rgba(0,0,0,0.1)',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: `-${ICON_RADIUS}rem`,
        padding: '0 1.5rem',
    },
    cardOwner: {
        fontWeight: 'bold',
        fontSize: '1.8rem',
        padding: '.75rem',
        textAlign: 'center',
    },
    amountAvailableWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontWeight: 'bold',
        letterSpacing: '-0.6pt',
        marginTop: '0.9rem',
    },
    amountSign: {
        fontWeight: 900,
        fontSize: '2rem',
    },
    amount: {
        fontSize: '4rem',
        marginTop: '-1.5rem !important',
        fontWeight: 900,
    },
    decimalAmount: {
        fontSize: '2.1rem',
        marginTop: '-0.2rem',
    },
    classroomRewardsTitle: {
        fontSize: '1rem',
        fontWeight: 500,
        paddingBottom: '.5rem',
        textAlign: 'center',
    },
    renderedCardNumber: { width: '100%', maxWidth: '20rem', height: '7rem' },
    cardNumber: {
        marginTop: '.5rem',
        padding: '.1rem .5rem',
        zIndex: 1,
        fontWeight: 500,
        backgroundColor: COLORS.primaryWhite,
    },
    pin: {
        fontSize: '2rem',
        fontWeight: 'bold',
        letterSpacing: '-0.51pt',
        textTransform: 'uppercase',
        marginTop: '-3.5rem',
    },
    photoIdDisclaimer: {
        color: COLORS.secondaryNeutralBlack,
        // fontSize: '1.2rem',
        width: '80%',
        textAlign: 'center',
        marginBottom: '1rem',
        marginTop: '0.5rem',
        ['@media (max-width: 360px)']: {
            marginTop: '0.9rem',
        },
    },
    expire: {
        color: COLORS.secondaryNeutralBlack,
        // fontSize: '1.2rem',
        width: '80%',
        textAlign: 'center',
    },
    cashierDisclaimer: {
        color: COLORS.secondaryNeutralBlack,
        fontSize: '0.75rem',
        fontWeight: 'bold',
        marginTop: '2rem',
        width: '80%',
        textAlign: 'center',
    },
    expirationDate: { paddingTop: '1rem', fontWeight: 500 },
    closeButton: {
        color: COLORS.linkText,
        marginBottom: '1.5rem !important',
    },
    storeButton: {
        marginTop: '0.30rem',
        marginBottom: '1rem',
        width: '100%',
        maxWidth: '17rem',
        height: '40px',
        borderRadius: '1.885rem',
        background: COLORS.brandRed,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '17px',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: COLORS.disabledBackgroundGray,
            color: COLORS.disabledTextGray,
        },
    },
    lottiePin: {
        marginTop: '2rem',
        textAlign: 'center',
    },
    footer: {
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        overflow: 'visible',
        backgroundColor: COLORS.secondaryNeutralLightGray,
    },
    footerTitle: {
        paddingTop: '0.25rem',
        fontWeight: 500,
        textAlign: 'center',
    },
    footerSubTitle: {
        fontSize: '0.75rem',
        textAlign: 'center',
        paddingBottom: '0.25rem',
    },
    imgCircle: {
        backgroundColor: COLORS.primaryWhite,
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        paddingTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    promoHead: {
        ['@media (max-width: 360px)']: {
            textAlign: 'center',
            width: '63%',
        },
    },
});

/**
 * Promo card dialog to show rewards number info.
 */
export default function CreditCelebrationDialog(
    props: RewardsPromoCardDialogProps
) {
    const {
        coupon,
        onClose,
        open,
        ...rest
    } = props;
    const { analyticsCustomEvent } = useEventsReporter();
    function close(event: any) {
        localStorage.setItem('isStoreCreditDialogOpen',
            JSON.stringify({ open: true, value: coupon?.couponInfo?.couponValue }));
        if (onClose) {
            onClose(event, 'backdropClick');
        }
        analyticsCustomEvent(eventMetricsMap.pop_up, {
            click_text: 'close',
            offer_description: `$${coupon?.couponInfo?.couponValue || 0} you've received a promo card!`,
        });
    }
    const classes = useStyles();
    const { history } = useRouter();



    const isPromoCardOpen = useIsActiveScreen(SCREEN_NAMES.PROMO_CARD, open);
    const handleUseInStore = () => {
        analyticsCustomEvent(eventMetricsMap.pop_up, {
            click_text: 'redeem in store',
            offer_description: `$${coupon?.couponInfo?.couponValue || 0} you've received a promo card!`,
        });
        localStorage.setItem('isStoreCreditDialogOpen',
            JSON.stringify({ open: true, value: coupon?.couponInfo?.couponValue }));
        const url = `/coupons?couponNumber=${coupon?.couponNumber}&deepLink=false`;
        history.push(url);
    }

    useEffect(() => {
        analyticsCustomEvent(eventMetricsMap.pop_up, {
            click_text: 'promo card',
            offer_description: `$${coupon?.couponInfo?.couponValue || 0} you've received a promo card!`,
        });
    }, [])

    return (
        <Dialog
            classes={{
                paper: classes.paper,
                container: classes.container,
            }}
            scroll="body"
            open={isPromoCardOpen}
            {...rest}
        >
            <div className={classes.content}>

                <div className={classes.imgCircle}>
                    {/* <IconRewardPop /> */}
                    <Player
                        // onEvent={animationPlaying}
                        autoplay
                        loop
                        src={require('../assets/lotties/icon-promo-card-lottie.json')}
                    // style={{ marginTop: `-${CIRCULAR_OVERHANG + 1}rem` }}
                    />
                </div>
                <>
                   <div aria-label={`You have received a ${coupon?.couponInfo?.couponValue || 0} dollar promo card`}>
                   <Typography
                        className={classes.amountAvailableWrapper}
                        id="promo-card-dollar-amount-text"
                        aria-hidden="true"
                    >
                        <Body size={50} weight='bold' className={classes.amountSign}>
                            $
                        </Body>
                        <Body size={100} weight='bold' className={classes.amount}>
                            {coupon?.couponInfo?.couponValue || 0}
                        </Body>
                        <span className={classes.decimalAmount}>
                            {/* {availableCents} */}
                        </span>
                    </Typography>
                    {/* <Body size={20} weight='bold' className={classes.cardOwner}>
                        You've received a promo card!
                    </Body> */}
                    <Heading ariaHidden type='h2' size={22} color='#000' weight="bold" textAlign = 'textCenter' className={classes.promoHead}>You've received a promo card!</Heading>
                    </div>
                    <Typography className={classes.photoIdDisclaimer}>
                        Use your promo card on your next in-store purchase.
                    </Typography>
                    <Typography className={classes.expire}>
                        Expires {moment(coupon?.couponInfo?.expirationDate).format('M/D/YY')}
                    </Typography>
                    <Button className={classes.storeButton} onClick={handleUseInStore}>
                        Use in store
                    </Button>
                    <button  className={styles.ariaButton} type='button' aria-label='close'>
                    <Body weight='bold'  className={classes.closeButton} role='button' ariaLabel='close pop-up' >
                        <span onClick={close}>Close</span>
                    </Body>
                    </button>         
                </>
            </div>
        </Dialog>
    );
}
