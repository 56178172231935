import React, { useState, useEffect } from 'react';
import OptimalDeviceDialog from 'src/components/optimal-device-dialog';
import { deviceType, isMobile as mobileDevice } from 'react-device-detect';

console.log("🚀 ~ isMobile:", mobileDevice)

function OptimalDeviceController() {
  const [isDismissed, setDismissed] = useState(false);
  const [isMobile, setIsMobile] = useState(mobileDevice);

  console.log("🚀 ~ OptimalDeviceController ~ aisMD:", mobileDevice)

  useEffect(() => {
    setIsMobile(mobileDevice)
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setDismissed(false);
    }
  }, [isMobile]);

  const showDialog = !isMobile && !isDismissed;

  function onClose() {
    setDismissed(true);
  }
  return <OptimalDeviceDialog open={showDialog} handleClose={onClose} />;
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedOptimalDeviceController = () => <OptimalDeviceController />;
export default WrappedOptimalDeviceController;
