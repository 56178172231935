/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import { makeSearchString } from 'src/lib/utils/url-utils';
import { DonationActivity, RewardsActivityOptions } from 'src/services/types';
import { AxiosResponse } from 'axios';
import { rewardsClassroomActivityURL } from 'src/services/urls';

async function getRewardsActivityBase(
    opts: RewardsActivityOptions = {}
): Promise<AxiosResponse<any>> {
    const searchString = makeSearchString(opts as Record<string, string>);
    const headers =
        opts.paginationPage || opts.paginationLimit
            ? {
                  headers: {
                      'Pagination-Page': opts.paginationPage || '',
                      'Pagination-Limit': opts.paginationLimit || '',
                  },
              }
            : {};
    const activities = await axiosInstance.get(
        `${rewardsClassroomActivityURL}${
            searchString ? `?${searchString}` : ''
        }`,
        headers
    );

    return activities;
}

export async function getRewardsActivities(
    opts: RewardsActivityOptions = {}
): Promise<DonationActivity[]> {
    const activities = await getRewardsActivityBase(opts);

    if (activities?.data?.results) {
        // applying sorting with date, otherwise activities are listing as all debits on top and then all credits
        const sortedActivities = activities.data.results.sort(
            (a: any, b: any) =>
                Date.parse(b.activityDate) - Date.parse(a.activityDate)
        );
        return sortedActivities;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when calling ${rewardsClassroomActivityURL}`
    );
    Log.axios(activities);
    return [];
}

export async function getRewardsActivitiesPaginated(
    opts: RewardsActivityOptions = {}
) {
    const activities = await getRewardsActivityBase(opts);

    let sortedActivities = [];
    if (activities?.data?.results) {
        // applying sorting with date, otherwise activities are listing as all debits on top and then all credits
        sortedActivities = activities.data.results.sort(
            (a: any, b: any) =>
                Date.parse(b.activityDate) - Date.parse(a.activityDate)
        );
    }

    const paginationCount = activities?.headers['pagination-count'];
    const paginationLimit = activities?.headers['pagination-limit'];
    const paginationPage = activities?.headers['pagination-page'];
    const paginationTotal = activities?.headers['pagination-total'];

    return {
        donationActivity: sortedActivities,
        paginationCount,
        paginationLimit,
        paginationPage,
        paginationTotal,
    };
}
