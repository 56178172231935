/* eslint-disable import/prefer-default-export */
import { useState } from 'react';

/** useful for retrying a query */
export function useAttempts() {
    const [attempts, setAttempts] = useState(0);
    function retry() {
        setAttempts((numAttempts: number) => numAttempts + 1);
    }
    return { attempts, setAttempts, retry };
}

/** Shorthand that keeps us from refetching on queries that require input changes. */
export const NO_REFETCH_FOCUS_CONNECT = {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
};

export const NO_REFETCH = {
    ...NO_REFETCH_FOCUS_CONNECT,
    refetchOnMount: false,
};

export const CACHE_ONLY = {
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
};
