
import React, { useContext } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useRouter from 'src/hooks/useRouter';
import ROUTES from '../../../lib/routes';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';

const useStyles = makeStyles((theme) => ({
    ShippingServicesNoDataConatiner: {
        margin: '52px auto',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
    },
    imageAlign: {
        margin: '42px auto',
    },
    errorAlign: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        textAlign: 'center',
        color: '#101820',
    },
    errorDetailsAlign: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        textAlign: 'center',
        width: '315px',
        marginTop: '10px',
        color: '#101820',
    },
    startShippingBtn: {
        height: '40px',
        width: '200px',
        borderRadius: '20px',
        background: '#CC0000',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '17px',
        color: "#FFFFFF",
        marginTop: '24px',
        letterSpacing: '0px',
    },
    goBackBtn: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        textAlign: 'center',
        marginTop: '10px',
        color: '#086DD2',
    },
}))

const ShippingServicesNoData = ({ imgData, error, errorDetails }: any) => {
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const onBack = () => {
        history.push({
            pathname: ROUTES.shippingServices,
        })
    }
    const { updateFetchSenderInfo } = useContext(ShippingInfoContext);
    const handleStartShipment = () => {
        updateFetchSenderInfo(true)
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=createShipmentSenderInfo',
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'no data page',
        });

    }
    return (
        <div className={classes.ShippingServicesNoDataConatiner}>
            <div>
                <img src={imgData} alt="shipping no data icons" className={classes.imageAlign} />
            </div>
            <Typography className={classes.errorAlign}>{error}</Typography>
            {errorDetails ?
                <Typography className={classes.errorDetailsAlign}>{errorDetails}</Typography> : null}
            <ButtonBase className={classes.startShippingBtn} onClick={handleStartShipment}>Start a shipment</ButtonBase>
            <ButtonBase className={classes.goBackBtn} onClick={onBack}>Go back</ButtonBase>
        </div>
    )
}

export default ShippingServicesNoData;