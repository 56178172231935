import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import { GUTTER_WIDTH } from 'src/theme';
import classroomRewardsBg from '../../../assets/bgs/classroomRewardsBg.svg';

const useStyles = makeStyles({
  screen: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: COLORS.brandRed,
    backgroundImage: `url(${classroomRewardsBg})`,
  },
  srwSscreen: {
    background: COLORS.brandRed,
    backgroundImage: 'none',
  },
  classRoomScreen: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  enrollFormCard: {
    marginTop: '1rem',
    marginLeft: GUTTER_WIDTH,
    marginRight: GUTTER_WIDTH,
    marginBottom: '2rem',
    justifyContent: 'center',
    padding: '1rem 0',
  },
  darkTitle: {
    color: '#ffffff',
  },
  whiteTitle: {
    color: COLORS.primaryWhite,
  },
  welcomeMessage: {
    marginTop: '0.75rem',
    textAlign: 'center',
    fontSize: '1.3125rem',
    minHeight: '1.5rem',
    fontWeight: 500,
  },
  rewardsWalletText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 24px 24px',
    alignItems: 'flex-start',
  },
  rewardsWalletImag: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  cardWrapper: {
    borderRadius: '1.5rem',
    width: 'inherit',
  },
  rewardCardWrapper: {
    borderRadius: '1.5rem',
    width: 'inherit',
    marginTop: '80px',
    background: '#FFF',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
  srwMmberCardWrapper: {
    margin: '0 auto 1.5rem auto',
  },
  blackButton: {
    display: 'flex',
    margin: '24px 1.5rem',
    padding: '.5rem',
    minHeight: '2.5rem',
    textTransform: 'none',
    borderRadius: '1.885rem',
    background: COLORS.homeBlack,
    color: COLORS.whiteSmoke,
    fontWeight: 500,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: COLORS.homeBlack,
    },
    '&:active': {
      backgroundColor: COLORS.homeBlack,
    },
  },
  classRoomContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: COLORS.rewardsYellow,
    backgroundImage: `url(${classroomRewardsBg})`,
    marginBottom: '10px',
  },
});
export default useStyles;
