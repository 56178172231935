import React, { useState } from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import COLORS from 'src/lib/colors';
import NavTop from 'src/components/nav-top';
import GetStartedWithClassroomRewards from 'src/components/get-started-with-classroom-rewards';
import ClassroomRewardsSummary from 'src/components/classroom-rewards-summary';
import RecentActivity from 'src/components/recent-activity';
import FavoritesActivity from 'src/components/favorites-activty';
import AllRecentActivityDialog from 'src/components/all-recent-activity-dialog/all-recent-activity-dialog';
import { fmtDollarsAndCents } from 'src/lib/utils/string-utils';
import { RewardsDetails } from 'src/services/types';
import ShareDialog from 'src/components/share-dialog';
import useDonationFlow from 'src/hooks/useDonationFlow';
import useAllRecentActivity from 'src/hooks/useAllRecentActivity';
import GiveNowErrorDialog, {
    useGiveNowError,
} from 'src/components/give-now-error-dialog';
import useRouter from 'src/hooks/useRouter';
import { makeSearchString } from 'src/lib/utils/url-utils';
import PullRefresh from 'src/components/pull-refresh';

type EnrolledScreenProps = {
    rewardsDetails?: RewardsDetails;
    switchTab?: any;
};

const useStyles = makeStyles({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        justifyContent: 'center',
    },
    payoutAvailableAmount: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        letterSpacing: '-0.6pt',
    },
    amountSign: {
        fontSize: '1.8rem',
    },
    amount: {
        fontSize: '4rem',
    },
    decimalAmount: {
        fontSize: '1.9rem',
    },
    screenTitle: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 500,
        marginBottom: '2rem',
        marginTop: '-1rem',
        letterSpacing: '-0.6pt',
    },
    giveNowButton: {
        width: '75%',
        marginBottom: '8.5rem',
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    topNavContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        background: COLORS.rewardsYellow,
        backgroundImage: 'url(bgs/classroomRewardsBg.svg)',
        marginBottom: '-110px',
    },
    topNavSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

function RewardsEnrolledNonEducator({
    rewardsDetails,
    switchTab,
}: EnrolledScreenProps) {
    const classes = useStyles();
    const { history } = useRouter();
    const {
        openAllRecentActivity,
        closeAllRecentActivity,
        allRecentActivityOpen,
    } = useAllRecentActivity();
    const { openDonationFlow } = useDonationFlow();
    const [showShareDialog, setShowShareDialog] = useState(false);
    function openShareDialog() {
        setShowShareDialog(true);
    }

    function closeShareDialog() {
        setShowShareDialog(false);
    }

    const { variant, setVariant, handlePrimaryBtnClick, open, handleClose } =
        useGiveNowError();

    const { dollars: payoutDollars, cents: payoutCents } = fmtDollarsAndCents(
        rewardsDetails?.availableDonationAmount || 0
    );

    function giveNow() {
        if (!rewardsDetails?.availableDonationAmount) {
            setVariant('give');
        } else {
            openDonationFlow();
        }
    }

    function addFavorites() {
        openDonationFlow();
    }

    function manageFavorites() {
        history.push({
            search: makeSearchString({ myFavorites: 'open' }),
        });
    }

    function seeAllRecentActivities() {
        openAllRecentActivity();
    }

    return (
        <PullRefresh className="hiddenScrollForPullToRefresh">
            <Box className={classes.screen}>
                <div className={classes.topNavContainer}>
                    {switchTab()}
                    <div className={classes.topNavSubContainer}>
                        <Typography className={classes.payoutAvailableAmount}>
                            <span className={classes.amountSign}>$</span>
                            <span className={classes.amount}>
                                <span id="amount-text">{payoutDollars}</span>
                                <sup className={classes.decimalAmount}>
                                    {payoutCents}
                                </sup>
                            </span>
                        </Typography>
                        <Typography className={classes.screenTitle}>
                            Classroom Rewards available
                        </Typography>
                        <Button
                            id="rewards-enrolled-give-now-button"
                            variant="contained"
                            classes={{
                                root: classes.giveNowButton,
                            }}
                            onClick={giveNow}
                        >
                            Give now
                        </Button>
                    </div>
                </div>
                <ClassroomRewardsSummary
                    rewardsSummaryData={rewardsDetails}
                    userType="Supporter"
                />
                <FavoritesActivity
                    onAddFavoritesPressed={addFavorites}
                    onManageFavoritesPressed={manageFavorites}
                />
                <RecentActivity onSeeAllPressed={seeAllRecentActivities} />
                <GetStartedWithClassroomRewards
                    userType="Supporter"
                    userConfirmState="Confirmed"
                    onGiveNowPressed={giveNow}
                    onSharePressed={openShareDialog}
                />
                <AllRecentActivityDialog
                    open={allRecentActivityOpen}
                    onClose={closeAllRecentActivity}
                />
                <GiveNowErrorDialog
                    open={open}
                    variant={variant}
                    handleClose={handleClose}
                    handlePrimaryBtnClick={handlePrimaryBtnClick}
                />
                <ShareDialog
                    onBasePage
                    open={showShareDialog}
                    onClose={closeShareDialog}
                    inviteType="general"
                />
            </Box>
        </PullRefresh>
    );
}

export default RewardsEnrolledNonEducator;
