import React from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import useUserInfo from 'src/hooks/useUserInfo';

// const useStyles = makeStyles({})

export default function PullRefresh({
    children,
    isPullable = true,
    className = '',
    refetchData,
}: any) {
    const { refetchUserInfo } = useUserInfo();
    const refreshData = (): Promise<void> =>
        new Promise(() => {
            if (refetchData) {
                refetchData();
            }
            setTimeout(() => {
                refetchUserInfo();
            }, 1000);
        });

    return (
        <PullToRefresh
            onRefresh={refreshData}
            isPullable={isPullable}
            className={className}
        >
            {children}
        </PullToRefresh>
    );
}
