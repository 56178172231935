/* eslint-disable max-len */
import React from 'react';

const IconCall = () => (

    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.1729 20.8907C19.9893 22.168 18.6182 22.6954 16.7432 22.6954C13.4736 22.6954 9.52441 20.5391 5.91504 16.9414C2.29395 13.3204 0.161133 9.35942 0.161133 6.06645C0.161133 4.21488 0.700195 2.85551 1.96582 1.68363C2.00513 1.64994 2.04175 1.61625 2.07697 1.58384C2.11524 1.54862 2.15186 1.51493 2.18848 1.48441C2.93848 0.781289 3.72363 0.429727 4.43848 0.441446C5.2002 0.464883 5.90332 0.875039 6.5127 1.76566L8.85645 5.1641C9.52441 6.12504 9.62988 7.26176 8.63379 8.28129L7.79004 9.13676C7.53223 9.39457 7.48535 9.67582 7.66113 9.99223C8.12988 10.8125 8.90332 11.6914 9.99316 12.7813C10.9893 13.7774 12.29 14.8438 12.8643 15.1954C13.1807 15.3711 13.4619 15.3243 13.7197 15.0664L14.5752 14.2227C15.5947 13.2266 16.7314 13.3321 17.6924 14L21.0908 16.3438C21.9814 16.9532 22.415 17.6563 22.415 18.418C22.415 19.1329 22.0752 19.9063 21.3721 20.668C21.3416 20.7046 21.3079 20.7412 21.2726 20.7795C21.2402 20.8147 21.2065 20.8514 21.1729 20.8907ZM6.95801 15.9805C10.0283 19.0625 13.7666 21.3125 16.7549 21.2774C18.0791 21.2657 19.2393 20.8204 20.1182 19.8711C20.1525 19.8197 20.1805 19.787 20.2161 19.7457C20.2291 19.7305 20.2431 19.7142 20.2588 19.6954C20.6572 19.2618 20.8564 18.8282 20.8564 18.4414C20.8564 18.0664 20.7041 17.75 20.3174 17.5039L16.9307 15.2305C16.4971 14.9375 16.0049 14.8907 15.5244 15.3711L14.5752 16.3321C13.8721 17.0352 12.9697 17.0118 12.2666 16.5313C11.4463 15.9688 10.0986 14.8204 9.03223 13.7422C7.97754 12.6758 6.8877 11.4102 6.3252 10.5899C5.84473 9.88676 5.82129 8.98441 6.52441 8.28129L7.48535 7.33207C7.96582 6.8516 7.91895 6.35941 7.62598 5.92582L5.35254 2.5391C5.10645 2.15238 4.79004 2.00004 4.41504 2.00004C4.04004 2.00004 3.59473 2.19926 3.16113 2.5977L3.04915 2.69145L2.98535 2.73832C2.03613 3.61723 1.59082 4.76566 1.5791 6.06645C1.54395 9.06645 3.86426 12.8985 6.95801 15.9805Z" fill="#086DD2" />
    </svg>

);

export default IconCall;
