import React from 'react';
import { DialogContentText, makeStyles } from '@material-ui/core';
import ErrorDialog, { ErrorDialogProps } from 'src/components/error-dialog';
import COLORS from 'src/lib/colors';
import QRCode from 'react-qr-code';

const useStyles = makeStyles({
  dialogContentText: {
    color: COLORS.secondaryNeutralCharcoal,
    textAlign: 'center',
    marginTop: '1rem',
    letterSpacing: '-0.5pt',
    lineHeight: '1.2rem',
  },
});

export default function OptimalDeviceDialog(props: ErrorDialogProps) {
  const classes = useStyles();

  return (
    <ErrorDialog
      hideSecondaryBtn
      errorTitle="Staples app is best viewed on a mobile device"
      primaryBtnLabel="Got It!"
      primaryBtnId="optimal-device-primary-btn"
      {...props}
    >
      <QRCode value={'https://l.staplesconnect.com/app'} />

      <DialogContentText
        id="give-now-error-error-dialog-description"
        className={classes.dialogContentText}
      >
        Scan this QR code on your mobile device to open the Staples app.
      </DialogContentText>
    </ErrorDialog>
  );
}
