import { useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { guestTokenLogin } from 'src/lib/api-client/guest-api';

export interface GuestModeState {
    isGuestModeOn: boolean;
    guestModeStartTime?: Date;
}

export const guestModeStateStorageKey = 'guestModeState';
const guestModeStateAtom = atomWithStorage<GuestModeState>(
    guestModeStateStorageKey,
    {
        isGuestModeOn: false,
    },
    createJSONStorage(() => sessionStorage)
);
function useGuestMode() {
    const [guestModeState, setGuestModeState] = useAtom(guestModeStateAtom);
    const [isGuestModeLoading, setIsGuestModeLoading] = useState(false);

    // const updateGuestModeState = (newState: Partial<GuestModeState>) => {
    //     setGuestModeState((oldState) => ({ ...oldState, ...newState }));
    // };

    const isGuestModeOn = useMemo(
        () => guestModeState.isGuestModeOn,
        [guestModeState.isGuestModeOn]
    );

    const enterGuestMode = async (): Promise<boolean> => {
        setIsGuestModeLoading(true);
        try {
            const res = await guestTokenLogin();
            if (res?.access_token) {
                setGuestModeState({ isGuestModeOn: true });
                return true;
            }
            throw new Error('access token not found');
        } catch (_e) {
            return false;
        } finally {
            setIsGuestModeLoading(false);
        }
    };

    const exitGuestMode = () => setGuestModeState({ isGuestModeOn: false });

    return {
        isGuestModeLoading,
        isGuestModeOn,
        guestModeState,
        enterGuestMode,
        exitGuestMode,
    };
}

export default useGuestMode;
