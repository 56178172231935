import React from 'react';
import { Pill } from 'src/components/UI';
import styles from './styles.module.scss';

interface OfferTagProps {
    labels: (string | null)[];
    variant: 'full' | 'truncated';
}

function OfferTags({ labels, variant }: OfferTagProps) {
    const filteredLabels = labels.filter((label) => !!label);
    const labelsLength = filteredLabels.length;
    const pills = filteredLabels.map((label, index) => {
        if (variant === 'full' || index === 0) {
            return (
                <li>
                    <Pill className={styles.pill}>{label}</Pill>
                </li>
            );
        }
        if (index === 1) {
            return (
                <li>
                    <Pill className={styles.pill}>{labelsLength - 1}</Pill>
                </li>
            );
        }
        return null;
    });
    return <ul className={styles.pillList}>{pills}</ul>;
}

export default OfferTags;
