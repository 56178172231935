import React, { useLayoutEffect, useState } from 'react';
import { Body, Button, Heading } from 'src/components/UI';
import Carousel from 'src/components/Carousel';
import COLORS from 'src/lib/colors';
import {
    eventMetricsMap,
    useEventsReporter,
    useGuestMode,
    useRouter,
} from 'src/hooks';
import { COACH_SCREENS_ROUTES, LOGIN_ROUTES } from 'src/routers/routes';
import { guestTokenLogin } from 'src/lib/api-client/guest-api';
import LoadingOverlay from 'src/components/loading-overlay';
import ROUTES from 'src/lib/routes';
import styles from './styles.module.scss';

const IS_FIRST_TIME_SCREEN_SEEN_KEY = 'persistent_isFirstTimeScreenSeen';

function FirstTimeUserScreen() {
    const { push } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const { analyticsCustomEvent } = useEventsReporter();
    const { enterGuestMode } = useGuestMode();

    useLayoutEffect(() => {
        const isFirstTimeScreenSeen = localStorage?.getItem(
            IS_FIRST_TIME_SCREEN_SEEN_KEY
        );
        if (ROUTES.activateAllStoreOffers === sessionStorage.getItem('route')) {
            push(ROUTES.activateAllStoreOffers);
        } else if (isFirstTimeScreenSeen) {
            push(LOGIN_ROUTES.login);
        } else {
            localStorage?.setItem(IS_FIRST_TIME_SCREEN_SEEN_KEY, 'true');
        }
    }, []);

    const handleGuest = () => {
        setIsLoading(true);
        analyticsCustomEvent(eventMetricsMap.continue_as_guest, {
            click_text: 'continue as guest',
            element_location: 'all in one place',
        });
        enterGuestMode().then((success) => {
            if (success) {
                push(COACH_SCREENS_ROUTES.location);
            }
            setIsLoading(false);
        });
        // guestTokenLogin()
        //     .then((res) => {
        //         if (res?.access_token) {
        //             sessionStorage.setItem('guest', 'ON');
        //         }
        //         setIsLoading(false);
        //     })
        //     .catch((e) => {
        //         setIsLoading(false);
        //         console.log('error', e);
        //     });
    };

    return (
        <div className={styles.firstTimeUserContainer}>
            <div className={styles.firstTimeUserCarouselContainer}>
                <Heading color="textWhite" type="h3" weight="bold" size={26}>
                    Welcome to Staples
                </Heading>
                <Carousel
                    isAutoPlay
                    activeColor={COLORS.primaryWhite}
                    styles={{ root: { width: '100%' } }}
                    content={[
                        <Body
                            padding="10px 50px"
                            color="textWhite"
                            size={16}
                            textAlign="textCenter"
                            weight="light"
                        >
                            <Body display="inline">Shop anytime.</Body> Enjoy
                            fast delivery and easy, in-store pickup.
                        </Body>,
                        <Body
                            padding="10px 50px"
                            color="textWhite"
                            size={16}
                            textAlign="textCenter"
                            weight="light"
                        >
                            <Body display="inline">Earn points.</Body> Make the
                            most of every purchase with Easy Rewards™.
                        </Body>,
                        <Body
                            padding="10px 50px"
                            color="textWhite"
                            size={16}
                            textAlign="textCenter"
                            weight="light"
                        >
                            <Body display="inline">Save even more.</Body> From
                            Hot Deals to exclusive coupons, it’s all in the app!
                        </Body>,
                    ]}
                    dots={3}
                />
            </div>

            <div className={styles.firstTimeUserBottomContentContainer}>
                <Button
                    bgColor="bgBlack"
                    onClick={() => {
                        analyticsCustomEvent(eventMetricsMap.account_creation, {
                            click_text: 'create an account',
                            element_location: 'all in one place',
                        });
                        push(LOGIN_ROUTES.create);
                    }}
                    className={styles.btnAlign}
                >
                    Create an account
                </Button>
                <Button
                    bgColor="bgRed"
                    onClick={() => {
                        analyticsCustomEvent(eventMetricsMap.sign_in_page, {
                            click_text: 'sign in or sign up',
                            element_location: 'app first page',
                            enroll_flow: 'onboarding page',
                        });
                        push(LOGIN_ROUTES.login);
                    }}
                    className={styles.btnAlign}
                >
                    Sign in
                </Button>
                <Button
                    variant="text"
                    color="textLinkBlue"
                    onClick={handleGuest}
                    fontWeight="light"
                >
                    Or shop as a guest
                </Button>
                {isLoading && (
                    <LoadingOverlay
                        variant="wordmark"
                        theme="transparent-light"
                        open={isLoading}
                    />
                )}
            </div>
        </div>
    );
}

export default FirstTimeUserScreen;
