import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import FullWidthCard from 'src/components/cards/full-width-card';
import { GUTTER_WIDTH_SLIM } from 'src/theme';
import { DonationSummary, RewardsAccountUserType } from 'src/services/types';
import { formatCommasWithDecimal } from 'src/lib/utils/string-utils';
import { isEducatorOrAdmin } from 'src/lib/utils/user-utils';

type RewardsSummaryProps = {
    userType?: RewardsAccountUserType;
    /** if undefined, we are fetching */
    rewardsSummaryData?: DonationSummary;
};

const useStyles = makeStyles({
    rewardsSummary: {
        marginLeft: GUTTER_WIDTH_SLIM,
        marginRight: GUTTER_WIDTH_SLIM,
        justifyContent: 'center',
    },
    rewardsSummaryIcon: {
        alignSelf: 'center',
    },
    rewardsSummaryIconWrapper: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '5rem',
        height: '5rem',
        margin: '0 auto',
        marginBottom: '-3.5rem',
        zIndex: 99999,
    },
    rewardsWrapper: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rewardsTitle: {
        color: '#101820',
        fontWeight: 'bold',
        fontSize: '1.35rem',
        marginTop: '3.75rem',
        marginBottom: '.75rem',
        textAlign: 'center',
        padding: '0 1rem',
        lineHeight: '1.6rem',
    },
    rewardsInnerWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    rewardsTypeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rewardsSummaryAmount: {
        color: '#101820',
        fontWeight: 'bold',
        fontSize: '1.10rem',
        textAlign: 'center',
        marginTop: '0.3rem',
    },
    rewardsSummaryIcons: {
        marginTop: '1rem',
        width: '3.3rem',
    },
    rewardsTypeText: {
        fontSize: '0.9rem',
        textAlign: 'center',
        marginTop: '0.3rem',
        maxWidth: '8rem',
        letterSpacing: '-0.30pt',
        padding: '0 1.2rem',
        lineHeight: '1rem',
    },
});

const secondaryAmount = (
    rewardsSummaryData: DonationSummary,
    userType?: RewardsAccountUserType
) =>
    isEducatorOrAdmin(userType)
        ? rewardsSummaryData.neededForNextPayoutAmount
        : rewardsSummaryData.availableDonationAmount;

type IconInfo = { icon: string; title: string };
const getLeftIcon = (userType?: RewardsAccountUserType): IconInfo =>
    isEducatorOrAdmin(userType)
        ? {
              icon: require('../assets/icons/iconClassroomrewardsGreen.svg'),
              title: 'Lifetime Earnings',
          }
        : {
              icon: require('../assets/icons/iconClassroomrewardsDonateGreen.svg'),
              title: 'Lifetime Donations',
          };
const getRightIcon = (userType?: RewardsAccountUserType): IconInfo =>
    isEducatorOrAdmin(userType)
        ? {
              icon: require('../assets/icons/iconClassroomrewardsDonateYellow.svg'),
              title: 'Needed for next payout',
          }
        : {
              icon: require('../assets/icons/iconClassroomrewardsGreen.svg'),
              title: 'Available to Donate',
          };

/** used for loading and displaying classroom reward summary */
export default function ClassroomRewardsSummary({
    userType,
    rewardsSummaryData,
}: RewardsSummaryProps) {
    const classes = useStyles();
    if (!rewardsSummaryData) {
        return <Box>&nbsp;</Box>;
    }
    const { lifetimeDonationAmount } = rewardsSummaryData;

    const leftIcon = getLeftIcon(userType);
    const rightIcon = getRightIcon(userType);

    return (
        <Box>
            <Box className={classes.rewardsSummaryIconWrapper}>
                <img
                    className={classes.rewardsSummaryIcon}
                    src={require('../assets/icons/iconClassroomrewardsModal.svg')}
                    alt="rewards modal icon"
                />
            </Box>
            <FullWidthCard
                hasManualShadow
                className={classes.rewardsSummary}
                id="classroom-rewards-summary-card"
            >
                <Box display="flex" className={classes.rewardsWrapper}>
                    <Typography
                        className={classes.rewardsTitle}
                        id="classroom-rewards-summary-title"
                    >
                        Classroom Rewards Summary
                    </Typography>
                    <Box className={classes.rewardsInnerWrapper}>
                        <Box
                            id="rewards-summary-type-lifetime"
                            className={classes.rewardsTypeWrapper}
                        >
                            <img
                                className={classes.rewardsSummaryIcons}
                                src={leftIcon.icon}
                                alt="rewards summary icon"
                            />
                            <Typography
                                className={classes.rewardsSummaryAmount}
                                id="rewards-summary-type-lifetime-amount-text"
                            >
                                $
                                {formatCommasWithDecimal(
                                    lifetimeDonationAmount / 100
                                )}
                            </Typography>
                            <Typography
                                className={classes.rewardsTypeText}
                                id="rewards-summary-type-lifetime-amount-text"
                            >
                                {leftIcon.title}
                            </Typography>
                        </Box>
                        <Box
                            id="rewards-summary-type-available-donations"
                            className={classes.rewardsTypeWrapper}
                        >
                            <img
                                className={classes.rewardsSummaryIcons}
                                src={rightIcon.icon}
                                alt="rewards summary icon"
                            />
                            <Typography
                                className={classes.rewardsSummaryAmount}
                                id="rewards-summary-type-available-donations-amount-text"
                            >
                                $
                                {formatCommasWithDecimal(
                                    secondaryAmount(
                                        rewardsSummaryData,
                                        userType
                                    ) / 100
                                )}
                            </Typography>
                            <Typography
                                className={classes.rewardsTypeText}
                                id="rewards-summary-type-available-donations-amount-text"
                            >
                                {rightIcon.title}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </FullWidthCard>
        </Box>
    );
}
