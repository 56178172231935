import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import {
    makeStyles,
    Box,
    Typography,
    Fade,
    ButtonBase,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import COLORS from 'src/lib/colors';
import ListCreateEditDialog from 'src/components/list-create-edit-dialog';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import {
    FullListResult,
    ListItemType,
    ListQueryResult,
    ListResult,
    Store,
} from 'src/services/types';
import { getFeaturedLists, getListDetails } from 'src/lib/api-client/lists';
import FullWidthCard from 'src/components/cards/full-width-card';
import { updateItemSequence } from 'src/lib/utils/draggable-list-utils';
import {
    buildLink,
    compareFields,
    listsIsEqual,
    removeDuplicates,
} from 'src/lib/utils/list-utils';
import DraggableReorderableVariableHeightList from 'src/components/draggable-reorderable-variable-height-list';
import StoreSearchModal from 'src/components/store-search-modal';
import useStoreDataWithInventory from 'src/hooks/useStoreDataWithInventory';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ErrorDialog, { GenericErrorContent } from 'src/components/error-dialog';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import { NO_REFETCH_FOCUS_CONNECT } from 'src/lib/utils/react-query-utils';
import useUpdateItem from 'src/hooks/useUpdateItem';
import {
    getItemFromLocalStorage,
    saveToLocalStorage,
} from 'src/hooks/useLocalStorage';
import LoadingOverlay from 'src/components/loading-overlay';
import useRouter from 'src/hooks/useRouter';
import { ShareListDialog } from 'src/components/share-dialog';
import ListFilters, { ListFilter } from './list-filters';
import IndividualListItem from './individual-list-item';
import ReceiveSharedListBase from '../receive-shared-list';
import useStyles from './individual-list-styles';

function Item({ provided, item, style, itemProps }: any) {
    const {
        isDragging,
        inEditMode,
        handleToggleCheckOffItem,
        handleChangeStoreClick,
        handleItemDetailsClick,
        handleItemReviewsClick,
        handleRefresh,
        hideToolTip,
    } = itemProps;
    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
                ...style,
                ...provided.draggableProps.style,
                outline: 'none',
                width: '90%',
                margin: '0 auto',
            }}
            className={`item ${isDragging ? 'is-dragging' : ''}`}
        >
            <IndividualListItem
                item={item}
                inEditMode={inEditMode}
                handleToggleCheckOffItem={handleToggleCheckOffItem}
                handleChangeStoreClick={handleChangeStoreClick}
                handleRefresh={handleRefresh}
                hideToolTip={hideToolTip}
            />
        </div>
    );
}

type IndividualListProps = {
    list?: ListResult;
    justCreated?: boolean;
    fromFlow?: 'import';
    onBack: () => void;
    onSave: () => void;
    selectedListType?: string;
};

/** An individual shopping list of products */
const IndividualList = (props: IndividualListProps) => {
    const { list, justCreated, fromFlow, onBack, onSave, selectedListType } =
        props;
    const {
        query: { listId, type },
        location,
    } = useRouter();
    const { state }: { state: any } = location;
    const featured = type === 'featured';
    const isSharedList = type === 'shared';
    const isSavedSharedList = type === 'saved-shared-list';
    const classes = useStyles();
    const { goToPage } = useProductBrowseContext();
    const { customEvent } = useEventsReporter();
    const [inEditMode, _setInEditMode] = useState(
        !!state?.newList || justCreated
    );
    const [listNotAvailableDialogOpen, setListNotAvailableDialogOpen] =
        useState(false);
    const [shareFallbackDialogProps, setShareFallbackDialogProps] =
        useState<null | {
            listName: string;
            url: string;
        }>(null);
    const [isShareLoading, setIsShareLoading] = useState(false);
    const { notifyFeaturedListUpdate, featuredListUpdateCount } = useUserUpdateContext();
    const { data: featuredListsData } = useQuery(
        ['getFeaturedLists', featuredListUpdateCount],
        getFeaturedLists
    );
    function setInEditMode(newVal: boolean) {
        if (listId) {
            _setInEditMode(newVal);
        }
        if (newVal === false && justCreated) {
            // we were just in edit mode and the user has selected save
            // on a freshly created list
            onSave();
        }
    }

    const [disclaimerErrorOpen, setDisclaimerErrorOpen] = useState(false);
    function handleDisclaimerErrorClose() {
        setDisclaimerErrorOpen(false);
    }
    const [activeListFilters, setActiveListFilters] =
        React.useState<ListFilter[]>();

    const [currentSelectedItem, setCurrentSelectedItem] =
        useState<ListItemType | null>();
    useEffect(() => {
        if (isSharedList) {
            customEvent(eventMetricsMap.share_list_opened);
        }

    }, [isSharedList]);
    const [isChangeStoreDialogOpen, setIsChangeStoreDialogOpen] = useState(false);
    const {
        isFetching: isFetchingStoresWithInventory,
        setRetrieveStoreInventory,
        mainStoreWithInventory,
        additionalStoresWithInventory,
    } = useStoreDataWithInventory({
        itemNumber: currentSelectedItem?.itemNumber?.toString() || '',
    });
    const {
        data: listDetailsData,
        error: listDetailsError,
        isLoading: listDetailsIsFetching,
        refetch: refetchUserListDetails,
    } = useQuery(
        ['userListDetails', listId, type],
        async () => {
            if (listId) {
                return getListDetails({
                    listId,
                    type,
                });
            }
            return list as FullListResult;
        },
        {
            keepPreviousData: true,
            ...NO_REFETCH_FOCUS_CONNECT,
        }
    );

    const [listItems, setListItems] = useState<FullListResult | undefined | null>(
        listDetailsData
    );
    const isWeeklyAddTile: any = featuredListsData?.find((fData: any) =>
        listDetailsData?.id && fData?.id === listDetailsData?.id);

    useEffect(() => {
        refetchUserListDetails();
    }, [listItems?.items?.length]);

    useEffect(() => {
        if (!listDetailsIsFetching) {
            if (
                listDetailsError ||
                !listDetailsData ||
                (listDetailsData?.items.length <= 0 && (featured || isSharedList))
            ) {
                // redirect to previous page
                notifyFeaturedListUpdate();
                setListNotAvailableDialogOpen(true);
            } else if (!listDetailsError && listDetailsData) {
                listDetailsData.items =
                    listDetailsData.items.length > 0
                        ? [
                            ...listDetailsData.items.sort((a, b) =>
                                a.sequence > b.sequence ? 1 : -1
                            ),
                        ]
                        : [];
                setListItems(listDetailsData);
            }
        }

    }, [listDetailsError, listDetailsIsFetching, listDetailsData]);

    function addAnItem() {
        goToPage({
            type: 'search-landing',
            intent: {
                type: 'add-to-existing-list',
                info: { listId: listDetailsData?.id },
            },
        });
    }

    const handleClose = () => {
        setListNotAvailableDialogOpen(false);
        onBack();
    };

    const { commitToUpdate, isFetching: isFetchingUpdate } = useUpdateItem({
        onComplete: refetchUserListDetails,
        toastConfig: { skip: true },
    });

    function handleItemReviewsClick(item: ListItemType) {
        if (item.itemNumber) {
            goToPage({
                type: 'product-details',
                pageDetails: {
                    itemInfo: item,
                    listId: listDetailsData?.id,
                    forceLoad: true,
                    quantity: item.quantity,
                },
                intent: {
                    type:
                        featured || isSharedList /* || isSavedSharedList */
                            ? 'hide-features'
                            : 'add-to-existing-list',
                    info: { listId: listDetailsData?.id },
                },
                openReviews: true,
            });
        } else {
            setDisclaimerErrorOpen(true);
        }
    }

    function handleItemDetailsClick(item: ListItemType) {
        if (item.itemNumber) {
            goToPage({
                type: 'product-details',
                pageDetails: {
                    itemInfo: item,
                    listId: listDetailsData?.id,
                    forceLoad: true,
                    quantity: item.quantity,
                },
                intent: {
                    type:
                        featured || isSharedList ? 'hide-features' : 'add-to-existing-list',
                    info: { listId: listDetailsData?.id },
                },
            });
        } else {
            setDisclaimerErrorOpen(true);
        }
    }

    function handleOnBackClick() {
        if (inEditMode) {
            setInEditMode(false);
        } else {
            onBack();
        }
    }

    function handleOnDiscardClick() {
        customEvent(eventMetricsMap.share_list_discard);
        handleOnBackClick();
    }

    function handleUpdateItemStore(newStore: Store) {
        if (currentSelectedItem) {
            commitToUpdate(currentSelectedItem.id, newStore.storeNumber, 'storeId');
        }
        setIsChangeStoreDialogOpen(false);
    }

    function handleToggleCheckOffItem(item: ListItemType) {
        setCurrentSelectedItem(item);

        // Flipped the isChecked value and converted to a string
        const toggledIsChecked = item.isChecked ? 'false' : 'true';
        if (!item.isChecked) {
            customEvent(eventMetricsMap.checked_off_product);
        }
        commitToUpdate(item.id, toggledIsChecked, 'isChecked');
    }

    function handleChangeStoreClick(item: ListItemType) {
        setCurrentSelectedItem(item);
        setRetrieveStoreInventory(true);
        setIsChangeStoreDialogOpen(true);
    }

    function handleUpdateItemSequence(itemId: number, sequence: number) {
        commitToUpdate(itemId, sequence.toString(), 'sequence');
    }

    function handleStoreFilterSelect(selectedFilter: ListFilter) {
        const isActive =
            activeListFilters?.find(
                (activeFilter) => activeFilter.id === selectedFilter.id
            ) !== undefined;

        const action = isActive
            ? activeListFilters?.filter(
                (activeFilter) => activeFilter.id !== selectedFilter.id
            ) || []
            : [...(activeListFilters || []), ...[selectedFilter]];

        setActiveListFilters(action);
    }

    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const addFeaturedListOnComplete = () => {
        setIsEditDialogOpen(false);
        handleOnBackClick();
    };

    const handleShareListBtn = () => {
        // TODO image link hardcoded for now
        setIsShareLoading(true);
        buildLink(
            listId,
            // Image to store in the backend DB
            'https://de-app.staplesconnect.com/images/ShareLists/share-list-for-tile.png'
        )
            .then((link) => {
                if (navigator.share) {
                    navigator
                        .share({
                            title: 'Staples Connect',
                            text: listItems?.productListName || 'Shared list',
                            url: link,
                        })
                        .catch((error) => {
                            if (error.name !== 'AbortError') {
                                setShareFallbackDialogProps({
                                    listName: listItems?.productListName || 'Your shared list',
                                    url: link,
                                });
                            }
                            console.error(error);
                        });
                } else {
                    setShareFallbackDialogProps({
                        listName: listItems?.productListName || 'Your shared list',
                        url: link,
                    });
                }
                const sharedListIds = getItemFromLocalStorage('shared_list_ids', []);
                if (!sharedListIds.includes(listId)) {
                    sharedListIds.push(listId);
                }
                saveToLocalStorage('shared_list_ids', sharedListIds);
                customEvent(eventMetricsMap.share_list_clicked);
            })
            .catch((error) => {
                setListNotAvailableDialogOpen(true);
                console.error(error);
            })
            .finally(() => {
                setIsShareLoading(false);
            });
    };

    const mutateDraggedItem = (
        originalItems: ListQueryResult,
        destinationIndex: number
    ) =>
        updateItemSequence(
            originalItems,
            destinationIndex,
            (item: any, newSequenceNumber: number) =>
                handleUpdateItemSequence(item.id, newSequenceNumber)
        );

    const shouldUpdateItemsOnProps = (
        items: ListQueryResult,
        itemsFromProps: ListQueryResult
    ) =>
        !listsIsEqual(items, itemsFromProps, (aElem, index) =>
            compareFields(aElem, itemsFromProps[index], [
                'id',
                'sequence',
                'quantity',
                'storeId',
            ])
        );


    const filteredListItems =
        !!activeListFilters && !!activeListFilters.length
            ? listItems?.items?.filter((item) =>
                activeListFilters.find(
                    (activeFilter) => activeFilter.id === item.storeId
                )
            )
            : listItems?.items;

    const uncheckedOffItems = inEditMode
        ? filteredListItems
        : filteredListItems?.filter((item) => item.isChecked === false);
    const checkedOffItems =
        !inEditMode && filteredListItems?.filter((item) => item.isChecked === true);

    const stores = listItems?.items.map(
        (val) =>
        ({
            id: val.storeId,
            name: val.storeName,
        } as ListFilter)
    );

    const filteredStores = removeDuplicates(stores || [], 'id');
    const { analyticsCustomEvent } = useEventsReporter();
    const onClickWeeklyTile = () => {
        const url = "https://www.staplesconnect.com/weeklyad";
        openInNewTab(url);
        analyticsCustomEvent(eventMetricsMap.outbound, {
            click_text: 'Weekly Ad Tile',
            link_domain: 'staplesconnect.com',
            link_url: url,
        });
        optimoveEvents(eventMetricsMap.scm_outbound, {
            click_text: 'Weekly Ad Tile',
            link_domain: 'staplesconnect.com',
            link_url: url,
        });
    }
    return (
        <Box className={classes.screen} id="individual-list-screen">
            {isSharedList ? (
                <ReceiveSharedListBase
                    onSave={setIsEditDialogOpen}
                    onDiscard={handleOnDiscardClick}
                    listDetails={listItems}
                />
            ) : (
                <Box className={classes.commonNav}>
                    <Fade in>
                        <IconButton
                            onClick={handleOnBackClick}
                            size="small"
                            className={classes.backButton}
                        >
                            <ArrowBackIosRoundedIcon className={classes.backIcon} />
                        </IconButton>
                    </Fade>

                    <ButtonBase
                        onClick={(event: any) => {
                            event.preventDefault();
                            if (featured) {
                                analyticsCustomEvent(eventMetricsMap.product_lists, {
                                    click_text: 'save to my lists',
                                    element_location: 'product list',
                                });
                                optimoveEvents(eventMetricsMap.scm_product_lists, {
                                    click_text: 'save to my lists',
                                    element_location: 'product list',
                                });
                                setIsEditDialogOpen(true);
                            } else {
                                setInEditMode(!inEditMode);
                            }
                        }}
                        className={classes.editButton}
                        id="individual-list-edit-button"

                        disabled={listDetailsIsFetching || isFetchingUpdate}
                    >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {featured ? 'Save to my lists' : inEditMode ? 'Save' : 'Edit'}
                    </ButtonBase>
                </Box>
            )}
            <div>
                {isSharedList ? null : (
                    <Box className={classes.titleWrapper}>
                        <Typography
                            className={clsx(classes.listName, classes.singleLineClamp)}
                        >
                            {listItems?.productListName}
                        </Typography>
                        <Typography
                            className={clsx(classes.listDescription, classes.singleLineClamp)}
                        >
                            {listItems?.description}
                        </Typography>

                        {!featured &&
                            type &&
                            listDetailsData &&
                            listDetailsData?.items?.length > 0 && (
                                <ButtonBase
                                    onClick={async () => {
                                        handleShareListBtn();
                                    }}
                                    className={classes.shareListBtn}
                                    id="individual-list-share-list-button"
                                >
                                    Share List{' '}
                                    <img
                                        src={require('../../../assets/icons/iconSharedList.svg')}
                                        alt="share list icon"
                                        className={classes.shareListIcon}
                                    />
                                </ButtonBase>
                            )}
                    </Box>
                )}
                {inEditMode && (
                    <div className={classes.addItemButtonContainer}>
                        <Button onClick={addAnItem} className={classes.addItemButton}>
                            Add an item
                        </Button>
                    </div>
                )}
                {listDetailsData && isSavedSharedList && !featured && (
                    <div className={classes.sharedByContainer}>
                        <span
                            className={classes.nameByShared}
                            style={{
                                color:
                                    listDetailsData?.sharedByRole === 'Supporter'
                                        ? COLORS.primaryWhite
                                        : COLORS.textBlack,
                                backgroundColor:
                                    listDetailsData?.sharedByRole === 'Supporter'
                                        ? COLORS.brandCyan
                                        : COLORS.rewardsYellow,
                            }}
                        >
                            Shared by{' '}
                            {listDetailsData?.sharedByRole === 'Educator' ? 'teacher, ' : ''}
                            <b>{listDetailsData?.sharedBy}</b>
                        </span>
                    </div>
                )}
            </div>
            <Box className={classes.itemsContainer}>
                {isWeeklyAddTile?.isAdsDisplay ? <FullWidthCard
                    hasManualShadow
                    className={classes.itemContainerWeeklyAd}
                    onClick={onClickWeeklyTile}
                >
                    <Box className={classes.itemContentWeeklyAd} display="flex" flexDirection="row">
                        <img
                            className={classes.itemImageWeeklyAd}
                            src={require('../../../assets/icons/featuredListIcon.svg')}
                            alt="item icon"
                        />
                        <Typography className={classes.titleWeeklyAd}>
                            View the full weekly ad
                        </Typography>
                    </Box>
                </FullWidthCard> : null}
                {filteredListItems && filteredListItems.length > 0 && (
                    <>
                        <DraggableReorderableVariableHeightList
                            items={uncheckedOffItems}
                            disabled={!inEditMode}
                            itemProps={{
                                inEditMode,
                                handleToggleCheckOffItem,
                                handleChangeStoreClick,
                                handleItemDetailsClick,
                                handleItemReviewsClick,
                                handleRefresh: refetchUserListDetails,
                                hideToolTip: featured || isSharedList,
                            }}
                            Item={Item}
                            mutateDraggedItem={mutateDraggedItem}
                            shouldUpdateItemsOnProps={shouldUpdateItemsOnProps}
                        />

                        {checkedOffItems && checkedOffItems.length > 0 && (
                            <>
                                <Typography className={classes.checkedOffItemsTitle}>
                                    Checked Off Items
                                </Typography>
                                <DraggableReorderableVariableHeightList
                                    items={checkedOffItems}
                                    disabled={!inEditMode}
                                    itemProps={{
                                        inEditMode,
                                        handleToggleCheckOffItem,
                                        handleChangeStoreClick,
                                        handleItemDetailsClick,
                                        handleItemReviewsClick,
                                        handleRefresh: refetchUserListDetails,
                                    }}
                                    Item={Item}
                                    mutateDraggedItem={mutateDraggedItem}
                                    shouldUpdateItemsOnProps={shouldUpdateItemsOnProps}
                                />
                            </>
                        )}
                    </>
                )}
                {filteredListItems && filteredListItems.length === 0 && (
                    <FullWidthCard hasManualShadow className={classes.noItemsContainer}>
                        <Box className={classes.disclaimerContent}>
                            <img
                                className={classes.disclaimerIcon}
                                src={require('../../../assets/icons/iconNolist.svg')}
                                alt="no lists"
                            />
                            <Typography className={classes.disclaimerText}>
                                There are no products in this list yet. Add a product to get
                                started.
                            </Typography>
                        </Box>
                    </FullWidthCard>
                )}
            </Box>
            <ErrorDialog
                open={disclaimerErrorOpen}
                primaryBtnLabel="OK"
                errorTitle="Item Not Found"
                handleClose={handleDisclaimerErrorClose}
                hideSecondaryBtn
            >
                {/* eslint-disable-next-line max-len */}
                <GenericErrorContent errorDescription="Unfortunately, it looks like we don't carry this item, but we brought it into your list to help you keep track of your back to school needs." />
            </ErrorDialog>
            <StoreSearchModal
                ignoreIsActiveScreen={fromFlow === 'import'}
                isLoading={isFetchingStoresWithInventory}
                open={isChangeStoreDialogOpen}
                itemNumber={currentSelectedItem?.itemNumber?.toString()}
                withInventory
                includeMap={false}
                title="Check other stores"
                selectStoreCtaText="Shop this store"
                mainStore={mainStoreWithInventory}
                nearbyStores={additionalStoresWithInventory}
                onStoreSelected={handleUpdateItemStore}
                handleClose={() => setIsChangeStoreDialogOpen(false)}
                reportEvents={null}
            />
            <LoadingOverlay
                variant="wordmark"
                theme="light"
                open={listDetailsIsFetching || isFetchingUpdate || isShareLoading}
            />
            <ListCreateEditDialog
                open={isEditDialogOpen}
                mode="create"
                type={type}
                editDetails={{
                    listId: listDetailsData?.id,
                    listName: listDetailsData?.productListName,
                    listDescription: listDetailsData?.description || undefined,
                }}
                onComplete={addFeaturedListOnComplete}
                handleClose={() => setIsEditDialogOpen(false)}
                lowestValue={0}
            />
            {shareFallbackDialogProps && (
                <ShareListDialog
                    open={!!shareFallbackDialogProps}
                    {...shareFallbackDialogProps}
                    handleClose={() => setShareFallbackDialogProps(null)}
                />
            )}
            <Dialog
                open={listNotAvailableDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    This list is not available
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The list you selected has been removed from the app. If you believe
                        this is an error, please try your action again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default React.memo(IndividualList);