import React, { useState, useEffect, memo, useRef } from 'react';
import {
    Backdrop,
    BackdropProps,
    Button,
    Dialog,
    makeStyles,
    Typography,
} from '@material-ui/core';
import useUserInfo from 'src/hooks/useUserInfo';
import { countUniques, uniques } from 'src/lib/utils/list-utils';
import { fmtDollarsAndCents } from 'src/lib/utils/string-utils';
import COLORS from 'src/lib/colors';
import { Player } from '@lottiefiles/react-lottie-player';
import ShareDialog from 'src/components/share-dialog';
import { SCREEN_NAMES } from 'src/controllers/config';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import useIsOnLandingScreen from 'src/hooks/useIsOnLandingScreen';
import useDonationFlow from 'src/hooks/useDonationFlow';
import GiveNowErrorDialog, {
    useGiveNowError,
} from 'src/components/give-now-error-dialog';
import { DonationActivity } from 'src/services/types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';

const CIRCULAR_OVERHANG = '3.5rem';

const useStyles = makeStyles({
    dialogPaper: {
        overflowY: 'visible',
        padding: '0 2rem',
        borderRadius: '1rem',
        marginTop: `calc(${CIRCULAR_OVERHANG} + 1rem)`,
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
    },
    icon: {
        marginTop: `-${CIRCULAR_OVERHANG}`,
        height: '7rem',
    },
    title: {
        textAlign: 'center',
        paddingTop: '1.1rem',
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    amountTexts: {
        fontSize: '5rem',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2rem',
        letterSpacing: '-0.6pt',
    },
    amountSign: {
        letterSpacing: '-0.6pt',
        fontSize: '2rem',
        marginTop: '-1.5rem',
    },
    amount: {},
    decimalAmount: {
        fontSize: '2rem',
        marginTop: '-1.5rem',
        letterSpacing: '-0.6pt',
    },
    supporterText: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.1rem',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '1rem',
        paddingTop: CIRCULAR_OVERHANG,
    },
    ctaButton: {
        maxWidth: '17rem',
        width: '100%',
        marginTop: '.5rem',
        marginBottom: '.65rem',
        // TODO: move this to common theme
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    dismissButton: {
        textTransform: 'none',
        width: '100%',
        maxWidth: '17rem',
        color: COLORS.brandCyan,
        marginBottom: '.5rem',
        fontSize: '1rem',
    },
    lottieOver: {
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        zIndex: 2000,
        pointerEvents: 'none',
    },
});

function AnimatedBackdrop({
    src,
    updateCount,
    ...rest
}: BackdropProps & { updateCount: number; src: string }) {
    const playerRef = useRef<any>(null);
    useEffect(() => {
        if (playerRef.current?.play) {
            playerRef.current.setSeeker(0);
            playerRef.current.play();
        }
    }, [updateCount]);

    return (
        <Backdrop {...rest}>
            <Player
                ref={playerRef}
                autoplay
                keepLastFrame
                src={src}
                style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    left: '0',
                    bottom: '0',
                    backgroundColor: '#000',
                }}
            />
        </Backdrop>
    );
}

function AnimatedOverlayComponent({
    src,
    updateCount,
}: {
    src: string;
    updateCount: number;
}) {
    const classes = useStyles();
    const playerRef = useRef<any>(null);

    useEffect(() => {
        if (playerRef.current?.play) {
            playerRef.current.setSeeker(0);
            playerRef.current.play();
        }
    }, [updateCount]);
    return (
        <div className={classes.lottieOver}>
            <Player
                ref={playerRef}
                autoplay
                src={src}
                className={classes.lottieOver}
            />
        </div>
    );
}
const AnimatedOverlay = memo(AnimatedOverlayComponent);

type RewardsCelebrationModalProps = {
    // "classroom" rewards refer to rewards educators has received from donations
    // "earned" rewards refer to rewards all users have earned and are able to donate
    rewardsType: 'classroom' | 'earned';
    rewardsData: DonationActivity[] | undefined;
    lastAccessDateTime: number;
};

/** Listens for updates to recent activity and displays celebration modal */
function RewardsCelebrationModalComponent({
    rewardsType,
    rewardsData,
    lastAccessDateTime,
}: RewardsCelebrationModalProps) {
    const isClassroomReward = rewardsType === 'classroom';
    const classes = useStyles();
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [manuallyDismissedDate, setManuallyDismissedDate] = useState(0);
    const [cumulativeRewardsData, setCumulativeRewardsData] = useState({
        data: [] as DonationActivity[],
        total: 0,
        supporters: 0,
        updateCount: 0,
    });

    const { userInfo } = useUserInfo();
    // do not show the dialog or start a query if we are in the middle of another flow
    const { isOnLandingScreen, router } = useIsOnLandingScreen();
    const { openDonationFlow } = useDonationFlow();
    const { variant, setVariant, handlePrimaryBtnClick, open, handleClose } =
        useGiveNowError();
    const { customEvent } = useEventsReporter();

    const hasDimissedCurrentModal = manuallyDismissedDate >= lastAccessDateTime;

    const isOpen =
        cumulativeRewardsData.total &&
        !hasDimissedCurrentModal &&
        isOnLandingScreen &&
        !!rewardsData &&
        rewardsData.length > 0 &&
        cumulativeRewardsData.supporters > 0;

    const [holdOpen, setHoldOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            setHoldOpen(isOpen);
        }
    }, [isOpen]);
    useEffect(() => {
        setHoldOpen(false);
    }, [router.pathname]);

    const isActiveScreen = useIsActiveScreen(
        isClassroomReward
            ? SCREEN_NAMES.CLASSROOM_REWARDS_RECEIVED
            : SCREEN_NAMES.EARNED_FUNDS_RECEIVED,
        holdOpen
    );

    useEffect(() => {
        setCumulativeRewardsData((prev) => {
            const data = uniques(
                [...prev.data, ...(rewardsData || [])],
                (priorElems, elem) => {
                    const isUnique = !priorElems.find(
                        (p: any) => p.id === elem.id
                    );
                    return isUnique;
                }
            );

            const total =
                data
                    ?.filter(
                        (d: any) =>
                            d.donationType ===
                            (isClassroomReward ? 'Credit' : 'Earned')
                    )
                    .reduce((agg, elem) => agg + elem.pointsAmount, 0) || 0;
            const supporters = countUniques(data, (priorElems, elem) => {
                const isUnique = !priorElems.find(
                    (p: any) =>
                        p.sourceOrDestination === elem.sourceOrDestination
                );
                return isUnique;
            });

            const valuesHaveChanged =
                total !== prev.total || supporters !== prev.supporters;

            return {
                data,
                total,
                supporters,
                updateCount: valuesHaveChanged
                    ? prev.updateCount + 1
                    : prev.updateCount,
            };
        });
    }, [rewardsData]);

    useEffect(() => {
        if (isActiveScreen) {
            customEvent(eventMetricsMap.educator_funds_recieved);
        }
    }, [cumulativeRewardsData.updateCount, isActiveScreen]);

    const { dollars, cents } = fmtDollarsAndCents(cumulativeRewardsData.total);

    function openShareDialog() {
        customEvent(eventMetricsMap.share_tapped);
        setShowShareDialog(true);
    }

    function closeShareDialog() {
        setShowShareDialog(false);
    }

    function dismiss() {
        setHoldOpen(false);
        setManuallyDismissedDate(new Date(lastAccessDateTime).getTime());
    }

    function giveNow() {
        dismiss();
        if (!userInfo?.rewardsDetails?.availableDonationAmount) {
            setVariant('give');
        } else {
            openDonationFlow();
        }
    }

    function onExited() {
        // we do this after exit for the modal doesn't flicker 0 values before close
        setCumulativeRewardsData({
            data: [] as DonationActivity[],
            total: 0,
            supporters: 0,
            updateCount: 0,
        });
    }

    return (
        <>
            <Dialog
                open={isActiveScreen}
                scroll="body"
                classes={{ paper: classes.dialogPaper }}
                // material ui allows you to send custom props to the backdrop
                // but typescript isn't a fan so we need to use any here
                BackdropComponent={AnimatedBackdrop as any}
                BackdropProps={
                    {
                        updateCount: cumulativeRewardsData.updateCount,
                        src: isClassroomReward
                            ? require('../../assets/lotties/confetti-back.json')
                            : require('../../assets/lotties/confetti-orange-back.json'),
                    } as any
                }
                onExited={onExited}
            >
                <AnimatedOverlay
                    src={
                        isClassroomReward
                            ? require('../../assets/lotties/confetti-front.json')
                            : require('../../assets/lotties/confetti-orange-front.json')
                    }
                    updateCount={cumulativeRewardsData.updateCount}
                />
                <div className={classes.mainContent}>
                    <img
                        className={classes.icon}
                        src={
                            isClassroomReward
                                ? require('../../assets/icons/iconCrReceived.svg')
                                : require('../../assets/icons/iconClassroomrewardsSupporterWhiteBg.svg')
                        }
                        alt="received reward"
                    />
                    <Typography className={classes.title}>
                        {isClassroomReward
                            ? "Congrats! You've received new Classroom Rewards"
                            : 'New funds added to your Classroom Rewards!'}
                    </Typography>
                    <Typography className={classes.amountTexts}>
                        <span className={classes.amountSign}>$</span>
                        <span className={classes.amount}>
                            {dollars}
                            <sup className={classes.decimalAmount}>{cents}</sup>
                        </span>
                    </Typography>
                    <Typography className={classes.supporterText}>
                        {isClassroomReward
                            ? `From ${cumulativeRewardsData.supporters} ${
                                  cumulativeRewardsData.supporters === 1
                                      ? 'Parent or 1 Supporter'
                                      : 'Supporters'
                              }`
                            : 'Available to give'}
                    </Typography>
                </div>
                <div className={classes.buttons}>
                    <Button
                        className={classes.ctaButton}
                        onClick={isClassroomReward ? openShareDialog : giveNow}
                    >
                        {isClassroomReward ? 'Share' : 'Give now'}
                    </Button>
                    <Button className={classes.dismissButton} onClick={dismiss}>
                        Dismiss
                    </Button>
                </div>
                {isClassroomReward && (
                    <ShareDialog
                        open={showShareDialog}
                        onClose={closeShareDialog}
                        inviteType="receiving"
                        amount={cumulativeRewardsData.total / 100}
                    />
                )}
            </Dialog>
            {isActiveScreen && (
                <GiveNowErrorDialog
                    open={open}
                    variant={variant}
                    handleClose={handleClose}
                    handlePrimaryBtnClick={handlePrimaryBtnClick}
                />
            )}
        </>
    );
}

const RewardsCelebrationModal = memo(RewardsCelebrationModalComponent);
export default RewardsCelebrationModal;
