/* eslint-disable import/prefer-default-export */
import axiosInstance from 'src/lib/api-client/custom-axios';
import Log from 'src/lib/logging';
import { userHomeCarouselsURL } from 'src/services/urls';

export async function getHomeCarouselsDetails() {
    const result = await axiosInstance.get(`${userHomeCarouselsURL}`);
    if (result.data) {
        return result.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching home carousels ${userHomeCarouselsURL}`
    );
    Log.axios(result);
    return null;
}
